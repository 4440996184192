<ng-container *ngIf="featureDefinition && form" [formGroup]="form">
    <label [for]="'input-'+formControlName" [innerHTML]="featureDefinition.texts[currentLanguage] + (isRequired ? ' *' : '')"></label>
    <span class="text-service" *ngIf="authenticationService.currentUser?.isEmployee()"> {{ featureDefinition.id }}</span>
    <div class="input-group">
        <input
            [id]="'input-'+formControlName"
            class="form-control"
            [type]="type"
            min="{{featureDefinition.units ? '0' : ''}}"
            [formControlName]="formControlName"
        >
        <div *ngIf="featureDefinition.unit" class="input-group-append">
            {{ featureDefinition.unit[0] }}
            <span class="input-group-text">{{ featureDefinition.unit[currentLanguage] }}</span>
        </div>
    </div>
    <app-validation-error-messages [control]="form.get(formControlName)"></app-validation-error-messages>
</ng-container>