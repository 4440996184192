import { Directive, HostListener } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Platform } from '@angular/cdk/platform';

@Directive({
    selector: '[appShowTooltipOnClick]',
})
export class ShowTooltipOnClickDirective {
    constructor(private tooltip: MatTooltip, private platform: Platform) {}

    @HostListener('click') onClick() {
        if (this.platform.IOS || this.platform.ANDROID) {
            if (this.tooltip) {
                this.tooltip.show();
            }
        }
    }
}
