import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ApiConnectorService } from '../../../services/api-connector/api-connector.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { FindAccommodationStateDto } from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-state.dto';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AccommodationGroupEntity } from 'data-structures/lib/es6/entity/accommodation-group/accommodation-group.entity';
import { GroupComponentsEnum } from 'data-structures/lib/es6/dto/accommodation-group/find-accommodation-group/find-accommodation-group-request.dto';

@Component({
    selector: 'app-accommodation-group-select',
    templateUrl: './accommodation-group-select.component.html',
    styleUrls: ['./accommodation-group-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AccommodationGroupSelectComponent),
            multi: true
        }
    ]
})
export class AccommodationGroupSelectComponent implements OnInit, ControlValueAccessor {

    accommodationGroups: AccommodationGroupEntity[];
    selectedAccommodationGroups: number[];
    @Input() idKey: string = '';
    @Input() classKey: string = '';
    @Input() noneSelectedDisplayAll = false; // Wenn nichts ausgewählt ist, soll "alle Objektgruppen" angezeigt werden
    @Input() multipleAccommodationGroups = true;
    @Input() addEmptyOption = false;
    @Output() onFinish: EventEmitter<any> = new EventEmitter<any>();

    constructor(readonly apiConnector: ApiConnectorService,
                readonly authService: AuthenticationService) {
    }

    onChange: any = () => {
    };
    onTouched: any = () => {
    };

    writeValue(obj: any): void {
        this.selectedAccommodationGroups = obj;
    }

    get value() {
        return this.selectedAccommodationGroups;
    }

    set value(v) {
        this.selectedAccommodationGroups = v;
        this.onChange(this.selectedAccommodationGroups);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    async ngOnInit(): Promise<void> {
        const components: GroupComponentsEnum[] = [GroupComponentsEnum.AccommodationGroupId, GroupComponentsEnum.Name];
        const result: any = await this.apiConnector.getAccommodationGroupsByOwnerNumber(this.authService.currentUser.ownerNumber, 0, 1000, components);
        this.accommodationGroups = result.accommodationGroups;

        $('body').on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {

            // TODO: Bessere Möglichkeiten finde das der Selector oben greift falls man mehere Selectboxen auf einer Seite nutzen will und man die ID nicht mehr prüfen muss
            if (e.target.id === this.idKey) {
                this.value = this.selectedAccommodationGroups;
                this.writeValue(this.selectedAccommodationGroups);
                this.onFinish.emit(this.selectedAccommodationGroups);
            }
        });
    }

}
