import { Component, OnInit } from '@angular/core';
import { InvoiceRequestDto } from '../../../../entities/Invoices/invoiceRequestDto';
import { BackOfficeConnectorService } from '../../../../services/api-connector/back-office-connector-service';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
    invoiceRequest: InvoiceRequestDto = new InvoiceRequestDto();
    invoices = [];
    invoicesCount: number;
    commissions = [];
    commissionCount: number;
    selectedTab: string;

    constructor(readonly authenticationService: AuthenticationService,
                readonly backofficeApiConnector: BackOfficeConnectorService,
                readonly activatedRoute: ActivatedRoute) {}

    async ngOnInit() {
        await this.getInvoices();
        await this.getCommissions();

        this.activatedRoute.fragment.subscribe((fragment) => {
            // Tab setzen wenn Anker gesetzt ist
            if (!fragment) {
                this.selectedTab = 'advice';
                return;
            }

            this.selectedTab = fragment;
        });
    }

    async getInvoices() {
        this.invoices = await this.backofficeApiConnector.getInvoices(this.invoiceRequest, 'overview', this.authenticationService.currentUser.ownerNumber);
        this.invoicesCount = this.invoices[this.invoices.length - 1];
    }

    async getCommissions() {
        this.commissions = await this.backofficeApiConnector.getInvoices(this.invoiceRequest, 'commission', this.authenticationService.currentUser.ownerNumber);
        this.commissionCount = this.commissions[this.commissions.length - 1];
    }
}
