import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDuration } from 'date-fns';
import { de, da, enGB, fr, it, nl, pl, sv, es } from 'date-fns/esm/locale';
import { OfferDto } from 'data-structures/lib/es6/dto/accommodation';

@Pipe({
    name: 'daysBeforeArrival',
})
export class DaysBeforeArrivalPipe implements PipeTransform {
    locales = {
        da,
        de,
        enGB,
        fr,
        it,
        nl,
        pl,
        sv,
        es,
    };

    disallowedValues = [0, 999];

    constructor(readonly translationService: TranslateService) {}

    async transform(offer: OfferDto, ...args: any[]): Promise<string> {
        let translation: string = '';
        if (offer.daysBeforeArrival.min && !(offer.daysBeforeArrival.min in this.disallowedValues)) {
            let translationKey = 'atraveo.accommodationbundle.price.create.offer.validFor.daysBeforeArrivalFrom.prefix';
            if (offer.typeId === 'longStay') {
                translationKey = 'atraveo.accommodationbundle.price.create.offer.longTravelPeriod.longTravelPeriodLimitationsOccupancyFrom';
            }
            translation += ' ' + (await this.translationService.get(translationKey).toPromise());
            // @ts-ignore
            translation += ' ' + formatDuration({ days: offer.daysBeforeArrival.min }, { locale: this.locales[this.translationService.currentLang] });
        }

        if (offer.daysBeforeArrival.max && !(offer.daysBeforeArrival.max in this.disallowedValues)) {
            translation += ' ' + (await this.translationService.get('atraveo.accommodationbundle.price.create.offer.validFor.daysBeforeArrivalTo.prefix').toPromise());
            // @ts-ignore
            translation += ' ' + formatDuration({ days: offer.daysBeforeArrival.max }, { locale: this.locales[this.translationService.currentLang] });
        }

        if (translation && offer.typeId !== 'longStay') {
            translation += ' ' + (await this.translationService.get('atraveo.accommodationbundle.price.create.offer.validFor.daysBeforeArrival.suffix').toPromise());
        }

        return translation;
    }
}
