import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BackOfficeConnectorService } from '../../../../../services/api-connector/back-office-connector-service';
import { InvoiceRequestDto } from '../../../../../entities/Invoices/invoiceRequestDto';
import { SortEnum } from 'data-structures/lib/es6/dto/rating/owner/find-rating-sort-dto';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';

@Component({
  selector: 'app-invoices-overview',
  templateUrl: './invoices-overview.component.html',
  styleUrls: ['./invoices-overview.component.scss']
})
export class InvoicesOverviewComponent {
    @Input() invoiceRequest: InvoiceRequestDto = new InvoiceRequestDto();
    @Input() invoices = [];
    @Input() invoicesCount: number;
    @Output() getInvoices: EventEmitter<any> = new EventEmitter();
    sortDate: SortEnum = null;
    sortPrice: SortEnum = null;

    constructor(readonly authenticationService: AuthenticationService,
                readonly backofficeApiConnector: BackOfficeConnectorService) {}

    async changePage(pageNumber: number) {
        this.invoiceRequest.page = String(pageNumber);
        this.getInvoices.emit();
    }

    async refresh(event, sortType: string) {
        if (sortType === 'date') {
            this.sortDate = event;
        } else {
            this.sortPrice = event;
        }
        this.invoiceRequest.sortBy = sortType;
        this.invoiceRequest.sortTo = sortType === 'date' ? this.sortDate.toLowerCase() : this.sortPrice.toLowerCase();
        this.getInvoices.emit();
    }

    async filterInvoices() {
        this.getInvoices.emit();
    }

    async getPdf(id: string) {
        await this.backofficeApiConnector.getInvoicePdf(id, 'pdf', this.authenticationService.currentUser.ownerNumber);
    }
}
