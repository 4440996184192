<div class="accommodation-group">
    <h1>{{ 'atraveo.accommodationbundle.group.overview.title' | translate }}</h1>
    <p>{{ 'atraveo.accommodationbundle.group.overview.header' | translate }}</p>
</div>

<form>
    <div class="form-group">
        <div class="col-sm-6 search-object-group">
            <div class="col-sm-3">
                <strong>{{ 'text.filter.accommodation.group.0' | translate }}:</strong>
            </div>
            <app-search-by-group (onSearchByGroupId)="onSearchGroup($event)"></app-search-by-group>
        </div>
        <div class="col-sm-6 search-object">
            <div class="col-sm-3">
                <strong>{{ 'text.filter.accommodation.0' | translate }}:</strong>
            </div>
            <app-search-by-object (onSearchByObject)="onSearchGroup($event)"></app-search-by-object>
        </div>
    </div>
</form>

<hr />

<div class="accommodationGroup-list">
    <div *ngFor="let accommodationGroup of accommodationGroups">
        <div class="row object-group-row">
            <div class="col-sm-8">
                <h4 class="media-heading">
                    <a
                        routerLink="/accommodation-group/{{ accommodationGroup.accommodationGroupId }}"
                        [queryParams]="{ ownerid: authenticationService.currentUser.ownerNumber }"
                        routerLinkActive="active"
                    >
                        {{ accommodationGroup.accommodationGroupId }} / {{ accommodationGroup.name | translate }}

                        <ng-template [ngIf]="accommodationGroup.groupAccommodationIds.length == 1"> ({{ 'atraveo.accommodationbundle.group.number.assigned.singular' | translate }}) </ng-template>
                        <ng-template [ngIf]="!accommodationGroup.groupAccommodationIds.length || accommodationGroup.groupAccommodationIds.length > 1">
                            ({{ 'atraveo.accommodationbundle.group.number.assigned.0' | translate: { numObjects: accommodationGroup.groupAccommodationIds?.length } }})
                        </ng-template>
                    </a>
                </h4>
            </div>
            <div class="col-sm-4 media-buttons">
                <a
                    routerLink="/accommodation-group/{{ accommodationGroup.accommodationGroupId }}"
                    [queryParams]="{ ownerid: authenticationService.currentUser.ownerNumber }"
                    routerLinkActive="active"
                    class="pull-right btn--delete btn btn-atraveoTertiary btn-icon-only btn-icon--delete"
                >
                    <i class="fas fa-pencil-alt"></i>
                </a>
                <a
                    class="pull-right btn btn-atraveoTertiary btn-icon-only btn-icon--edit"
                    [attr.data-value]="accommodationGroup.accommodationGroupId"
                    (click)="openConfirmationDialog(accommodationGroup.accommodationGroupId, $event)"
                >
                    <i class="fas fa-trash-alt"></i>
                </a>
            </div>
        </div>
        <hr />
    </div>
</div>

<a class="btn btn-primary" routerLink="/accommodation-group/create" [queryParams]="{ ownerid: authenticationService.currentUser.ownerNumber }" routerLinkActive="active"
    ><i class="fas fa-plus"></i> {{ 'atraveo.accommodationbundle.group.overview.create.button' | translate }}
</a>

<div *ngFor="let accommodationGroup of accommodationGroups | paginate: { itemsPerPage: filter.take, currentPage: currentPage, totalItems: accommodationGroupsCount }"></div>

<pagination-controls class="float-right pagination-control" (pageChange)="changePage($event)" previousLabel="" nextLabel=""></pagination-controls>
