import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '../../../services/loader-service/loader.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorComponent implements OnInit, OnDestroy {
    isLoading: boolean;
    loadingSubscription: Subscription;

    constructor(readonly loaderService: LoaderService, readonly changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit() {
        this.loadingSubscription = this.loaderService.isLoading.subscribe((data) => {
            this.isLoading = data;
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnDestroy() {
        this.loadingSubscription?.unsubscribe();
    }
}
