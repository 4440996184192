<form id="contactForm">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'button.contact.owner.service' | translate }}</h5>
        <button class="close" (click)="closeDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <div class="col-sm-3">
                {{ 'text.notification.field.fromUser' | translate }}
            </div>
            <div class="col-sm-3">
                {{ authenticationService.currentUser.getPrimaryContact()?.forename }}
                {{ authenticationService.currentUser.getPrimaryContact()?.surname }}
            </div>
        </div>
        <div class="form-group row">
            <label for="receiver"
                   class="col-sm-3 col-form-label">{{ 'text.notification.field.toUser' | translate }}
            </label>
            <div class="col-sm-4">
                <select id="receiver" class="form-control" readonly="readonly" name="receiver"
                        [(ngModel)]="contactFormular.receiver" [ngModelOptions]="{standalone: true}">
                    <option value="ownerservice">{{ 'vermieterservice' | translate }}</option>
                    <!--
                  <option value="customerservice">{{ 'kundenservice' | translate }}</option>
                  <option value="customerreview">{{ 'kundenmeinung' | translate }}</option>
                  -->
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="filterObjectnumberInbox"
                   class="col-sm-3 col-form-label">{{ 'text.arrival.accommodation.0' | translate }}
            </label>
            <div class="col-sm-4">
                <app-accommodation-ajax-search (onSearch)="contactFormular.accommodationId = $event"
                                               hideLabel="true"
                                               hideIcons="true"
                                               classes="form-control"
                                               htmlId="filterObjectnumberInbox">
                </app-accommodation-ajax-search>
            </div>
        </div>
        <!--
        <div class="form-group row">
          <label for="booking"
                 class="col-sm-3 col-form-label">{{ 'text.notification.create.booking' | translate }}
          </label>
          <div class="col-sm-8">
            <select id="booking" class="form-control" name="booking"
                    [(ngModel)]="contactFormular.bookingId">
              <option value="">{{ 'text.select.empty' | translate }}</option>
              <option *ngFor="let booking of bookings" [value]="booking.bookingId">{{ booking.bookingId }}</option>
            </select>
          </div>
        </div>-->
        <div class="form-group row">
            <label for="subject"
                   class="col-sm-3 col-form-label required">{{ 'text.notification.field.subject' | translate }}
            </label>
            <div class="col-sm-8">
                <input type="text" id="subject" class="form-control"
                       placeholder="{{ 'atraveo.notificationbundle.contact-vrms.subject.vrms-default' | translate }}"
                       ngModel="" name="subject" #subject="ngModel" required
                       [(ngModel)]="contactFormular.subject" [ngModelOptions]="{standalone: true}">
            </div>
        </div>
        <div class="form-group row">
            <label for="message"
                   class="col-sm-3 col-form-label required">{{ 'text.notification.create.message' | translate }}
            </label>
            <div class="col-sm-8">
        <textarea type="text" id="message" row="10" class="form-control" ngModel="" name="message" #message="ngModel"
                  required
                  [(ngModel)]="contactFormular.text" [ngModelOptions]="{standalone: true}">
        </textarea>
            </div>
        </div>
        <div class="form-group row">
            <label for="message"
                   class="col-sm-3 col-form-label">{{ 'atraveo.backofficebundle.backoffice.info.section.arrivalFiles' | translate }}
            </label>
            <div class="col-sm-8">
                <input class="file-upload" multiple type="file" (change)="onFileSelected($event)" [(ngModel)]="contactFormular.file" [ngModelOptions]="{standalone: true}">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 col-form-label">
                {{ 'text.mandatoryfield' | translate }}
            </label>
            <div class="col-sm-3">
                <label class="checkbox-label">
                    <input type="checkbox" id="urgent" class="form-control" ngModel="" name="urgent"
                           [(ngModel)]="contactFormular.isImportant" [ngModelOptions]="{standalone: true}">
                    {{ 'text.notification.priority' | translate }}
                </label>
                <app-tooltip [additionalClassList]="'mr-2'" [text]="'info.priority' | translate"></app-tooltip>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="onSubmit()" type="submit" class="btn btn-success"><i
            class="far fa-paper-plane"></i>{{ 'button.send' | translate }}</button>
    </div>
</form>
