import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { addDays, addYears, format, subDays, subYears } from 'date-fns';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-advanced-setting-record',
    templateUrl: './advanced-setting-record.component.html',
    styleUrls: ['./advanced-setting-record.component.scss'],
})
export class AdvancedSettingRecordComponent implements OnInit, OnChanges, OnDestroy {
    @Output() deleteEvent = new EventEmitter();
    @Input() settingsControl: AbstractControl;
    @Input() isDefault: boolean = false;
    @Input() exceptionId: number;
    @Input() submitted: boolean = false;
    @Input() useDailyPrices: boolean = false;
    settingsGroup: FormGroup;
    daysShortening = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];
    minDateFrom: string;
    maxDateFrom: string;
    minDateTo: string;
    maxDateTo: string;
    settingFromSubscription: Subscription;
    settingToSubscription: Subscription;

    ngOnInit(): void {
        this.settingsGroup = this.settingsControl as FormGroup;

        if (this.settingsControl.get('from')) {
            this.settingFromSubscription = this.settingsControl.get('from').valueChanges.subscribe(() => {
                if (this.settingsControl.get('from').value) {
                    // setze das maxDateTo auf das ausgewählte Datum + 1 Tag
                    this.minDateTo = format(addDays(new Date(this.settingsGroup.get('from').value), 1), 'yyyy-MM-dd');
                    if (this.settingsControl.get('from').valid) {
                        this.settingsControl.get('to').enable();
                    } else {
                        this.settingsControl.get('to').disable();
                    }
                } else {
                    this.settingsControl.get('to').disable();
                }
            });
        }

        if (this.settingsControl.get('to')) {
            this.settingToSubscription = this.settingsControl.get('to').valueChanges.subscribe(() => {
                if (this.settingsControl.get('to').value) {
                    // setze das maxDateTo auf das ausgewählte Datum + 1 Tag
                    this.maxDateFrom = format(subDays(new Date(this.settingsGroup.get('to').value), 1), 'yyyy-MM-dd');
                }
            });
        }
    }

    ngOnDestroy() {
        this.settingFromSubscription?.unsubscribe();
        this.settingToSubscription?.unsubscribe();
    }

    ngOnChanges() {
        const minDate = subYears(new Date(), 10);
        const maxDate = addYears(new Date(), 20);

        this.minDateFrom = format(minDate, 'yyyy-MM-dd');
        this.maxDateFrom = format(maxDate, 'yyyy-MM-dd');

        this.minDateTo = format(minDate, 'yyyy-MM-dd');
        this.maxDateTo = format(maxDate, 'yyyy-MM-dd');
    }

    possibleDurations: number[] = Array.from({ length: 28 }, (_, index) => index + 1);
    possibleOccupancies: number[] = Array.from({ length: 30 }, (_, index) => index + 1);

    toggle() {
        if (this.settingsControl.disabled) {
            this.settingsControl.enable();
            this.settingsControl.get('enabled').setValue(true);
        } else {
            this.settingsControl.disable();
            this.settingsControl.get('enabled').setValue(false);
        }
    }
}
