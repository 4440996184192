import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DateService } from '../../../services/date/date.service';

@Component({
    selector: 'app-validation-error-messages',
    templateUrl: './validation-error-messages.component.html',
    styleUrl: './validation-error-messages.component.scss',
})
export class ValidationErrorMessagesComponent {
    @Input() control: AbstractControl;

    constructor(readonly translateService: TranslateService, readonly dateService: DateService) {}
}
