<div *ngIf="geoDto">
    <app-country-select idKey="{{ idKeyPrefix }}-country"
                        classKey="{{ classKey }}" isOptional="true"
                        [(ngModel)]="geoDto.countryId"
                        [ngModelOptions]="{standalone: true}"
                        (change)="changeCountry($event.target.value)"
                        [noneSelectedDisplayAll]="noneSelectedDisplayAll"
                        [isAccommodationOrGroupActive]="isAccommodationOrGroupActive">
    </app-country-select>

    <select name="region" id="{{ idKeyPrefix }}-region"
            class="{{ classKey }}"
            [(ngModel)]="geoDto.bestRegionId"
            [ngModelOptions]="{standalone: true}"
            (change)="changeRegion($event.target.value)"
            [disabled]="this.regionNames.length === 0 || isAccommodationOrGroupActive"
            [attr.data-select-all-text]= "'select.all' | translate"
            [attr.data-deselect-all-text]= "'select.none' | translate"
            [attr.data-none-selected-text]= "noneSelectedDisplayAll ? ('text.arrival.geo.path.allregions' | translate) : ('text.select.empty' | translate)"
    >
        <option *ngFor="let region of this.regionNames" [value]="region?.id">
            {{ region?.text}}
        </option>
    </select>

    <select name="city" id="{{ idKeyPrefix }}-city"
            class="{{ classKey }}"
            [(ngModel)]="geoDto.cityId"
            [ngModelOptions]="{standalone: true}"
            [disabled]="this.cityNames.length === 0 || isAccommodationOrGroupActive"
            [attr.data-select-all-text]= "'select.all' | translate"
            [attr.data-deselect-all-text]= "'select.none' | translate"
            [attr.data-none-selected-text]= "noneSelectedDisplayAll ? ('text.arrival.geo.path.allcities' | translate) : ('text.select.empty' | translate)"
    >
        <option *ngFor="let city of this.cityNames" [value]="city?.id">
            {{ city?.text }}
        </option>
    </select>
</div>
