import { Component, HostListener, Input, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { ExtendedAccommodationEntity } from '../../../../../entities/extendedAccommodationEntity';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { StateDto } from 'data-structures/lib/es6/dto/accommodation';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../../global/confirmation-dialog/confirmation-dialog.service';
import * as Sentry from '@sentry/angular-ivy';
import { NotificationService } from '../../../../../services/notification/notification.service';
import { ErrorCountService } from '../../../../../services/accommodation/error-count.service';
import { AccommodationCountService } from '../../../../../services/accommodation/accommodation-count.service';
import { TransferDataComponentsEnum, TransferDataDto } from 'data-structures/lib/es6/dto/accommodation/transfer-data.dto';
import { AccommodationQualifyService } from '../../../../../services/accommodation/accommodation-qualify.service';
import { AccommodationLoadUpdateService } from '../../../../../services/accommodation/accommodation-load-update-service';
import { ErrorsComponent } from '../errors/errors.component';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../../../services/dialog.service';

@Component({
    selector: 'app-accommodation-header',
    templateUrl: './accommodation-header.component.html',
    styleUrls: ['./accommodation-header.component.scss'],
})
export class AccommodationHeaderComponent implements OnInit, OnDestroy {
    accommodation: ExtendedAccommodationEntity;
    accommodationSubscription: Subscription;
    @ViewChild(ErrorsComponent) errorsComponent: ErrorsComponent;
    accommodationCount: number;
    @Input() selectedTab: string;
    accommodations: ExtendedAccommodationEntity[];

    selectedImageOrder: number = 0;

    copyAccommodationIds: number[] = [];
    selectedComponents = {
        priceRules: false,
        additionalCosts: false,
        offers: false,
        features: false,
        position: false,
    };
    accommodationCanBeQualified: boolean = false;
    showPublicationButton: boolean = false;
    @ViewChild('copyModal')
    copyModal: TemplateRef<any>;
    constructor(
        readonly authenticationService: AuthenticationService,
        readonly apiConnectorService: ApiConnectorService,
        readonly route: ActivatedRoute,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly router: Router,
        readonly notificationService: NotificationService,
        readonly errorCountService: ErrorCountService,
        readonly accommodationCountService: AccommodationCountService,
        readonly accommodationQualifyService: AccommodationQualifyService,
        readonly accommodationUpdateService: AccommodationLoadUpdateService,
        readonly ngZone: NgZone,
        readonly dialogService: DialogService,
    ) {}

    async ngOnInit() {
        this.accommodationSubscription = this.apiConnectorService.activeAccommodation$.subscribe(async (accommodation) => {
            if (accommodation) {
                this.accommodation = cloneDeep(accommodation);

                if (this.accommodation.accommodationId) {
                    if (!this.accommodation.state?.qualified && this.authenticationService.currentUser.isEmployee()) {
                        this.accommodationCanBeQualified = await this.apiConnectorService.getAccommodationCanBeQualified(this.accommodation.accommodationId);
                    }
                    this.showPublicationButton = !this.accommodation?.state?.imported && this.accommodation?.main?.checkResultProgressBarTotal && this.accommodation?.state?.qualified === false;
                }
            }
        });
        this.accommodationCount = await this.apiConnectorService.getAccommodationCount(this.authenticationService.currentUser);

        this.route.url.subscribe(async (params) => {
            this.toggleTabVisibility();

            if (params?.length >= 3) {
                if (params.find((param) => param.path === 'image')) {
                    this.selectedImageOrder = Number(params[params.length - 1].path);
                }
            } else {
                this.selectedImageOrder = 0;
            }
        });
    }

    ngOnDestroy() {
        this.accommodationSubscription?.unsubscribe();
    }
    openCopyObjectData() {
        this.dialogService.openDialog(this.copyModal, { dialogWidth: this.dialogService.dialogWidth.M, panelClass: 'overflow-visible' }, {});
    }
    async toggleDeactivatedByOwnerService() {
        if (!this.accommodation.state) {
            this.accommodation.state = new StateDto();
        }

        this.accommodation.state.deactivatedByOwnerService = !this.accommodation.state.deactivatedByOwnerService;
        await this.saveAccommodation();
    }

    openConfirmationDialog(event) {
        this.confirmationDialogService
            .confirm('atraveo.accommodationbundle.accommodation.delete.0', 'confirm.content.delete.0')
            .then(async (confirmed) => {
                if (confirmed) {
                    await this.deleteAccommodation();
                    event.target.parentNode.parentNode.remove();
                }
            })
            .catch(() => undefined);
    }

    async deleteAccommodation() {
        try {
            if (!this.accommodation.state) {
                this.accommodation.state = new StateDto();
            }
            this.accommodation.state.deleted = true;
            await this.apiConnectorService.deleteAccommodation(this.accommodation);
            await this.errorCountService.getAccommodationErrorCount();
            await this.accommodationCountService.getAccommodationCount();
            await this.notificationService.add('text.flashbag.accommodation.deleted', 'success');
            await this.router.navigateByUrl('accommodations');
        } catch (e) {
            Sentry.captureException(e);
            await this.notificationService.add('form.save.failure', 'danger');
        }
    }

    openQualifyAccommodationConfirmationDialog() {
        this.confirmationDialogService
            .confirm('atraveo.accommodationbundle.qualify.confirm.title', 'atraveo.accommodationbundle.qualify.confirm.content')
            .then(async (confirmed) => {
                if (confirmed) {
                    await this.accommodationQualifyService.qualifyAccommodation(this.accommodation).then(async () => {
                        this.accommodationCanBeQualified = false;
                    });
                }
            })
            .catch(() => undefined);
    }

    async copyAccommodationData() {
        const transferDto = new TransferDataDto();
        transferDto.sourceAccommodationId = Number(this.accommodation.accommodationId);
        transferDto.targetAccommodationIds = this.copyAccommodationIds;
        transferDto.components = [];

        for (const [key, value] of Object.entries(this.selectedComponents)) {
            if (value) {
                transferDto.components.push(key as TransferDataComponentsEnum);
            }
        }

        await this.apiConnectorService.transferAccommodationData(transferDto);
        this.dialogService.closeDialog();
    }

    async restoreDeletedAccommodation() {
        this.accommodation.state.deleted = false;
        await this.apiConnectorService.saveAccommodation(this.accommodation);
    }

    async saveAccommodation() {
        return this.apiConnectorService.saveAccommodation(this.accommodation);
    }

    private toggleTabVisibility() {
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                const accommodationLink = document.querySelector('ul.desktop-tab-navigation .accommodation-link') as HTMLElement;
                const positionLink = document.querySelector('ul.desktop-tab-navigation .position-link') as HTMLElement;
                const imagesOverviewLink = document.querySelector('ul.desktop-tab-navigation .images-link') as HTMLElement;
                const imageDeepLink = document.querySelector('ul.desktop-tab-navigation .image-deep-link') as HTMLElement;

                if (accommodationLink?.classList?.contains('active')) {
                    accommodationLink?.classList?.remove('hide');
                    positionLink?.classList?.add('hide');
                }

                if (positionLink?.classList?.contains('active')) {
                    positionLink?.classList?.remove('hide');
                    accommodationLink?.classList?.add('hide');
                }

                if (!positionLink?.classList?.contains('active') && !accommodationLink?.classList?.contains('active')) {
                    accommodationLink?.classList?.add('hide');
                    positionLink?.classList?.remove('hide');
                }

                if (imageDeepLink?.classList?.contains('active')) {
                    imagesOverviewLink?.classList?.add('hide');
                    imageDeepLink?.classList?.remove('hide');
                }

                if (imagesOverviewLink?.classList.contains('active')) {
                    imagesOverviewLink?.classList?.remove('hide');
                    imageDeepLink.classList.add('hide');
                }

                if (!imagesOverviewLink?.classList?.contains('active') && !imageDeepLink?.classList?.contains('active')) {
                    imageDeepLink?.classList?.add('hide');
                    imagesOverviewLink?.classList?.remove('hide');
                }
            }, 200);
        });
    }

    @HostListener('window:load', ['$event'])
    onWindowLoad(event: Event) {
        this.toggleTabVisibility();
    }
}
