import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../../entities/extendedAccommodationEntity';
import { ExtendedPriceRule, PriceTypes } from '../../../../../../entities/PriceRule/extended-price-rule';
import { ApiConnectorService } from '../../../../../../services/api-connector/api-connector.service';
import { SortEnum } from 'data-structures/lib/es6/dto/rating/owner/find-rating-sort-dto';
import { ConfirmationDialogService } from '../../../../../global/confirmation-dialog/confirmation-dialog.service';
import { DateService } from '../../../../../../services/date/date.service';
import { NotificationService } from '../../../../../../services/notification/notification.service';
import { EditPriceComponent } from '../edit-price/edit-price.component';
import { cloneDeep } from 'lodash';
import { SeasonsDto } from 'data-structures/lib/es6/dto/season/put-season/seasons.dto';
import { TravelPeriodService } from '../../../../../../services/travel-period/travel-period.service';
import { AuthenticationService } from '../../../../../../services/authentication/authentication.service';
import { OwnerCurrencyEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../../../../../services/dialog.service';
import { PriceDialogComponent } from '../../../../../global/dialogs/price-dialog/price-dialog.component';

@Component({
    selector: 'app-price-table',
    templateUrl: './price-table.component.html',
    styleUrls: ['./price-table.component.scss'],
})
export class PriceTableComponent implements OnInit, OnDestroy {
    @Input() priceType: PriceTypes;
    @ViewChild(EditPriceComponent) editPrice: EditPriceComponent;
    @ViewChild('priceTableForm') priceTableForm: ElementRef;
    accommodation: ExtendedAccommodationEntity;
    sortPrice: SortEnum | undefined;
    sortDate: SortEnum | undefined;
    deleteElements: number[] = [];
    deleteMultipleInProgress: boolean = false;
    hasPricePerAdditionalPerson: boolean = false;
    hasPerPersonPriceForPriceType: boolean = false;
    priceRules: ExtendedPriceRule[];
    submitted: boolean = false;
    dateOverlap: string = '';
    currentPrice: ExtendedPriceRule;
    seasons: SeasonsDto = new SeasonsDto();
    ownerCurrency: OwnerCurrencyEnum = this.authService.currentUser.getOwnerCurrency();
    pricesWithMaxPersons: any[] = [];
    accommodationGroupSubscription: Subscription;

    maxPersons: number[] = [];
    keyToTranslation: any = {
        [PriceTypes.Day]: 0,
        [PriceTypes.Week]: 1,
        [PriceTypes.Midweek]: 2,
        [PriceTypes.ShortWeekend]: 3,
        [PriceTypes.Weekend]: 4,
    };

    travelPeriodService: TravelPeriodService = new TravelPeriodService();

    constructor(
        readonly apiConnectorService: ApiConnectorService,
        readonly translateService: TranslateService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly dateService: DateService,
        readonly notificationService: NotificationService,
        readonly authService: AuthenticationService,
        readonly dialogService: DialogService,
    ) {}

    setCurrentPrice(priceRule) {
        this.currentPrice = cloneDeep(priceRule);
        this.dialogService.openDialog(
            PriceDialogComponent,
            { dialogWidth: this.dialogService.dialogWidth.L },
            {
                currentPrice: this.currentPrice,
                submitted: this.submitted,
                dateOverlap: this.dateOverlap,
                accommodation: this.accommodation,
            },
        );
    }

    ngOnInit() {
        this.accommodationGroupSubscription = this.apiConnectorService.activeAccommodation$.subscribe(async (accommodation) => {
            if (accommodation) {
                this.accommodation = cloneDeep(accommodation);
                this.setPriceInformation();
            }
        });
        this.sortDate = SortEnum.Asc;
    }

    ngOnDestroy() {
        this.accommodationGroupSubscription?.unsubscribe();
    }

    async setPriceInformation() {
        this.pricesWithMaxPersons = [];
        this.maxPersons = this.accommodation.getDifferentMaxPersonsForPriceType(this.priceType).sort((a, b) => a - b);

        this.hasPricePerAdditionalPerson = this.accommodation.hasPricePerAdditionalPerson(this.priceType);
        this.hasPerPersonPriceForPriceType = this.accommodation.hasPerPersonPriceForPriceType(this.priceType);

        if (!this.seasons.seasons?.length) {
            this.seasons = await this.apiConnectorService.getSeasons();
        }

        this.priceRules = this.accommodation.getPriceType(this.priceType, this.sortPrice, this.sortDate);
        for (const priceRule of this.priceRules) {
            const pricesMaxPersonTemp = [];
            if (this.maxPersons.length) {
                for (const maxPerson of this.maxPersons) {
                    const priceRuleWithMaxPerson = priceRule.prices.find((price) => price.maxPersons === maxPerson);
                    if (priceRuleWithMaxPerson) {
                        pricesMaxPersonTemp.push(priceRuleWithMaxPerson.price);
                    } else {
                        pricesMaxPersonTemp.push(0);
                    }
                }
            }
            this.pricesWithMaxPersons.push(pricesMaxPersonTemp);
        }
    }

    abortDeletePrice = () => {
        this.deleteElements = [];
        this.deleteMultipleInProgress = false;
    };

    async deletePrice() {
        const priceElements = this.accommodation.priceRules;

        for (let i = priceElements.length - 1; i >= 0; i--) {
            if (this.deleteElements.indexOf(this.accommodation.priceRules[i].id) !== -1) {
                this.accommodation.priceRules.splice(Number(i), 1);
            }
        }
        if (this.deleteElements.length) {
            await this.apiConnectorService.saveAccommodation(this.accommodation);
        }
        this.deleteMultipleInProgress = false;
        this.deleteElements = [];
    }

    async deleteMultiple() {
        this.deletePrice();
    }

    addOrRemoveDeleteElement(id: number) {
        if (this.deleteMultipleInProgress) {
            if (this.deleteElements.includes(id)) {
                const index = this.deleteElements.indexOf(id);
                if (index !== -1) {
                    this.deleteElements.splice(index);
                }
            } else {
                this.deleteElements.push(id);
            }
        }
    }

    openConfirmationDialog() {
        this.confirmationDialogService
            .confirm('atraveo.accommodationbundle.price.delete.0', 'confirm.content.delete.0')
            .then(async (confirmed) => {
                if (confirmed) {
                    this.deletePrice();
                } else {
                    this.abortDeletePrice();
                }
            })
            .catch(() => undefined);
    }

    trackByFn(index, item) {
        return item.id;
    }
}
