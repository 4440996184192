import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { ApiConnectorService } from '../../../../services/api-connector/api-connector.service';
import { StreetAdressDto } from 'data-structures/lib/es6/dto/common/street-adress.dto';
import { NotificationService } from '../../../../services/notification/notification.service';
import { ExtendedBankAccountEntity } from '../../../../entities/extended-bank-account-entity';
import { ConfirmationDialogService } from '../../../global/confirmation-dialog/confirmation-dialog.service';
import { plainToInstance } from 'class-transformer';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { OwnerConnectorService } from '../../../../services/api-connector/owner-connector.service';
import { isValidIBAN } from 'ibantools';
import { isEqual } from 'lodash';

@Component({
    selector: 'app-bank-account',
    templateUrl: './bank-account.component.html',
    styleUrls: ['./bank-account.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BankAccountComponent),
            multi: true,
        },
    ],
})
export class BankAccountComponent implements OnInit, OnChanges {
    @Input() forAccommodationOrUser: 'accommodation' | 'user' = 'user';
    @Input() bankAccount: ExtendedBankAccountEntity;
    @Input() bankAccountId: string;
    @Input() default = false; // Ist dies das Hauptkonto?
    @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
    _selectedAccommodations: number[];
    isAdmin: boolean = this.authService.currentUser.isAdmin();
    bankAccountForm: FormGroup;
    editBankAccount: ExtendedBankAccountEntity;
    initialBankAccount: any = false;

    constructor(
        readonly apiConnector: ApiConnectorService,
        readonly notificationService: NotificationService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly authService: AuthenticationService,
        readonly ownerConnectorService: OwnerConnectorService,
    ) {
        this.bankAccountForm = new FormGroup({
            accommodations: new FormControl(''),
            bankCountryISOCode: new FormControl('', Validators.required),
            accountHolder: new FormControl('', Validators.required),
            iban: new FormControl('', Validators.required),
            bic: new FormControl('', Validators.required),
            accountNumber: new FormControl(''),
            routingNumber: new FormControl(''),
            accountHolderStreetAndNumber: new FormControl(''),
            accountHolderZip: new FormControl(''),
            accountHolderCity: new FormControl(''),
            accountHolderCountryISOCode: new FormControl(''),
            bankName: new FormControl(''),
            bankStreetAndNumber: new FormControl(''),
            bankZip: new FormControl(''),
            bankCity: new FormControl(''),
            bankAddressCountryISOCode: new FormControl(''),
        });
    }

    get selectedAccommodations(): any {
        return this._selectedAccommodations;
    }

    set selectedAccommodations(value: any) {
        this._selectedAccommodations = value;
        this.bankAccountForm.get('accommodations').setValue(value);
        this.bankAccountForm.get('accommodations').updateValueAndValidity();
    }

    ngOnInit(): void {
        this.ngOnChanges();
    }

    ngOnChanges() {
        this.isAdmin = this.authService.currentUser.isAdmin();
        this.initFields();
        this.initForm();
    }

    initFields() {
        this.editBankAccount = plainToInstance(ExtendedBankAccountEntity, this.bankAccount);
        if (!this.editBankAccount) {
            this.editBankAccount = new ExtendedBankAccountEntity();
        }

        if (!this.editBankAccount.accountHolderAddress) {
            this.editBankAccount.accountHolderAddress = new StreetAdressDto();
            if (!this.editBankAccount.accountHolderAddress.countryISOCode) {
                this.editBankAccount.accountHolderAddress.countryISOCode = '';
            }
        }

        if (!this.editBankAccount.bankAddress) {
            this.editBankAccount.bankAddress = new StreetAdressDto();
            if (!this.editBankAccount.bankAddress.countryISOCode) {
                this.editBankAccount.bankAddress.countryISOCode = '';
            }
        }

        if (!this.editBankAccount.bankCountryISOCode) {
            this.editBankAccount.bankCountryISOCode = '';
        }

        if (this.editBankAccount.accommodationIds) {
            this.selectedAccommodations = this.editBankAccount.accommodationIds;
        }
    }

    initForm() {
        if (this.default) {
            this.bankAccountForm.get('accommodations').disable();
        } else {
            this.bankAccountForm.get('accommodations').enable();
            this.bankAccountForm.get('accommodations').setValidators(Validators.required);
        }

        this.bankAccountForm.patchValue({
            accommodations: this.selectedAccommodations,
            bankCountryISOCode: this.editBankAccount.bankCountryISOCode,
            accountHolder: this.editBankAccount.accountHolder,
            iban: this.editBankAccount.iban,
            bic: this.editBankAccount.bic,
            accountNumber: this.editBankAccount.accountNumber,
            routingNumber: this.editBankAccount.routingNumber,
            accountHolderStreetAndNumber: this.editBankAccount.accountHolderAddress.streetAndNumber,
            accountHolderZip: this.editBankAccount.accountHolderAddress.zip,
            accountHolderCity: this.editBankAccount.accountHolderAddress.city,
            accountHolderCountryISOCode: this.editBankAccount.accountHolderAddress.countryISOCode,
            bankName: this.editBankAccount.bankName,
            bankStreetAndNumber: this.editBankAccount.bankAddress.streetAndNumber,
            bankZip: this.editBankAccount.bankAddress.zip,
            bankCity: this.editBankAccount.bankAddress.city,
            bankAddressCountryISOCode: this.editBankAccount.bankAddress.countryISOCode,
        });

        this.isSepaCountryCheck();

        this.bankAccountForm.valueChanges.subscribe((values) => {
            if (!this.initialBankAccount) {
                this.initialBankAccount = values;
            }
            this.editBankAccount.bankCountryISOCode = this.bankAccountForm.get('bankCountryISOCode').value;
            this.isSepaCountryCheck();
        });

        if (this.isAdmin) {
            this.bankAccountForm.disable();
        }
    }

    isSepaCountryCheck() {
        if (this.editBankAccount.isSEPACountry()) {
            this.bankAccountForm.get('iban').setValidators([Validators.required, this.ibanValidator(), this.duplicateAccountValidator()]);
            this.bankAccountForm.get('accountHolderStreetAndNumber').clearValidators();
            this.bankAccountForm.get('accountHolderZip').clearValidators();
            this.bankAccountForm.get('accountHolderCity').clearValidators();
            this.bankAccountForm.get('accountHolderCountryISOCode').clearValidators();
            this.bankAccountForm.get('bankName').clearValidators();
            this.bankAccountForm.get('bankStreetAndNumber').clearValidators();
            this.bankAccountForm.get('bankZip').clearValidators();
            this.bankAccountForm.get('bankCity').clearValidators();
            this.bankAccountForm.get('bankAddressCountryISOCode').clearValidators();
        } else {
            this.bankAccountForm.get('iban').setValidators([this.ibanValidator(), this.duplicateAccountValidator()]);
            this.bankAccountForm.get('accountHolderStreetAndNumber').setValidators([Validators.required]);
            this.bankAccountForm.get('accountHolderZip').setValidators([Validators.required]);
            this.bankAccountForm.get('accountHolderCity').setValidators([Validators.required]);
            this.bankAccountForm.get('accountHolderCountryISOCode').setValidators([Validators.required]);
            this.bankAccountForm.get('bankName').setValidators([Validators.required]);
            this.bankAccountForm.get('bankStreetAndNumber').setValidators([Validators.required]);
            this.bankAccountForm.get('bankZip').setValidators([Validators.required]);
            this.bankAccountForm.get('bankCity').setValidators([Validators.required]);
            this.bankAccountForm.get('bankAddressCountryISOCode').setValidators([Validators.required]);
        }
        this.bankAccountForm.get('iban').updateValueAndValidity({ emitEvent: false });
        this.bankAccountForm.get('accountHolderStreetAndNumber').updateValueAndValidity({ emitEvent: false });
        this.bankAccountForm.get('accountHolderZip').updateValueAndValidity({ emitEvent: false });
        this.bankAccountForm.get('accountHolderCity').updateValueAndValidity({ emitEvent: false });
        this.bankAccountForm.get('accountHolderCountryISOCode').updateValueAndValidity({ emitEvent: false });
        this.bankAccountForm.get('bankName').updateValueAndValidity({ emitEvent: false });
        this.bankAccountForm.get('bankStreetAndNumber').updateValueAndValidity({ emitEvent: false });
        this.bankAccountForm.get('bankZip').updateValueAndValidity({ emitEvent: false });
        this.bankAccountForm.get('bankCity').updateValueAndValidity({ emitEvent: false });
        this.bankAccountForm.get('bankAddressCountryISOCode').updateValueAndValidity({ emitEvent: false });
    }

    submitForm() {
        if (this.bankAccountForm.valid) {
            this.save();
        } else {
            this.bankAccountForm.markAllAsTouched();
        }
    }

    ibanValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const iban = control.value;
            if (!iban) {
                return null;
            }
            if (!isValidIBAN(iban.replace(/\s+/g, ''))) {
                return {
                    iban: true,
                };
            }
            return null;
        };
    }

    duplicateAccountValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const iban = control.value;

            if (!iban) {
                return null;
            }

            const ibanList = [];

            for (const [bankAccountId, bankAccount] of Object.entries(this.authService.currentUser.payment?.bankAccounts)) {
                if (bankAccountId !== this.bankAccountId) {
                    if (bankAccount.iban) {
                        ibanList.push(bankAccount.iban);
                    }
                }
            }
            if (ibanList.includes(iban)) {
                return {
                    duplicateIban: true,
                };
            }
            return null;
        };
    }

    async save(): Promise<void> {
        if (!this.selectedAccommodations) {
            this.selectedAccommodations = [];
        }

        this.editBankAccount.accommodationIds = this.selectedAccommodations;
        this.editBankAccount.accountHolder = this.bankAccountForm.get('accountHolder').value;
        this.editBankAccount.iban = this.bankAccountForm.get('iban').value;

        this.editBankAccount.bic = this.bankAccountForm.get('bic').value;
        this.editBankAccount.accountNumber = this.bankAccountForm.get('accountNumber').value;
        this.editBankAccount.routingNumber = this.bankAccountForm.get('routingNumber').value;

        this.editBankAccount.bankName = this.bankAccountForm.get('bankName').value;
        this.editBankAccount.bankAddress = new StreetAdressDto();
        this.editBankAccount.bankAddress.streetAndNumber = this.bankAccountForm.get('bankStreetAndNumber').value;
        this.editBankAccount.bankAddress.zip = this.bankAccountForm.get('bankZip').value;
        this.editBankAccount.bankAddress.city = this.bankAccountForm.get('bankCity').value;
        this.editBankAccount.bankAddress.countryISOCode = this.bankAccountForm.get('bankAddressCountryISOCode').value;

        this.editBankAccount.accountHolderAddress = new StreetAdressDto();
        this.editBankAccount.accountHolderAddress.streetAndNumber = this.bankAccountForm.get('accountHolderStreetAndNumber').value;
        this.editBankAccount.accountHolderAddress.zip = this.bankAccountForm.get('accountHolderZip').value;
        this.editBankAccount.accountHolderAddress.city = this.bankAccountForm.get('accountHolderCity').value;
        this.editBankAccount.accountHolderAddress.countryISOCode = this.bankAccountForm.get('accountHolderCountryISOCode').value;

        if (!isEqual(this.initialBankAccount, this.bankAccountForm.value)) {
            delete this.editBankAccount.validBic;
            delete this.editBankAccount.validIban;
        }

        this.onSave.emit(this.editBankAccount);
    }
}
