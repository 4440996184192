<form id="arrival-form" name="arrival-form">
    <h1>{{ 'text.arrival.title' | translate }}</h1>

    <h2 class="mt-5">{{ 'text.arrival.information.heading' | translate }}</h2>

    <h3 class="mt-4">{{ 'text.arrival.information.bookings' | translate }}</h3>

    <div *ngIf="arrivalInformation">
        <app-conditions-select [(ngModel)]="arrivalInformation.conditions" [multipleAccommodationGroups]="false" [condition]="arrivalInformation.conditions" [ngModelOptions]="{standalone: true}"></app-conditions-select>
    </div>

    <h3 class="mt-4">{{ 'text.arrival.information.contact' | translate }}</h3>
    <input [checked]="!contactPersonSelected" type="radio" name="contactType" required="required" [value]="true" (click)="clearContactPerson()" id="contact-person"> <label for="contact-person"> {{ 'text.arrival.contact.type.self' | translate }}</label><br>
    <ng-container *ngIf="contactsLength > 0 &&
                        (
                        (!authService.currentUser.isImported() || (authService.currentUser.isImported() && $any(authService.currentUser.features)?.importHow !== 'VM-SST' && (this.arrivalInformation.contactPerson || this.clonedContactPerson)))
                     || (authService.currentUser.isImported() && $any(authService.currentUser.features)?.importHow === 'VM-SST' && this.contactsLength)
                        )
    ">
        <input [checked]="contactPersonSelected" type="radio" name="contactType" required="required" [value]="false" (click)="setContactPerson()" id="contact-type"> <label for="contact-type">{{ 'tab.contact.person' | translate }}:</label>
        <!-- Importe über VM-SST liefern KEINE Anreiseinformationen mit -->
        <ng-container *ngIf='authService.currentUser.isImported() && $any(authService.currentUser.features)?.importHow !== "VM-SST"; else notImported'>
            <span *ngIf='arrivalInformation.contactPerson || clonedContactPerson'>
                {{ (clonedContactPerson ?? arrivalInformation.contactPerson).forename}}
                {{ (clonedContactPerson ?? arrivalInformation.contactPerson).surname }}
            </span>
        </ng-container>
        <ng-template #notImported>
            <select [(ngModel)]="arrivalInformation.contactPersonId" (ngModelChange)='arrivalInformation.contactPerson = null' name="contact-person" [ngModelOptions]="{standalone: true}">
                <ng-container *ngFor="let contactId of contactList; let i = index">
                    <option *ngIf="contactId != this.authService.currentUser.addressBook?.primaryId" value="{{ contactId }}">
                        {{ this.authService.currentUser.addressBook.contacts[contactId]?.forename }} {{ this.authService.currentUser.addressBook.contacts[contactId]?.surname }}
                    </option>
                </ng-container>
            </select>
        </ng-template>
        <app-tooltip data-placement="right" [additionalClassList]="'mr-2'" [text]="'info.arrival_contacttype' | translate"></app-tooltip>
    </ng-container>

    <h3 class="mt-4">{{ 'text.arrival.information.customer.arrival' | translate}}
        <app-tooltip [additionalClassList]="'mr-2'" [text]="'info.zeitwichtig' | translate"></app-tooltip>
    </h3>

    <div *ngIf="arrivalInformation?.arrival">
        {{ 'text.arrival.arrival' | translate }}
        {{ 'text.arrival.from' | translate }}
        <app-hour-select [(hour)]="arrivalInformation.arrival.from" [required]="arrivalRequired" (change)="arrivalByArrangement = false; this.arrivalRequired = true"></app-hour-select>
        {{ 'text.arrival.to' | translate }}
        <app-hour-select [(hour)]="arrivalInformation.arrival.to" [required]="arrivalRequired" (change)="arrivalByArrangement = false; this.arrivalRequired = true"></app-hour-select>
        {{ 'text.arrival.clock' | translate }}
        {{ 'text.arrival.or' | translate }}
        <input type="checkbox" name="arrival-by-arrangment" id="arrival-by-arrangement" (change)="setArrivalByArrangement($event)" [checked]="arrivalByArrangement">
        <label for="arrival-by-arrangement">{{ 'text.arrival.arrangement' | translate }}</label>
    </div>

    <div *ngIf="this.arrivalRequired && !this.arrivalInformation.arrival.from && !this.arrivalInformation.arrival.to" class="error">
        {{ 'arrival.validation.arrival.time' | translate }}
    </div>

    <div *ngIf="this.arrivalRequired && this.arrivalInformation.arrival.from > this.arrivalInformation.arrival.to" class="error">
        {{ 'arrival.validation.check.times' | translate }}
    </div>

    <div *ngIf="arrivalInformation?.departure">
        {{ 'text.arrival.departure' | translate }}
        {{ 'text.arrival.from' | translate }}
        <app-hour-select [(hour)]="arrivalInformation.departure.from" [required]="departureRequired" (change)="departureByArrangement = false; this.departureRequired = true"></app-hour-select>
        {{ 'text.arrival.to' | translate }}
        <app-hour-select [(hour)]="arrivalInformation.departure.to" [required]="departureRequired" (change)="departureByArrangement = false; this.departureRequired = true"></app-hour-select>
        {{ 'text.arrival.clock' | translate }}
        {{ 'text.arrival.or' | translate }}
        <input type="checkbox" name="departure-by-arrangement" id="departure-by-arrangement" (change)="setDepartureByArrangement($event)" [checked]="departureByArrangement">
        <label for="departure-by-arrangement">{{ 'text.arrival.arrangement' | translate }}</label>
    </div>

    <div *ngIf="this.departureRequired && !this.arrivalInformation.departure.from && !this.arrivalInformation.departure.to" class="error">
        {{ 'arrival.validation.departure.time' | translate }}
    </div>

    <div *ngIf="this.departureRequired && this.arrivalInformation.departure.from > this.arrivalInformation.departure.to" class="error">
        {{ 'arrival.validation.check.times' | translate }}
    </div>

    <h3 class="mt-4">{{ 'text.arrival.information.key' | translate }}</h3>
    <div *ngIf="arrivalInformation">
        <input [(ngModel)]="arrivalInformation.keyHandover.type" type="radio" name="key-handover" value="ACCOMMODATION" required="required"
               id="key-object"> <label for="key-object">{{ 'text.arrival.key.transfer.accommodation' | translate }}</label><br>
        <input [(ngModel)]="arrivalInformation.keyHandover.type" type="radio" name="key-handover" value="CONTACT_PERSON" required="required"
               id="key-contact"> <label for="key-contact">{{ 'text.arrival.key.transfer.city' | translate }}</label><br>
        <input [(ngModel)]="arrivalInformation.keyHandover.type" type="radio" name="key-handover" value="POST" required="required"
               id="key-mail"> <label for="key-mail">{{ 'text.arrival.key.transfer.post' | translate }}</label><br>
    </div>

    <div *ngIf="arrivalInformation">
        <app-language-dto-form [(content)]="arrivalInformation.keyHandover.descriptions"
                               infoKey="info.schluesseltextsprache"></app-language-dto-form>
        <input type="checkbox" id="should-contact" [(ngModel)]="arrivalInformation.keyHandover.shouldContactOwner" name="should-contact"> <label for="should-contact">{{ 'text.arrival.request.contact' | translate }}</label>
    </div>

    <h3 class="mt-3">{{ 'text.arrival.information.direction' | translate }}</h3>
    <div *ngIf="arrivalInformation">
        <app-language-dto-form [(content)]="arrivalInformation.directionsDescriptions"
                               infoKey="info.anreisetextsprache"></app-language-dto-form>
    </div>

    <h3 class="mt-3">{{ 'text.arrival.direction.image' | translate}}</h3>

    <p *ngIf="!arrivalInformation?.id">
        {{ 'arrivalinformation.save-before-upload' | translate }}
    </p>
    <input [disabled]="!arrivalInformation?.id" type="file" (change)="uploadFiles($event)" multiple="multiple">

    <div class="mt-5">
        <ul *ngIf="arrivalInformation?.files?.length">
            <li *ngFor="let file of arrivalInformation.files" class="pt-2">
                <ng-container *ngIf="file.displayUrl">
                    <a href="{{ file.displayUrl }}" target="_blank">{{ file.originalName || file.localUrl || file.remoteUrl }}</a>
                </ng-container>
                <ng-container *ngIf="!file.displayUrl">
                    {{ file.originalName || file.localUrl || file.remoteUrl }}
                </ng-container>

                <a (click)="deleteFile(file)" class="btn btn-link"><i class="fa fa-trash-alt fa-lg"></i></a>
            </li>
        </ul>
    </div>
</form>

<div class="error mt-5">
    {{ errorMessage | translate }}
</div>

<div id="delete-image-title" class="hide">
    <p>{{ 'confirm.title.delete' | translate }}</p>
</div>

<div id="delete-image-popover" class="hide">
    <p>{{ 'confirm.content.delete.0' | translate }}</p>
    <div class="btn-group">
        <div class="btn btn-secondary btn-delete">{{ 'text.marketing.teaser.yes' | translate }}</div>
        <div class="btn btn-primary btn-close">{{ 'atraveo.accommodationbundle.description.existAtOtherProvider.choice1' | translate }}</div>
    </div>
</div>
