<div class="form">
    <div class="row mt-3" *ngIf="accommodationOrGroup?.isGroup">
        <div class="col-sm-12">
            <label for="input-name" class="col-form-label required"><strong>{{ 'atraveo.accommodationbundle.group.description.ownName.label' | translate }}</strong></label>
        </div>
        <div class="col-sm-5">
            <input id="input-name"
                   class="form-control"
                   type="text"
                   name="input-name"
                   placeholder="{{ 'text.filter.accommodation.group.0' | translate }} 148749"
                   [(ngModel)]="accommodationOrGroup.name"
                   [ngModelOptions]="{standalone: true}"
                   required>
        </div>
    </div>

    <p *ngIf="!accommodationOrGroup?.isGroup" class="mt-3" [innerHTML]="'atraveo.accommodationbundle.description.additional.text' | translate"></p>
    <div *ngIf="accommodationOrGroup?.isGroup" class="group-description" [innerHTML]="'atraveo.accommodationbundle.group.description.additional.text' | translate"></div>

    <div class="row mt-3">
        <div class="col-sm-12">
            <p>{{ 'atraveo.accommodationbundle.description.locale.label' | translate }}</p>
        </div>
        <div class="col-sm-6">
            <select [(ngModel)]="this.selectedLanguage" [ngModelOptions]="{standalone: true}" name="select-name" class="form-control custom-select">
                <option *ngFor="let language of this.allowedLanguages" value="{{ language }}">
                    <strong *ngIf="getDescriptionLanguages().includes(language)">{{ 'intl.language.name.' + language | translate }}</strong>
                    <span *ngIf="!getDescriptionLanguages().includes(language)">{{ 'intl.language.name.' + language | translate }} {{ 'atraveo.accommodationbundle.description.form.option.language.empty' | translate  }}</span>
                </option>
            </select>
        </div>
    </div>

    <div class="accommodation-description-tab"  *ngIf="accommodationOrGroup">
        <ng-container *ngFor="let type of this.textAreas">
            <div *ngIf="type === 'caption' && this.selectedLanguage" class="row mt-3">
                <div class="col-md-12">
                    <label for="title"> {{ 'atraveo.accommodationbundle.group.description.title' | translate }}:</label>
                    <input
                        class="form-control"
                        [(ngModel)]="this.descriptions[type][this.selectedLanguage]" (ngModelChange)='transformDescriptionsToDTOs()'
                        (input)="checkText($event.target.value)"
                        ngDefaultControl
                        name="{{ type }}"
                        id="title">
                    <div class="text-errors-wrapper" *ngIf="textTitleErrorMessages">
                        <p *ngFor="let errorMessage of textTitleErrorMessages" class="error">
                            {{ errorMessage | translate }}
                        </p>
                    </div>
                </div>
            </div>
            <div *ngIf="type === 'complete' && this.selectedLanguage" class="row mt-3">
                <div class="col-md">
                    <label for="{{ type }}">{{ 'atraveo.accommodationbundle.group.description.tab.description.' + textAreaNames[type] + '.label' | translate }}</label>
                    <textarea
                        [(ngModel)]="this.descriptions[type][this.selectedLanguage]"
                        (ngModelChange)='transformDescriptionsToDTOs()'
                        ngDefaultControl
                        name="{{ type }}"
                        rows="15"
                        class="form-control col-xs-12"
                    >
                    </textarea>
                </div>
            </div>
        </ng-container>
        <div class="row mt-3" *ngIf="accommodationOrGroup?.isGroup">
            <div class="col-sm-12 description-footer">
                <label class="col-form-label">
                    {{ 'text.mandatoryfield' | translate }}
                </label>
            </div>
        </div>
    </div>
</div>

<div class="text-right clear mt-2" *ngIf="viewType === 'accommodation'">
    <a class="btn btn-success" (click)="save()"><i class="fas fa-cloud-upload-alt mr-2"></i>{{ 'button.save' | translate }}</a>
</div>
