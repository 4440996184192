<div class="modal-header">
    <h5 class="modal-title">{{ 'atraveo.frontendbundle.logout.confirm.0' | translate }}</h5>
    <button class="close" (click)="closeDialog()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>{{ 'atraveo.frontendbundle.logout.confirm.headline' | translate }}</p>
    <app-task-display [logout]="true"></app-task-display>
    <p>{{ 'atraveo.frontendbundle.logout.confirm.content.line1' | translate }}</p>
    <p>{{ 'atraveo.frontendbundle.logout.confirm.content.line2' | translate }}</p>
</div>
<div class="modal-footer">
    <button (click)="logout()" type="button" class="btn btn-success">{{ 'button.logout' | translate }}</button>
</div>