<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'atraveo.accommodationbundle.bankaccount.edit' | translate }}</h5>
        <button class="close" (click)="closeDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <app-bank-account [bankAccount]="bankAccount" [default]="isDefault" [bankAccountId]="bankAccountId" (onSave)="saveBankAccount($event)"></app-bank-account>
        </div>
    </div>
</div>
