import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import {
    ComponentsEnum,
    FindAccommodationRequestDto
} from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-request.dto';

@Component({
  selector: 'app-quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss', './../dashboard.component.scss']
})
export class QuickAccessComponent implements OnInit {
    constructor(
        readonly authenticationService: AuthenticationService,
        readonly apiConnector: ApiConnectorService
    ) { }

    firstActiveAccommodation: number;

    async ngOnInit() {
        const findAccommodationRequestDto = new FindAccommodationRequestDto();

        findAccommodationRequestDto.ownerNumber = this.authenticationService.currentUser.ownerNumber;

        let result = await this.apiConnector.getAccommodationsByOwnerNumber(
            this.authenticationService.currentUser,
            0,
            1,
            {'active': true, 'deleted': false, 'paused': false},
            [ComponentsEnum.AccommodationId]
        )

        if (!result?.accommodations.length) {
            result = await this.apiConnector.getAccommodationsByOwnerNumber(
                this.authenticationService.currentUser,
                0,
                1,
                {'deleted': false},
                [ComponentsEnum.AccommodationId]
            )
        }

        this.firstActiveAccommodation = result?.accommodations[0]?.accommodationId;
    }

}
