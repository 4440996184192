<div class="modal-header">
    <button class="close" (click)="closeDialog()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="legend popover-calendar-content">
        <div *ngIf="!bookingInDateRange">
            <h4 id="date-range-headline">{{ this.dateRangeHeadLine }}</h4>
            <form [formGroup]="stateForm" (ngSubmit)="setState()">
                <div class="col-sm-2" *ngFor="let option of options">
                    <input type="radio" formControlName="newEvent" [value]="option.value">
                    <div class="calendar-key" [ngClass]="option.class"></div>
                    <div>{{ option.label | translate }}</div>
                </div>
                <button type="submit" id="finish-selection-btn" class="btn btn-success w-100">{{ 'button.save' | translate }}</button>
                <button class="btn btn-link pl-0" (click)="openBookingDialog()">{{ 'atraveo.bookingbundle.button.new' | translate}}</button>
            </form>
        </div>
        <div *ngIf="bookingInDateRange" class="error">
            {{ 'atraveo.accommodationbundle.calendar.info.blockedby' | translate }}
            <button id="cancel-selection-btn" class="btn btn-success btn-icon btn-icon-done w-100" (click)="closeDialog()">{{ 'button.cancel' | translate }}</button>
        </div>
    </div>
</div>