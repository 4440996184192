<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'atraveo.accommodationbundle.contact.edit' | translate }}</h5>
        <button class="close" (click)="closeDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <app-registration-form
                [newUser]="newUser"
                [isUserProfile]="isUserProfile"
                [primaryId]=contactId
                (onSave)="reloadOwner()"
                [deletableReason]="deletableReason"
                [newOwnerParams]="newOwnerParams"
                (ownerWithPoolSaved)="ownerWithPoolSaved()">
            </app-registration-form>
        </div>
    </div>
</div>
