import { Component, Input, NgZone, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../entities/extendedAccommodationEntity';
import { GoogleMapService } from '../../../../../services/google-map-loader/google-map.service';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { ExtendedAccommodationGroupEntity } from '../../../../../entities/extendedAccommodationGroupEntity';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GeoAssignmentResultDto } from 'data-structures/lib/es6/dto/geography/geo-assignment-result.dto';
import { TranslateService } from '@ngx-translate/core';
import { GeoDto } from 'data-structures/lib/es6/dto/accommodation/position/geo.dto';
import { NotificationService } from '../../../../../services/notification/notification.service';
import { LoaderService } from '../../../../../services/loader-service/loader.service';
import { GeoCityEntity } from 'data-structures/lib/es6/entity/geography/geo-city.entity';
import { Observable, Subscription } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash';
import { LocationDto } from 'data-structures/lib/es6/dto/accommodation/position/location.dto';
import { PendingChangesComponentService } from '../../../../../services/pending-changes-component/pending-changes-component.service';
import { Location } from '@angular/common';
import { ComponentCanDeactivate } from '../../../../../guards/pending-changes-guard';
import { AccommodationAjaxSearchComponent } from '../../../../global/accommodation-ajax-search/accommodation-ajax-search.component';
import { OnboardingComponent } from '../../user/onboarding/onboarding.component';
import { OnboardingService } from '../../../../../services/onboarding/onboarding.service';

@Component({
    selector: 'app-position',
    templateUrl: './position.component.html',
    styleUrls: ['./position.component.scss'],
})
export class PositionComponent implements OnInit, OnChanges, OnDestroy, ComponentCanDeactivate {
    accommodationOrGroup: ExtendedAccommodationEntity | ExtendedAccommodationGroupEntity;
    accommodationOrGroupOriginal: ExtendedAccommodationEntity | ExtendedAccommodationGroupEntity;
    @Input() viewType: 'accommodation' | 'accommodationGroup' = 'accommodation';
    accommodationGroupId: number = null;
    marker: google.maps.Marker;
    geoNames: GeoAssignmentResultDto;
    lastZoomLevel: number;
    switzerLandNotAllowed: boolean = false;
    currentAddressString: string;
    isAccommodationActive: boolean = false;
    showGeoTriplett = false;
    currentLocation: LocationDto;
    saveTriggerSubscription: Subscription;
    accommodationGroupSubscription: Subscription;

    constructor(
        readonly authenticationService: AuthenticationService,
        readonly apiConnectorService: ApiConnectorService,
        readonly googleMapService: GoogleMapService,
        readonly route: ActivatedRoute,
        readonly router: Router,
        readonly translateService: TranslateService,
        readonly notificationService: NotificationService,
        readonly loaderService: LoaderService,
        readonly ngZone: NgZone,
        readonly pendingChangesComponentService: PendingChangesComponentService,
        readonly location: Location,
        readonly onboardingService: OnboardingService,
    ) {}

    async ngOnInit() {
        this.accommodationGroupSubscription = this.apiConnectorService.activeAccommodation$.subscribe(async (accommodation) => {
            if (accommodation) {
                this.accommodationOrGroup = cloneDeep(accommodation);
                this.accommodationOrGroupOriginal = cloneDeep(accommodation);
                await this.ngOnChanges();
            }
        });

        this.saveTriggerSubscription = this.pendingChangesComponentService.getSaveTriggerObservable().subscribe(async () => {
            await this.save();
        });
    }

    ngOnDestroy() {
        this.saveTriggerSubscription?.unsubscribe();
        this.accommodationGroupSubscription?.unsubscribe();
    }

    async ngOnChanges() {
        if (this.accommodationOrGroup?.position?.location) {
            this.currentLocation = cloneDeep(this.accommodationOrGroup?.position?.location);
        }

        if (this.accommodationOrGroup) {
            this.accommodationOrGroup.addRequiredFields();
        }

        if (document.querySelector('#map')) {
            if (this.accommodationOrGroup && this.accommodationOrGroup.position?.location?.latitude && this.accommodationOrGroup.position?.location?.longitude) {
                this.googleMapService.init(
                    document.querySelector('#map'),
                    this.setMarkerOnClick,
                    this.accommodationOrGroup.position?.location.latitude,
                    this.accommodationOrGroup.position?.location.longitude,
                    this.lastZoomLevel ?? 12,
                );
                this.googleMapService.placeMarker(this.accommodationOrGroup.position?.location.latitude, this.accommodationOrGroup.position?.location.longitude, false, true, this.onMarkerMoved, '');

                if (this.accommodationOrGroup.position?.geo?.cityId) {
                    this.getCityLatAndLong(this.accommodationOrGroup.position.geo.cityId).then((city) => {
                        if (city?.latitude && city?.longitude) {
                            this.googleMapService.placeMarker(city.latitude, city.longitude, false, false, this.onMarkerMoved, 'assets/icons/cityCenter.png', this.lastZoomLevel, false);
                        }
                    });
                }

                this.setZoomLevel();
            } else {
                if (this.authenticationService.currentUser) {
                    this.accommodationOrGroup.ownerNumber = this.authenticationService.currentUser.ownerNumber;
                }
                this.googleMapService.init(document.querySelector('#map'), this.setMarkerOnClick);
            }
        } else {
            // Seit der Änderung mit den Tabs ist das map Element nicht da, wenn das erste Mal ngOnChanges aufgerufen wird
            // und es gibt leider auch kein passendes Angular-Event
            window.setTimeout(() => {
                this.ngOnChanges();
            }, 50);
        }

        if ('accommodationId' in this.accommodationOrGroup && this.accommodationOrGroup.checkResults?.length) {
            this.switzerLandNotAllowed = this.accommodationOrGroup.checkResults.some((result) => result.checkId === 66);
        }

        if (this.viewType === 'accommodation' && 'accommodationId' in this.accommodationOrGroup && this.accommodationOrGroup?.state?.active) {
            this.isAccommodationActive = true;
        }

        this.currentAddressString = this.getAddressString();

        // speichere neu da Änderung gemacht wurden
        if (!isEqual(this.accommodationOrGroup, this.accommodationOrGroupOriginal)) {
            this.apiConnectorService.setActiveAccommodation(this.accommodationOrGroup);
        }
    }

    private getCityLatAndLong(id: number): Promise<GeoCityEntity> {
        return this.apiConnectorService.getCity(id);
    }

    private getAddressString(): string {
        const addressComponents = [];
        addressComponents.push(this.accommodationOrGroup.position.address.street ?? '');
        addressComponents.push(this.accommodationOrGroup.position.address.zipCode ?? '');
        addressComponents.push(this.accommodationOrGroup.position.address.city ?? '');
        addressComponents.push(this.accommodationOrGroup.position.address.country ?? '');
        return addressComponents.join(' ').trim();
    }

    private setZoomLevel() {
        if (!this.lastZoomLevel) {
            this.lastZoomLevel = 12;
        }

        if (this.googleMapService.map) {
            this.googleMapService.map.setZoom(this.lastZoomLevel);
        }
    }

    onMarkerMoved = (marker: google.maps.Marker) => {
        this.loaderService.show();
        this.googleMapService.getAddress(marker.getPosition().lat(), marker.getPosition().lng(), this.onPositionChanged);
        this.lastZoomLevel = this.googleMapService.map.getZoom();
        this.marker = marker;
    };

    onPositionChanged = async (results: google.maps.GeocoderResult[]) => {
        const addressString = this.getAddressString();
        this.lastZoomLevel = this.googleMapService.map.getZoom();

        this.accommodationOrGroup.position.location.latitude = this.marker.getPosition().lat();
        this.accommodationOrGroup.position.location.longitude = this.marker.getPosition().lng();

        let address_components = results[0].address_components;

        // Google hat da anscheinend etwas geändert, das erste Resultat hat keine Adresse mehr
        if (results[0].types?.length && results[0].types[0] === 'plus_code' && results.length > 1) {
            address_components = results[1].address_components;
        }

        const components = {
            street_number: null,
            route: null,
            locality: null,
            country: null,
            postal_code: null,
            administrative_area_level_1: null,
            administrative_area_level_2: null,
            administrative_area_level_3: null,
        };

        jQuery.each(address_components, (k, v1) => {
            jQuery.each(v1.types, (k2, v2) => {
                components[v2] = v1.long_name;
            });
        });

        if (addressString === '') {
            this.accommodationOrGroup.addRequiredFields();

            this.accommodationOrGroup.position.address.street = '';
            this.accommodationOrGroup.position.address.houseNumber = ''; // soll es nicht mehr geben. wird demnächst auf allen ebenen entfernt
            if (typeof components.route !== 'undefined' && components.route !== 'Unnamed Road' && components.route != null) {
                this.accommodationOrGroup.position.address.street = components.route + (components.street_number ? ' ' + components.street_number : '');
            }

            this.accommodationOrGroup.position.address.city = '';
            this.accommodationOrGroup.position.address.city = typeof components.locality !== 'undefined' ? components.locality : '';

            this.accommodationOrGroup.position.address.country = '';
            this.accommodationOrGroup.position.address.country = typeof components.country !== 'undefined' ? components.country : '';

            this.accommodationOrGroup.position.address.zipCode = '';
            this.accommodationOrGroup.position.address.zipCode = typeof components.postal_code !== 'undefined' ? components.postal_code : '';

            // Wenn google keine Stadt findet, kann man nicht speichern
            if (!this.accommodationOrGroup.position.address.city) {
                this.accommodationOrGroup.position.address.city = 'not found';
            }
        }

        this.accommodationOrGroup.position.address.region = '';
        this.accommodationOrGroup.position.address.region = typeof components.administrative_area_level_1 !== 'undefined' ? components.administrative_area_level_1 : '';

        this.accommodationOrGroup.position.address.subRegion = '';
        this.accommodationOrGroup.position.address.subRegion = typeof components.administrative_area_level_2 !== 'undefined' ? components.administrative_area_level_2 : '';
        await this.save();
    };

    onAddressChanged = async (results: google.maps.GeocoderResult[]) => {
        if (results) {
            if (results.length) {
                if (results[0].geometry?.location?.lat() && results[0].geometry?.location?.lng()) {
                    const latLng = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry?.location?.lng());
                    this.googleMapService.placeMarker(latLng.lat(), latLng.lng(), true, false, this.onMarkerMoved, '');

                    if (!this.lastZoomLevel) {
                        this.setZoomLevel();
                    }

                    this.googleMapService.map.setZoom(this.lastZoomLevel);
                    this.googleMapService.map.setCenter(latLng);
                }
            } else {
                await this.notificationService.add('atraveo.accommodationbundle.position.address.error', 'danger');
            }
        } else {
            await this.save();
            return;
        }
    };

    setMarkerOnClick = (map) => {
        if (document.getElementById('map').classList.contains('disabled')) {
            return;
        }

        google.maps.event.addListener(map, 'click', (event) => {
            this.googleMapService.placeMarker(event.latLng.lat(), event.latLng.lng(), true, false, this.onMarkerMoved, '');
            this.googleMapService.map.setCenter(event.latLng);
        });
    };

    changeCoordinates() {
        if (this.accommodationOrGroup?.position?.location?.latitude && this.accommodationOrGroup?.position?.location?.longitude) {
            this.googleMapService.placeMarker(this.accommodationOrGroup.position.location.latitude, this.accommodationOrGroup.position.location.longitude, true, false, this.onMarkerMoved, '');
        }
    }

    async redetermineCity() {
        if ('accommodationId' in this.accommodationOrGroup) {
            const result = await this.apiConnectorService.redetermineCityPosition(this.accommodationOrGroup.accommodationId);
            if (result && this.accommodationOrGroup?.position) {
                this.accommodationOrGroup.position.geo = result;
            }
        }
    }

    updateGeo($event: Event) {
        if (this.accommodationOrGroup.position.geo.countryId) {
            this.accommodationOrGroup.position.geo.manual = true;

            if (this.accommodationOrGroup.position.geo.bestRegionId) {
                this.accommodationOrGroup.position.geo.bestRegionId = Number(this.accommodationOrGroup.position.geo.bestRegionId);
            }

            if (this.accommodationOrGroup.position.geo.cityId) {
                this.accommodationOrGroup.position.geo.cityId = Number(this.accommodationOrGroup.position.geo.cityId);
            }
        } else {
            this.accommodationOrGroup.position.geo = new GeoDto();
        }
    }

    async checkAndSave() {
        if (this.accommodationOrGroup.isNew()) {
            const addressString = this.getAddressString();

            if (addressString) {
                await this.googleMapService.getPositionByAddress(addressString.trim(), this.onAddressChanged);
            }
        } else {
            await this.save();
        }
    }

    async save() {
        let redetermineCity = false;
        if (
            this.currentLocation?.latitude !== this.accommodationOrGroup?.position?.location?.latitude ||
            this.currentLocation?.longitude !== this.accommodationOrGroup?.position?.location?.longitude
        ) {
            redetermineCity = true;
        }

        if (redetermineCity) {
            await this.redetermineCity();
            this.currentLocation = this.accommodationOrGroup?.position?.location;
        }

        const result = await this.apiConnectorService.saveAccommodationOrGroupForTab(this.route, this.accommodationOrGroup);

        // speichere tracking für onboarding
        const userTracking = this.authenticationService.currentUser?.tracking;
        if (userTracking?.onboarding) {
            if (!userTracking.onboarding.accommodationCreationStarted) {
                userTracking.onboarding.accommodationCreationStarted = new Date();
                this.authenticationService.saveOwner(this.authenticationService.currentUser);
                this.onboardingService.reloadSteps();
            }
        }
        this.pendingChangesComponentService.saveComplete(!!result);
    }

    canDeactivate(): Observable<boolean> | boolean {
        return isEqual(this.accommodationOrGroup, this.accommodationOrGroupOriginal);
    }
}
