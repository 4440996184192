import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../entities/extendedAccommodationEntity';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { ExtendedAccommodationGroupEntity } from '../../../../../entities/extendedAccommodationGroupEntity';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../../services/notification/notification.service';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEqual } from 'lodash';
import {
    ComponentsEnum as FindAccommodationComponents
} from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-request.dto';
import {
    FindAccommodationStateDto
} from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-state.dto';
import {
    PendingChangesComponentService
} from '../../../../../services/pending-changes-component/pending-changes-component.service';
import { ComponentCanDeactivate } from '../../../../../guards/pending-changes-guard';

@Component({
    selector: 'app-objects',
    templateUrl: './objects.component.html',
    styleUrls: ['./objects.component.scss'],
})
export class ObjectsComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    accommodations: ExtendedAccommodationEntity[];
    accommodationGroup: ExtendedAccommodationGroupEntity;
    accommodationGroupOriginal: ExtendedAccommodationGroupEntity;
    accommodationGroupId: number = null;
    accommodationGroupSubscription: Subscription;
    saveTriggerSubscription: Subscription;

    objectSearch = '';
    checked = false;
    selected = 'select.none';
    objectSelected = null;
    allSelected = '';
    geoCollection = [];

    constructor(
        readonly authenticationService: AuthenticationService,
        readonly apiConnectorService: ApiConnectorService,
        readonly route: ActivatedRoute,
        readonly notificationService: NotificationService,
        readonly router: Router,
        readonly translateService: TranslateService,
        readonly pendingChangesComponentService: PendingChangesComponentService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.accommodationGroupSubscription = this.apiConnectorService.activeAccommodation$.subscribe(async accommodationGroup => {
            if (accommodationGroup) {
                this.accommodationGroup = cloneDeep(accommodationGroup);
                this.accommodationGroupOriginal = cloneDeep(accommodationGroup);

                const components: FindAccommodationComponents[] = [FindAccommodationComponents.AccommodationId, FindAccommodationComponents.Position, FindAccommodationComponents.AccommodationGroupId];
                const state: FindAccommodationStateDto = new FindAccommodationStateDto();
                state.deleted = false;

                const result: any = await this.apiConnectorService.getAccommodationsByOwnerNumber(this.authenticationService.currentUser, Number(0), 999, state, components);

                // Gordon in VRMB-1196: Objekte soll man nur noch zu einer Objektgruppe hinzufügen können wenn sie eine gültige Position haben
                this.accommodations = result.accommodations.filter((accommodation) => {
                    return (!accommodation.accommodationGroupId || accommodation.accommodationGroupId === this.accommodationGroupId) && accommodation?.position?.geo?.cityId;
                });
            }
        });

        this.saveTriggerSubscription = this.pendingChangesComponentService.getSaveTriggerObservable().subscribe(async () => {
            await this.saveAccommodationGroup();
        });
    }

    ngOnDestroy() {
        this.saveTriggerSubscription?.unsubscribe();
        this.accommodationGroupSubscription?.unsubscribe();
    }

    changeStateAll(event) {
        if (event.target.checked) {
            document.querySelectorAll('.objectCheck').forEach((element) => {
                this.accommodationGroup.groupAccommodationIds.push(Number(element.getAttribute('value')));
                if (element.getAttribute('data-geo')) {
                    this.geoCollection.push(element.getAttribute('data-geo'));
                }
            });
        } else {
            this.accommodationGroup.groupAccommodationIds = [];
            this.geoCollection = [];
        }
    }

    changeState(event) {
        if (event.target.checked) {
            this.accommodationGroup.groupAccommodationIds = this.accommodationGroup.groupAccommodationIds ?? [];
            this.accommodationGroup.groupAccommodationIds.push(Number(event.target.value));
            if (event.target.attributes['data-geo']) {
                this.geoCollection.push(event.target.attributes['data-geo'].value);
            }
            this.objectSelected = this.accommodationGroup.groupAccommodationIds.length;
            this.selected = 'select.n';
        } else {
            this.accommodationGroup.groupAccommodationIds = this.accommodationGroup.groupAccommodationIds.filter((item) => !event.target.value.includes(item));
            if (event.target.attributes['data-geo']) {
                this.geoCollection = this.geoCollection.filter((item) => !event.target.attributes['data-geo'].value.includes(item));
            }
            this.objectSelected = this.accommodationGroup.groupAccommodationIds.length !== 0 ? this.accommodationGroup.groupAccommodationIds.length : null;
            this.selected = this.accommodationGroup.groupAccommodationIds.length !== 0 ? 'select.n' : 'select.none';
        }
        this.allSelected = '';
    }

    removeObjects() {
        this.accommodationGroup.groupAccommodationIds = [];
        this.selected = 'select.none';
        this.allSelected = '';
        this.objectSelected = null;
        this.checked = false;
        $('.objectCheckAll').prop('checked', false);
    }

    convertToNumber(value) {
        return Number(value);
    }

    validateAssignment() {
        if (!this.geoCollection.every((val, i, arr) => val === arr[0])) {
            document.querySelector('.group-geo-error').classList.remove('hide');
            this.notificationService.add('atraveo.accommodationbundle.validation.group.cities.different', 'danger');
            return false;
        }

        document.querySelector('.group-geo-error').classList.add('hide');
        return true;
    }

    async saveAccommodationGroup() {
        const assignmentIsValid = await this.validateAssignment();
        if (!assignmentIsValid) {
            return;
        }

        const isNewAccommodationGroup = !this.accommodationGroup.accommodationGroupId;

        if (!this.accommodationGroup.name) {
            this.accommodationGroup.name = await this.translateService.get('atraveo.accommodationbundle.group.new').toPromise();
        }

        await this.apiConnectorService.saveAccommodationGroup(this.accommodationGroup, this.authenticationService.currentUser.ownerNumber, false, true);
        if (isNewAccommodationGroup) {
            await this.router.navigate(['/accommodation-group/', this.accommodationGroup.accommodationGroupId, 'objects'], {
                queryParams: { ownerid: this.accommodationGroup.ownerNumber },
            });
        }
    }

    canDeactivate(): Observable<boolean> | boolean {
        return isEqual(this.accommodationGroup, this.accommodationGroupOriginal);
    }
}
