import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ImageConnectorService {
    constructor(readonly httpClient: HttpClient) {}

    async rotateImageAndLoad(ownerNumber: number, type: string, accOrGroupId: number, fileName: string, angle: number): Promise<Blob> {
        const url = `rotate/${type}/${ownerNumber}/${accOrGroupId}/${fileName}?x=600&y=400&angle=${angle}`;
        return await this.httpClient.get(environment.imageServerUrl + url, { responseType: 'blob' }).toPromise();
    }
}
