<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="arrivalModalLabel">{{ 'text.arrival.title' | translate }}</h5>
        <button class="close" (click)="closeDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <app-create-arrival-information [(arrivalInformation)]="emptyArrivalInformation"></app-create-arrival-information>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="save()" type="button" class="btn btn-primary">{{ 'button.save' | translate }}</button>
    </div>
</div>