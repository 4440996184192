import { Component, Inject } from '@angular/core';
import { DialogService } from '../../../../services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-price-dialog',
    templateUrl: './contact-dialog.component.html',
    styleUrl: './contact-dialog.component.scss',
})
export class ContactDialogComponent {
    contactId: string = '';
    deletableReason: string = '';
    newUser: boolean = false;
    isUserProfile: boolean = false;
    newOwnerParams: any = null;

    constructor(readonly dialogService: DialogService, @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.contactId) {
            this.contactId = data.contactId;
        }
        if (data.deletableReason) {
            this.deletableReason = data.deletableReason;
        }
        if (data.newUser) {
            this.newUser = data.newUser;
        }
        if (data.isUserProfile) {
            this.isUserProfile = data.isUserProfile;
        }
        if (data.newOwnerParams) {
            this.newOwnerParams = data.newOwnerParams;
        }
    }

    reloadOwner() {
        this.data.reloadOwner();
    }

    closeDialog() {
        this.dialogService.closeDialog();
    }

    ownerWithPoolSaved() {
        this.data.ownerWithPoolSaved();
    }
}
