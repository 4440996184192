<h1>{{ 'text.arrival.title' | translate }}</h1>
<p>{{ 'text.arrival.info' | translate }}</p>

<h2>{{ 'text.arrival.overview' | translate }}</h2>

<button class="btn btn-primary mb-3" (click)="changeShowFilter()">
    <i class="fa fa-filter mr-2"></i>
    {{ 'atraveo.bookingbundle.filter.label' | translate }}
</button>

<div class="filter mb-3 hid" *ngIf="this.showFilter || !this.arrivalInformations?.length && (this.filterDto.accommodationId || this.filterDto.countryIsoCode || this.filterDto.accommodationGroupId)">
    <div class="row">
        <div class="col-3">
            <label for="search">
                {{ 'text.arrival.accommodation.0' | translate }}
            </label>
        </div>
        <div class="col-5">
            <app-accommodation-ajax-search
                    hideLabel="true"
                    hideIcons="true"
                    classes="form-control d-inline typeahead"
                    (onSearch)="onSearch($event)"
            ></app-accommodation-ajax-search>
        </div>
    </div>

    <div class="row">
        <div class="col-3">
            <label for="accommodationGroup">
                {{ 'text.arrival.accommodation.group.0' | translate }}
            </label>
        </div>
        <div class="col-5">
            <app-accommodation-group-select
                    idKey="accommodationGroup"
                    classKey="form-control custom-select selectpicker"
                    [multipleAccommodationGroups]="false"
                    [(ngModel)]="this.filterDto.accommodationGroupId"
                    [noneSelectedDisplayAll]="false"
                    [addEmptyOption]="true"
            ></app-accommodation-group-select>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-3">
            <label for="country">
                {{ 'text.country' | translate}}
            </label>
        </div>
        <div class="col-5">
            <app-country-select
                    idKey="country"
                    classKey="form-control custom-select"
                    [(ngModel)]="filterDto.countryIsoCode"
            ></app-country-select>
        </div>
    </div>
    <div class="mt-3">
        <button type="button" class="btn btn-secondary" (click)="this.load(1);">{{ 'button.filter' | translate }}</button>
        <button type="button" class="btn btn-primary ml-3" (click)="this.resetFilter()">{{ 'button.reset.filter' | translate }}</button>
    </div>
</div>

<table *ngIf="arrivalInformations && arrivalInformations.length" class="table responsive-table">
    <thead>
        <tr>
            <th class="w-75">{{ 'text.arrival.type' | translate }}</th>
            <th>
                <app-sort-arrows (ngModelChange)="refresh($event)" [(ngModel)]="sort" [translationKey]="'text.arrival.creation.date'"></app-sort-arrows>
            </th>
            <th><!-- Icons --></th>
        </tr>
    </thead>
    <tr *ngFor="let arrivalInformation of this.arrivalInformations; let i = index">
        <td [attr.data-label]="'text.arrival.type' | translate">
            <span *ngIf="arrivalInformation.allAccommodationsSelected()">
                {{ 'text.filter.all.0' | translate }}
            </span>
            <span *ngIf="arrivalInformation.names.accommodations?.length && !arrivalInformation.allAccommodationsSelected()">
                <ng-container *ngFor="let accommodationName of arrivalInformation.names.accommodations; last as isLast;">
                    <a routerLink="/accommodation/{{ accommodationName.id }}">{{ accommodationName.id }}</a>
                    <span *ngIf="accommodationName.name"> ({{accommodationName.name}})</span>
                    <span *ngIf="(arrivalInformation.conditions.accommodationGroupIds && arrivalInformation.conditions.accommodationGroupIds.length) || !isLast">, </span>
                    {{ getTranslation(accommodationName.translations) }}
                </ng-container>
            </span>
            <span *ngIf="!arrivalInformation.names.accommodations?.length && !arrivalInformation.allAccommodationsSelected()">
                {{ arrivalInformation.conditions.accommodationIds }}
            </span>
            <span *ngIf="arrivalInformation.names.accommodationGroup">
                <span *ngIf="arrivalInformation.names.accommodationGroup.id"> {{ arrivalInformation.names.accommodationGroup.id }} </span>
                <span *ngIf="arrivalInformation.names.accommodationGroup.name"> ({{ arrivalInformation.names.accommodationGroup.name }}) </span>
            </span>
            <span *ngIf="arrivalInformation.names.geoCountry?.translations">
                {{ getTranslation(arrivalInformation.names.geoCountry.translations) }}
            </span>
            <span *ngIf="arrivalInformation.names.geoRegion?.translations">
                {{ getTranslation(arrivalInformation.names.geoRegion.translations) }}
            </span>
            <span *ngIf="arrivalInformation.names.geoCity?.translations">
                {{ getTranslation(arrivalInformation.names.geoCity.translations) }}
            </span>
        </td>
        <td [attr.data-label]="'text.arrival.creation.date' | translate">{{ arrivalInformation.createDate | stringToDate | date }}</td>
        <td class="ml-5 pl-5 text-right">
            <!--
            <a (click)="getPdf(arrivalInformation.id)" class="btn btn-link p-0"><i class="fas fa-download fa-2x ml-2"></i></a>
            -->
            <a (click)="setActive(i)" class="btn btn-link p-0"><i data-toggle="modal" class="fas fa-pencil-alt fa-lg ml-2"></i></a>
            <a (click)="openConfirmationDialog(arrivalInformation, $event)" class="btn btn-link p-0">
                <i class="fas fa-trash-alt fa-lg ml-2"></i>
            </a>
        </td>
    </tr>
</table>

<div *ngFor="let arrival of arrivalInformations | paginate: { itemsPerPage: this.resultsPerPage, currentPage: page, totalItems: arrivalCount }"></div>

<pagination-controls class="float-right" (pageChange)="load($event)" previousLabel="" nextLabel=""></pagination-controls>

<a class="btn btn-link">
    <i (click)="createNew()" class="fas fa-plus-circle mt-5 fa-2x"></i>
</a>
