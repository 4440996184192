import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { environment } from '../../environments/environment';
import { differenceInHours } from 'date-fns';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    newTokenRequested: boolean = false;

    constructor(readonly authenticationService: AuthenticationService, readonly httpClient: HttpClient) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const urls = [environment.apiUrl, environment.authServiceUrl, environment.bookingConnectorUrl, environment.boUrl];
        if (urls.some((url) => request.url.includes(url))) {
            const currentUser = this.authenticationService.currentUser;
            if (currentUser && currentUser.token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUser.token}`,
                    },
                });

                if (
                    !this.newTokenRequested &&
                    !request.url.includes('auth/new-token') &&
                    !request.url.includes('auth/login') &&
                    (!currentUser.jwtDate || Math.abs(differenceInHours(currentUser.jwtDate, new Date())) >= 1)
                ) {
                    this.newTokenRequested = true;
                    this.httpClient
                        .post(environment.authServiceUrl + 'auth/new-token', null)
                        .toPromise()
                        .then((result) => {
                            if (result) {
                                currentUser.token = result.toString();
                                currentUser.jwtDate = new Date();
                                this.authenticationService.currentUserSubject.next(currentUser);
                                this.newTokenRequested = false;
                            }
                        })
                        .catch(() => {
                            this.authenticationService.logout();
                        });
                }
            }
        }

        return next.handle(request);
    }
}
