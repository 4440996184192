<div *ngIf="this.errors?.length">
    <div class="col accommodation-errors p-2">
        <i class="fa fa-exclamation-triangle fa-lg mr-2"></i>
        <strong>
            {{ 'atraveo.accommodationbundle.overview.error.title' | translate }}
            <button data-toggle="collapse" [attr.data-target]="'#collapseErrors'" class="btn btn-link collapse-link">
                <i class="fa fa-chevron-right" ></i>
            </button>
        </strong>
        <ol class="collapse dont-collapse-sm" id="collapseErrors">
            <li *ngFor="let error of this.errors">

                <a *ngIf="error.link !== 'profile'; else profile" routerLink="/accommodation/{{ accommodation.accommodationId }}/{{error.link}}" routerLinkActive="active"
                   [innerHTML]="error.message"></a>
                <ng-template #profile>
                    <a routerLink="/{{error.link}}" routerLinkActive="active"
                       [innerHTML]="error.message"></a>
                </ng-template>
            </li>
        </ol>
    </div>
</div>
<div *ngIf="this.ownerServiceErrors?.length  && this.authService.currentUser.isEmployee()">
    <div class="col service p-2 service-box">
        <i class="fa fa-exclamation-triangle fa-2x mr-2"></i>
        <strong>Fehler nur für Vermieterservice sichtbar:</strong>
        <ol>
            <li *ngFor="let error of this.ownerServiceErrors">
                <a routerLink="/accommodation/{{ accommodation.accommodationId }}/{{error.link}}" routerLinkActive="active"
                   [innerHTML]="error.message"></a>
            </li>
        </ol>
    </div>
</div>