import { Exclude } from 'class-transformer';
import { FilterDto } from 'data-structures/lib/es6/dto/find-booking/filter.dto';

export class ExtendedBookingFilterDto extends FilterDto {
    @Exclude()
    accommodationId: number;

    @Exclude()
    channelString: string;
}
