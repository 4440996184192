import { Component, OnInit } from '@angular/core';
import { ApiConnectorService } from '../../../../services/api-connector/api-connector.service';
import { RatingAccommodationEntity } from 'data-structures/lib/es6/entity/rating/accommodation/rating-accommodation.entity';
import { FindRatingRequestDto, RatingsComponentsEnum } from 'data-structures/lib/es6/dto/rating/owner/find-rating-request.dto';
import { FindRatingFilterDto } from 'data-structures/lib/es6/dto/rating/owner/find-rating-filter.dto';
import { FindRatingSortDto, SortEnum } from 'data-structures/lib/es6/dto/rating/owner/find-rating-sort-dto';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-ratings',
    templateUrl: './ratings.component.html',
    styleUrls: ['./ratings.component.scss'],
})
export class RatingsComponent implements OnInit {
    currentRatings: RatingAccommodationEntity[];
    currentRequest: FindRatingRequestDto;
    currentRating: RatingAccommodationEntity;
    currentPage: number;
    count: number;
    selectedTab: string;

    constructor(readonly apiConnector: ApiConnectorService, readonly authService: AuthenticationService, readonly translateService: TranslateService, readonly activatedRoute: ActivatedRoute) {}

    async ngOnInit(): Promise<void> {
        this.currentRequest = new FindRatingRequestDto();
        this.currentRequest.filter = new FindRatingFilterDto();
        this.currentRequest.take = 10;
        this.currentRequest.sort = new FindRatingSortDto();
        this.currentRequest.sort.createDate = SortEnum.Desc;
        this.currentRequest.ownerNumber = this.authService.currentUser.ownerNumber;
        this.currentRequest.components = [
            RatingsComponentsEnum.CreateDate,
            RatingsComponentsEnum.AccommodationId,
            RatingsComponentsEnum.TravelPeriod,
            RatingsComponentsEnum.OverallRating,
            RatingsComponentsEnum.Source,
            RatingsComponentsEnum.From,
            RatingsComponentsEnum.UniqueId,
            RatingsComponentsEnum.Read,
        ];

        await this.getResults();

        this.activatedRoute.fragment.subscribe((fragment) => {
            // Tab setzen wenn Anker gesetzt ist
            if (!fragment) {
                this.selectedTab = 'view-rating';
                return;
            }

            this.selectedTab = fragment;
        });
    }

    async getResults() {
        if (this.authService.currentUser.ownerNumber) {
            const result = await this.apiConnector.findRatings(this.currentRequest);
            this.currentRatings = result.result;
            this.count = result.count;
        }
    }

    async changePage(page: number) {
        this.currentRequest.skip = this.currentRequest.take * (page - 1);
        if (this.currentRequest.skip < 0) {
            this.currentRequest.skip = 0;
        }
        this.currentPage = page;

        await this.getResults();
    }

    async viewRating(rating: RatingAccommodationEntity) {
        this.currentRating = await this.apiConnector.getRatingById(rating.uniqueId, this.authService.currentUser.ownerNumber);
        const ratingFromList = this.currentRatings.find((existingRating) => rating.uniqueId === existingRating.uniqueId);
        if (ratingFromList) {
            ratingFromList.read = true;
        }
    }

    async saveCurrentRating() {
        await this.apiConnector.saveRating(this.currentRating, this.authService.currentUser.ownerNumber);
    }

    get accommodationText() {
        if (this.currentRating?.categories.length) {
            const category = this.currentRating.categories.find((category) => category.category === 'accommodation');
            if (category) {
                return category.content;
            }
        }

        return '';
    }

    getCustomerName(rating: RatingAccommodationEntity): string {
        if (rating?.from?.displayName) {
            return rating?.from.displayName;
        } else {
            let customerName = (rating?.from?.salutation ?? '') + ' ';
            customerName += (rating?.from?.forename ?? '') + ' ';
            customerName += rating?.from?.surname ?? '';
            customerName = customerName.trim().replace('  ', ' ');
            return customerName ?? '';
        }
    }

    changeToRequestRating() {
        // Da es zwei Buttons gibt, muss der eine per Javascript aktiviert werden
        // @ts-ignore
        $('a#request-tab').click();
    }
}
