import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiConnectorService } from '../../../../services/api-connector/api-connector.service';
import { ComponentsEnum, FindAccommodationRequestDto } from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-request.dto';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { NotificationService } from '../../../../services/notification/notification.service';
import { ConfirmationDialogService } from '../../../global/confirmation-dialog/confirmation-dialog.service';

// TODO: add translationservice, api für pools
@Component({
    selector: 'app-pool-config',
    templateUrl: './pool-config.component.html',
    styleUrls: ['./pool-config.component.scss'],
})
export class PoolConfigComponent implements OnInit {
    dataReady: boolean = false;

    // Formular für die Pools
    poolForm: FormGroup;
    availablePools = [];
    selectedPools = [];
    activePoolSelection = [];

    // Formular für die Zuweisung von Unterkünften zu Pools
    accommodationPoolForm: FormGroup;
    accommodationPools = [];
    usedAccommodationIds = [];
    usedAccommodationAndPoolNames = [];

    constructor(
        private fb: FormBuilder,
        readonly apiConnector: ApiConnectorService,
        readonly authenticationService: AuthenticationService,
        readonly notificationService: NotificationService,
        readonly confirmationDialogService: ConfirmationDialogService,
    ) {
        this.poolForm = new FormGroup({
            pools: this.fb.array([]),
        });
        this.accommodationPoolForm = new FormGroup({
            accommodationNumber: new FormControl(null, Validators.required),
            poolNumber: new FormControl(null, Validators.required),
        });
    }
    get accommodationNumberSelectControl(): FormControl {
        return this.accommodationPoolForm.get('accommodationNumber') as FormControl;
    }

    get poolsFormArray() {
        return this.poolForm.controls.pools as FormArray;
    }

    private addPoolCheckboxes() {
        this.availablePools.forEach((pool) => {
            const isSelected = this.selectedPools.includes(pool.poolId);
            const control = new FormControl(isSelected);
            (this.poolForm.controls.pools as FormArray).push(control);
        });
    }
    async ngOnInit() {
        await this.setPools();
        await this.setSelectedPools();
        this.addPoolCheckboxes();
        this.setPoolSelection();

        await this.getAccommodationsWithPools();

        this.dataReady = true;
    }

    setUsedAccommodations() {
        this.usedAccommodationIds = this.accommodationPools.map((config) => config.accommodation);
        this.usedAccommodationAndPoolNames = this.accommodationPools.map((accommodationPool) => {
            return {
                accommodation: accommodationPool.accommodation,
                name: accommodationPool.name,
                pool: this.availablePools.find((pool) => pool.poolId === accommodationPool.pool)?.name || '',
            };
        });
    }

    async onSubmitOwner() {
        if (this.poolForm.valid) {
            await this.savePoolsOwner();
            this.setSelectedPools();
            this.setPoolSelection();
            this.resetAccommodationSelects();
        } else {
            this.poolForm.markAllAsTouched();
        }
    }

    async onSubmitAccommodations() {
        if (this.accommodationPoolForm.valid) {
            this.saveAccommodationPool(this.accommodationPoolForm.get('accommodationNumber').value, this.accommodationPoolForm.get('poolNumber').value);
        } else {
            this.accommodationPoolForm.markAllAsTouched();
        }
    }

    async saveAccommodationPool(accommodationId: number, poolId: number) {
        // Hole die aktuelle Unterkunft damit nicht falsch gespeichert wird
        const usedAccommodation = await this.getAccommodation(accommodationId);

        if (usedAccommodation) {
            // Setze im Connector für den Datenänderungsvegleich
            this.apiConnector.setActiveAccommodation(usedAccommodation);

            usedAccommodation.destinationPool = Number(poolId);
            await this.apiConnector.saveAccommodation(usedAccommodation, false, false, false);

            // Aktualisierung der Anzeige
            this.getAccommodationsWithPools();
            this.resetAccommodationSelects();
        } else {
            console.error('Accommodation not found');
        }
    }

    openConfirmationDialog(accommodationId: number) {
        this.confirmationDialogService
            .confirm('poolConfig.confirm.deleteAccommodation', 'confirm.content.delete.0')
            .then(async (confirmed) => {
                if (confirmed) {
                    this.saveAccommodationPool(accommodationId, null);
                }
            })
            .catch(() => undefined);
    }

    resetAccommodationSelects() {
        this.accommodationPoolForm.patchValue({
            accommodationNumber: null,
            poolNumber: null,
        });
        this.accommodationPoolForm.markAsUntouched();
    }

    async getAccommodation(accommodationId): Promise<any> {
        const findDto: any = new FindAccommodationRequestDto();
        findDto.accommodationId = [accommodationId];
        const res = await this.apiConnector.findAccommodations(findDto);
        if (res.accommodations && res.accommodations.length > 0) {
            return res.accommodations[0]; // Access the accommodations array properly
        }
        return null;
    }

    setPoolSelection() {
        this.activePoolSelection = this.availablePools
            .filter((pool) => this.poolsFormArray.value[this.availablePools.indexOf(pool)])
            .map((activePool) => {
                return {
                    poolId: activePool.poolId,
                    name: activePool.name,
                };
            });
    }

    async setPools() {
        this.availablePools = (await this.apiConnector.getPools()).map((pool) => {
            return {
                poolId: pool.poolId,
                name: pool.name,
            };
        });
    }
    async setSelectedPools() {
        this.selectedPools = (await this.apiConnector.getPoolOwner(this.authenticationService.currentUser.ownerNumber)).map((pool) => pool.poolId);
    }
    async savePoolsOwner() {
        let somethingToDo = false;
        await Promise.all(
            this.availablePools.map(async (pool) => {
                const poolIndex = this.availablePools.indexOf(pool);
                const isSelected = this.selectedPools.includes(pool.poolId);
                const formValue = this.poolsFormArray.value[poolIndex];
                if (isSelected && !formValue) {
                    await this.apiConnector.deletePoolOwner(pool.poolId, this.authenticationService.currentUser.ownerNumber);
                    somethingToDo = true;
                } else if (!isSelected && formValue) {
                    await this.apiConnector.putPoolOwner(pool.poolId, this.authenticationService.currentUser.ownerNumber);
                    somethingToDo = true;
                }
            }),
        );
        if (somethingToDo) {
            await this.notificationService.add('form.save.success', 'success');
        }
    }

    async getAccommodationsWithPools() {
        const findDto: FindAccommodationRequestDto = new FindAccommodationRequestDto();
        findDto.ownerNumber = this.authenticationService.currentUser.ownerNumber;
        findDto.withDestinationPools = true;
        findDto.components = [ComponentsEnum.AccommodationId, ComponentsEnum.Main, ComponentsEnum.DestinationPool];
        const result: any = await this.apiConnector.findAccommodations(findDto);
        this.accommodationPools = result.accommodations.map((accommodation) => {
            return {
                accommodation: accommodation.accommodationId,
                name: accommodation.main?.name || '',
                pool: accommodation.destinationPool,
            };
        });
        this.setUsedAccommodations();
    }
}
