import { AccommodationGroupEntity } from 'data-structures/lib/es6/entity/accommodation-group/accommodation-group.entity';
import { environment } from '../../environments/environment';
import { DescriptionDto, MainDto, MediaDto, PositionDto, StateDto } from 'data-structures/lib/es6/dto/accommodation';
import { Exclude } from 'class-transformer';
import { DescriptionCategory, DescriptionType } from 'data-structures/lib/es6/dto/accommodation/descriptions/description.dto';
import { LanguageDto } from 'data-structures/lib/es6/dto/common/language.dto';
import { GeoDto } from 'data-structures/lib/es6/dto/accommodation/position/geo.dto';
import { AddressDto } from 'data-structures/lib/es6/dto/accommodation/position/address.dto';
import { LocationDto } from 'data-structures/lib/es6/dto/accommodation/position/location.dto';

export class ExtendedAccommodationGroupEntity extends AccommodationGroupEntity {
    @Exclude({ toPlainOnly: true })
    updated: Date;
    @Exclude({ toPlainOnly: true })
    isGroup: boolean = true;

    sortMedia() {
        if (!this.media || !this.media.length) {
            return;
        }

        this.media = this.media.sort((a: MediaDto, b: MediaDto): number => {
            return a.order - b.order;
        });
    }

    addRequiredFields() {
        if (!this.position) {
            this.position = new PositionDto();
        }

        if (!this.position.address) {
            this.position.address = new AddressDto();
        }

        if (!this.position.location) {
            this.position.location = new LocationDto();
        }

        if (!this.position.geo) {
            this.position.geo = new GeoDto();
        }
    }

    getShortenedName() {
        if (!this.accommodationGroupId) {
            return '';
        }

        let name: string = this.accommodationGroupId.toString();
        if (this.name) {
            name += ' (' + this.name.substring(0, 20) + ')';
        }

        return name;
    }

    getIdForAccommodationOrGroup() {
        return this.accommodationGroupId;
    }

    getRouteForAccommodationOrGroup() {
        return 'accommodation-group';
    }

    isNew() {
        return !this.accommodationGroupId;
    }
}
