import { Component, Input, OnInit, ViewChild, ElementRef, HostListener, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogService } from './confirmation-dialog.service';
@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent implements OnInit {
    @Input() title: string;
    @Input() message: string;
    @Input() btnOkText: string;
    @Input() btnCancelText: string;
    @Input() btnOkClass: string;
    @Input() btnCancelClass: string;
    @ViewChild('acceptButton', { static: true }) acceptButton: ElementRef<HTMLButtonElement>;
    @ViewChild('cancelButton', { static: true }) cancelButton: ElementRef<HTMLButtonElement>;

    constructor(readonly dialogService: ConfirmationDialogService, @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.acceptButton.nativeElement.focus();
    }

    getCssVariable(atraveoBlue: string): string {
        return getComputedStyle(document.documentElement).getPropertyValue(atraveoBlue);
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        const { key } = event;
        if (key === 'ArrowRight') this.cancelButton.nativeElement.focus();
        else if (key === 'ArrowLeft') this.acceptButton.nativeElement.focus();
        else if (key === 'Enter') document.activeElement === this.acceptButton.nativeElement ? this.accept() : this.decline();
    }

    decline() {
        this.dialogService.setResult(false);
        this.dialogService.closeDialog();
    }

    accept() {
        this.dialogService.setResult(true);
        this.dialogService.closeDialog();
    }

    dismiss() {
        this.dialogService.setResult(false);
        this.dialogService.closeDialog();
    }
}
