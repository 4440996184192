<div class="modal-content">
    <div class="modal-header">
        <h5 id="advancedSettingsLabel" class="modal-title">
            {{ 'atraveo.accommodationbundle.price.moreConfiguration' | translate }}
        </h5>
        <button class="close" (click)="closeDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <app-advanced-settings></app-advanced-settings>
        </div>
    </div>
</div>