import { Pipe, PipeTransform } from '@angular/core';
import { MediaDto } from 'data-structures/lib/es6/dto/accommodation';

@Pipe({
    name: 'imageUrlPipe',
})
export class ImageUrlPipePipe implements PipeTransform {
    transform(value: string, ...args: unknown[]): unknown {
        const imageServiceArguments: { x?: number; y?: number; format?: string; media?: MediaDto } = 0 in args ? args[0] : {};

        if (!value) {
            return value;
        }

        let returnValue = value.replace('http://', '//');

        // tslint:disable-next-line:no-shadowed-variable
        for (const [param, value] of Object.entries(imageServiceArguments)) {
            let paramToAdd = param;
            let valueToAdd = value;
            if (param === 'media') {
                paramToAdd = 'rotated';
                valueToAdd = ('angle' in (value as MediaDto)) as any;
            }
            returnValue += `${returnValue.includes('?') ? '&' : '?'}${paramToAdd}=${valueToAdd}`;
        }

        return returnValue;
    }
}
