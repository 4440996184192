import { Component, OnInit } from '@angular/core';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

interface RouteData {
    showFooter?: boolean;
}
@Component({
    selector: 'app-full',
    templateUrl: './full.component.html',
    styleUrl: './full.component.scss',
})
export class FullComponent implements OnInit {
    currentYear: number;
    showFooter = true;
    constructor(readonly router: Router, readonly route: ActivatedRoute) {}

    ngOnInit() {
        this.currentYear = new Date().getFullYear();
        // Initialisierung der Route-Daten beim Start
        this.updateRouteData(this.route);

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.route),
                map((route) => {
                    while (route.firstChild) route = route.firstChild;
                    return route;
                }),
                mergeMap((route) => route.data),
            )
            .subscribe((data) => {
                this.checkFooter(data);
            });
    }

    private updateRouteData(route: ActivatedRoute) {
        while (route.firstChild) route = route.firstChild;
        route.data.subscribe((data) => {
            this.checkFooter(data);
        });
    }

    checkFooter(data) {
        const routeData = data as RouteData;
        this.showFooter = routeData.showFooter !== false;
    }
}
