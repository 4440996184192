import { ExtendedUserEntity } from '../../entities/extendedUserEntity';
import { jwtDecode } from 'jwt-decode';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { classToPlain, plainToInstance } from 'class-transformer';
import { Injectable } from '@angular/core';
import { NotificationService } from '../notification/notification.service';
import { VacancyResultDto } from 'data-structures/lib/es6/dto/owner/vacancy-result.dto';
import { ChangeService } from '../change/change.service';
import { cloneDeep } from 'lodash';
import { merge } from 'lodash';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OwnerConnectorService {
    baseUrl = environment.apiUrl;
    baseAuthUrl = environment.authServiceUrl;

    constructor(readonly httpClient: HttpClient, readonly notificationService: NotificationService, readonly changeService: ChangeService) {}

    async login(username: string, password: string, role: string = null, loginAsUser = null, ownerType = null): Promise<ExtendedUserEntity> {
        // Erst im Authservice einloggen
        let response: any;
        const body: any = {
            username,
            password,
        };

        if (role) {
            body.loginAsRole = role;
        }

        if (loginAsUser) {
            body.loginAsUser = loginAsUser;
        }

        if (ownerType) {
            body.role = ownerType;
        } else {
            body.role = 'owner';
        }

        response = await this.httpClient.post<any>(this.baseAuthUrl + 'auth/login', body).toPromise();

        const decodedResponse: any = jwtDecode(response.accessToken);

        // Temporären User anlegen, damit der Zugriff aufs Inventory klappt
        let user = new ExtendedUserEntity();
        user.token = response.accessToken;
        // Dann echte User-Daten aus Inventory holen
        user = await this.getUserData(decodedResponse.userIdentification, response.accessToken);
        user.token = response.accessToken;
        user.roles = [decodedResponse.role];

        if (user.isDestination()) {
            const responsePools: any = await this.getDestinationPools(decodedResponse.userIdentification, response.accessToken);
            if (responsePools) {
                user.destinationPools = responsePools.map((pool) => pool.poolId);
            }
        }
        return user;
    }

    /**
     * @deprecated Es sollte wenn möglich außerhalb der Registrierung nur noch AuthenticationService.saveOwner genutzt werden, anstatt das hier direkt aufzurufen
     */
    async saveUserData(user: ExtendedUserEntity, duringRegistration = false, dontSaveUserToLocalstorage: boolean = false): Promise<ExtendedUserEntity> {
        let oldUser;
        let userInventory;

        oldUser = localStorage.getItem('currentUser');
        if (oldUser) {
            oldUser = plainToInstance(ExtendedUserEntity, JSON.parse(oldUser));
        }

        if (!duringRegistration) {
            userInventory = await this.getUserData(user.ownerNumber);
        }
        let userWrite = plainToInstance(ExtendedUserEntity, cloneDeep(user));

        // Erst den aktuellen User mit dem aus dem Lokalstorage vergleichen
        const diff = this.changeService.getChangedContentTypes(user, oldUser);
        // Und den User  aus dem Inventorsy mit dem aus dem Lokalstorage vergleichen
        const inventoryDiff = this.changeService.getChangedContentTypes(oldUser, userInventory);

        if (!duringRegistration) {
            // Dann nicht geänderte Felder rauslöschen
            this.deleteUnusedObjectsFromUser(diff, userWrite);
            this.deleteUnusedObjectsFromUser(inventoryDiff, userInventory);
        }

        // Dann Änderungen aus dem VRMB über die aus dem Inventory legen
        if (userInventory) {
            userWrite = merge(new ExtendedUserEntity(), userInventory, userWrite);
        }

        userWrite.convertBankAccounts();

        if (userWrite.payment?.bankAccounts && Object.keys(userWrite.payment.bankAccounts).length) {
            for (const [key, account] of Object.entries(userWrite.payment.bankAccounts)) {
                if (account && 'verified' in account && account.verified !== 'unverified') {
                    delete account.verified;
                }
            }
        }

        // Payment Knoten wo nur ein leerer bankAccount knoten drin ist
        if (userWrite.payment?.bankAccounts && !Object.keys(userWrite.payment.bankAccounts).length && Object.keys(userWrite.payment).length === 1) {
            delete userWrite.payment;
        }

        if (!userWrite.channels?.length) {
            delete userWrite.channels;
        }

        if (!userWrite.vat) {
            delete userWrite.vat;
        }

        if (userWrite.state === null) {
            delete userWrite.state;
        }

        if (userWrite.destinationPools) {
            delete userWrite.destinationPools;
        }

        if (!dontSaveUserToLocalstorage) {
            localStorage.setItem('currentUser', JSON.stringify(user));
        }
        userWrite.removeUnnecessaryValues();
        const convertedUser = classToPlain(userWrite);
        delete convertedUser.roles;
        const result: any = await this.httpClient.put(this.baseUrl + '/owner', convertedUser).toPromise();
        return plainToInstance(ExtendedUserEntity, result);
    }

    deleteUnusedObjectsFromUser(diff: any, user?: ExtendedUserEntity) {
        // Alle nicht geänderten Objekte (und arrays) auf der obersten Ebene rauslöschen
        if (user) {
            for (const key of Object.keys(user)) {
                if (!diff.includes(key) && typeof user[key] === 'object') {
                    delete user[key];
                }
            }
        }
    }

    async getUserData(ownerNumber: number, token: string = null): Promise<ExtendedUserEntity | undefined> {
        if (ownerNumber) {
            let header = new HttpHeaders();
            if (token) {
                // Wenn dies während des Logins aufgerufen wird, muss der Token mitgeschickt werden
                header = header.append('Authorization', 'Bearer ' + token);
            }

            const response: any = await this.httpClient.get(this.baseUrl + '/owner/' + ownerNumber.toString(), { headers: header }).toPromise();
            const owner = plainToInstance(ExtendedUserEntity, response);
            owner?.convertBankAccounts();

            return owner;
        }
        return undefined;
    }

    async saveAndValidateVacancyInterface(ownerNumber, url): Promise<VacancyResultDto> {
        if (url === '') {
            url = null;
        }

        const payload: any = {
            ownerNumber,
            url,
        };

        const response: any = await this.httpClient.put(this.baseUrl + '/owner/checkVacancyInterface', payload).toPromise();
        return plainToInstance(VacancyResultDto, response);
    }

    async ownerHasIcal(ownerNumber: number): Promise<boolean> {
        const result = await this.httpClient.get(this.baseUrl + '/owner/owner-has-ical/' + ownerNumber.toString()).toPromise();
        return result as boolean;
    }

    async getIpAndLogInInventory(ownerNumber: number = null, isLogin: boolean = false): Promise<string> {
        let url = this.baseUrl + '/owner/ip';

        if (isLogin) {
            url += '?isLogin';
        }

        if (ownerNumber) {
            url += '&ownerNumber=' + ownerNumber.toString();
        }

        return (await this.httpClient.get(url, { responseType: 'text' }).toPromise()) as string;
    }

    async getDestinationPools(ownerNumber: number, token: string = ''): Promise<any> {
        if (ownerNumber) {
            let header = new HttpHeaders();
            if (token) {
                // Wenn dies während des Logins aufgerufen wird, muss der Token mitgeschickt werden
                header = header.append('Authorization', 'Bearer ' + token);
            }
            return await lastValueFrom(this.httpClient.get(this.baseUrl + '/destination/destination-pool/' + ownerNumber, { headers: header }));
        }
        return null;
    }

    async saveDestinationPoolOwner(poolOwnerData: any): Promise<any> {
        return await this.httpClient.put(this.baseUrl + '/destination/pool-owner', poolOwnerData).toPromise();
    }
}
