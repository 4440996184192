export class InvoiceDto {
    id: string;
    date: Date;
    sum: string;

    // @ts-ignore
    static backOfficeToClass(result: any): InvoiceDto {
        const invoice = new InvoiceDto();
        invoice.id = result.data.id;
        invoice.date = result.data.date;
        invoice.sum = result.data.sum;
        return invoice;
    }
}
