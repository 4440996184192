import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

declare var window: any;
declare var google: any;

@Injectable({
    providedIn: 'root',
})
export class GoogleMapService {
    constructor() {}

    private static promise;
    // @ts-ignore
    map: google.maps.Map;

    markers: any = {};

    static load() {
        if (!GoogleMapService.promise) {
            // load once
            GoogleMapService.promise = new Promise((resolve) => {
                window.onGoogleApiLoaded = () => {
                    resolve(window.googleApi);
                };
                const node = document.createElement('script');
                node.src = `https://maps.googleapis.com/maps/api/js?&key=${environment.googleMapsFrontendKey}&callback=onGoogleApiLoaded&libraries=places`;
                node.type = 'text/javascript';
                document.getElementsByTagName('head')[0].appendChild(node);
            });
        }

        return GoogleMapService.promise;
    }

    init(domElement, callback, lat: number = 50.7127778, lng: number = 9.1260422, zoomLevel = 12): void {
        return GoogleMapService.load().then(() => {
            this.map = new google.maps.Map(domElement, {
                center: new google.maps.LatLng(lat, lng),
                zoom: zoomLevel,
                mapTypeId: google.maps.MapTypeId.HYBRID,
            });
            callback(this.map);
        });
    }

    placeMarker(lat: number, lng: number, clickable: boolean = false, draggable: boolean = false, callback, fileName: string, zoomLevel = 17, setCenter: boolean = false): void {
        if (this.markers[fileName] && this.markers[fileName].setMap && lat && lng) {
            this.markers[fileName].setMap(null);
            if (setCenter) this.map.setCenter(new google.maps.LatLng(lat, lng));
            this.map.setZoom(zoomLevel);
        }

        GoogleMapService.load().then(() => {
            this.markers[fileName] = new google.maps.Marker({
                position: new google.maps.LatLng(lat, lng),
                map: this.map,
                icon: fileName,
                animation: google.maps.Animation.DROP,
                draggable,
                clickable,
            });

            if (clickable) {
                callback(this.markers[fileName]);
            }

            if (draggable) {
                google.maps.event.addListener(this.markers[fileName], 'dragend', () => {
                    callback(this.markers[fileName]);
                });
            }
        });
    }

    getAddress(lat: number, lng: number, callback) {
        GoogleMapService.load().then(() => {
            const geoCoder = new google.maps.Geocoder();

            geoCoder.geocode({ latLng: new google.maps.LatLng(lat, lng) }, callback);
        });
    }

    async getPositionByAddress(address: string, callback) {
        const request = {
            query: address,
            fields: ['geometry', 'place_id'],
        };

        const service = new google.maps.places.PlacesService(this.map);

        service.findPlaceFromQuery(request, callback);
    }
}
