<div *ngIf="accommodationOrGroup">
    <div class="row">
        <div class="col-md mt-3">
            <p [innerHTML]="'atraveo.accommodationbundle.position.title' | translate"></p>
        </div>
    </div>

    <div class="form">
        <div class="row mt-3">
            <div class="col-md" *ngIf="accommodationOrGroup?.position?.address">
                <h4>
                    {{ 'atraveo.accommodationbundle.position.address.title' | translate }}
                    <app-tooltip [text]="'atraveo.accommodationbundle.position.address.info' | translate"></app-tooltip>
                </h4>
                    {{ 'text.street.0' | translate }}<br>
                <input
                    id="input-street"
                    class="form-control"
                    type="text"
                    [(ngModel)]="accommodationOrGroup.position.address.street"
                    #inputStreet="ngModel"
                    [disabled]="isAccommodationActive && authenticationService.currentUser?.isOwner()"
                ><br>

                {{ 'text.postcode' | translate }}<br>
                <input
                        id="input-zipcode"
                        class="form-control"
                        type="text"
                        [(ngModel)]="accommodationOrGroup.position.address.zipCode"
                        #inputZipCode="ngModel"
                        [disabled]="isAccommodationActive && authenticationService.currentUser?.isOwner()"
                ><br>

                {{ 'text.city' | translate }}<br>
                <input
                        id="input-city"
                        class="form-control"
                        type="text"
                        [(ngModel)]="accommodationOrGroup.position.address.city"
                        #inputCity="ngModel"
                        [disabled]="isAccommodationActive && authenticationService.currentUser?.isOwner()"
                ><br>

                {{ 'text.country' | translate }}<br>
                <input
                        id="input-country"
                        class="form-control"
                        type="text"
                        [(ngModel)]="accommodationOrGroup.position.address.country"
                        #inputCountry="ngModel"
                        [disabled]="isAccommodationActive && authenticationService.currentUser?.isOwner()"
                ><br>

                <div *ngIf="accommodationOrGroup?.position?.location">
                    {{ 'text.latitude' | translate }}<br>
                    <input
                        id="input-latitude"
                        class="form-control"
                        type="text"
                        (change)="changeCoordinates()"
                        [(ngModel)]="accommodationOrGroup.position.location.latitude"
                        pattern="^(\+|-)?(?:90(?:(?:[,\.]0{1,10})?)|(?:[0-9]|[1-8][0-9])(?:(?:[,\.][0-9]{1,16})?))$"
                        #inputLatitude="ngModel"
                        [disabled]="isAccommodationActive && authenticationService.currentUser?.isOwner()"
                    ><br>

                    {{ 'text.longitude' | translate }}<br>
                    <input
                        id="input-longitude"
                        class="form-control"
                        type="text"
                        (change)="changeCoordinates()"
                        [(ngModel)]="accommodationOrGroup.position.location.longitude"
                        pattern="^(\+|-)?(?:180(?:(?:[,\.]0{1,10})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:[,\.][0-9]{1,16})?))$"
                        #inputLongitude="ngModel"
                        [disabled]="isAccommodationActive && authenticationService.currentUser?.isOwner()"
                    ><br>
                </div>

            </div>
            <div class="col-md" *ngIf="accommodationOrGroup?.position?.location">
                <div class="row mb-2">
                    <div class="col-md">
                        <h4 class="map-title">{{ 'atraveo.accommodationbundle.position.map.title' | translate }}</h4>
                    </div>
                </div>
                <div class="map" [ngClass]="(isAccommodationActive && authenticationService.currentUser?.isOwner()) ? 'disabled' : ''" id="map"></div>

                <p>{{ 'atraveo.accommodationbundle.position.map.description' | translate }}</p>

                <span class="error" *ngIf="this.switzerLandNotAllowed" [innerHTML]="'atraveo.accommodationbundle.check.error.66' | translate"></span>
                <span class="error" *ngIf="this.accommodationOrGroup.position?.geo?.errorInGeoAssignment">{{ 'error.geoAssignment' | translate }}</span>

                <div class="my-3">
                    <strong>{{ 'atraveo.accommodationbundle.position.legend.label' | translate }}</strong>&nbsp;&nbsp;
                    <img class="icon-24 w-auto" src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png" alt="">
                    {{ 'atraveo.accommodationbundle.position.legend.accommodationposition' | translate }}
                    <img src="assets/icons/cityCenter.png" alt="" height=22>
                    {{ 'atraveo.accommodationbundle.position.legend.citycenter' | translate }}
                </div>

            </div>
        </div>
    </div>

    <hr>

    <div *ngIf="this.authenticationService.currentUser?.isEmployee()" class="row service-box p-3">
        <button class="btn btn-primary" *ngIf="!showGeoTriplett" (click)="showGeoTriplett = true">Geotriplett anzeigen (dann lädt alles langsamer)</button>
        <button class="btn btn-primary" *ngIf="showGeoTriplett" (click)="showGeoTriplett = false">Geotriplett verstecken</button>

        <app-geo-triplet *ngIf="showGeoTriplett && accommodationOrGroup?.position?.geo"
                         idKeyPrefix="position-geo"
                         classKey="form-control custom-select service mt-3"
                         [geoDto]="accommodationOrGroup?.position.geo"
                         [isAccommodationOrGroupActive]="isAccommodationActive && authenticationService.currentUser?.isOwner()"
                         [(ngModel)]="accommodationOrGroup?.position.geo"
                         (change)="updateGeo($event)"
        ></app-geo-triplet>
    </div>

    <div class="row service-box" *ngIf="this.geoNames && this.authenticationService.currentUser?.isEmployee()">
        <div class="col">
            <h3>{{ 'atraveo.accommodationbundle.position.advertised.title' | translate }}
                <app-tooltip [text]="'atraveo.beworbeneregionen' | translate"></app-tooltip>
            </h3>
            <p>{{ 'atraveo.accommodationbundle.position.advertised.found.description' | translate }}</p>

            <table>
                <tr *ngIf="this.geoNames?.cityName">
                    <td>{{ 'atraveo.accommodationbundle.position.advertised.found.city' | translate }}</td>
                    <td>{{ this.geoNames.cityName }}
                        <span *ngIf="authenticationService.currentUser.isEmployee()" class="service">
                            ({{ 'atraveo.accommodationbundle.position.ortid' | translate}} {{ this.accommodationOrGroup?.position?.geo?.cityId }})
                        </span>

                        <button *ngIf="authenticationService.currentUser.isEmployee()" class="btn btn-secondary" (click)="redetermineCity()">{{ 'atraveo.accommodationbundle.position.ort.neu.ermitteln' | translate }}</button>

                        <span *ngIf="authenticationService.currentUser.isEmployee()" class="service">
                            <app-tooltip [additionalClassList]="'mr-2 service'" [text]="'atraveo.accommodationbundle.position.ort.neu.erklaerung' | translate"></app-tooltip>
                        </span>

                    </td>
                </tr>
                <tr *ngIf="this.geoNames?.regionNames?.length">
                    <td>{{ 'atraveo.accommodationbundle.position.advertised.found.regions' | translate }}</td>
                    <td>
                        <span *ngFor="let region of this.geoNames?.regionNames">
                            {{ region }}<br>
                        </span>
                    </td>
                </tr>
                <tr *ngIf="this.geoNames?.countryName">
                    <td>{{ 'atraveo.accommodationbundle.position.advertised.found.country' | translate}}</td>
                    <td>{{ this.geoNames?.countryName }}</td>
                </tr>
            </table>
        </div>
    </div>

    <div class="col-md text-right clear">
        <button class="btn btn-success" (click)="checkAndSave()"><i class="fas fa-cloud-upload-alt mr-2"></i>{{ 'button.save' | translate }}</button>
    </div>

    <pre class="service" *ngIf="authenticationService.currentUser && authenticationService.currentUser.isEmployee()">{{ accommodationOrGroup?.position | json }}</pre>
</div>
