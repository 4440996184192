import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader-service/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    counter: number = 0;
    ignoreUrlParts = [
        'owner/ip',
        'owner/error-count',
        'messages/unread',
        '/seasons/',
        'auth/new-token',
        'geography/city',
        'owner/accommodation-count', // Bei dem bin ich nicht sicher wegen Startseite
        'booking/getNextTravels', // Bei dem bin ich nicht sicher wegen Startseite
        'owner/online-accommodation-count', // Bei dem bin ich nicht sicher wegen Startseite
        '/geography/get-assignment-by-accommodation', // Das ist nur für Admins
        '/accommodation-is-qualifiable', // Das ist nur für Admins
    ];

    constructor(public loaderService: LoaderService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let ignoreRequest = false;

        if (req?.url) {
            for (const urlPart of this.ignoreUrlParts) {
                if (req.url.includes(urlPart)) {
                    ignoreRequest = true;
                }
            }
        }

        if (this.counter === 0) {
            this.loaderService.show();
        }

        if (!ignoreRequest) {
            this.counter++;
        }

        return next.handle(req).pipe(
            finalize(() => {
                if (!ignoreRequest) {
                    this.counter--;
                }

                if (this.counter <= 0) {
                    this.counter = 0;
                    this.loaderService.hide();
                }
            }),
        );
    }
}
