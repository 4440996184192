import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../../entities/extendedAccommodationEntity';

@Component({
    selector: 'app-thumbnail',
    templateUrl: './thumbnail.component.html',
    styleUrls: ['./thumbnail.component.scss']
})
export class ThumbnailComponent implements OnInit, OnChanges {
    @Input() accommodation: ExtendedAccommodationEntity;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
    }
}
