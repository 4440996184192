<h1>{{ 'text.invoice.title' | translate }}</h1>
<ul class="nav nav-tabs nav-justified" id="invoices-tab" role="tablist">
    <li class="nav-item">
        <a class="nav-link"
           [class.active]="this.selectedTab == 'advice'"
           (click)="this.selectedTab='advice'"
           id="advice-tab" data-toggle="tab" href="#advice" role="tab" aria-controls="advice" aria-selected="true">
            {{ 'text.invoice.payment.advice' | translate }}
        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link"
           [class.active]="this.selectedTab == 'commission'"
           (click)="this.selectedTab='commission'"
           id="commission-tab" data-toggle="tab" href="#commission" role="tab" aria-controls="commission" aria-selected="true">
            {{ 'text.invoice.commission.notes' | translate }}
        </a>
    </li>
</ul>

<div class="tab-content p-3">
    <div class="nav-item-sm mt-3" [class.active]="this.selectedTab == 'advice'" (click)="this.selectedTab= this.selectedTab == 'advice' ? '' : 'advice'">
        <i class="fa" [ngClass]="this.selectedTab == 'advice' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'text.invoice.payment.advice' | translate }}
    </div>
    <div class="tab-pane"
         [ngClass]="{'active': this.selectedTab == 'advice', 'show': this.selectedTab == 'advice', 'fade': this.selectedTab != 'advice'}"
         id="advice" role="tabpanel" aria-labelledby="advice-tab">
        <app-invoices-overview [invoiceRequest]="invoiceRequest" [invoices]="invoices" [invoicesCount]="invoicesCount" (getInvoices)="getInvoices()"></app-invoices-overview>
    </div>
    <div class="nav-item-sm mt-3" [class.active]="this.selectedTab == 'commission'" (click)="this.selectedTab= this.selectedTab == 'commission' ? '' : 'commission'">
        <i class="fa" [ngClass]="this.selectedTab == 'commission' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'text.invoice.commission.notes' | translate }}
    </div>
    <div class="tab-pane"
         [ngClass]="{'active': this.selectedTab == 'commission', 'show': this.selectedTab == 'commission', 'fade': this.selectedTab != 'commission'}"
         id="commission" role="tabpanel" aria-labelledby="commission-tab">
        <app-commission-invoices [invoiceRequest]="invoiceRequest" [commissions]="commissions" [commissionCount]="commissionCount" (getCommissions)="getCommissions()"></app-commission-invoices>
    </div>
</div>
