<a (click)="changeDirection();" class="link-primary sort-link">
    <span *ngIf="translationKey">{{ translationKey | translate }}</span>
    <span *ngIf="translation" [innerHTML]="translation"></span>

    <span *ngIf="direction === null || direction === undefined">
        <i class="fas fa-sort"></i>
    </span>
    <span *ngIf="direction === 'ASC'">
        <i class="fas fa-sort-up"></i>
    </span>
    <span *ngIf="direction === 'DESC'">
        <i class="fas fa-sort-down"></i>
    </span>
</a>
