import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '../../../../services/dialog.service';
import { BookingEntity } from 'data-structures/lib/es6/entity/booking.entity';

@Component({
    selector: 'app-not-editable-dialog',
    templateUrl: './not-editable-dialog.component.html',
    styleUrl: './not-editable-dialog.component.scss',
})
export class NotEditableDialogComponent {
    booking: BookingEntity;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, readonly dialogService: DialogService) {
        this.booking = data.booking;
    }

    openBookingDialog() {
        this.data.openDialog(this.booking);
    }

    changeToTab(tabType: string) {
        this.data.changeTab(tabType);
    }

    closeDialog() {
        this.dialogService.closeDialog();
        this.data.cancel();
    }
}
