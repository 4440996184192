import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
    constructor(readonly router: Router, readonly authenticationService: AuthenticationService) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUser;
        if (currentUser) {
            if (this.authenticationService.isTokenValid) {
                return true;
            } else {
                this.authenticationService.logout();
                return false;
            }
        }

        if (
            !window.location.href.includes('create-account') &&
            !window.location.href.includes('login') &&
            !window.location.href.includes('idnow') &&
            !window.location.href.includes('special-login') &&
            !window.location.href.includes('direct-login')
        ) {
            // this.router.url hat irgendwie immer die falsche url, deswegen window.location.href
            await this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        } else {
            return true;
        }
    }
}
