<p class="mt-3" *ngIf="bookingStatusType !== 'open'">{{ 'atraveo.bookingbundle.' + bookingStatusType + '.header' | translate }}</p>

<!--Filterlink -->
<div *ngIf="bookingStatusType" class="mb-3">
    <a id="toggleModal" (click)="openModal()">
        <!--    <a data-toggle="modal" data-target="#filterModal">-->
        <!--        <strong>{{ 'atraveo.bookingbundle.filter.label' | translate }}</strong>:-->
        <!--        <span *ngIf="(!findBookingDto.filter?.channelString) && !findBookingDto.filter?.accommodationId && !findBookingDto.filter?.bookingNumber"> {{ 'text.filter.all.bookings' | translate }}</span>-->
        <span *ngIf="channelString === 'AT' && !findBookingDto.filter?.accommodationIds && !findBookingDto.filter?.bookingNumber"> {{ 'text.filter.only.atraveo.bookings' | translate }}</span>
        <span *ngIf="channelString === 'VM' && !findBookingDto.filter?.accommodationIds && !findBookingDto.filter?.bookingNumber"> {{ 'text.filter.only.own.bookings' | translate }}</span>

        <span *ngIf="findBookingDto.filter?.accommodationId"> {{ 'text.filter.accommodation.number' | translate }} {{ findBookingDto.filter?.accommodationId }} </span>

        <span *ngIf="findBookingDto.filter?.bookingNumber"> {{ 'text.filter.booking.number' | translate }} {{ findBookingDto.filter?.bookingNumber }} </span>

        <span *ngIf="findBookingDto.filter?.arrivalDate"> {{ findBookingDto.filter?.arrivalDate | stringToDate | date: 'shortDate' : undefined: this.translationService.currentLang }}</span>
        <span *ngIf="findBookingDto.filter?.departureDate"> {{ findBookingDto.filter?.departureDate | stringToDate | date: 'shortDate' : undefined: this.translationService.currentLang }}</span>
        <button class="btn btn-primary ml-2" type="button">
            <i class="fa fa-filter mr-2"></i>
            {{ 'filter' | translate }}
        </button>
    </a>
</div>

<!--Buchungstabelle-->
<table class="table responsive-table">
    <thead>
    <tr>
        <th [innerHTML]="'atraveo.bookingbundle.tableheader.booking.bookingNo.text' | translate | shyReplace"></th>
        <th>
            <app-sort-arrows (ngModelChange)="updateSort('arrivalDate')" [(ngModel)]="this.arrivalDateSort.direction" [translationKey]="'atraveo.bookingbundle.tableheader.arrivalDate.text'"></app-sort-arrows>
        </th>
        <th>
            <app-sort-arrows (ngModelChange)="updateSort('departureDate')" [(ngModel)]="this.departureDateSort.direction" [translationKey]="'atraveo.bookingbundle.tableheader.booking.departureDate.text'"></app-sort-arrows>
        </th>
        <th>{{ 'atraveo.bookingbundle.tableheader.accommodation.id.text' | translate }}</th>
        <th>{{ 'atraveo.bookingbundle.tableheader.customer.surname.text' | translate }}</th>
        <th>
            <app-sort-arrows (ngModelChange)="updateSort('bookingDate')" [(ngModel)]="this.bookingDateSort.direction" [translation]="'atraveo.bookingbundle.tableheader.booking.bookingDate.text' | translate | shyReplace"></app-sort-arrows>
        </th>
        <th [innerHTML]="'atraveo.bookingbundle.tableheader.booking.price.text' | translate | shyReplace"></th>
        <th *ngIf="['confirmed', 'cancelled'].includes(bookingStatusType)">{{ 'atraveo.bookingbundle.tableheader.booking.paymentstatus.text' | translate }}</th>
        <th><!-- Icons --></th>
    </tr>
    </thead>
    <tr *ngFor="let booking of this.bookings">
        <td [attr.data-label]="'atraveo.bookingbundle.tableheader.booking.bookingNo.text' | translate | shyReplace">{{ booking.bookingNumber }}</td>
        <td [attr.data-label]="'atraveo.bookingbundle.tableheader.arrivalDate.text' | translate">
            <span *ngIf="booking.arrivalDate">
                {{ dateService.getFormattedDate(booking.arrivalDate, translationService.currentLang, false) }}
            </span>
        </td>
        <td [attr.data-label]="'atraveo.bookingbundle.tableheader.booking.departureDate.text' | translate">
            <span *ngIf="booking.departureDate">
                {{ dateService.getFormattedDate(booking.departureDate, translationService.currentLang, false) }}
            </span>
        </td>
        <td [attr.data-label]="'atraveo.bookingbundle.tableheader.accommodation.id.text' | translate"><a routerLink="/accommodation/{{ booking.accommodationId }}">{{ booking.accommodationId }}</a></td>
        <td [attr.data-label]="'atraveo.bookingbundle.tableheader.customer.surname.text' | translate"> {{ booking.customer?.forename }} {{ booking?.customer?.surname }}</td>
        <td [attr.data-label]="'atraveo.bookingbundle.tableheader.booking.bookingDate.text' | translate | shyReplace">
            <span *ngIf="booking.bookingDates.bookingDate">
                {{ dateService.getFormattedDate(booking.bookingDates.bookingDate.toDateString(), translationService.currentLang, false) }}
            </span>
        </td>
        <td [attr.data-label]="'atraveo.bookingbundle.tableheader.booking.price.text' | translate | shyReplace">
            {{ booking.priceDetail?.price?.amount |  currency : booking.priceDetail?.price?.currency ? booking.priceDetail.price.currency : ownerCurrency }} <br>
            <span *ngIf="booking.priceDetail?.additionalPrice?.amount > 0">
                {{ booking.priceDetail?.additionalPrice?.amount | currency : booking.priceDetail?.additionalPrice?.currency ? booking.priceDetail.additionalPrice.currency : ownerCurrency }} <br>
            </span>
            <span *ngIf="booking.priceDetail?.locationPrice?.amount > 0">
                {{ booking.priceDetail?.locationPrice?.amount | currency : booking.priceDetail?.locationPrice?.currency ? booking.priceDetail.locationPrice.currency : ownerCurrency }} {{ 'atraveo.accommodationbundle.price.create.additionalcost.field.payableAtLocation.short' | translate }} <br>
            </span>
        </td>
        <td [attr.data-label]="'atraveo.bookingbundle.tableheader.booking.paymentstatus.text' | translate"
            *ngIf="['confirmed', 'cancelled'].includes(bookingStatusType)">
            <ng-container *ngIf="bookingStatusType === 'cancelled'">
                <span *ngIf="booking.paymentStatus === 'cancelled' && booking.ownerStatus !== 'declined'">
                    {{ 'atraveo.bookingbundle.paymentstatus.' + booking.paymentStatus | translate }}
                </span>
                <span *ngIf="booking.paymentStatus !== 'cancelled' || booking.ownerStatus === 'declined'">
                    {{ 'atraveo.bookingbundle.status.denied' | translate}}
                </span>
            </ng-container>
            <ng-container *ngIf="bookingStatusType === 'confirmed'">
                <span *ngIf="booking.paymentStatus !== null">
                    {{ 'atraveo.bookingbundle.paymentstatus.' + booking.paymentStatus | translate }}
                </span>
            </ng-container>
        </td>
        <td [attr.data-label]="'atraveo.bookingbundle.tableheader.booking.bookingDetails.text' | translate">
            <button class="btn p-0" (click)="this.viewBooking = booking; openBookingViewDialog()">
                <i class="fas fa-eye fa-lg"></i>
            </button>
            <button class="btn p-0" (click)="openBookingDialog(booking)" *ngIf="booking.channel.includes('VM')">
                <i class="fas fa-pencil-alt fa-lg ml-1"></i>
            </button>
            <a *ngIf="bookingStatusType === 'confirmed' && getEdomizilBookingPdfLink(booking)" href="{{ getEdomizilBookingPdfLink(booking) }}"><i class="fas fa-download fa-lg ml-1"></i></a>
            <button class="btn p-0" *ngIf="bookingStatusType === 'confirmed' && !getEdomizilBookingPdfLink(booking)" (click)="downloadBookingConfirmation(booking.bookingId)">
                <i  class="fas fa-download fa-lg ml-1"></i>
            </button>
            <button class="btn p-0" (click)="openConfirmationDialog(booking.bookingId)" *ngIf="booking.channel.includes('VM')">
                <i class="fas fa-trash-alt fa-lg ml-1"></i>
            </button>
        </td>
    </tr>
</table>
<div class="mt-1">
    <button (click)="openBookingDialog(null)" [attr.data-target]="'#create-booking' + bookingStatusType"
            class="btn btn-link btn-secondary"><i class="fa fa-plus"></i>{{ 'atraveo.bookingbundle.button.new' | translate}}</button>
    <button *ngIf="this.bookings && this.bookings.length" (click)="downloadOverview('pdf')"
            class="btn btn-link btn-primary ml-1" data-download-format=PDF>{{ 'atraveo.bookingbundle.button.pdf' | translate}}</button>
    <button *ngIf="this.bookings && this.bookings.length" (click)="downloadOverview('csv')"
            class="btn btn-link btn-primary ml-1" data-download-format=CSV >{{ 'atraveo.bookingbundle.button.csv' | translate}}</button>
</div>

<div *ngFor="let booking of this.bookings | paginate: { itemsPerPage: findBookingDto.take, currentPage: currentPage, totalItems: this.bookingCount, id: bookingStatusType }"></div>
<pagination-controls [id]="bookingStatusType" class="float-right" (pageChange)="changePage($event)" previousLabel=""
                     nextLabel=""></pagination-controls>
