import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { ApiConnectorService } from '../api-connector/api-connector.service';
import { Subject } from 'rxjs';
import { AccommodationUpdateService } from '../accommodation-update.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorCountService {
    accommodationErrorCountChange: Subject<number> = new Subject<number>();
    accommodationErrorCount: number;

    constructor(readonly authenticationService: AuthenticationService, readonly apiConnectorService: ApiConnectorService, readonly accommodationUpdateService: AccommodationUpdateService) {
        this.accommodationUpdateService.getUpdatedMessage().subscribe(async (updatedAccommodation) => {
            await this.getAccommodationErrorCount();
        });
    }

    async getAccommodationErrorCount() {
        await this.apiConnectorService.getAccommodationErrorCount(this.authenticationService.currentUser).then((accommodationErrorCount) => {
            this.accommodationErrorCount = accommodationErrorCount;
        });

        this.accommodationErrorCountChange.next(this.accommodationErrorCount);

        return this.accommodationErrorCount;
    }
}
