import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ExtendedUserEntity } from '../../../../../entities/extendedUserEntity';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { OwnerService } from '../../../../../services/owner-service/owner.service';
import { NotificationService } from '../../../../../services/notification/notification.service';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { OwnerAddressBookDto } from 'data-structures/lib/es6/dto/owner/OwnerAddressBookDto';
import { OwnerContactDto } from 'data-structures/lib/es6/dto/owner/put-owner/owner-contact.dto';
import { PhoneNumberTypeEnum } from 'data-structures/lib/es6/dto/common/phone-number';
import { OwnerVatDto } from 'data-structures/lib/es6/dto/owner/put-owner/owner-vat.dto';
import { BankAccountComponent } from '../../bank-account/bank-account.component';
import { ActivatedRoute, Router } from '@angular/router';
import { OwnerSettingsDto, OwnerTypeEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { ChannelTypeEnum } from 'data-structures/lib/es6/enum/channel-type.enum';
import { OwnerChannelDto } from 'data-structures/lib/es6/dto/owner/OwnerChannelDto';
import { v4 as uuidv4 } from 'uuid';
import { RegistrationDataService } from '../../../../../services/registration/registration-data.service';
import { GeoCountryEntity } from 'data-structures/lib/es6/entity/geography/geo-country.entity';
import { environment } from 'src/environments/environment';
import { cloneDeep } from 'lodash';
import { SalutationEnum } from 'data-structures/lib/es6/enum/salutation.enum';
import * as Sentry from '@sentry/angular-ivy';
import { UrlLangDetectorService } from '../../../../../services/url-lang-detector/url-lang-detector.service';
import { OwnerTaxIdDto } from 'data-structures/lib/es6/dto/owner/put-owner/owner-tax-id.dto';
import { OwnerTaxDto } from 'data-structures/lib/es6/dto/owner/put-owner/owner-tax.dto';
import { OwnerCurrencyEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DateService } from '../../../../../services/date/date.service';
import { OnboardingDto } from '../../../../../entities/onboarding.dto';
import { Subscription } from 'rxjs';
import { LocationDto } from 'data-structures/lib/es6/dto/accommodation/position/location.dto';
import { DialogService } from '../../../../../services/dialog.service';
import { NewOwnerParams } from '../../destinations/owner-list/owner-list.component';

declare global {
    interface Window {
        gtag_report_conversion: any;
    }
}

@Component({
    selector: 'app-registration-form',
    templateUrl: './registration-form.component.html',
    styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() newUser: boolean = true;
    @Input() isUserProfile: boolean = true;
    @Input() primaryId: string;
    @Input() deletableReason: string;
    @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
    @Input() newOwnerParams: NewOwnerParams;
    @Output() ownerWithPoolSaved: EventEmitter<void> = new EventEmitter<void>();
    registrationForm: FormGroup;
    user: ExtendedUserEntity = new ExtendedUserEntity();
    countriesRaw = [];
    prefixes = [];
    importantCountriesIsoCodes = ['DE', 'DK', 'FR', 'GR', 'IE', 'IT', 'HR', 'NL', 'NO', 'PL', 'SE', 'CH', 'ES', 'US', 'GB', 'AT'];
    partnerId: string = '';
    showHint: boolean = false;
    currentYear: number;

    pageTypes = {
        typePerson: 'person',
        typeCompany: 'company',
        typeOther: 'other',
    };

    @ViewChild(BankAccountComponent) bankAccountComponent: BankAccountComponent;

    errorMessage: string = '';
    spokenLanguages: string[] = ['bg', 'cs', 'da', 'de', 'el', 'en', 'es', 'fi', 'fr', 'hr', 'hu', 'it', 'ja', 'lv', 'nl', 'no', 'pl', 'pt', 'ro', 'ru', 'sk', 'sl', 'sr', 'sv', 'tr', 'zh'];
    isSubmitted: boolean = false;
    selected = 'select.none';
    objectSelected = null;
    allSelected = '';
    pageType: string = this.pageTypes.typePerson;
    isUserVatId: boolean;
    returnUrl: string;
    showAutoFillButton = false;
    primaryDefaultEmailAddressUnique: boolean = true;
    translationServiceSubscription: Subscription;
    currentUserSubscription: Subscription;
    ustTextConst = 'text.ust_id';
    prefix0Subscription: Subscription;
    prefix1Subscription: Subscription;
    prefix2Subscription: Subscription;
    number0Subscription: Subscription;
    number1Subscription: Subscription;
    number2Subscription: Subscription;
    salutationSubscription: Subscription;

    withoutLayout: boolean = false;
    destinationPool: number = 0;

    constructor(
        readonly authService: AuthenticationService,
        readonly ownerService: OwnerService,
        readonly translationService: TranslateService,
        readonly notificationService: NotificationService,
        readonly apiConnector: ApiConnectorService,
        readonly route: ActivatedRoute,
        readonly router: Router,
        readonly registrationData: RegistrationDataService,
        readonly urlLangDetectorService: UrlLangDetectorService,
        readonly formBuilder: FormBuilder,
        readonly dateService: DateService,
        readonly dialogService: DialogService,
    ) {}

    async ngOnInit() {
        this.initRegisterForm();
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
        this.currentYear = new Date().getFullYear();

        if (this.newOwnerParams) {
            if (this.newOwnerParams?.destinationPools) {
                this.destinationPool = this.newOwnerParams?.destinationPools.pop();
            }
            this.withoutLayout = true;
        }

        // Temporär deaktiviert, damit die QA-Stage erst mal wieder funktioniert
        this.currentUserSubscription = this.authService.currentUserSubject.subscribe((user) => {
            if (user && !this.newUser) {
                this.user = user;
                this.initFields();
            }
        });
        if (!localStorage.getItem('contacts') && this.user?.addressBook?.contacts) {
            localStorage.setItem('contacts', String(Object.keys(this.user.addressBook.contacts).length));
        }

        if (!this.countriesRaw.length) {
            this.countriesRaw = await this.apiConnector.getCountries();
            this.langSpecifics();
        }

        this.translationServiceSubscription = this.translationService.onLangChange.subscribe(() => {
            this.langSpecifics();
        });
        this.patchFormValues();
    }

    ngOnDestroy() {
        this.translationServiceSubscription?.unsubscribe();
        this.currentUserSubscription?.unsubscribe();
        this.prefix0Subscription?.unsubscribe();
        this.prefix1Subscription?.unsubscribe();
        this.prefix2Subscription?.unsubscribe();
        this.number0Subscription?.unsubscribe();
        this.number1Subscription?.unsubscribe();
        this.number2Subscription?.unsubscribe();
        this.salutationSubscription?.unsubscribe();
    }

    async langSpecifics() {
        if (this.newUser) {
            this.user = this.ownerService.createNewOwner(this.translationService.currentLang);
            this.primaryId = this.user.addressBook.primaryId;

            this.createSettingsIfNotPresent();
            this.user.settings.language = this.translationService.currentLang;

            if (environment.stage !== 'prod') {
                this.showAutoFillButton = true;
            }

            if (this.newOwnerParams) {
                this.useParams(this.newOwnerParams);
            } else {
                this.route.queryParams.subscribe((params) => {
                    if (this.newUser) {
                        this.useParams(params);
                    }
                });
            }
        } else {
            this.user = this.authService.currentUser;
            if (this.user.settings.client === 5) {
                this.ustTextConst = 'text.ust_id_ch';
            }
            this.initFields();
        }

        const preffered: any[] = [];
        const rest: any[] = [];
        for (const country of this.countriesRaw) {
            if (country.phonePrefix && this.getCountryName(country)) {
                if (this.importantCountriesIsoCodes.includes(country.countryISOCode)) {
                    preffered.push({
                        name: this.getCountryName(country) + ' (+' + country.phonePrefix + ')',
                        value: '+' + country.phonePrefix,
                    });
                } else {
                    rest.push({
                        name: this.getCountryName(country) + ' (+' + country.phonePrefix + ')',
                        value: '+' + country.phonePrefix,
                    });
                }
            }
        }

        preffered.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        rest.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });

        this.prefixes = [];
        const moreCountriesText = await this.translationService.get('text.select.moreCountries').toPromise();
        const allCountriesText = await this.translationService.get('text.select.empty').toPromise();
        this.prefixes = [{ value: '', name: allCountriesText }, ...preffered, { value: '', name: '--' + moreCountriesText + '--', disabled: true }, ...rest];
    }

    useParams(params) {
        this.user.settings = this.user?.settings || new OwnerSettingsDto();
        if (!params.mandant || (params.mandant && params.mandant !== '5')) {
            this.user.settings.currency = OwnerCurrencyEnum.EUR;
        }

        if (params.mandant || params.herkunftmandant) {
            if (params.mandant) {
                this.user.settings.client = Number(params.mandant);
                if (params.mandant === '5') {
                    this.user.settings.currency = OwnerCurrencyEnum.CHF;
                }
            } else if (!this.user.settings.client) {
                this.user.settings.client = 1;
            }

            if (params.herkunftmandant) {
                this.user.settings.clientOrigin = Number(params.herkunftmandant);
            } else if (!this.user.settings.clientOrigin) {
                this.user.settings.clientOrigin = 14;
            } else {
                if (this.user.settings?.client === 5) {
                    delete this.user.settings?.clientOrigin;
                }
            }
        }

        if (params.partner) {
            if (!this.user.channels) {
                this.user.channels = [];
            }

            let edomizilChannel = this.user.getChannelByType(ChannelTypeEnum.EDOMIZIL);
            if (!edomizilChannel) {
                edomizilChannel = new OwnerChannelDto();
                edomizilChannel.type = ChannelTypeEnum.EDOMIZIL;
            }

            edomizilChannel.partnerId = params.partner;

            this.user.channels.push(edomizilChannel);
        }
    }

    get emailArray() {
        return this.registrationForm.get('emails') as FormArray;
    }

    get taxIdDetails(): FormArray {
        return this.registrationForm.get('taxIdDetails') as FormArray;
    }

    initRegisterForm() {
        this.registrationForm = new FormGroup<any>({
            ownerType: new FormControl(''),
            salutation: new FormControl('', Validators.required),
            forename: new FormControl('', this.newUser ? Validators.required : null),
            surname: new FormControl('', Validators.required),
            company: new FormControl('', this.pageType === this.pageTypes.typeCompany ? Validators.required : null),
            street: new FormControl('', this.newUser || this.isUserProfile ? Validators.required : null),
            zipCode: new FormControl('', this.newUser || this.isUserProfile ? Validators.required : null),
            city: new FormControl('', this.newUser || this.isUserProfile ? Validators.required : null),
            countryISOCode: new FormControl('', this.newUser || this.isUserProfile ? Validators.required : null),
            prefix0: new FormControl(''),
            number0: new FormControl(''),
            prefix1: new FormControl(''),
            number1: new FormControl(''),
            prefix2: new FormControl(''),
            number2: new FormControl(''),
            emails: new FormArray([]),
            latitude: new FormControl(''),
            longitude: new FormControl(''),
            smsNotifications: new FormControl(''),
            website: new FormControl(''),
            birthdate: new FormControl(''),
            hasVatId: new FormControl('', !this.newUser && this.isUserProfile ? Validators.required : null),
            vatId: new FormControl('', this.requiredVatIdValidator()),
            hasTaxId: new FormControl(''),
            taxIdDetails: this.formBuilder.array([]),
            companyRegistrationNumber: new FormControl(''),
            placeOfBirth: new FormControl(''),
        });

        if (!this.newUser && this.isUserProfile && !this.authService.currentUser.isEmployee()) {
            this.registrationForm.get('forename').disable();
            this.registrationForm.get('surname').disable();
            this.registrationForm.get('company').disable();
            this.registrationForm.get('street').disable();
            this.registrationForm.get('zipCode').disable();
            this.registrationForm.get('city').disable();
            this.registrationForm.get('countryISOCode').disable();
        }
        // VRMB-3328: neue Telefon-Validierung
        if (!this.newUser) {
            this.number0Subscription = this.registrationForm.get('number0').valueChanges.subscribe((value) => {
                this.validatePhoneTuple(value, 0);
            });
            this.number1Subscription = this.registrationForm.get('number1').valueChanges.subscribe((value) => {
                this.validatePhoneTuple(value, 1);
            });
            this.number2Subscription = this.registrationForm.get('number2').valueChanges.subscribe((value) => {
                this.validatePhoneTuple(value, 2);
            });
            this.registrationForm.setValidators(this.prefixNumberCombinationValidator);
        } else {
            this.registrationForm.get('prefix2').setValidators([Validators.required]);
            this.prefix2Subscription = this.registrationForm.get('prefix2').valueChanges.subscribe((value) => {
                if (value) {
                    const newVal = value.replace(/[^\d+]/g, '');
                    if (newVal !== value) {
                        this.registrationForm.get(`prefix2`).setValue(newVal);
                    }
                }
            });

            this.registrationForm.get('number2').setValidators([Validators.required]);
            this.number2Subscription = this.registrationForm.get('number2').valueChanges.subscribe((value) => {
                if (value) {
                    const newVal = value.replace(/[^\d+]/g, '');
                    if (newVal !== value) {
                        this.registrationForm.get(`number2`).setValue(newVal);
                    }
                }
            });
        }

        this.salutationSubscription = this.registrationForm.get('salutation').valueChanges.subscribe((value) => {
            this.registrationForm.get('company').clearValidators();
            if (value === SalutationEnum.Company) {
                this.pageType = this.pageTypes.typeCompany;
                this.registrationForm.get('company').setValidators(Validators.required);
            } else if (value === SalutationEnum.Other) {
                this.pageType = this.pageTypes.typeOther;
            } else {
                this.pageType = this.pageTypes.typePerson;
            }
            this.registrationForm.get('company').updateValueAndValidity({ emitEvent: false });
        });
    }

    getEmailValidator() {
        return this.newUser || this.isUserProfile ? [Validators.required, Validators.email] : [Validators.email];
    }

    prefixNumberCombinationValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
        const prefix0 = formGroup.get('prefix0');
        const number0 = formGroup.get('number0');
        const prefix1 = formGroup.get('prefix1');
        const number1 = formGroup.get('number1');
        const prefix2 = formGroup.get('prefix2');
        const number2 = formGroup.get('number2');

        // Überprüfen, ob eine der Kombinationen ausgefüllt ist
        if ((prefix0.value && number0.value) || (prefix1.value && number1.value) || (prefix2.value && number2.value)) {
            // Gültig, wenn mindestens eine Kombination ausgefüllt ist
            return null;
        } else {
            // Ungültig, wenn keine Kombination ausgefüllt ist
            return { prefixNumberCombination: true };
        }
    };

    patchFormValues() {
        if (!this.newUser) {
            this.taxIdDetails.clear();
            for (const taxId of this.user.tax.taxIds) {
                const taxDetails = this.formBuilder.group({
                    id: [taxId.id],
                    countryISOCode: [taxId.countryISOCode],
                });
                this.taxIdDetails.push(taxDetails);
            }
        }

        this.registrationForm.patchValue({
            ownerType: this.newUser ? OwnerTypeEnum.PrivateOwner : this.user.settings?.ownerType,
            salutation: this.user.addressBook.contacts[this.primaryId]?.salutation || '',
            forename: this.user.addressBook.contacts[this.primaryId]?.forename,
            surname: this.user.addressBook.contacts[this.primaryId]?.surname,
            company: this.user.addressBook.contacts[this.primaryId]?.company,
            street: this.user.addressBook.contacts[this.primaryId]?.street,
            zipCode: this.user.addressBook.contacts[this.primaryId]?.zipCode,
            city: this.user.addressBook.contacts[this.primaryId]?.city,
            countryISOCode: this.user.addressBook.contacts[this.primaryId]?.countryISOCode || '',
            prefix0: this.user.addressBook.contacts[this.primaryId]?.phoneNumbers[0]?.prefix || '',
            number0: this.user.addressBook.contacts[this.primaryId]?.phoneNumbers[0]?.number,
            prefix1: this.user.addressBook.contacts[this.primaryId]?.phoneNumbers[1]?.prefix || '',
            number1: this.user.addressBook.contacts[this.primaryId]?.phoneNumbers[1]?.number,
            prefix2: this.user.addressBook.contacts[this.primaryId]?.phoneNumbers[2]?.prefix || '',
            number2: this.user.addressBook.contacts[this.primaryId]?.phoneNumbers[2]?.number,
            emails: this.user.addressBook.contacts[this.primaryId]?.email || [],
            latitude: this.user.addressBook.contacts[this.primaryId]?.location?.latitude,
            longitude: this.user.addressBook.contacts[this.primaryId]?.location?.longitude,
            smsNotifications: this.user.settings?.smsNotifications,
            website: this.user.registration?.website,
            birthdate: this.user.addressBook.contacts[this.primaryId]?.birthdate,
            hasVatId: this.user.vat?.hasVatId,
            vatId: this.user.vat?.id,
            hasTaxId: this.user.tax?.hasTaxId,
            companyRegistrationNumber: this.user.tax?.companyRegistrationNumber,
            placeOfBirth: this.user.addressBook.contacts[this.primaryId]?.placeOfBirth,
        });

        if (this.user.addressBook.contacts[this.primaryId]?.email.length) {
            this.emailArray.clear();
            for (const email of this.user.addressBook.contacts[this.primaryId].email) {
                this.emailArray.push(new FormControl(email, this.getEmailValidator()));
            }
        }
    }

    requiredVatIdValidator() {
        return (control: AbstractControl) => {
            const hasVatId = control.parent?.get('hasVatId').value;
            return hasVatId ? Validators.required(control) : null;
        };
    }

    initFields() {
        const userClone = cloneDeep(this.user);

        if (userClone && !userClone.addressBook) {
            userClone.addressBook = new OwnerAddressBookDto();
        }

        if (userClone && !userClone.addressBook.contacts) {
            userClone.addressBook.contacts = {};
        }

        if (!userClone.addressBook.contacts[this.primaryId]) {
            this.ownerService.createNewContact(userClone, this.primaryId);
        }

        if (userClone && !Object.keys(userClone.addressBook.contacts).length) {
            this.primaryId = uuidv4();
            userClone.addressBook.contacts = { [this.primaryId]: new OwnerContactDto() };
        }

        if (userClone && !userClone.addressBook.contacts[this.primaryId]?.phoneNumbers?.length) {
            userClone.addressBook.contacts[this.primaryId].phoneNumbers = [];
            userClone.addressBook.contacts[this.primaryId].phoneNumbers.push(this.ownerService.createPhoneNumber(PhoneNumberTypeEnum.phone));
            userClone.addressBook.contacts[this.primaryId].phoneNumbers.push(this.ownerService.createPhoneNumber(PhoneNumberTypeEnum.phone));
            userClone.addressBook.contacts[this.primaryId].phoneNumbers.push(this.ownerService.createPhoneNumber(PhoneNumberTypeEnum.mobile));
        }
        for (let i = 0; i < 3; i++) {
            if (userClone.addressBook.contacts[this.primaryId].phoneNumbers[i]?.type === PhoneNumberTypeEnum.mobile && i !== 2) {
                userClone.addressBook.contacts[this.primaryId].phoneNumbers[2] = userClone.addressBook.contacts[this.primaryId].phoneNumbers[i];
                delete userClone.addressBook.contacts[this.primaryId].phoneNumbers[i];
            }
            if (!userClone.addressBook.contacts[this.primaryId].phoneNumbers[i]) {
                userClone.addressBook.contacts[this.primaryId].phoneNumbers[i] = this.ownerService.createPhoneNumber(i === 2 ? PhoneNumberTypeEnum.mobile : PhoneNumberTypeEnum.phone);
            }
        }

        if (!userClone.addressBook.contacts[this.primaryId].email) {
            userClone.addressBook.contacts[this.primaryId].email = [''];
        }

        if (userClone && !userClone.vat) {
            userClone.vat = new OwnerVatDto();
        }

        if (!userClone.settings) {
            userClone.settings = new OwnerSettingsDto();
        }

        if (userClone && !userClone.tax) {
            userClone.tax = new OwnerTaxDto();
        }

        if (!userClone.tax?.taxIds) {
            userClone.tax.taxIds = [];
            userClone.tax.taxIds.push(new OwnerTaxIdDto());
        }

        this.user = userClone;
    }

    ngAfterViewInit() {
        this.route.queryParams.subscribe((params) => {
            this.partnerId = params.partner;
        });
    }

    createSettingsIfNotPresent() {
        if (!this.user.settings) {
            this.user.settings = new OwnerSettingsDto();
        }
    }

    setValidator(required: boolean) {
        if (required === false) {
            this.registrationForm.get('vatId').setValue('');
            this.registrationForm.get('vatId').clearValidators();
            this.registrationForm.get('vatId').updateValueAndValidity();
        } else {
            this.registrationForm.get('vatId').setValidators(Validators.required);
        }
    }

    private validatePhoneTuple(value, index) {
        if (value) {
            const newVal = value.replace(/[^\d]/g, '');
            if (newVal !== value) {
                this.registrationForm.get(`number${index}`).setValue(newVal);
            }
            this.registrationForm.get(`prefix${index}`).setValidators([Validators.required]);
        } else {
            this.registrationForm.get(`prefix${index}`).clearValidators();
        }
        this.registrationForm.get(`prefix${index}`).updateValueAndValidity({ emitEvent: false });
    }

    validateEmails(user: ExtendedUserEntity) {
        for (const contactId of Object.keys(this.user.addressBook.contacts)) {
            if (contactId !== user.addressBook.primaryId && this.user.addressBook.contacts[contactId].email) {
                this.user.addressBook.contacts[contactId].email.reverse().forEach((email, index) => {
                    if (email === '') {
                        this.user.addressBook.contacts[contactId].email.splice(index, 1);
                    }
                });
                if (!this.user.addressBook.contacts[contactId].email.length) {
                    delete this.user.addressBook.contacts[contactId].email;
                }
            }
        }
    }

    async checkUniquePrimaryDefaultEmailAddress() {
        this.primaryDefaultEmailAddressUnique = true;
        const invPrimaryDefaultEmailAddress = this.user.addressBook.contacts[this.primaryId]?.email ? this.user.addressBook.contacts[this.primaryId]?.email[0] : '';
        const formPrimaryDefaultEmailAddress = this.registrationForm.get('emails').value ? this.registrationForm.get('emails').value[0] : '';
        const emailChanges = invPrimaryDefaultEmailAddress !== formPrimaryDefaultEmailAddress;
        if (emailChanges) {
            this.primaryDefaultEmailAddressUnique = await this.apiConnector.isPrimaryDefaultEmailAddressUnique(this.user.ownerNumber, formPrimaryDefaultEmailAddress);
            if (!this.primaryDefaultEmailAddressUnique) {
                return false;
            }
        }
        return true;
    }

    async save(): Promise<void> {
        const isPrimaryDefaultEmailAddressUnique = !this.newUser && this.isUserProfile ? !!(await this.checkUniquePrimaryDefaultEmailAddress()) : true;

        this.registrationForm.markAllAsTouched();
        this.isSubmitted = true;
        if (this.registrationForm.invalid || isPrimaryDefaultEmailAddressUnique === false) {
            const formErrors = [];

            if (this.newUser) {
                if (formErrors.length) {
                    (window as any).dataLayer.push({
                        event: 'form_submission_registration_error',
                        error_type: formErrors,
                    });
                }
            }
            return;
        } else {
            this.user.settings.ownerType = this.registrationForm.get('ownerType').value;
            this.user.addressBook.contacts[this.primaryId].salutation = this.registrationForm.get('salutation').value;
            this.user.addressBook.contacts[this.primaryId].forename = this.registrationForm.get('forename').value;
            this.user.addressBook.contacts[this.primaryId].surname = this.registrationForm.get('surname').value;
            this.user.addressBook.contacts[this.primaryId].company = this.registrationForm.get('company').value;
            this.user.addressBook.contacts[this.primaryId].street = this.registrationForm.get('street').value;
            this.user.addressBook.contacts[this.primaryId].zipCode = this.registrationForm.get('zipCode').value;
            this.user.addressBook.contacts[this.primaryId].city = this.registrationForm.get('city').value;
            this.user.addressBook.contacts[this.primaryId].countryISOCode = !this.registrationForm.get('countryISOCode').value ? undefined : this.registrationForm.get('countryISOCode').value;
            this.user.addressBook.contacts[this.primaryId].phoneNumbers[2].prefix = this.registrationForm.get('number2').value ? this.registrationForm.get('prefix2').value : '';
            this.user.addressBook.contacts[this.primaryId].phoneNumbers[2].number = this.registrationForm.get('number2').value;
            this.user.addressBook.contacts[this.primaryId].email = this.registrationForm.get('emails').value;
            if (!this.newUser) {
                if (!this.isUserProfile) {
                    this.user.addressBook.contacts[this.primaryId].location = this.user.addressBook.contacts[this.primaryId].location ?? new LocationDto();
                    this.user.addressBook.contacts[this.primaryId].location.latitude = Number(this.registrationForm.get('latitude').value);
                    this.user.addressBook.contacts[this.primaryId].location.longitude = Number(this.registrationForm.get('longitude').value);
                }
                this.user.addressBook.contacts[this.primaryId].phoneNumbers[0].prefix = this.registrationForm.get('number0').value ? this.registrationForm.get('prefix0').value : '';
                this.user.addressBook.contacts[this.primaryId].phoneNumbers[0].number = this.registrationForm.get('number0').value;
                this.user.addressBook.contacts[this.primaryId].phoneNumbers[1].prefix = this.registrationForm.get('number1').value ? this.registrationForm.get('prefix1').value : '';
                this.user.addressBook.contacts[this.primaryId].phoneNumbers[1].number = this.registrationForm.get('number1').value;
                this.user.settings.smsNotifications = this.registrationForm.get('smsNotifications').value;
                this.user.registration.website = this.registrationForm.get('website').value;
                this.user.addressBook.contacts[this.primaryId].birthdate = this.registrationForm.get('birthdate').value;
                this.user.vat.hasVatId = this.registrationForm.get('hasVatId').value;
                this.user.vat.id = this.registrationForm.get('vatId').value;
                this.user.tax.hasTaxId = this.registrationForm.get('hasTaxId').value;
                this.user.tax.taxIds = this.registrationForm.get('taxIdDetails').value;
                this.user.tax.companyRegistrationNumber = this.registrationForm.get('companyRegistrationNumber').value;
                this.user.addressBook.contacts[this.primaryId].placeOfBirth = this.registrationForm.get('placeOfBirth').value;
                const userTracking = this.user?.tracking;
                if (userTracking?.onboarding) {
                    userTracking.onboarding.completeProfileStarted = new Date();
                }
            } else {
                if (this.user.settings.ownerType === OwnerTypeEnum.PrivateOwner) {
                    this.user.tracking.onboarding = new OnboardingDto();
                    this.user.tracking.onboarding.registrationStarted = new Date();
                }
            }
        }
        if (this?.authService?.currentUser?.addressBook?.contacts && Object.keys(this?.authService?.currentUser?.addressBook?.contacts).length > 1) {
            const contactList = Object.keys(this.authService.currentUser.addressBook.contacts).map((key) => key);
            let index = 0;
            const addressBookContacts = cloneDeep(this.authService.currentUser.addressBook.contacts);

            for await (const key of Object.keys(addressBookContacts)) {
                if (key !== this.primaryId) {
                    this.user.addressBook.contacts[key] = addressBookContacts[key];
                }

                if (Object.keys(this.authService.currentUser.addressBook.contacts).length === Number(index) + 1) {
                    const isNumberDefined = this.authService.currentUser.addressBook?.contacts[contactList[index]]?.phoneNumbers.find((phoneNumber) => phoneNumber.prefix && phoneNumber.number);
                    if (
                        contactList[index] !== this.primaryId &&
                        (!this.authService.currentUser.addressBook?.contacts[contactList[index]]?.salutation ||
                            !this.authService.currentUser.addressBook?.contacts[contactList[index]]?.surname ||
                            !isNumberDefined)
                    ) {
                        delete this.authService.currentUser.addressBook.contacts[contactList[index]];
                        delete this.user.addressBook.contacts[contactList[index]];
                    }

                    await this.saveUser();
                }

                index++;
            }
        } else {
            await this.saveUser();
        }
    }

    async saveUser() {
        if (this.newUser) {
            window.gtag_report_conversion();
        }

        this.validateEmails(this.user);

        try {
            if (this.user.addressBook?.primaryId) {
                this.user.addressBook.primaryId = String(this.user.addressBook.primaryId);
            }
            if (this.newUser) {
                await this.urlLangDetectorService.checkUrlLangAndRemove();
                await this.registrationData.setNewUser();
                const country = this.user.addressBook.contacts[this.user.addressBook.primaryId].countryISOCode;
                this.user.termsOfServiceSettings.acceptedId = 1;
                if (this.user.settings.language === 'da' || this.user.settings.language === 'sv') {
                    this.user.settings.language = 'en';
                }
                this.user.settings.directBooking = true;
                await this.authService.registerUser(this.user, this.destinationPool).then(async () => {
                    (window as any).dataLayer.push({
                        event: 'form_submission_registration',
                        registration_country: country,
                        partner_ID: this.partnerId,
                    });
                });
                if (this.destinationPool) {
                    this.ownerWithPoolSaved.emit();
                }
                // Todo: Ist noch Jquery. Wirds noch gebraucht?
                // @ts-ignore
                $('.modal-backdrop').hide();
            } else {
                const ownerNumber = await this.authService.saveOwner(this.user).then((result) => {
                    // Das Feld muss für das Frontend erneut initialisiert werden, jedoch ohne die Änderungen zu speichern
                    if (this.user.tax?.hasTaxId && !this.user.tax?.taxIds.length) {
                        this.user.tax.taxIds.push(new OwnerTaxIdDto());
                    }
                    return result;
                });

                if (!ownerNumber) {
                    return;
                }
            }

            this.errorMessage = '';
            if (!this.isUserProfile) {
                if (localStorage.getItem('contacts') !== String(Object.keys(this.user.addressBook.contacts).length)) {
                    await this.notificationService.add('text.flashbag.profile.contact.person.created', 'success');
                    localStorage.setItem('contacts', String(Object.keys(this.user.addressBook.contacts).length));
                } else {
                    await this.notificationService.add('text.flashbag.profile.contact.person.updated', 'success');
                }
            } else {
                if (this.newUser) {
                    await this.notificationService.add('text.flashbag.profile.register', 'success');
                } else {
                    await this.notificationService.add('text.flashbag.profile.success', 'success');
                }
            }
            this.dialogService.closeDialog();
            this.onSave.emit();
        } catch (e) {
            Sentry.captureException(e);
            if (e.toString().includes(['OWNER2'])) {
                this.errorMessage = 'atraveo.accommodationbundle.fast.user.emailtaken';
                (window as any).dataLayer.push({
                    event: 'form_submission_registration_error',
                    error_type: 'email_already_exists',
                });
                await this.notificationService.add('atraveo.accommodationbundle.fast.user.emailtaken', 'danger');
            } else {
                this.errorMessage = 'atraveo.accommodationbundle.fast.user.emailtaken';
                await this.notificationService.add('form.save.failure', 'danger');
            }
        }
    }

    // Todo: Sollten wir auch nochmal umbauen und mit den Variablen hier ändern
    changeStateAll(event) {
        this.user.addressBook.contacts[this.primaryId].spokenLanguages = [];
        if (event.target.checked) {
            document.querySelectorAll('.objectCheck').forEach((element) => {
                this.user.addressBook.contacts[this.primaryId].spokenLanguages.push(element.getAttribute('value'));
            });
        }
        this.objectSelected = this.user.addressBook.contacts[this.primaryId].spokenLanguages.length !== 0 ? this.user.addressBook.contacts[this.primaryId].spokenLanguages.length : null;
        this.selected = this.user.addressBook.contacts[this.primaryId].spokenLanguages.length !== 0 ? 'select.n' : 'select.none';
    }

    changeState(event) {
        if (event.target.checked) {
            this.user.addressBook.contacts[this.primaryId].spokenLanguages = this.user.addressBook.contacts[this.primaryId].spokenLanguages ?? [];
            this.user.addressBook.contacts[this.primaryId].spokenLanguages.push(event.target.value);
            this.objectSelected = this.user.addressBook.contacts[this.primaryId].spokenLanguages.length;
            this.selected = 'select.n';
        } else {
            this.user.addressBook.contacts[this.primaryId].spokenLanguages = this.user.addressBook.contacts[this.primaryId].spokenLanguages.filter((item) => !event.target.value.includes(item));
            this.objectSelected = this.user.addressBook.contacts[this.primaryId].spokenLanguages.length !== 0 ? this.user.addressBook.contacts[this.primaryId].spokenLanguages.length : null;
            this.selected = this.user.addressBook.contacts[this.primaryId].spokenLanguages.length !== 0 ? 'select.n' : 'select.none';
        }
        this.allSelected = '';
    }

    fixUrl() {
        if (this.user.registration?.website && !this.user.registration.website.startsWith('http')) {
            this.user.registration.website = 'https://' + this.user.registration.website;
        }
    }

    addEmail() {
        this.emailArray.push(new FormControl('', this.getEmailValidator()));
    }

    addTaxId() {
        const taxDetails = this.formBuilder.group({
            id: [''],
            countryISOCode: [''],
        });
        this.taxIdDetails.push(taxDetails);
    }

    deleteEmail(i) {
        this.emailArray.removeAt(i);
    }

    deleteTaxId(i) {
        this.taxIdDetails.removeAt(i);
    }

    setPhonePrefix(phonePrefix: string) {
        if (!this.registrationForm.get('number2').value) {
            this.registrationForm.get('prefix2').setValue('+' + phonePrefix);
        }
    }

    getCountryName(country: GeoCountryEntity): string {
        let countryName = '';

        if (!country.text) {
            return countryName;
        }

        if (country.text[this.translationService.currentLang]) {
            countryName = country.text[this.translationService.currentLang];
            // tslint:disable-next-line:no-string-literal
        } else if (country.text['en']) {
            // tslint:disable-next-line:no-string-literal
            countryName = country.text['en'];
        } else if (Object.keys(country)) {
            countryName = country.text[0];
        }

        return countryName;
    }

    get taxIdDetailsControls(): FormGroup[] {
        return this.taxIdDetails.controls as FormGroup[];
    }

    // Formular schnell ausfüllen zum schnellen Testen (nur auf Dev und QA verfügbar)
    autoFillForm() {
        // Kopiert aus V2 und leicht angepasst
        const gender = Math.random() > 0.5 ? SalutationEnum.Mr : SalutationEnum.Ms;

        // http://www.beliebte-vornamen.de/jahrgang/j2013/top500-2013
        const firstNames = {
            [SalutationEnum.Ms]: [
                'Mia',
                'Emma',
                'Hannah',
                'Sofia',
                'Anna',
                'Lea',
                'Emilia',
                'Marie',
                'Lena',
                'Leonie',
                'Emily',
                'Lina',
                'Amelie',
                'Sophie',
                'Lilly',
                'Luisa',
                'Johanna',
                'Laura',
                'Nele',
                'Lara',
                'Maja',
                'Charlotte',
                'Clara',
                'Leni',
                'Sarah',
                'Pia',
                'Mila',
                'Alina',
                'Lisa',
                'Lotta',
                'Ida',
                'Julia',
                'Greta',
                'Mathilda',
                'Melina',
                'Zoe',
                'Frieda',
                'Lia',
                'Paula',
                'Marlene',
                'Ella',
                'Emely',
                'Jana',
                'Victoria',
                'Josephine',
                'Finja',
                'Isabell',
                'Helena',
                'Isabella',
                'Elisa',
                'Amy',
                'Mara',
                'Mira',
                'Katharina',
                'Jasmin',
                'Stella',
                'Lucy',
                'Luise',
                'Antonia',
                'Annika',
                'Fiona',
                'Pauline',
                'Nora',
                'Eva',
                'Jule',
                'Magdalena',
                'Luna',
                'Merle',
                'Carla',
                'Maria',
                'Nina',
                'Theresa',
                'Melissa',
                'Franziska',
                'Martha',
                'Milena',
                'Chiara',
                'Ronja',
                'Carlotta',
                'Elena',
                'Romy',
                'Mina',
                'Helene',
                'Selina',
                'Annabell',
                'Paulina',
                'Vanessa',
                'Maila',
                'Anni',
                'Fabienne',
                'Zoey',
                'Sina',
                'Miriam',
                'Leila',
                'Linda',
                'Aylin',
                'Samira',
                'Elina',
                'Jolina',
                'Celina',
            ],
            [SalutationEnum.Mr]: [
                'Ben',
                'Luca',
                'Paul',
                'Jonas',
                'Finn',
                'Leon',
                'Luis',
                'Lukas',
                'Maximilian',
                'Felix',
                'Noah',
                'Elias',
                'Julian',
                'Max',
                'Tim',
                'Moritz',
                'Henry',
                'Niklas',
                'Philipp',
                'Jakob',
                'Tom',
                'Jan',
                'Emil',
                'Alexander',
                'David',
                'Oskar',
                'Fabian',
                'Anton',
                'Erik',
                'Rafael',
                'Matteo',
                'Leo',
                'Mats',
                'Simon',
                'Jannik',
                'Lennard',
                'Liam',
                'Linus',
                'Hannes',
                'Mika',
                'Vincent',
                'Adrian',
                'Jonathan',
                'Theo',
                'Nico',
                'Till',
                'Benjamin',
                'Florian',
                'Marlon',
                'Julius',
                'Nick',
                'Samuel',
                'Nils',
                'Johannes',
                'Jona',
                'Carl',
                'Daniel',
                'Lennox',
                'Aaron',
                'Mattis',
                'Ole',
                'Leonard',
                'Constantin',
                'Sebastian',
                'Jannis',
                'Colin',
                'Joel',
                'Tobias',
                'Lenny',
                'Milan',
                'Johann',
                'Joshua',
                'Dominic',
                'Maxim',
                'John',
                'Mohammed',
                'Timo',
                'Robin',
                'Valentin',
                'Jayden',
                'Benedikt',
                'Justus',
                'Levin',
                'Damian',
                'Phil',
                'Toni',
                'Levi',
                'Jamie',
                'Lian',
                'Kilian',
                'Malte',
                'Noel',
                'Jason',
                'Bennet',
                'Tyler',
                'Gabriel',
                'Sam',
                'Michael',
                'Artur',
                'Bastian',
            ],
        };

        // https://de.wikipedia.org/wiki/Liste_der_h%C3%A4ufigsten_Familiennamen_in_Deutschland
        const lastNames = [
            'Müller',
            'Schmidt',
            'Schneider',
            'Fischer',
            'Weber',
            'Meyer',
            'Wagner',
            'Becker',
            'Schulz',
            'Hoffmann',
            'Schäfer',
            'Koch',
            'Bauer',
            'Richter',
            'Klein',
            'Wolf',
            'Schröder',
            'Neumann',
            'Schwarz',
            'Zimmermann',
            'Braun',
            'Krüger',
            'Hofmann',
            'Hartmann',
            'Lange',
            'Schmitt',
            'Werner',
            'Schmitz',
            'Krause',
            'Meier',
            'Lehmann',
            'Schmid',
            'Schulze',
            'Maier',
            'Köhler',
            'Herrmann',
            'König',
            'Walter',
            'Mayer',
            'Huber',
            'Kaiser',
            'Fuchs',
            'Peters',
            'Lang',
            'Scholz',
            'Möller',
            'Weiß',
            'Jung',
            'Hahn',
            'Schubert',
            'Vogel',
            'Friedrich',
            'Keller',
            'Günther',
            'Frank',
            'Berger',
            'Winkler',
            'Roth',
            'Beck',
            'Lorenz',
            'Baumann',
            'Franke',
            'Albrecht',
            'Schuster',
            'Simon',
            'Ludwig',
            'Böhm',
            'Winter',
            'Kraus',
            'Martin',
            'Schumacher',
            'Krämer',
            'Vogt',
            'Stein',
            'Jäger',
            'Otto',
            'Sommer',
            'Groß',
            'Seidel',
            'Heinrich',
            'Brandt',
            'Haas',
            'Schreiber',
            'Graf',
            'Schulte',
            'Dietrich',
            'Ziegler',
            'Kuhn',
            'Kühn',
            'Pohl',
            'Engel',
            'Horn',
            'Busch',
            'Bergmann',
            'Thomas',
            'Voigt',
            'Sauer',
            'Arnold',
            'Wolff',
            'Pfeiffer',
        ];

        // http://www.strassen-in-deutschland.de/die-haeufigsten-strassennamen-in-deutschland.html
        const streetNames = [
            'Hauptstraße',
            'Schulstraße',
            'Gartenstraße',
            'Bahnhofstraße',
            'Dorfstraße',
            'Bergstraße',
            'Birkenweg',
            'Lindenstraße',
            'Kirchstraße',
            'Waldstraße',
            'Ringstraße',
            'Schillerstraße',
            'Goethestraße',
            'Amselweg',
            'Jahnstraße',
            'Buchenweg',
            'Wiesenweg',
            'Wiesenstraße',
            'Finkenweg',
            'Ahornweg',
            'Eichenweg',
            'Rosenstraße',
            'Feldstraße',
            'Blumenstraße',
            'Mühlenweg',
            'Am Sportplatz',
            'Friedhofstraße',
            'Erlenweg',
            'Tannenweg',
            'Mozartstraße',
            'Brunnenstraße',
            'Lindenweg',
            'Bachstraße',
            'Raiffeisenstraße',
            'Rosenweg',
            'Drosselweg',
            'Kirchweg',
            'Lerchenweg',
            'Mühlenstraße',
            'Talstraße',
            'Beethovenstraße',
            'Industriestraße',
            'Mittelstraße',
            'Poststraße',
            'Meisenweg',
            'Gartenweg',
            'Breslauer Straße',
            'Fliederweg',
            'Lessingstraße',
            'Waldweg',
            'Kirchgasse',
            'Uhlandstraße',
            'Schloßstraße',
            'Königsberger Straße',
            'Birkenstraße',
            'Kirchplatz',
            'Fasanenweg',
            'Burgstraße',
            'Kiefernweg',
            'Tulpenweg',
            'Danziger Straße',
            'Am Bahnhof',
            'Neue Straße',
            'Kastanienweg',
            'Parkstraße',
            'Im Winkel',
            'Marktplatz',
            'Schulweg',
            'Schützenstraße',
            'Berliner Straße',
            'Mühlweg',
            'Römerstraße',
            'Grüner Weg',
            'Kapellenweg',
            'Mittelweg',
            'Nelkenweg',
            'Eschenweg',
            'Heideweg',
            'Fichtenweg',
            'Stettiner Straße',
            'Ulmenweg',
            'Schubertstraße',
            'Wilhelmstraße',
            'Sudetenstraße',
            'Sonnenstraße',
            'Friedrichstraße',
            'Marienstraße',
            'Am Anger',
            'Eichenstraße',
            'Lärchenweg',
            'Eichendorffstraße',
            'Brückenstraße',
            'Am Hang',
            'Marktstraße',
            'Ginsterweg',
            'Friedhofsweg',
            'Kurze Straße',
            'Nordstraße',
            'Schwalbenweg',
            'Lange Straße',
            'Ahornstraße',
            'Flurstraße',
            'Kolpingstraße',
            'Neuer Weg',
            'Karlstraße',
            'Steinweg',
            'Pappelweg',
            'Holunderweg',
            'Südstraße',
            'Akazienweg',
            'Buchenstraße',
            'Kapellenstraße',
            'Rathausstraße',
            'Kantstraße',
            'Hochstraße',
            'Pestalozzistraße',
            'Richard-Wagner-Straße',
            'Mühlstraße',
            'Tulpenstraße',
            'Friedrich-Ebert-Straße',
            'Höhenweg',
            'Brunnenweg',
            'Seestraße',
            'Friedensstraße',
            'Kreuzstraße',
            'Robert-Koch-Straße',
            'Querstraße',
            'Steinstraße',
            'Weidenweg',
            'Sonnenweg',
            'Gutenbergstraße',
            'Heinrich-Heine-Straße',
            'Nelkenstraße',
            'Falkenweg',
            'Im Wiesengrund',
            'Pfarrgasse',
            'Sandweg',
            'Asternweg',
            'Friedenstraße',
            'Albert-Schweitzer-Straße',
            'Weinbergstraße',
            'Zeppelinstraße',
            'Dahlienweg',
            'Schlehenweg',
            'Grenzweg',
            'Frankenstraße',
            'Haydnstraße',
            'Mörikestraße',
            'Teichstraße',
            'Klosterstraße',
            'Grabenstraße',
            'Veilchenweg',
            'Lerchenstraße',
            'Oststraße',
            'Siedlung',
            'Schwarzer Weg',
            'Starenweg',
            'Siemensstraße',
            'Fichtenstraße',
            'Wacholderweg',
            'Jägerstraße',
            'Am Berg',
            'Hölderlinstraße',
            'Gerhart-Hauptmann-Straße',
            'Forststraße',
            'Markt',
            'Bismarckstraße',
            'Ludwigstraße',
            'Lilienweg',
            'Wiesengrund',
            'Tannenstraße',
            'Heckenweg',
            'Bergweg',
            'Burgweg',
            'Leipziger Straße',
            'Hohlweg',
            'Mühlgasse',
            'Geschwister-Scholl-Straße',
            'Hohe Straße',
            'Weiherstraße',
            'Daimlerstraße',
            'Blumenweg',
            'Dieselstraße',
            'August-Bebel-Straße',
            'Alte Dorfstraße',
            'Weststraße',
            'Ulmenstraße',
            'Erlenstraße',
            'Forstweg',
            'Rheinstraße',
            'Robert-Bosch-Straße',
            'Rotdornweg',
            'Lindenallee',
            'Luisenstraße',
            'Max-Planck-Straße',
            'Am Friedhof',
            'Finkenstraße',
            'Kirchenweg',
            'Kreuzweg',
            'Frühlingstraße',
        ];
        const streetNumberAdditions = ['a', 'b', 'c', 'a-g', '/1'];

        // http://plz-von.de/staedte.php
        const cities = [
            {
                name: 'Berlin',
                zips: [
                    10115, 10117, 10119, 10178, 10179, 10243, 10245, 10247, 10249, 10315, 10317, 10318, 10319, 10365, 10367, 10369, 10405, 10407, 10409, 10435, 10437, 10439, 10551, 10553, 10555,
                    10557, 10559, 10585, 10587, 10589, 10623, 10625, 10627, 10629, 10707, 10709, 10711, 10713, 10715, 10717, 10719, 10777, 10779, 10781, 10783, 10785, 10787, 10789, 10823, 10825,
                    10827, 10829, 10961, 10963, 10965, 10967, 10969, 10997, 10999, 12043, 12045, 12047, 12049, 12051, 12053, 12055, 12057, 12059, 12099, 12101, 12103, 12105, 12107, 12109, 12157,
                    12159, 12161, 12163, 12165, 12167, 12169, 12203, 12205, 12207, 12209, 12247, 12249, 12277, 12279, 12305, 12307, 12309, 12347, 12349, 12351, 12353, 12355, 12357, 12359, 12435,
                    12437, 12439, 12459, 12487, 12489, 12524, 12526, 12527, 12555, 12557, 12559, 12587, 12589, 12619, 12621, 12623, 12627, 12629, 12679, 12681, 12683, 12685, 12687, 12689, 13051,
                    13053, 13055, 13057, 13059, 13086, 13088, 13089, 13125, 13127, 13129, 13156, 13158, 13159, 13187, 13189, 13347, 13349, 13351, 13353, 13355, 13357, 13359, 13403, 13405, 13407,
                    13409, 13435, 13437, 13439, 13465, 13467, 13469, 13503, 13505, 13507, 13509, 13581, 13583, 13585, 13587, 13589, 13591, 13593, 13595, 13597, 13599, 13627, 13629, 14050, 14052,
                    14053, 14055, 14057, 14059, 14089, 14109, 14129, 14163, 14165, 14167, 14169, 14193, 14195, 14197, 14199,
                ],
            },
            {
                name: 'Hamburg',
                zips: [
                    20095, 20097, 20099, 20144, 20146, 20148, 20149, 20249, 20251, 20253, 20255, 20257, 20259, 20354, 20355, 20357, 20359, 20457, 20459, 20535, 20537, 20539, 21029, 21031, 21033,
                    21035, 21037, 21039, 21073, 21075, 21077, 21079, 21107, 21109, 21129, 21147, 21149, 22041, 22043, 22045, 22047, 22049, 22081, 22083, 22085, 22087, 22089, 22111, 22113, 22115,
                    22117, 22119, 22143, 22145, 22147, 22149, 22159, 22175, 22177, 22179, 22297, 22299, 22301, 22303, 22305, 22307, 22309, 22335, 22337, 22339, 22359, 22391, 22393, 22395, 22397,
                    22399, 22415, 22417, 22419, 22453, 22455, 22457, 22459, 22523, 22525, 22527, 22529, 22547, 22549, 22559, 22587, 22589, 22605, 22607, 22609, 22761, 22763, 22765, 22767, 22769,
                ],
            },
            {
                name: 'München',
                zips: [
                    80331, 80333, 80335, 80336, 80337, 80339, 80469, 80538, 80539, 80634, 80636, 80637, 80638, 80639, 80686, 80687, 80689, 80796, 80797, 80798, 80799, 80801, 80802, 80803, 80804,
                    80805, 80807, 80809, 80933, 80935, 80937, 80939, 80992, 80993, 80995, 80997, 80999, 81241, 81243, 81245, 81247, 81249, 81369, 81371, 81373, 81375, 81377, 81379, 81475, 81476,
                    81477, 81479, 81539, 81541, 81543, 81545, 81547, 81549, 81667, 81669, 81671, 81673, 81675, 81677, 81679, 81735, 81737, 81739, 81825, 81827, 81829, 81925, 81927, 81929, 85540,
                ],
            },
            {
                name: 'Köln',
                zips: [
                    50667, 50668, 50670, 50672, 50674, 50676, 50677, 50678, 50679, 50733, 50735, 50737, 50739, 50765, 50767, 50769, 50823, 50825, 50827, 50829, 50858, 50859, 50931, 50933, 50935,
                    50937, 50939, 50968, 50969, 50996, 50997, 50999, 51061, 51063, 51065, 51067, 51069, 51103, 51105, 51107, 51109, 51143, 51145, 51147, 51149, 51467,
                ],
            },
            {
                name: 'Frankfurt am Main',
                zips: [
                    60306, 60308, 60310, 60311, 60313, 60314, 60316, 60318, 60320, 60322, 60323, 60325, 60326, 60327, 60329, 60385, 60386, 60388, 60389, 60431, 60433, 60435, 60437, 60438, 60439,
                    60486, 60487, 60488, 60489, 60528, 60529, 60549, 60594, 60596, 60598, 60599, 65929, 65931, 65933, 65934, 65936,
                ],
            },
            {
                name: 'Stuttgart',
                zips: [
                    70173, 70174, 70176, 70178, 70180, 70182, 70184, 70186, 70188, 70190, 70191, 70192, 70193, 70195, 70197, 70199, 70327, 70329, 70372, 70374, 70376, 70378, 70435, 70437, 70439,
                    70469, 70499, 70563, 70565, 70567, 70569, 70597, 70599, 70619, 70629,
                ],
            },
            {
                name: 'Düsseldorf',
                zips: [
                    40210, 40211, 40212, 40213, 40215, 40217, 40219, 40221, 40223, 40225, 40227, 40229, 40231, 40233, 40235, 40237, 40239, 40468, 40470, 40472, 40474, 40476, 40477, 40479, 40489,
                    40545, 40547, 40549, 40589, 40591, 40593, 40595, 40597, 40599, 40625, 40627, 40629, 40721,
                ],
            },
            {
                name: 'Dortmund',
                zips: [
                    44135, 44137, 44139, 44141, 44143, 44145, 44147, 44149, 44225, 44227, 44229, 44263, 44265, 44267, 44269, 44287, 44289, 44309, 44319, 44328, 44329, 44339, 44357, 44359, 44369,
                    44379, 44388,
                ],
            },
            {
                name: 'Essen',
                zips: [
                    45127, 45128, 45130, 45131, 45133, 45134, 45136, 45138, 45139, 45141, 45143, 45144, 45145, 45147, 45149, 45219, 45239, 45257, 45259, 45276, 45277, 45279, 45289, 45307, 45309,
                    45326, 45327, 45329, 45355, 45356, 45357, 45359,
                ],
            },
            {
                name: 'Bremen',
                zips: [
                    28195, 28197, 28199, 28201, 28203, 28205, 28207, 28209, 28211, 28213, 28215, 28217, 28219, 28237, 28239, 28259, 28277, 28279, 28307, 28309, 28325, 28327, 28329, 28355, 28357,
                    28359, 28717, 28719, 28755, 28757, 28759, 28777, 28779,
                ],
            },
            {
                name: 'Dresden',
                zips: [
                    '01067',
                    '01069',
                    '01097',
                    '01099',
                    '01108',
                    '01109',
                    '01127',
                    '01129',
                    '01139',
                    '01156',
                    '01157',
                    '01159',
                    '01169',
                    '01187',
                    '01189',
                    '01217',
                    '01219',
                    '01237',
                    '01239',
                    '01257',
                    '01259',
                    '01277',
                    '01279',
                    '01307',
                    '01309',
                    '01324',
                    '01326',
                    '01328',
                ],
            },
            {
                name: 'Leipzig',
                zips: [
                    '04103',
                    '04105',
                    '04107',
                    '04109',
                    '04129',
                    '04155',
                    '04157',
                    '04158',
                    '04159',
                    '04177',
                    '04178',
                    '04179',
                    '04205',
                    '04207',
                    '04209',
                    '04229',
                    '04249',
                    '04275',
                    '04277',
                    '04279',
                    '04288',
                    '04289',
                    '04299',
                    '04315',
                    '04316',
                    '04317',
                    '04318',
                    '04319',
                    '04328',
                    '04329',
                    '04347',
                    '04349',
                    '04356',
                    '04357',
                ],
            },
            {
                name: 'Hannover',
                zips: [
                    30159, 30161, 30163, 30165, 30167, 30169, 30171, 30173, 30175, 30177, 30179, 30419, 30449, 30451, 30453, 30455, 30457, 30459, 30519, 30521, 30539, 30559, 30625, 30627, 30629,
                    30655, 30657, 30659, 30669,
                ],
            },
            {
                name: 'Nürnberg',
                zips: [
                    90402, 90403, 90408, 90409, 90411, 90419, 90425, 90427, 90429, 90431, 90439, 90441, 90443, 90449, 90451, 90453, 90455, 90459, 90461, 90469, 90471, 90473, 90475, 90478, 90480,
                    90482, 90489, 90491,
                ],
            },
            {
                name: 'Duisburg',
                zips: [47051, 47053, 47055, 47057, 47058, 47059, 47119, 47137, 47138, 47139, 47166, 47167, 47169, 47178, 47179, 47198, 47199, 47226, 47228, 47229, 47239, 47249, 47259, 47269, 47279],
            },
            {
                name: 'Bochum',
                zips: [44787, 44789, 44791, 44793, 44795, 44797, 44799, 44801, 44803, 44805, 44807, 44809, 44866, 44867, 44869, 44879, 44892, 44894],
            },
            {
                name: 'Wuppertal',
                zips: [42103, 42105, 42107, 42109, 42111, 42113, 42115, 42117, 42119, 42275, 42277, 42279, 42281, 42283, 42285, 42287, 42289, 42327, 42329, 42349, 42369, 42389, 42399],
            },
            {
                name: 'Bonn',
                zips: [53111, 53113, 53115, 53117, 53119, 53121, 53123, 53125, 53127, 53129, 53173, 53175, 53177, 53179, 53225, 53227, 53229],
            },
            {
                name: 'Bielefeld',
                zips: [33602, 33604, 33605, 33607, 33609, 33611, 33613, 33615, 33617, 33619, 33647, 33649, 33659, 33689, 33699, 33719, 33729, 33739],
            },
            {
                name: 'Mannheim',
                zips: [68159, 68161, 68163, 68165, 68167, 68169, 68199, 68219, 68229, 68239, 68259, 68305, 68307, 68309],
            },
            {
                name: 'Karlsruhe',
                zips: [76131, 76133, 76135, 76137, 76139, 76149, 76185, 76187, 76189, 76199, 76227, 76228, 76229],
            },
            {
                name: 'Münster',
                zips: [48143, 48145, 48147, 48149, 48151, 48153, 48155, 48157, 48159, 48161, 48163, 48165, 48167],
            },
            {
                name: 'Wiesbaden',
                zips: [65183, 65185, 65187, 65189, 65191, 65193, 65195, 65197, 65199, 65201, 65203, 65205, 65207],
            },
            {
                name: 'Augsburg',
                zips: [86150, 86152, 86153, 86154, 86156, 86157, 86159, 86161, 86163, 86165, 86167, 86169, 86179, 86199],
            },
            { name: 'Aachen', zips: [52062, 52064, 52066, 52068, 52070, 52072, 52074, 52076, 52078, 52080] },
            {
                name: 'Mönchengladbach',
                zips: [41061, 41063, 41065, 41066, 41068, 41069, 41169, 41179, 41189, 41199, 41236, 41238, 41239],
            },
            {
                name: 'Gelsenkirchen',
                zips: [45879, 45881, 45883, 45884, 45886, 45888, 45889, 45891, 45892, 45894, 45896, 45897, 45899],
            },
            {
                name: 'Braunschweig',
                zips: [38100, 38102, 38104, 38106, 38108, 38110, 38112, 38114, 38116, 38118, 38120, 38122, 38124, 38126],
            },
            {
                name: 'Chemnitz',
                zips: ['09111', '09112', '09113', '09114', '09116', '09117', '09119', '09120', '09122', '09123', '09125', '09126', '09127', '09128', '09130', '09131'],
            },
            {
                name: 'Kiel',
                zips: [24103, 24105, 24106, 24107, 24109, 24111, 24113, 24114, 24116, 24118, 24143, 24145, 24146, 24147, 24148, 24149, 24159],
            },
            { name: 'Krefeld', zips: [47798, 47799, 47800, 47802, 47803, 47804, 47805, 47807, 47809, 47829, 47839] },
            {
                name: 'Halle (Saale)',
                zips: ['06108', '06110', '06112', '06114', '06116', '06118', '06120', '06122', '06124', '06126', '06128', '06130', '06132'],
            },
            {
                name: 'Magdeburg',
                zips: [39104, 39106, 39108, 39110, 39112, 39114, 39116, 39118, 39120, 39122, 39124, 39126, 39128, 39130],
            },
            {
                name: 'Freiburg im Breisgau',
                zips: [79098, 79100, 79102, 79104, 79106, 79108, 79110, 79111, 79112, 79114, 79115, 79117],
            },
            { name: 'Oberhausen', zips: [46045, 46047, 46049, 46117, 46119, 46145, 46147, 46149] },
            { name: 'Lübeck', zips: [23552, 23554, 23556, 23558, 23560, 23562, 23564, 23566, 23568, 23569, 23570] },
            {
                name: 'Erfurt',
                zips: [99084, 99085, 99086, 99087, 99089, 99091, 99092, 99094, 99096, 99097, 99098, 99099],
            },
            {
                name: 'Rostock',
                zips: [18055, 18057, 18059, 18069, 18106, 18107, 18109, 18119, 18146, 18147, 18181, 18182],
            },
        ];
        const randomCity = cities[Math.floor(Math.random() * cities.length)];

        // https://www.billiger-telefonieren.de/thema/handy-vorwahlen/
        const mobileNumberPrefixes = [
            '01511',
            '01512',
            '01514',
            '01515',
            '01516',
            '01517',
            '0160',
            '0170',
            '0171',
            '0175',
            '01520',
            '01522',
            '01523',
            '01525',
            '0162',
            '0172',
            '0173',
            '0174',
            '01570',
            '01573',
            '01575',
            '01577',
            '01578',
            '0163',
            '0177',
            '0178',
            '01590',
            '0176',
            '0179',
        ];
        const emailConnectors = ['.', '_', ''];
        const emailDomains = ['atraveo.de', 'atraveo.com', 'gmail.com', 'googlemail.com', 'aol.de', 'gmx.de', 'gmx.net', 'web.de', 'test.test'];

        this.registrationForm.patchValue({
            ownerType: 0,
            salutation: gender,
            forename: firstNames[gender][Math.floor(Math.random() * firstNames[gender].length)],
            surname: lastNames[Math.floor(Math.random() * lastNames.length)],
            street:
                streetNames[Math.floor(Math.random() * streetNames.length)] +
                ' ' +
                Math.floor(Math.random() * 200) +
                (Math.random() > 0.75 ? streetNumberAdditions[Math.floor(Math.random() * streetNumberAdditions.length)] : ''),
            zipCode: randomCity.zips[Math.floor(Math.random() * randomCity.zips.length)].toString(),
            city: randomCity.name,
            countryISOCode: 'DE',
            prefix2: '+49',
            number2: mobileNumberPrefixes[Math.floor(Math.random() * mobileNumberPrefixes.length)].substr(1) + '' + (1000000 + Math.floor(Math.random() * 1000000000)),
            emails: [
                firstNames[gender][Math.floor(Math.random() * firstNames[gender].length)].toLowerCase() +
                    emailConnectors[Math.floor(Math.random() * emailConnectors.length)] +
                    lastNames[Math.floor(Math.random() * lastNames.length)] +
                    (Math.random() > 0.5 ? 50 + Math.floor(Math.random() * 40) : '') +
                    '@' +
                    emailDomains[Math.floor(Math.random() * emailDomains.length)],
            ],
        });
    }

    protected readonly toString = toString;
    protected readonly FormGroup = FormGroup;
}
