import { Injectable } from '@angular/core';
import { TravelPeriodDto } from 'data-structures/lib/es6/dto/accommodation/price-rule/travel-period.dto';
import { areIntervalsOverlapping, differenceInDays, getDaysInYear } from 'date-fns';
import { DateService } from '../date/date.service';
import { isLeapYear } from 'date-fns';

@Injectable({
    providedIn: 'root',
})
export class TravelPeriodService {
    constructor() {}

    // Prüft ob sich die beiden Travelperiods überschneiden. Wenn ja, wird der treffende Zeitraum der zweiten Travelperiod zurückgegeben
    getPeriodOverlap(travelPeriodOriginal: TravelPeriodDto, travelPeriodCompare: TravelPeriodDto): TravelPeriodDto {
        // Wie viele Jahre sollen die Perioden verglichen werden
        const yearsToCompare = 10;

        // Daten nicht gesetzt, können also nichts vergleichen
        if (!travelPeriodOriginal.from || !travelPeriodOriginal.to || !travelPeriodCompare.from || !travelPeriodCompare.to) {
            return undefined;
        }

        // erstelle ein array für die zu checkenden travelPeriods
        const travelPeriods: Interval[] = [];
        travelPeriods.push({
            start: DateService.getDateFromString(travelPeriodOriginal.from).getTime(),
            end: DateService.getDateFromString(travelPeriodOriginal.to).getTime(),
        });

        // ergänze die nächsten Jahre
        if (travelPeriodOriginal.followingYears) {
            for (let year = 1; year < yearsToCompare; year++) {
                travelPeriods.push({
                    start: this.addYearsToDate(DateService.getDateFromString(travelPeriodOriginal.from), year),
                    end: this.addYearsToDate(DateService.getDateFromString(travelPeriodOriginal.to), year),
                });
            }
        }

        // nun für die schon bestehenden travelPeriods
        const travelPeriodsToCompare: Interval[] = [];
        travelPeriodsToCompare.push({
            start: DateService.getDateFromString(travelPeriodCompare.from).getTime(),
            end: DateService.getDateFromString(travelPeriodCompare.to).getTime(),
        });

        // ergänze die nächsten Jahre
        if (travelPeriodCompare.followingYears) {
            for (let year = 1; year < yearsToCompare; year++) {
                travelPeriodsToCompare.push({
                    start: this.addYearsToDate(DateService.getDateFromString(travelPeriodCompare.from), year),
                    end: this.addYearsToDate(DateService.getDateFromString(travelPeriodCompare.to), year),
                });
            }
        }

        // prüfe, ob sich die Perioden überschneiden
        for (const travelPeriod of travelPeriods) {
            for (const travelPeriodToCompare of travelPeriodsToCompare) {
                if (areIntervalsOverlapping(travelPeriod, travelPeriodToCompare, { inclusive: true })) {
                    const returnDTO = new TravelPeriodDto();
                    returnDTO.from = new Date(travelPeriodToCompare.start).toISOString();
                    returnDTO.to = new Date(travelPeriodToCompare.end).toISOString();
                    returnDTO.followingYears = travelPeriodCompare.followingYears;
                    return returnDTO;
                }
            }
        }
    }

    isDateRangeToLong(travelPeriod: any): boolean {
        if (
            travelPeriod.followingYears &&
            differenceInDays(DateService.getDateFromString(travelPeriod.to), DateService.getDateFromString(travelPeriod.from)) > getDaysInYear(DateService.getDateFromString(travelPeriod.from))
        ) {
            return true;
        }
    }

    addYearsToDate(inputDate: Date, yearsToAdd: number): Date {
        const resultDate = new Date(inputDate);
        const currentYear = resultDate.getFullYear();
        const targetYear = currentYear + yearsToAdd;

        // addiere die Jahre
        resultDate.setFullYear(targetYear);

        // Wenn das Jahr ein Schaltjahr ist und das Zieljahr nicht, dann muss der 29. Februar auf den 28. Februar gesetzt werden
        if (isLeapYear(currentYear) && !isLeapYear(resultDate.getFullYear()) && inputDate.getMonth() === 1 && inputDate.getDate() === 29) {
            const lastDayOfMonth = new Date(resultDate.getFullYear(), 2, 0).getDate();
            resultDate.setMonth(1);
            resultDate.setDate(lastDayOfMonth);
        }
        return resultDate;
    }
}
