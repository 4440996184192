<div class="thumbnail">
    <picture>
        <source *ngIf="accommodation.media && accommodation.media[0]?.displayUrl" srcset="{{ accommodation.media[0]?.displayUrl | imageUrlPipe: { x: 90, y: 70, format: 'webp', media:  accommodation.media[0] } }}" type="image/webp">
        <img *ngIf="accommodation.media && accommodation.media[0]?.displayUrl" src="{{ accommodation.media[0]?.displayUrl | imageUrlPipe: { x: 90, y: 70, media:  accommodation.media[0] } }}" onerror="this.onerror = null; this.src='assets/img/empty-accommodation.png';" />
        <img *ngIf="!accommodation.media || !accommodation.media[0]?.displayUrl" src="assets/img/empty-accommodation.png"  />
    </picture>
    <div class="deleted" *ngIf="accommodation.state?.deleted">
        <i class="fas fa-ban fa-3x"></i>
    </div>
</div>
