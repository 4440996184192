import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PendingChangesComponentService {
    private saveTrigger = new Subject<void>();
    private saveResponse = new Subject<boolean>();

    triggerSave() {
        this.saveTrigger.next();
    }

    getSaveTriggerObservable(): Observable<void> {
        return this.saveTrigger.asObservable();
    }

    saveComplete(success: boolean) {
        this.saveResponse.next(success);
    }

    getSaveResponseObservable(): Observable<boolean> {
        return this.saveResponse.asObservable();
    }
}
