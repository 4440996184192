<div appClientWidth>
    <app-loading-indicator></app-loading-indicator>
    <ng-container *ngIf="authenticationService.currentUser.isEmployee()">
        <app-issue-collector ></app-issue-collector>
        <div class="display-breakpoint" title="Breakpoint"></div>
    </ng-container>
    <div class="d-flex" [ngClass]="(!(toggleMenuService.isMenuVisible$ | async) ? '' : 'toggled')" id="wrapper">
        <div class="col-nav" id="sidebar-wrapper">
            <app-navigation></app-navigation>
        </div>
        <div id="page-content-wrapper">
            <div class="container-fluid pb-2">
                <app-main-header></app-main-header>
                <router-outlet></router-outlet>
            </div>
        </div>

        <div *ngIf="authenticationService.currentUser && authenticationService.currentUser.isEmployee()">
            <app-service-sidebar></app-service-sidebar>
        </div>
    </div>
    <back-to-top-button></back-to-top-button>
</div>
