import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationConnectorService } from '../../../../../services/api-connector/authentication-connector-service';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';

@Component({
    selector: 'app-password-forgotten',
    templateUrl: './password-forgotten.html',
    styleUrls: ['./password-forgotten.scss'],
})
export class PasswordForgotten implements OnInit {
    password: string = '';
    confirmPassword: string = '';
    errorMessages: string[] = [];
    changePasswordToken: string;

    constructor(readonly router: Router, readonly authConnector: AuthenticationConnectorService, private route: ActivatedRoute, readonly authenticationService: AuthenticationService) {}

    ngOnInit(): void {
        const $passwordForgottenModal = $('#password-forgotten') as any;
        const $loginModal = $('#modalLogin') as any;
        this.route.queryParams.subscribe((params) => {
            this.changePasswordToken = params.authToken;
            if (this.changePasswordToken && !this.authenticationService.currentUser) {
                $passwordForgottenModal.modal('show');
                $loginModal.modal('hide');
            } else {
                this.router.navigate(['/']);
            }
        });
    }

    getErrorMessageFromPassword() {
        this.errorMessages = [];

        if (this.password.length < 8) {
            this.errorMessages.push('user.validation.password.length');
        }

        if (!/\d/.test(this.password)) {
            this.errorMessages.push('user.validation.password.number');
        }

        if (this.password.toUpperCase() === this.password) {
            this.errorMessages.push('user.validation.password.lowercase');
        }

        if (this.password.toLowerCase() === this.password) {
            this.errorMessages.push('user.validation.password.uppercase');
        }
    }

    async checkPassword() {
        this.getErrorMessageFromPassword();
    }

    async submitPassword() {
        this.getErrorMessageFromPassword();

        if (this.password !== this.confirmPassword) {
            this.errorMessages.push('user.validation.password.repeat');
        }

        if (this.errorMessages.length || !this.changePasswordToken) {
            return;
        }

        try {
            await this.authConnector.changePasswordByToken(this.password, this.changePasswordToken);
        } catch (error) {
            if (error.includes('[CHANGE6]') || error.includes('[CHANGE7]')) {
                this.errorMessages.push('user.password.token.invalid');
            } else {
                this.errorMessages.push('form.save.failure');
            }
        }

        if (!this.errorMessages.length) {
            const $passwordForgottenModal = $('#password-forgotten') as any;

            $passwordForgottenModal.find('.change-wrapper').hide();
            $passwordForgottenModal.find('.modal-footer').hide();
            $passwordForgottenModal.find('.success').show();
        }
    }
}
