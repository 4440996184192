<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="icalModalLabel">{{ 'atraveo.accommodationbundle.calendar.interfaceform.title' | translate }}</h5>
        <button class="close" (click)="closeDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="icalForm" (ngSubmit)="saveIcals()">
        <div class="modal-body">
            <div class="container-fluid">
                <p>{{ 'atraveo.accommodationbundle.calendar.interfaceform.description' | translate }}</p>
                <p [innerHTML]="'urls.ical.instruction' | translate"></p>
                <h5>{{ 'atraveo.accommodationbundle.calendar.interfaceform.url' | translate }}</h5>

                <div formArrayName="icalUrls" class="row" *ngFor="let control of icalUrlsFormArray.controls; let i = index;">
                    <div class="col-sm-11">
                        <input type="text"
                               class="form-control"
                               placeholder="http://"
                               [formControlName]="i">
                        <app-validation-error-messages [control]="control"></app-validation-error-messages>
                    </div>
                    <div class="col-sm-1">
                        <a class="btn btn-link" (click)="toggleIcalUrl(i)">
                            <i class="fas fa-2x" [class]="((control.enabled) ? 'fa-trash-alt' : 'fa-history')"></i>
                        </a>
                    </div>
                </div>

                <button type="button" class="btn btn-secondary mt-2" (click)="addIcalUrl()" [disabled]="!icalForm.valid"><i class="fas fa-plus"></i> {{ 'atraveo.accommodationbundle.calendar.interfaceform.button-add' | translate }}</button>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-success col-10 offset-1 offset-lg-2 col-lg-5 mb-2" type="submit">
                <ng-template [ngIf]="disabledCounter > 0">
                    {{ 'atraveo.accommodationbundle.price.create.deleteWithCounter' | translate:{ count: disabledCounter } }}
                </ng-template>
                {{ 'atraveo.accommodationbundle.price.create.save' | translate }}
            </button>
        </div>
    </form>
</div>
