import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { SeasonsDto } from 'data-structures/lib/es6/dto/season/put-season/seasons.dto';
import { ApiConnectorService } from '../../../../../../services/api-connector/api-connector.service';
import { CurrencyEnum } from 'data-structures/lib/es6/enum/currency.enum';
import { PriceDto } from 'data-structures/lib/es6/dto/accommodation/price-rule/price.dto';
import { TranslateService } from '@ngx-translate/core';
import { ExtendedAccommodationEntity } from '../../../../../../entities/extendedAccommodationEntity';
import { AgeExceptionDto } from 'data-structures/lib/es6/dto/accommodation/price-rule/age-exception.dto';
import { TravelPeriodDto } from 'data-structures/lib/es6/dto/accommodation/price-rule/travel-period.dto';
import { PriceInformationExceptionDto } from 'data-structures/lib/es6/dto/accommodation/price-information-exception.dto';
import { ExtendedPriceRule } from '../../../../../../entities/PriceRule/extended-price-rule';
import { DateService } from '../../../../../../services/date/date.service';
import { AuthenticationService } from '../../../../../../services/authentication/authentication.service';
import { OwnerCurrencyEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { TravelPeriodService } from '../../../../../../services/travel-period/travel-period.service';
import { NotificationService } from '../../../../../../services/notification/notification.service';
import { DialogService } from '../../../../../../services/dialog.service';

@Component({
    selector: 'app-edit-price',
    templateUrl: './edit-price.component.html',
    styleUrls: ['./edit-price.component.scss'],
})
export class EditPriceComponent implements OnInit, OnChanges {
    @Input() price: ExtendedPriceRule;
    @Input() submitted: boolean;
    @Input() editPriceModal: boolean;
    @Input() dateOverlap: string;
    @Input() modalID: string; // welche modalID hat es geöffnet
    @ViewChild('priceTableForm') priceTableForm: ElementRef;
    accommodation: ExtendedAccommodationEntity;
    isDateRangeToLong: boolean = false;
    seasons: SeasonsDto = new SeasonsDto();
    personArray: number[];
    dateType: string; // Checkbox dateabhängig
    priceType: string; // Checkbox personenabhängig
    validPersons: boolean = false; // Checkbox gilt bis
    ageExceptions: boolean = false; // Checkbox es existieren Altersausnahmen
    priceInformations: PriceInformationExceptionDto[] = [];
    overlapFromDate: Date;
    overlapToDate: Date;
    seasonValue: any;
    ownerCurrency: OwnerCurrencyEnum = this.authService.currentUser.getOwnerCurrency();
    minDate: string;
    maxDate: string;
    invalidYearFrom: boolean = false;
    invalidYearTo: boolean = false;
    accommodationSubscription: Subscription;

    travelPeriodService: TravelPeriodService = new TravelPeriodService();

    constructor(
        readonly authService: AuthenticationService,
        readonly apiConnector: ApiConnectorService,
        readonly translationService: TranslateService,
        readonly notificationService: NotificationService,
        readonly dialogService: DialogService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.accommodationSubscription = this.apiConnector.activeAccommodation$.subscribe(async (accommodation) => {
            if (accommodation) {
                this.accommodation = cloneDeep(accommodation);
            }
            if (!this.seasons.seasons?.length) {
                this.seasons = await this.apiConnector.getSeasons();
            }
            this.ngOnChanges();
        });
    }

    ngOnDestroy(): void {
        this.accommodationSubscription?.unsubscribe();
    }

    updateFields() {
        if (!this.price) {
            return;
        }

        if (this.price && !this.price?.prices?.length) {
            this.price.prices = [];
            const newPrice = new PriceDto();
            newPrice.currency = CurrencyEnum.EUR;
            newPrice.pricePerPerson = false;
            this.price.prices.push(newPrice);
        }

        if (this.price.priceType === undefined) {
            this.price.priceType = 'ta';
        }

        if (this.price && !this.price.travelPeriod) {
            this.price.travelPeriod = new TravelPeriodDto();
        }

        this.dateType = this.price.travelPeriod?.seasonId ? 'season' : 'dates';

        this.priceType = this.price?.prices[0].pricePerPerson || this.price?.prices[0].maxPersons || this.price?.prices[0].ageExceptions?.length ? 'person' : 'booking';

        this.validPersons = !!this.price?.prices[0].maxPersons;

        this.ageExceptions = !!this.price?.prices[0].ageExceptions?.length;

        if (this.price.prices.length) {
            this.price.prices.forEach((price) => {
                price.currency = this.ownerCurrency;
            });
        }

        if (this.price.travelPeriod?.seasonId) {
            this.seasonValue = this.price.travelPeriod.seasonId;
        }
    }

    ngOnChanges(): void {
        this.updateFields();
        const minDate = new Date();
        const maxDate = new Date();

        minDate.setFullYear(minDate.getFullYear() - 10);
        maxDate.setFullYear(maxDate.getFullYear() + 20);

        this.minDate = minDate.toISOString().slice(0, 10);
        this.maxDate = maxDate.toISOString().slice(0, 10);

        this.dateType = 'dates';
        if (this.price?.travelPeriod?.seasonId) {
            this.dateType = 'season';
        }

        if (this.accommodation) {
            this.personArray = [];
            for (let i = 1; i < this.accommodation.getMaximumNumberOfPeople(); i++) {
                this.personArray.push(i);
            }

            if (this.price?.travelPeriod) {
                this.priceInformations = this.accommodation.priceInformation?.getInformationForDateRange(this.price?.travelPeriod);
            }

            this.accommodation.addRequiredFields();
        }

        if (!this.price?.travelPeriod?.from && !this.price?.travelPeriod?.to) {
            this.overlapFromDate = null;
            this.overlapToDate = null;
            this.dateOverlap = null;
        }
    }

    seasonValueChange() {
        const season = this.seasons.seasons.find((seasonTarget) => seasonTarget.seasonId === Number(this.seasonValue));
        if (season) {
            this.price.travelPeriod.seasonId = Number(this.seasonValue);
            this.price.travelPeriod.from = season.travelPeriods[0].from;
            this.price.travelPeriod.to = season.travelPeriods[0].to;
        }

        this.checkDates();
    }

    changeValidPersons($event: Event) {
        // @ts-ignore
        const validPersons = $event.target.checked;
        if (!validPersons) {
            this.price.prices[0].maxPersons = null;
            this.price.prices[0].pricePerAdditionalPerson = null;
        }
    }

    changeExceptions($event: MouseEvent) {
        // @ts-ignore
        const ageExceptions = $event.target.checked;
        if (!ageExceptions) {
            this.price.prices[0].ageExceptions = [];
        } else {
            this.addAgeException();
        }
    }

    addAgeException() {
        if (!this.price.prices[0].ageExceptions) {
            this.price.prices[0].ageExceptions = [];
        }

        this.price.prices[0].ageExceptions.push(new AgeExceptionDto());
    }

    deleteAgeException(i: number) {
        this.price.prices[0].ageExceptions.splice(i, 1);
    }

    async checkDates() {
        this.invalidYearFrom = false;
        this.invalidYearTo = false;
        this.overlapFromDate = null;
        this.overlapToDate = null;
        this.dateOverlap = '';

        if (this.price?.travelPeriod) {
            this.priceInformations = this.accommodation.priceInformation?.getInformationForDateRange(this.price?.travelPeriod);
        }

        if (this.price.travelPeriod?.from && DateService.getDateFromString(this.price.travelPeriod?.from).getFullYear() < 1000) {
            this.invalidYearFrom = true;
            return;
        }

        if (this.price.travelPeriod?.to && DateService.getDateFromString(this.price.travelPeriod?.to).getFullYear() < 1000) {
            this.invalidYearTo = true;
            return;
        }

        if (
            this.price.travelPeriod?.from &&
            DateService.getDateFromString(this.price.travelPeriod?.from).getFullYear() >= 1000 &&
            this.price.travelPeriod?.to &&
            DateService.getDateFromString(this.price.travelPeriod?.to).getFullYear() >= 1000
        ) {
            if (DateService.getDateFromString(this.price.travelPeriod?.to) < DateService.getDateFromString(this.price.travelPeriod?.from)) {
                const fromDate = this.price.travelPeriod.from;
                this.price.travelPeriod.from = this.price.travelPeriod.to;
                this.price.travelPeriod.to = fromDate;
            }
        }

        const result = this.accommodation.getPriceOverlap(this.price);
        if (result) {
            this.overlapFromDate = DateService.getDateFromString(result.from);
            this.overlapToDate = DateService.getDateFromString(result.to);
            this.dateOverlap = 'overlap';
        }
    }

    async save(doValidation: boolean = true) {
        if (this.travelPeriodService.isDateRangeToLong(this.price.travelPeriod)) {
            this.isDateRangeToLong = true;
            return;
        }
        this.checkDates();
        if (this.dateOverlap) {
            return;
        }

        // bereinige die Daten
        if (this.dateType === 'season') {
            delete this.price.travelPeriod.followingYears;
        } else {
            delete this.price.travelPeriod.seasonId;
        }

        if (this.priceType === 'booking') {
            this.price.prices[0].pricePerPerson = false;
            this.price.prices[0].maxPersons = null;
            this.price.prices[0].pricePerAdditionalPerson = null;
        }

        // no form validation for deleting prices needed
        if (doValidation) {
            const result = this.priceTableForm.nativeElement.checkValidity();
            if (!result) {
                this.priceTableForm.nativeElement.reportValidity();
                await this.notificationService.add('validation.form.all.required', 'danger');
                return;
            }
        }

        // ersetze den currentPrice mit dem aktuellen Preis in der accommodation
        const indexToUpdate = this.accommodation.priceRules.findIndex((priceRule) => priceRule.id === this.price.id);

        // Element mit passender ID gefunden
        if (indexToUpdate !== -1) {
            this.accommodation.priceRules[indexToUpdate] = this.price;
        } else {
            // neuen Preis ergänzen
            this.accommodation.priceRules.push(this.price);
        }

        await this.apiConnector.saveAccommodation(this.accommodation);
        this.dialogService.closeDialog();
    }
}
