import { EventEmitter, Injectable, Output } from '@angular/core';
import { FindBookingDto } from 'data-structures/lib/es6/dto/find-booking/find-booking.dto';
import { cloneDeep } from 'lodash';
import { BookingConnectorService } from '../api-connector/booking-connector.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { ConfirmationDialogService } from '../../components/global/confirmation-dialog/confirmation-dialog.service';
import { BookingUpdateService } from '../booking-update.service';
import { DateService } from '../date/date.service';
import { ExtendedFindBookingDto } from '../../entities/extended-find-booking.dto';
import { ExtendedBookingFilterDto } from '../../entities/extended-booking-filter.dto';
import { OrderByDto } from 'data-structures/lib/es6/dto/find-booking/order-by.dto';
import { SortableComponentsEnum } from 'data-structures/lib/es6/enum/order-by/sortable-components.enum';
import { DirectionEnum } from 'data-structures/lib/es6/enum/order-by/direction.enum';
import { BookingEntity } from 'data-structures/lib/es6/entity/booking.entity';

@Injectable({
    providedIn: 'root',
})
export class LoadBookingsService {
    @Output() onSetFilter: EventEmitter<any> = new EventEmitter<any>();
    findBookingDto: ExtendedFindBookingDto;
    standardFindBookingDto: ExtendedFindBookingDto;
    currentPage: number;
    isPageChanged: boolean = false;
    bookings: BookingEntity[] = [new BookingEntity()];
    bookingCount: number;
    arrivalDateSort: OrderByDto = {
        component: SortableComponentsEnum.ArrivalDate,
        direction: DirectionEnum.Descending,
    };
    bookingDateSort: OrderByDto = {
        component: SortableComponentsEnum.BookingDate,
        direction: null,
    };

    constructor(
        readonly bookingConnectorService: BookingConnectorService,
        readonly translationService: TranslateService,
        readonly authenticationService: AuthenticationService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly bookingUpdateService: BookingUpdateService,
        readonly dateService: DateService,
    ) {
        this.findBookingDto = new ExtendedFindBookingDto();
        this.findBookingDto.filter = new ExtendedBookingFilterDto();
        this.findBookingDto.skip = 0;
        this.findBookingDto.take = 10;
        this.findBookingDto.orderBy = [this.arrivalDateSort];
        this.findBookingDto.includeCount = true;
        this.findBookingDto.filter.channelString = 'all';
        this.currentPage = 1;

        this.standardFindBookingDto = Object.assign({}, this.findBookingDto);
        this.standardFindBookingDto.filter = Object.assign({}, this.findBookingDto.filter);
    }

    async loadData(isLoadFromFilter: boolean = false) {
        const clonedFindBooking = this.cloneFindBookingDtoAndPrepareFilter(this.findBookingDto);
        if (isLoadFromFilter) {
            this.onSetFilter.emit(clonedFindBooking);
        } else {
            const result = await this.bookingConnectorService.findBookings(this.authenticationService.currentUser.ownerNumber, clonedFindBooking);
            this.bookings = result.result;
            this.bookingCount = result.count;
        }
    }
    cloneFindBookingDtoAndPrepareFilter(findBookingDto: FindBookingDto): FindBookingDto {
        const clonedFindBooking = cloneDeep(findBookingDto);

        if (findBookingDto.orderBy?.length) {
            clonedFindBooking.orderBy = findBookingDto.orderBy.filter((order) => order.direction);
        }

        clonedFindBooking.take = Number(clonedFindBooking.take);

        if (!clonedFindBooking.filter.channelString) {
            clonedFindBooking.filter.channelString = 'all';
        }

        if (clonedFindBooking.filter.channelString === 'all') {
            delete clonedFindBooking.filter.channel;
        } else if (clonedFindBooking.filter.channelString === 'AT') {
            clonedFindBooking.filter.channel = ['AT', 'ED'];
        } else {
            clonedFindBooking.filter.channel = ['VM'];
        }

        delete clonedFindBooking.filter.channelString;

        return clonedFindBooking;
    }
}
