import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { environment } from '../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AutocompletionAccommodationGroupRequestDto } from 'data-structures/lib/es6/dto/accommodation-group/autocompletion-accommodation-group-request.dto';

declare const Bloodhound;

@Component({
    selector: 'app-search-by-group',
    templateUrl: './search-by-group.component.html',
    styleUrls: ['./search-by-group.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchByGroupComponent),
            multi: true,
        },
    ],
})
export class SearchByGroupComponent implements OnInit {
    @Output() onSearchByGroupId: EventEmitter<any> = new EventEmitter<any>();
    selectedAccommodationGroup: number;
    dataName: string;

    constructor(readonly authenticationService: AuthenticationService, readonly translationService: TranslateService) {}

    onChange: any = () => {};
    onTouched: any = () => {};

    writeValue(obj: any): void {
        this.selectedAccommodationGroup = obj;
    }

    get value() {
        return this.selectedAccommodationGroup;
    }

    set value(v) {
        this.selectedAccommodationGroup = v;
        this.onChange(this.selectedAccommodationGroup);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    ngOnInit(): void {
        const ownerNumber = this.authenticationService.currentUser.ownerNumber;
        // tslint:disable-next-line:no-shadowed-variable
        this.translationService
            .get('atraveo.accommodationbundle.group.new', {})
            .toPromise()
            .then((data) => {
                this.dataName = data;
            });

        const dataSource = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.whitespace,
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: 'http://bla.xyz', // Wenn man hier nichts angibt, funktioniert es nicht, obwohl unten die Url wieder überschrieben wird
                prepare: (query, settings) => {
                    settings.type = 'POST';
                    settings.url = `${environment.apiUrl}/accommodation-group/autocompletion`;
                    settings.headers = {
                        Authorization: `Bearer ${this.authenticationService.currentUser.token}`,
                    };
                    settings.contentType = 'application/json; charset=UTF-8';
                    const accommodationGroupRequestDto = new AutocompletionAccommodationGroupRequestDto();
                    accommodationGroupRequestDto.searchTermAccommodationGroup = query;
                    accommodationGroupRequestDto.ownerNumber = ownerNumber;
                    settings.data = JSON.stringify(accommodationGroupRequestDto);

                    return settings;
                },
            },
        });

        // @ts-ignore
        $('#search-by-group').typeahead(
            {
                hint: true,
                minLength: 3,
                highlight: true,
            },
            {
                name: 'search',
                source: dataSource,
                display: 'accommodationGroupId',
                templates: {
                    suggestion: (data) => {
                        if (data.name) {
                            let name = data.name;

                            if (data.name === 'atraveo.accommodationbundle.group.new') {
                                name = this.dataName;
                            }

                            return '<div><strong>' + name + '</strong> (' + data.accommodationGroupId + ')</div>';
                        } else {
                            return '<div>' + data.accommodationGroupId + '</div>';
                        }
                    },
                },
            },
        );

        $('#search-by-group').bind('typeahead:select', (ev, selected) => {
            this.writeValue(selected.accommodationGroupId);
            this.onSearchByGroupId.emit(selected.accommodationGroupId);
        });
    }

    reset() {
        this.writeValue(null);
        // @ts-ignore
        $('#search-by-group').typeahead('val', '');
    }
}
