<nav class="nav flex-column px-lg-3 py-3 m-0">
    <app-onboarding layout="card"></app-onboarding>
    <div class="pl-3 d-sm-none" ><button (click)="toggleMenu()" type="button" title="Menü schliessen" class="btn btn-outline-primary text-primary border-primary btn-sm "><i class="fas fa-times"></i> Schließen</button></div>
    <i class="bi bi-caret-left-square"></i>
    <a class="nav-link" routerLink="/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        {{ 'button.start' | translate }}
        <i class="fa fa-angle-right fa-1x"></i>
    </a>
    <a class="nav-link" routerLink="/profile" routerLinkActive="active">
        {{ 'button.my.profile' | translate }} <span  *ngIf="this.authenticationService.currentUser.vat?.hasVatId === null || this.authenticationService.currentUser.vat?.hasVatId === undefined" class="badge badge-danger badge-pill">1</span>
        <i class="fa fa-angle-right fa-1x"></i>
    </a>
    <a class="nav-link" routerLink="/accommodations" routerLinkActive="active">
        {{ 'button.my.accommodations' | translate }} <span *ngIf="this.accommodationErrorCount > 0" class="badge badge-danger badge-pill">{{ this.accommodationErrorCount }}</span>
        <i class="fa fa-angle-right fa-1x"></i>
    </a>
    <div [class.disabled]="!this.authenticationService.currentUser?.state?.checkedByOwnerService">
        <a class="nav-link" href="#" routerLink="/bookings" routerLinkActive="active">
            {{ 'button.my.bookings' | translate }}
            <i class="fa fa-angle-right fa-1x"></i>
        </a>
        <a class="nav-link" href="#" routerLink="/arrival" routerLinkActive="active">
            {{ 'button.arrival.informations' | translate }}
            <i class="fa fa-angle-right fa-1x"></i>
        </a>
        <a class="nav-link" routerLink="/ratings" routerLinkActive="active">
            {{ 'button.my.customer.rating' | translate }}
            <i class="fa fa-angle-right fa-1x"></i>
        </a>
    </div>
    <a class="nav-link" routerLink="/notification" routerLinkActive="active">
        {{ 'button.notification' | translate }}
        <span class="badge badge-danger badge-pill" *ngIf="this.messagesCount">
        {{ this.messagesCount }}
    </span>
        <i class="fa fa-angle-right fa-1x"></i>
    </a>
    <div [class.disabled]="!this.authenticationService.currentUser?.state?.checkedByOwnerService">
        <hr class="dropdown-divider" />
        <a *ngIf="!authenticationService.currentUser.hasClient()" class="nav-link" routerLink="/invoices" routerLinkActive="active">
            {{ 'button.billing' | translate }}
            <i class="fa fa-angle-right fa-1x"></i>
        </a>
        <a class="nav-link" routerLink="/accommodation-groups" routerLinkActive="active"
        >{{ 'button.accommodation.groups.0' | translate }}
            <i class="fa fa-angle-right fa-1x"></i>
        </a>
        <a class="nav-link" routerLink="/additional-cost-templates" routerLinkActive="active">
            {{ 'button.additional.costs.template.0' | translate }}
            <i class="fa fa-angle-right fa-1x"></i>
        </a>
        <a class="nav-link" routerLinkActive="active" routerLink="/interface">
            {{ 'button.interfaces' | translate }}
            <i class="fa fa-angle-right fa-1x"></i>
        </a>
    </div>
    <!--pre>
        {{ authenticationService.currentUser | json }}
    </pre-->
    <div *ngIf="this.authenticationService.userIsFromCh() && this.authenticationService.currentUser.isDestination()">
        <!-- Destination Vermieterliste -->
        <a class="nav-link" routerLinkActive="active" routerLink="/owner-list">
            {{ 'button.ownerlist' | translate }}
            <i class="fa fa-angle-right fa-1x"></i>
        </a>
    </div>
</nav>
<nav *ngIf="this.authenticationService.userIsFromCh() && authenticationService.currentUser.isEmployee()" class="nav flex-column  px-lg-3 pr-4 m-0 service">
    <a class="nav-link" routerLinkActive="active" routerLink="/pool-config">{{ 'button.pool.config' | translate }}</a>
</nav>

<nav *ngIf="authenticationService.currentUser.isEmployee()" class="nav flex-column  px-lg-3 pr-4 m-0 service">
    <a class="nav-link" routerLinkActive="active" routerLink="/history">{{ 'button.history' | translate }}</a>
<!--
    <a class="nav-link" href="#">Manuelle Merkmale</a>
    <a class="nav-link" href="#">Abreisetageinstellungen</a>
-->
</nav>

<nav class="nav flex-column  px-lg-3 py-3 m-0">
    <hr class="dropdown-divider" />
    <a class="nav-link" (click)="contactUs()">
        {{ 'button.contact.owner.service' | translate }}
        <i class="fa fa-angle-right fa-1x"></i>
    </a>
    <a class="nav-link" routerLink="/data-protection">
        {{ 'button.privacy.0' | translate }}
        <i class="fa fa-angle-right fa-1x"></i>
    </a>
    <a class="nav-link" routerLink="/terms" routerLinkActive="active">
        {{ 'button.terms' | translate }}
        <i class="fa fa-angle-right fa-1x"></i>
    </a>
    <a class="nav-link" routerLink="/imprint">
        {{ 'button.imprint.0' | translate }}
        <i class="fa fa-angle-right fa-1x"></i>
    </a>
    <div class="btn-group w-auto d-inline dropright">
        <button type="button" class="btn btn-link dropdown-toggle ml-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ 'button.select.language' | translate }}
        </button>
        <div class="dropdown-menu p-0">
            <div class="list-group m-0 p-0 border-0">
                <a class="cursor-pointer list-group-item list-group-item-action" *ngFor="let language of environment.allowedLanguages" (click)="changeLanguage($event, language)">
                    <img src="assets/img/{{language}}.gif" class="align-middle" alt="{{ 'intl.language.name.' + language | translate }}"/>
                    {{ 'intl.language.name.' + language | translate }}
                </a>
            </div>
        </div>
    </div>
    <a class="nav-link" (click)="handleLogoutConfirmation()">{{ 'button.logout' | translate }}</a>
    <div class="social-media">
        <p>{{ 'text.atraveo.on' | translate }}:</p>
        <div class="icons d-flex mb-3">
            <a [href]="translateService.currentLang === 'de' ? 'https://www.instagram.com/atraveo/' : 'https://www.instagram.com/atraveo_en/'" target="_blank">
                <i class="fab fa-instagram-square share"></i>
            </a>
            <a [href]="translateService.currentLang === 'de' ? 'https://www.facebook.com/atraveo' : 'https://www.facebook.com/atraveoEN'" target="_blank">
                <i class="fab fa-facebook-square"></i>
            </a>
            <a href="https://www.youtube.com/@atraveobyedomizil" target="_blank">
                <i class="fab fa-youtube-square"></i>
            </a>
            <a href="https://www.pinterest.de/atraveo/" target="_blank">
                <i class="fab fa-pinterest-square"></i>
            </a>
            <a href="https://ch.linkedin.com/company/e-domizil-ag" target="_blank">
                <i class="fab fa-linkedin"></i>
            </a>
        </div>
    </div>

    <hr class="dropdown-divider" />
    <span class="copyright">© 1997 - {{ this.currentYear }}</span>
    <span class="copyright">atraveo by e-domizil</span>
</nav>
