import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ConstantsService {
    static readonly ROLE_OWNER = 'owner';
    static readonly ROLE_ADMIN = 'admin_overall';
    static readonly ROLE_OWNERSERVICE = 'admin_owner';
    static readonly ROLE_CUSTOMERSERVICE = 'CUSTOMERSERVICE'; // Gibt es nicht im Authservice?
    static readonly ROLE_DESTINATION = 'destination';

    readonly FEATUREDEFINITION_TYPE_CHECKBOX = 'CHECKBOX';
    readonly FEATUREDEFINITION_TYPE_INPUT = 'INPUT';
    readonly FEATUREDEFINITION_TYPE_SELECT = 'SELECT';
    readonly FEATUREDEFINITION_TYPE_MULTISELECT = 'MULTISELECT';

    readonly PRICE_TYPE_DAY = 'DAY';
    readonly PRICE_TYPE_WEEK = 'WEEK';

    static readonly MOBILE_BELOW_RESOLUTION = 576;

    constructor() {}
}
