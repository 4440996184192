export class MessageDto {
    accommodationId: string;
    bookingNumber: string;
    date: Date;
    id: string;
    isRead: string;
    kontoid: string;
    opinion: string;
    receiver: string;
    sender: string;
    subject: string;
    attachments: object[];

    // @ts-ignore
    static backOfficeToClass(data: any): MessageDto {
        const message = new MessageDto();
        message.accommodationId = data.accommodationId;
        message.bookingNumber = data.bookingNumber;
        message.date = data.date;
        message.id = data.id;
        message.isRead = data.isRead;
        message.kontoid = data.kontoid;
        message.opinion = data.opinion;
        message.receiver = data.receiver;
        message.sender = data.sender;
        message.subject = data.subject;
        message.attachments = data.attachment;
        return message;
    }
}
