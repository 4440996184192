<ul class="nav nav-tabs nav-fill">
    <li class="nav-item">
        <a class="nav-link cursor-pointer" data-toggle="tab" role="tab"
           [class.active]="this.selectedTab == 'open'"
           (click)="this.setSelectedTab('open')">{{ 'atraveo.bookingbundle.navigation.homepage.title' | translate }}</a>
    </li>
    <li class="nav-item">
        <a class="nav-link cursor-pointer" data-toggle="tab"
           [class.active]="this.selectedTab == 'confirmed'"
           (click)="this.setSelectedTab('confirmed')"
           role="tab">{{ 'atraveo.bookingbundle.navigation.confirmed.title' | translate }}</a>
    </li>
    <li class="nav-item">
        <a class="nav-link cursor-pointer" data-toggle="tab"
           [class.active]="this.selectedTab == 'cancelled'"
           (click)="this.setSelectedTab('cancelled')"
           role="tab">{{ 'atraveo.bookingbundle.navigation.cancelled.title' | translate }}</a>
    </li>
</ul>

<div class="tab-content" style="display: flex; justify-content: space-between; flex-direction: column;">
    <div style="order: 1" class="nav-item-sm mt-3" [class.active]="this.selectedTab == 'open'" (click)="this.setSelectedTab(((this.selectedTab == 'open') ? '' : 'open'))">
        <i class="fa" [ngClass]="this.selectedTab == 'open' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.bookingbundle.navigation.homepage.title' | translate }}
    </div>
    <div style="order: 3" class="nav-item-sm mt-3" [class.active]="this.selectedTab == 'confirmed'" (click)="this.setSelectedTab(((this.selectedTab == 'confirmed') ? '' : 'confirmed'))">
        <i class="fa" [ngClass]="this.selectedTab == 'confirmed' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.bookingbundle.navigation.confirmed.title' | translate }}
    </div>
    <div style="order: 5" class="nav-item-sm mt-3" [class.active]="this.selectedTab == 'cancelled'" (click)="this.setSelectedTab(((this.selectedTab == 'cancelled') ? '' : 'cancelled'))">
        <i class="fa" [ngClass]="this.selectedTab == 'cancelled' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.bookingbundle.navigation.cancelled.title' | translate }}
    </div>
    <app-booking-list-tab *ngIf="selectedTab" [style]="'order: ' + selectedTabOrder" [bookingStatusType]="this.selectedTab"></app-booking-list-tab>
</div>