import { AdditionalCostTemplateEntity } from 'data-structures/lib/es6/entity/additional-cost/template/template.entity';
import { ExtendedAdditionalCost } from './extended-addition-cost';

export class ExtendedAdditionalCostTemplateEntity extends AdditionalCostTemplateEntity {
    additionalCosts: ExtendedAdditionalCost[];

    orderAdditionalCostsByObligation() {
        if (this.additionalCosts && this.additionalCosts.length) {
            this.additionalCosts.sort((a, b) => {
                return a.obligation - b.obligation;
            });
        }
    }
}
