<div>
    <select
        multiple
        id="{{ idKey }}"
        class="{{ classKey }}"
        data-actions-box="true"
        data-live-search="true"
        [attr.data-header]="'text.arrival.type' | translate"
        [attr.data-select-all-text]="'select.all' | translate"
        [attr.data-deselect-all-text]="'select.none' | translate"
        [attr.data-none-selected-text]="noneSelectedDisplayAll ? ('text.filter.all.0' | translate) : ('select.none' | translate)"
        [(ngModel)]="selectedAccommodationIds"
        [ngModelOptions]="{ standalone: true }"
    >
        <ng-container *ngIf="accommodations">
            <option
                *ngIf="includeAllObjects"
                [ngValue]="null"
                [selected]="!selectedAccommodationIds || selectedAccommodationIds.length == 0 || (selectedAccommodationIds.length === 1 && selectedAccommodationIds[0] === null)"
            >
                {{ 'text.filter.all.0' | translate }}
            </option>
            <ng-container *ngFor="let accommodation of this.accommodations">
                <!-- keine Ahnung was diese Abfrage soll, hat aber wohl was mit Bankdaten zu tun und macht ständig nur Probleme -->
                <ng-container
                    *ngIf="
                        (assignedAccommodationIdsToBankAccounts?.includes(accommodation.accommodationId) && selectedAccommodationIds?.includes(accommodation.accommodationId)) ||
                        !assignedAccommodationIdsToBankAccounts?.includes(accommodation.accommodationId) ||
                        showAllIds
                    "
                >
                    <option
                        [ngValue]="accommodation.accommodationId"
                        value="{{ accommodation.accommodationId }}"
                        [selected]="selectedAccommodationIds && selectedAccommodationIds.includes(accommodation.accommodationId)"
                    >
                        {{ accommodation.accommodationId }}
                        <span *ngIf="accommodation.main?.name"> ({{ accommodation.main?.name }}) </span>
                    </option>
                </ng-container>
            </ng-container>
        </ng-container>
    </select>
</div>
