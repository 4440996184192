import { Component, Input, OnInit } from '@angular/core';
import { BackOfficeConnectorService } from '../../../../../services/api-connector/back-office-connector-service';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { ExtendedAccommodationEntity } from '../../../../../entities/extendedAccommodationEntity';
import { BookingEntity } from 'data-structures/lib/es6/entity/booking.entity';
import { MessageRequestDto } from '../../../../../entities/Messages/messageRequestDto';
import { ConfirmationDialogService } from '../../../../global/confirmation-dialog/confirmation-dialog.service';
import { DialogService } from '../../../../../services/dialog.service';
import { MessagesFilterDialogComponent } from '../../../../global/dialogs/messages-filter-dialog/messages-filter-dialog.component';

@Component({
    selector: 'app-outbox',
    templateUrl: './outbox.component.html',
    styleUrls: ['./outbox.component.scss'],
})
export class OutboxComponent implements OnInit {
    @Input() bookings: BookingEntity[];
    @Input() messageRequest: MessageRequestDto = new MessageRequestDto();
    messages = [];
    messagesCount: number;
    selectedMessages = [];
    noSelectedMessages = true;
    selectedElements = [];

    accountIds: {
        ownerService: string;
        customerService: string;
        customerRatings: string;
    };

    constructor(
        readonly apiConnector: ApiConnectorService,
        readonly authenticationService: AuthenticationService,
        readonly backofficeApiConnector: BackOfficeConnectorService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly dialogService: DialogService,
    ) {}

    async ngOnInit() {
        this.accountIds = { ownerService: '32', customerService: '17', customerRatings: '70' };
        this.messageRequest.isSend = 1;
        await this.filterMessages();
    }

    changeState() {
        const checkboxCheckLength = document.querySelectorAll('input[name="outbox-checkbox"]:checked').length;
        this.noSelectedMessages = !checkboxCheckLength;
        document.querySelector('.notification-outbox-buttons').classList.toggle('active', checkboxCheckLength > 0);
    }

    async filterMessages() {
        this.messages = await this.backofficeApiConnector.getOwnerMessages(this.messageRequest, this.authenticationService.currentUser.ownerNumber);
        this.messagesCount = this.messages[this.messages.length - 1];
        // @ts-ignore
        this.dialogService.closeDialog();
    }

    async clearFilter() {
        this.messageRequest.searchInSubject = '';
        this.filterMessages();
    }

    async changePage(pageNumber: number) {
        this.messageRequest.page = String(pageNumber);
        this.filterMessages();
    }

    selectMessages(isRead: boolean = false, isDelete: boolean = false) {
        this.selectedElements = [];
        this.selectedMessages = [];

        document.querySelectorAll('input[name=outbox-checkbox]:checked').forEach((element) => {
            if (isDelete || (isRead && element.parentElement.parentElement.classList.contains('not-read')) || (!isRead && !element.parentElement.parentElement.classList.contains('not-read'))) {
                this.selectedElements.push(element.parentNode.parentNode);
                this.selectedMessages.push(Number(element.id));
            }
            $(element).prop('checked', false);
        });

        document.querySelector('.notification-outbox-buttons').classList.remove('active');
    }

    selectAllMessages() {
        const checkboxes = document.querySelectorAll('.outbox-checkbox');

        checkboxes.forEach((checkbox) => {
            checkbox.toggleAttribute('checked');
        });

        this.changeState();
    }

    deleteMessage = async () => {
        this.selectMessages(false, true);

        this.selectedElements.forEach((element) => element.remove());

        $(this.selectedElements).remove();
        await this.backofficeApiConnector.setMessageStatus(this.authenticationService.currentUser.ownerNumber, 'delete', this.selectedMessages, 1);

        if (document.querySelectorAll('.notification-content-outbox').length === 0) {
            await this.filterMessages();
        }
        this.noSelectedMessages = true;
    };

    // todo testen anscheinend muss in der api eine /inventory api angelegt werden
    openConfirmationDialog() {
        this.confirmationDialogService
            .confirm('text.notification.option.delete', 'confirm.content.delete.0')
            .then(async (confirmed) => {
                if (confirmed) {
                    this.deleteMessage();
                }
            })
            .catch(() => undefined);
    }

    openFilterModal() {
        this.dialogService.openDialog(
            MessagesFilterDialogComponent,
            { dialogWidth: this.dialogService.dialogWidth.SM },
            {
                messageRequest: this.messageRequest,
                accountIds: this.accountIds,
                bookings: this.bookings,
                reset: this.resetMessagesFilter.bind(this),
                filter: this.filterMessages.bind(this),
            },
        );
    }

    async resetMessagesFilter() {
        this.messageRequest.resetFilterValues();
        await this.filterMessages();
    }
}
