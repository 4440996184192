import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ExtendedAccommodationEntity } from '../entities/extendedAccommodationEntity';

@Injectable({
    providedIn: 'root'
})
export class AccommodationUpdateService {

    private subject = new Subject<ExtendedAccommodationEntity>();

    constructor() {
    }

    getUpdatedMessage(): Observable<ExtendedAccommodationEntity> {
        return this.subject.asObservable();
    }

    sendUpdatedMessage(accommodation: ExtendedAccommodationEntity) {
        this.subject.next(accommodation);
    }

    getNewAccommodationMessage(): Observable<ExtendedAccommodationEntity> {
        return this.subject.asObservable();
    }

    sendNewAccommodationMessage(accommodation: ExtendedAccommodationEntity) {
        this.subject.next(accommodation);
    }
}
