<h1 class="page-heading">{{ 'text.headline' | translate }}: {{ 'text.owner.number' | translate }} {{ owner.ownerNumber }}</h1>

<ul class="nav nav-tabs nav-justified" id="profile-tab" role="tablist">
    <li class="nav-item">
        <a class="nav-link"
           [class.active]="this.selectedTab == 'profile'"
           (click)="this.selectedTab='profile'"
           id="owner-profile-tab" data-toggle="tab" href="#owner-profile" role="tab" aria-controls="owner-profile" aria-selected="true">
            {{ 'tab.trade.partner' | translate }}
        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link"
           [class.active]="this.selectedTab == 'contact'"
           (click)="this.selectedTab='contact'"
           id="contact-person-tab" data-toggle="tab" href="#contact-person" role="tab" aria-controls="contact-person" aria-selected="false">
            {{ 'tab.contact.person' | translate }}
        </a>
    </li>
</ul>

<div class="tab-content">
    <div class="nav-item-sm mt-3" [class.active]="this.selectedTab == 'profile'" (click)="this.selectedTab= this.selectedTab == 'profile' ? '' : 'profile'">
        <i class="fa" [ngClass]="this.selectedTab == 'profile' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'tab.trade.partner' | translate }}
    </div>
    <div class="tab-pane"
         [ngClass]="{'active': this.selectedTab == 'profile', 'show': this.selectedTab == 'profile', 'fade': this.selectedTab != 'profile'}"
         id="owner-profile" role="tabpanel" aria-labelledby="owner-profile-tab">
        <app-owner-profile></app-owner-profile>
    </div>
    <div class="nav-item-sm mt-3" [class.active]="this.selectedTab == 'contact'" (click)="this.selectedTab= this.selectedTab == 'contact' ? '' : 'contact'">
        <i class="fa" [ngClass]="this.selectedTab == 'contact' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'tab.contact.person' | translate }}
    </div>
    <div class="tab-pane"
         [ngClass]="{'active': this.selectedTab == 'contact', 'show': this.selectedTab == 'contact', 'fade': this.selectedTab != 'contact'}"
         id="contact-person" role="tabpanel" aria-labelledby="contact-person-tab">
        <app-contact-person></app-contact-person>
    </div>
</div>
