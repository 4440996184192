import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RegistrationDataService {
    private newAccommodation = new Subject<any>();
    private newUser: boolean = false;

    async saveAccommodation() {
        this.newAccommodation.next(null);
    }

    registerNewAccommodation(): Observable<any> {
        return this.newAccommodation.asObservable();
    }

    isNewUser(): boolean {
        return this.newUser;
    }

    setNewUser() {
        this.newUser = true;
    }
}
