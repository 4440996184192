<div *ngIf="accommodationOrTemplate && additionalCostRules.length" class="container">
    <form [formGroup]="createForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-lg-6 col-12">
                <div class="form-group">
                    <label for="select-additionalCost">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.type' | translate }} *</label
                    ><app-tooltip data-placement="right" [text]="'info.nl_art' | translate"></app-tooltip>
                    <select
                        id="select-additionalCost"
                        name="additionalCost"
                        formControlName="additionalCost"
                        class="form-control custom-select"
                    >
                        <option value="">{{ 'text.select.empty' | translate }}</option>
                        <ng-container *ngFor="let definition of this.sortedAdditionalCostDefinitions">
                            <ng-container *ngIf="additionalCostIsNew">
                                <option *ngIf="definition.additionalCostId !== 'ASENT'" value="{{ definition.additionalCostId }}">
                                    {{ definition.additionalCostName[this.translationService.currentLang] }}
                                </option>
                            </ng-container>
                            <ng-container *ngIf="!additionalCostIsNew">
                                <option value="{{ definition.additionalCostId }}">{{ definition.additionalCostName[this.translationService.currentLang] }}</option>
                            </ng-container>
                        </ng-container>
                    </select>
                    <app-validation-error-messages [control]="createForm.controls.additionalCost"></app-validation-error-messages>
                </div>

                <div class="form-group" *ngIf="formContainerVisibilty.get('obligationcontainer')">
                    <label for="select-obligation">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.obligationType' | translate }} </label
                    ><app-tooltip data-placement="right" [text]="'info.nl_pflicht' | translate"></app-tooltip>
                    <select
                        id="select-obligation"
                        name="obligation"
                        formControlName="obligation"
                        class="form-control custom-select">
                        <option value="">{{ 'text.select.empty' | translate }}</option>
                        <option *ngFor="let obligation of formObligations" [value]="obligation">
                            {{ 'atraveo.accommodationbundle.price.create.additionalcost.field.obligation.' + obligation | translate }}</option>
                    </select>
                    <app-validation-error-messages [control]="createForm.controls.obligation"></app-validation-error-messages>
                </div>

                <div class="form-group" *ngIf="formContainerVisibilty.get('intervalcontainer')">
                    <label for="select-interval">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.0' | translate }}</label
                    ><app-tooltip data-placement="right" [text]="'info.nl_intervall' | translate"></app-tooltip>
                    <select
                        id="select-interval"
                        name="interval"
                        formControlName="interval"
                        class="form-control custom-select">
                            <option value="">{{ 'text.select.empty' | translate }}</option>
                            <option *ngFor="let interval of formIntervals" [value]="interval">
                                {{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.' + interval | translate }}</option>
                    </select>
                    <app-validation-error-messages [control]="createForm.controls.interval"></app-validation-error-messages>
                </div>

                <div class="form-group" *ngIf="formContainerVisibilty.get('unitcontainer')">
                    <label for="select-unit">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.unit.0' | translate }}</label
                    ><app-tooltip data-placement="right" [text]="'info.nl_einheit' | translate"></app-tooltip>
                    <select
                        class="form-control"
                        id="select-unit"
                        name="unit"
                        formControlName="unit"
                        >
                        <option value="">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.ko' | translate }}</option>
                        <option *ngFor="let unit of formUnits" [value]="unit">
                            {{ 'atraveo.accommodationbundle.price.create.additionalcost.field.unit.' + unit | translate }}</option>
                    </select>
                    <app-validation-error-messages [control]="createForm.controls.unit"></app-validation-error-messages>
                </div>

                <div class="form-group" *ngIf="formContainerVisibilty.get('piececountcontainer')">
                    <label for="piececount">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.pieceCount' | translate }}</label>

                    <input
                        type="number"
                        id="piececount"
                        name="maxPieceCount"
                        formControlName="maxPieceCount"
                        class="form-control"/>
                    <app-validation-error-messages [control]="createForm.controls.maxPieceCount"></app-validation-error-messages>
                </div>
            </div>
            <div class="col-lg-6 col-12" *ngIf="formContainerVisibilty.get('pricerow')">
                <div class="row" *ngIf="formContainerVisibilty.get('localdepositcontainer')">
                    <div class="col form-group">
                        <label for="select-localPaymentDeposit">{{ 'atraveo.accommodationbundle.deposit.field.payType' | translate }}</label>
                        <select
                            id="select-localPaymentDeposit"
                            name="paymentType"
                            formControlName="paymentType"
                            class="form-control custom-select">
                            <option value="">{{ 'text.select.empty' | translate }}</option>
                            <option value="LOCAL_CASH">{{ 'atraveo.accommodationbundle.deposit.paymenthandling.CashAtLocation' | translate }}</option>
                            <option value="CREDIT_CARD">{{ 'atraveo.accommodationbundle.deposit.paymenthandling.ccAtLocation' | translate }}</option>
                            <!--<option value="IN_ADVANCE">{{ 'atraveo.accommodationbundle.deposit.paymenthandling.inAdvance' | translate }}</option>-->
                        </select>
                        <app-validation-error-messages [control]="createForm.controls.paymentType"></app-validation-error-messages>
                    </div>
                    <div class="col form-group">
                        <label for="select-localBackpayDeposit">{{ 'atraveo.accommodationbundle.deposit.field.payback' | translate }}</label>

                        <select id="select-localBackpayDeposit"
                                name="refundType"
                                formControlName="refundType"
                                class="form-control custom-select">
                            <option value="">{{ 'text.select.empty' | translate }}</option>
                            <option value="LOCAL_CASH">{{ 'atraveo.accommodationbundle.deposit.refundHandling.cashAtLocation' | translate }}</option>
                            <option value="BANK_TRANSFER">{{ 'atraveo.accommodationbundle.deposit.refundHandling.bankTransfer' | translate }}</option>
                            <option value="CREDIT_CARD">{{ 'atraveo.accommodationbundle.deposit.refundHandling.cc' | translate }}</option>
                        </select>
                        <app-validation-error-messages [control]="createForm.controls.refundType"></app-validation-error-messages>
                    </div>
                </div>


                <div *ngIf="formContainerVisibilty.get('pricecontainer')">
                    <div class="form-group">
                        <label *ngIf="!this.priceIsOptional">{{ 'atraveo.accommodationbundle.additionalcost.field.rate.0' | translate }} *</label>
                        <label *ngIf="this.priceIsOptional">{{ 'atraveo.accommodationbundle.additionalcost.field.rate.optional' | translate }}</label>


                        <div class="form-check pricefieldcontainer" *ngIf="formContainerVisibilty.get('pricefieldcontainer')">
                            <div class="row">
                                <div class="col-5">
                                    <input type="radio"
                                           name="priceType"
                                           formControlName="priceType"
                                           value="price"
                                           class="form-check-input"
                                           id="price-type-fixed"/>
                                    <label for="price-type-fixed">{{ 'atraveo.accommodationbundle.deposit.form.field.rateType.fixed' | translate }}</label>
                                </div>
                                <div class="col-7">
                                    <div class="input-group" *ngIf="formContainerVisibilty.get('pricefield')">
                                        <input
                                            type="number"
                                            id="price"
                                            step="0.01"
                                            name="price"
                                            class="form-control"
                                            formControlName="price" />
                                        <div class="input-group-append">
                                            <select
                                                id="select-currency"
                                                name="currency"
                                                formControlName="currency"
                                                class="form-control"
                                            >
                                                <option value="{{ currency }}" *ngFor="let currency of currencies">{{ currency }}</option>
                                            </select>
                                        </div>
                                        <app-validation-error-messages [control]="createForm.controls.price"></app-validation-error-messages>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-100"></div>

                        <div class="form-check percentfieldcontainer" *ngIf="formContainerVisibilty.get('percentfieldcontainer')">
                            <div class="row">
                                <div class="col-8">
                                    <input type="radio"
                                           name="priceType"
                                           formControlName="priceType"
                                           value="percent"
                                           class="form-check-input"
                                           id="price-type-percent" />
                                    <label for="price-type-percent">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.rate.percent' | translate }}</label>
                                </div>
                                <div class="col-4">
                                    <div class="input-group" *ngIf="formContainerVisibilty.get('percentfield')">
                                        <input type="number"
                                               id="percent"
                                               step="0.01"
                                               name="percent"
                                               class="form-control"
                                               formControlName="percent" />
                                        <div class="input-group-append">
                                            <div class="input-group-text">%</div>
                                        </div>
                                        <app-validation-error-messages [control]="createForm.controls.percent"></app-validation-error-messages>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-100"></div>

                        <div class="form-check dailyrentalfieldcontainer" *ngIf="formContainerVisibilty.get('dailyrentalfieldcontainer')">
                            <div class="row">
                                <div class="col-8">
                                    <input
                                        type="radio"
                                        name="priceType"
                                        value="dailyRental"
                                        formControlName="priceType"
                                        class="form-check-input"
                                        id="price-type-daily-rental"/>
                                    <label for="price-type-daily-rental">
                                        {{ 'atraveo.accommodationbundle.price.create.additionalcost.field.rate.days' | translate }}
                                    </label>
                                </div>

                                <div class="col-4">
                                    <div class="input-group" *ngIf="formContainerVisibilty.get('dailyRentalField')">
                                        <input
                                            type="number"
                                            step="0.01"
                                            id="dailyRental"
                                            name="dailyRental"
                                            class="form-control"
                                            formControlName="dailyRental" />
                                        <app-validation-error-messages [control]="createForm.controls.dailyRental"></app-validation-error-messages>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="formContainerVisibilty.get('checkboxcontainer')">
                    <div class="form-group form-check" *ngIf="formContainerVisibilty.get('ordercontainer')">
                        <input
                            type="checkbox"
                            id="order"
                            formControlName="hasToBeOrdered"
                            name="hasToBeOrdered"
                            class="form-check-input"
                        />
                        <label for="order" class="form-check-label">
                            {{ 'atraveo.accommodationbundle.price.create.additionalcost.field.mustBeOrdered' | translate }}
                        </label>
                        <app-tooltip data-placement="right" [text]="'info.nl_bestellen' | translate"></app-tooltip>
                    </div>


                    <div class="form-group form-check" *ngIf="formContainerVisibilty.get('localcheckboxcontainer')">
                        <input
                            type="checkbox"
                            id="local"
                            formControlName="hasToBePaidLocal"
                            class="form-check-input"
                            name="hasToBePaidLocal"
                            (click)="noticeIfOrigin()"/>
                        <label for="local" [class.service]="editableDepositBecauseEmployee" >
                            {{ 'atraveo.accommodationbundle.price.create.additionalcost.field.payableAtLocation.0' | translate }}
                        </label>
                        <app-tooltip data-placement="right" [text]="'info.nl_bezahlen' | translate"></app-tooltip>
                        <app-validation-error-messages [control]="createForm.controls.hasToBePaidLocal"></app-validation-error-messages>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="limitationscontainer" *ngIf="formContainerVisibilty.get('limitationscontainer')">
                    <p>
                        <a class="limitation-toggle" [class.service]="showLimitationsBecauseEmployee" data-toggle="collapse" href="#limitations">
                            <i class="fa fa-fw fa-chevron-right"></i>
                            {{ 'atraveo.accommodationbundle.price.create.additionalcost.limitations' | translate }}
                        </a>
                        <app-tooltip data-placement="right" [text]="'info.einschraenkungen' | translate"></app-tooltip>
                    </p>

                    <div class="limitations collapse" id="limitations">
                        <div class="row">
                            <div *ngIf="formContainerVisibilty.get('minmaxcontainer')" class="col-lg-6 col-12 form-group">
                                <label for="min">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.min-max-info' | translate }}</label>
                                <div class="row">
                                    <div class="col-6">
                                        <input
                                            type="number"
                                            id="min"
                                            step="0.01"
                                            name="minPrice"
                                            placeholder="{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.minRate' | translate }}"
                                            formControlName="minPrice"
                                            class="form-control"/>
                                            <app-validation-error-messages [control]="createForm.controls.minPrice"></app-validation-error-messages>
                                    </div>
                                    <div class="col-6">
                                        <input
                                            type="number"
                                            id="max"
                                            step="0.01"
                                            name="maxPrice"
                                            formControlName="maxPrice"
                                            placeholder="{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.maxRate' | translate }}"
                                            class="form-control"/>
                                            <app-validation-error-messages [control]="createForm.controls.maxPrice"></app-validation-error-messages>
                                    </div>
                                </div>
                            </div>

                            <div *ngFor="let fieldsAndTranslations of activeMixMaxFieldsAndTranslations" class="{{ fieldsAndTranslations.variable }} col-lg-6 col-12 form-group">
                                <label for="{{ fieldsAndTranslations.variable }}-min">
                                    {{ 'atraveo.accommodationbundle.price.create.additionalcost.limitations' + fieldsAndTranslations.texts + '.prefix' | translate }}
                                </label>
                                <app-tooltip data-placement="right" [text]="fieldsAndTranslations.info | translate"></app-tooltip>
                                <div class="row">
                                    <div class="col-3">
                                        <input
                                            class="form-control"
                                            type="number"
                                            id="{{ fieldsAndTranslations.variable }}-min"
                                            step="1"
                                            attr.data-name="{{ fieldsAndTranslations.variable }}-min"
                                            [name]="fieldsAndTranslations.variable + '-min'"
                                            [formControlName]="fieldsAndTranslations.variable + 'Min'"
                                        />
                                    </div>
                                    <div class="col-2">
                                        {{ 'atraveo.accommodationbundle.price.create.additionalcost.limitations' + fieldsAndTranslations.texts + '.glue' | translate }}
                                    </div>
                                    <div class="col-3">
                                        <input
                                            class="form-control"
                                            type="number"
                                            id="{{ fieldsAndTranslations.variable }}-max"
                                            step="1"
                                            [name]="fieldsAndTranslations.variable + '-max'"
                                            attr.data-name="{{ fieldsAndTranslations.variable }}-max"
                                            [formControlName]="fieldsAndTranslations.variable + 'Max'"
                                        />
                                    </div>
                                    <div class="col-4 unit-text">
                                        {{ 'atraveo.accommodationbundle.price.create.additionalcost.limitations' + fieldsAndTranslations.texts + '.suffix' | translate }}
                                    </div>
                                    <div class="col-12">
                                        <app-validation-error-messages [control]="createForm.get(fieldsAndTranslations.variable + 'Min')"></app-validation-error-messages>
                                        <app-validation-error-messages [control]="createForm.get(fieldsAndTranslations.variable + 'Max')"></app-validation-error-messages>
                                    </div>
                                </div>
                            </div>


                            <div class="travelPeriod col-lg-6 col-12 form-group">
                                <label for="travelPeriod-from">
                                    {{ 'atraveo.accommodationbundle.deposit.form.field.limitationsDate.prefix' | translate }}
                                    <app-tooltip data-placement="right" [text]="'info.reisezeitraum' | translate"></app-tooltip>
                                </label>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <input
                                            class="form-control"
                                            type="date"
                                            id="travelPeriod-from"
                                            name="travelPeriod-from"
                                            formControlName="travelPeriodFrom"
                                            [min]="minDateFrom"
                                            [max]="maxDateFrom"
                                        />
                                    </div>
                                    <div class="col-2">
                                        {{ 'atraveo.accommodationbundle.deposit.form.field.limitationsDate.glue' | translate }}
                                    </div>
                                    <div class="col-5">
                                        <input
                                            class="form-control"
                                            type="date"
                                            id="travelPeriod-to"
                                            name="travelPeriod-to"
                                            formControlName="travelPeriodTo"
                                            [min]="minDateTo"
                                            [max]="maxDateTo"
                                        />
                                    </div>
                                    <div class="col-12">
                                        <div class="form-check">
                                            <input
                                                type="checkbox"
                                                id="years"
                                                formControlName="followingYears"
                                                name="followingYears"
                                                class="form-check-input" />
                                            <label for="years" class="form-check-label">{{ 'atraveo.accommodationbundle.price.create.additionalcost.limitationsDateIgnoreYear' | translate }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p *ngIf="isDateRangeToLong" class="error">{{ 'atraveo.accommodationbundle.price.accommodation-daterange-too-long' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-3" *ngIf="!this.priceIsOptional">
            {{ 'text.mandatoryfield' | translate }}
        </div>

        <a class="btn btn-link" [class.service]="isAdmin" *ngIf="isAdmin" (click)="debug()">Debug (console)</a>
        <div>
            <button
                type="submit"
                class="btn btn-success float-right save-button"
            >
                <i class="fas fa-cloud-upload-alt mr-2"></i>
                {{ 'atraveo.accommodationbundle.price.create.save' | translate }}
            </button>
        </div>
    </form>
</div>
