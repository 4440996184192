import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OnboardingService {
    private setSteps = new Subject<void>();

    setSteps$ = this.setSteps.asObservable();

    reloadSteps() {
        this.setSteps.next();
    }
}
