import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiConnectorService } from '../../../../services/api-connector/api-connector.service';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { ExtendedAccommodationEntity } from '../../../../entities/extendedAccommodationEntity';
import { TranslateService } from '@ngx-translate/core';
import { RegistrationDataService } from '../../../../services/registration/registration-data.service';
import { ProgressCheckService } from '../../../../services/accommodation/check/progress-check.service';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { PendingChangesComponentService } from '../../../../services/pending-changes-component/pending-changes-component.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-accommodation',
    templateUrl: './accommodation.component.html',
    styleUrls: ['./accommodation.component.scss'],
})
export class AccommodationComponent implements OnInit, OnDestroy {
    accommodationId: number = null;
    accommodation: ExtendedAccommodationEntity;
    registrationNewUser: boolean = false;
    accommodationOriginal: ExtendedAccommodationEntity;
    accommodationSubscription: Subscription;
    selectedTab: string = '';
    registrationDataSubscription: Subscription;
    selectedTabOrder: number = 2;
    showRouterContent: boolean = true;

    constructor(
        readonly route: ActivatedRoute,
        readonly apiConnectorService: ApiConnectorService,
        readonly authenticationService: AuthenticationService,
        readonly translateService: TranslateService,
        readonly translate: TranslateService,
        readonly registrationData: RegistrationDataService,
        readonly progressCheckService: ProgressCheckService,
        readonly router: Router,
        readonly pendingChangesComponentService: PendingChangesComponentService,
    ) {}

    async ngOnInit() {
        // hole mir den selectedTab aus der URL
        this.setSelectedTab(this.getTab(this.router.url));
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.setSelectedTab(this.getTab(event.url));
        });

        this.accommodationSubscription = this.apiConnectorService.activeAccommodation$.subscribe((accommodation) => {
            if (accommodation) {
                this.accommodation = cloneDeep(accommodation);
            }
        });

        this.route.params.subscribe(async (params) => {
            // wenn es kein neues objekt ist, dann subscribe auf das aktive Objekt
            if (params.accommodationId === 'register') {
                this.accommodation = new ExtendedAccommodationEntity();
                this.accommodation.addRequiredFields();
                this.apiConnectorService.setActiveAccommodation(this.accommodation);
            } else {
                // kurz resetten, damit das alte Objekt nicht mehr angezeigt wird
                this.apiConnectorService.setActiveAccommodation(null);
                // start das Laden des Objekts
                this.accommodationId = +params.accommodationId;
                await this.apiConnectorService.getAccommodationByAccommodationId(this.accommodationId, this.authenticationService.currentUser);
                this.setSelectedTab(this.getTab(this.router.url));
            }
        });
    }

    getTab(url: string): string {
        // bereinige GET Parameter und Hashes
        const urlParts = url.split(/[?#]/)[0].split('/');
        if (urlParts.length > 0) {
            // wenn das vorletzte Element 'image' ist, dann nimm das vorletzte, sonst das letzte
            if (urlParts[urlParts.length - 2] === 'image') {
                return urlParts[urlParts.length - 2];
            }
            return urlParts[urlParts.length - 1];
        }
        return '';
    }

    setSelectedTab(tab: string): void {
        if (['position', 'main', 'model', 'features', 'description', 'images', 'image', 'prices', 'calendar', 'overview'].includes(tab)) {
            this.selectedTab = tab;
            switch (tab) {
                case 'position': {
                    this.selectedTabOrder = 2;
                    break;
                }
                case 'main': {
                    this.selectedTabOrder = 4;
                    break;
                }
                case 'model': {
                    this.selectedTabOrder = 6;
                    break;
                }
                case 'features': {
                    this.selectedTabOrder = 8;
                    break;
                }
                case 'description': {
                    this.selectedTabOrder = 10;
                    break;
                }
                case 'images': {
                    this.selectedTabOrder = 12;
                    break;
                }
                case 'image': {
                    this.selectedTabOrder = 12;
                    break;
                }
                case 'prices': {
                    this.selectedTabOrder = 14;
                    break;
                }
                case 'calendar': {
                    this.selectedTabOrder = 16;
                    break;
                }
                default:
                    this.selectedTabOrder = 0;
            }
        } else {
            this.selectedTab = null;
        }
    }

    ngOnDestroy() {
        this.accommodationSubscription?.unsubscribe();
        this.registrationDataSubscription?.unsubscribe();
        this.apiConnectorService.setActiveAccommodation(null);
    }

    handleClick(tab: string) {
        if (this.selectedTab === tab || (['images', 'image'].includes(tab) && ['images', 'image'].includes(this.selectedTab))) {
            this.showRouterContent = !this.showRouterContent;
        } else {
            this.router.navigate(['/accommodation/' + this.accommodation.accommodationId + '/' + tab]);
            this.showRouterContent = true;
        }
    }
}
