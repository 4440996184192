import { PriceRuleDto } from 'data-structures/lib/es6/dto/accommodation';
import { TravelPeriodDto } from 'data-structures/lib/es6/dto/accommodation/price-rule/travel-period.dto';
import { Exclude } from 'class-transformer';

export enum PriceTypes {
    'Day' = 'ta',
    'Week' = 'wo',
    'Weekend' = 'we',
    'ShortWeekend' = 'kw',
    'Midweek' = 'mw',
}

export class ExtendedPriceRule extends PriceRuleDto {
    @Exclude()
    private calculatePriceForFollowingYears = 2;

    @Exclude()
    id: number;

    constructor() {
        super();
        this.travelPeriod = new TravelPeriodDto();
    }
}
