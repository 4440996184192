import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ExtendedBookingEntity } from '../entities/extended-booking-entity';
import { BookingEntity } from 'data-structures/lib/es6/entity/booking.entity';

@Injectable({
    providedIn: 'root',
})
export class BookingUpdateService {
    private createdSubject = new Subject<BookingEntity>();

    constructor() {}

    getCreatedMessage(): Observable<BookingEntity> {
        return this.createdSubject.asObservable();
    }

    sendCreatedMessage(booking: BookingEntity) {
        this.createdSubject.next(booking);
    }
}
