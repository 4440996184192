import { OwnerPaymentDto } from 'data-structures/lib/es6/dto/owner/put-owner/owner-payment.dto';
import { Type } from 'class-transformer';
import { ExtendedBankAccountEntity } from './extended-bank-account-entity';
import { BankAccountDto } from 'data-structures/dto/accommodation/index';

export interface ExtendedBankAccounts {
    [key: string]: ExtendedBankAccountEntity;
}

export class ExtendedOwnerPaymentDto extends OwnerPaymentDto {
    bankAccounts: ExtendedBankAccounts;
}
