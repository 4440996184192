import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class UserValidTokenGuard {
    constructor(readonly router: Router, readonly authenticationService: AuthenticationService) {}

    async canActivate() {
        const currentUser = this.authenticationService.currentUser;
        if (currentUser) {
            if (!this.authenticationService.isTokenValid) {
                this.authenticationService.logout();
                return false;
            }
        }
        return true;
    }
}
