import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ExtendedAccommodationGroupEntity } from '../../../../../entities/extendedAccommodationGroupEntity';
import { ConfirmationDialogService } from '../../../../global/confirmation-dialog/confirmation-dialog.service';
import { Subscription } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-accommodation-group',
    templateUrl: './accommodation-group.component.html',
    styleUrls: ['./accommodation-group.component.scss'],
})
export class AccommodationGroupComponent implements OnInit, OnDestroy {
    accommodationGroup: ExtendedAccommodationGroupEntity;
    accommodationGroupSubscription: Subscription;
    selectedTab: string = '';
    selectedTabOrder: number = 2;

    constructor(
        readonly apiConnector: ApiConnectorService,
        readonly authenticationService: AuthenticationService,
        readonly router: Router,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly activatedRoute: ActivatedRoute,
    ) {}
    async ngOnInit(): Promise<void> {
        // hole mir den selectedTab aus der URL
        this.setSelectedTab(this.getTab(this.router.url));
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.setSelectedTab(this.getTab(event.url));
        });

        this.accommodationGroupSubscription = this.apiConnector.activeAccommodation$.subscribe((accommodationGroup) => {
            if (accommodationGroup) {
                this.accommodationGroup = cloneDeep(accommodationGroup);
            }
        });
        if (this.selectedTab === 'create') {
            this.accommodationGroup = new ExtendedAccommodationGroupEntity();
            this.accommodationGroup.groupAccommodationIds = [];
            this.accommodationGroup.ownerNumber = this.authenticationService.currentUser.ownerNumber;
            this.apiConnector.setActiveAccommodation(this.accommodationGroup);
        } else {
            this.activatedRoute.params.subscribe((params) => {
                // start das Laden des Objektgruppe
                this.apiConnector.getAccommodationGroupByAccommodationGroupId(+params.accommodationGroupId, this.authenticationService.currentUser);
            });
        }
    }

    ngOnDestroy() {
        this.accommodationGroupSubscription.unsubscribe();
    }

    getTab(url: string): string {
        // bereinige GET Parameter und Hashes
        const urlParts = url.split(/[?#]/)[0].split('/');
        if (urlParts.length > 0) {
            // wenn das vorletzte Element 'image' ist, dann nimm das vorletzte, sonst das letzte
            if (urlParts[urlParts.length - 2] === 'image') {
                return urlParts[urlParts.length - 2];
            }
            return urlParts[urlParts.length - 1];
        }
        return '';
    }

    setSelectedTab(tab: string): void {
        if (['create', 'objects', 'position', 'description', 'images', 'image'].includes(tab)) {
            this.selectedTab = tab;
            // für die Sortierung der Flexbox
            switch (tab) {
                case 'create':
                case 'objects':
                    this.selectedTabOrder = 2;
                    break;
                case 'position':
                    this.selectedTabOrder = 4;
                    break;
                case 'description':
                    this.selectedTabOrder = 6;
                    break;
                case 'images':
                    this.selectedTabOrder = 8;
                    break;
                case 'image':
                    this.selectedTabOrder = 8;
                    break;
            }
        } else {
            this.selectedTab = null;
        }
    }

    openConfirmationDialog(accommodationGroupId) {
        this.confirmationDialogService
            .confirm('atraveo.accommodationbundle.accommodation.group.delete', 'confirm.content.delete.0')
            .then(async (confirmed) => {
                if (confirmed) {
                    await this.apiConnector.deleteAccommodationGroup(accommodationGroupId, this.authenticationService.currentUser.ownerNumber);
                    this.router.navigate(['/accommodation-groups/'], { queryParams: { ownerid: this.authenticationService.currentUser.ownerNumber } });
                }
            })
            .catch(() => undefined);
    }
}
