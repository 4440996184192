import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InvoiceRequestDto } from '../../../../../entities/Invoices/invoiceRequestDto';
import { BackOfficeConnectorService } from '../../../../../services/api-connector/back-office-connector-service';
import { SortEnum } from 'data-structures/lib/es6/dto/rating/owner/find-rating-sort-dto';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';

@Component({
  selector: 'app-commission-invoices',
  templateUrl: './commission-invoices.component.html',
  styleUrls: ['./commission-invoices.component.scss']
})
export class CommissionInvoicesComponent {
    @Input() invoiceRequest: InvoiceRequestDto = new InvoiceRequestDto();
    @Input() commissions = [];
    @Input() commissionCount: number;
    @Output() getCommissions: EventEmitter<any> = new EventEmitter();
    sortDate: SortEnum = null;
    sortPrice: SortEnum = null;

    constructor(readonly authenticationService: AuthenticationService,
                readonly backofficeApiConnector: BackOfficeConnectorService) {}

    async changePage(pageNumber: number) {
        this.invoiceRequest.page = String(pageNumber);
        this.getCommissions.emit();
    }

    async refresh(event, sortType: string) {
        if (sortType === 'date') {
            this.sortDate = event;
        } else {
            this.sortPrice = event;
        }
        this.invoiceRequest.sortBy = sortType;
        this.invoiceRequest.sortTo = sortType === 'date' ? this.sortDate.toLowerCase() : this.sortPrice.toLowerCase();
        this.getCommissions.emit();
    }

    async filterCommissions() {
        this.getCommissions.emit();
    }

    async getPdf(id: string) {
        await this.backofficeApiConnector.getInvoicePdf(id, 'commissioninvoices', this.authenticationService.currentUser.ownerNumber);
    }
}
