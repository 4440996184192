import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DialogService } from '../../../../services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExtendedFindBookingDto } from '../../../../entities/extended-find-booking.dto';
import { AccommodationAjaxSearchComponent } from '../../accommodation-ajax-search/accommodation-ajax-search.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-booking-filter-dialog',
    templateUrl: './booking-filter-dialog.component.html',
    styleUrl: './booking-filter-dialog.component.scss',
})
export class BookingFilterDialogComponent implements OnInit {
    @ViewChild(AccommodationAjaxSearchComponent) ajaxSearch: AccommodationAjaxSearchComponent;
    channelString: string;
    findBookingDto: ExtendedFindBookingDto;
    filterForm: FormGroup;
    accommodations: number[] = [];

    constructor(readonly dialogService: DialogService, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.channelString = data.channelString;
        this.findBookingDto = data.findBookingDto;
        this.accommodations = data.findBookingDto.filter?.accommodationIds || [];

        this.filterForm = new FormGroup({
            channelString: new FormControl('', [Validators.required]),
            take: new FormControl('', [Validators.required, Validators.min(1)]),
            arrivalDate: new FormControl('', [Validators.pattern('^[0-9]{4}-[0-9]{2}-[0-9]{2}$')]),
            departureDate: new FormControl('', [Validators.pattern('^[0-9]{4}-[0-9]{2}-[0-9]{2}$')]),
            bookingNumber: new FormControl(''),
        });
    }

    ngOnInit() {
        this.filterForm.patchValue({
            channelString: this.channelString,
            take: this.findBookingDto.take,
            arrivalDate: this.findBookingDto?.filter?.arrivalDate,
            departureDate: this.findBookingDto?.filter?.departureDate,
            bookingNumber: this.findBookingDto?.filter?.bookingNumber,
        });
    }

    resetData() {
        this.data.reset();
        this.ajaxSearch.reset();
        this.closeDialog();
    }

    loadData() {
        if (this.filterForm.valid) {
            this.data.findBookingDto.filter.arrivalDate = this.filterForm.value.arrivalDate;
            this.data.findBookingDto.filter.departureDate = this.filterForm.value.departureDate;
            this.data.findBookingDto.filter.bookingNumber = this.filterForm.value.bookingNumber;
            if (this.accommodations.length > 0) {
                this.data.findBookingDto.filter.accommodationIds = this.accommodations;
            } else {
                delete this.data.findBookingDto.filter.accommodationIds;
            }
            this.data.findBookingDto.take = this.filterForm.value.take;
            this.data.findBookingDto.skip = 0;
            this.data.channelString = this.filterForm.value.channelString;
            this.data.load(this.data);
            this.closeDialog();
        }
    }

    removeAccommodation(accommodationId) {
        this.accommodations = this.accommodations.filter((id) => id !== accommodationId);
    }

    addAccommodation($event: any) {
        const usedNumber = Number($event);
        if (!isNaN(usedNumber) && !this.accommodations.includes(usedNumber)) {
            this.accommodations.push(usedNumber);
        }
    }
    closeDialog() {
        this.dialogService.closeDialog();
    }
}
