<div class="py-3 clearfix">
    <button (click)="toggleMenu()" class="btn btn-sm btn-link d-inline d-sm-none mr-3 text-primary border-primary" id="menu-toggle"><i class="fa fa-bars"></i> Menü</button>
    <div class="site-logo">
        <a routerLink="/" ><img src="assets/img/Logo/logo_atraveo_by_edomizil.png" class="brand-logo" alt="company logo" title="Start"></a>
    </div>
    <div class="site-logo-mobile ">
        <a routerLink="/" ><img src="assets/img/Logo/logo_atraveo_by_edomizil.png" class="brand-logo" alt="company logo" title="Start"></a>
    </div>
    <app-onboarding layout="header" *ngIf="!authenticationService.currentUser?.isImported()"></app-onboarding>
</div>
