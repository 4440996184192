<div class="modal-content">
    <form [formGroup]="pauseForm" (ngSubmit)="submit()">
        <div class="modal-header">
            <button class="close" (click)="closeDialog()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ul>
                <strong><p>{{ 'atraveo.accommodationbundle.accommodation.pause.dialog.title' | translate }}</p></strong>
                <li class="custom-radio">
                    <label for="pauseOption1" class="mb-3"><span class="label">{{ 'atraveo.accommodationbundle.accommodation.pause.dialog.option1' | translate }}</span>
                        <input type="radio" name="pauseReason" id="pauseOption1" formControlName="pauseReason" [value]="pausedInfoReasonEnum.TemporarilyNotRenting">
                        <span class="radio-btn"><i class="check-icon"></i></span>
                    </label>
                </li>
                <li class="custom-radio">
                    <label for="pauseOption2" class="mb-3"><span class="label">{{ 'atraveo.accommodationbundle.accommodation.pause.dialog.option2' | translate }}</span>
                        <input type="radio" name="pauseReason" id="pauseOption2" formControlName="pauseReason" [value]="pausedInfoReasonEnum.AccommodationWillBeSold">
                        <span class="radio-btn"><i class="check-icon"></i></span>
                    </label>
                </li>
                <li class="custom-radio">
                    <label for="pauseOption3" class="mb-3"><span class="label">{{ 'atraveo.accommodationbundle.accommodation.pause.dialog.option3' | translate }}</span>
                        <input type="radio" name="pauseReason" id="pauseOption3" formControlName="pauseReason" [value]="pausedInfoReasonEnum.TechnicalProblems">
                        <span class="radio-btn"><i class="check-icon"></i></span>
                    </label>
                </li>
                <li class="custom-radio">
                    <label for="pauseOption4" class="mb-3"><span class="label">{{ 'atraveo.accommodationbundle.accommodation.pause.dialog.option4' | translate }}</span>
                        <input type="radio" name="pauseReason" id="pauseOption4" formControlName="pauseReason" [value]="pausedInfoReasonEnum.EndPartnership">
                        <span class="radio-btn"><i class="check-icon"></i></span>
                    </label>
                </li>
                <li class="custom-radio">
                    <label for="pauseOption5" class="mb-3"><span class="label">{{ 'atraveo.accommodationbundle.accommodation.pause.dialog.option5' | translate }}: </span>
                        <input type="radio" name="pauseReason" id="pauseOption5" formControlName="pauseReason" [value]="pausedInfoReasonEnum.CustomText">
                        <span class="radio-btn"><i class="check-icon"></i></span>
                    </label>
                </li>
            </ul>
            <textarea type="text" placeholder="{{ 'atraveo.accommodationbundle.accommodation.pause.dialog.placeholder.' + placeholderKey | translate }}" class="form-control" [class.hide]="!placeholderKey.length" formControlName="customText"></textarea>
            <app-validation-error-messages [control]="pauseForm.get('customText')"></app-validation-error-messages>
        </div>
        <div class="modal-footer">
            <button class="btn btn-modal-footer btn-danger" type="button" (click)="closeDialog()">
                {{ 'button.cancel' | translate }}
            </button>
            <button class="btn btn-modal-footer btn-success" type="submit">
                {{ 'button.save' | translate }}
            </button>
        </div>
    </form>
</div>