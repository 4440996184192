<p>{{ 'text.contact.person.info.text' | translate }}</p>
<form [formGroup]="searchForm">
    <div class="form-group">
        <input type="text" class="form-control" id="search" name="search" placeholder="{{ 'button.search' | translate }}" formControlName="search">
    </div>
</form>
<table class="table responsive-table">
    <thead>
    <tr>
        <th>
            {{ 'profile.contact.name' | translate  }}
        </th>
        <th>
            {{ 'profile.contact.address' | translate  }}
        </th>
        <th>
            {{ 'profile.contact.phones' | translate  }}
        </th>
        <th>
            {{ 'profile.contact.emails' | translate  }}
        </th>
        <th>{{ 'profile.contact.languages' | translate }}</th>
        <th><!-- Icons --></th>
    </tr>
    </thead>
    <tbody>
        <tr *ngFor="let contact of filteredContactList"
            (click)="addOrRemoveDeleteContact(contact.contactId)" [class.selected]="this.toDeleteContacts.includes(contact.contactId) && this.deleteMultipleInProgress"
        >
            <td class="pr-3" [attr.data-label]="'profile.contact.name' | translate">{{ 'text.salutation.' + contact.salutation | translate }} {{ contact.forename }} {{ contact.surname }}</td>
            <td [attr.data-label]="'profile.contact.address' | translate"
                [class]="contact.address ? 'pr-3' : 'd-none d-lg-table-cell'">{{ contact.address }}</td>
            <td [attr.data-label]="'profile.contact.phones' | translate">
                <ul class="list-group list-group-flush">
                    <li *ngFor="let phone of contact.phoneNumbers " class="list-group-item bg-transparent text-break">
                        {{ phone }}
                    </li>
                </ul>
            <td [attr.data-label]="'profile.contact.emails' | translate"
                [class]="contact.email.length ? '' : 'd-none d-lg-table-cell'">
                <ul class="list-group list-group-flush">
                    <li *ngFor="let email of contact.email " class="list-group-item bg-transparent text-break">
                        {{ email }}
                    </li>
                </ul>
            </td>
            <td [attr.data-label]="'profile.contact.languages' | translate"
                [class]="contact.spokenLanguages.length ? '' : 'd-none d-lg-table-cell'">
                <span *ngFor="let language of contact.spokenLanguages; let last = last">
                    {{ 'intl.language.name.' + language | translate }}<span *ngIf="!last">, </span>
                </span>
            </td>
            <td class="small text-nowrap text-right pr-3 pt-2">
                <button class="btn p-0" (click)="editContact(contact.contactId);">
                    <i class="fa fa-pen fa-lg"></i>
                </button>
                <button *ngIf="contact.deletable"
                        type="button"
                        class="btn btn-link p-0"
                        (click)="openConfirmationDialog(contact.contactId);"
                >
                    <i class="fa fa-trash-alt fa-lg ml-2"></i>
                </button>
                <button
                    *ngIf="!contact.deletable"
                    type="button"
                    class="btn btn-link p-0 mat-tooltip text-muted"
                    #tooltip="matTooltip"
                    appShowTooltipOnClick
                    matTooltip="{{ contact.deletableReason | translate }}"
                >
                    <i class="fa fa-trash-alt fa-lg ml-2"></i>
                </button>
            </td>
        </tr>
    </tbody>
</table>

<div class="form-group row">
    <div class="col-12 col-lg-6 mb-2">
        <button class="btn btn-secondary" (click)="addNewContact()">
            <i class="fa fa-plus"></i>
            {{ 'text.contact.person.add' | translate }}
        </button>
    </div>
    <div class="col-12 col-lg-6">
        <div class="delete-multiple text-right">
            <span *ngIf="deleteMultipleInProgress" class="mr-3">
                {{ 'atraveo.accommodationbundle.delete.multipleTitle' | translate }}
            </span>
            <button *ngIf="deleteMultipleInProgress" class="btn btn-primary mb-2" (click)="deleteMultipleContacts()">
                {{ 'atraveo.accommodationbundle.delete.confirmDeleteMultipleWithNumber' | translate: {number: toDeleteContacts.length} }}
            </button>
            <button *ngIf="deleteMultipleInProgress" class="btn btn-primary ml-3 mb-2" (click)="this.abortDeleteMultiple()">
                {{ 'button.cancel' | translate }}
            </button>
            <button *ngIf="!deleteMultipleInProgress" class="btn btn-primary" (click)="this.deleteMultipleInProgress = true">
                {{ 'atraveo.accommodationbundle.delete.deleteMultiple' | translate }}
            </button>
        </div>
    </div>
</div>
