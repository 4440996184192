import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { NotificationService } from '../../services/notification/notification.service';
import { AuthenticationConnectorService } from '../../services/api-connector/authentication-connector-service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import md5 from 'md5';
import { UrlLangDetectorService } from '../../services/url-lang-detector/url-lang-detector.service';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    loading = false;
    loginError: string = null;
    infoTextPresent: boolean;
    currentYear: number;
    translationServiceSubscription: Subscription;
    currentUserSubscription: Subscription;
    loginForm: FormGroup;
    resetPasswordForm: FormGroup;
    passwordSendActive: boolean = false;
    showResetPassword: boolean = false;
    passwordType = 'password';

    constructor(
        readonly route: ActivatedRoute,
        readonly router: Router,
        readonly authenticationService: AuthenticationService,
        readonly notificationService: NotificationService,
        readonly authConnector: AuthenticationConnectorService,
        readonly translate: TranslateService,
        readonly titleService: Title,
        readonly translateService: TranslateService,
        readonly urlLangDetectorService: UrlLangDetectorService,
        readonly location: Location,
    ) {
        this.loginForm = new FormGroup({
            userName: new FormControl('', [Validators.required, this.emailOrNumberValidator()]),
            password: new FormControl('', [Validators.required]),
            role: new FormControl(''),
            loginAsUser: new FormControl(''),
            ownerType: new FormControl(''),
        });

        this.resetPasswordForm = new FormGroup({
            userName: new FormControl('', [Validators.required, this.emailOrNumberValidator()]),
        });
    }

    async ngOnInit() {
        if (this.authenticationService.isLoggedIn()) {
            this.router.navigate(['/dashboard']);
        }
        await this.updateBox();
        this.currentYear = new Date().getFullYear();

        this.translationServiceSubscription = this.translateService.onLangChange.subscribe(async () => {
            await this.updateBox();
        });

        if (this.router.url.includes('special-login')) {
            this.loginForm.get('role').setValidators([Validators.required]);
            this.loginForm.get('loginAsUser').setValidators([Validators.required]);
            this.loginForm.get('ownerType').setValidators([Validators.required]);
            this.loginForm.get('userName').setValidators([Validators.required]);
            this.loginForm.get('ownerType').setValue('backoffice');
            this.loginForm.get('role').setValue('owner');

            this.loginForm.get('role').updateValueAndValidity();
            this.loginForm.get('loginAsUser').updateValueAndValidity();
            this.loginForm.get('ownerType').updateValueAndValidity();
            this.loginForm.get('userName').updateValueAndValidity();
        }
    }

    ngOnDestroy() {
        this.translationServiceSubscription?.unsubscribe();
        this.currentUserSubscription?.unsubscribe();
    }

    emailOrNumberValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            // Prüfen, ob der Wert eine gültige E-Mail-Adresse ist
            const emailValidator = Validators.email(control);
            const isEmailValid = !emailValidator;

            // Prüfen, ob der Wert eine Zahl ist (mit regulärem Ausdruck)
            const numberValidator = Validators.pattern(/^[0-9]+$/)(control);
            const isNumberValid = !numberValidator;

            // Wenn weder eine gültige E-Mail-Adresse noch eine Zahl, dann ungültig
            if (!isEmailValid && !isNumberValid) {
                return { emailOrNumber: { value: control.value } };
            }

            // Gültig
            return null;
        };
    }

    async login() {
        if (this.loginForm.valid) {
            const userName = this.loginForm.get('userName').value;
            const password = this.loginForm.get('password').value;
            const role = this.loginForm.get('role').value;
            const loginAsUser = this.loginForm.get('loginAsUser').value;
            const ownerType = this.loginForm.get('ownerType').value;

            this.loading = true;
            this.loginError = null;

            try {
                await this.urlLangDetectorService.checkUrlLangAndRemove();
                await this.authenticationService.login(userName, password, role, loginAsUser, ownerType);

                this.currentUserSubscription = this.authenticationService.currentUserSubject.subscribe(async (value) => {
                    if (value) {
                        this.loading = false;
                        (window as any).dataLayer.push({
                            event: 'userID',
                            userID: md5(value.ownerNumber),
                        });
                    } else {
                        this.loginError = 'text.login.error.timeout';
                        this.loading = false;
                    }
                });
            } catch (error) {
                if (error?.indexOf('AUTH3') !== -1 || error?.indexOf('AUTH4') !== -1) {
                    this.loginError = 'text.login.error.425';
                } else {
                    this.loginError = 'text.login.error.timeout';
                }
                this.loading = false;
            }
        } else {
            this.loginForm.markAllAsTouched();
        }
    }

    async updateBox() {
        const translation = await this.translateService.get('text.login.box').toPromise();
        this.infoTextPresent = !!translation && translation !== 'text.login.box';
    }
    togglePasswordVisibility() {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    }

    toggleResetPasswordBox() {
        this.loading = !this.loading;
        this.showResetPassword = !this.showResetPassword;
    }

    async newPasswordRequest() {
        if (this.resetPasswordForm.valid) {
            const userName = this.resetPasswordForm.get('userName');

            this.passwordSendActive = true;

            await this.authConnector
                .newPasswordRequest(userName.value)
                .then((success) => {
                    this.passwordSendActive = false;
                    if (success) {
                        this.toggleResetPasswordBox();
                    }
                })
                .catch(() => {
                    this.passwordSendActive = false;
                });
        } else {
            this.resetPasswordForm.markAllAsTouched();
        }
    }

    navigateToCreateAccount() {
        this.router.navigate(['/create-account'], {
            queryParamsHandling: 'preserve',
        });
    }
}
