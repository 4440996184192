import { Component, Inject } from '@angular/core';
import { DialogService } from '../../../../services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExtendedBankAccountEntity } from '../../../../entities/extended-bank-account-entity';

@Component({
    selector: 'app-bankdata-dialog',
    templateUrl: './bank-account-dialog.component.html',
    styleUrl: './bank-account-dialog.component.scss',
})
export class BankAccountDialogComponent {
    bankAccountId: string;
    bankAccount: ExtendedBankAccountEntity;
    isDefault: boolean;

    constructor(readonly dialogService: DialogService, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.bankAccountId = data.bankAccountId;
        this.bankAccount = data.bankAccount;
        this.isDefault = data.isDefault;
    }

    saveBankAccount($event: ExtendedBankAccountEntity) {
        this.data.saveBankAccount($event, this.bankAccountId);
        this.dialogService.closeDialog();
    }

    closeDialog() {
        this.dialogService.closeDialog();
    }
}
