<header>
    <h1>{{ "poolConfig.title" | translate }}</h1>
    <p>{{ "poolConfig.info" | translate }}</p>
</header>


<div class="mt-5" *ngIf="dataReady">
    <h2>{{ "poolConfig.owner.title" | translate }}</h2>

    <form [formGroup]="poolForm" (ngSubmit)="onSubmitOwner()" id="poolConfigForm" class="mt-3">
        <p>{{ "poolConfig.owner.poolSelectLabel" | translate }}</p>
        <ul class="list-group" formArrayName="pools">
            <li class="list-group-item" *ngFor="let pool of availablePools; let i = index">
                <span class="form-check">
                    <input
                        type="checkbox"
                        class="form-check-input"
                        [formControlName]="i"
                        [id]="'pool' + pool.poolId"
                        value="{{ pool.poolId }}">
                    <label class="form-check-label ml-2" [for]="'pool' + pool.poolId">
                        {{ pool.name }}
                    </label>
                </span>
            </li>
        </ul>
        <p class="mt-3">
            <button type="submit" class="btn btn-success">
                <i class="fas fa-cloud-upload-alt mr-2"></i>
                {{ "poolConfig.save" | translate }}
            </button>
        </p>
    </form>
</div>


<div class="mt-5" *ngIf="dataReady">
    <h2>{{ "poolConfig.accommodation.title" | translate }}</h2>
    <form [formGroup]="accommodationPoolForm" (ngSubmit)="onSubmitAccommodations()" class="grid-item">
        <div class="row">
            <div class="col-12 col-lg-6">
                <label for="accommodationSelections">{{ "poolConfig.accommodation.accommodationSelectLabel" | translate }}</label><br>
                <app-accommodation-change-select id="accommodationSelections" [currentAccommodationIdList]="usedAccommodationIds" [control]="accommodationNumberSelectControl"></app-accommodation-change-select>
                <app-validation-error-messages [control]="accommodationPoolForm.get('accommodationNumber')"></app-validation-error-messages>
            </div>
            <div class="col-12 col-lg-6">
                <label for="accommodationPoolSelections">{{ "poolConfig.owner.poolSelectLabel" | translate }}</label>
                <select id="accommodationPoolSelections"
                        class="form-control"
                        formControlName="poolNumber">
                    <option disabled value="null">{{ 'poolConfig.pool.select' | translate }}</option>
                    <ng-container *ngFor="let pool of activePoolSelection">
                        <option [value]="pool.poolId">{{ pool.name }}</option>
                    </ng-container>
                </select>
                <app-validation-error-messages [control]="accommodationPoolForm.get('poolNumber')"></app-validation-error-messages>
            </div>
        </div>
        <p class="mt-3">
            <button id="accommodationConfigSave" type="submit" class="btn btn-success">
                <i class="fas fa-cloud-upload-alt mr-2"></i>
                {{ "poolConfig.save" | translate }}
            </button>
        </p>
    </form>
</div>

<div class="mt-5">
    <h2>{{ 'poolConfig.accommodation.assignedAccommodations' | translate }}</h2>
    <div class="table-responsive">
        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>{{ 'poolConfig.assigned.accommodation' | translate }}</th>
                    <th>{{ 'poolConfig.assigned.pool' | translate }}</th>
                    <th></th>
                </tr>
            </thead>
            <tr *ngFor="let accommodationPool of usedAccommodationAndPoolNames;">
                <td>{{ accommodationPool.accommodation}} {{ accommodationPool.name}}</td>
                <td>{{ accommodationPool.pool}}</td>
                <td class="text-right">
                    <a class="btn btn-link" (click)="openConfirmationDialog(accommodationPool.accommodation)" title="löschen">
                        <i class="fas fa-trash-alt"></i>
                    </a>
                </td>
            </tr>
        </table>
    </div>
</div>
