<div *ngIf="accommodation">
    <div class="row">
        <div *ngIf="authenticationService.currentUser && (accommodationCount !== undefined || true)" class="col">
            <h1 *ngIf="accommodationCount !== undefined || accommodationCount !== null">{{ 'atraveo.accommodationbundle.detail.title' | translate: { accommodationCount: accommodationCount } }}</h1>
            <a routerLink="/accommodations" routerLinkActive="active"> <i class="fa fa-chevron-left align-middle"></i> {{ 'atraveo.accommodationbundle.overview.back' | translate }} </a>
        </div>
        <div class="d-flex justify-content-md-end search-box mt-3 mr-3 pl-3">
            <app-accommodation-change-select *ngIf="accommodationCount && accommodationCount >= 2" [currentAccommodationIdList]="[accommodation.accommodationId]" [currentTab]="selectedTab"></app-accommodation-change-select>
        </div>
    </div>
    <hr />
    <div class="header-grid">
        <div class="header-grid-1">
            <app-thumbnail [accommodation]="accommodation"></app-thumbnail>
            <app-state *ngIf="authenticationService.currentUser" [accommodation]="accommodation"></app-state>
            <div class="object-title mt-1">
                <h2 class="d-inline mr-2" *ngIf="accommodation.accommodationId">
                    {{ accommodation.accommodationId }}
                    <span *ngIf="accommodation?.main?.name"> ({{ accommodation?.main?.name }}) </span>
                </h2>
                <h2 class="edomizil-id" *ngIf="accommodation.getEdomizilId()">{{ 'atraveo.accommodationbundle.description.eDomizilId.label' | translate }}: {{ accommodation.getEdomizilId() }}</h2>
                <p *ngIf="accommodation?.main?.contingent" class="contingent-container">
                    {{ 'atraveo.accommodationbundle.overview.description.kontingent.0' | translate: { kontingent: accommodation.main.contingent } }}
                    <a class="btn btn-link mr-2" data-toggle="popover" attr.data-content="{{ 'atraveo.accommodationbundle.overview.description.kontingent.info' | translate }}">
                        <i class="fas fa-info-circle"></i>
                    </a>
                </p>
            </div>
        </div>
        <div class="header-grid-2">
            <a
                *ngIf="authenticationService.currentUser?.isAdmin() && this.accommodation && !this.accommodation.state?.imported"
                routerLink="/change-owner/{{ accommodation.accommodationId }}"
                routerLinkActive="active"
                class="service btn btn-link d-none d-sm-block"
            >
                <i class="fas fa-exchange-alt"></i> Objekt auf anderen Vermieter verschieben
            </a>
            <a
                *ngIf="authenticationService.currentUser?.isEmployee() && !this.accommodation.state?.deleted"
                class="service btn btn-link d-none d-sm-block"
                (click)="openConfirmationDialog($event)"
            >
                <i class="fas fa-trash-alt"></i> {{ 'atraveo.accommodationbundle.accommodation.delete.0' | translate }}
            </a>
            <span *ngIf="authenticationService.currentUser && (!authenticationService.currentUser?.hasClient() || authenticationService.currentUser.isEmployee())">
                <a
                    class="btn btn-link service d-none d-sm-block"
                    *ngIf="authenticationService.currentUser?.isEmployee() || !authenticationService.currentUser?.hasClient()"
                    (click)="toggleDeactivatedByOwnerService()"
                >
                    <span *ngIf="!accommodation.state?.deactivatedByOwnerService">
                        <i class="fa fa-pause mr-2"></i>
                        {{ 'Aus der Bewerbung nehmen'  }}
                    </span>
                    <span *ngIf="accommodation.state?.deactivatedByOwnerService">
                        <i class="fa fa-play mr-2"></i>
                        {{ 'In die Bewerbung nehmen' }}
                    </span>
                </a>
            </span>
            <div class="flex-container">
                <button type="button" class="my-2 btn btn-primary" *ngIf="authenticationService.currentUser" (click)="openCopyObjectData()">
                    <i class="fas fa-copy"></i>
                    {{ 'atraveo.accommodationbundle.accommodation.clone.partial.0' | translate }}
                </button>
            </div>
        </div>
        <div class="header-grid-3">
            <app-accommodation-progress-bar *ngIf="accommodation?.main?.checkResultProgressBarTotal" [accommodation]="accommodation"></app-accommodation-progress-bar>
        </div>
        <div class="header-grid-4">
            <div
                class="accommodation-btn-group"
                *ngIf="showPublicationButton"
            >
                <div class="apply" >
                    <app-publication-request-btn [accommodation]="accommodation" [owner]="authenticationService.currentUser"></app-publication-request-btn>
                </div>

            </div>
        </div>
    </div>
    <div>
        <div class="row p-1">
            <div class="col-md text-right" *ngIf="accommodation.accommodationId">
                <a *ngIf="authenticationService.currentUser?.isEmployee() && accommodation.state?.deleted" class="service" (click)="this.restoreDeletedAccommodation()">
                    <i class="fa fa-undo fa-2x"></i>
                </a>
                <a
                    class="btn btn-link service"
                    *ngIf="this.accommodationCanBeQualified && !accommodation.state?.active && accommodation.state?.valid && accommodation.state?.publishingRequested"
                    (click)="openQualifyAccommodationConfirmationDialog()"
                ><i class="fas fa-magic fa-2x"></i
                ></a>
                <span *ngIf="authenticationService.currentUser && !authenticationService.currentUser.hasClient()">
                    <a class="btn btn-link mr-2" (click)="openConfirmationDialog($event)">
                        <i class="fa fa-trash-alt mr-2"></i>
                        {{ 'atraveo.accommodationbundle.accommodation.delete.short' | translate }}
                    </a>
                </span>
            </div>
        </div>

        <app-errors [accommodation]="accommodation" [isAccommodationList]="false"></app-errors>
        <br /><br />

        <ul class="nav nav-tabs nav-justified desktop-tab-navigation" id="accommodation-tab">
            <li class="nav-item">
                <a *ngIf="this.accommodation.accommodationId; else positionTitle"
                    class="nav-link accommodation-link"
                    [routerLinkActive]="['active']"
                    [routerLink]="['/accommodation', this.accommodation.accommodationId, 'position']"
                    [routerLinkActiveOptions]="{ exact: true }"
                    aria-selected="true"
                    [attr.itemid]="0"
                >
                    <p>{{ 'atraveo.accommodationbundle.navigation.position.title' | translate }}</p>
                </a>
                <ng-template #positionTitle>
                    <p class="nav-link active">{{ 'atraveo.accommodationbundle.navigation.position.title' | translate }}</p>
                </ng-template>
                <a *ngIf="this.accommodation.accommodationId"
                    class="nav-link position-link hide"
                    [routerLinkActive]="['active']"
                    [routerLink]="['/accommodation', this.accommodation.accommodationId, 'position']"
                    aria-selected="true"
                    [attr.itemid]="0"
                >
                    <p>{{ 'atraveo.accommodationbundle.navigation.position.title' | translate }}</p>
                </a>
            </li>
            <li class="nav-item">
                <a *ngIf="this.accommodation.accommodationId; else mainTitle"
                    class="nav-link"
                    [routerLinkActive]="['active']"
                    [routerLink]="['/accommodation', this.accommodation.accommodationId, 'main']"
                    aria-selected="false"
                    [attr.itemid]="1"
                >
                    <p>{{ 'atraveo.accommodationbundle.navigation.main.title' | translate }}</p>
                </a>
                <ng-template #mainTitle>
                    <p class="nav-link disabled">{{ 'atraveo.accommodationbundle.navigation.main.title' | translate }}</p>
                </ng-template>

            </li>
            <li class="nav-item" *ngIf="accommodation.modelIsAllowed(authenticationService)">
                <a *ngIf="this.accommodation.accommodationId; else modelTitle"
                   class="nav-link"
                   [routerLinkActive]="['active']"
                   [routerLink]="['/accommodation', this.accommodation.accommodationId, 'model']"
                   aria-selected="false"
                   [attr.itemid]="1"
                >
                    <p>{{ 'atraveo.accommodationbundle.navigation.model.title' | translate }}</p>
                </a>
                <ng-template #modelTitle>
                    <p class="nav-link disabled">{{ 'atraveo.accommodationbundle.navigation.model.title' | translate }}</p>
                </ng-template>

            </li>
            <li class="nav-item">
                <a *ngIf="this.accommodation.accommodationId; else featureTitle"
                   class="nav-link"
                    [routerLinkActive]="['active']"
                    [routerLink]="['/accommodation', this.accommodation.accommodationId, 'features']"
                    aria-selected="false"
                    [attr.itemid]="2"
                >
                    <p>{{ 'atraveo.accommodationbundle.navigation.feature.title' | translate }}</p>
                </a>
                <ng-template #featureTitle>
                    <p class="nav-link disabled">{{ 'atraveo.accommodationbundle.navigation.feature.title' | translate }}</p>
                </ng-template>
            </li>
            <li class="nav-item">
                <a *ngIf="this.accommodation.accommodationId; else descriptionTitle"
                    class="nav-link"
                    [routerLinkActive]="['active']"
                    [routerLink]="['/accommodation', this.accommodation.accommodationId, 'description']"
                    aria-selected="false"
                    [attr.itemid]="3"
                >
                    <p>{{ 'atraveo.accommodationbundle.navigation.description.title' | translate }}</p>
                </a>
                <ng-template #descriptionTitle>
                    <p class="nav-link disabled">{{ 'atraveo.accommodationbundle.navigation.description.title' | translate }}</p>
                </ng-template>
            </li>
            <li class="nav-item">
                <a *ngIf="this.accommodation.accommodationId; else imageTitle"
                    class="nav-link images-link"
                    [routerLink]="['/accommodation', this.accommodation.accommodationId, 'images']"
                    [ngClass]="selectedTab === 'image' || selectedTab === 'images' ? 'active' : ''"
                    aria-selected="false"
                    [attr.itemid]="4"
                >
                    <p>{{ 'atraveo.accommodationbundle.navigation.media.title' | translate }}</p>
                </a>

                <ng-template #imageTitle>
                    <p class="nav-link disabled">{{ 'atraveo.accommodationbundle.navigation.media.title' | translate }}</p>
                </ng-template>

                <a
                    class="nav-link image-deep-link hide"
                    [routerLinkActive]="['active']"
                    [routerLink]="['/accommodation', this.accommodation.accommodationId, 'image', selectedImageOrder]"
                    aria-selected="false"
                    [attr.itemid]="4"
                >
                    <p>{{ 'atraveo.accommodationbundle.navigation.media.title' | translate }}</p>
                </a>
            </li>
            <li class="nav-item">
                <a *ngIf="this.accommodation.accommodationId; else priceTitle"
                    class="nav-link"
                    [routerLinkActive]="['active']"
                    [routerLink]="['/accommodation', this.accommodation.accommodationId, 'prices']"
                    aria-selected="false"
                    [attr.itemid]="5"
                >
                    <p>{{ 'atraveo.accommodationbundle.navigation.price.title' | translate }}</p>
                </a>
                <ng-template #priceTitle>
                    <p class="nav-link disabled">{{ 'atraveo.accommodationbundle.navigation.price.title' | translate }}</p>
                </ng-template>
            </li>
            <li class="nav-item">
                <a *ngIf="this.accommodation.accommodationId; else calendarTitle"
                    class="nav-link last"
                    [routerLinkActive]="['active']"
                    [routerLink]="['/accommodation', this.accommodation.accommodationId, 'calendar']"
                    aria-selected="false"
                    [attr.itemid]="6"
                >
                    <p>{{ 'atraveo.accommodationbundle.navigation.calendar.title' | translate }}</p>
                </a>
                <ng-template #calendarTitle>
                    <p class="nav-link disabled">{{ 'atraveo.accommodationbundle.navigation.calendar.title' | translate }}</p>
                </ng-template>
            </li>
        </ul>
    </div>
</div>
<ng-template #copyModal>
<div *ngIf="accommodation"   tabindex="-1" role="dialog" aria-labelledby="copy-modal-label" aria-hidden="true">
    <div  role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="copy-modal-label">{{ 'atraveo.accommodationbundle.accommodation.clone.partial.0' | translate }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dialogService.closeDialog()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <p class="alert alert-light ">
                                {{ 'atraveo.accommodationbundle.accommodation.clone.partial.source.intro' | translate }}
                            </p>
                            <p>
                                <strong class="h2 ">{{ 'atraveo.accommodationbundle.accommodation.clone.partial.source.0' | translate }}&nbsp;</strong>
                                <strong>{{ accommodation.accommodationId }}</strong>
                                <span *ngIf="accommodation.main?.name"> ({{ accommodation.main.name }}) </span>
                            </p>
                            <p>{{ 'atraveo.accommodationbundle.accommodation.clone.partial.source.options' | translate }}</p>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="priceRules" id="priceRules" name="priceRules" [(ngModel)]="selectedComponents.priceRules" />
                                <label class="form-check-label" for="priceRules">
                                    {{ 'atraveo.accommodationbundle.accommodation.clone.partial.price' | translate }}
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="additionalCosts" id="additionalCosts" name="additionalCosts" [(ngModel)]="selectedComponents.additionalCosts" />
                                <label class="form-check-label" for="additionalCosts">
                                    {{ 'atraveo.accommodationbundle.accommodation.clone.partial.additionalcost' | translate }}
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="offers" id="offers" name="offers" [(ngModel)]="selectedComponents.offers" />
                                <label class="form-check-label" for="offers">
                                    {{ 'atraveo.accommodationbundle.accommodation.clone.partial.offer' | translate }}
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="features" id="features-copy" name="features" [(ngModel)]="selectedComponents.features" />
                                <label class="form-check-label" for="features-copy">
                                    {{ 'atraveo.accommodationbundle.accommodation.clone.partial.featureset' | translate }}
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="position" id="position-copy" name="position" [(ngModel)]="selectedComponents.position" />
                                <label class="form-check-label" for="position-copy">
                                    {{ 'atraveo.accommodationbundle.accommodation.clone.partial.position' | translate }}
                                </label>
                            </div>
                        </div>

                        <div class="col-12 mt-3">
                            <p>{{ 'atraveo.accommodationbundle.accommodation.clone.partial.target.intro' | translate }}</p>
                            <strong>{{ 'atraveo.accommodationbundle.accommodation.clone.partial.target.0' | translate }}</strong>
                            <app-accommodation-select
                                [(ngModel)]="copyAccommodationIds"
                                classKey="form-control custom-select selectpicker"
                                idKey="copy-accommodations"
                                [noneSelectedDisplayAll]="false"
                                [includeAllObjects]="false"
                                [showAllIds]="true"
                            >
                            </app-accommodation-select>
                            <p class="mt-3 alert alert-warning">{{ 'atraveo.accommodationbundle.accommodation.clone.partial.target.info' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="dialogService.closeDialog()">
                    {{ 'button.close' | translate }}
                </button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="copyAccommodationData()">
                    {{ 'atraveo.accommodationbundle.accommodation.clone.partial.now' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
</ng-template>
