<p class="mt-3">{{ 'atraveo.accommodationbundle.price.infotext' | translate }}</p>

<div *ngIf="authenticationService.currentUser" class="accordion" id="accordionPrices">
    <div class="card mb-2">
        <div class="card-header" id="rentprice">
            <h2 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePrice"
                        aria-expanded="true" aria-controls="collapseOne">
                    <i class="fa fa-chevron-down"></i> {{ 'atraveo.accommodationbundle.price.rentprice' | translate }}
                </button>
            </h2>
        </div>
        <div id="collapsePrice"
             class="collapse show"
             aria-labelledby="rentprice"
             data-parent="#accordionPrices">
            <div class="card-body">
                <div>
                    <button class="btn btn-success advanced-settings-button"
                            data-title="{{ 'atraveo.accommodationbundle.price.set-arrival-days.0' | translate }}"
                            (click)="openAdvancedSettings()"
                    >
                        {{ 'atraveo.accommodationbundle.price.moreConfiguration' | translate }}
                    </button>
                </div>
                <app-daily-price *ngIf="useDailyPrices" (selectById)="setCurrentDailyPriceId($event)" (deleteByIds)="deleteDailyprices($event)" (saveDailyPrice)="saveDailyPrice($event)"></app-daily-price>
                <ng-template [ngIf]="!useDailyPrices">
                    <ng-container *ngFor="let type of activePriceTypes">
                        <app-price-table [priceType]="type"></app-price-table>
                    </ng-container>
                </ng-template>
                <div>
                    <button class="btn btn-secondary create-price-button"
                            (click)="createPriceRule()">
                        + {{ 'text.call.price' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-2">
        <div class="card-header" id="headingOne">
            <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne"
                        aria-expanded="false" aria-controls="collapseOne">
                    <i class="fa fa-chevron-down"></i> {{ 'atraveo.accommodationbundle.additionalcost.title' | translate }}
                </button>
            </h2>
        </div>
        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionPrices">
            <div class="card-body">
                <app-additional-cost [accommodationOrTemplateInput]="accommodation" (onSave)="save($event)"></app-additional-cost>
            </div>
        </div>
    </div>

    <div class="card mb-2">
        <div class="card-header" id="specialOffers">
            <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSpecialOffers" aria-expanded="false" aria-controls="collapseThree">
                    <i class="fa fa-chevron-down"></i> {{ 'atraveo.accommodationbundle.price.offer.title' | translate }}
                </button>
            </h2>
        </div>
        <div id="collapseSpecialOffers" class="collapse" aria-labelledby="specialOffers" data-parent="#accordionPrices">
            <div class="card-body">
                <app-special-offer (onSave)="save($event)"></app-special-offer>
            </div>
        </div>
    </div>
</div>
