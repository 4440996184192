import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApiConnectorService } from '../../../services/api-connector/api-connector.service';
import { GeoCountryEntity } from 'data-structures/lib/es6/entity/geography/geo-country.entity';
import { Subscription } from 'rxjs';

export interface LanguageTextAndCountry {
    country: string;
    text: string;
    disabled?: boolean;
}

@Component({
    selector: 'app-country-select',
    templateUrl: './country-select.component.html',
    styleUrls: ['./country-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CountrySelectComponent),
            multi: true,
        },
    ],
})
export class CountrySelectComponent implements OnInit, ControlValueAccessor, OnDestroy {
    preferredCountries: string[] = ['DE', 'DK', 'FR', 'GR', 'IE', 'IT', 'HR', 'NL', 'NO', 'PL', 'SE', 'CH', 'ES', 'US', 'GB', 'AT'];
    allCountries: LanguageTextAndCountry[] = [];
    @Input() idKey: string = '';
    @Input() classKey: string = '';
    @Input() isOptional: boolean = false;
    @Input() noneSelectedDisplayAll = false; // Wenn nichts ausgewählt ist, soll "alle Länder" angezeigt werden
    @Input() isUserProfile: boolean;
    @Input() readOnly: boolean = false;
    @Input() form: FormGroup;
    @Input() formControlName: string;
    @Input() isAccommodationOrGroupActive: boolean = false;
    @Output() setPhonePrefix: EventEmitter<any> = new EventEmitter();
    selectedCountry: string;
    countriesRaw = [];
    translateServiceSubscription: Subscription;

    constructor(readonly translateService: TranslateService, readonly apiConnector: ApiConnectorService) {}

    onChange: any = () => {};
    onTouched: any = () => {};

    writeValue(obj: any): void {
        this.selectedCountry = obj;
    }

    get value() {
        return this.selectedCountry;
    }

    set value(v) {
        this.selectedCountry = v;
        this.onChange(this.selectedCountry);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    async ngOnInit(): Promise<void> {
        this.allCountries = [];

        let preffered: LanguageTextAndCountry[] = [];
        let rest: LanguageTextAndCountry[] = [];

        if (!this.countriesRaw.length) {
            this.countriesRaw = await this.apiConnector.getCountries();
        }

        for (const country of this.countriesRaw) {
            const countryName = this.getCountryName(country);
            if (countryName) {
                const countryData = { text: countryName, country: country.countryISOCode, disabled: false };

                if (this.preferredCountries.includes(country.countryISOCode)) {
                    preffered.push(countryData);
                } else {
                    rest.push(countryData);
                }
            }
        }

        preffered = preffered.sort((a, b) => a.text.localeCompare(b.text));
        rest = rest.sort((a, b) => a.text.localeCompare(b.text));
        const moreCountriesText = await this.translateService.get('text.select.moreCountries').toPromise();
        this.allCountries = [...preffered, { country: '', text: '--' + moreCountriesText + '--', disabled: true }, ...rest];

        const allCountriesText = await this.translateService.get('text.select.empty').toPromise();
        this.allCountries.unshift({ country: '', text: allCountriesText });

        this.translateServiceSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.ngOnInit();
        });
    }

    ngOnDestroy() {
        this.translateServiceSubscription?.unsubscribe();
    }

    private getCountryName(country: GeoCountryEntity): string {
        let countryName = '';

        if (!country.text) {
            return countryName;
        }

        if (country.text[this.translateService.currentLang]) {
            countryName = country.text[this.translateService.currentLang];
        } else if (country.text['en']) {
            countryName = country.text['en'];
        } else if (Object.keys(country)) {
            countryName = country.text[0];
        }

        return countryName;
    }

    private getCountryById(id: string) {
        return this.allCountries.find((country) => country.country.toUpperCase() === id.toUpperCase());
    }

    setCurrentPhonePrefix(val) {
        const selectedCountry = this.countriesRaw.filter((country) => {
            return country.countryISOCode === val;
        });

        if (selectedCountry?.length) {
            this.setPhonePrefix.emit(selectedCountry[0].phonePrefix);
        }
    }

    onCountryChange(event: Event): void {
        const selectElement = event.target as HTMLSelectElement;
        const selectedValue = selectElement.value;
        this.setCurrentPhonePrefix(selectedValue);
    }
}
