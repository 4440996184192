<div class="is-invalid" *ngIf="control.touched && control.errors"></div>
<div class="invalid-feedback" *ngIf="control.touched && control.errors">
    <div *ngIf="control.errors.required">
        {{ 'validationMessages.required' | translate }}
    </div>
    <div *ngIf="control.errors.minlength">
        {{ 'validationMessages.minlength' | translate: { length: control.errors.minlength.requiredLength } }}
    </div>
    <div *ngIf="control.errors.maxlength">
        {{ 'validationMessages.maxlength' | translate: { length: control.errors.maxlength.requiredLength } }}
    </div>
    <div *ngIf="control.errors.email">
        {{ 'validationMessages.email' | translate }}
    </div>
    <div *ngIf="control.errors.pattern">
        {{ 'validationMessages.pattern' | translate: { requiredPattern: control.errors.pattern.requiredPattern } }}
    </div>
    <div *ngIf="control.errors.min">
        {{ 'validationMessages.min' | translate: { min: control.errors.min.min } }}
    </div>
    <div *ngIf="control.errors.max">
        {{ 'validationMessages.max' | translate: { max: control.errors.max.max } }}
    </div>
    <div *ngIf="control.errors.overlapError">
        {{ 'validationMessages.overlapError' | translate: {
        dateFrom: dateService.getFormattedDate(control.errors.overlapError.from, translateService.currentLang, false),
        dateTo: dateService.getFormattedDate(control.errors.overlapError.to, translateService.currentLang, false) } }}
    </div>
    <div *ngIf="control.errors.dateOrderError">
        {{ 'validationMessages.dateOrder' | translate }}
    </div>
    <div *ngIf="control.errors.prefixNumberCombination">
        {{ 'user.validation.profile.phone' | translate }}
    </div>
    <div *ngIf="control.errors.emailOrNumber">
        {{ 'validationMessages.emailOrNumber' | translate }}
    </div>
    <div *ngIf="control.errors.dateRangeToLongError">
        {{ 'atraveo.accommodationbundle.price.accommodation-daterange-too-long' | translate }}
    </div>
    <div *ngIf="control.errors.invalidUrl">
        {{ 'user.validation.invalidUrl' | translate }}
    </div>
    <div *ngIf="control.errors.minChildren">
        {{ 'text.feature.form.KINDER.compared.MAXPERSON.error' | translate }}
    </div>
    <div *ngIf="control.errors.ownerAccommodationId">
        {{ 'validationMessages.ownerAccommodationId' | translate }}
        <a routerLink="/accommodation/{{ control.errors.usedAccommodationId }}" target="_blank">{{ control.errors.usedAccommodationId }}</a>
    </div>
    <div *ngIf="control.errors.iban">
        {{ 'user.validation.iban' | translate }}
    </div>
    <div *ngIf="control.errors.duplicateIban">
        {{ 'user.validation.duplicateIban' | translate }}
    </div>
    <div *ngIf="control.errors.dateOuterMinMax">
        {{ 'user.validation.dateOuterMinMax' | translate }}
    </div>
    <div *ngIf="control.errors.pauseInputLengthError">
        {{ 'atraveo.accommodationbundle.accommodation.pause.dialog.input.minLength' | translate }}
    </div>
</div>
