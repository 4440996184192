<div class="col-nav service-sidebar-container" [class.service-sidebar-hidden]="!serviceSidebarVisible" >
    <button [class.service-sidebar-hidden]="serviceSidebarVisible" (click)="toggleServiceSidebar(!serviceSidebarVisible)" class="btn toggle-service-sidebar btn-top btn-internal">
        <span >Service-Sidebar</span>
    </button>
    <button [class.service-sidebar-hidden]="!serviceSidebarVisible" (click)="toggleServiceSidebar(!serviceSidebarVisible)"  class="btn toggle-service-sidebar inside-service btn-internal ">
        <span ><i class="fa fa-compress-arrows-alt"></i></span>
    </button>


    <div class="container-fluid p-3" [class.service-sidebar-hidden]="!serviceSidebarVisible">
        <table>
            <tr>
                <td>Vermieternr.</td>
                <td>
                    {{ authService.currentUser.ownerNumber }}
                    <ng-container *ngIf="authService.currentUser.state">
                        <span *ngIf="authService.currentUser.state.active">(aktiv)</span>
                        <span *ngIf="!authService.currentUser.state.active">(inaktiv)</span>
                    </ng-container>
                    <ng-container *ngIf="$any(authService.currentUser.features)?.VRMVIPACCOUNT">
                        <img src="assets/img/label/vip.webp" class="vip-label" alt="VIP Host" />
                    </ng-container>
                </td>
            </tr>
            <tr>
                <td>Mandant</td>
                <td>{{ authService.currentUser.settings?.client }} (HKM {{ authService.currentUser.settings?.clientOrigin }})</td>
            </tr>
            <tr>
                <td>Name</td>
                <td>{{ authService.currentUser.getPrimaryContact()?.forename }} {{ authService.currentUser.getPrimaryContact()?.surname }}</td>
            </tr>
            <tr>
                <td>Firma</td>
                <td>{{ authService.currentUser.getPrimaryContact()?.company }}</td>
            </tr>
            <tr *ngIf="registrationDate">
                <td>Registrierung</td>
                <td>{{ registrationDate | date: 'medium' }}</td>
            </tr>
            <tr *ngIf="lastLoginDate">
                <td>Letzter Login</td>
                <td>{{ lastLoginDate | date: 'medium' }}</td>
            </tr>
            <tr>
                <td>IP-Adresse</td>
                <td>{{ authService.currentUser.registration?.ipAddress }}</td>
            </tr>
            <tr>
                <td>Anzahl Objekte</td>
                <td>{{ accommodationCount }}</td>
            </tr>
            <tr>
                <td>Alle Objekte pausiert</td>
                <td>{{ this.authService.currentUser?.getTakeAllAccommodationsOffline() ? 'Ja': 'Nein' }}</td>
            </tr>
            <tr>
                <td>Objekterfassung</td>
                <td *ngIf="authService.currentUser.settings?.importType === imported">Importiert</td>
                <td *ngIf="authService.currentUser.settings?.importType !== imported">Selbsterfasst</td>
            </tr>
            <tr>
                <td>Rolle</td>
                <td>{{ authService.currentUser.roles }}</td>
            </tr>
            <tr>
                <td>Aktionen</td>
                <td>
                    <a class="btn btn-secondary" href="https://backoffice.atraveo.de/programme/vermieter/vermieter_edit.php?vermieternummer={{ authService.currentUser.ownerNumber }}"
                        >Zum Vermieterstamm</a
                    >
                </td>
            </tr>
            <tr>
                <td><input class="form-control" type="number" name="changeOwner" [(ngModel)]="changeToUser" /><p *ngIf="errorChangeToUser" class="error mt-2">{{ errorChangeToUser }}</p></td>
                <td><button (click)="changeUser()" class="btn btn-secondary">Anderen VRM aufrufen</button></td>
            </tr>
            <tr>
                <td><input class="form-control" type="text" [(ngModel)]="changeToAccommodation" /></td>
                <td><button (click)="changeAccommodation()" class="btn btn-secondary">Objekt aufrufen</button></td>
            </tr>
            <tr *ngIf="notesActive">
                <td colspan="2">
                    <p>
                        <textarea class="form-control" rows="3" [(ngModel)]="notesBilling" placeholder="Bemerkung für Inserats-Abrechnung"></textarea>
                    </p>
                    <p>
                        <button (click)="saveNotes()" class="btn btn-secondary float-right">Speichern</button>
                    </p>
                </td>
            </tr>
            <tr>
                <td></td>
                <td>
                    <button (click)="autoCreateAndSave()" class="btn btn-secondary float-right">Unterkunft automatisch erstellen</button>
                </td>
            </tr>
        </table>
    </div>
</div>
