
<div class="accommodations">
    <h1 *ngIf="accommodationCount">{{ 'atraveo.accommodationbundle.overview.title' | translate: { accommodationCount: accommodationCount } }}</h1>
    <p>{{ 'atraveo.accommodationbundle.overview.header' | translate }}</p>

    <div class="row">
        <div class="col-8">
            <app-accommodation-change-select *ngIf="accommodationCount >= 2"></app-accommodation-change-select>
        </div>
        <div class="col-4">
            <button class="btn btn-primary float-right" type="button" (click)="openFilterModal()">
                <i class="fa fa-filter mr-2"></i>
                {{ 'filter' | translate }}
            </button>
        </div>
    </div>

    <hr>

    <div *ngIf="groupedAccommodations">
        <div *ngFor="let group of groupedAccommodations | keyvalue">
            <ng-container *ngFor="let accommodation of group.value; let i = index">
                <div class="accommodationGroupName" *ngIf="i == 0 && accommodation.accommodationGroupId > 0">{{ accommodation.accommodationGroupName }}</div>

                <div [class.indented]="accommodation.accommodationGroupId > 0" class="accommodation mb-3">
                    <div class="row col-md clearfix">
                        <a class="image-wrapper float-left" routerLink="/accommodation/{{ accommodation.accommodationId }}{{ mobileAccommodationOverviewLink }}" routerLinkActive="active">
                            <app-thumbnail [accommodation]="accommodation"></app-thumbnail>
                            <app-state [accommodation]="accommodation"></app-state>
                            <div *ngFor="let language of accommodation.descriptionLanguages" class="languages">
                                <a routerLink="/accommodation/{{ accommodation.accommodationId }}{{ mobileAccommodationOverviewLink }}" routerLinkActive="active" fragment="description"
                                    ><img src="assets/img/{{ language }}.gif" class="align-middle"
                                /></a>
                            </div>
                        </a>
                        <div class="short-description float-left ml-2">
                            <a routerLink="/accommodation/{{ accommodation?.accommodationId }}{{ mobileAccommodationOverviewLink }}" routerLinkActive="active">
                                <h4>
                                    {{ accommodation.accommodationId }}
                                    <span *ngIf="accommodation?.main?.name"> ({{ accommodation?.main?.name }}) </span>
                                </h4>
                                <h4 *ngIf="accommodation.getEdomizilId()">{{ 'atraveo.accommodationbundle.description.eDomizilId.label' | translate}}: {{ accommodation.getEdomizilId() }}</h4>
                                <span [class.deleted]="accommodation.state?.deleted" [innerHTML]="this.descriptionTexts[accommodation.accommodationId]"></span>
                            </a>
                            <p *ngIf="accommodation?.main?.contingent">
                                {{ 'atraveo.accommodationbundle.overview.description.kontingent.0' | translate: { kontingent: accommodation.main.contingent } }}
                                <app-tooltip [additionalClassList]="'mr-2'" [text]="'atraveo.accommodationbundle.overview.description.kontingent.info' | translate"></app-tooltip>
                            </p>
                            <p *ngIf="accommodation?.main?.productKey">
                                <strong>
                                    {{ 'atraveo.accommodationbundle.model.' + accommodation?.main?.productKey | translate }}
                                </strong>
                            </p>

                            <app-accommodation-progress-bar *ngIf="accommodation?.main?.checkResultProgressBarTotal" [accommodation]="accommodation"></app-accommodation-progress-bar>

                            <div class="mt-3">
                                <div class="accommodations-btn-group">
                                    <a class="btn btn-primary edit" routerLink="/accommodation/{{ accommodation.accommodationId }}{{ mobileAccommodationOverviewLink }}" ><i class="fa fa-pen"></i> {{ 'text.edit' | translate }}</a>
                                    <div class="apply" *ngIf="!accommodation?.state?.imported">
                                        <app-publication-request-btn *ngIf="accommodation?.main?.checkResultProgressBarTotal" [accommodation]="accommodation" [owner]="authenticationService.currentUser" (onSetStates)="filterAccommodations();"></app-publication-request-btn>
                                    </div>
                                    <ng-container *ngIf="accommodation.state?.qualified && !accommodation.state.deactivatedByOwnerService">
                                        <button class="btn btn-primary" *ngIf="!accommodation.state.paused" (click)="openPauseAccommodationConfirmationDialog(accommodation)"><i class="fa fa-pause"></i> {{ 'atraveo.accommodationbundle.accommodation.pause.short' | translate }}</button>
                                        <button class="btn btn-primary" *ngIf="accommodation.state.paused" (click)="toggleAccommodationPaused(accommodation)"><i class="fa fa-play"></i> {{ 'atraveo.accommodationbundle.accommodation.activate.short.0' | translate }}</button>
                                    </ng-container>
                                </div>
                                <a
                                    class="service pause"
                                    *ngIf="authenticationService.currentUser.isEmployee()"
                                    (click)="toggleDeactivateByOwnerServerAndDeleted(accommodation)"
                                >
                                    <i *ngIf="accommodation.state?.deactivatedByOwnerService && !accommodation.state?.deleted" class="fa fa-play fa-2x"></i>
                                    <i *ngIf="!accommodation.state?.deactivatedByOwnerService && !accommodation.state?.deleted" class="fa fa-pause fa-2x"></i>
                                    <i *ngIf="accommodation.state?.deleted" class="fa fa-undo fa-2x"></i>
                                </a>
                                <a class="btn btn-link service" *ngIf="this.accommodationsCanBeQualified[accommodation.accommodationId]" (click)="openQualifyAccommodationConfirmationDialog(accommodation)">
                                    <i class="fa fa-magic fa-2x"></i></a>
                            </div>
                        </div>
                    </div>
                    <app-errors [accommodation]="accommodation" [isAccommodationList]=true></app-errors>
                </div>
                <hr />
            </ng-container>
        </div>

        <div class="row">
            <div class="col-12 col-lg-2 mb-2">
                <a routerLink="/accommodation/register" class="btn btn-secondary">
                    <i class="fas fa-plus"></i>
                    {{ 'atraveo.accommodationbundle.overview.create.button' | translate }}
                </a>
            </div>
            <div class="col-12 col-lg-10">
                <button type="button" class="btn btn-primary" (click)="openCopyObjectData()">
                    <i class="fas fa-copy"></i>
                    {{ 'atraveo.accommodationbundle.overview.clone.button' | translate }}
                </button>
            </div>
        </div>

        <div *ngFor="let accommodation of accommodations | paginate: { itemsPerPage: filter.take, currentPage: currentPage, totalItems: accommodationCount }"></div>

        <pagination-controls *ngIf="accommodationCount > 0" class="float-right" (pageChange)="changePage($event)" previousLabel="" nextLabel=""></pagination-controls>
    </div>
    <ng-template #copyModal>
        <div tabindex="-1" role="dialog" aria-labelledby="copy-modal-label" aria-hidden="true">
            <div role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="copy-modal-label">{{ 'atraveo.accommodationbundle.overview.clone.button' | translate }}</h5>
                        <button type="button" class="close" aria-label="Close" (click)="dialogService.closeDialog()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body ">
                        <p>{{ 'atraveo.accommodationbundle.overview.clone.intro' | translate }}</p>
                        <app-accommodation-ajax-search
                            htmlId="search-copy"
                            (onSearch)="onSearchForCopyAccommodation($event)"
                            hideLabel="true"
                            hideIcons="true"
                        ></app-accommodation-ajax-search>
                    </div>
                    <div class="modal-footer"  >
                        <button type="button" class="btn btn-primary"  (click)="copyAccommodation()">{{ 'atraveo.accommodationbundle.overview.clone.0'  | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
