import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    notifications = new Subject<any>();

    constructor(readonly translationService: TranslateService) {}

    async add(text: string = null, type: string = 'danger', params: object = null) {
        let message = await this.translationService.get(text).toPromise();

        if (params) {
            for (const param of Object.keys(params)) {
                message = message.replace(param, params[param]);
            }
        }

        this.notifications.next({
            text: message,
            type,
            class: `alert alert-${type}`,
        });
    }
}
