import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ExtendedAccommodationEntity } from '../../../../../../entities/extendedAccommodationEntity';
import { ApiConnectorService } from '../../../../../../services/api-connector/api-connector.service';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { DateService } from '../../../../../../services/date/date.service';
import { SeasonsDto } from 'data-structures/lib/es6/dto/season/put-season/seasons.dto';
import { ExtendedDailyPriceRule } from '../../../../../../entities/PriceRule/extended-daily-price-rule';
import { ConfirmationDialogService } from '../../../../../global/confirmation-dialog/confirmation-dialog.service';
import { PriceDialogComponent } from '../../../../../global/dialogs/price-dialog/price-dialog.component';
import { DialogService } from '../../../../../../services/dialog.service';
import { addDays, format } from 'date-fns';
import { ExtendedPriceRule } from '../../../../../../entities/PriceRule/extended-price-rule';

@Component({
    selector: 'app-daily-price',
    templateUrl: './daily-price.component.html',
    styleUrl: './daily-price.component.scss',
})
export class DailyPriceComponent implements OnInit, OnDestroy {
    @Output() selectById: EventEmitter<number> = new EventEmitter<number>();
    @Output() deleteByIds: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() saveDailyPrice: EventEmitter<ExtendedDailyPriceRule> = new EventEmitter<ExtendedDailyPriceRule>();
    accommodation: ExtendedAccommodationEntity;
    accommodationSubscription: Subscription;
    dailyPrices: ExtendedDailyPriceRule[];
    seasons: SeasonsDto = new SeasonsDto();
    deleteElements: number[] = [];
    deleteMultipleInProgress: boolean = false;
    currentDailyPrice: ExtendedDailyPriceRule;
    currentDailyPriceId: number;

    constructor(
        readonly apiConnectorService: ApiConnectorService,
        readonly translateService: TranslateService,
        readonly dateService: DateService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly dialogService: DialogService,
    ) {}
    async ngOnInit() {
        if (!this.seasons.seasons?.length) {
            this.seasons = await this.apiConnectorService.getSeasons();
        }

        this.accommodationSubscription = this.apiConnectorService.activeAccommodation$.subscribe(async (accommodation) => {
            if (accommodation) {
                this.accommodation = cloneDeep(accommodation);

                this.dailyPrices = this.accommodation.dailyPriceRules;

                // sortieren nach Datum
                this.dailyPrices.sort((a, b) => {
                    return new Date(a.travelPeriod.from).getTime() - new Date(b.travelPeriod.from).getTime();
                });
            }
        });
    }
    ngOnDestroy() {
        this.accommodationSubscription?.unsubscribe();
    }

    setCurrentDailyPriceId(id: number) {
        this.selectById.emit(id);
        const selectedpriceRule = this.accommodation.dailyPriceRules.find((price) => price.id === id);
        if (selectedpriceRule) {
            this.currentDailyPrice = cloneDeep(selectedpriceRule);

            this.dialogService.openDialog(
                PriceDialogComponent,
                { dialogWidth: this.dialogService.dialogWidth.L },
                {
                    accommodation: this.accommodation,
                    useDailyPrices: true,
                    currentDailyPrice: this.currentDailyPrice,
                    savePrice: this.saveDailyPrice.emit.bind(this.saveDailyPrice),
                },
            );
        }
    }

    async deletePrice() {
        await this.deleteByIds.emit(this.deleteElements);
        this.deleteMultipleInProgress = false;
        this.deleteElements = [];
    }

    addOrRemoveDeleteElement(id: number) {
        if (this.deleteMultipleInProgress) {
            if (this.deleteElements.includes(id)) {
                const index = this.deleteElements.indexOf(id);
                if (index !== -1) {
                    this.deleteElements.splice(index);
                }
            } else {
                this.deleteElements.push(id);
            }
        }
    }

    abortDeletePrice = () => {
        this.deleteElements = [];
        this.deleteMultipleInProgress = false;
    };

    async deleteMultiple() {
        this.openConfirmationDialog();
    }

    openConfirmationDialog() {
        this.confirmationDialogService
            .confirm('atraveo.accommodationbundle.price.delete.0', 'confirm.content.delete.0')
            .then(async (confirmed) => {
                if (confirmed) {
                    this.deletePrice();
                }
            })
            .catch(() => undefined);
    }
}
