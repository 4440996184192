import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit, OnChanges {
    @Input() progressBarTotal: number = 0;
    @Input() classNameOverride: string = '';
    progressBarClassName: string;

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if (this.classNameOverride) {
            this.progressBarClassName = this.classNameOverride;
            return;
        }

        switch (true) {
            case this.progressBarTotal >= 0 && this.progressBarTotal < 50:
                this.progressBarClassName = 'danger';
                break;
            case this.progressBarTotal >= 50 && this.progressBarTotal < 100:
                this.progressBarClassName = 'progress';
                break;
            default:
                this.progressBarClassName = 'success';
        }
    }
}
