import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExtendedUserEntity } from '../../../../entities/extendedUserEntity';
import { ApiConnectorService } from '../../../../services/api-connector/api-connector.service';
import { NotificationService } from '../../../../services/notification/notification.service';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { OwnerConnectorService } from '../../../../services/api-connector/owner-connector.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import { ImportSourceEnum, OwnerInterfacesDto } from 'data-structures/lib/es6/dto/owner/OwnerInterfacesDto';
import * as Sentry from '@sentry/angular-ivy';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-interface',
    templateUrl: './interface.component.html',
    styleUrls: ['./interface.component.scss'],
})
export class InterfaceComponent implements OnInit, OnDestroy {
    owner: ExtendedUserEntity;
    selectedTab: string;
    ownerHasIcal: boolean;
    useBookingsModelMinOwnerNumber: number = 105329;
    currentUserSubscription: Subscription;

    constructor(
        readonly apiConnector: ApiConnectorService,
        readonly notificationService: NotificationService,
        readonly authentificationService: AuthenticationService,
        readonly ownerConnector: OwnerConnectorService,
        readonly activatedRoute: ActivatedRoute,
        readonly translationService: TranslateService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.owner = this.authentificationService.currentUser;
        this.ownerHasIcal = await this.ownerConnector.ownerHasIcal(this.owner.ownerNumber);

        this.createInterfacesIfNotPresent();

        // Wenn schon eine Schnittstelle eingetragen wurde, es zu dem Zeitpunkt aber keine Häuser mit ownerAccommodationId gab einmal die Validierung neu anstoßen
        if (
            this.owner.interfaces.importSource === ImportSourceEnum.Atraveo &&
            this.owner?.interfaces?.vacancyCheckResult?.valid === false &&
            this.owner.interfaces.vacancyCheckResult.checkErrors?.includes(1)
        ) {
            this.owner.interfaces.vacancyCheckResult = await this.ownerConnector.saveAndValidateVacancyInterface(this.owner.ownerNumber, this.owner.interfaces.vacancy);
        }

        // Deaktiviert, weil das bereits im Auth-Service automatisch passiert wenn man die Route wechselt, sonst wird hier doppelt geladen
        // const reloaded = await this.ownerConnector.getUserData(this.owner.ownerNumber);
        // this.owner.interfaces = reloaded.interfaces;

        this.createInterfacesIfNotPresent();

        this.activatedRoute.fragment.subscribe((fragment) => {
            // Tab setzen wenn Anker gesetzt ist
            if (!fragment) {
                this.selectedTab = 'vacancy';
                return;
            }

            this.selectedTab = fragment;
        });

        this.currentUserSubscription = this.authentificationService.currentUserSubject.subscribe((user) => {
            if (user) {
                this.owner = user;
                this.createInterfacesIfNotPresent();
            }
        });
    }

    ngOnDestroy(): void {
        this.currentUserSubscription?.unsubscribe();
    }

    createInterfacesIfNotPresent() {
        if (!this.owner.interfaces) {
            this.owner.interfaces = new OwnerInterfacesDto();
        }

        if (!this.owner.interfaces.importSource) {
            this.owner.interfaces.importSource = ImportSourceEnum.Atraveo;
        }
    }

    async saveVacancy(showMessage: boolean = true): Promise<void> {
        try {
            this.owner.interfaces.vacancyCheckResult = await this.ownerConnector.saveAndValidateVacancyInterface(this.owner.ownerNumber, this.owner.interfaces?.vacancy);
            await this.apiConnector.updateOriginalVacancy();
            if (showMessage) {
                await this.notificationService.add('form.save.success', 'success');
            }
        } catch (e) {
            Sentry.captureException(e);
            await this.notificationService.add('form.save.failure', 'danger');
        }
    }

    async saveBooking(): Promise<void> {
        try {
            if (!this.owner.interfaces.booking) {
                delete this.owner.interfaces.booking;
            }

            if (this.owner?.ownerNumber >= this.useBookingsModelMinOwnerNumber) {
                this.owner.settings.directBooking = !this.owner.interfaces.booking;
            }

            await this.authentificationService.saveOwner(this.owner);
            await this.notificationService.add('form.save.success', 'success');
        } catch (e) {
            Sentry.captureException(e);
            await this.notificationService.add('form.save.failure', 'danger');
        }
    }

    async saveSource() {
        try {
            if (this.owner.interfaces.importSource !== ImportSourceEnum.Atraveo) {
                this.owner.interfaces.password = uuidv4();
                delete this.owner.interfaces.vacancy;
                delete this.owner.interfaces.vacancyCheckResult;
            } else {
                await this.saveVacancy(false);
                delete this.owner.interfaces.password;
            }

            await this.authentificationService.saveOwner(this.owner);
            await this.notificationService.add('form.save.success', 'success');
        } catch (e) {
            Sentry.captureException(e);
            await this.notificationService.add('form.save.failure', 'danger');
        }
    }
}
