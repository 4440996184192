import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BackOfficeConnectorService } from '../../../../../services/api-connector/back-office-connector-service';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageCountService } from '../../../../../services/messages/message-count.service';

@Component({
    selector: 'app-message-content',
    templateUrl: './message-content.component.html',
    styleUrls: ['./message-content.component.scss'],
})
export class MessageContentComponent implements OnInit {
    messageId: number;
    messageContent: any;
    messageDate: Date = null;

    constructor(
        readonly authenticationService: AuthenticationService,
        readonly backofficeApiConnector: BackOfficeConnectorService,
        readonly route: ActivatedRoute,
        readonly translationService: TranslateService,
        readonly messageCountService: MessageCountService,
    ) {}

    async ngOnInit() {
        this.route.params.subscribe((params) => {
            this.messageId = +params['messageId'];
        });

        this.messageContent = await this.backofficeApiConnector.getMessageContent(this.authenticationService.currentUser.ownerNumber, this.messageId);

        if (this.messageContent.date) {
            const date = new Date(
                this.messageContent.date.substr(0, 4) +
                    '-' +
                    this.messageContent.date.substr(4, 2) +
                    '-' +
                    this.messageContent.date.substr(6, 2) +
                    ' ' +
                    this.messageContent.date.substr(8, 2) +
                    ':' +
                    this.messageContent.date.substr(10, 2) +
                    ':' +
                    this.messageContent.date.substr(12, 2),
            );

            this.messageDate = date;
        }

        if (this.messageContent?.body) {
            this.messageContent.body = this.messageContent.body.replace(/\\n/g, '\n');
        }

        if (this.messageContent.isRead === '0') {
            await this.messageCountService.removeMessagesCount(1);
            await this.backofficeApiConnector.setMessageStatus(this.authenticationService.currentUser.ownerNumber, 'read', [this.messageId], 1, 'messageContent');
        }
    }

    async openAttachment(attachment: { name: string; id: string }) {
        await this.backofficeApiConnector.getMailAttachment(this.authenticationService.currentUser.ownerNumber, Number(this.messageId), Number(attachment.id), attachment.name);
    }
}
