import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../entities/extendedAccommodationEntity';
import { environment } from '../../../../../../environments/environment';
import { DescriptionCategory, DescriptionType } from 'data-structures/lib/es6/dto/accommodation/descriptions/description.dto';
import { TranslateService } from '@ngx-translate/core';
import { ExtendedAccommodationGroupEntity } from '../../../../../entities/extendedAccommodationGroupEntity';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DescriptionDto } from 'data-structures/lib/es6/dto/accommodation';
import { NotificationService } from '../../../../../services/notification/notification.service';
import { Observable, Subscription } from 'rxjs';
import { ComponentCanDeactivate } from '../../../../../guards/pending-changes-guard';
import { cloneDeep, isEqual } from 'lodash';
import { PendingChangesComponentService } from '../../../../../services/pending-changes-component/pending-changes-component.service';

@Component({
    selector: 'app-description',
    templateUrl: './description.component.html',
    styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent implements OnInit, ComponentCanDeactivate, OnDestroy {
    accommodationOrGroup: ExtendedAccommodationEntity & ExtendedAccommodationGroupEntity;
    accommodationOrGroupOriginal: ExtendedAccommodationEntity & ExtendedAccommodationGroupEntity;
    @Input() viewType: 'accommodation' | 'accommodationGroup' = 'accommodation';
    accommodationGroupId: number = null;
    selectedLanguage: string = '';
    allowedLanguages = environment.allowedLanguages;
    descriptions: object = {};
    textTitleErrorMessages: string[] = [];
    textAreas = [DescriptionType.caption, DescriptionType.complete];
    textAreaNames = {
        complete: 'complete',
    };
    saveTriggerSubscription: Subscription;
    accommodationSubscription: Subscription;
    debug = environment.stage === 'dev' || environment.stage === 'local' || environment.stage === 'qa';

    constructor(
        readonly translationService: TranslateService,
        readonly authenticationService: AuthenticationService,
        readonly apiConnectorService: ApiConnectorService,
        readonly route: ActivatedRoute,
        readonly router: Router,
        readonly notificationService: NotificationService,
        readonly ngZone: NgZone,
        readonly pendingChangesComponentService: PendingChangesComponentService,
    ) {
        // für debug local und qa
        if (this.debug) {
            this.textAreas.push(DescriptionType.location);
            this.textAreas.push(DescriptionType.characteristics);
            this.textAreas.push(DescriptionType.outdoor);
            this.textAreas.push(DescriptionType.features);
            this.textAreas.push(DescriptionType.neighbourhood);
        }
    }

    async ngOnInit() {
        this.accommodationSubscription = this.apiConnectorService.activeAccommodation$.subscribe((accommodation) => {
            if (accommodation) {
                this.accommodationOrGroup = cloneDeep(accommodation);
                this.initial();
            }
        });

        this.saveTriggerSubscription = this.pendingChangesComponentService.getSaveTriggerObservable().subscribe(async () => {
            await this.save();
        });
    }

    initial() {
        for (const descriptionType of Object.values(this.textAreas)) {
            this.descriptions[descriptionType] = {};
        }

        this.accommodationOrGroup = this.mergeDescriptionTypesToComplete(this.accommodationOrGroup);
        this.accommodationOrGroup = this.convertHtmlToPlainTextForDescriptions(this.accommodationOrGroup);

        if (this.accommodationOrGroup.main?.licenseNumber) {
            this.accommodationOrGroup.main.licenseNumber = this.accommodationOrGroup.main.licenseNumber.trim();
        }
        this.ngOnChanges();
    }

    ngOnDestroy() {
        this.saveTriggerSubscription?.unsubscribe();
        this.accommodationSubscription?.unsubscribe();
    }

    async ngOnChanges() {
        if (this.accommodationOrGroup?.descriptions) {
            for (const description of this.accommodationOrGroup?.descriptions) {
                this.descriptions[description.type] = description.content;
            }
        }

        if (this.accommodationOrGroup.accommodationGroupId && this.accommodationOrGroup instanceof ExtendedAccommodationGroupEntity) {
            this.accommodationOrGroup.name = this.accommodationOrGroup.name ? await this.translationService.get(this.accommodationOrGroup.name, {}).toPromise() : '';
        }

        if (this.accommodationOrGroup.main?.licenseNumber) {
            this.accommodationOrGroup.main.licenseNumber = this.accommodationOrGroup.main.licenseNumber.trim();
        }
        // Für den Check, ob es Änderungen gibt
        this.accommodationOrGroupOriginal = cloneDeep(this.accommodationOrGroup);

        this.selectedLanguage = this.translationService.currentLang;
    }

    mergeDescriptionTypesToComplete(accommodation: ExtendedAccommodationEntity & ExtendedAccommodationGroupEntity): ExtendedAccommodationEntity & ExtendedAccommodationGroupEntity {
        // Neue Description erzeugen
        const completeDescription = new DescriptionDto();
        completeDescription.category = DescriptionCategory.accommodation;
        completeDescription.type = DescriptionType.complete;
        completeDescription.content = {};
        let setNewCompleteDescription = false;

        const newDescriptions = [];
        let oldCompleteDescription: DescriptionDto;

        // loop durch alle descriptions
        for (const [key, description] of Object.entries(accommodation.descriptions || [])) {
            const descriptionType = description.type;
            if (![DescriptionType.complete.toString(), DescriptionType.caption.toString()].includes(descriptionType)) {
                for (const [language, text] of Object.entries(description.content)) {
                    if (!completeDescription.content[language]) {
                        completeDescription.content[language] = '';
                    }

                    completeDescription.content[language] += (completeDescription.content[language] !== '' ? '<br><br>' : '') + text.toString().replace(/\n/g, '');
                    setNewCompleteDescription = true;
                }
            } else {
                // diese Logik ist für die Möglichkeit gedacht entweder die alten zu gruppieren oder complete zu speichern
                if (descriptionType === DescriptionType.complete.toString()) {
                    oldCompleteDescription = description;
                } else {
                    newDescriptions.push(description);
                }
            }
        }

        if (setNewCompleteDescription) {
            newDescriptions.push(completeDescription);
        } else {
            if (oldCompleteDescription) {
                newDescriptions.push(oldCompleteDescription);
            }
        }
        accommodation.descriptions = newDescriptions;
        return accommodation;
    }

    convertHtmlToPlainTextForDescriptions(accommodation: ExtendedAccommodationEntity & ExtendedAccommodationGroupEntity) {
        for (const description of accommodation.descriptions) {
            for (const [language, text] of Object.entries(description.content)) {
                description.content[language] = this.convertHtmlToPlainText(text.toString());
            }
        }
        return accommodation;
    }

    private convertHtmlToPlainText(htmlText: string): string {
        let plainText = htmlText.replace(/<br[^>]*>/g, '\n');

        plainText = plainText.replace(/<em[^>]*>/g, '').replace(/<\/em>/g, '');
        plainText = plainText.replace(/<strong[^>]*>/g, '').replace(/<\/strong>/g, '');
        plainText = plainText.replace(/<b[^>]*>/g, '').replace(/<\/b>/g, '');
        plainText = plainText.replace(/<pre[^>]*>/g, '').replace(/<\/pre>/g, '\n');
        plainText = plainText.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '\n');
        plainText = plainText.replace(/<div[^>]*>/g, '').replace(/<\/div>/g, '\n');
        plainText = plainText.replace(/<a[^>]*>/g, '').replace(/<\/a>/g, '\n');
        plainText = plainText.replace(/\n{3,}/g, '\n\n');

        // Ersetze <ol> durch Nummerierungen und <ul> durch Listenpunkte
        plainText = plainText.replace(/<ol[^>]*>/g, '\n').replace(/<\/ol>/g, '');
        plainText = plainText.replace(/<ul[^>]*>/g, '\n').replace(/<\/ul>/g, '');
        plainText = plainText.replace(/<li[^>]*>/g, '- ').replace(/<\/li>/g, '\n');

        // Überschriften
        plainText = plainText.replace(/<h\d[^>]*>/g, '\n\n').replace(/<\/h\d>/g, '\n');

        // wegen der ul erst jetzt
        plainText = plainText.replace(/<u[^>]*>/g, '').replace(/<\/u>/g, '');

        // gesamtes restliches html entfernen
        // plainText = plainText.replace(/<\/?[a-zA-Z][^>]+(>)/g, ' ');

        // sonderzeichen ersetzen
        const replaceMappingsEntities: [RegExp, string][] = [
            [/&nbsp;/g, ' '],
            [/&amp;/g, '&'],
            [/&lt;/g, '<'],
            [/&gt;/g, '>'],
            [/&quot;/g, '"'],
            [/&euro;/g, '€'],
            [/&copy;/g, '©'],
            [/&reg;/g, '®'],
            [/&trade;/g, '™'],
            [/&deg;/g, '°'],
            [/&sect;/g, '§'],
            [/&para;/g, '¶'],
            [/&bull;/g, '•'],
            [/&mdash;/g, '—'],
            [/&ndash;/g, '–'],
            [/&auml;/g, 'ä'],
            [/&ouml;/g, 'ö'],
            [/&uuml;/g, 'ü'],
            [/&Auml;/g, 'Ä'],
            [/&Ouml;/g, 'Ö'],
            [/&Uuml;/g, 'Ü'],
            [/&szlig;/g, 'ß'],
        ];

        for (const [search, replace] of replaceMappingsEntities) {
            plainText = plainText.replace(search, replace);
        }

        // Entferne führende und nachfolgende Leerzeichen sowie Zeilenumbrüche
        plainText = plainText.trim();

        return plainText;
    }

    hasHtmlTags(input: string): boolean {
        // Überprüfe, ob das Eingabefeld HTML-Tags enthält
        return /<([a-z][\w-]*)(\s[^>]*>|>)[\s\S]*<\/\1>|<([a-z][\w-]*)(\s[^>]*>|\/>)/i.test(input);
    }

    transformDescriptionsToDTOs() {
        const preparedData = [];
        for (const [type, descriptions] of Object.entries(this.descriptions)) {
            if (Object.keys(descriptions).length) {
                const description = new DescriptionDto();
                description.type = type as DescriptionType;
                description.category = this.accommodationOrGroup.isGroup ? DescriptionCategory.accommodationGroup : DescriptionCategory.accommodation;
                if (descriptions[this.selectedLanguage] === '' || descriptions[this.selectedLanguage] === null) {
                    delete descriptions[this.selectedLanguage];
                }
                description.content = descriptions;
                preparedData.push(description);
            }
        }
        this.accommodationOrGroup.descriptions = preparedData;
    }

    getDescriptionLanguages() {
        const allowedLanguages = new Set(environment.allowedLanguages);
        const foundLanguages = new Set();

        if (this.accommodationOrGroup?.descriptions?.length) {
            for (const description of this.accommodationOrGroup.descriptions) {
                // Kann undefined sein
                if (description) {
                    // @ts-ignore
                    for (const [language, text] of Object.entries(description.content)) {
                        if (allowedLanguages.has(language) && text) {
                            foundLanguages.add(language);
                        }
                    }
                }
            }
        }

        return Array.from(foundLanguages);
    }

    checkText(value: string) {
        const regexCharactersCheck = /^[a-zA-Z0-9\-_'.áùúâôíìżäöüßåæøèàòéąćęłńóśźñçð,\s]*$/i;
        const regexConsecutiveDigitsCheck = /\d{4,}/;
        const regexMeasurementUnitsCheck = /\b\d*(?:\.\d+)?\s*(?:(?![Mm][öäü]))\b(?:mm|cm|m|km|qm|sq)\b|\b(?:(?![Mm][öäü]))\b(?:mm|cm|m|km|qm|sq)\b/gi;
        const regexNoContinuousCapitalLettersCheck = /^(?:[A-ZßÄÖÜÅÆØĄĆĘŁŃÓŚŹŻÅÄÖÑÇ])+$/;
        const regexCapitalLetterCheck = /[A-Z\-_'.ßÄÖÜÅÆØĄĆĘŁŃÓŚŹŻÅÄÖÑÇ]/;
        const valueWords = value.split(' ');

        if (!regexCharactersCheck.test(value)) {
            if (!this.textTitleErrorMessages.includes('title.text.error.specialCharacter')) {
                this.textTitleErrorMessages.push('title.text.error.specialCharacter');
            }
        } else {
            if (this.textTitleErrorMessages.includes('title.text.error.specialCharacter')) {
                this.textTitleErrorMessages.splice(this.textTitleErrorMessages.indexOf('title.text.error.specialCharacter'), 1);
            }
        }

        if (value.length < 8 || value.length > 100) {
            if (!this.textTitleErrorMessages.includes('title.text.error.maxCharacter')) {
                this.textTitleErrorMessages.push('title.text.error.maxCharacter');
            }
        } else {
            if (this.textTitleErrorMessages.includes('title.text.error.maxCharacter')) {
                this.textTitleErrorMessages.splice(this.textTitleErrorMessages.indexOf('title.text.error.maxCharacter'), 1);
            }
        }

        if (regexConsecutiveDigitsCheck.test(value)) {
            if (!this.textTitleErrorMessages.includes('title.text.error.numbers')) {
                this.textTitleErrorMessages.push('title.text.error.numbers');
            }
        } else {
            if (this.textTitleErrorMessages.includes('title.text.error.numbers')) {
                this.textTitleErrorMessages.splice(this.textTitleErrorMessages.indexOf('title.text.error.numbers'), 1);
            }
        }

        if (value.match(regexMeasurementUnitsCheck)) {
            if (!this.textTitleErrorMessages.includes('title.text.error.units')) {
                this.textTitleErrorMessages.push('title.text.error.units');
            }
        } else {
            if (this.textTitleErrorMessages.includes('title.text.error.units')) {
                this.textTitleErrorMessages.splice(this.textTitleErrorMessages.indexOf('title.text.error.units'), 1);
            }
        }

        if (!value.match(regexCapitalLetterCheck)) {
            if (!this.textTitleErrorMessages.includes('title.text.error.lowercaseLetters')) {
                this.textTitleErrorMessages.push('title.text.error.lowercaseLetters');
            }
        } else {
            if (this.textTitleErrorMessages.includes('title.text.error.lowercaseLetters')) {
                this.textTitleErrorMessages.splice(this.textTitleErrorMessages.indexOf('title.text.error.lowercaseLetters'), 1);
            }
        }

        for (const valueWord of valueWords) {
            const containsOnlyUppercase = regexNoContinuousCapitalLettersCheck.test(valueWord);

            if (containsOnlyUppercase) {
                if (!this.textTitleErrorMessages.includes('title.text.error.capitalLetters')) {
                    this.textTitleErrorMessages.push('title.text.error.capitalLetters');
                }
                break;
            } else {
                if (this.textTitleErrorMessages.includes('title.text.error.capitalLetters')) {
                    this.textTitleErrorMessages.splice(this.textTitleErrorMessages.indexOf('title.text.error.capitalLetters'), 1);
                }
            }
        }

        if (value === '') {
            this.textTitleErrorMessages = [];
        }
    }

    async save() {
        // dürfen wir speichern?
        for (const language of this.allowedLanguages) {
            if (this.descriptions[DescriptionType.complete] && this.descriptions[DescriptionType.complete][language] && this.hasHtmlTags(this.descriptions[DescriptionType.complete][language])) {
                await this.notificationService.add('text.validation.htmlTags', 'danger');
                return;
            }
        }

        if (this.accommodationOrGroup instanceof ExtendedAccommodationEntity) {
            if (this.textTitleErrorMessages?.length) {
                await this.notificationService.add('text.validation.pleasecorrect', 'danger');
                return;
            }
        }

        const result = await this.apiConnectorService.saveAccommodationOrGroupForTab(this.route, this.accommodationOrGroup);
        this.pendingChangesComponentService.saveComplete(!!result);
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.viewType === 'accommodationGroup') {
            return true;
        }
        if (this.route?.snapshot?.url?.length >= 2 && this.route.snapshot.url[0].path === 'accommodation' && this.accommodationOrGroup?.state?.deleted) {
            return true;
        }
        return isEqual(this.accommodationOrGroup, this.accommodationOrGroupOriginal);
    }
}
