import { Config } from './types/config';
import { State } from './types/state';
import { DateService } from '../services/date/date.service';

export class OccupancyCalendar {
    private element: Element;
    private index = 0;
    private firstSelectedElement = null;
    private secondSelectedElement = null;
    private readonly startDate: Date;
    private config: Config = {
        dayClass: 'day',
        dayInPastClass: 'past',
        dayInPreviousMonthClass: 'previous-month',
        dayInNextMonthClass: 'next-month',
        daySelectedClass: 'selected',
        firstDayOfWeek: 0,
        locale: 'en-US',
        onCreateDay: (state: State): HTMLTableDataCellElement => state.element,
        onFinishSelection: (state: State): HTMLTableDataCellElement => state.element,
        onDayMouseEnter: (state: State): HTMLTableDataCellElement => state.element,
        onDayMouseLeave: (state: State): HTMLTableDataCellElement => state.element,
        showMonths: null,
        tableClass: 'occupancy-calendar',
        weekClass: 'week',
        weekDayLegendClass: 'day-legend',
        weekDayTranslations: 'short',
    };

    calendarDateIndexMap: Map<string, { dayIndex: number; customerStart: number }> = new Map<string, { dayIndex: number; customerStart: number }>();
    constructor(showMonths: number, element: Element, config: Config, startDate: Date = null) {
        this.element = element;
        this.config = Object.assign(this.config, config);
        this.config.showMonths = showMonths;

        if (!startDate) {
            this.startDate = new Date();
            this.startDate.setDate(1);
            this.startDate.setHours(0, 0, 0, 0);
        } else {
            this.startDate = startDate;
        }

        this.updateCalendar();
    }

    updateConfig(config): void {
        this.config = Object.assign(this.config, config);
        this.updateCalendar();
    }

    updateCalendar(): void {
        this.element.innerHTML = '';
        this.element.classList.add(this.config.tableClass);

        for (let monthDisplay = 0; monthDisplay < this.config.showMonths; monthDisplay++) {
            this.createMonth(monthDisplay);
        }
    }

    private createMonth(monthDisplay: number): void {
        const date = new Date(this.startDate);
        const tableRow = document.createElement('div');
        const table = document.createElement('table');
        const headerTr = document.createElement('tr');
        const header = document.createElement('th');
        const weekDays = document.createElement('tr');
        let dayOfMonth = 1;

        date.setMonth(date.getMonth() + monthDisplay);

        header.innerText = date.toLocaleDateString(this.config.locale, { year: 'numeric', month: 'long' });
        header.id = date.getFullYear() + '-' + date.getMonth();
        header.colSpan = 7;
        header.classList.add('month-header');
        weekDays.classList.add(this.config.weekDayLegendClass);

        this.element.appendChild(tableRow);
        tableRow.classList.add('table-row');
        const evenodd = monthDisplay % 2 === 0 ? 'even' : 'odd';
        tableRow.classList.add('row-' + evenodd);
        tableRow.appendChild(table);
        headerTr.appendChild(header);
        table.appendChild(headerTr);
        table.appendChild(weekDays);

        // Wochentage Mo,Di, Mi.....
        for (let weekDay = this.config.firstDayOfWeek; weekDay < this.config.firstDayOfWeek + 7; weekDay++) {
            const cell = document.createElement('td');
            const innerText = this.getDayTranslation(weekDay);
            cell.innerText = innerText;
            weekDays.appendChild(cell);
        }

        for (let week = 0; week < 6; week++) {
            if (dayOfMonth > this.getDaysInMonth(date.getMonth(), date.getFullYear())) {
                break;
            }

            const tr = document.createElement('tr');
            tr.classList.add(this.config.weekClass);
            table.appendChild(tr);

            for (let day = 0; day < 7; day++) {
                let td = document.createElement('td');
                td.classList.add(this.config.dayClass);
                const weekday = day + 1;
                td.classList.add(`weekday-${weekday}`);
                td.dataset.weekday = weekday.toString();

                let dateGetDay = date.getDay();
                if (dateGetDay === 0 && week === 0) {
                    dateGetDay = 7;
                }

                if (dayOfMonth > this.getDaysInMonth(date.getMonth(), date.getFullYear())) {
                    td.classList.add(this.config.dayInNextMonthClass);
                } else if (week === 0 && day < dateGetDay - this.config.firstDayOfWeek) {
                    td.classList.add(this.config.dayInPreviousMonthClass);
                } else {
                    td = this.createDay(date, dayOfMonth, td);
                    dayOfMonth++;
                    this.index++;
                }

                tr.appendChild(td);
            }
        }
    }

    getDayIdFromIndex(index: number) {
        return 'day-' + index.toString();
    }

    getIndexFromId(id: string) {
        return Number.parseInt(id.substring(4), 10);
    }

    createRange(startIndex: number, endIndex: number, className: string = this.config.daySelectedClass): void {
        for (let i = startIndex; i < endIndex + 1; i++) {
            const element = document.getElementById(this.getDayIdFromIndex(i));
            element.classList.add(className);
        }
    }

    private createDay(date, dayOfMonth: number, td: HTMLTableDataCellElement) {
        const dayDate = new Date(date);
        const occupancyDate = new Date(date);
        dayDate.setHours(0, 0, 0, 0);
        occupancyDate.setHours(12, 0, 0, 0);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        dayDate.setDate(dayDate.getDate() + dayOfMonth - 1);
        occupancyDate.setDate(occupancyDate.getDate() + dayOfMonth - 1);

        const infoDiv = document.createElement('div');
        infoDiv.classList.add('day-info');

        const dayDiv = document.createElement('div');
        dayDiv.classList.add('day-number');
        dayDiv.innerText = dayOfMonth.toString();

        const priceDiv = document.createElement('div');
        priceDiv.classList.add('price-tag');
        infoDiv.appendChild(dayDiv);
        infoDiv.appendChild(priceDiv);

        td.appendChild(infoDiv);
        td.setAttribute('data-date', dayDate.toJSON());

        const dateService = new DateService();
        const dateString = dateService.getFormattedDate(occupancyDate.toString(), 'en-US', false);
        td.setAttribute('data-datestring', dateString);
        this.calendarDateIndexMap.set(dateString, { dayIndex: this.index, customerStart: null });

        td.id = this.getDayIdFromIndex(this.index);

        if (today > dayDate) {
            td.classList.add(this.config.dayInPastClass);
            td.classList.add('no-selection');
        }

        td = this.callHooks(td, dayDate);

        return td;
    }

    private callHooks(td: HTMLTableDataCellElement, dayDate: Date): HTMLTableDataCellElement {
        td.onclick = () => {
            if (td.classList.contains('no-selection')) {
                return;
            }

            if (this.firstSelectedElement) {
                this.secondSelectedElement = td;

                const newState = {
                    element: td,
                    date: dayDate,
                    selectionStartedElement: this.firstSelectedElement,
                    selectionFinishedElement: this.secondSelectedElement,
                    calendar: this,
                };

                this.firstSelectedElement.classList.remove('selection-start');
                this.createRange(this.getIndexFromId(this.firstSelectedElement.id), this.getIndexFromId(td.id));

                if (this.config.onFinishSelection) {
                    this.config.onFinishSelection(newState);
                }
                this.firstSelectedElement = null;
            } else {
                this.firstSelectedElement = td;
                this.firstSelectedElement.classList.add('selection-start');
                state.selectionFinishedElement = null;
            }
        };

        const state: State = {
            element: td,
            date: dayDate,
            selectionStartedElement: null,
            selectionFinishedElement: null,
            calendar: this,
        };

        if (this.config.onCreateDay) {
            td = this.config.onCreateDay(state);
        }

        if (this.config.onDayMouseEnter) {
            td.onmouseenter = () => {
                state.selectionStartedElement = this.firstSelectedElement;
                state.date = dayDate;
                state.selectionFinishedElement = this.secondSelectedElement;
                td = this.config.onDayMouseEnter(state);
            };
        }

        if (this.config.onDayMouseLeave) {
            td.onmouseleave = () => {
                state.selectionStartedElement = this.firstSelectedElement;
                state.selectionFinishedElement = this.secondSelectedElement;
                state.date = dayDate;
                td = this.config.onDayMouseLeave(state);
            };
        }

        return td;
    }

    getDayTranslation(day: number): string {
        const date = new Date(2017, 0, 1); // Ist ein Sonntag :)
        date.setDate(date.getDate() + day);
        // @ts-ignore
        return date.toLocaleDateString(this.config.locale, { weekday: this.config.weekDayTranslations });
    }

    getDaysInMonth(month: number, year: number): number {
        return new Date(year, month + 1, 0).getDate();
    }

    getLocale() {
        return this.config.locale;
    }
}
