<div id="accordion">
    <p class="mt-3" *ngIf="authenticationService.currentUser">{{ 'atraveo.accommodationbundle.feature.header' | translate }}</p>

    <div *ngFor="let featureDefinitionGroup of this.featureDefinitionGroups"  class="accordion mt-2" id="collapse-features-{{ featureDefinitionGroup.id }}">
        <!-- Bei Kategorie Besonderheiten gibt es eine weitere Unterscheidung: Wenn das Haus importiert ist, wird die Kategorie mit manualForImports = 1 angezeigt, sonst die andere -->
        <ng-container *ngIf="(featureDefinitionGroup.id !== 15 && featureDefinitionGroup.id !== 9) || (!!featureDefinitionGroup.manualForImports) === accommodationsAreImported">
        <div class="card border-0">

            <div class="card-header" id="features-{{ featureDefinitionGroup.id }}-heading">
                <h2 class="mb-0">
                    <button class="btn btn-link" [class.collapsed]="!featureErrorIds.includes(featureDefinitionGroup.id)" data-toggle="collapse" type="button"  [attr.data-target]="'#features-' + featureDefinitionGroup.id" [attr.aria-controls]="'features-' + featureDefinitionGroup.id" aria-expanded="true">
                        <i [ngClass]="featureErrorIds.includes(featureDefinitionGroup.id) ? '' : 'chevron-rotated'" class="fa fa-chevron-down"></i>
                        {{ featureDefinitionGroup?.texts[currentLanguage] }}
                    </button>
                </h2>
            </div>

            <div id="features-{{ featureDefinitionGroup.id }}" class="collapse" [ngClass]="{'show': featureErrorIds.includes(featureDefinitionGroup.id)}" [attr.aria-labelledby]="'features-' + featureDefinitionGroup.id + '-heading'" [attr.data-parent]="'#accordion'">
                <div class="card-body">
                    <div class="row">
                        <div class="container-fluid" *ngIf="featureDefinitionGroup.rooms != null">
                            <div class="row mb-3">
                                <div class="col">
                                    <strong><label *ngIf="featureDefinitionGroup.id === 2">{{ 'atraveo.accommodationbundle.feature.bedrooms.number' | translate }} *</label></strong>
                                    <strong><label *ngIf="featureDefinitionGroup.id === 3">{{ 'atraveo.accommodationbundle.feature.bathroom.number' | translate }} *</label></strong>
                                    <div class="col-md-3 room-number-col">
                                        <select class="form-control"
                                                [attr.data-featureId]="featureDefinitionGroup.id === 2 ? 'SZ' : 'BZ'"
                                                (change)="changeFeature($event.target, this.constants.FEATUREDEFINITION_TYPE_SELECT)"
                                                [disabled]="allFieldsReadonly">
                                            <ng-container *ngFor="let roomNumber of roomsCounter(41);let i=index">
                                                <option [selected]="i == this.selectedValue[featureDefinitionGroup.id]">{{i}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <h4>{{ 'atraveo.accommodationbundle.feature.room.0' | translate }}</h4>
                                </div>
                                <label class="col required">
                                    {{ 'atraveo.accommodationbundle.feature.features' | translate }}
                                </label>
                            </div>
                            <div class="row mb-3" *ngFor="let keyValue of rooms[featureDefinitionGroup.id] | keyvalue: keepOriginalOrder; let roomIndex = index">
                                <div class="col pb-2 border-bottom">
                                    <!-- Raumauswahl -->
                                    <div class="row">
                                        <div class="col room-select">
                                            <select class="form-control custom-select" data-container="body"
                                            [attr.data-roomId]="0"
                                            [attr.data-featureGroupId]="featureDefinitionGroup.id"
                                            [(ngModel)]="keyValue.value.roomType"
                                            (change)="updateRoomsAndFeatures()"
                                            [required]="keyValue.value.roomType == null"
                                            [attr.data-select-all-text]= "'select.all' | translate"
                                            [attr.data-deselect-all-text]= "'select.none' | translate"
                                            [disabled]="(this.selectedValue[featureDefinitionGroup.id] && this.selectedValue[featureDefinitionGroup.id] > roomIndex) || this.selectedValue[featureDefinitionGroup.id] === 0">
                                                <option [value]="null" [selected]="null">{{ 'text.select.empty' | translate }}</option>
                                                <ng-container *ngFor="let roomDefinition of featureDefinitionGroup.rooms | keyvalue; let arrayIndex = index">
                                                    <option *ngIf="(featureDefinitionGroup.id == 2 && (this.selectedValue[featureDefinitionGroup.id] > roomIndex ||
                                                             this.selectedValue[featureDefinitionGroup.id] <= roomIndex && arrayIndex < 1)) ||
                                                            (featureDefinitionGroup.id == 3 && (this.selectedValue[featureDefinitionGroup.id] > roomIndex ||
                                                            this.selectedValue[featureDefinitionGroup.id] <= roomIndex && arrayIndex < 3))"
                                                            [selected]="keyValue.key == roomDefinition.key"
                                                            [value]="roomDefinition.key">
                                                        {{ roomDefinition.value.texts[currentLanguage] | replace:'%d':(roomIndex + 1) }}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                        <div class="col-xs-auto" *ngIf="this.selectedValue[featureDefinitionGroup.id] && !allFieldsReadonly &&
                                                                        (this.selectedValue[featureDefinitionGroup.id] == roomsLength[featureDefinitionGroup.id] && featureDefinitionGroup.id === 2 || (featureDefinitionGroup.id === 3))">
                                            <a *ngIf="!allFieldsReadonly" (click)="deleteRoom(featureDefinitionGroup.id, keyValue.key)" class="btn btn-icon btn-icon-only btn-icon-delete delete-room"></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col pb-2 border-bottom">
                                    <!-- Featureauswahl im Raum -->
                                    <div class="row" *ngFor="let feature of keyValue.value.features; let i = index">
                                        <div class="col">
                                            <select class="form-control"
                                                    data-container="body"
                                                    [attr.data-roomId]="'ROOMFEATURES'"
                                                    [(ngModel)]="feature.featureId"
                                                    (change)="updateRoomsAndFeatures()"
                                                    [required]="feature.featureId == null && keyValue.value.roomType !== null"
                                                    [disabled]="allFieldsReadonly || keyValue.value.roomType === null"
                                            >
                                                <option [value]="null" [selected]="null">{{ 'text.select.empty' | translate }}</option>
                                                <option *ngFor="let featureDefinition of featureDefinitionGroup.features"
                                                        [value]="featureDefinition.id">
                                                    {{ featureDefinition.texts[currentLanguage] | replace:'%d':(roomIndex + 1) }}
                                                </option>
                                            </select>
                                            <span *ngIf="feature.featureId == null && keyValue.value.roomType !== null" class="error">
                                                {{ 'This value should not be blank.' | translate }}
                                            </span>
                                        </div>
                                        <div class="col-xs-auto" *ngIf="!allFieldsReadonly">
                                            <ng-container>
                                                <a (click)="deleteFeature(featureDefinitionGroup.id, keyValue.key, i)" class="btn btn-icon btn-icon-only btn-icon-delete delete-room"></a>
                                                <br>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <a class="btn btn-primary mt-2" (click)="addFeatureToRoom(featureDefinitionGroup.id, keyValue.key)" *ngIf="!allFieldsReadonly">
                                        <i class="fas fa-plus mr-2"></i>
                                        {{ 'atraveo.accommodationbundle.feature.add.feature' | translate }}
                                    </a>
                                </div>
                            </div>
                            <a *ngIf="this.selectedValue[featureDefinitionGroup.id] && !allFieldsReadonly &&
                                      (this.selectedValue[featureDefinitionGroup.id] == roomsLength[featureDefinitionGroup.id] && featureDefinitionGroup.id === 2 || (featureDefinitionGroup.id === 3))"
                                      class="btn btn-primary mt-2" (click)="addSingleRoom(featureDefinitionGroup.id)">{{ 'atraveo.accommodationbundle.feature.add.room' | translate }}</a>
                        </div>
                        <ng-container *ngFor="let featureDefinition of featureDefinitionGroup.features">
                            <div class="col-md-6 mb-3"
                                *ngIf="!['HAUSART', 'MAXPERSON', 'WHNFLAECHE', 'KINDER', 'KONTINGENT', 'SZ', 'BZ'].includes(featureDefinition.id) &&
                                (featureDefinition.visibility === 'ALL' || authenticationService.currentUser?.isEmployee())"
                                [class.input-service]="featureDefinition.visibility != 'ALL' && authenticationService.currentUser?.isEmployee()">
                                <div *ngIf="featureDefinition.type == 'SELECT'" [attr.data-featureDefinitionId]="featureDefinition.id">
                                    <span [innerHTML]="featureDefinition.texts[currentLanguage] + (['TIERE', 'KUECHENUTZ'].includes(featureDefinition.id) ? ' *' : '')"></span>
                                    <span class="text-service" *ngIf="authenticationService.currentUser?.isEmployee()"> {{ featureDefinition.id }}</span>
                                    <br>
                                    <select class="form-control"
                                        [class.feature-error]="featureErrorIdNames.includes(featureDefinition.id)"
                                        data-container="body"
                                        [attr.data-featureId]="featureDefinition.id"
                                        (change)="changeFeature($event.target, featureDefinition.type)"
                                        [required]="requiredFeatures.indexOf(featureDefinition.id) != -1 && accommodationFeatures[featureDefinition.id]?.value == null"
                                        [disabled]="allFieldsReadonly"
                                    >
                                        <option [selected]="!accommodationFeatures[featureDefinition.id]?.value" [value]="null">{{ 'text.select.empty' | translate }}</option>
                                        <ng-container *ngFor="let values of featureDefinition.values | keyvalue: keepOriginalOrder">
                                            <option *ngIf="values.value[currentLanguage]"
                                                [value]="values.key ? values.key : null"
                                                [selected]="accommodationFeatures[featureDefinition.id]?.value == values.key"
                                            >{{ values.value[currentLanguage] }}</option>
                                        </ng-container>
                                    </select>
                                    <span *ngIf="featureDefinition.id == 'CARPORTS' && featureError && featureError['text.feature.form.CARPORT.error']" class="error">
                                        {{ 'text.feature.form.CARPORT.error' | translate }}
                                    </span>
                                    <span *ngIf="featureDefinition.id == 'GARAGEN' && featureError && featureError['text.feature.form.GARAGE.error']" class="error">
                                        {{ 'text.feature.form.GARAGE.error' | translate }}
                                    </span>
                                    <span *ngIf="featureDefinition.id == 'PRIVATPARK' && featureError && featureError['text.feature.form.PRIVATPARK.error']" class="error">
                                        {{ 'text.feature.form.PRIVATPARK.error' | translate }}
                                    </span>
                                    <span *ngIf="featureDefinition.id == 'FEWOART' && featureError && featureError['text.feature.form.FEWOART.error']" class="error">
                                        {{ 'text.feature.form.FEWOART.error' | translate }}
                                    </span>
                                    <span *ngIf="featureErrorIdNames.includes(featureDefinition.id)" class="error">
                                        {{ 'text.feature.field.error' | translate }}
                                    </span>

                                    <span *ngIf="featureDefinition.id != 'HAUSART' &&
                                     requiredFeatures.indexOf(featureDefinition.id) != -1 &&
                                     accommodationFeatures[featureDefinition.id]?.value == null" class="error">
                                        {{ 'This value should not be blank.' | translate }}
                                    </span>
                                </div>

                                <div [class.required]="(featureDefinition.id == 0 && featureDefinitionGroup.id == 5 && (!checkInternetOption || featureErrorIdNames.includes(featureDefinition.id)))"
                                    *ngIf="featureDefinition.type == 'MULTISELECT'"
                                    [attr.data-featureDefinitionId]="featureDefinition.id">
                                    <span [innerHTML]="featureDefinition.texts[currentLanguage] + (featureDefinition.id == 0 && featureDefinitionGroup.id == 5 ? ' *' : '')"></span>
                                    <span class="text-service" *ngIf="authenticationService.currentUser?.isEmployee()">{{ featureDefinition.id }}</span>
                                    <br>
                                    <select
                                        class="form-control custom-select selectpicker"
                                        multiple
                                        data-container="body"
                                        data-actions-box="true"
                                        [attr.title]="'select.none' | translate"
                                        [attr.data-featureId]="featureDefinition.id"
                                        [attr.data-select-all-text]= "'select.all' | translate"
                                        [attr.data-deselect-all-text]= "'select.none' | translate"
                                        (change)="changeFeature($event.target, featureDefinition.type)"
                                        [disabled]="allFieldsReadonly"
                                    >
                                        <option *ngFor="let values of featureDefinition.values | keyvalue"
                                            [value]="values.key"
                                            [selected]="accommodationFeatures[values.key]?.featureId == values.key">
                                            {{ values.value.texts[currentLanguage] ? values.value.texts[currentLanguage] : ('select.none' | translate) }}
                                        </option>
                                    </select>
                                    <span *ngIf="featureDefinition.id == 0 && featureDefinitionGroup.id == 5 && !checkInternetOption" class="error">
                                        {{ 'This value should not be blank.' | translate }}
                                    </span>
                                    <span *ngIf="featureDefinition.id == 0 && featureDefinitionGroup.id == 5 && featureErrorIdNames.includes(featureDefinition.id)" class="error">
                                        {{ 'text.feature.form.MULTISELECT_5_0.error' | translate }}
                                    </span>
                                </div>

                                <div *ngIf="featureDefinition.type == 'CHECKBOX'"
                                    class="custom-control custom-checkbox"
                                    [attr.data-featureDefinitionId]="featureDefinition.id">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        [attr.id]="'chk-' + featureDefinition.id"
                                        [attr.data-featureId]="featureDefinition.id"
                                        [checked]="accommodationFeatures[featureDefinition.id]?.value == 1"
                                        (change)="changeFeature($event.target, featureDefinition.type)"
                                        [value]="featureDefinition.value"
                                        [disabled]="allFieldsReadonly && featureDefinitionGroup.id !== 15 && !authenticationService.currentUser?.isEmployee()"
                                    >
                                    <label
                                        class="custom-control-label"
                                        [class.feature-error]="featureErrorIdNames.includes(featureDefinition.id)"
                                        [attr.for]="'chk-' + featureDefinition.id">
                                        {{ featureDefinition.texts[currentLanguage] }}
                                        <span class="text-service" *ngIf="authenticationService.currentUser?.isEmployee()">{{ featureDefinition.id }}</span>
                                    </label>
                                    <app-tooltip [additionalClassList]="'mr-2'" [text]="featureDefinition.infoTexts[currentLanguage]" *ngIf="featureDefinition.infoTexts && featureDefinition.infoTexts[currentLanguage]"></app-tooltip>
                                    <p *ngIf="featureErrorIdNames.includes(featureDefinition.id) && featureDefinition.id !== 'FREISTEHND' && featureDefinition.id !== 'GRASDACH' && featureDefinition.id !== 'HOEHLE' && featureDefinition.id !== 'BAUMHAUS'" class="error">
                                        {{ 'text.feature.field.error' | translate }}
                                    </p>
                                    <p *ngIf="featureErrorIdNames.includes(featureDefinition.id) && featureDefinition.id === 'FREISTEHND'" class="error">
                                        {{ 'text.feature.form.FREISTEHND.error' | translate }}
                                    </p>
                                    <p *ngIf="featureErrorIdNames.includes(featureDefinition.id) && featureDefinition.id === 'GRASDACH'" class="error">
                                        {{ 'text.feature.form.GRASDACH.error' | translate }}
                                    </p>
                                    <p *ngIf="featureErrorIdNames.includes(featureDefinition.id) && featureDefinition.id === 'HOEHLE'" class="error">
                                        {{ 'text.feature.form.HOEHLE.error' | translate }}
                                    </p>
                                </div>
                                <div
                                    *ngIf="featureDefinition.type == 'INPUT' && accommodation.main"
                                    [attr.data-featureDefinitionId]="featureDefinition.id">
                                    <span [innerHTML]="featureDefinition.texts[currentLanguage]"></span>
                                    <span class="text-service" *ngIf="authenticationService.currentUser?.isEmployee()">{{ featureDefinition.id }}</span>
                                    <br>
                                    <div class="input-group">
                                        <input
                                            class="form-control"
                                            type="{{(featureDefinition.id=='BJ'|| featureDefinition.id=='MODERN' || featureDefinition.units) ? 'number' : 'text'}}"
                                            min="{{(featureDefinition.id=='BJ'|| featureDefinition.id=='MODERN') ? '1000' : featureDefinition.units ? '0' : ''}}"
                                            [attr.max]="(featureDefinition.id === 'BJ' || featureDefinition.id=='MODERN') ? currentYear: null"
                                            [value]=accommodationFeatures[featureDefinition.id]?.value
                                            [attr.data-featureId]="featureDefinition.id"
                                            [required]="requiredFeatures.indexOf(featureDefinition.id) != -1"
                                            (change)="changeFeature($event.target, featureDefinition.type)"
                                            [readOnly]="allFieldsReadonly"
                                            (input)="removeSpecialCharacters($event)"
                                        >
                                        <div *ngIf="featureDefinition.unit" class="input-group-append">
                                            {{ featureDefinition.unit[0] }}
                                            <span class="input-group-text">{{ featureDefinition.unit[currentLanguage] }}</span>
                                        </div>

                                        <div *ngIf="featureDefinition.units" class="input-group-append">
                                            <select
                                                name="unit-select"
                                                class="input-group-text form-control custom-select"
                                                data-container="body"
                                                [attr.data-featureId]="featureDefinition.id"
                                                [disabled]="allFieldsReadonly"
                                                (change)="this.selectChange($event.target)"
                                                >
                                                <ng-container
                                                    *ngFor="let units of (featureDefinition.units | keyvalue).slice().reverse()">
                                                    <option [value]="units.key" [label]="units.value?.texts[currentLanguage]">
                                                            {{ units.value?.factor }}
                                                    </option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                    <span *ngIf="featureDefinition.id == 'GRUNDSTCKG' && featureError && featureError['text.feature.form.GRUNDSTCKG.error']" class="error">
                                        {{ 'text.feature.form.GRUNDSTCKG.error' | translate }}
                                    </span><br>
                                    <span *ngIf="featureErrorIdNames.includes(featureDefinition.id)" class="error">
                                        {{ 'text.feature.field.error' | translate }}
                                    </span>
                                    <ng-container *ngIf="featureDefinition.id == 'BJ' && yearOfRenovationError">
                                        <span class="error">
                                            {{ 'text.feature.form.BJ.error' | translate }}
                                        </span><br>
                                    </ng-container>
                                    <span *ngIf="featureDefinition.id == 'BJ' && yearOfConstructionError" class="error">
                                        {{ 'text.feature.form.BJ.compared.years.error' | translate: { compared_value: currentYear } }}
                                    </span>
                                    <ng-container *ngIf="featureDefinition.id == 'MODERN' && yearOfRenovationError">
                                        <span class="error">
                                            {{ 'text.feature.form.MODERN.error' | translate }}
                                        </span><br>
                                    </ng-container>
                                    <span *ngIf="featureDefinition.id == 'MODERN' && yearOfConstructionModernError" class="error">
                                        {{ 'text.feature.form.BJ.compared.years.error' | translate: { compared_value: currentYear } }}
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                        <div *ngIf="[1, 2, 3, 5, 32].includes(featureDefinitionGroup.id)" class="col-md-12 mt-3">
                            <span>{{ 'text.mandatoryfield' | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </ng-container>
    </div>
</div>

<div class="text-right clear mt-2">
    <button class="btn btn-success" (click)="save()"><i class="fas fa-cloud-upload-alt mr-2"></i>{{ 'button.save' | translate }}</button>
</div>
