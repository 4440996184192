export class TermsDto {
    // Kommentare sind von Ralf aus dem Ticket

    ownerId: number;
    language: string;
    data: {
        acceptedId: string | null; // Akzeptierte AGB-ID oder null
        acceptedDate: Date | null; // Wann die AGB-ID akzeptiert wurde oder null
        useAgbId: string | null; // Akzeptierte bzw. neue AGB-ID
        useLanguage: string | null; // Sprache zu der AGB-ID
        useDateFrom: Date | null; // Die AGB ist gültig ab
        useAgbText: string; // AGB-Text
    }
}
