<div class="container my-2">
<div class="block" *ngIf="this.translationService.currentLang === 'de'">
    <div class="pimcore_area_headline pimcore_area_content"><h1>Impressum für den Online-Vermieterbereich</h1></div>
    <div class="pimcore_area_text pimcore_area_content"><p><strong>e-domizil AG</strong><br>
        Binzstrasse 38<br>
        CH-8045 Zürich</p>
        <p>Geschäftsführer: Dieter Rumpel<br>
            Handelsregisternummer:&nbsp;CHE-112.646.132<br>
            Registergericht: Handelsregisteramt des Kantons Zürich<br>
            Umsatzsteueridentifikationsnummer:&nbsp;CHE-112.646.132 HR/MWST</p>
        <p>&nbsp;</p></div>
    <div class="pimcore_area_headline pimcore_area_content"><h3>Kontakt</h3></div>
    <div class="pimcore_area_text pimcore_area_content"><p><strong>{{ 'text.imprint.email' | translate }}</strong></p>
        <p><strong>{{ 'text.imprint.phone.display' | translate }}</strong><br>
            {{ 'text.imprint.time.open' | translate }}</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p></div>
</div>

<div class="content" *ngIf="this.translationService.currentLang !== 'de'">
    <div class="twocolcontainer ontop">
        <div class="leftcolWrapper">
            <div class="leftcol womargin fullWidth">
                <div class="pimcore_area_snippet pimcore_area_content"><div class="pimcore_area_articleBlock pimcore_area_content"><div class="block">
                    <div class="pimcore_area_headline pimcore_area_content"><h1> Legal Notice for the owner area</h1></div><div class="pimcore_area_text pimcore_area_content"><p><strong>e-domizil AG</strong><br>
                    Binzstrasse 38<br>
                    CH-8045 Zurich</p><p>Managing Director: Dieter Rumpel<br>
                    Commercial register number: CHE-112.646.132<br>
                    Register Court: Commercial Register Office of the Canton of Zurich<br>
                    Sales tax identification number: CHE-112.646.132 HR/MWST</p><p>&nbsp;</p></div><div class="pimcore_area_headline pimcore_area_content"><h3>Contact</h3></div><div class="pimcore_area_text pimcore_area_content"><p><strong>{{ 'text.imprint.email' | translate }}</strong></p><p><strong>{{ 'text.imprint.phone.display' | translate }}</strong><br>
                    {{ 'text.imprint.time.open' | translate }}</p><p>&nbsp;</p></div></div></div></div>		</div>
        </div>
    </div>
</div>
</div>
