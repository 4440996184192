<div *ngIf="image">
    <p class="edit-image-label hide"><strong>{{ 'text.images.edit' | translate }}</strong></p>
    <div class="row justify-content-center mt-4">
        <div class="col ">
            <div class="row main-image flex-row justify-content-center">
                <div class="col-sm-auto button-wrap wrap-left text-right d-flex flex-column justify-content-center flex-wrap">
                    <a [routerLink]="prevImageLink" [ngClass]="prevImageLink === ''? 'hide': ''" class="btn btn-icon">
                        <i class="fas fa-angle-double-left d-flex justify-content-center"></i></a>
                </div>
                <picture [class.remote]="image?.remoteUrl" class="d-flex justify-content-center">
                    <source srcset="{{ image.displayUrl | imageUrlPipe: { x: 600, y: 400, format: 'webp', media: image } }}" type="image/webp" media="(min-width: 600px)">
                    <source srcset="{{ image.displayUrl | imageUrlPipe: { x: 300, y: 200, format: 'webp', media: image } }}" type="image/webp" media="(min-width: 300px)">
                    <img alt="{{ 'text.images.alternative.edit' | translate }}" src="{{ image.displayUrl | imageUrlPipe: { x: 600, y: 400, media: image } }}" />
                </picture>
                <div class="col-sm-auto button-wrap wrap-right align-content-end d-flex justify-content-center flex-column flex-wrap">
                    <a [routerLink]="nextImageLink" [ngClass]="nextImageLink === ''? 'hide': ''" class="btn btn-icon">
                        <i class="fas fa-angle-double-right d-flex justify-content-center"></i></a>
                </div>
            </div>
        </div>
        <div class="row justify-content-center w-100 mt-4 mb-4">
            <div class="col-sm-auto text-center edit-img-box mr-5">
                <div class="btn-icon" role="button" (click)="rotateImage(image.order - 1)">
                    <i class="fa fa-undo fa-flip-horizontal"></i>
                    <span class="img-setting-label"><strong>{{ 'text.images.rotate' | translate }}</strong></span>
                </div>
            </div>
            <div class="col-sm-auto text-center sub-title hide edit-img-box mr-5" >
                <div  class="btn-icon" role="button"  data-toggle="modal" data-target="#imgTitleModal">
                    <i class="fa fa fa-pen"></i>
                    <span class="img-setting-label"><strong>{{ 'text.images.add.subtitle' | translate }}</strong></span>
                </div>
            </div>
            <div *ngIf="!accommodationOrGroup.accommodationGroupId" class="col-sm-auto text-center edit-img-box mr-5" [class.disabled]="image.order === 1">
                <div class="btn-icon" (click)="setCoverPhoto(image)"  role="button">
                    <i class="fas fa-home"></i>
                    <span class="img-setting-label"><strong>{{ 'text.images.title.photo' | translate }}</strong></span>
                </div>
            </div>
            <div class="col-sm-auto text-center edit-img-box">
                <div class="btn-icon" role="button" (click)="openConfirmationDialog(image.order - 1)">
                    <i class="fas fa-trash-alt"></i>
                    <span class="img-setting-label"><strong>{{ 'text.images.delete' | translate }}</strong></span>
                </div>
            </div>
            <div class="col-sm-auto text-center mt-3">
                <a href="javascript:void(0)" (click)="goBack()">{{ 'button.back-to-overview' | translate }}</a>
            </div>
        </div>
        <hr class="w-100">
        <div class="mt-5 sub-title-edit-box">
            <h1>{{ 'text.images.subtitle.label' | translate }}</h1>
            <p>{{ 'text.images.short.titles' | translate }}</p>
            <div class="mt-5">
                <div *ngFor="let language of allowedLanguages" class="row mt-2 mb-4 img-input-box">
                    <label for="{{language}}" class="col col-sm-3 labelcol">{{ 'intl.language.name.' + language | translate }}</label>
                    <div class="col col-sm-7">
                        <input id="{{language}}" [(ngModel)]="image.descriptions[language]" [ngModelOptions]="{updateOn: 'blur'}" name="text-{{ language }}" type="text" maxlength="100" #textCounter>
                    </div>
                    <div *ngIf="textCounter.value.length" class="col col-sm-2 p-0 text-right">
                        <span class="text-counter" [class.text-full]="textCounter.value.length === 100">{{textCounter.value.length}}/100</span>
                        <a href="javascript:void(0)" (click)="image.descriptions[language] = null">x</a>
                    </div>
                </div>
                <div class="row justify-content-center mt-5">
                    <div class="w-100 text-center">
                        <button type="button" class="btn btn-secondary" (click)="saveAccommodation();">{{ 'button.done' | translate }}</button>
                    </div>
                    <a href="javascript:void(0)" class="mt-3" (click)="goBack()">{{ 'button.back-to-overview' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
    <div class="modal modal-fullscreen-sm fade" role="dialog" tabindex="-1" aria-labelledby="imgTitleModalLabel" aria-hidden="true" id="imgTitleModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row p-3">
                        <h1>{{ 'text.images.subtitle.label' | translate }}</h1>
                        <p>{{ 'text.images.short.titles' | translate }}</p>
                        <div class="mt-5">
                            <div *ngFor="let language of allowedLanguages" class="row mt-2 mb-4 ml-0 w-100 img-input-box">
                                <label for="{{language}}-sm" class="col labelcol">{{ 'intl.language.name.' + language | translate }}</label>
                                <div class="col">
                                    <input id="{{language}}-sm" [(ngModel)]="image.descriptions[language]" [ngModelOptions]="{updateOn: 'blur'}" name="text-{{ language }}" type="text" maxlength="100" #textCounter>
                                </div>
                                <div *ngIf="textCounter.value.length" class="col-1 p-0 text-right">
                                    <a href="javascript:void(0)" (click)="image.descriptions[language] = null">x</a>
                                </div>
                            </div>
                            <div class="row justify-content-center mt-5">
                                <div class="w-100 text-center">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="saveAccommodation()">{{ 'button.done' | translate }}</button>
                                </div>
                                <a href="javascript:void(0)" class="mt-3" data-dismiss="modal">{{ 'button.cancel' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
