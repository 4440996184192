<p [innerHTML]="'atraveo.notificationbundle.explanation' | translate"></p>
<div class="row">
    <div class="col">
        <strong class="large">{{ 'atraveo.notificationbundle.filter.form.searchText' | translate }}:</strong>
        <input type="text" class="form-control w-auto d-inline typeahead" id="search" [(ngModel)]="messageRequest.searchInSubject" [ngModelOptions]="{ standalone: true }" />
        <a><i class="fa fa-search fa-lg ml-2" (click)="filterMessages()"></i></a>
        <a><i class="fa fa-undo fa-lg" (click)="clearFilter()"></i></a>
    </div>

    <div class="col-md-5">
        <div class="float-right">
            <div class="col">
                <button class="btn btn-primary float-right" type="button" (click)="openFilterModal()">
                    <i class="fa fa-filter mr-2"></i>
                    {{ 'filter' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<table class="responsive-table">
    <thead>
        <tr>
            <th>
                <a href="javascript:void(0);" (click)="selectAllMessages()">{{ 'atraveo.accommodationbundle.mark-all.short-text' | translate }}</a>
            </th>
            <th>{{ 'text.notification.field.sendDate' | translate }}</th>
            <th>{{ 'text.notification.field.toUser' | translate }}</th>
            <th>{{ 'text.notification.field.subject' | translate }}</th>
            <th>{{ 'text.notification.field.relation' | translate }}</th>
            <th>{{ 'text.notification.field.options' | translate }}</th>
        </tr>
    </thead>

    <ng-container *ngFor="let outboxMessage of messages; let messageIndex = index">
        <tr *ngIf="messageIndex < messages.length - 1" class="notification-content-outbox">
            <td [attr.data-label]="'atraveo.accommodationbundle.mark-all.short-text' | translate">
                <input name="outbox-checkbox" type="checkbox" class="outbox-checkbox" (change)="changeState()" id="{{ outboxMessage.id }}" />
            </td>
            <td [attr.data-label]="'text.notification.field.sendDate' | translate">{{ outboxMessage.date }}</td>
            <td [attr.data-label]="'text.notification.field.fromUser' | translate">{{ outboxMessage.receiver }}</td>
            <td [attr.data-label]="'text.notification.field.subject' | translate">
                <a routerLink="/notification/show/{{ outboxMessage.id }}" [queryParams]="{ ownerid: authenticationService.currentUser.ownerNumber }" routerLinkActive="active">{{
                    outboxMessage.subject
                }}</a>
            </td>
            <td [attr.data-label]="'text.notification.field.relation' | translate"></td>
            <td [attr.data-label]="'text.notification.field.options' | translate">
                <a routerLink="/notification/show/{{ outboxMessage.id }}" [queryParams]="{ ownerid: authenticationService.currentUser.ownerNumber }" routerLinkActive="active"
                    ><i class="fas fa-eye"></i
                ></a>

                <i *ngIf="outboxMessage.attachments?.length" class="fas fa-paperclip"></i>
            </td>
        </tr>
    </ng-container>
</table>

<div class="btn-group notification-outbox-buttons">
    <button class="btn btn-primary" [disabled]="noSelectedMessages" (click)="openConfirmationDialog()" title="{{'atraveo.notification.messages.unmarked.deleted' | translate }}">{{ 'text.notification.button.mark.delete' | translate }}</button>
</div>

<div *ngFor="let outboxMessage of messages | paginate: { id: 'outbox-pagination', itemsPerPage: messageRequest.limit, currentPage: messageRequest.page, totalItems: messagesCount }"></div>

<pagination-controls id="outbox-pagination" class="float-right pagination-control" (pageChange)="changePage($event)" previousLabel="" nextLabel=""> </pagination-controls>
