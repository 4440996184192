import { Component, OnChanges, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { ConstantsService } from '../../../../services/constants/constants.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnChanges, OnInit {
    destinationUser: boolean;
    constructor(readonly authenticationService: AuthenticationService) {}

    async ngOnInit() {
        this.ngOnChanges();
    }

    async ngOnChanges() {
        this.destinationUser = this.authenticationService.currentUser.hasRole(ConstantsService.ROLE_DESTINATION);
    }
}
