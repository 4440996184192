<ng-container *ngIf="featureDefinition && form" [formGroup]="form">
    <label [for]="'select-'+formControlName" [innerHTML]="featureDefinition.texts[currentLanguage] + (isRequired ? ' *' : '')"></label>
    <span class="text-service" *ngIf="authenticationService.currentUser?.isEmployee()"> {{ featureDefinition.id }}</span>
    <div class="input-group">
        <select class="form-control"
                data-container="body"
                [attr.data-featureId]="featureDefinition.id"
                [id]="'select-'+formControlName"
                [formControlName]="formControlName"
        >
            <option value="">{{ 'text.select.empty' | translate }}</option>
            <ng-container *ngFor="let values of featureDefinition.values | keyvalue: keepOriginalOrder">
                <option *ngIf="values.value[currentLanguage]"
                        [value]="values.key ? values.key : null"
                >{{ values.value[currentLanguage] }}</option>
            </ng-container>
        </select>
    </div>
    <app-validation-error-messages [control]="form.get(formControlName)"></app-validation-error-messages>
</ng-container>