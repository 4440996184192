<div class="modal-content">
    <div class="modal-header">
        <h3 *ngIf="currentAdditionalCostIsNew"
            class="modal-title">{{ 'atraveo.accommodationbundle.price.create.additionalcost.create.title' | translate }}</h3>
        <h3 *ngIf="!currentAdditionalCostIsNew"
            class="modal-title">{{ 'atraveo.accommodationbundle.additionalcost.edit-additionalcost' | translate }}</h3>
        <button class="close" (click)="closeDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-create
            *ngIf="currentAdditionalCost"
            [accommodationOrTemplateInput]="accommodationOrTemplate"
            [modalId]="idPrefix"
            [additionalCostDefinitionsObject]="additionalCostDefinitions"
            [additionalCost]="currentAdditionalCost"
            [additionalCostIsNew]="currentAdditionalCostIsNew"
            (onSave)="save($event)"
        >
        </app-create>
    </div>
</div>