<div class="modal-header">
    <button class="close" (click)="closeDialog()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>{{ 'atraveo.accommodationbundle.calendar.status.disabled' | translate }}</p>
    <button class="btn btn-link pl-0" (click)="openBookingDialog()">{{ 'atraveo.bookingbundle.button.new' | translate}}</button>
    <br>
    <a class="btn btn-link pl-0" id="switch-to-tab"
       (click)="changeToTab('prices')">
        {{ 'atraveo.accommodationbundle.price.addPrice' | translate }}
    </a>
</div>