import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { diff } from 'deep-object-diff';
import { cloneDeep, isEqual } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class ChangeService {
    constructor() {}

    getChangedContentTypes(entityFull: any, entityDb: any): string[] {
        if (!entityFull) {
            return [];
        }

        if (!entityDb) {
            return Object.keys(entityFull);
        }

        const changes = cloneDeep(diff(entityFull, entityDb));
        const changesFiltered = ChangeService.filterDeep(changes, entityFull, entityDb);

        if (entityDb?.modifyDate && entityFull?.modifyDate && format(entityDb.modifyDate, 'yyyy-MM-dd') === entityFull.modifyDate) {
            delete changesFiltered.modifyDate;
        }

        if (
            entityDb.termsOfServiceSettings?.acceptDateTime &&
            entityFull.termsOfServiceSettings?.acceptDateTime &&
            new Date(entityFull.termsOfServiceSettings?.acceptDateTime).getTime() === new Date(entityDb.termsOfServiceSettings?.acceptDateTime).getTime()
        ) {
            delete changesFiltered.termsOfServiceSettings;
        }

        return Object.keys(changesFiltered);
    }

    static filterDeep(changes, payload, current) {
        for (const changedNode of Object.keys(changes)) {
            // Wenn es gar nicht im Payload war, dann wegwerfen. "Knoten: null"-Werte sollen weiterhin betrachtet werden
            if (!(changedNode in payload) || (payload[changedNode]?.length === 0 && !current[changedNode])) {
                delete changes[changedNode];
            }

            // Bei PriceRules mussten wir eine ID hinzufügen beim laden, wenn nur diese sich geändert hat, dann ignorieren
            if (changedNode === 'priceRules' && changes[changedNode]) {
                // Das ist irgendwie ein Objekt, kein Array
                for (const [key, value] of Object.entries(changes[changedNode])) {
                    if (value === undefined) {
                        continue;
                    }

                    if (Object.keys(value).length === 1 && 'id' in (value as object)) {
                        delete changes[changedNode][key];
                    }
                }

                if (Object.keys(changes[changedNode]).length === 0) {
                    delete changes[changedNode];
                }
            }

            if (changedNode === 'additionalCosts') {
                if (changes[changedNode] === null || !changes[changedNode] ) {
                    continue;
                }

                for (const [key, value] of Object.entries(changes[changedNode])) {
                    if (value === undefined) {
                        delete changes[changedNode][key];
                    }
                }

                // Das Problem mit dem ASENT ist durch die Referenzproblematik glaube ich gelöst
                // Bei den Nebenkosten wird "Asent" immer wieder neu vom Inventory angelegt, wenn es nur da Änderungen gibt, diese ignorieren
                // if (
                //     Object.keys(changes[changedNode]).length === 1 &&
                //     Object.keys(changes[changedNode][0]).length === 1 &&
                //     'id' in changes[changedNode][0] &&
                //     changes[changedNode][0].id in payload.additionalCosts &&
                //     payload.additionalCosts[changes[changedNode][0].id].additionalCost === 'ASENT'
                // ) {
                //     delete changes[changedNode];
                // }
            }

            if (Array.isArray(payload[changedNode]) && Array.isArray(current[changedNode])) {
                if (isEqual(payload[changedNode], current[changedNode])) {
                    delete changes[changedNode];
                }
            }
        }
        return changes;
    }
}
