import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UrlLangDetectorService } from '../../../services/url-lang-detector/url-lang-detector.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Input() withLogo: boolean = false;
    environment = environment;
    constructor(readonly translateService: TranslateService, readonly urlLangDetectorService: UrlLangDetectorService, readonly titleService: Title) {}

    ngOnInit(): void {}

    async changeLanguage(event, lang) {
        await this.urlLangDetectorService.checkUrlLangAndRemove();
        this.translateService.use(lang);
        this.titleService.setTitle(await this.translateService.get('atraveo.application.title').toPromise());
    }
}
