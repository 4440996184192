import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { ExtendedAccommodationEntity } from '../../../../../entities/extendedAccommodationEntity';
import { cloneDeep, isEqual } from 'lodash';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConstantsService } from '../../../../../services/constants/constants.service';
import { ComponentCanDeactivate } from '../../../../../guards/pending-changes-guard';

interface Model {
    model: number;
    service: boolean;
}

@Component({
    selector: 'app-modell',
    templateUrl: './model.component.html',
    styleUrl: './model.component.scss',
})
export class ModelComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    private accommodationGroupSubscription: Subscription;
    accommodation: ExtendedAccommodationEntity;
    modelForm: FormGroup;
    models: Model[];
    originalFormValues: any;

    constructor(readonly apiConnectorService: ApiConnectorService, readonly authenticationService: AuthenticationService) {}
    async ngOnInit() {
        this.modelForm = new FormGroup({
            model: new FormControl('', [Validators.required]),
        });

        this.accommodationGroupSubscription = this.apiConnectorService.activeAccommodation$.subscribe(async (accommodation) => {
            if (accommodation) {
                this.accommodation = cloneDeep(accommodation);

                this.modelForm.patchValue({
                    model: this.accommodation.main.productKey || '',
                });
                this.originalFormValues = cloneDeep(this.modelForm.value);

                // hole alle möglichen Modelle
                this.models = await this.apiConnectorService.getAccommodationModels(this.accommodation.accommodationId);

                this.controlForm();
            }
        });
    }

    ngOnDestroy() {
        this.accommodationGroupSubscription.unsubscribe();
    }

    controlForm() {
        if (this.authenticationService.currentUser.roles.includes(ConstantsService.ROLE_OWNER)) {
            if (this.modelForm.get('model').value !== 511) {
                this.modelForm.disable();
                this.models = this.models.filter((model) => model.model !== 511);
            }
        }
    }

    async save() {
        if (this.modelForm.valid) {
            this.accommodation.main.productKey = this.modelForm.get('model').value;
            if (this.accommodation.main.productKey !== 511) {
                this.accommodation.main.managerId = '';
            }
            await this.apiConnectorService.saveAccommodation(this.accommodation);
        } else {
            this.modelForm.markAllAsTouched();
        }
    }

    canDeactivate(): Observable<boolean> | boolean {
        return isEqual(this.originalFormValues, this.modelForm.value);
    }
}
