import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '../../../../services/dialog.service';
import { ExtendedAdditionalCostTemplateEntity } from '../../../../entities/extended-additional-cost-template-entity';

@Component({
    selector: 'app-additional-cost-template-dialog',
    templateUrl: './additional-cost-template-dialog.component.html',
    styleUrl: './additional-cost-template-dialog.component.scss',
})
export class AdditionalCostTemplateDialogComponent {
    currentTemplate: ExtendedAdditionalCostTemplateEntity;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, readonly dialogService: DialogService) {
        this.currentTemplate = data.currentTemplate;
    }

    closeDialog() {
        this.dialogService.closeDialog();
    }

    save($event) {
        this.data.saveAdditionalCostTemplate($event);
    }
}
