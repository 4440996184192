import { Component, ElementRef, NgZone, OnChanges, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../entities/extendedAccommodationEntity';
import { OccupancyCalendar } from '../../../../../modules/occupancy-calendar';
import { getLocaleFirstDayOfWeek } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { State } from '../../../../../modules/types/state';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { ExtendedVacancy } from '../../../../../entities/extendedVacancy';
import { BookingEntity } from 'data-structures/lib/es6/entity/booking.entity';
import { environment } from '../../../../../../environments/environment';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { DateService } from '../../../../../services/date/date.service';
import { ConfirmationDialogService } from '../../../../global/confirmation-dialog/confirmation-dialog.service';
import { cloneDeep, isEqual } from 'lodash';
import { CreateBookingComponent } from '../../booking/create-booking/create-booking.component';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadBookingsService } from '../../../../../services/load-bookings/load-bookings.service';
import { format } from 'date-fns';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusEnum } from 'data-structures/lib/es6/enum/status.enum';
import { Observable, Subscription } from 'rxjs';
import { OwnerCurrencyEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { ComponentCanDeactivate } from '../../../../../guards/pending-changes-guard';
import { PaymentStatusEnum } from 'data-structures/lib/es6/enum/payment-status.enum';
import { DialogService } from '../../../../../services/dialog.service';
import { IcalDialogComponent } from '../../../../global/dialogs/ical-dialog/ical-dialog.component';
import { NotEditableDialogComponent } from '../../../../global/dialogs/not-editable-dialog/not-editable-dialog.component';
import { FinishSelectionDialogComponent } from '../../../../global/dialogs/finish-selection-dialog/finish-selection-dialog.component';
import { BookingViewComponent } from '../../booking/booking-view/booking-view.component';
import { AdvancedSettingsDialogComponent } from '../../../../global/dialogs/advanced-settings-dialog/advanced-settings-dialog.component';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnChanges, OnDestroy, ComponentCanDeactivate {
    @ViewChild(CreateBookingComponent) createBookingComponent: CreateBookingComponent;
    accommodation: ExtendedAccommodationEntity;
    accommodationOriginal: ExtendedAccommodationEntity;
    booking: BookingEntity = new BookingEntity();
    dateRangeHeadLine: string;
    selectedStart: Date;
    selectedEnd: Date;
    icalUrl: string = '';
    bookingInDateRange: boolean = false;
    popoverAllowed: boolean = false;
    editable: boolean = true;
    invalidUrlsList: string[] = [];
    priceInfoHtml: any;
    icalInfoHtml: any;
    icalLinksSize: number;
    buttonClicked: boolean = false;
    updateCalendar: boolean = false;
    copyClipboard: boolean = false;
    accommodationSubscription: Subscription;
    monthsToShow: number = 6;
    fullMonthsToShow: number = 24;
    backdropClickSubscription: Subscription;

    langToLocale: {} = {
        da: 'da-DK',
        de: 'de-DE',
        en: 'en-US',
        es: 'es-ES',
        fr: 'fr-FR',
        it: 'it-IT',
        nl: 'nl-NL',
        pl: 'pl-PL',
        sv: 'sv-SV',
        hr: 'hr-HR',
    };
    calendar: OccupancyCalendar;
    bookings: BookingEntity[];
    ownerCurrency: OwnerCurrencyEnum = this.authenticationService.currentUser.getOwnerCurrency();
    translationServiceSubscription: Subscription;
    constructor(
        readonly translationService: TranslateService,
        readonly apiConnectorService: ApiConnectorService,
        readonly authenticationService: AuthenticationService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly sanitizer: DomSanitizer,
        readonly ngZone: NgZone,
        readonly route: ActivatedRoute,
        readonly router: Router,
        readonly loadBookingsService: LoadBookingsService,
        readonly dateService: DateService,
        private el: ElementRef,
        readonly dialogService: DialogService,
        private renderer: Renderer2,
    ) {}

    async ngOnInit() {
        this.accommodationSubscription = this.apiConnectorService.activeAccommodation$.subscribe(async (accommodation) => {
            if (accommodation) {
                this.accommodation = cloneDeep(accommodation);
                this.accommodationOriginal = cloneDeep(accommodation);
                this.ngOnChanges();
            }
        });
        this.backdropClickSubscription = this.dialogService.backdropClick$.subscribe(() => {
            this.cancelSelection();
        });
    }

    async ngOnChanges() {
        this.icalLinksSize = this.accommodation?.vacancy?.importUrls ? this.accommodation?.vacancy?.importUrls.length : 0;

        if (this.authenticationService.currentUser?.interfaces?.vacancy || this.accommodation?.vacancy?.importUrls?.length) {
            this.editable = false;
        } else {
            this.editable = true;
        }

        if (Object.keys(this.accommodation).length) {
            this.booking.accommodationId = this.accommodation.accommodationId;
            this.accommodation.createDayHasPriceCache();

            await this.initCalendar();
            this.translationServiceSubscription?.unsubscribe();
            this.translationServiceSubscription = this.translationService.onLangChange.subscribe(() => {
                this.initCalendar();
            });

            if (!this.accommodation.vacancy) {
                this.accommodation.vacancy = new ExtendedVacancy();
            }

            if (!this.accommodation.vacancy.importUrls) {
                this.accommodation.vacancy.importUrls = [];
            }

            if (!this.accommodation.vacancy?.securityToken && this.accommodation.accommodationId) {
                await this.getNewIcalToken();
            }

            this.updateIcalLink();
        }
    }
    ngOnDestroy(): void {
        ($('.popover') as any).popover('dispose');
        this.accommodationSubscription?.unsubscribe();
        this.translationServiceSubscription?.unsubscribe();
        this.backdropClickSubscription?.unsubscribe();
    }

    async initCalendar() {
        const hasContingent = this.accommodation.main?.contingent || 0;
        if (hasContingent <= 1) {
            this.loadBookingsService.findBookingDto.filter.ownerNumber = this.authenticationService.currentUser.ownerNumber;
            this.loadBookingsService.findBookingDto.filter.accommodationIds = [this.accommodation.accommodationId];
            this.loadBookingsService.findBookingDto.filter.arrivalDate = DateService.getCurrentDateString();
            this.loadBookingsService.findBookingDto.skip = 0;
            this.loadBookingsService.findBookingDto.take = 0;
            await this.loadBookingsService.loadData();
            this.bookings = this.loadBookingsService.bookings;

            for (let i = this.bookings.length - 1; i >= 0; i--) {
                // @ts-ignore
                const paymentCancelled = this.bookings[i].paymentStatus === PaymentStatusEnum.Cancelled;
                const bookingCancelled = this.bookings[i].status === StatusEnum.Cancelled;
                if (bookingCancelled || paymentCancelled) {
                    this.bookings.splice(i, 1);
                }
            }
        }
        const button = this.renderer.createElement('button');
        const text = this.renderer.createText(await this.translationService.get('atraveo.accommodationbundle.price.moreConfiguration').toPromise());
        this.renderer.appendChild(button, text);
        this.renderer.setAttribute(button, 'id', 'advancedSettingsButton');
        this.renderer.addClass(button, 'btn');
        this.renderer.addClass(button, 'advanced-settings-btn');

        this.priceInfoHtml = this.sanitizer.bypassSecurityTrustHtml(
            await this.translationService.get('atraveo.accommodationbundle.calendar.info.0', { routerValue: this.accommodation.accommodationId }).toPromise(),
        );
        this.icalInfoHtml = this.sanitizer.bypassSecurityTrustHtml(await this.translationService.get('atraveo.accommodationbundle.calendar.introduction.second').toPromise());
        document.querySelector('.show-more')?.classList.remove('hide');
        $(document).off('click', 'body');
        $(document).on('click', 'body', async (e) => {
            const $target = $(e.target);
            if (
                !$target.parent().hasClass('nav-link') &&
                $target.parents().find('.popover .popover-calendar-content:visible').length &&
                $target.parents('.popover .popover-calendar-content').length === 0
            ) {
                e.preventDefault();
                e.stopImmediatePropagation();
                if (!this.popoverAllowed) {
                    // @ts-ignore
                    $('.popover').popover('dispose');
                    await this.initCalendar();
                } else {
                    this.popoverAllowed = false;
                }
            }
        });

        HTMLCollection.prototype[Symbol.iterator] = Array.prototype[Symbol.iterator];
        const elements = document.getElementsByClassName('calendar');
        if (!elements.length) {
            // Durch die Änderungen der Tabs wird dies ausgeführt bevor das Template gerendert ist, auf andere Angular-Events zu warten hat leider auch nicht funktioniert
            window.setTimeout(() => {
                this.ngOnChanges();
            }, 50);
            return;
        }

        const locale = this.langToLocale[this.translationService.currentLang];
        const occupancyToClass = {
            N: 'occupied',
            Y: 'free',
            C: 'closed',
            P: 'no-price',
        };
        const popoverStartTextPromise = this.translationService.get('atraveo.accommodationbundle.calendar.hint.select.start').toPromise();
        const popoverStartedTextPromise = this.translationService.get('atraveo.accommodationbundle.calendar.hint.select.end').toPromise();
        const dateRangeTextPromise = this.translationService.get('atraveo.accommodationbundle.calendar.status.headline').toPromise();
        const bookedTextPromise = this.translationService.get('atraveo.accommodationbundle.calendar.info.blockedby').toPromise();

        const [popoverStartText, popoverStartedText, dateRangeText, bookedText] = await Promise.all([popoverStartTextPromise, popoverStartedTextPromise, dateRangeTextPromise, bookedTextPromise]);
        const accommodationBookingsDates: any = {};
        if (this.bookings?.length) {
            for (const bookingEntity of this.bookings) {
                accommodationBookingsDates[bookingEntity.arrivalDate] = bookingEntity;
                accommodationBookingsDates[bookingEntity.departureDate] = bookingEntity;
            }
        }
        this.calendar = new OccupancyCalendar(this.monthsToShow, elements.item(0), {
            locale,
            firstDayOfWeek: getLocaleFirstDayOfWeek(locale),

            onCreateDay: (state: State): HTMLTableDataCellElement => {
                const stateDateString = format(state.date, 'yyyy-MM-dd');
                const hasPrice = this.accommodation.dayHasPrice(state.date);
                if (!hasPrice) {
                    state.element.classList.add(occupancyToClass.P);
                }

                if (!this.accommodation.vacancy) {
                    this.accommodation.vacancy = new ExtendedVacancy();
                }

                let occupancy = this.accommodation.vacancy.getVacancyForDate(state.date);
                if (occupancy === null) {
                    occupancy = ExtendedVacancy.FILL_UNDEFINED_DAYS_WITH;
                }
                const priceString = this.accommodation.vacancy.getPriceForDate(state.date);
                const priceDiv: HTMLDivElement = state.element.querySelector('.price-tag');
                priceDiv.innerHTML = priceString;
                state.element.classList.add(occupancyToClass[occupancy]);

                if (this.accommodation.vacancy.isArrivalDay(state.date)) {
                    state.element.classList.add('arrival');
                }
                const isArrivalDate = stateDateString === accommodationBookingsDates[stateDateString]?.arrivalDate;

                if (isArrivalDate) {
                    const bookingId = accommodationBookingsDates[stateDateString]?.bookingId;
                    if (bookingId) {
                        state.element.dataset.bookingId = bookingId;
                        state.element.classList.add('booking-start');
                    }
                }
                if (this.accommodation.vacancy.isBookingStart(state.date) || isArrivalDate) {
                    state.element.classList.add('booking-start', 'arrival');
                }
                // state.date ist ein DepartureDate
                const departureDate = stateDateString === accommodationBookingsDates[stateDateString]?.departureDate;
                if (departureDate) {
                    state.element.classList.add('departure');
                    const curStateDay = new Date(state.date);
                    const nextDay = new Date(curStateDay.setDate(curStateDay.getDate() + 1));
                    const nextDayOccupancy = this.accommodation.vacancy.getVacancyForDate(nextDay);
                    if (nextDayOccupancy === 'N') {
                        // wenn der nächste Tag nach dem Abreisetag belegt ist, dann ist dieser Tag auch ein Anreisetag
                        state.element.classList.add('arrival');
                        state.element.dataset.departure = accommodationBookingsDates[stateDateString]?.departureDate;
                    }
                }

                if (this.accommodation.vacancy.isBookingEnd(state.date)) {
                    state.element.classList.add('booking-end');
                }
                /** TODO
                 *  prüfen warum die Klasse no-selection nicht gesetzt wird
                 *  Objekt 2191292, 25. August Eigenbelegung Clara Fall
                 * */
                if (this.accommodation.vacancy.isBookedDay(state.date)) {
                    state.element.classList.add('booking');
                    if (
                        !this.accommodation.vacancy.isArrivalDay(state.date) &&
                        !this.accommodation.vacancy.isDepartureDay(state.date) &&
                        !this.accommodation.vacancy.isBookingStart(state.date) &&
                        !this.accommodation.vacancy.isBookingEnd(state.date)
                    ) {
                        state.element.classList.add('no-selection');
                    }
                }

                if (this.accommodation.vacancy.isDepartureDay(state.date)) {
                    state.element.classList.add('departure', occupancyToClass.N);
                }

                if (this.accommodation.vacancy.isBlockedDayChangeStart(state.date)) {
                    state.element.classList.add('blocked-start');

                    const blockedIcon = document.createElement('i');
                    blockedIcon.classList.add('icon-blocked', 'fa', 'fa-solid', 'fa-ban');

                    const blockedLabel = document.createElement('div');
                    blockedLabel.classList.add('blocked-label');
                    blockedLabel.appendChild(blockedIcon);
                    const textNode = document.createElement('span');
                    textNode.innerText = ' blocked';
                    blockedLabel.appendChild(textNode);
                }

                if (this.accommodation.vacancy.isBlockedDayChangeEnd(state.date)) {
                    state.element.classList.add('blocked-end');
                }

                if (this.accommodation.priceInformation?.dayIsArrivalDay(state.date)) {
                    state.element.classList.add('arrival-day');
                }

                return state.element;
            },

            onDayMouseEnter: (state: State): HTMLTableDataCellElement => {
                if (!state.selectionFinishedElement) {
                    this.bookingInDateRange = false;

                    let message;
                    if (
                        state.element.classList.contains('booking') &&
                        !state.element.classList.contains('arrival') &&
                        !state.element.classList.contains('departure') &&
                        !state.element.classList.contains('booking-start') &&
                        !state.element.classList.contains('booking-end')
                    ) {
                        message = bookedText;
                    } else if (state.selectionStartedElement) {
                        message = popoverStartedText;
                    } else {
                        message = popoverStartText;
                    }

                    if (state.selectionStartedElement) {
                        $('.selecting').removeClass('selecting');

                        const startIndex = state.calendar.getIndexFromId(state.selectionStartedElement.id);
                        const endIndex = state.calendar.getIndexFromId(state.element.id);

                        for (let i = startIndex; i < endIndex; i++) {
                            const id = state.calendar.getDayIdFromIndex(i);
                            const element = document.getElementById(id);
                            if (
                                element.classList.contains('booking') &&
                                !element.classList.contains('arrival') &&
                                !element.classList.contains('departure') &&
                                !element.classList.contains('booking-start') &&
                                !element.classList.contains('booking-end')
                            ) {
                                message = bookedText;
                                this.bookingInDateRange = true;
                                break;
                            }
                        }

                        state.calendar.createRange(startIndex, endIndex, 'selecting');
                    }

                    ($(state.element) as any)
                        .popover({
                            trigger: 'manual',
                            placement: 'top',
                            title: '<a class="close" data-dismiss="alert">&times;</a>',
                            container: 'body',
                            html: true,
                            sanitize: false,
                            content: message,
                        })
                        .popover('show');

                    this.popoverAllowed = true;
                }

                return state.element;
            },

            onDayMouseLeave: (state: State): HTMLTableDataCellElement => {
                if (!state.selectionFinishedElement) {
                    // @ts-ignore
                    $('.popover').popover('dispose');
                }

                return state.element;
            },

            onFinishSelection: (state: State): HTMLTableDataCellElement => {
                const options: any = {
                    dateStyle: 'short',
                };

                const startDate = new Date(state.selectionStartedElement.getAttribute('data-date'));
                const endDate = new Date(state.selectionFinishedElement.getAttribute('data-date'));

                if (this.dateIsBefore(startDate, endDate)) {
                    this.selectedStart = startDate;
                    this.selectedEnd = endDate;
                } else {
                    this.selectedStart = endDate;
                    this.selectedEnd = startDate;
                }
                this.booking = new BookingEntity();
                this.booking.arrivalDate = DateService.getStringFromDate(this.selectedStart);
                this.booking.departureDate = DateService.getStringFromDate(this.selectedEnd);
                this.booking.accommodationId = this.accommodation.accommodationId;

                const placeHolder = this.selectedStart.toLocaleString(locale, options) + ' - ' + this.selectedEnd.toLocaleString(locale, options);
                this.dateRangeHeadLine = dateRangeText.replace('%s', placeHolder);
                const headLineElement = document.getElementById('date-range-headline');
                if (headLineElement) {
                    headLineElement.innerText = this.dateRangeHeadLine;
                }

                // Wenn man hier versucht alle alten Popover auszublenden und ein neuen einzublenen kommt irgendwie ein alter Inhalt, deswegen einfach den Inhalt des bestehenden Popover ändern
                if (this.editable) {
                    this.dialogService.openDialog(
                        FinishSelectionDialogComponent,
                        { dialogWidth: this.dialogService.dialogWidth.M },
                        {
                            booking: this.booking,
                            bookingInDateRange: this.bookingInDateRange,
                            dateRangeHeadLine: this.dateRangeHeadLine,
                            openDialog: this.openBookingDialog.bind(this),
                            cancel: this.cancelSelection.bind(this),
                            stateSet: this.setState.bind(this),
                            fromCalender: true,
                        },
                    );
                } else {
                    this.dialogService.openDialog(
                        NotEditableDialogComponent,
                        { dialogWidth: this.dialogService.dialogWidth.M },
                        {
                            booking: this.booking,
                            openDialog: this.openBookingDialog.bind(this),
                            changeTab: this.changeToTab.bind(this),
                            cancel: this.cancelSelection.bind(this),
                            fromCalender: true,
                        },
                    );
                }

                return state.element;
            },
        });
        // Select Box zum auswählen des Monats (nur Mobil sichtbar)

        $('.occupancy-calendar th').each((index, th) => {
            $('.calendar-footer select').append($('<option>').text(th.innerText).val(th.id));
        });

        $('.calendar-footer select').on('change', (event) => {
            const id = '#' + $(event.target).val();
            const scrollTo = $(id).offset().top;

            $('html, body').animate(
                {
                    scrollTop: scrollTo,
                },
                500,
            );
        });

        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                const advancedButton = document.getElementById('advancedSettingsButton');
                const priceTabFragments = Array.from(document.getElementsByClassName('price-tab-fragment')) as HTMLElement[];
                if (priceTabFragments.length) {
                    priceTabFragments.forEach((priceTabFragment) => {
                        priceTabFragment.addEventListener('click', () => {
                            this.changeToTab('prices');
                        });
                    });
                }

                if (advancedButton) {
                    advancedButton.addEventListener('click', () => {
                        this.ngZone.run(() => {
                            this.dialogService.openDialog(AdvancedSettingsDialogComponent, { dialogWidth: this.dialogService.dialogWidth.L });
                        });
                    });
                }

                if (document.getElementById('toIcalBox')) {
                    document.getElementById('toIcalBox').addEventListener('click', () => {
                        document.getElementById('#icalBox').scrollIntoView({ behavior: 'smooth' });
                    });
                }
            }, 0);
        });
        this.setArrivalAndDepartureDates();
        this.createCustomerLabel();
        this.setHighlightEvent();
    }

    getBookingId(element) {
        const ancestor: HTMLElement = element.closest('td[data-booking-from]');
        const arrivalBookingId: number = Number(ancestor.dataset?.arrivalBookingId);
        const currentBookingId: number = Number(ancestor.dataset.bookingFrom);
        const hightlightBookingId = arrivalBookingId || currentBookingId;
        this.highlightBooking(hightlightBookingId);
    }
    highlightBooking(bookingId: number) {
        const bookedDays: NodeList = document.querySelectorAll(`[data-booking-from="${bookingId}"]`);
        for (let i = 0; i < bookedDays.length; i++) {
            // @ts-ignore
            if (Number(bookedDays[i].dataset.bookingFrom) === bookingId) {
                const dayElement = bookedDays[i];
                // Alle Tage, die zu einer Buchung gehören hervorheben
                // @ts-ignore
                dayElement.classList.add('highlight');
                // @ts-ignore
                const prevTdElement: HTMLElement = dayElement.previousSibling;
                let isOccupied;
                if (!prevTdElement?.dataset?.bookingFrom) {
                    // Den aktuellen Tag als Abschluss hervorheben ('reverse-booking'), wenn dieser nicht zu einer Buchung gehört
                    isOccupied = prevTdElement?.classList.contains('occupied');
                    const isClosed = prevTdElement?.classList.contains('closed');
                    if (isOccupied || isClosed) {
                        // @ts-ignore
                        dayElement.classList.add('reverse-highlight');
                    }
                } else {
                    const prevBookingId = Number(prevTdElement?.dataset?.bookingFrom);
                    const highlightId = bookingId;
                    // Den vorherigen Tag als Abschluss hervorheben ('reverse-booking'), wenn dieser nicht zu der gleichen Buchung gehört
                    if (prevBookingId && prevBookingId !== highlightId) {
                        // @ts-ignore
                        prevTdElement.classList.add('reverse-highlight');
                    }
                }
                // Wenn der aktuelle Tag ein Montag ist und kein 'booking-start', dann prüfe das letzte <td> im <tr> davor
                // @ts-ignore
                if (!dayElement.classList.contains('booking-start') && dayElement.classList.contains('weekday-1')) {
                    const prevTrElement = dayElement.parentElement.previousElementSibling;
                    const sundayElementAsArrival = prevTrElement.querySelector('.weekday-7.arrival');
                    if (sundayElementAsArrival) {
                        // @ts-ignore
                        sundayElementAsArrival.classList.add('reverse-highlight');
                    }
                    // @ts-ignore
                    dayElement.classList.add('reverse-highlight');
                }
            }
        }
    }
    setHighlightEvent() {
        const bookedDays = document.querySelectorAll('[data-booking-from]');
        for (let i = 0; i < bookedDays.length; i++) {
            bookedDays[i].addEventListener('mouseenter', (event) => {
                this.getBookingId(event.target);
            });
            bookedDays[i].addEventListener('mouseleave', () => {
                document.querySelectorAll('.highlight').forEach((element) => {
                    element.classList.remove('highlight');
                });
                document.querySelectorAll('.reverse-highlight').forEach((element) => {
                    element.classList.remove('reverse-highlight');
                });
            });
        }
    }
    async setArrivalAndDepartureDates() {
        if (this.bookings?.length) {
            // checke alle Buchungen für dieses Objekt
            for (const booking of this.bookings) {
                const dateString = this.dateService.getFormattedDate(booking.arrivalDate, 'en-US', false);
                const dateService = new DateService();
                const duration = dateService.getDayDiff(booking.arrivalDate, booking.departureDate);
                // hole den Kalendertag für das Datum
                const mapEntry = this.calendar.calendarDateIndexMap.get(dateString);
                // nur wenn die Buchung im Kalender zu finden ist
                if (mapEntry) {
                    const { dayIndex } = mapEntry;
                    const calendarDisplay = this.el.nativeElement.querySelector('#calendar-display');
                    if (dateString) {
                        const dateElement = calendarDisplay.querySelector('#day-' + dayIndex);
                        dateElement.dataset.arrivalBookingId = booking.bookingId;
                        if (dateElement) {
                            dateElement.classList.add('arrival');
                            const prevElement = calendarDisplay.querySelector('#day-' + (dayIndex - 1));
                            // Setze den Abreisetag, wenn der Vortag belegt ist
                            if (prevElement && prevElement.classList.contains('occupied') && !prevElement.classList.contains('free')) {
                                dateElement.classList.add('departure');
                            }
                            // markiere den Abreisetag anhand der Duration
                            if (duration) {
                                for (let i = 0; i <= duration; i++) {
                                    const nextElement = calendarDisplay.querySelector('#day-' + (dayIndex + i));
                                    if (nextElement) {
                                        nextElement.dataset.bookingFrom = booking.bookingId;
                                    }
                                }

                                const nextElement = calendarDisplay.querySelector('#day-' + (dayIndex + duration));
                                if (nextElement) {
                                    nextElement.classList.add('departure', 'Abreisetag');
                                    nextElement.dataset.departureBookingId = booking.bookingId;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private updateIcalLink() {
        this.icalUrl = environment.apiUrl + '/ical/export/' + this.accommodation.accommodationId + '/ical.ics?securitytoken=' + this.accommodation.vacancy.securityToken;
    }

    async updateCalendarDate(): Promise<void> {
        await this.apiConnectorService.updateVacancyDate(this.accommodation);
    }

    showMore() {
        this.monthsToShow = this.fullMonthsToShow;
        this.initCalendar();
        this.buttonClicked = true;
        document.querySelector('.show-more').classList.add('hide');
    }

    dateIsBefore(pickedDate: Date, compareDate: Date): boolean {
        return pickedDate <= compareDate;
    }

    async setState(eventValue: string): Promise<void> {
        if (eventValue && this.selectedStart && this.selectedEnd) {
            const value: any = eventValue;

            if (!this.accommodation.vacancy) {
                this.accommodation.vacancy = new ExtendedVacancy();
            }

            // Sowohl in OrignalVacancy als auch Vacancystring setzen
            this.accommodation.vacancy.setVacancyForDateRange(value, this.selectedStart, this.selectedEnd, 'originalVacancy');
            this.accommodation.vacancy.setVacancyForDateRange(value, this.selectedStart, this.selectedEnd, 'vacancyString');

            this.selectedStart = null;
            this.selectedEnd = null;

            await this.initCalendar();
            if (this.authenticationService.currentUser) {
                await this.saveAccommodation();
            }
        }
    }

    async getNewIcalToken() {
        const token = await this.apiConnectorService.getNewIcalToken(this.accommodation.accommodationId);

        if (!this.accommodation.vacancy) {
            this.accommodation.vacancy = new ExtendedVacancy();
        }

        this.accommodation.vacancy.securityToken = token;
        this.accommodationOriginal.vacancy.securityToken = token;

        this.updateIcalLink();
    }

    async copyUrlToClipboard() {
        await navigator.clipboard.writeText(this.icalUrl);
        this.copyClipboard = true;
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.ngZone.run(() => {
                    this.copyClipboard = false;
                });
            }, 3000);
        });
    }

    async saveAccommodation() {
        await this.apiConnectorService.saveAccommodationOrGroupForTab(this.route, this.accommodation);
        this.dialogService.closeDialog();

        // Gordon: Wenn ein Kalender gelöscht wird soll NICHT upgedatet werden. VRMB-3313
        if (this.updateCalendar) {
            await this.updateIcalCalendar();
        }
    }

    // Wenn man das nicht macht, verliert das input feld immer nach einem buchstaben den Focus. Das passiert wenn man ein <input mit *ngFor und ngModel benutzt
    // https://stackoverflow.com/questions/50139508/input-loses-focus-when-editing-value-using-ngfor-and-ngmodel-angular5
    trackByFn(index) {
        return index;
    }

    openConfirmationDialog(i: number) {
        this.confirmationDialogService
            .confirm('atraveo.accommodationbundle.price.delete.confirmDeleteSinglePrice', 'confirm.content.delete.0')
            .then(async (confirmed) => {
                if (confirmed) {
                    this.accommodation.vacancy.importUrls.splice(i, 1);
                    await this.saveAccommodation();
                }
            })
            .catch(() => undefined);
    }

    async updateIcalCalendar() {
        await this.apiConnectorService.updateIcalCalendar(this.accommodation.accommodationId);
    }

    async cancelSelection() {
        this.selectedStart = null;
        this.selectedEnd = null;
        await this.initCalendar();
    }

    changeToTab(tab: string) {
        this.dialogService.closeDialog();
        return this.router.navigate(['/accommodation/' + this.accommodation.accommodationId + '/' + tab]);
    }

    canDeactivate(): Observable<boolean> | boolean {
        return !$('.popover').is(':visible') && isEqual(this.accommodation, this.accommodationOriginal);
    }
    createCustomerLabel() {
        const arrivalDays = document.querySelectorAll('[data-arrival-booking-id]');
        const departureDays = document.querySelectorAll('[data-departure-booking-id]');
        if (arrivalDays.length && departureDays.length) {
            for (let index = 0; index < arrivalDays.length; index++) {
                const arrivalDay: HTMLElement = <HTMLElement>arrivalDays[index];
                const arrivalIndex = Number(arrivalDay?.id.substring(4));
                const departureIndex = Number(departureDays[index]?.id.substring(4));
                const customerLabel = document.createElement('div');

                // Setze den customer start index in die Mitte zwischen arrival und departure
                let customerStartIndex = Math.floor((arrivalIndex + departureIndex) / 2);
                const duration = departureIndex - arrivalIndex;
                customerStartIndex = arrivalIndex;
                /* genügend Platz, lass erste Zelle frei*/
                // if (duration > 3) {
                //     customerStartIndex = arrivalIndex;
                // }
                let customerStartElement = document.getElementById(`day-${customerStartIndex}`);
                const parentTr = customerStartElement.parentElement;
                const nextMonthDays = parentTr.querySelectorAll('.next-month');
                let firstNextMonthDayWeekDay = 7;
                if (nextMonthDays.length) {
                    // @ts-ignore
                    firstNextMonthDayWeekDay = nextMonthDays[0]?.dataset.weekday;
                }
                const weekday = Number(customerStartElement?.dataset.weekday);

                const restWeekLength = firstNextMonthDayWeekDay - weekday;
                // wenn der Rest der Woche kleiner ist als die Hälfte der Duration, dann setze den Kundennamen in die nächste Zeile
                // 6 - 5 < 5 / 2
                let useNextRow = firstNextMonthDayWeekDay - weekday < duration / 2 || firstNextMonthDayWeekDay - weekday <= duration - restWeekLength;
                //  nextDeyIsNextMonth
                if (!useNextRow && document.querySelector(`#day-${customerStartIndex} + td.next-month`)) {
                    useNextRow = true;
                }
                let restDuration = duration - restWeekLength;
                const longStay = duration >= 14;
                /*begrenze das customer label auf die Breite der duration*/
                let widthClassName = `hundred-${duration - 1}`;
                // Wenn die Duration über 14 Tage geht, ist in der nächsten Zeile, das erste TD, der beste Platz für den Kundennamen
                if (longStay || useNextRow) {
                    const nextRowDayIndex = arrivalIndex + restWeekLength + 1;

                    const nextRowDay = document.querySelector('#day-' + nextRowDayIndex);
                    if (nextRowDay) {
                        // 8. Tag tr td:first:child
                        const nextTr = nextRowDay.parentElement;
                        customerStartElement = nextTr.querySelector('td:first-child');
                        const nxtMonthdays = nextTr.querySelectorAll('td.next-month');
                        if (nxtMonthdays.length > 5) {
                            // nur ein tag übrig
                            const nextDayIndex = Number(customerStartElement.id.substring(4)) + 1;
                            if (restDuration >= 3) customerStartElement = document.getElementById(`day-${nextDayIndex}`);
                        }
                        // Wenn der nächste Montag zu dem vorherigen Monat gehört
                        if (customerStartElement.classList.contains('previous-month')) {
                            // suche die nächsten belegten Tag in der Tabellereihe
                            const firstPossibleDay = nextTr.querySelectorAll('td.occupied');
                            // @ts-ignore
                            const currentWeekday = Number(firstPossibleDay[0].dataset.weekday);
                            restDuration -= currentWeekday;
                            // nutze die erste Zelle als Start
                            customerStartElement = firstPossibleDay[0] as HTMLElement;
                        }
                        customerStartElement.classList.add(useNextRow && restDuration < 7 ? 'short-week' : 'long-stay');
                        if (restDuration < 7) {
                            widthClassName = `hundred-${Math.max(restDuration - 1, 1)}`;
                        }
                        customerStartIndex = Number(customerStartElement.id.substring(4));
                    }
                } else if (duration <= 3 && weekday > 5) {
                    // Abreise Samstag oder Sonntag
                    customerStartElement.classList.add('short-duration');
                } else if (weekday === 4 && duration === 3) {
                    customerStartElement.classList.add('doso');
                } else if (weekday === 5 && restDuration <= 2) {
                    widthClassName = `hundred-1`;
                } else {
                    const nextDayIndex = arrivalIndex + 2;
                    const parentTr = customerStartElement.parentElement;
                    const nextMonthSibling: HTMLElement = parentTr.querySelector('.next-month');
                    /* ist in dieser Reihe Monatsende? */
                    if (nextMonthSibling) {
                        const siblingWeekday = Number(nextMonthSibling.dataset.weekday);
                        if (siblingWeekday - weekday < 3) {
                            customerStartElement.classList.add('short-duration');
                        }
                    }
                }

                if (customerStartElement) {
                    customerStartElement.classList.add('customer-start');
                    customerStartElement.appendChild(customerLabel);
                    customerLabel.classList.add('customer-label');
                    customerLabel.innerText = '';
                }
                customerLabel.classList.add(widthClassName);

                // Customer Ausgabe
                if (customerStartElement) {
                    // @ts-ignore
                    const bookingId = arrivalDay.dataset.arrivalBookingId;
                    const bookingEntity = this.bookings.find((item) => item.bookingId === Number(bookingId));
                    if (bookingEntity?.customer?.surname || bookingEntity?.customer?.forename) {
                        const forName = bookingEntity?.customer?.forename ?? '';
                        let name = forName;
                        if (bookingEntity.customer?.surname) {
                            name += ' ' + bookingEntity.customer?.surname;
                        }
                        customerLabel.title = name;
                        if (duration > 1) {
                            const span = document.createElement('span');
                            customerLabel.appendChild(span);
                            customerLabel.classList.add('filled');
                            span.innerHTML = `${name}`;
                        }
                        customerLabel.setAttribute('data-toggle', 'modal');
                        customerLabel.addEventListener('click', () => {
                            this.cancelSelection();
                            this.booking = bookingEntity;
                        });
                        this.renderer.listen(customerLabel, 'click', () => {
                            this.dialogService.openDialog(
                                BookingViewComponent,
                                { dialogWidth: this.dialogService.dialogWidth.M },
                                {
                                    booking: this.booking,
                                    id: 'bookingView',
                                },
                            );
                        });
                    } else {
                        customerStartElement.removeChild(customerLabel);
                    }
                    if (bookingEntity?.bookingNumber?.toString().substring(0, 4) === 'ERCH') {
                        customerLabel.classList.add('erch');
                        const i = document.createElement('i');
                        i.classList.add('icon-erch', 'icon');
                        if (duration === 1) {
                            customerLabel.innerHTML = '&nbsp;';
                        }
                        customerLabel.appendChild(i);
                    }
                }
            }
        }
    }
    getLatestInvalidUrlsList(): string[] {
        return this.invalidUrlsList;
    }
    openDialog() {
        this.dialogService.openDialog(
            IcalDialogComponent,
            { dialogWidth: this.dialogService.dialogWidth.M },
            {
                accommodation: this.accommodation,
                save: (icalUrls) => {
                    this.updateCalendar = false;
                    for (const icalUrl of icalUrls) {
                        if (!this.accommodation.vacancy.importUrls.find((url) => url.url === icalUrl.url)) {
                            this.updateCalendar = true;
                        }
                    }
                    this.accommodation.vacancy.importUrls = icalUrls;
                    this.saveAccommodation();
                },
            },
        );
    }

    openBookingDialog(any) {
        this.dialogService.openDialog(
            CreateBookingComponent,
            { dialogWidth: this.dialogService.dialogWidth.M },
            {
                newOrEditBooking: this.booking,
                fromCalender: true,
            },
        );
    }
}
