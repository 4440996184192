import { Component, OnInit } from '@angular/core';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { OwnerConnectorService } from '../../../../../services/api-connector/owner-connector.service';
import { DateService } from '../../../../../services/date/date.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../../environments/environment';

@Component({
    selector: 'app-status-quo',
    templateUrl: './status-quo.component.html',
    styleUrls: ['./status-quo.component.scss', './../dashboard.component.scss'],
})
export class StatusQuoComponent implements OnInit {
    countOnlineAccommodations: number;
    lastChangedAccommodation: number;
    lastVacancyChangeDate: Date;
    showCommission: boolean = false;

    constructor(
        readonly apiConnector: ApiConnectorService,
        readonly authService: AuthenticationService,
        readonly ownerConnector: OwnerConnectorService,
        readonly translationService: TranslateService,
    ) {}

    async ngOnInit() {
        const userData = await this.ownerConnector.getUserData(this.authService.currentUser.ownerNumber);

        if (userData.state?.lastVacancyChange?.accommodationId && userData.state?.lastVacancyChange?.changeDate) {
            this.lastChangedAccommodation = userData.state?.lastVacancyChange?.accommodationId;
            this.lastVacancyChangeDate = DateService.getDateFromString(userData.state.lastVacancyChange?.changeDate);
        }

        this.countOnlineAccommodations = await this.apiConnector.getOnlineAccommodationCount(this.authService.currentUser);

        this.showCommission = environment.showDiscountAndCommissionForOwners.includes(this.authService.currentUser?.ownerNumber);
    }
}
