<table class="table responsive-table offers" *ngIf="accommodation">
    <thead>
        <th>{{ 'atraveo.accommodationbundle.price.offer.field.offerType' | translate }}</th>
        <th>{{ 'atraveo.accommodationbundle.price.offer.field.validityPeriod' | translate }}</th>
        <th>{{ 'atraveo.accommodationbundle.price.offer.field.discount' | translate }}</th>
        <th></th>
    </thead>
    <tr *ngFor="let offer of accommodation.offers; index as i" id="{{ i }}">
        <td *ngIf="offer.typeId != 'specialOffer'" [attr.data-label]="'atraveo.accommodationbundle.price.offer.field.offerType' | translate">
            <span *ngIf="offer.typeId !== 'longStay'">
                {{ 'atraveo.accommodationbundle.price.create.offer.field.offerType.' + offer.typeId | translate }}
            </span>
            <span *ngIf="offer.typeId === 'longStay'">
                {{ 'atraveo.accommodationbundle.price.create.offer.field.offerType.longTravelPeriod' | translate }}
            </span>
        </td>
        <td *ngIf="offer.typeId == 'specialOffer'" [attr.data-label]="'atraveo.accommodationbundle.price.create.offer.field.offerType.offer' | translate">
            {{ 'atraveo.accommodationbundle.price.create.offer.field.offerType.offer' | translate }}
        </td>
        <td [attr.data-label]="'atraveo.accommodationbundle.price.offer.field.validityPeriod' | translate">
            <p *ngIf="offer?.offerPeriod?.from || offer?.offerPeriod?.to">
                {{ 'atraveo.accommodationbundle.price.create.offer.validFor.bookingDate' | translate }}:
                <br />
                {{ offer.offerPeriod.from | stringToDate | date: 'shortDate':'':translateService.currentLang }} &rarr;
                {{ offer.offerPeriod.to | stringToDate | date: 'shortDate':'':translateService.currentLang }}
            </p>

            <!-- Days before arrival-->
            <p
                *ngIf="
                    offer &&
                    offer.typeId != 'bonusDays' &&
                    !(offer.daysBeforeArrival.min == null && offer.daysBeforeArrival.max == null) &&
                    !(offer.daysBeforeArrival.min == null && offer.daysBeforeArrival.max == 1) &&
                    !(offer.daysBeforeArrival.min == 1 && offer.daysBeforeArrival.max == null) &&
                    !(offer.daysBeforeArrival.min == 1 && offer.daysBeforeArrival.max == 1)
                "
            >
                {{ offer | daysBeforeArrival: this.language | async }}
            </p>

            <!-- Limitations -->
            <p *ngFor="let limitation of this.limitations | keyvalue">
                <span *ngIf="offer[limitation.key]['min'] && !offer[limitation.key]['max']">
                    {{ 'atraveo.accommodationbundle.price.list.limitations.' + limitation.value + 'From' | translate }}
                    {{ offer[limitation.key]['min'] }}
                    {{ 'atraveo.accommodationbundle.price.list.limitations.' + limitation.value + '.suffix' | translate }}
                    .<br />
                </span>
                <span *ngIf="!offer[limitation.key]['min'] && offer[limitation.key]['max']">
                    {{ 'atraveo.accommodationbundle.price.list.limitations.' + limitation.value + 'To' | translate }}
                    {{ offer[limitation.key]['max'] }}
                    {{ 'atraveo.accommodationbundle.price.list.limitations.' + limitation.value + '.suffix' | translate }}
                    .<br />
                </span>
                <span *ngIf="offer[limitation.key]['min'] && offer[limitation.key]['max']">
                    {{ 'atraveo.accommodationbundle.price.list.limitations.' + limitation.value + '.prefix' | translate }}
                    {{ offer[limitation.key]['min'] }}
                    {{ 'atraveo.accommodationbundle.price.list.limitations.' + limitation.value + '.glue' | translate }}
                    {{ offer[limitation.key]['max'] }}
                    {{ 'atraveo.accommodationbundle.price.list.limitations.' + limitation.value + '.suffix' | translate }}
                    .<br />
                </span>
            </p>

            <p *ngIf="offer && offer.travelPeriod?.from && offer.travelPeriod?.to">
                {{ 'atraveo.accommodationbundle.price.list.limitations.Date.prefix' | translate }}
                <br />
                {{ offer.travelPeriod.from | stringToDate | date: 'dd.MM.yyyy' }}
                {{ 'atraveo.accommodationbundle.price.list.limitations.Date.glue' | translate }}
                {{ offer.travelPeriod.to | stringToDate | date: 'dd.MM.yyyy' }}
            </p>
        </td>
        <td [attr.data-label]="'atraveo.accommodationbundle.price.offer.field.discount' | translate">
            <p *ngIf="offer.newPrice">
                {{ offer.newPrice | currency: ownerCurrency }}
                {{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.' + offer.priceType | translate }}
                <span *ngIf="offer.pricePerPerson">
                    {{ 'atraveo.frontendbundle.form.type.perBookingOrPersons.1' | translate }}
                </span>
                <span *ngIf="!offer.pricePerPerson">
                    {{ 'atraveo.frontendbundle.form.type.perBookingOrPersons.0' | translate }}
                </span>
            </p>
            <p *ngIf="offer.daysToBook && offer.daysToPay">
                {{ 'atraveo.accommodationbundle.price.create.offer.offerType.bonusDays.prefix' | translate }}
                {{ offer.daysToBook }}
                {{ 'atraveo.accommodationbundle.price.create.offer.offerType.bonusDays.glue' | translate }}
                {{ offer.daysToPay }}
                {{ 'atraveo.accommodationbundle.price.create.offer.offerType.bonusDays.suffix' | translate }}
                <span *ngIf="offer.calcType == 1">
                    {{ 'atraveo.accommodationbundle.price.create.offer.offerType.bonusDays.calculation.lowestDays' | translate }}
                </span>
            </p>
            <p *ngIf="offer.absoluteDiscount">- {{ offer.absoluteDiscount | currency: ownerCurrency }}</p>
            <p *ngIf="offer.percentDiscount">- {{ offer.percentDiscount }}%</p>
            <div *ngIf="this.accommodation.checkResults?.length">
                <ng-container *ngFor="let check of this.accommodation.checkResults">
                    <p *ngIf="[15, 36, 58].includes(check.checkId)" [innerHTML]="'atraveo.accommodationbundle.check.error.' + check.checkId.toString() | translate"></p>
                </ng-container>
            </div>
        </td>
        <td class="small">
            <a (click)="editOffer(i)"><i class="fas fa-pen"></i></a>
            <a (click)="this.cancelDeleteMultiple()" class="btn btn-secondary float-right d-none">{{ 'button.cancel' | translate }}</a>
            <a (click)="openConfirmationDialog(i)">
                <i class="fas fa-trash-alt ml-2"></i>
            </a>
        </td>
    </tr>
</table>

<div class="row">
    <div class="col-lg-6 col-12 mb-2">
        <button id="create-offer" class="btn btn-secondary" (click)="createSpecialOffer(-1)">
            <i class="fa fa-plus"></i>
            {{ 'atraveo.accommodationbundle.price.offer.field.add-offer' | translate }}
        </button>
    </div>
    <div class="col-lg-6 col-12">
        <span class="delete-explanation d-none">{{ 'atraveo.accommodationbundle.price.delete.deleteMultiple' | translate }}</span>
        <button id="abort-delete-offers" (click)="this.cancelDeleteMultiple()" class="btn btn-primary float-right d-none">{{ 'button.cancel' | translate }}</button>

        <button class="btn btn-primary float-right" id="delete-multiple-offers" (click)="this.deleteMultiple()">
            {{ 'atraveo.accommodationbundle.offer.delete.deleteMultiple' | translate }}
        </button>
    </div>
</div>
