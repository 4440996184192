<div class="modal-content">
    <form id="specialOfferForm">
        <div class="modal-header">
            <h5 class="modal-title">{{ 'atraveo.accommodationbundle.price.create.offer.title' | translate }}</h5>
            <button class="close" (click)="closeDialog()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row form-group">
                <label for="reductionType" class="col-sm-3 col-form-label"
                ><strong class="selectText">{{ 'atraveo.accommodationbundle.price.create.offer.field.offerType.0' | translate }}:</strong>
                </label>
                <div class="col-sm-5">
                    <select id="reductionType" class="form-control" name="price-reduction" [(ngModel)]="offer.typeId" (change)="isSubmitted = false;">
                        <option [selected]="!offer.typeId" value="" hidden>{{ 'text.select.empty' | translate }}</option>
                        <option value="lastMinute">{{ 'atraveo.accommodationbundle.price.create.offer.field.offerType.lastMinute' | translate }}</option>
                        <option value="earlyBird">{{ 'atraveo.accommodationbundle.price.create.offer.field.offerType.earlyBird' | translate }}</option>
                        <option value="bonusDays">{{ 'atraveo.accommodationbundle.price.create.offer.field.offerType.bonusDays' | translate }}</option>
                        <option value="specialPrice">{{ 'atraveo.accommodationbundle.price.create.offer.field.offerType.specialPrice' | translate }}</option>
                        <option value="longStay">{{ 'atraveo.accommodationbundle.price.create.offer.field.offerType.longTravelPeriod' | translate }}</option>
                        <option value="specialOffer">{{ 'atraveo.accommodationbundle.price.create.offer.field.offerType.offer' | translate }}</option>
                    </select>
                </div>
                <div class="col-sm-2">
                    <app-tooltip [additionalClassList]="'mr-2 price-reduction-info hide'" [text]="'info.eingeschraenktessonderangebot' | translate"></app-tooltip>
                </div>
            </div>
            <div class="row form-group form-section lastMinute">
                <label class="col-sm-12 col-form-label"
                ><strong>{{ 'atraveo.accommodationbundle.price.create.offer.field.validForBookingPeriod.0' | translate }}</strong></label
                >
                <div class="row form-group col-sm-12">
                    <div class="col-sm-12 offer-radio">
                        <label class="radio-label">
                            <input type="radio" name="lastMinuteBookingPeriod" class="form-control bookingPeriod" checked (change)="hideTravelPeriod($event)" />{{
                                'atraveo.accommodationbundle.price.create.offer.longTravelPeriod.longTravelPeriodBookingPeriod.none' | translate
                            }}
                        </label>
                    </div>
                </div>
                <div class="row form-group col-sm-12">
                    <div class="col-sm-3 offer-radio">
                        <label class="radio-label">
                            <input type="radio" id="lastMinute-radio-info" name="lastMinuteBookingPeriod" class="form-control bookingPeriodTravel" (change)="showTravelPeriod($event)" />{{
                                'atraveo.accommodationbundle.price.create.offer.lastMinute.lastMinuteBookingPeriod.date.prefix' | translate
                            }}
                        </label>
                    </div>
                    <div class="col-sm-3 offer-radio-input">
                        <input
                            type="date"
                            name="lastMinuteDateFrom"
                            class="form-control dateFrom"
                            disabled="disabled"
                            [required]="offer.typeId === 'lastMinute'"
                            [ngModel]="offer.travelPeriod?.from"
                            (ngModelChange)="offer.travelPeriod.from = $event;"
                            (change)="checkDates();"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <span *ngIf="isSubmitted && offer.typeId === 'lastMinute' && !offer.travelPeriod?.from" class="error">
                        {{ 'This value should not be blank.' | translate }}
                    </span>
                    </div>
                    <p class="col-sm-2 offer-radio-input-label">{{ 'atraveo.accommodationbundle.price.create.period.field.dateTo' | translate }}</p>
                    <div class="col-sm-3 offer-radio-input">
                        <input
                            type="date"
                            name="lastMinuteDateTo"
                            class="form-control dateTo"
                            disabled="disabled"
                            [required]="offer.typeId === 'lastMinute'"
                            [ngModel]="offer.travelPeriod?.to"
                            (ngModelChange)="offer.travelPeriod.to = $event;"
                            (change)="checkDates();"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <span *ngIf="isSubmitted && offer.typeId === 'lastMinute' && !offer.travelPeriod?.to" class="error">
                        {{ 'This value should not be blank.' | translate }}
                    </span>
                    </div>
                    <div class="col-sm-12 offer-radio-info lastMinute-radio-info hide">
                        <p>{{ 'info.occupancyHowComplete' | translate }}</p>
                    </div>
                </div>
                <div class="row form-group col-sm-12">
                    <label class="col-sm-3 col-form-label required"
                    ><strong>{{ 'atraveo.accommodationbundle.price.create.offer.lastMinute.step2' | translate }}</strong></label
                    >
                    <div class="col-sm-2 percentage-discount-arrival">
                        <select class="form-control" name="lastMinuteDiscountPercent" [(ngModel)]="offer.percentDiscount" [ngModelOptions]="{ standalone: true }" [required]="offer.typeId === 'lastMinute' && !offer?.percentDiscount">
                            <ng-container *ngFor="let in of optionCounter(100); let i = index">
                                <option *ngIf="i > 4" [ngValue]="i">{{ i }} %</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-sm-6 nights-before-arrival">
                        <p class="col-sm-3">{{ 'atraveo.accommodationbundle.price.create.offer.lastMinute.lastMinuteBookingPeriod.days.prefix' | translate }}</p>
                        <select class="form-control col-sm-4" name="lastMinuteNightsBeforeArival" [(ngModel)]="offer.daysBeforeArrival.min" [ngModelOptions]="{ standalone: true }" [required]="offer.typeId === 'lastMinute' && !offer?.daysBeforeArrival?.min">
                            <ng-container *ngFor="let durationEntry of durationChoices">
                                <option [ngValue]="durationEntry.days" [selected]="durationEntry.days === offer.daysBeforeArrival.min">{{ durationEntry.text }}</option>
                            </ng-container>
                        </select>
                        <p class="col-sm-4">
                            <label class="required only-field"></label>
                            {{ 'atraveo.accommodationbundle.price.create.offer.lastMinute.lastMinuteBookingPeriod.days.suffix' | translate }}
                        </p>
                    </div>
                    <span *ngIf="isSubmitted && offer.typeId === 'lastMinute' && (!offer?.daysBeforeArrival?.min || !offer?.percentDiscount)" class="col-sm-12 error">
                    {{ 'This value should not be blank.' | translate }}
                </span>
                </div>
            </div>
            <div class="row form-group form-section earlyBird hide">
                <label class="col-sm-12 col-form-label"
                ><strong>{{ 'atraveo.accommodationbundle.price.create.offer.field.validForBookingPeriod.0' | translate }}</strong></label
                >
                <div class="row form-group col-sm-12">
                    <div class="col-sm-12 offer-radio">
                        <label class="radio-label">
                            <input type="radio" name="earlyBirdBookingPeriod" class="form-control bookingPeriod" checked (change)="hideTravelPeriod($event)" />{{
                                'atraveo.accommodationbundle.price.create.offer.longTravelPeriod.longTravelPeriodBookingPeriod.none' | translate
                            }}
                        </label>
                    </div>
                </div>
                <div class="row form-group col-sm-12">
                    <div class="col-sm-3 offer-radio">
                        <label class="radio-label">
                            <input type="radio" id="earlyBird-radio-info" name="earlyBirdBookingPeriod" class="form-control bookingPeriodTravel" (change)="showTravelPeriod($event)" />{{
                                'atraveo.accommodationbundle.price.create.offer.earlyBird.earlyBirdBookingPeriod.date.prefix' | translate
                            }}
                        </label>
                    </div>
                    <div class="col-sm-3 offer-radio-input">
                        <input
                            type="date"
                            name="earlyBirdDateFrom"
                            class="form-control dateFrom"
                            disabled="disabled"
                            [required]="offer.typeId === 'earlyBird'"
                            [ngModel]="offer.travelPeriod?.from"
                            (ngModelChange)="offer.travelPeriod.from = $event;"
                            (change)="checkDates();"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <span *ngIf="isSubmitted && offer.typeId === 'earlyBird' && !offer.travelPeriod?.from" class="error">
                        {{ 'This value should not be blank.' | translate }}
                    </span>
                    </div>
                    <p class="col-sm-2 offer-radio-input-label">{{ 'atraveo.accommodationbundle.price.create.period.field.dateTo' | translate }}</p>
                    <div class="col-sm-3 offer-radio-input">
                        <input
                            type="date"
                            name="earlyBirdDateTo"
                            class="form-control dateTo"
                            disabled="disabled"
                            [required]="offer.typeId === 'earlyBird'"
                            [ngModel]="offer.travelPeriod?.to"
                            (ngModelChange)="offer.travelPeriod.to = $event;"
                            (change)="checkDates();"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <span *ngIf="isSubmitted && offer.typeId === 'earlyBird' && !offer.travelPeriod?.to" class="error">
                        {{ 'This value should not be blank.' | translate }}
                    </span>
                    </div>
                    <div class="col-sm-12 offer-radio-info earlyBird-radio-info hide">
                        <p>{{ 'info.occupancyHowComplete' | translate }}</p>
                    </div>
                </div>
                <div class="row form-group col-sm-12">
                    <label class="col-sm-3 col-form-label required"
                    ><strong>{{ 'atraveo.accommodationbundle.price.create.offer.earlyBird.step2' | translate }}</strong></label
                    >
                    <div class="col-sm-2 percentage-discount-arrival">
                        <select class="form-control" name="earlyBirdDiscountPercent" [(ngModel)]="offer.percentDiscount" [ngModelOptions]="{ standalone: true }" [required]="offer.typeId === 'earlyBird' && !offer?.percentDiscount">
                            <ng-container *ngFor="let in of optionCounter(100); let i = index">
                                <option *ngIf="i > 4" [ngValue]="i">{{ i }} %</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-sm-6 nights-before-arrival">
                        <p class="col-sm-3">{{ 'atraveo.accommodationbundle.price.create.offer.earlyBird.earlyBirdBookingPeriod.days.prefix' | translate }}</p>
                        <select class="form-control col-sm-4" name="earlyBirdNightsBeforeArival" [(ngModel)]="offer.daysBeforeArrival.max" [ngModelOptions]="{ standalone: true }" [required]="offer.typeId === 'earlyBird' && !offer?.daysBeforeArrival?.max">
                            <ng-container *ngFor="let durationEntry of durationChoices">
                                <option [ngValue]="durationEntry.days" [selected]="durationEntry.days === offer.daysBeforeArrival.max">{{ durationEntry.text }}</option>
                            </ng-container>
                        </select>
                        <p class="col-sm-4">
                            <label class="required only-field"></label>
                            {{ 'atraveo.accommodationbundle.price.create.offer.earlyBird.earlyBirdBookingPeriod.days.suffix' | translate }}
                        </p>
                    </div>
                    <span *ngIf="isSubmitted && offer.typeId === 'earlyBird' && (!offer?.daysBeforeArrival?.max || !offer?.percentDiscount)" class="col-sm-12 error">
                    {{ 'This value should not be blank.' | translate }}
                </span>
                </div>
            </div>
            <div class="row form-group form-section bonusDays hide">
                <label class="col-sm-12 col-form-label"
                ><strong>{{ 'atraveo.accommodationbundle.price.create.offer.field.validForBookingPeriod.0' | translate }}</strong></label>
                <div class="row form-group col-sm-12">
                    <div class="col-sm-3 offer-radio">
                        <label class="radio-label">
                            <input type="radio" id="bonusDays-radio-info" name="bonusDaysBookingPeriod" class="form-control bookingPeriodTravel" [checked]="offer?.travelPeriod?.from && offer?.travelPeriod?.to" (change)="showTravelPeriod($event)" />{{
                                'atraveo.accommodationbundle.price.create.offer.bonusDays.bonusDaysBookingPeriod.date.prefix' | translate
                            }}
                        </label>
                    </div>
                    <div class="col-sm-3 offer-radio-input">
                        <input
                            type="date"
                            name="bonusDaysDateFrom"
                            class="form-control dateFrom"
                            [required]="offer.typeId === 'bonusDays'"
                            [ngModel]="offer.travelPeriod?.from"
                            (ngModelChange)="offer.travelPeriod.from = $event;"
                            (change)="checkDates();"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <span *ngIf="isSubmitted && offer.typeId === 'bonusDays' && !offer.travelPeriod?.from" class="error">
                        {{ 'This value should not be blank.' | translate }}
                    </span>
                    </div>
                    <p class="col-sm-2 offer-radio-input-label">{{ 'atraveo.accommodationbundle.price.create.period.field.dateTo' | translate }}</p>
                    <div class="col-sm-3 offer-radio-input">
                        <input
                            type="date"
                            name="bonusDaysDateTo"
                            class="form-control dateTo"
                            [required]="offer.typeId === 'bonusDays'"
                            [ngModel]="offer.travelPeriod?.to"
                            (ngModelChange)="offer.travelPeriod.to = $event;"
                            (change)="checkDates();"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <span *ngIf="isSubmitted && offer.typeId === 'bonusDays' && !offer.travelPeriod?.to" class="error">
                        {{ 'This value should not be blank.' | translate }}
                    </span>
                    </div>
                    <div class="col-sm-12 offer-radio-info bonusDays-radio-info hide">
                        <p [innerHTML]="'info.occupancyHowArrival' | translate"></p>
                    </div>
                </div>
                <label class="col-sm-3 col-form-label"
                ><strong>{{ 'atraveo.accommodationbundle.price.create.offer.bonusDays.bonusDaysPayForDays.title' | translate }}</strong></label
                >
                <div class="col-sm-12 discount-amount">
                    {{ 'atraveo.accommodationbundle.price.create.offer.field.payForDays.prefix' | translate }}
                    <select class="col-sm-1 form-control" [(ngModel)]="offer.daysToBook" [ngModelOptions]="{ standalone: true }" [required]="offer.typeId === 'bonusDays' && !offer?.daysToBook">
                        <ng-container *ngFor="let in of optionCounter(32); let i = index">
                            <option *ngIf="i > 1" [ngValue]="i">{{ i }}</option>
                        </ng-container>
                    </select><label class="required only-field"></label>
                    {{ 'atraveo.accommodationbundle.price.create.offer.field.payForDays.glue' | translate }}
                    <select class="col-sm-1 form-control" [(ngModel)]="offer.daysToPay" [ngModelOptions]="{ standalone: true }" [required]="offer.typeId === 'bonusDays' && !offer?.daysToPay">
                        <ng-container *ngFor="let in of optionCounter(32); let i = index">
                            <option *ngIf="i > 0" [ngValue]="i">{{ i }}</option>
                        </ng-container>
                    </select><label class="required only-field"></label>
                    {{ 'atraveo.accommodationbundle.price.create.offer.field.payForDays.suffix' | translate }}
                </div>
                <span *ngIf="isSubmitted && offer.typeId === 'bonusDays' && (!offer?.daysToBook || !offer?.daysToPay)" class="col-sm-12 error-box error">
                {{ 'This value should not be blank.' | translate }}
            </span>
                <div class="col-sm-12 discount-amount">
                    {{ 'atraveo.accommodationbundle.price.create.offer.bonusDays.bonusDaysPayForDays.infotext' | translate }}
                </div>
                <p *ngIf="bonusDaysBookError" class="col-sm-12 error booking-error">{{ 'atraveo.accommodationbundle.price.offer.payForDays.invalid' | translate }}</p>
            </div>
            <div class="row form-group form-section specialPrice hide">
                <label class="col-sm-12 col-form-label"
                ><strong>{{ 'atraveo.accommodationbundle.price.create.offer.field.validForBookingPeriod.0' | translate }}</strong></label
                >
                <div class="row form-group col-sm-12">
                    <div class="col-sm-3 offer-radio">
                        <label class="radio-label">
                            <input type="radio" id="specialPrice-radio-info" name="specialPriceBookingPeriod" class="form-control bookingPeriodTravel" checked (change)="showTravelPeriod($event)" />{{
                                'atraveo.accommodationbundle.price.create.offer.specialPrice.specialPriceBookingPeriod.date.prefix' | translate
                            }}
                        </label>
                    </div>
                    <div class="col-sm-3 offer-radio-input">
                        <input
                            type="date"
                            name="specialPriceDateFrom"
                            class="form-control dateFrom"
                            [required]="offer.typeId === 'specialPrice'"
                            [ngModel]="offer.travelPeriod?.from"
                            (ngModelChange)="offer.travelPeriod.from = $event;"
                            (change)="checkDates();"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <span *ngIf="isSubmitted && offer.typeId === 'specialPrice' && !offer.travelPeriod?.from" class="error">
                        {{ 'This value should not be blank.' | translate }}
                    </span>
                    </div>
                    <p class="col-sm-2 offer-radio-input-label">{{ 'atraveo.accommodationbundle.price.create.period.field.dateTo' | translate }}</p>
                    <div class="col-sm-3 offer-radio-input">
                        <input
                            type="date"
                            name="specialPriceDateTo"
                            class="form-control dateTo"
                            [required]="offer.typeId === 'specialPrice'"
                            [ngModel]="offer.travelPeriod?.to"
                            (ngModelChange)="offer.travelPeriod.to = $event;"
                            (change)="checkDates();"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <span *ngIf="isSubmitted && offer.typeId === 'specialPrice' && !offer.travelPeriod?.to" class="error">
                        {{ 'This value should not be blank.' | translate }}
                    </span>
                    </div>
                    <div class="col-sm-12 offer-radio-info specialPrice-radio-info">
                        <p [innerHTML]="'info.occupancyHowArrival' | translate"></p>
                    </div>
                </div>
                <div class="row form-group col-sm-12">
                    <div class="col-sm-3 offer-radio">
                        <label class="radio-label">
                            <input type="radio" name="specialPriceBookingPeriod" class="form-control bookingPeriodNights" (change)="hideTravelPeriod($event)" />{{ 'text.absence.start' | translate }}
                        </label>
                    </div>
                    <div class="col-sm-3 offer-radio-input">
                        <select class="form-control nightsFrom" name="nightsFrom" disabled="disabled" [(ngModel)]="offer.duration.min">
                            <ng-container *ngFor="let durationEntry of durationChoices">
                                <option [ngValue]="durationEntry.days" [selected]="durationEntry.days === offer.duration.min">{{ durationEntry.text }}</option>
                            </ng-container>
                        </select>
                    </div>
                    <p class="col-sm-2 offer-radio-input-label">{{ 'atraveo.accommodationbundle.price.create.period.field.dateTo' | translate }}</p>
                    <div class="col-sm-3 offer-radio-input">
                        <select class="form-control nightsTo" name="nightsTo" disabled="disabled" [(ngModel)]="offer.duration.max">
                            <ng-container *ngFor="let durationEntry of durationChoices">
                                <option [ngValue]="durationEntry.days" [selected]="durationEntry.days === offer.duration.max">{{ durationEntry.text }}</option>
                            </ng-container>
                        </select>
                        <p>{{ 'atraveo.accommodationbundle.price.create.offer.specialPrice.specialPriceBookingPeriod.days.suffix' | translate }}</p>
                    </div>
                </div>
                <div class="row form-group col-sm-12">
                    <div class="col-sm-3">
                        <label class="col-form-label required"
                        ><strong>{{ 'atraveo.accommodationbundle.price.create.offer.specialPrice.step2' | translate }}</strong></label
                        >
                        <app-tooltip [additionalClassList]="'mr-2'" [text]="'info.sonderpreis' | translate"></app-tooltip>
                    </div>
                    <div class="col-sm-3">
                        <div class="input-group">
                            <input
                                type="number"
                                name="newPrice"
                                class="form-control ng-untouched ng-pristine ng-valid newSpecialPrice"
                                [(ngModel)]="offer.newPrice"
                                [ngModelOptions]="{ standalone: true }"
                                [required]="offer.typeId === 'specialPrice'"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text">{{ ownerCurrency }}</span>
                            </div>
                            <span *ngIf="isSubmitted && offer.typeId === 'specialPrice' && !offer.newPrice" class="error">
                            {{ 'This value should not be blank.' | translate }}
                        </span>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <select class="form-control" name="specialPriceIntervalType" [(ngModel)]="offer.priceType">
                            <option value="ta">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.ta' | translate }}</option>
                            <option value="wo">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.wo' | translate }}</option>
                        </select>
                    </div>
                    <div class="col-sm-3">
                        <select class="form-control" name="specialPricePerBookingOrPerson" [(ngModel)]="offer.pricePerPerson">
                            <option [ngValue]="false">{{ 'atraveo.accommodationbundle.price.perBookingOrPersons.booking' | translate }}</option>
                            <option [ngValue]="true">{{ 'atraveo.accommodationbundle.entity.additionalcost.perperson' | translate }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row form-group form-section longStay hide">
                <label class="col-sm-12 col-form-label"
                ><strong>{{ 'atraveo.accommodationbundle.price.create.offer.field.validForBookingPeriod.0' | translate }}</strong></label
                >
                <div class="row form-group col-sm-12">
                    <div class="col-sm-12 offer-radio">
                        <label class="radio-label">
                            <input type="radio" name="longTravelPeriodBookingPeriod" class="form-control bookingPeriod" checked (change)="hideTravelPeriod($event)" />{{
                                'atraveo.accommodationbundle.price.create.offer.longTravelPeriod.longTravelPeriodBookingPeriod.none' | translate
                            }}
                        </label>
                    </div>
                </div>
                <div class="row form-group col-sm-12">
                    <div class="col-sm-3 offer-radio">
                        <label class="radio-label">
                            <input type="radio" id="longTravelPeriod-radio-info" name="longTravelPeriodBookingPeriod" class="form-control bookingPeriodTravel" (change)="showTravelPeriod($event)" />{{
                                'atraveo.accommodationbundle.price.create.offer.longTravelPeriod.longTravelPeriodBookingPeriod.date.prefix' | translate
                            }}
                        </label>
                    </div>
                    <div class="col-sm-3 offer-radio-input">
                        <input
                            type="date"
                            name="longTravelPeriodDateFrom"
                            class="form-control dateFrom"
                            disabled="disabled"
                            [required]="offer.typeId === 'longStay'"
                            [ngModel]="offer.travelPeriod?.from"
                            (ngModelChange)="offer.travelPeriod.from = $event;"
                            (change)="checkDates();"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <span *ngIf="isSubmitted && offer.typeId === 'longStay' && !offer.travelPeriod?.from" class="error">
                        {{ 'This value should not be blank.' | translate }}
                    </span>
                    </div>
                    <p class="col-sm-2 offer-radio-input-label">{{ 'atraveo.accommodationbundle.price.create.period.field.dateTo' | translate }}</p>
                    <div class="col-sm-3 offer-radio-input">
                        <input
                            type="date"
                            name="longTravelPeriodDateTo"
                            class="form-control dateTo"
                            disabled="disabled"
                            [required]="offer.typeId === 'longStay'"
                            [ngModel]="offer.travelPeriod?.to"
                            (ngModelChange)="offer.travelPeriod.to = $event;"
                            (change)="checkDates();"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <span *ngIf="isSubmitted && offer.typeId === 'longStay' && !offer.travelPeriod?.to" class="error">
                        {{ 'This value should not be blank.' | translate }}
                    </span>
                    </div>
                    <div class="col-sm-12 offer-radio-info longTravelPeriod-radio-info hide">
                        <p>{{ 'info.occupancyHowComplete' | translate }}</p>
                    </div>
                </div>
                <div class="row form-group col-sm-12">
                    <label class="col-sm-12 col-form-label"
                    ><strong>{{ 'atraveo.accommodationbundle.price.create.offer.longTravelPeriod.step2' | translate }}</strong></label
                    >
                    <div class="col-sm-3 offer-radio">
                        <label class="required">{{ 'atraveo.accommodationbundle.price.create.offer.longTravelPeriod.longTravelPeriodLimitationsOccupancyFrom' | translate }}</label>
                    </div>
                    <div class="col-sm-3 offer-radio-input">
                        <select class="form-control" name="longTravelPeriodLimitationsOccupancyFrom" [(ngModel)]="offer.duration.min" [ngModelOptions]="{ standalone: true }" [required]="offer.typeId === 'longStay' && !offer?.duration?.min">
                            <ng-container *ngFor="let durationEntry of durationChoices">
                                <option [ngValue]="durationEntry.days" [selected]="durationEntry.days === offer.duration.min">{{ durationEntry.text }}</option>
                            </ng-container>
                        </select>
                    </div>
                    <span *ngIf="isSubmitted && offer.typeId === 'longStay' && !offer?.duration?.min" class="col-sm-12 error-box error">
                    {{ 'This value should not be blank.' | translate }}
                </span>
                </div>
                <div class="row form-group col-sm-12">
                    <label class="col-sm-3 col-form-label offer-radio required">{{ 'atraveo.accommodationbundle.price.create.offer.longTravelPeriod.longTravelPeriodRate' | translate }}</label>
                    <div class="col-sm-3">
                        <div class="input-group">
                            <input
                                type="number"
                                name="newPrice"
                                class="form-control ng-untouched ng-pristine ng-valid newLongTravelPrice"
                                [(ngModel)]="offer.absoluteDiscount"
                                [ngModelOptions]="{ standalone: true }"
                                [required]="offer.typeId === 'longStay' && !offer?.absoluteDiscount"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text">{{ ownerCurrency }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <select class="form-control mt-3 mt-sm-0" name="longTravelPeriodIntervalType" [(ngModel)]="offer.priceType" [ngModelOptions]="{ standalone: true }" [required]="offer.typeId === 'longStay' && !offer?.priceType">
                            <option value="ta">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.ta' | translate }}</option>
                            <option value="wo">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.wo' | translate }}</option>
                        </select>
                    </div>
                    <span *ngIf="isSubmitted && offer.typeId === 'longStay' && (!offer?.absoluteDiscount || !offer?.priceType)" class="col-sm-12 error-box error">
                    {{ 'This value should not be blank.' | translate }}
                </span>
                </div>
            </div>
            <div class="row form-group form-section specialOffer hide">
                <label class="col-sm-12 col-form-label">
                    <strong>{{ 'atraveo.accommodationbundle.price.create.offer.field.validForBookingPeriod.0' | translate }} </strong>
                </label>
                <div class="row form-group col-sm-12">
                    <div class="col-sm-12 offer-radio">
                        <label class="radio-label">
                            <input type="radio" name="offerTypeBookingPeriod" class="form-control" checked (change)="hideTravelPeriod($event)" />
                            {{ 'atraveo.accommodationbundle.price.create.offer.field.validForBookingPeriod.independent' | translate }}
                        </label>
                    </div>
                </div>
                <div class="row form-group col-sm-12">
                    <div class="col-sm-3 offer-radio">
                        <label class="radio-label">
                            <input
                                type="radio"
                                id="offer-radio-info"
                                name="offerTypeBookingPeriod"
                                class="form-control"
                                [checked]="offer.offerPeriod?.from || offer.offerPeriod?.to"
                                (change)="showTravelPeriod($event)"
                            />
                            {{ 'atraveo.accommodationbundle.price.create.offer.field.bookingDate.prefix' | translate }}
                        </label>
                    </div>
                    <div class="col-sm-3 offer-radio-input">
                        <input
                            type="date"
                            name="offerTypeDateFrom"
                            class="form-control dateFrom"
                            [disabled]="!offer.offerPeriod?.from"
                            [required]="offer.typeId === 'specialOffer'"
                            [ngModel]="offer.offerPeriod?.from"
                            (ngModelChange)="offer.offerPeriod.from = $event;"
                            (change)="checkDates();"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <span *ngIf="isSubmitted && offer.typeId === 'specialOffer' && !offer.offerPeriod?.from" class="error">
                        {{ 'This value should not be blank.' | translate }}
                    </span>
                    </div>
                    <p class="col-sm-2 offer-radio-input-label">{{ 'atraveo.accommodationbundle.price.create.period.field.dateTo' | translate }}</p>
                    <div class="col-sm-3 offer-radio-input">
                        <input
                            type="date"
                            name="offerTypeeDateTo"
                            class="form-control dateTo"
                            [disabled]="!offer.offerPeriod?.to"
                            [required]="offer.typeId === 'specialOffer'"
                            [ngModel]="offer.offerPeriod?.to"
                            (ngModelChange)="offer.offerPeriod.to = $event;"
                            (change)="checkDates();"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <span *ngIf="isSubmitted && offer.typeId === 'specialOffer' && !offer.offerPeriod?.to" class="error">
                        {{ 'This value should not be blank.' | translate }}
                    </span>
                    </div>
                </div>
                <div class="row form-group col-sm-12">
                    <div class="col-sm-3 offer-radio">
                        <label class="radio-label">
                            <input
                                type="radio"
                                name="offerTypeBookingPeriod"
                                class="form-control offerTypeBookingPeriod"
                                [checked]="offer.daysBeforeArrival?.min || offer.daysBeforeArrival?.max"
                                (change)="hideTravelPeriod($event)"
                            />
                            {{ 'text.absence.start' | translate }}
                        </label>
                    </div>
                    <div class="col-sm-3 offer-radio-input">
                        <select class="form-control nightsFrom" name="nightsFrom" [(ngModel)]="offer.daysBeforeArrival.min" [ngModelOptions]="{ standalone: true }" [disabled]="!offer.daysBeforeArrival.min">
                            <ng-container *ngFor="let durationEntry of durationChoices">
                                <option [ngValue]="durationEntry.days" [selected]="durationEntry.days === offer.daysBeforeArrival.min">
                                    {{ durationEntry.text }}
                                </option>
                            </ng-container>
                        </select>
                    </div>
                    <p class="col-sm-2 offer-radio-input-label">{{ 'atraveo.accommodationbundle.price.create.period.field.dateTo' | translate }}</p>
                    <div class="col-sm-3 offer-radio-input">
                        <select class="form-control nightsTo" name="nightsTo" [(ngModel)]="offer.daysBeforeArrival.max" [ngModelOptions]="{ standalone: true }" [disabled]="!offer.daysBeforeArrival.max">
                            <ng-container *ngFor="let durationEntry of durationChoices">
                                <option [ngValue]="durationEntry.days" [selected]="durationEntry.days === offer.daysBeforeArrival.max">
                                    {{ durationEntry.text }}
                                </option>
                            </ng-container>
                        </select>
                        <p>{{ 'atraveo.accommodationbundle.price.create.offer.specialPrice.specialPriceBookingPeriod.days.suffix' | translate }}</p>
                    </div>
                </div>

                <label class="col-sm-12 col-form-label">
                    <strong>{{ 'atraveo.accommodationbundle.price.create.offer.field.occupancyDate.0' | translate }} </strong>
                </label>
                <div class="row form-group col-sm-12 trip-from">
                    <label class="col-sm-3 col-form-label checkbox-label">
                        <input type="checkbox" (change)="changeCertainTravelPeriodRule()" [checked]="offerTravelPeriodEnable" />
                        {{ 'atraveo.accommodationbundle.price.create.offer.field.occupancyDate.prefix' | translate }}
                    </label>
                    <div class="col-sm-3 offer-radio-input">
                        <input
                            type="date"
                            name="occupancyDateFrom"
                            class="form-control dateFrom"
                            [disabled]="!offerTravelPeriodEnable"
                            [required]="offer.typeId === 'specialOffer'"
                            [ngModel]="offer.travelPeriod?.from"
                            (ngModelChange)="offer.travelPeriod.from = $event;"
                            (change)="checkDates();"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <span *ngIf="isSubmitted && offer.typeId === 'specialOffer' && !offer.travelPeriod?.from && offerTravelPeriodEnable" class="error">
                        {{ 'This value should not be blank.' | translate }}
                    </span>
                    </div>
                    <p class="col-sm-2 offer-radio-input-label">{{ 'atraveo.accommodationbundle.price.create.period.field.dateTo' | translate }}</p>
                    <div class="col-sm-3 offer-radio-input">
                        <input
                            type="date"
                            name="occupancyDateTo"
                            class="form-control dateTo"
                            [disabled]="!offerTravelPeriodEnable"
                            [required]="offer.typeId === 'specialOffer'"
                            [ngModel]="offer.travelPeriod?.to"
                            (ngModelChange)="offer.travelPeriod.to = $event;"
                            (change)="checkDates();"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <span *ngIf="isSubmitted && offer.typeId === 'specialOffer' && !offer.travelPeriod?.to && offerTravelPeriodEnable" class="error">
                        {{ 'This value should not be blank.' | translate }}
                    </span>
                    </div>
                </div>
                <div class="row form-group col-sm-12 time-period">
                    <select class="col-sm-3 form-control" name="occupancyDateHow" [disabled]="!offerTravelPeriodEnable" [(ngModel)]="offer.travelPeriod.travelRelation" [ngModelOptions]="{standalone: true}">
                        <option [ngValue]="0">{{ 'atraveo.accommodationbundle.price.create.offer.field.occupancyDateHow.0' | translate }}</option>
                        <option [ngValue]="2">{{ 'atraveo.accommodationbundle.price.create.offer.field.occupancyDateHow.2' | translate }}</option>
                        <option [ngValue]="1">{{ 'atraveo.accommodationbundle.price.create.offer.field.occupancyDateHow.1' | translate }}</option>
                    </select>
                    <p>{{ 'atraveo.accommodationbundle.price.create.offer.field.occupancyDateHow.suffix' | translate }}</p>
                </div>

                <label class="col-sm-12 col-form-label">
                    <strong>{{ 'atraveo.accommodationbundle.price.create.offer.field.occupancyDate.rate.title' | translate }} </strong>
                </label>

                <div class="row form-group col-sm-12">
                    <div class="col-sm-3 offer-radio">
                        <label class="radio-label">
                            <input type="radio" name="rateType" class="form-control discount-option-one" checked (change)="changeDiscountType($event)" />
                            {{ 'atraveo.accommodationbundle.price.create.offer.field.rateType.3.0' | translate }}
                        </label>
                    </div>
                    <div class="col-sm-2 percentage-discount-arrival">
                        <select class="form-control discount-option discount-option-one" name="ratePercent" [(ngModel)]="offer.percentDiscount" [ngModelOptions]="{ standalone: true }">
                            <ng-container *ngFor="let in of optionCounter(100); let i = index">
                                <option *ngIf="i > 4" [ngValue]="i">{{ i }} %</option>
                            </ng-container>
                        </select>
                    </div>
                </div>

                <div class="row form-group col-sm-12 discount-details">
                    <div class="col-sm-3 offer-radio">
                        <label class="radio-label">
                            <input type="radio" name="rateType" class="form-control discount-option-two" (change)="changeDiscountType($event)" />
                            {{ 'atraveo.accommodationbundle.price.create.offer.longTravelPeriod.longTravelPeriodPayForDays.prefix' | translate }}
                        </label>
                    </div>
                    <div class="col-sm-2 offer-radio-input">
                        <select name="bookForDays" class="form-control discount-option discount-option-two" [(ngModel)]="offer.daysToBook" [ngModelOptions]="{ standalone: true }" disabled="disabled">
                            <ng-container *ngFor="let in of optionCounter(32); let i = index">
                                <option *ngIf="i > 1">{{ i }}</option>
                            </ng-container>
                        </select>
                    </div>
                    <p>{{ 'atraveo.accommodationbundle.price.create.offer.bonusDays.bonusDaysPayForDays.glue' | translate }}</p>
                    <div class="col-sm-2 offer-radio-input">
                        <select name="payForDays" class="form-control discount-option discount-option-two" [(ngModel)]="offer.daysToPay" [ngModelOptions]="{ standalone: true }" disabled="disabled">
                            <ng-container *ngFor="let in of optionCounter(32); let i = index">
                                <option *ngIf="i > 0">{{ i }}</option>
                            </ng-container>
                        </select>
                    </div>
                    <p>{{ 'atraveo.accommodationbundle.price.create.offer.bonusDays.bonusDaysPayForDays.suffix' | translate }}</p>
                </div>

                <div class="row form-group col-sm-12 discount-details">
                    <div class="col-sm-3 offer-radio">
                        <label class="radio-label">
                            <input type="radio" name="rateType" class="form-control discount-option-three" (change)="changeDiscountType($event)" />
                            {{ 'atraveo.accommodationbundle.price.create.offer.field.rateNewPrice' | translate }}
                        </label>
                        <app-tooltip [additionalClassList]="'mr-2'" [text]="'info.sonderpreis' | translate"></app-tooltip>
                    </div>

                    <div class="col-sm-3">
                        <div class="input-group">
                            <input
                                type="text"
                                name="rateNewPrice"
                                class="form-control discount-option discount-option-three"
                                [(ngModel)]="offer.newPrice"
                                [ngModelOptions]="{ standalone: true }"
                                disabled="disabled"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text">{{ ownerCurrency }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="discount-new-price hide">
                        <div class="form-group col-sm-12">
                            <select
                                class="form-control discount-option discount-option-three"
                                name="rateNewPriceIntervalType"
                                [(ngModel)]="offer.priceType"
                                [ngModelOptions]="{ standalone: true }"
                                disabled="disabled"
                            >
                                <option value="1w">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.1w' | translate }}</option>
                                <option value="ko">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.ko' | translate }}</option>
                                <option value="kw">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.kw' | translate }}</option>
                                <option value="mi">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.mi' | translate }}</option>
                                <option value="mw">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.mw' | translate }}</option>
                                <option value="ta">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.ta' | translate }}</option>
                                <option value="we">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.we' | translate }}</option>
                                <option value="wo">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.wo' | translate }}</option>
                            </select>
                        </div>
                        <div class="col-sm-12">
                            <select
                                class="form-control discount-option discount-option-three"
                                name="rateNewPricePerBookingOrPerson"
                                [(ngModel)]="offer.pricePerPerson"
                                [ngModelOptions]="{ standalone: true }"
                                disabled="disabled"
                            >
                                <option [ngValue]="false">{{ 'atraveo.accommodationbundle.price.perBookingOrPersons.booking' | translate }}</option>
                                <option [ngValue]="true">{{ 'atraveo.accommodationbundle.entity.additionalcost.perperson' | translate }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row form-group col-sm-12 discount-details">
                    <div class="col-sm-3 offer-radio">
                        <label class="radio-label">
                            <input type="radio" name="rateType" class="form-control discount-option-four" (change)="changeDiscountType($event)" />
                            {{ 'atraveo.accommodationbundle.price.create.offer.field.rateDiscount' | translate }}
                        </label>
                    </div>
                    <div class="col-sm-3">
                        <div class="input-group">
                            <input
                                type="text"
                                name="rateDiscount"
                                class="form-control discount-option discount-option-four"
                                [(ngModel)]="offer.absoluteDiscount"
                                [ngModelOptions]="{ standalone: true }"
                                disabled="disabled"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text">{{ ownerCurrency }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="absolute-discount hide">
                        <div class="form-group col-sm-12">
                            <select class="form-control discount-option discount-option-four" name="rateDiscount" [(ngModel)]="offer.priceType" [ngModelOptions]="{ standalone: true }" disabled="disabled">
                                <option value="1w">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.1w' | translate }}</option>
                                <option value="ko">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.ko' | translate }}</option>
                                <option value="kw">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.kw' | translate }}</option>
                                <option value="mi">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.mi' | translate }}</option>
                                <option value="mw">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.mw' | translate }}</option>
                                <option value="ta">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.ta' | translate }}</option>
                                <option value="we">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.we' | translate }}</option>
                                <option value="wo">{{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.wo' | translate }}</option>
                            </select>
                        </div>
                        <div class="col-sm-12">
                            <select
                                class="form-control discount-option discount-option-four"
                                name="rateDiscountPerBookingOrPerson"
                                [(ngModel)]="offer.pricePerPerson"
                                [ngModelOptions]="{ standalone: true }"
                                disabled="disabled"
                            >
                                <option [ngValue]="false">{{ 'atraveo.accommodationbundle.price.perBookingOrPersons.booking' | translate }}</option>
                                <option [ngValue]="true">{{ 'atraveo.accommodationbundle.entity.additionalcost.perperson' | translate }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <a href="javascript:void(0)" data-toggle="collapse" data-target="#limitations" class="col-sm-12" aria-expanded="true">
                    <i class="fa fa-fw fa-chevron-down"></i>
                    {{ 'atraveo.accommodationbundle.price.create.offer.limitations' | translate }}
                </a>

                <div id="limitations" class="row col-sm-12 form-group">
                    <div class="row form-group col-sm-12">
                        <label class="col-sm-3 col-form-label checkbox-label">
                            <input type="checkbox" name="limitationsOccupancy" (change)="optionalRestrictionsRule()" [checked]="offerLimitationTripsEnable" />
                            {{ 'atraveo.accommodationbundle.price.create.deposit.limitationsOccupancy.prefix' | translate }}
                        </label>
                        <div class="col-sm-2 offer-radio-input">
                            <input type="number" name="limitationsOccupancyFrom" class="form-control" [(ngModel)]="offer.duration.min" [ngModelOptions]="{ standalone: true }" [disabled]="!offerLimitationTripsEnable" min="0" max="999" />
                        </div>
                        <p class="col-sm-2">{{ 'atraveo.accommodationbundle.price.create.period.field.dateTo' | translate }}</p>
                        <div class="col-sm-2 offer-radio-input">
                            <input type="number" name="limitationsOccupancyTo" class="form-control" [(ngModel)]="offer.duration.max" [ngModelOptions]="{ standalone: true }" [disabled]="!offerLimitationTripsEnable" min="0" max="999" />
                        </div>
                        <div class="col-sm-3">
                            <label class="info-label">{{ 'atraveo.accommodationbundle.price.create.deposit.limitationsDate.suffix' | translate }}</label>
                            <app-tooltip [additionalClassList]="'mr-2'" [text]="'info.reisedauer' | translate"></app-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <label class="col-sm-3 col-form-label">
                    {{ 'text.mandatoryfield' | translate }}
                </label>
            </div>
        </div>
        <div class="modal-footer">
            <button (click)="onSubmit()" type="submit" class="btn btn-success"><i class="fa fa-check"></i>{{ 'atraveo.accommodationbundle.price.create.save' | translate }}</button>
        </div>
    </form>
</div>
