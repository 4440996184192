<div class="panel">
    <div class="panel-heading">
        <h2>{{ 'atraveo.dashboardbundle.panel.heading.todos.0' | translate }}</h2>
    </div>
    <div class="panel-body">
        <div *ngIf="onboardingProcess">
            <p><strong>{{ 'atraveo.dashboardbundle.panel.heading.todos.firstSteps' | translate }}:</strong></p>
            <ul>
                <li class="d-flex align-items-center mb-2" *ngIf="(!this.authService.currentUser?.registration?.idNowStatusText || ['CREATED', 'CLICKED', 'ABORTED'].includes(this.authService.currentUser?.registration?.idNowStatusText)) && this.authService.currentUser?.registration?.idNowLink">
                    <div class="close-icon"></div>
                    <div>
                        <a target="_blank" href="{{ this.authService.currentUser?.registration?.idNowLink }}" (click)="hideLogoutDialog()">{{ 'atraveo.dashboardbundle.panel.heading.todos.verifyIdentity' | translate }}</a>
                    </div>
                </li>
                <li class="d-flex align-items-center mb-2" *ngIf="accommodationCount === 0">
                    <div class="close-icon"></div>
                    <div>
                        <a [routerLink]="'/accommodation/register/position'" (click)="hideLogoutDialog()">{{ 'atraveo.dashboardbundle.panel.heading.todos.newObject' | translate }}</a>
                    </div>
                </li>
                <li class="d-flex align-items-center" *ngIf="authService.currentUser?.vat?.hasVatId === null || authService.currentUser?.vat?.hasVatId === undefined">
                    <div class="close-icon"></div>
                    <div>
                         <a [routerLink]="'/profile'" (click)="hideLogoutDialog()">{{ 'atraveo.dashboardbundle.panel.heading.todos.completeProfileInfos' | translate }}</a>
                    </div>
                </li>
            </ul>
        </div>
        <div *ngIf="messagesCount > 0">
            <a routerLink="/notification" (click)="hideLogoutDialog()">
                <span class="counter">{{ messagesCount }} </span>
                <span *ngIf="messagesCount > 1; else oneMessage" class="message">{{ 'atraveo.dashboardbundle.panel.heading.todos.notifications' | translate }}</span>
                <ng-template #oneMessage>
                    <span>{{ 'atraveo.dashboardbundle.panel.heading.todos.notification' | translate }}</span>
                </ng-template>
            </a>
        </div>
        <div *ngIf="accommodationErrorCount > 0">
            <br />
            <a routerLink="/accommodations" (click)="hideLogoutDialog()">
                <span class="counter">{{ accommodationErrorCount }} </span>
                <span *ngIf="accommodationErrorCount > 1; else oneError" class="message">{{ 'atraveo.dashboardbundle.panel.heading.todos.objects' | translate }}</span>
                <ng-template #oneError>
                    <span>{{ 'atraveo.dashboardbundle.panel.heading.todos.object' | translate }}</span>
                </ng-template>
            </a>
        </div>
        <div *ngIf="accommodationUnreadRatingCount > 0">
            <br />
            <a routerLink="ratings" (click)="hideLogoutDialog()">
                <span class="counter">{{ accommodationUnreadRatingCount }} </span>
                <span *ngIf="accommodationUnreadRatingCount > 1; else oneRating" class="message">{{ 'atraveo.dashboardbundle.panel.heading.todos.customerratings' | translate }}</span>
                <ng-template #oneRating>
                    <span>{{ 'atraveo.dashboardbundle.panel.heading.todos.customerrating' | translate }}</span>
                </ng-template>
            </a>
        </div>
    </div>
</div>
