import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '../../../services/notification/notification.service';
import * as $ from 'jquery';
import 'bootstrap-notify';

@Component({
    selector: 'app-notification-display',
    templateUrl: './notification-display.component.html',
    styleUrls: ['./notification-display.component.scss'],
})
export class NotificationDisplayComponent implements OnInit, OnDestroy {
    notificationServiceDisplay: any;

    constructor(readonly notificationService: NotificationService) {}

    ngOnInit() {
        this.notificationServiceDisplay = this.notificationService.notifications.subscribe((value) => {
            // @ts-ignore
            $.notify(
                {
                    message: value.text,
                },
                {
                    type: value.type,
                    placement: {
                        from: 'top',
                        align: 'center',
                    },
                    // Damit Notifies auch bei Modals im Vordergrund stehen
                    z_index: 2000,
                },
            );
        });
    }

    ngOnDestroy() {
        this.notificationServiceDisplay.unsubscribe();
    }
}
