<ul class="nav nav-tabs nav-justified" id="ratings-tab" role="tablist">
    <li class="nav-item">
        <a class="nav-link" id="view-tab" data-toggle="tab" href="#view-rating" role="tab"
           [class.active]="this.selectedTab == 'view-rating'"
           (click)="this.selectedTab='view-rating'"
           aria-controls="view-rating" aria-selected="true">
            {{ 'atraveo.customerratingbundle.navigation.homepage.title' | translate }}
        </a>
    </li>
</ul>

<div class="tab-content p-3">
    <div class="nav-item-sm mt-3" [class.active]="this.selectedTab == 'view-rating'" (click)="this.selectedTab= this.selectedTab == 'view-rating' ? '' : 'view-rating'">
        <i class="fa" [ngClass]="this.selectedTab == 'view-rating' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.customerratingbundle.navigation.homepage.title' | translate }}
    </div>
    <div class="tab-pane"
         [ngClass]="{'active': this.selectedTab == 'view-rating', 'show': this.selectedTab == 'view-rating', 'fade': this.selectedTab != 'view-rating'}"
         id="view-rating" role="tabpanel" aria-labelledby="view-tab">
        <p>{{ 'atraveo.customerratingbundle.homepage.description' | translate }}</p>

        <table class="table responsive-table">
            <thead>
                <tr>
                    <th>
                        <app-sort-arrows [(ngModel)]="currentRequest.sort.createDate"
                                         [translationKey]="'atraveo.customerratingbundle.filter.deliverydate'"
                                         (ngModelChange)="getResults()"></app-sort-arrows>
                    </th>
                    <th>{{ 'atraveo.customerratingbundle.rating.travelmonth' | translate }}</th>
                    <th>
                        <app-sort-arrows [(ngModel)]="currentRequest.sort.accommodationId"
                                         [translationKey]="'atraveo.customerratingbundle.rating.accommodation'"
                                         (ngModelChange)="getResults()"></app-sort-arrows>
                    </th>
                    <th>
                        <app-sort-arrows [(ngModel)]="currentRequest.sort.overallRating"
                                         [translationKey]="'atraveo.customerratingbundle.rating.rating'"
                                         (ngModelChange)="getResults()"></app-sort-arrows>
                    </th>
                    <th>
                        {{ 'atraveo.customerratingbundle.rating.customer' | translate }}
                    </th>
                    <th><!-- ICON --></th>
                </tr>
            </thead>
            <tr *ngFor="let rating of this.currentRatings" [ngClass]="!rating.read ? 'unread': ''">
                <!-- Gibt es unten nochmal, kann man leider an dieser Stelle nicht als eigene Komponente machen -->
                <td [attr.data-label]="'atraveo.customerratingbundle.filter.deliverydate' | translate"><span *ngIf="rating.createDate">{{rating.createDate | stringToDate | date }}</span></td>
                <td [attr.data-label]="'atraveo.customerratingbundle.rating.travelmonth' | translate"><span *ngIf="rating.travelPeriod?.from">{{rating.travelPeriod?.from | stringToDate | date:'MMMM y' }}</span></td>
                <td [attr.data-label]="'atraveo.customerratingbundle.rating.accommodation' | translate">{{rating.accommodationId}}</td>
                <td [attr.data-label]="'atraveo.customerratingbundle.rating.rating' | translate"><app-display-rating-icons [rating]="rating.overallRating"></app-display-rating-icons></td>
                <td [attr.data-label]="'atraveo.customerratingbundle.rating.customer' | translate">{{getCustomerName(rating)}}</td>
                <td><a data-toggle="modal" data-target="#viewRating" (click)="viewRating(rating)"><i class="fas fa-eye fa-lg"></i></a></td>
            </tr>
        </table>

        <div class="modal fade" id="viewRating" tabindex="-1" role="dialog" aria-labelledby="viewRatingLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title"
                            id="viewRatingLabel">{{ 'atraveo.customerratingbundle.navigation.rating.title' | translate }}</h1>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table responsive-table">
                            <thead>
                            <tr>
                                <th>{{ 'atraveo.customerratingbundle.filter.deliverydate' | translate }}</th>
                                <th>{{ 'atraveo.customerratingbundle.rating.travelmonth' | translate }}</th>
                                <th>{{ 'atraveo.customerratingbundle.rating.accommodation' | translate }}</th>
                                <th>{{ 'atraveo.customerratingbundle.rating.rating' | translate }}</th>
                                <th>{{ 'atraveo.customerratingbundle.rating.customer' | translate }}</th>
                            </tr>
                            </thead>
                            <tr *ngIf="currentRating">
                                <!-- Gibt es oben nochmal, kann man leider an dieser Stelle nicht als eigene Komponente machen -->
                                <td [attr.data-label]="'atraveo.customerratingbundle.filter.deliverydate' | translate"><span *ngIf="currentRating.createDate">{{currentRating.createDate | stringToDate |date }}</span></td>
                                <td [attr.data-label]="'atraveo.customerratingbundle.rating.travelmonth' | translate"><span *ngIf="currentRating.travelPeriod?.from">{{currentRating.travelPeriod?.from | stringToDate | date:'MMMM y' }}</span></td>
                                <td [attr.data-label]="'atraveo.customerratingbundle.rating.accommodation' | translate">{{currentRating.accommodationId}}</td>
                                <td [attr.data-label]="'atraveo.customerratingbundle.rating.rating' | translate"><app-display-rating-icons [rating]="currentRating.overallRating"></app-display-rating-icons></td>
                                <td [attr.data-label]="'atraveo.customerratingbundle.rating.customer' | translate">{{getCustomerName(currentRating)}}</td>
                            </tr>
                        </table>

                        <div *ngIf="currentRating" class="mt-4">
                            <p *ngIf="this.accommodationText">
                                <strong>{{ 'atraveo.customerratingbundle.rating.publicmessage' | translate}}</strong>
                            </p>
                            <p>{{ this.accommodationText }}</p>
                            <p *ngIf="currentRating.messageFromCustomerToOwner">
                                <strong>{{ 'atraveo.customerratingbundle.rating.internalmessage' | translate }}</strong>
                            </p>
                            <p>{{ currentRating.messageFromCustomerToOwner }}</p>
                            <p [innerHTML]="'atraveo.customerratingbundle.rating.answer' | translate"></p>
                            <textarea class="form-control" [(ngModel)]="currentRating.ownerComment"></textarea>
                            <p><i class="fas fa-info-circle"></i> {{ 'atraveo.customerratingbundle.rating.replymessage' | translate }}</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" (click)="saveCurrentRating()"
                                data-dismiss="modal">{{ 'button.save' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            *ngFor="let rating of currentRatings | paginate: { itemsPerPage: currentRequest.take, currentPage: currentPage, totalItems: count }"></div>
        <pagination-controls class="float-right" (pageChange)="changePage($event)" previousLabel=""
                             nextLabel=""></pagination-controls>
    </div>
    <div class="nav-item-sm mt-3" [class.active]="this.selectedTab == 'request-rating'" (click)="this.selectedTab= this.selectedTab == 'request-rating' ? '' : 'request-rating'">
        <i class="fa" [ngClass]="this.selectedTab == 'request-rating' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.customerratingbundle.navigation.invitation.title' | translate }}
    </div>
    <div class="tab-pane"
         [ngClass]="{'active': this.selectedTab == 'request-rating', 'show': this.selectedTab == 'request-rating', 'fade': this.selectedTab != 'request-rating'}"
         id="request-rating" role="tabpanel" aria-labelledby="request-tab">
        <app-request-rating></app-request-rating>
    </div>
</div>
