import { Observable, Subject } from 'rxjs';
import { ExtendedAccommodationEntity } from '../../entities/extendedAccommodationEntity';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AccommodationLoadUpdateService {
    private subject = new Subject<ExtendedAccommodationEntity>();

    getUpdatedMessage(): Observable<ExtendedAccommodationEntity> {
        return this.subject.asObservable();
    }
    sendUpdatedMessage(accommodation: ExtendedAccommodationEntity) {
        this.subject.next(accommodation);
    }
}