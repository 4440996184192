import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '../../../../services/dialog.service';
import { BookingEntity } from 'data-structures/lib/es6/entity/booking.entity';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-finish-selection-dialog',
    templateUrl: './finish-selection-dialog.component.html',
    styleUrl: './finish-selection-dialog.component.scss',
})
export class FinishSelectionDialogComponent {
    booking: BookingEntity;
    bookingInDateRange: boolean = false;
    dateRangeHeadLine: string;
    stateForm: FormGroup;
    options = [
        { value: 'Y', class: 'free', label: 'atraveo.accommodationbundle.calendar.key.available' },
        { value: 'N', class: 'occupied', label: 'atraveo.accommodationbundle.calendar.key.booked' },
        { value: 'C', class: 'closed', label: 'atraveo.accommodationbundle.calendar.key.closed' },
    ];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, readonly dialogService: DialogService, private fb: FormBuilder) {
        this.booking = data.booking;
        this.bookingInDateRange = data.bookingInDateRange;
        this.dateRangeHeadLine = data.dateRangeHeadLine;
        this.stateForm = this.fb.group({
            newEvent: new FormControl(''),
        });
    }

    openBookingDialog() {
        this.data.openDialog(this.booking);
    }

    setState() {
        this.data.stateSet(this.stateForm.get('newEvent').value);
    }

    closeDialog() {
        this.dialogService.closeDialog();
        this.data.cancel();
    }
}
