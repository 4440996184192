import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantsService } from '../constants/constants.service';
import { NotificationService } from '../notification/notification.service';
import { Injectable, OnDestroy } from '@angular/core';
import { MessageRequestDto } from '../../entities/Messages/messageRequestDto';
import { MessageDto } from '../../entities/Messages/messageDto';
import * as Sentry from '@sentry/angular-ivy';
import { InvoiceDto } from '../../entities/Invoices/invoiceDto';
import { InvoiceRequestDto } from '../../entities/Invoices/invoiceRequestDto';
import { map, timeout } from 'rxjs/operators';
import { TermsDto } from '../../entities/terms-dto';
import { plainToInstance } from 'class-transformer';
import { AuthenticationService } from '../authentication/authentication.service';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BackOfficeConnectorService implements OnDestroy {
    invoiceFileSubscription: Subscription;
    httpSubscription: Subscription;

    constructor(readonly httpClient: HttpClient, readonly constants: ConstantsService, readonly notificationService: NotificationService, readonly authService: AuthenticationService) {}

    ngOnDestroy() {
        this.invoiceFileSubscription?.unsubscribe();
        this.httpSubscription?.unsubscribe();
    }

    private getHeaders(): HttpHeaders {
        return new HttpHeaders({
            'content-type': 'application/json',
        });
    }

    async sendMessage(formData: FormData, ownerNumber: number) {
        const url = environment.boUrl + 'api/inventory/messages/write/owners/' + ownerNumber + '/';
        try {
            await this.httpClient.post(url, formData).toPromise();
            return true;
        } catch (e) {
            await this.notificationService.add('form.save.failure', 'danger');
            Sentry.captureException(e);
            return false;
        }
    }

    async getOwnerMessages(messageRequest: MessageRequestDto, ownerNumber: number): Promise<MessageDto[]> {
        const messages: MessageDto[] = [];

        const url = environment.boUrl + 'api/inventory/messages/readall/owners/' + ownerNumber;

        const body = {
            filter: {
                accommodationId: messageRequest.accommodationId,
                bookingNumber: messageRequest.bookingNumber,
                accountId: messageRequest.accountId,
                searchInSubject: messageRequest.searchInSubject,
                page: messageRequest.page,
                limit: messageRequest.limit,
                sortBy: messageRequest.sortBy,
                sortTo: messageRequest.sortTo,
                isSend: messageRequest.isSend,
            },
        };

        try {
            const answer = await this.httpClient.post(url, body, { headers: this.getHeaders() }).toPromise();

            Object.keys(answer).map((messageIndex) => {
                if (Number(messageIndex) < Object.keys(answer).length) {
                    messages.push(MessageDto.backOfficeToClass(answer[messageIndex]));
                } else {
                    messages.push(answer[messageIndex]);
                }
            });
        } catch (e) {
            this.notificationService.add('error.message.couldNotLoad', 'danger');
            Sentry.captureException(e);
        }
        return messages;
    }

    async getMessageContent(ownerNumber: number, messageIdValue: number) {
        let answer: any = {};

        const url = environment.boUrl + 'api/inventory/messages/read/' + messageIdValue + '/owners/' + ownerNumber;

        const body = {
            messageId: messageIdValue,
        };

        try {
            answer = await this.httpClient.post(url, body, { headers: this.getHeaders() }).toPromise();
        } catch (e) {
            this.notificationService.add('error.message.couldNotLoad', 'danger');
            Sentry.captureException(e);
        }

        return answer;
    }

    async setMessageStatus(ownerNumber: number, actionState: string, messageIdsValue: number[], actionValue: number, from: string = null) {
        const url = environment.boUrl + 'api/inventory/messages/setstatus/owners/' + ownerNumber;

        const body = {
            data: {
                action: actionState,
                messageIds: messageIdsValue,
                value: actionValue,
            },
        };

        try {
            await this.httpClient.put(url, body, { headers: this.getHeaders() }).toPromise();
            if (actionState === 'read' && from !== 'messageContent') {
                if (actionValue === 1) {
                    await this.notificationService.add('text.flashbag.notification.messages.marked.read', 'success');
                } else {
                    await this.notificationService.add('text.flashbag.notification.messages.marked.unread', 'success');
                }
            }

            if (actionState === 'delete') {
                await this.notificationService.add('text.flashbag.notification.messages.marked.deleted', 'success');
            }
        } catch (e) {
            await this.notificationService.add('form.save.failure', 'danger');
            Sentry.captureException(e);
        }
    }

    async getUnreadMessagesCount(ownerNumber: number): Promise<number> {
        try {
            const messageCount: any = await this.httpClient.get(environment.boUrl + 'api/inventory/messages/unread/owners/' + ownerNumber, { headers: this.getHeaders() }).toPromise();
            return messageCount;
        } catch (e) {
            return 0;
        }
    }

    async getInvoices(invoiceRequest: InvoiceRequestDto, type: string, ownerNumber: number): Promise<InvoiceDto[]> {
        const invoices: InvoiceDto[] = [];

        const url = environment.boUrl + 'api/inventory/invoices/' + type + '/owners/' + ownerNumber;

        const body = {
            filter: {
                dateFrom: invoiceRequest.dateFrom,
                dateTo: invoiceRequest.dateTo,
                bookingNumber: invoiceRequest.bookingNumber,
                page: invoiceRequest.page,
                limit: invoiceRequest.limit,
                sortBy: invoiceRequest.sortBy,
                sortTo: invoiceRequest.sortTo,
            },
        };

        try {
            const answer = await this.httpClient.post(url, body, { headers: this.getHeaders() }).toPromise();

            Object.keys(answer).map((invoiceIndex) => {
                if (Number(invoiceIndex) < Object.keys(answer).length) {
                    invoices.push(InvoiceDto.backOfficeToClass(answer[invoiceIndex]));
                } else {
                    invoices.push(answer[invoiceIndex]);
                }
            });
        } catch (e) {
            this.notificationService.add('error.message.couldNotLoad', 'danger');
            Sentry.captureException(e);
        }

        return invoices;
    }

    downloadInvoiceFile(url, type, dto) {
        const types = {
            pdf: 'application/pdf',
            csv: 'text/csv',
        };

        return this.httpClient.post(url, dto, { responseType: 'blob' }).pipe(
            map((res) => {
                // @ts-ignore
                return new Blob([res], { type: types[type] });
            }),
        );
    }

    async getInvoicePdf(id: string, type: string, ownerNumber: number) {
        const url = environment.boUrl + 'api/inventory/invoices/' + type + '/owners/' + ownerNumber;

        const body = {
            data: {
                id: id,
            },
        };

        this.invoiceFileSubscription = this.downloadInvoiceFile(url, 'pdf', body).subscribe((res) => {
            const fileURL = URL.createObjectURL(res);
            window.open(fileURL, '_blank');
        });
    }

    async getTerms(ownerNumber: number, language: string): Promise<TermsDto> {
        const terms: any = await this.httpClient.get(environment.boUrl + `api/inventory/owners/${ownerNumber}/termsandconditions/${language}/acceptData/`, { headers: this.getHeaders() }).toPromise();

        return plainToInstance(TermsDto, terms);
    }

    async getMailAttachment(ownerNumber: number, mailId: number, attachmentId: number, filename: string) {
        this.httpSubscription = this.httpClient
            .post(environment.boUrl + `api/inventory/messages/read/${mailId}/owners/${ownerNumber}/attachment/${attachmentId}/`, null, {
                responseType: 'arraybuffer',
            })
            .subscribe((response: any) => {
                const dataType = response.type;
                const binaryData = [];
                binaryData.push(response);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
                downloadLink.setAttribute('download', filename);
                document.body.appendChild(downloadLink);
                downloadLink.click();
            });
    }

    async getIDNowUrl() {
        try {
            // gibt es schon einen IDNowLink?
            let url = this.authService.currentUser?.registration?.idNowLink;

            // ansonsten erzeugen
            if (!url) {
                const primaryId = this.authService.currentUser?.addressBook?.primaryId;
                if (primaryId) {
                    const userContact = this.authService.currentUser?.addressBook?.contacts[primaryId];
                    const ownerNumber = this.authService.currentUser?.ownerNumber;
                    const ownerData = {
                        firstname: userContact.forename,
                        lastname: userContact.surname,
                        street: userContact.street,
                        zipcode: userContact.zipCode,
                        nationality: userContact.countryISOCode,
                        email: userContact.email[0],
                    };
                    const result = await this.httpClient.post(environment.boUrl + `api/inventory/idnow/newowner/` + ownerNumber, ownerData, { headers: this.getHeaders() }).toPromise();
                    if (result) {
                        url = result.toString();
                    }
                }
            }
            return url;
        } catch (e) {
            Sentry.captureException(e);
            return null;
        }
    }
}
