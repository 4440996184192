import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class AuthRedirectGuard implements CanActivate {
    constructor(readonly authenticationService: AuthenticationService, private router: Router) {}

    canActivate(): boolean {
        if (this.authenticationService.isTokenValid) {
            this.router.navigate(['/dashboard']);
        } else {
            this.router.navigate(['/login']);
        }
        return false; // Verhindert den Zugriff auf die Route selbst
    }
}
