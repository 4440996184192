import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CacheKeys, CacheService } from '../cache/cache.service';
import { environment } from '../../../environments/environment';
import { classToPlain, plainToInstance } from 'class-transformer';
import { ExtendedAccommodationEntity } from '../../entities/extendedAccommodationEntity';
import { ExtendedUserEntity } from '../../entities/extendedUserEntity';
import { ConstantsService } from '../constants/constants.service';
import { ExtendedAdditionalCostDefinitionEntity } from '../../entities/extended-additional-cost-definition-entity';
import { NotificationService } from '../notification/notification.service';
import { AdditionalCostRuleEntity } from 'data-structures/lib/es6/entity/additional-cost/rules/rules.entity';
import { HistoryFilterDto } from 'data-structures/lib/es6/dto/history/history.filter.dto';
import {
    ComponentsEnum,
    ComponentsEnum as FindAccommodationComponents,
    FindAccommodationRequestDto,
} from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-request.dto';
import { FindAccommodationGroupRequestDto, GroupComponentsEnum } from 'data-structures/lib/es6/dto/accommodation-group/find-accommodation-group/find-accommodation-group-request.dto';
import { ExtendedAdditionalCostTemplateEntity } from '../../entities/extended-additional-cost-template-entity';
import { FindRatingRequestDto } from 'data-structures/lib/es6/dto/rating/owner/find-rating-request.dto';
import { RatingAccommodationEntity } from 'data-structures/lib/es6/entity/rating/accommodation/rating-accommodation.entity';
import { FindRatingResponseDto } from 'data-structures/lib/es6/dto/rating/owner/find-rating-response.dto';
import { PutArrivalInformationDto } from 'data-structures/lib/es6/dto/arrival-information/put-arrival-information.dto';
import { SeasonsDto } from 'data-structures/lib/es6/dto/season/put-season/seasons.dto';
import { SeasonDto } from 'data-structures/lib/es6/dto/season/put-season/season.dto';
import { GetArrivalRequestDto } from 'data-structures/lib/es6/dto/arrival-information/getArrivalRequest.dto';
import { GetArrivalResponseDto } from 'data-structures/lib/es6/dto/arrival-information/getArrivalResponse.dto';
import { map } from 'rxjs/operators';
import { ExtendedAccommodationGroupEntity } from '../../entities/extendedAccommodationGroupEntity';
import { ActivatedRoute, Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { GeoAssignmentResultDto } from 'data-structures/lib/es6/dto/geography/geo-assignment-result.dto';
import { GetAccommodationAssignmentDto } from 'data-structures/lib/es6/dto/geography/get-accommodation-assignment.dto';
import { GetAccommodationGroupAssignmentDto } from 'data-structures/lib/es6/dto/geography/get-accommodation-group-assignment.dto';
import { FeatureDto, MediaDto } from 'data-structures/lib/es6/dto/accommodation';
import { GeoCountryEntity } from 'data-structures/lib/es6/entity/geography/geo-country.entity';
import { GeoRegionEntity } from 'data-structures/lib/es6/entity/geography/geo-region.entity';
import { GeoCityEntity } from 'data-structures/lib/es6/entity/geography/geo-city.entity';
import { FeatureDefinitionsDto } from 'data-structures/lib/es6/dto/accommodation/feature/feature-definitions.dto';
import { AuthenticationService } from '../authentication/authentication.service';
import { format } from 'date-fns';
import { FindAccommodationOrderByDto } from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-order-by.dto';
import { TransferDataDto } from 'data-structures/lib/es6/dto/accommodation/transfer-data.dto';
import { TransferOwnerDataDto } from 'data-structures/lib/es6/dto/admin/transfer-owner-data.dto';
import { GeoDto } from 'data-structures/lib/es6/dto/accommodation/position/geo.dto';
import { AdditionalCostSource } from '../../entities/extended-addition-cost';
import { ChangeService } from '../change/change.service';
import { cloneDeep } from 'lodash';
import { HistoryResultDto } from 'data-structures/lib/es6/dto/history/history.results.dto';
import { BehaviorSubject, lastValueFrom, Observable, Subscription } from 'rxjs';
import { AccommodationUpdateService } from '../accommodation-update.service';
import { PoolOwnerDto } from 'data-structures/lib/es6/dto/pool-owner/pool-owner.dto';
import { PoolDto } from 'data-structures/lib/es6/dto/pool/pool.dto';

export class FindAccommodationResultDto {
    count: number;
    accommodations: ExtendedAccommodationEntity[];
}

// tslint:disable-next-line:max-classes-per-file
export class FindAccommodationGroupResultDto {
    count: number;
    accommodationGroups: ExtendedAccommodationGroupEntity[];
}

// tslint:disable-next-line:max-classes-per-file
@Injectable({
    providedIn: 'root',
})
export class ApiConnectorService implements OnDestroy {
    baseUrl = environment.apiUrl;
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
    };
    accommodationCount: number = 0;
    accommodationCountPromise: Promise<number>;
    moveFeaturesToMain = {
        KINDER: 'minChildren',
        MAXPERSON: 'maxPersons',
        WHNFLAECHE: 'livingSpace',
        KONTINGENT: 'contingent',
    };
    downloadPdfSubscription: Subscription;

    private activeAccommodationSubject = new BehaviorSubject<ExtendedAccommodationEntity & ExtendedAccommodationGroupEntity>(null);
    activeAccommodation$ = this.activeAccommodationSubject.asObservable();

    constructor(
        readonly httpClient: HttpClient,
        readonly apiCache: CacheService,
        readonly constants: ConstantsService,
        readonly notificationService: NotificationService,
        readonly router: Router,
        readonly authenticationService: AuthenticationService,
        readonly changeService: ChangeService,
        readonly accommodationUpdateService: AccommodationUpdateService,
    ) {}

    ngOnDestroy(): void {
        this.downloadPdfSubscription?.unsubscribe();
    }

    async deleteAccommodation(accommodation: ExtendedAccommodationEntity): Promise<any> {
        await this.httpClient.delete(this.baseUrl + '/import/' + accommodation.accommodationId).toPromise();
        // this.apiCache.add(accommodation.accommodationId.toString(), accommodation);
    }

    async saveAccommodation(accommodation: ExtendedAccommodationEntity, updateAccommodationId: boolean = false, returnErrors: boolean = false, reloadAndNavigate: boolean = true, autoCompleteFull: boolean = false) {
        const user = cloneDeep(this.authenticationService.currentUser);
        if (user?.tracking?.onboarding) {
            if (accommodation.state.publishingRequested && !user.tracking.onboarding.accommodationCreationCompleted && user.tracking.onboarding.accommodationCreationStarted) {
                user.tracking.onboarding.accommodationCreationCompleted = new Date();
                await this.authenticationService.saveOwner(user);
            }
        }
        let accommodationId;
        accommodation.updated = new Date();

        if (accommodation.vacancy?.vacancyString) {
            accommodation.vacancy.vacancyString = accommodation.vacancy?.vacancyString?.slice(0, 5000);
        }

        try {
            let accommodationToSave = cloneDeep(accommodation);
            const oldAccommodation = this.activeAccommodationSubject.getValue();
            const diff = this.changeService.getChangedContentTypes(oldAccommodation, accommodationToSave);
            accommodationToSave = this.prepareAccommodationForInventory(accommodationToSave, autoCompleteFull);

            // Alle nicht geänderten Objekte (und arrays) auf der obersten Ebene rauslöschen
            if (oldAccommodation) {
                for (const key of Object.keys(accommodationToSave)) {
                    if (!diff.includes(key) && typeof accommodationToSave[key] === 'object') {
                        delete accommodationToSave[key];
                    }
                }
            }

            if (accommodationToSave.features?.length === 0) {
                delete accommodationToSave.features;
            }

            const plain = classToPlain(accommodationToSave);

            const result = await this.httpClient.put(this.baseUrl + '/import/accommodation', plain).toPromise();
            if (updateAccommodationId) {
                // @ts-ignore
                accommodation.accommodationId = result.accommodationId;
                accommodationId = accommodation.accommodationId;
            }

            await this.notificationService.add('form.save.success', 'success');
            if (accommodation.accommodationId) {
                accommodationId = accommodation.accommodationId;
            }

            // ziehe die neuen Daten
            if (reloadAndNavigate) {
                await this.getAccommodationByAccommodationId(accommodationId, user, true);

                this.accommodationUpdateService.sendUpdatedMessage(this.activeAccommodationSubject.getValue());

                // lade korrekte url wenn eine neue accommodationID erzeugt wurde
                if (accommodationToSave.accommodationId !== accommodation.accommodationId) {
                    this.router.navigate([`/accommodation/${accommodation.accommodationId}/position`]);
                }
            }
        } catch (e) {
            if (environment.stage !== 'prod') {
                console.log(e);
            }

            if (accommodation.features?.some((feature) => feature.roomId && !feature.featureId)) {
                await this.notificationService.add('text.validation.pleasecorrect', 'danger');
            } else {
                await this.notificationService.add('form.save.failure', 'danger');
            }
            Sentry.captureException(e);
        }
    }

    async loadAccommodationOrGroupForTab(
        tab: 'main' | 'position' | 'features' | 'description' | 'images' | 'prices' | 'calendar',
        route: ActivatedRoute,
    ): Promise<ExtendedAccommodationEntity | ExtendedAccommodationGroupEntity> {
        if (route?.snapshot?.url?.length >= 2 && route.snapshot.url[0].path === 'accommodation') {
            const alwaysNeededComponents = [ComponentsEnum.AccommodationId, ComponentsEnum.Vacancy];
            const headerComponents = [ComponentsEnum.CheckResults, ComponentsEnum.State, ComponentsEnum.Main, ComponentsEnum.Media, ComponentsEnum.Channels];
            const accommodationId = Number(route.snapshot.url[1].path);
            const components = alwaysNeededComponents.concat(headerComponents);

            switch (tab) {
                case 'main':
                    components.push(ComponentsEnum.OwnerAccommodationId);
                    components.push(ComponentsEnum.Features);
                    break;
                case 'features':
                    components.push(ComponentsEnum.Features);
                    components.push(ComponentsEnum.Rooms);
                    break;

                case 'images':
                    // Bilder werden immer geladen, weil sonst das Foto im Header fehlt
                    break;

                case 'description':
                    components.push(ComponentsEnum.Descriptions);
                    break;

                case 'prices':
                    components.push(ComponentsEnum.PriceInformation);
                    components.push(ComponentsEnum.PriceRules);
                    components.push(ComponentsEnum.AdditionalCosts);
                    components.push(ComponentsEnum.Offers);
                    break;

                case 'calendar':
                    components.push(ComponentsEnum.Vacancy);
                    components.push(ComponentsEnum.Meta);
                    components.push(ComponentsEnum.PriceInformation);
                    break;

                default:
                    components.push(tab as ComponentsEnum);
            }

            return await this.getAccommodationByAccommodationId(accommodationId, this.authenticationService.currentUser, true, components);
        } else if (route?.snapshot?.url?.length >= 2 && route.snapshot.url[0].path === 'accommodation-groups') {
            const accommodationGroupId = Number(route.snapshot.url[1].path);
            return await this.getAccommodationGroupByAccommodationGroupId(accommodationGroupId, this.authenticationService.currentUser);
        }
    }

    async saveAccommodationOrGroupForTab(route: ActivatedRoute, entity: ExtendedAccommodationEntity | ExtendedAccommodationGroupEntity) {
        if (entity instanceof ExtendedAccommodationEntity) {
            return await this.saveAccommodation(entity as ExtendedAccommodationEntity, true, true);
        } else {
            await this.saveAccommodationGroup(entity as ExtendedAccommodationGroupEntity, entity.ownerNumber, false, true);
            return [];
        }
    }

    private prepareAccommodationForInventory(accommodation: ExtendedAccommodationEntity, autoCompleteFull: boolean = false) {
        accommodation.addRequiredFields();
        this.removeNullValuesFromMediaDescriptionAndDisplayUrl(accommodation);

        if (!accommodation.createDate) {
            delete accommodation.createDate;
        }

        if (!accommodation.channels?.length) {
            delete accommodation.channels;
        }

        if (!accommodation.accommodationId && !autoCompleteFull) {
            delete accommodation.main;
            delete accommodation.vacancy;
        }

        if (accommodation.checkResults) {
            Object.values(accommodation.checkResults).forEach((checkResult, index) => {
                if (checkResult['checkResultProgressBarTotal']) {
                    accommodation.checkResults.splice(index, 1);
                }
            });
        }

        if (accommodation['accommodationGroupName']) {
            delete accommodation['accommodationGroupName'];
        }

        // Das wird vom Inventory Suchendpunkt dazugepackt, ist eigentlich nicht Teil der Accommodation Entity
        if (accommodation.rating) {
            delete accommodation.rating;
        }

        delete accommodation.termsAndConditions;
        delete accommodation?.vacancy?.dayPriceString;
        delete accommodation?.vacancy?.priceString;
        delete accommodation?.vacancy?.vacancyStringWithPrices;

        if (accommodation?.vacancy) {
            accommodation.vacancy.vacancyString = accommodation.vacancy.vacancyString ?? '';
            accommodation.vacancy.originalVacancy = accommodation.vacancy.originalVacancy ?? '';
        }

        if (accommodation.modifyDate) {
            delete accommodation.modifyDate;
        }

        // Das Feld gibt es nicht mehr
        // @ts-ignore
        if (accommodation.bankAccount) {
            // @ts-ignore
            delete accommodation.bankAccount;
        }

        delete accommodation.arrivalInformation;

        for (const field of ['imported', 'active', 'qualified', 'tourOperated', 'valid']) {
            if (accommodation.state?.hasOwnProperty(field)) {
                delete accommodation.state[field];
            }
        }
        for (const field of ['deleted']) {
            if (accommodation.state?.hasOwnProperty(field) && !this.authenticationService.currentUser?.isEmployee()) {
                delete accommodation.state[field];
            }
        }

        if (accommodation.state && !Object.keys(accommodation.state).length) {
            delete accommodation.state;
        }

        if (accommodation.features?.length) {
            for (const [feature] of Object.entries(this.moveFeaturesToMain)) {
                if (accommodation.getFeatureByKey(feature).length) {
                    accommodation.deleteFeature(feature);
                }
            }
        }

        if (accommodation.main) {
            for (const field of Object.values(this.moveFeaturesToMain)) {
                if (field in accommodation.main) {
                    if (!['maxPersons', 'livingSpace'].includes(field) || (['maxPersons', 'livingSpace'].includes(field) && accommodation.main[field] !== null)) {
                        accommodation.main[field] = Number(accommodation.main[field]);
                    } else {
                        delete accommodation.main[field];
                    }
                }
            }

            if (!accommodation?.main?.groupName) {
                delete accommodation.main.groupName;
            }

            if (accommodation?.main?.classification === '') {
                delete accommodation.main.classification;
            }

            if (accommodation?.main.checkResultProgressBarTotal) {
                delete accommodation.main.checkResultProgressBarTotal;
            }
        }

        if (accommodation.additionalCosts?.length) {
            for (const additionalCost of accommodation.additionalCosts) {
                if ('deletable' in additionalCost) {
                    delete additionalCost.deletable;
                }

                if (additionalCost) {
                    // Werte in Zahlen umwandeln
                    for (const field of ['price', 'maxPieceCount', 'obligation', 'percent', 'maxPrice', 'minPrice']) {
                        if (field in additionalCost) {
                            additionalCost[field] = Number(additionalCost[field]);
                        }
                    }

                    // Leere Min-Max Felder löschen, bzw. bestehende in Zahl umwandeln
                    for (const field of ['duration', 'occupancy', 'calcAges', 'calcDays', 'calcPersons', 'daysBeforeArrival', 'age']) {
                        if (field in additionalCost) {
                            for (const suffix of ['min', 'max']) {
                                if (suffix in additionalCost[field] && additionalCost[field][suffix] === '') {
                                    delete additionalCost[field][suffix];
                                } else if (suffix in additionalCost[field]) {
                                    additionalCost[field][suffix] = Number(additionalCost[field][suffix]);
                                }
                            }
                        }
                    }
                }
            }

            for (const additionalCostId in accommodation.additionalCosts) {
                if (accommodation.additionalCosts[additionalCostId]) {
                    // Wenn nur ID und additionalCost vergeben, dann rauslöschen
                    if (Object.keys(accommodation.additionalCosts[additionalCostId]).length === 2) {
                        delete accommodation.additionalCosts[additionalCostId];
                    }

                    // Autogenerierte Nebenkosten wieder entfernen
                    if (
                        accommodation.additionalCosts[additionalCostId].source === AdditionalCostSource.GENERATED ||
                        accommodation.additionalCosts[additionalCostId].source === AdditionalCostSource.MANDATORY_COST_AUTO_CREATED
                    ) {
                        delete accommodation.additionalCosts[additionalCostId];
                    }
                }
            }

            accommodation.additionalCosts = accommodation.additionalCosts.filter((ac) => ac !== null);

            if (Object.keys(accommodation.additionalCosts).length === 0) {
                delete accommodation.additionalCosts;
            }
        }

        // Wir haben Preise mit Von- und Bis-Datum, aber auch seasonId = null, das nimmt die Validierung nicht an
        if (accommodation.priceRules?.length) {
            for (const priceRule of accommodation.priceRules) {
                if (priceRule.travelPeriod && 'seasonId' in priceRule.travelPeriod && priceRule.travelPeriod.seasonId === null) {
                    delete priceRule.travelPeriod.seasonId;
                }

                if (priceRule.prices.length) {
                    for (const price of priceRule.prices) {
                        if (!price.maxPersons) {
                            delete price.maxPersons;
                        }
                        if (!price.pricePerAdditionalPerson) {
                            delete price.pricePerAdditionalPerson;
                        }
                    }
                }
            }
        }

        if (accommodation.offers?.length) {
            for (const offer of accommodation.offers) {
                if (offer.travelPeriod && !offer.travelPeriod.from && !offer.travelPeriod.to) {
                    delete offer.travelPeriod;
                }

                if (offer.offerPeriod && !offer.offerPeriod.from && !offer.offerPeriod.to) {
                    delete offer.offerPeriod;
                }

                if (offer.daysToBook) {
                    offer.daysToBook = Number(offer.daysToBook);
                }

                if (offer.daysToPay) {
                    offer.daysToPay = Number(offer.daysToPay);
                }

                if (offer.newPrice) {
                    offer.newPrice = Number(offer.newPrice);
                }

                if (offer.absoluteDiscount) {
                    offer.absoluteDiscount = Number(offer.absoluteDiscount);
                }

                for (const field of ['duration', 'daysBeforeArrival', 'daysToBook', 'daysToPay', 'absoluteDiscount', 'age', 'percentDiscount', 'pricePerPerson', 'occupancy', 'travelRelation']) {
                    if (offer[field] && typeof offer[field] === 'object') {
                        if ('min' in offer[field] && !offer[field].min) {
                            delete offer[field].min;
                        }

                        if ('max' in offer[field] && !offer[field].max) {
                            delete offer[field].max;
                        }

                        if (Object.keys(offer[field]).length === 0) {
                            delete offer[field];
                        }
                    }

                    if (offer[field] === null) {
                        delete offer[field];
                    }
                }

                for (const field of ['priceType', 'newPrice']) {
                    if (offer[field] === null) {
                        delete offer[field];
                    }
                }
            }
        }

        this.removeGeoFromPosition(accommodation);

        if (accommodation.position?.location && !Object.keys(accommodation.position.location).length) {
            delete accommodation.position.location;
        }

        if (accommodation.accommodationId && accommodation.position?.location && !accommodation.position?.location?.latitude) {
            delete accommodation.position.location.latitude;
        }

        if (accommodation.accommodationId && accommodation.position?.location && !accommodation.position?.location?.longitude) {
            delete accommodation.position.location.longitude;
        }

        if (accommodation.position?.address && accommodation.position.address.zipCode === null) {
            delete accommodation.position.address.zipCode;
        }

        if (accommodation.position?.address && !Object.keys(accommodation.position.address).length) {
            delete accommodation.position.address;
        }

        if (accommodation.position && !Object.keys(accommodation.position).length) {
            delete accommodation.position;
        }

        for (const field of ['priceInformation', 'rooms']) {
            if (accommodation.hasOwnProperty(field) && accommodation[field] !== null && !Object.keys(accommodation[field]).length) {
                delete accommodation[field];
            }
        }

        if (accommodation?.vacancy && !accommodation?.vacancy?.securityToken) {
            delete accommodation.vacancy.securityToken;
        }

        delete accommodation.shortGeoName;
        delete accommodation.descriptionLanguages;
        delete accommodation.canBeQualified;
        return accommodation;
    }

    removeNullValuesFromMediaDescriptionAndDisplayUrl(accommodationOrGroup: ExtendedAccommodationEntity | ExtendedAccommodationGroupEntity) {
        if (accommodationOrGroup.media?.length) {
            for (const media of accommodationOrGroup.media) {
                if (media.descriptions) {
                    this.removeNullValuesFromObject(media.descriptions);
                }

                delete media.displayUrl;
            }
        }
    }

    private removeNullValuesFromObject(obj: object): void {
        Object.keys(obj).forEach((k) => obj[k] == null && delete obj[k]);
    }

    async saveAccommodationGroup(accommodationGroup: ExtendedAccommodationGroupEntity, ownerNumber: number, redirect: boolean = true, updateAccommodationGroupId: boolean = false) {
        if (accommodationGroup.createDate) {
            accommodationGroup.createDate = format(new Date(accommodationGroup.createDate), 'yyyy-MM-dd');
        } else {
            accommodationGroup.createDate = format(new Date(), 'yyyy-MM-dd');
        }

        this.removeNullValuesFromMediaDescriptionAndDisplayUrl(accommodationGroup);
        this.removeEmptyPosition(accommodationGroup);
        this.removeGeoFromPosition(accommodationGroup);

        if (accommodationGroup.modifyDate) {
            delete accommodationGroup.modifyDate;
        }

        if (accommodationGroup.ownerAccommodationGroupId === null) {
            delete accommodationGroup.ownerAccommodationGroupId;
        }

        try {
            const answer: any = await this.httpClient.put(this.baseUrl + '/accommodation-group/' + ownerNumber.toString(), classToPlain(accommodationGroup)).toPromise();

            await this.notificationService.add('form.save.success', 'success');

            if (updateAccommodationGroupId) {
                accommodationGroup.accommodationGroupId = answer.accommodationGroupId;
            }

            // hole gespeicherte Gruppe, um alle subjects auszulösen
            await this.getAccommodationGroupByAccommodationGroupId(answer.accommodationGroupId, this.authenticationService.currentUser);

            if (redirect) {
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['/accommodation-group/', answer.accommodationGroupId], { queryParams: { ownerid: ownerNumber } });
                });
            }
        } catch (e) {
            await this.notificationService.add('form.save.failure', 'danger');
            Sentry.captureException(e);
        }
    }

    async deleteAccommodationGroup(accommodationGroupId: number, ownerNumber: number): Promise<any> {
        await this.httpClient.delete(this.baseUrl + '/accommodation-group/' + ownerNumber.toString() + '/' + accommodationGroupId).toPromise();
        this.notificationService.add('text.flashbag.accommodationgroup.deleted', 'success');
    }

    async updateMedia(result) {
        const newMedia = result.map((media) => plainToInstance(MediaDto, media));
        this.reloadAccommodation();
        this.accommodationUpdateService.sendUpdatedMessage(this.activeAccommodationSubject.getValue());
        return newMedia;
    }

    async uploadData(urlPart: string, formData: FormData): Promise<MediaDto[]> {
        try {
            const result: any = await this.httpClient.post(this.baseUrl + '/accommodation/image-upload/' + urlPart, formData).toPromise();
            return this.updateMedia(result);
        } catch (e) {
            this.notificationService.add('form.save.failure', 'danger');
            Sentry.captureException(e);
        }
    }

    async deleteImage(urlPart: string, mediaDto: MediaDto): Promise<MediaDto[]> {
        try {
            if (mediaDto.descriptions) {
                this.removeNullValuesFromObject(mediaDto.descriptions);
            }
            const result: any = await this.httpClient
                .request('DELETE', this.baseUrl + '/accommodation/image-upload/' + urlPart, {
                    body: mediaDto,
                })
                .toPromise();
            return this.updateMedia(result);
        } catch (e) {
            this.notificationService.add('form.save.failure', 'danger');
            Sentry.captureException(e);
        }
    }

    async arrivalInformationUpload(ownerNumber: number, arrivalId: string, formData: FormData): Promise<MediaDto[]> {
        const urlPart = this.getUrlPartForArrival(ownerNumber, arrivalId);
        return this.uploadData(urlPart, formData);
    }

    async deleteArrivalInformationImage(file: MediaDto, ownerNumber: number, arrivalInformationId: string) {
        const urlPart = this.getUrlPartForArrival(ownerNumber, arrivalInformationId);
        return this.deleteImage(urlPart, file);
    }

    async getFeatureDefinitions(): Promise<FeatureDefinitionsDto> {
        const url = this.baseUrl + '/feature-definitions';

        const cachedFeatureDefinitions = this.apiCache.read(CacheKeys.FeatureDefinition);
        if (typeof cachedFeatureDefinitions !== 'undefined' && cachedFeatureDefinitions !== null) {
            return cachedFeatureDefinitions;
        }

        const featureDefinitions = await this.httpClient.get<FeatureDefinitionsDto>(url).toPromise();
        const featureDefinitionsObject: FeatureDefinitionsDto = plainToInstance(FeatureDefinitionsDto, featureDefinitions as object);
        this.apiCache.add(CacheKeys.FeatureDefinition, featureDefinitionsObject);

        return featureDefinitionsObject;
    }

    async getFeatureDefinitionsWithoutGroups(): Promise<{}> {
        const cachedFeatureDefinitions = this.apiCache.read(CacheKeys.FeatureDefinitionWithoutGroup);
        if (typeof cachedFeatureDefinitions !== 'undefined' && cachedFeatureDefinitions !== null) {
            return cachedFeatureDefinitions;
        }

        const featureDefinitions = await this.getFeatureDefinitions();

        // Das Feld id soll jetzt der Key in dem neuen array/objekt sein
        const featureDefinitionsArray = {};
        for (const group of featureDefinitions.groups) {
            for (const feature of group.features) {
                featureDefinitionsArray[feature.id] = feature;
            }
        }
        this.apiCache.add(CacheKeys.FeatureDefinitionWithoutGroup, featureDefinitionsArray);
        return featureDefinitionsArray;
    }

    async getAccommodationByAccommodationId(accommodationId: number, user: ExtendedUserEntity, notFromCache: boolean = false, requestComponents = null): Promise<ExtendedAccommodationEntity | null> {
        if (!user) {
            return null;
        }

        const body: FindAccommodationRequestDto = {
            ownerNumber: user.ownerNumber,
            accommodationId: [accommodationId],
            filter: {
                // @ts-ignore
                state: {
                    deleted: false,
                },
            },
            skip: 0,
            take: 100,
            destinationPools: user.destinationPools,
        };

        // if (requestComponents) {
        //     body.components = requestComponents;
        // }

        if (user?.isEmployee()) {
            delete body.filter.state.deleted;
        }

        const results = await this.findAccommodations(body);
        if (!results?.accommodations?.length && this.authenticationService.currentUser) {
            await this.router.navigate(['/accommodation-not-found']);
            return null;
        }

        const [result] = results.accommodations;

        // setze das subject
        this.activeAccommodationSubject.next(result);

        return result;
    }

    async findAccommodations(dto: FindAccommodationRequestDto) {
        try {
            if (dto.accommodationId?.length) {
                dto.accommodationId = dto.accommodationId.filter((element) => element !== null);
            }
            const answer: any = await this.httpClient.post(this.baseUrl + '/accommodation/find', dto).toPromise();

            const result: any = new FindAccommodationResultDto();
            result.accommodations = answer.result.map((accommodations) => plainToInstance(ExtendedAccommodationEntity, accommodations as object[]));
            result.accommodations.forEach((accommodation: ExtendedAccommodationEntity) => {
                if (accommodation.priceRules) {
                    accommodation.addIdsToPriceRules();
                }

                if (accommodation.dailyPriceRules) {
                    accommodation.addIdsToDailyPriceRules();
                }

                if (accommodation.position && !accommodation.position.geo) {
                    accommodation.position.geo = new GeoDto();
                }

                for (const [feature, mainName] of Object.entries(this.moveFeaturesToMain)) {
                    if (accommodation.main && mainName in accommodation.main && !accommodation.getFeatureByKey(feature).length) {
                        if (!accommodation.features) {
                            accommodation.features = [];
                        }

                        const newFeature = new FeatureDto();
                        newFeature.featureId = feature;
                        newFeature.value = accommodation.main[mainName];
                        accommodation.features.push(newFeature);
                    }
                }
            });
            result.count = answer.count;

            return result;
        } catch (e) {
            await this.notificationService.add('error.message.couldNotLoad', 'danger');
            Sentry.captureException(e);
        }
    }

    async getAccommodationsByOwnerNumber(
        user: ExtendedUserEntity,
        skip: number = 0,
        take: number = 10,
        state: any = { deleted: false },
        components: FindAccommodationComponents[] = [],
        orderBy?: FindAccommodationOrderByDto[],
    ): Promise<FindAccommodationResultDto> {
        if (!user) {
            return null;
        }

        // @ts-ignore
        const body: FindAccommodationRequestDto = {
            ownerNumber: user.ownerNumber,
            components: components.length !== 0 ? components : null,
            // @ts-ignore
            filter: {
                state,
            },
            skip,
            take,
            // @ts-ignore
            options: {
                resultCount: true,
            },
            orderBy,
            destinationPools: user.destinationPools,
        };

        if (user.isEmployee()) {
            delete body.filter.state.deleted;
        }

        return this.findAccommodations(body);
    }

    async findAccommodationGroups(dto: FindAccommodationGroupRequestDto): Promise<ExtendedAccommodationGroupEntity[]> {
        const url = this.baseUrl + `/accommodation-group/find`;
        const response: any = await this.httpClient.post(url, dto).toPromise();

        const result: any = new FindAccommodationGroupResultDto();
        result.accommodationGroups = response.result.map((accommodationGroups) => plainToInstance(ExtendedAccommodationGroupEntity, accommodationGroups as object[]));
        // result.accommodationGroups.forEach((accommodationGroup: ExtendedAccommodationGroupEntity) => {
        //     this.apiCache.add(accommodationGroup.accommodationGroupId.toString(), accommodationGroup);
        // });
        result.count = response.count;

        return result;
    }

    async getAccommodationGroupsByOwnerNumber(ownerNumber: number, skip: number = 0, take: number = 10, components: GroupComponentsEnum[] = []): Promise<ExtendedAccommodationGroupEntity[]> {
        const dto = new FindAccommodationGroupRequestDto();
        if (components) {
            dto.components = components;
        }
        dto.skip = skip;
        dto.take = take;
        dto.ownerNumber = ownerNumber;

        return this.findAccommodationGroups(dto);
    }

    async getAccommodationGroupByAccommodationGroupId(accommodationGroupId: number, user: ExtendedUserEntity): Promise<ExtendedAccommodationGroupEntity | null> {
        if (!user) {
            return null;
        }

        // cache kann zu Problemen führen, wenn mehrere user gleichzeitig arbeiten
        // const cachedAccommodationGroup = this.apiCache.read(accommodationGroupId.toString());
        // if (typeof cachedAccommodationGroup !== 'undefined' && cachedAccommodationGroup !== null && !cachedAccommodationGroup.updated) {
        //     // setze das subject
        //     this.activeAccommodationSubject.next(cachedAccommodationGroup);
        //
        //     return cachedAccommodationGroup;
        // } else {
        const body = {
            ownerNumber: user.ownerNumber,
            accommodationGroupId: [accommodationGroupId],
        };

        const answer: any = await this.httpClient.post(this.baseUrl + '/accommodation-group/find', body).toPromise();
        const [result] = answer.result.map((accommodationGroup) => plainToInstance(ExtendedAccommodationGroupEntity, accommodationGroup as object[]));

        // this.apiCache.add(result.accommodationGroupId.toString(), result);
        // setze das subject
        this.activeAccommodationSubject.next(result);
        return result;
        // }
    }

    async getUserDataByAccommodationId(accommodationId: string): Promise<ExtendedUserEntity | undefined> {
        const response: any = await this.httpClient.get(this.baseUrl + '/owner/by-accommodation/' + accommodationId).toPromise();
        return plainToInstance(ExtendedUserEntity, response);
    }

    async getAccommodationErrorCount(owner: ExtendedUserEntity): Promise<number> {
        if (!owner || !owner.ownerNumber) {
            return 0;
        }

        const count = await this.httpClient.get(this.baseUrl + '/owner/error-count/' + owner.ownerNumber).toPromise();
        // @ts-ignore
        return count;
    }

    async getAccommodationCount(owner: ExtendedUserEntity, isNewAccommodation: boolean = false): Promise<number> {
        if (!owner || !owner.ownerNumber) {
            return 0;
        }

        if (this.accommodationCountPromise && this.accommodationCount === undefined && !isNewAccommodation) {
            return await this.accommodationCountPromise;
        }

        if (this.accommodationCount && this.accommodationCount !== 0 && !isNewAccommodation) {
            return this.accommodationCount;
        }

        try {
            // @ts-ignore
            this.accommodationCountPromise = this.httpClient.get(this.baseUrl + '/owner/accommodation-count/' + owner.ownerNumber).toPromise();
            const count: number = await this.accommodationCountPromise;
            this.accommodationCount = count;
            return count;
        } catch (e) {
            Sentry.captureException(e);
        }
    }

    async getProductKeyCount(owner: ExtendedUserEntity): Promise<any> {
        if (!owner || !owner.ownerNumber) {
            return 0;
        }

        return await this.httpClient.get(this.baseUrl + '/owner/product-key-count/' + owner.ownerNumber).toPromise();
    }

    async getOnlineAccommodationCount(owner: ExtendedUserEntity): Promise<number> {
        if (!owner) {
            return 0;
        }

        const count = await this.httpClient.get(this.baseUrl + '/owner/online-accommodation-count/' + owner.ownerNumber).toPromise();
        // @ts-ignore
        return count;
    }

    async getAdditionalCostDefinitions(): Promise<{ [id: string]: ExtendedAdditionalCostDefinitionEntity }> {
        const url = this.baseUrl + '/additional-cost/definition';

        const cachedAdditionalCostDefinitions = this.apiCache.read(CacheKeys.AdditionalCostDefinition);

        if (typeof cachedAdditionalCostDefinitions !== 'undefined' && cachedAdditionalCostDefinitions !== null) {
            return cachedAdditionalCostDefinitions;
        }

        const response: any = await this.httpClient.get(url).toPromise();

        const result = {};
        for (const definition of response) {
            result[definition.additionalCostId] = plainToInstance(ExtendedAdditionalCostDefinitionEntity, definition);
        }

        this.apiCache.add(CacheKeys.AdditionalCostDefinition, result);

        return result;
    }

    async getAdditionalCostRules(): Promise<AdditionalCostRuleEntity[]> {
        let result: any[] = this.apiCache.read(CacheKeys.AdditionalCostRules);
        if (!result) {
            result = [];
            const response: any = await this.httpClient.get(this.baseUrl + '/additional-cost/rules').toPromise();
            for (const rule of response) {
                result.push(plainToInstance(AdditionalCostRuleEntity, rule));
            }
            this.apiCache.add(CacheKeys.AdditionalCostRules, result);
        }

        return result;
    }

    async getHistoryForOwner(ownerNumber: number, filter: HistoryFilterDto): Promise<HistoryResultDto> {
        const url = this.baseUrl + '/history/owner/' + ownerNumber.toString();
        const response: any = await this.httpClient.post(url, filter).toPromise();
        const result: HistoryResultDto = response as HistoryResultDto;
        return result;
    }

    async getAdditionalCostTemplates(ownerNumber: number): Promise<ExtendedAdditionalCostTemplateEntity[]> {
        const url = this.baseUrl + '/additional-cost/templates/' + ownerNumber.toString();
        const response: any = await this.httpClient.get(url).toPromise();
        const result: ExtendedAdditionalCostTemplateEntity[] = [];
        for (const entity of response) {
            const classEntity: ExtendedAdditionalCostTemplateEntity = plainToInstance(ExtendedAdditionalCostTemplateEntity, entity);
            if (!classEntity.conditions?.accommodationIds || classEntity.conditions?.accommodationIds.length === 0) {
                classEntity.conditions.accommodationIds = [null];
            }
            result.push(classEntity);
        }
        return result;
    }

    async putAdditionalCostTemplate(template: ExtendedAdditionalCostTemplateEntity, ownerNumber): Promise<void> {
        if (template?.conditions?.accommodationIds?.length) {
            template.conditions.accommodationIds = template.conditions.accommodationIds.filter((accommodationId) => accommodationId !== null);
        }

        const url = this.baseUrl + '/additional-cost/templates/' + ownerNumber.toString();
        await this.httpClient.put(url, [classToPlain(template)]).toPromise();
    }

    async deleteAdditionalCostTemplate(template: ExtendedAdditionalCostTemplateEntity, ownerNumber: number): Promise<void> {
        const url = this.baseUrl + '/additional-cost/templates/' + ownerNumber.toString() + '/' + template.additionalCostTemplateId;
        await this.httpClient.delete(url).toPromise();
    }

    async getArrivalInformation(ownerNumber: number, dto: GetArrivalRequestDto): Promise<GetArrivalResponseDto> {
        try {
            const url = this.baseUrl + '/arrival-information/' + ownerNumber.toString();
            const result = (await this.httpClient.post(url, dto).toPromise()) as GetArrivalResponseDto;
            return result;
        } catch (e) {
            await this.notificationService.add('error.message.couldNotLoad', 'danger');
            Sentry.captureException(e);
        }
    }

    async getContactPersonIds(ownerNumber: number): Promise<any> {
        const url = this.baseUrl + '/arrival-information/get/contactIds/' + ownerNumber.toString();
        return await this.httpClient.get(url).toPromise();
    }

    async getManagerContactIds(ownerNumber: number): Promise<any> {
        const url = this.baseUrl + '/owner/manager-ids/' + ownerNumber.toString();
        return await this.httpClient.get(url).toPromise();
    }

    async findRatings(currentRequest: FindRatingRequestDto): Promise<FindRatingResponseDto> {
        const url = this.baseUrl + '/rating/find/';
        const result: any = await this.httpClient.post(url, currentRequest).toPromise();
        const ratings: RatingAccommodationEntity[] = result.result.map((rating) => {
            const value = plainToInstance(RatingAccommodationEntity, rating as object);
            value.overallRating = Number(value.overallRating); // Kommt aus unerklärlichen Gründen als String aus der Datenbank im Inventory
            return value;
        });

        const findRatingResponseDto = new FindRatingResponseDto();
        findRatingResponseDto.count = result.count;
        findRatingResponseDto.result = ratings;

        return findRatingResponseDto;
    }

    async getRatingById(uniqueId: string, ownerNumber: number) {
        const url = this.baseUrl + '/rating/ownerNumber/' + ownerNumber.toString() + '/' + uniqueId;
        const result: any = await this.httpClient.get(url).toPromise();
        result.overallRating = Number(result.overallRating); // Kommt aus unerklärlichen Gründen als String aus der Datenbank im Inventory

        return plainToInstance(RatingAccommodationEntity, result);
    }

    async saveRating(rating: RatingAccommodationEntity, ownerNumber: number) {
        const url = this.baseUrl + '/rating/owner/' + ownerNumber.toString() + '/accommodation';
        const plain: any = classToPlain(rating);
        plain.overallRating = Number(plain.overallRating);

        try {
            await this.httpClient.put(url, { ratings: [plain] }).toPromise();
            await this.notificationService.add('form.save.success', 'success');
        } catch (e) {
            await this.notificationService.add('form.save.failure', 'danger');
            Sentry.captureException(e);
        }
    }

    async putArrivalInformation(ownerNumber: number, arrivalInformation: PutArrivalInformationDto): Promise<void> {
        try {
            const url = this.baseUrl + '/arrival-information/' + ownerNumber.toString();

            if (arrivalInformation?.arrivalInformation[0]?.conditions?.accommodationIds?.length) {
                arrivalInformation.arrivalInformation[0].conditions.accommodationIds = arrivalInformation.arrivalInformation[0].conditions.accommodationIds.filter((element) => element !== null);
            }

            await this.httpClient.put(url, arrivalInformation).toPromise();

            if (arrivalInformation.arrivalInformation[0].id) {
                await this.notificationService.add('text.flashbag.arrival.updated', 'success');
            } else {
                await this.notificationService.add('text.flashbag.arrival.created', 'success');
            }
        } catch (e) {
            await this.notificationService.add('form.save.failure', 'danger');
            Sentry.captureException(e);
        }
    }

    async getSeasons(): Promise<SeasonsDto> {
        const cachedSeasons: SeasonsDto = this.apiCache.read(CacheKeys.Seasons);
        if (typeof cachedSeasons !== 'undefined' && cachedSeasons !== null) {
            return cachedSeasons;
        }

        const url = this.baseUrl + '/seasons/';
        const result = await this.httpClient.get(url).toPromise();
        const seasons = new SeasonsDto();
        seasons.seasons = [];
        // @ts-ignore
        for (const season of result) {
            seasons.seasons.push(plainToInstance(SeasonDto, season));
        }

        this.apiCache.add(CacheKeys.Seasons, seasons);

        return seasons;
    }

    async getArrivalPdf(ownerNumber: number, id: string, language: string) {
        const url = this.baseUrl + `/arrival-information/${ownerNumber}/pdf/${id}/${language}`;

        this.downloadPdfSubscription = this.downloadPdf(url).subscribe((res) => {
            const fileURL = URL.createObjectURL(res);
            window.open(fileURL, '_blank');
        });
    }

    async deleteArrival(ownerNumber: number, id: string) {
        try {
            const url = this.baseUrl + `/arrival-information/${ownerNumber}/${id}`;
            await this.httpClient.delete(url).toPromise();
            await this.notificationService.add('text.flashbag.arrival.deleted', 'success');
        } catch (e) {
            await this.notificationService.add('form.save.failure', 'danger');
        }
    }

    downloadPdf(url) {
        return this.httpClient.get(url, { responseType: 'blob' }).pipe(
            map((res) => {
                // @ts-ignore
                return new Blob([res], { type: 'application/pdf' });
            }),
        );
    }

    async getGeoNamesByAccommodationId(accommodationId: number, currentLang: string): Promise<GeoAssignmentResultDto> {
        const request = new GetAccommodationAssignmentDto();
        request.language = currentLang;
        request.accommodationId = accommodationId;
        const result = await this.httpClient.post(this.baseUrl + '/geography/get-assignment-by-accommodation?ownerNumber=' + this.authenticationService.currentUser.ownerNumber, request).toPromise();

        return plainToInstance(GeoAssignmentResultDto, result);
    }

    async getGeoNamesByAccommodationGroupId(accommodationGroupId: number, currentLang: string): Promise<GeoAssignmentResultDto> {
        const request = new GetAccommodationGroupAssignmentDto();
        request.language = currentLang;
        request.accommodationGroupId = accommodationGroupId;
        const result = await this.httpClient
            .post(this.baseUrl + '/geography/get-assignment-by-accommodation-group?ownerNumber=' + this.authenticationService.currentUser.ownerNumber, request)
            .toPromise();

        return plainToInstance(GeoAssignmentResultDto, result);
    }

    async getNewIcalToken(accommodationId: number): Promise<string> {
        const result = await this.httpClient.get(this.baseUrl + '/ical/token/' + accommodationId.toString()).toPromise();

        // @ts-ignore
        return result.token;
    }

    private getUrlPartForArrival(ownerNumber: number, arrivalId: string) {
        return `arrival-information?ownerNumber=${ownerNumber.toString()}&arrivalInformationId=${arrivalId}`;
    }

    async redetermineCityPosition(accommodationId: number): Promise<GeoDto> {
        try {
            const result = await this.httpClient.get(this.baseUrl + '/accommodation/' + accommodationId.toString() + '/redetermine-city').toPromise();
            return plainToInstance(GeoDto, result);
        } catch (e) {
            Sentry.captureException(e);
        }
    }

    async getCountries(): Promise<GeoCountryEntity[]> {
        const cachedCountries = this.apiCache.read(CacheKeys.Countries);
        if (typeof cachedCountries !== 'undefined' && cachedCountries !== null && cachedCountries.length) {
            return cachedCountries;
        }

        const url = this.baseUrl + '/geography/countries';
        let result: any = [];
        const countries: GeoCountryEntity[] = [];

        try {
            result = await this.httpClient.get(url).toPromise();
            for (const entry of result) {
                const country: GeoCountryEntity = plainToInstance(GeoCountryEntity, entry);
                countries.push(country);
            }
        } catch (e) {
            await this.notificationService.add('error.message.couldNotLoad', 'danger');
            Sentry.captureException(e);
        }

        this.apiCache.add(CacheKeys.Countries, countries);
        return countries;
    }

    async getRegions(country: string): Promise<GeoRegionEntity[]> {
        const url = this.baseUrl + '/geography/countries/' + country + '/main-regions';

        let result: any = [];
        const regions: GeoRegionEntity[] = [];

        try {
            result = await this.httpClient.get(url).toPromise();
            for (const entry of result) {
                const region: GeoRegionEntity = plainToInstance(GeoRegionEntity, entry);
                regions.push(region);
            }
        } catch (e) {
            await this.notificationService.add('error.message.couldNotLoad', 'danger');
            Sentry.captureException(e);
        }
        return regions;
    }

    async getCities(id: number): Promise<GeoCityEntity[]> {
        const url = this.baseUrl + '/geography/regions/' + id + '/cities/';
        let result: any = [];
        const cities: GeoCityEntity[] = [];

        try {
            result = await this.httpClient.get(url).toPromise();
            for (const entry of result) {
                const city: GeoCityEntity = plainToInstance(GeoCityEntity, entry);
                cities.push(city);
            }
        } catch (e) {
            await this.notificationService.add('error.message.couldNotLoad', 'danger');
            Sentry.captureException(e);
        }

        return cities;
    }

    private removeEmptyPosition(accommodationGroup: ExtendedAccommodationGroupEntity) {
        if (
            accommodationGroup.position &&
            !Object.keys(accommodationGroup.position?.location).length &&
            !Object.keys(accommodationGroup.position?.geo).length &&
            !Object.keys(accommodationGroup.position?.address).length
        ) {
            delete accommodationGroup.position;
        }
    }

    private removeGeoFromPosition(accommodationOrGroup: ExtendedAccommodationEntity | ExtendedAccommodationGroupEntity) {
        if (accommodationOrGroup.position?.geo && !Object.keys(accommodationOrGroup.position.geo).length) {
            delete accommodationOrGroup.position.geo;
        }

        // Wenn ein Fehler auftritt, ist im geo Knoten nur noch errorInGeoAssignment und man kann nicht speichern
        if (
            accommodationOrGroup.position?.geo &&
            Object.keys(accommodationOrGroup.position.geo).length === 1 &&
            (accommodationOrGroup.position.geo.manual || 'errorInGeoAssignment' in accommodationOrGroup.position.geo)
        ) {
            delete accommodationOrGroup.position.geo;
        }

        if (
            accommodationOrGroup.position?.geo &&
            Object.keys(accommodationOrGroup.position.geo).length &&
            !accommodationOrGroup.position.geo.manual &&
            !accommodationOrGroup.position.geo.errorInGeoAssignment
        ) {
            delete accommodationOrGroup.position.geo;
        }
    }

    async updateIcalCalendar(accommodationId: number): Promise<void> {
        try {
            const url = this.baseUrl + '/ical/import/accommodation/' + accommodationId;
            await this.httpClient.get(url).toPromise();
            await this.getAccommodationByAccommodationId(accommodationId, this.authenticationService.currentUser, true);
            this.accommodationUpdateService.sendUpdatedMessage(this.activeAccommodationSubject.getValue());
        } catch (e) {
            await this.notificationService.add('error.message.couldNotLoad', 'danger');
            Sentry.captureException(e);
        }
    }

    async updateVacancyDate(accommodation: ExtendedAccommodationEntity) {
        try {
            const url = this.baseUrl + '/accommodation/updateVacancyDate/' + accommodation.accommodationId + '?ownerNumber=' + accommodation.ownerNumber.toString();
            await this.httpClient.get(url).toPromise();
            await this.getAccommodationByAccommodationId(accommodation.accommodationId, this.authenticationService.currentUser, true);
            this.accommodationUpdateService.sendUpdatedMessage(this.activeAccommodationSubject.getValue());
        } catch (e) {
            Sentry.captureException(e);
            await this.notificationService.add('form.save.failure', 'danger');
        }
    }

    async transferAccommodationData(transferDto: TransferDataDto) {
        try {
            const url = this.baseUrl + '/accommodation/transfer-data/';
            await this.httpClient.post(url, transferDto).toPromise();
            await this.notificationService.add('atraveo.accommodationbundle.accommodation.clone.partial.0', 'success');
            // if (transferDto.targetAccommodationIds?.length) {
            //     for (const id of transferDto.targetAccommodationIds) {
            //         this.apiCache.delete(id.toString());
            //     }
            // }
        } catch (e) {
            Sentry.captureException(e);
            await this.notificationService.add('form.save.failure', 'danger');
        }
    }

    async checkIcalLinks(icalLinksList: string[]) {
        const body = {
            icalLinks: icalLinksList,
        };

        const answer = this.httpClient.post(this.baseUrl + '/ical/check-links/post/ical-check-links', body).toPromise();
        this.accommodationUpdateService.sendUpdatedMessage(this.activeAccommodationSubject.getValue());
        return answer;
    }

    async getAccommodationCanBeQualified(accommodationId: number): Promise<boolean> {
        const url = this.baseUrl + '/admin/accommodation-is-qualifiable/' + accommodationId.toString();

        // @ts-ignore
        const answer: boolean = await this.httpClient.get(url).toPromise();

        return answer;
    }

    async qualifyAccommodation(accommodationId: number) {
        const url = this.baseUrl + '/admin/qualify-accommodation/' + accommodationId.toString();
        const result = await this.httpClient.get(url).toPromise();
        await this.getAccommodationByAccommodationId(accommodationId, this.authenticationService.currentUser, true);
        return result;
    }

    async transferAccommodationToOtherOwner(transferDto: TransferOwnerDataDto) {
        const url = this.baseUrl + '/admin/transferData/';
        return this.httpClient.post(url, transferDto).toPromise();
    }

    async updateOriginalVacancy() {
        await this.httpClient.get(this.baseUrl + '/accommodation/updateOriginalVacancy/' + this.authenticationService.currentUser.ownerNumber).toPromise();
        this.accommodationUpdateService.sendUpdatedMessage(this.activeAccommodationSubject.getValue());
    }

    async getCity(cityId: number): Promise<GeoCityEntity> {
        const url = this.baseUrl + '/geography/city/' + cityId.toString();
        const city = await this.httpClient.get(url).toPromise();
        return plainToInstance(GeoCityEntity, city);
    }

    async notifyImageIsRotated(
        accommodationOrGroup: ExtendedAccommodationEntity | ExtendedAccommodationGroupEntity,
        ownerNumber: number,
        type: 'accommodation' | 'accommodation-group',
        accommodationOrGroupId: number,
        mediaToRotate: MediaDto,
    ) {
        const url =
            this.baseUrl +
            `/accommodation/image-rotated?localFile=${encodeURIComponent(mediaToRotate.localUrl)}&type=${type}&id=${accommodationOrGroupId}&ownerNumber=${ownerNumber}&angle=${mediaToRotate.angle}`;

        const clonedAccommodationOrGroup = cloneDeep(accommodationOrGroup);
        this.removeNullValuesFromMediaDescriptionAndDisplayUrl(clonedAccommodationOrGroup);
        const result: any = await this.httpClient.post(url, clonedAccommodationOrGroup.media).toPromise();
        const newMedia = result?.map((media) => plainToInstance(MediaDto, media));
        const activeAccommdationOrGroup = this.activeAccommodationSubject.getValue();
        activeAccommdationOrGroup.media = newMedia;
        this.setActiveAccommodation(activeAccommdationOrGroup);
    }

    async copyAccommodation(ownerNumber: number, accommodationId: number) {
        try {
            const url = this.baseUrl + `/import/copy/${ownerNumber.toString()}/${accommodationId.toString()}`;
            await this.httpClient.get(url).toPromise();
            await this.notificationService.add('form.save.success', 'success');
            this.accommodationUpdateService.sendUpdatedMessage(this.activeAccommodationSubject.getValue());
        } catch (e) {
            await this.notificationService.add('form.save.failure', 'danger');
        }
    }

    async reloadAccommodation() {
        // hole gespeicherte Gruppe, um alle subjects auszulösen
        const activeAccommdationOrGroup = this.activeAccommodationSubject.getValue();
        if (activeAccommdationOrGroup?.accommodationId) {
            await this.getAccommodationByAccommodationId(activeAccommdationOrGroup?.accommodationId, this.authenticationService.currentUser, true);
        } else if (activeAccommdationOrGroup?.accommodationGroupId) {
            await this.getAccommodationGroupByAccommodationGroupId(activeAccommdationOrGroup?.accommodationGroupId, this.authenticationService.currentUser);
        }
    }

    async trackLink(urlPart: string) {
        const inventoryUrl = this.baseUrl + '/owner/idnow-link';
        const url = 'https://owner.atraveo.com' + urlPart.split('?')[0];
        return this.httpClient.post(inventoryUrl, { url }).toPromise();
    }

    setActiveAccommodation(accommodation: any) {
        this.activeAccommodationSubject.next(accommodation);
    }

    accommodationAjaxSearchObservable(query: string = '', skip = 0): Observable<any> {
        const url = this.baseUrl + `/accommodation/ajax-search`;

        // FindAccommodationAjaxDto, noch nicht im VRMB
        const searchDto = {
            ownerNumber: this.authenticationService.currentUser.ownerNumber,
            searchTerm: query,
            take: 50,
            skip,
        };

        return this.httpClient.post(url, searchDto);
    }

    async isPrimaryDefaultEmailAddressUnique(ownerNumber: number, primaryDefaultEmailAddress: string): Promise<boolean> {
        const body: { ownerNumber: number; primaryDefaultEmailAddress: string } = {
            ownerNumber,
            primaryDefaultEmailAddress,
        };
        return lastValueFrom(this.httpClient.post<boolean>(this.baseUrl + '/owner/is-primary-default-email-address-unique', body));
    }

    async getAccommodationModels(accounmodationId: number): Promise<any[]> {
        const url = this.baseUrl + `/accommodation/available-models/${accounmodationId}`;
        return this.httpClient.get<number[]>(url).toPromise();
    }

    loadDestinationOwnerList(poolIds): Observable<any> {
        return this.httpClient.post<any>(`${this.baseUrl}/destination/destination-pool/owner-by-pool/`, { poolIds });
    }

    async getPools(): Promise<PoolDto[]> {
        return await lastValueFrom(this.httpClient.post<PoolDto[]>(this.baseUrl + '/destination/pool/find', {}));
    }

    async putPoolOwner(poolId: number, ownerNumber: number) {
        const poolOwner = new PoolOwnerDto();
        poolOwner.ownerNumber = ownerNumber;
        poolOwner.poolId = poolId;
        await lastValueFrom(this.httpClient.put(this.baseUrl + '/destination/pool-owner/', poolOwner));
    }

    async deletePoolOwner(poolId: number, ownerNumber: number) {
        return await lastValueFrom(this.httpClient.delete(this.baseUrl + '/destination/pool-owner/' + ownerNumber.toString() + '/' + poolId.toString()));
    }

    async getPoolOwner(ownerNumber: number): Promise<PoolOwnerDto[]> {
        return await lastValueFrom(this.httpClient.post<PoolOwnerDto[]>(this.baseUrl + '/destination/pool-owner/find', { ownerNumber }));
    }
}
