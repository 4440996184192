import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { OwnerConnectorService } from '../../../../../services/api-connector/owner-connector.service';

@Component({
    selector: 'app-idnow-banner',
    templateUrl: './idnow-banner.component.html',
    styleUrls: ['./idnow-banner.component.scss'],
})
export class IdnowBannerComponent implements OnInit {
    requestedNewIdNowCheck = false;
    constructor(readonly authService: AuthenticationService) {}
    @Input() inForm: boolean = false;

    ngOnInit(): void {}

    async changeStatus() {
        await this.authService.saveOwner(this.authService.currentUser);
    }
}
