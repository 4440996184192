<div class="modal-content">
    <div class="filter-modal-header">
        <button class="close" (click)="closeDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                    {{ 'text.filter.accommodation.state.0' | translate }}:
                </div>
                <div class="col-sm-9">
                    <select class="form-control" [(ngModel)]="accommodationStateValue" (change)="setState($event.target.value)">
                        <option value="">{{ 'text.filter.accommodation.state.all' | translate }}</option>
                        <option *ngIf="stateByParam === 'active'; else notSelected" value="active:true">{{ 'text.filter.accommodation.state.online' | translate }}</option>
                        <ng-template #notSelected>
                            <option value="active:true">{{ 'text.filter.accommodation.state.online' | translate }}</option>
                        </ng-template>
                        <option value="active:false:paused:false">{{ 'text.filter.accommodation.state.offline' | translate }}</option>
                        <option value="paused:true">{{ 'text.filter.accommodation.state.paused' | translate }}</option>
                        <option value="valid:false">{{ 'text.filter.accommodation.state.error' | translate }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                    {{ 'text.filter.elements.per.page' | translate }}:
                </div>
                <div class="col-sm-9">
                    <select class="form-control" [(ngModel)]="filterTakeValue" (change)="onChange($event.target.value)">
                        <option value="1">1</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                    </select>
                </div>
            </div>
            <button (click)="filterAccommodations(true)" class="btn btn-success filter-btn" type="button">
                <i class="fa fa-check"></i>
                {{ 'button.filter' | translate }}
            </button>
        </div>
    </div>
</div>