import { Component, Input, OnInit } from '@angular/core';
import { ArrivalInformationEntity, FromToTime } from 'data-structures/lib/es6/entity/arrival-information/arrival-information.entity';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { ExtendedConditions } from '../../../../../entities/extended-conditions';
import { KeyHandoverDto, KeyHandoverType } from 'data-structures/lib/es6/dto/arrival-information/key-handover.dto';
import { LanguageDto } from 'data-structures/lib/es6/dto/common/language.dto';
import { NotificationService } from '../../../../../services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { MediaDto } from 'data-structures/lib/es6/dto/accommodation';
import { MediaService } from '../../../../../services/media/media.service';
import { ImageTypeEnum } from 'data-structures/lib/es6/enum/image-type.enum';
import { OwnerContactDto } from 'data-structures/dto/owner/put-owner/owner-contact.dto';
import { cloneDeep } from 'lodash';

export enum ContactType {
    contactOwner,
    contactLocal,
}

@Component({
    selector: 'app-create-arrival-information',
    templateUrl: './create-arrival-information.component.html',
    styleUrls: ['./create-arrival-information.component.scss'],
})
export class CreateArrivalInformationComponent implements OnInit {
    @Input() arrivalInformation: ArrivalInformationEntity;
    errorMessage: string = '';
    arrivalByArrangement = false;
    arrivalRequired = false;
    departureRequired = false;
    departureByArrangement = false;
    contactPersonSelected = false;
    contactsLength: number = 0;
    contactList: any;
    clonedContactPerson: OwnerContactDto;

    constructor(
        readonly authService: AuthenticationService,
        readonly notificationService: NotificationService,
        readonly translateService: TranslateService,
        readonly apiConnector: ApiConnectorService,
        readonly mediaService: MediaService,
    ) {}

    ngOnInit() {}

    ngOnChanges(): void {
        this.clonedContactPerson = null;
        if (!this.arrivalInformation.conditions) {
            this.arrivalInformation.conditions = ExtendedConditions.createEmpty(this.authService.currentUser.ownerNumber);
        }

        if (!this.arrivalInformation.arrival) {
            this.arrivalInformation.arrival = new FromToTime();
        }

        if (!this.arrivalInformation.departure) {
            this.arrivalInformation.departure = new FromToTime();
        }

        if (!this.arrivalInformation.keyHandover) {
            this.arrivalInformation.keyHandover = new KeyHandoverDto();
            this.arrivalInformation.keyHandover.type = KeyHandoverType.Accommodation;
            this.arrivalInformation.keyHandover.shouldContactOwner = false;
        }

        if (!this.arrivalInformation.keyHandover.descriptions) {
            this.arrivalInformation.keyHandover.descriptions = new LanguageDto();
        }

        if (!this.arrivalInformation.directionsDescriptions) {
            this.arrivalInformation.directionsDescriptions = new LanguageDto();
        }

        if (!this.arrivalInformation.arrival.from && !this.arrivalInformation.arrival.to) {
            this.arrivalByArrangement = true;
            this.setArrivalInformations();
        } else {
            this.arrivalByArrangement = false;
        }

        if (!this.arrivalInformation.departure.from && !this.arrivalInformation.departure.to) {
            this.departureByArrangement = true;
            this.setDepartureInformations();
        } else {
            this.departureByArrangement = false;
        }

        if (this.arrivalInformation.contactPersonId) {
            this.contactPersonSelected = true;
            this.arrivalInformation.contactPerson = null; // Es kann nicht beides geben
        } else {
            this.contactPersonSelected = this.arrivalInformation.contactPerson ? true : false;
            if (!this.authService.currentUser.isImported()) {
                this.arrivalInformation.contactPerson = null; // Damit Vertragspartner greift
            }
        }

        this.contactList = Object.keys(this.authService.currentUser.addressBook.contacts);
        this.contactsLength = Object.keys(this.authService.currentUser.addressBook.contacts).length;
    }

    clearContactPerson() {
        this.arrivalInformation.contactPersonId = null;

        if (!this.clonedContactPerson && this.arrivalInformation.contactPerson) {
            this.clonedContactPerson = cloneDeep(this.arrivalInformation.contactPerson);
        }
        this.arrivalInformation.contactPerson = null;
    }

    async uploadFiles($event) {
        if ($event.target?.files) {
            for (const file of $event.target.files) {
                if (this.mediaService.isSuitable(file, ImageTypeEnum.ArrivalInformation)) {
                    const formData = new FormData();
                    formData.append('file', file, file.name);
                    this.arrivalInformation.files = await this.apiConnector.arrivalInformationUpload(this.authService.currentUser.ownerNumber, this.arrivalInformation.id, formData);
                }
            }

            $event.target.files = null;
            $event.target.value = '';
        }
    }

    async deleteFile(file: MediaDto) {
        await this.apiConnector.deleteArrivalInformationImage(file, this.authService.currentUser.ownerNumber, this.arrivalInformation.id);
        this.arrivalInformation.files = this.arrivalInformation.files.filter((media) => media.localUrl !== file.localUrl);
    }

    validate() {
        const form = document.getElementById('arrival-form');
        // @ts-ignore
        const result = form.checkValidity();

        if (!result || this.arrivalInformation.arrival.from > this.arrivalInformation.arrival.to || this.arrivalInformation.departure.from > this.arrivalInformation.departure.to) {
            // @ts-ignore
            form.reportValidity();
            this.errorMessage = 'validation.form.all.required';
            this.notificationService.add('validation.form.all.required', 'danger');
            return false;
        }

        this.errorMessage = '';

        return true;
    }

    setArrivalByArrangement($event) {
        const checked = $event.currentTarget.checked;

        if (checked) {
            this.setArrivalInformations();
        } else {
            this.arrivalRequired = true;
        }
    }

    setArrivalInformations() {
        this.arrivalInformation.arrival.from = '';
        this.arrivalInformation.arrival.to = '';
        this.arrivalRequired = false;
    }

    setDepartureByArrangement($event) {
        const checked = $event.currentTarget.checked;

        if (checked) {
            this.setDepartureInformations();
        } else {
            this.departureRequired = true;
        }
    }

    setDepartureInformations() {
        this.arrivalInformation.departure.from = '';
        this.arrivalInformation.departure.to = '';
        this.departureRequired = false;
    }

    setContactPerson() {
        this.arrivalInformation.contactPerson = this.clonedContactPerson ?? this.arrivalInformation.contactPerson;
    }
}
