import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { ApiConnectorService } from '../../../services/api-connector/api-connector.service';
import { ExtendedUserEntity } from '../../../entities/extendedUserEntity';
import { ConstantsService } from '../../../services/constants/constants.service';

@Component({
    selector: 'app-direct-login',
    templateUrl: './direct-login.component.html',
    styleUrls: ['./direct-login.component.scss'],
})
export class DirectLoginComponent implements OnInit {
    constructor(readonly route: ActivatedRoute, readonly authService: AuthenticationService, readonly router: Router, readonly apiConnector: ApiConnectorService) {}

    ngOnInit(): void {
        this.route.paramMap.subscribe(async (params) => {
            const accommodationId = params.get('accommodationId');
            const token = params.get('token');

            if (token) {
                this.authService.logout(false);
                const directUser = new ExtendedUserEntity();
                directUser.token = token;
                directUser.roles = [ConstantsService.ROLE_OWNERSERVICE];
                this.authService.currentUserSubject.next(directUser);
            }

            if (!accommodationId) {
                const ownerNumber = params.get('ownerNumber');
                if (ownerNumber) {
                    await this.authService.loadAndChangeToUser(Number(ownerNumber), token);
                    await this.router.navigate(['accommodations']);
                }
            } else {
                const user = await this.apiConnector.getUserDataByAccommodationId(accommodationId);
                await this.authService.loadAndChangeToUser(user.ownerNumber, token);
                await this.router.navigate(['accommodation/' + accommodationId]);
            }
        });
    }
}
