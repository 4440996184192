<h1>Objekt verschieben.</h1>

<div class="wrapper">
    <div class="row pt-2">
        <div class="col-md-3">
            <label for="transfer-to">zu Vermieter:</label>
        </div>
        <div class="col-md-2">
            <input type="text" [(ngModel)]="transferDto.newOwnerNumber" id="transfer-to">
        </div>
    </div>
    <div class="row pt-2" *ngIf="isAdminOverall">
        <div class="col-md-3">
            <label for="transfer-bookings">Buchungen kopieren <strong>(Datenschutz beachten!)</strong></label>
        </div>
        <div class="col-md-2">
            <input type="checkbox" [(ngModel)]="transferDto.bookings" id="transfer-bookings">
        </div>
    </div>
    <div class="row pt-2" *ngIf="isAdminOverall">
        <div class="col-md-3">
            <label for="transfer-ratings">Bewertungen kopieren</label>
        </div>
        <div class="col-md-2">
            <input type="checkbox" [(ngModel)]="transferDto.ratings" id="transfer-ratings">
        </div>
    </div>

    <p class="mt-5" *ngIf="transferDto?.accommodationId && transferDto?.oldOwnerNumber" [ngClass]="transferDto.newOwnerNumber ? '' : 'error'">
        Objekt {{transferDto.accommodationId}} jetzt kopieren von Vermieter {{ transferDto.oldOwnerNumber}} zu Vermieter {{ transferDto.newOwnerNumber }}
        <span *ngIf="transferDto.bookings"> mit Buchungen <strong>(enthält personenbezogene Daten)</strong></span>
        <span *ngIf="transferDto.ratings"> mit Kundenbewertungen</span>
    </p>
    <button class="btn btn-primary" (click)="startTransfer()"
                                             [disabled]="!transferDto.newOwnerNumber">
        Objekt jetzt zu anderem Vermieter verschieben
    </button>

</div>

