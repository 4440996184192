<form [formGroup]="priceForm" *ngIf="priceForm" (ngSubmit)="onSubmit()">
    <h3>{{ 'atraveo.accommodationbundle.price.create.step1' | translate }}</h3>
    <div class="m-3">
        <div class="row">
            <div class="col-lg-3 col-6">
                <label for="travelPeriodFrom">{{ 'atraveo.accommodationbundle.price.create.period.field.dateFrom' | translate }}</label>
                <input
                    class="form-control"
                    type="date"
                    min="{{ minDateFrom }}"
                    max="{{ maxDateFrom }}"
                    id="travelPeriodFrom"
                    formControlName="travelPeriodFrom"
                    [class.highlight]="highlightTravelPeriodFrom"
                    (animationend)="resetHighlight('travelPeriodFrom')"
                >
                <app-validation-error-messages [control]="priceForm.get('travelPeriodFrom')"></app-validation-error-messages>
            </div>
            <div class="col-lg-3 col-6">
                <label for="travelPeriodTo">{{ 'atraveo.accommodationbundle.price.create.period.field.dateTo' | translate }}</label>
                <input
                    class="form-control"
                    type="date"
                    min="{{ minDateTo }}"
                    max="{{ maxDateTo }}"
                    id="travelPeriodTo"
                    formControlName="travelPeriodTo"
                    [class.highlight]="highlightTravelPeriodTo"
                    (animationend)="resetHighlight('travelPeriodTo')"
                >
                <app-validation-error-messages [control]="priceForm.get('travelPeriodTo')"></app-validation-error-messages>
            </div>
            <div class="col-lg-6 col-12">
                <div class="form-check mt-lg-4">
                    <input
                        type="checkbox"
                        formControlName="followingYears"
                        id="input-following-year"
                        class="form-check-input">
                    <label for="input-following-year" class="form-check-label">
                        {{ 'atraveo.accommodationbundle.price.create.period.date-range.ignore-year' | translate}}
                    </label>
                </div>
            </div>
            <app-validation-error-messages class="ml-3" [control]="priceForm"></app-validation-error-messages>
            <div class="col-12" *ngIf="!showSeasons">
                <a class="btn btn-link pl-0" (click)="toggleSeasons()">
                    {{ 'atraveo.accommodationbundle.price.create.season.show' | translate }}
                </a>
            </div>
            <div class="col-12 mt-3 form-group" *ngIf="seasons && showSeasons">
                <select
                    class="form-control w-lg-50"
                    id="seasonId"
                    formControlName="seasonId">
                    <option value="">{{ 'atraveo.accommodationbundle.price.create.chooseSeason' | translate }}</option>
                    <ng-container *ngFor="let season of seasons.seasons">
                        <option *ngIf="season.seasonId > 0" value="{{ season.seasonId }}">
                            {{ season.name[this.translationService.currentLang]}}
                            <ng-container *ngIf="season.travelPeriods[0]?.from && season.travelPeriods[0]?.to">
                                ({{ season.travelPeriods[0].from | stringToDate | date}} - {{ season.travelPeriods[0].to | stringToDate | date }})
                            </ng-container>
                        </option>
                    </ng-container>
                </select>
                <a class="btn btn-link pl-0" (click)="toggleSeasons()">
                    {{ 'atraveo.accommodationbundle.price.create.season.hide' | translate }}
                </a>
            </div>
        </div>
    </div>
    <hr *ngIf="step1Completed">
    <h3 class="mt-3" *ngIf="step1Completed">{{ 'atraveo.accommodationbundle.price.create.step2daily' | translate }}</h3>
    <div class="m-3" *ngIf="step1Completed">
        <div *ngIf="authService.userIsFromCh()">
            <p><b>{{ 'atraveo.accommodationbundle.price.create.additionForCH.notice.title' | translate }}</b><br>
                {{ 'atraveo.accommodationbundle.price.create.additionForCH.notice.text' | translate }}</p>
            <p><b>{{ 'atraveo.accommodationbundle.price.create.additionForCH.suggestion.title' | translate }}</b><br>
                {{ 'atraveo.accommodationbundle.price.create.additionForCH.suggestion.text' | translate }}</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-12">
                <div class="form-group">
                    <div class="row">
                        <div class="col-12">
                            <label for="price">{{ 'atraveo.accommodationbundle.price.create.pricePerNight' | translate }}</label>
                            <div class="input-group w-50">
                                <input
                                    type="number"
                                    step="0.01"
                                    class="form-control"
                                    id="price"
                                    formControlName="price">
                                <div class="input-group-append">
                                    <span class="input-group-text">{{ ownerCurrency }}</span>
                                </div>
                            </div>
                            <app-validation-error-messages [control]="priceForm.get('price')"></app-validation-error-messages>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 form-group" *ngIf="!showWeekendPrice">
                <a class="btn btn-link" (click)="toggleWeekendPrice()">
                    {{ 'atraveo.accommodationbundle.price.create.weekendprice.' + showWeekendPriceToggleDescription | translate }}
                </a>
            </div>

            <div class="col-lg-4 col-12">
                <div class="row" *ngIf="showWeekendPrice">
                    <div class="col-12 form-group">
                        <label for="priceWeekend">{{ 'atraveo.accommodationbundle.price.create.pricePerWeekendNight' | translate }}</label>
                        <div class="input-group w-50">
                            <input
                                type="number"
                                step="0.01"
                                class="form-control"
                                id="priceWeekend"
                                formControlName="priceWeekend">
                            <div class="input-group-append">
                                <span class="input-group-text">{{ ownerCurrency }}</span>
                            </div>
                        </div>
                        <app-validation-error-messages [control]="priceForm.get('priceWeekend')"></app-validation-error-messages>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr *ngIf="step2Completed">
    <h3 class="mt-3" *ngIf="step2Completed">{{ 'atraveo.accommodationbundle.price.create.step3' | translate }}</h3>
    <div class="m-3" *ngIf="step2Completed">
        <div class="row">
            <div class="col-12 col-lg-4">
                <div class="form-check form-group">
                    <input type="radio"
                       value="booking"
                       formControlName="priceType"
                       id="priceTypeBooking"
                       class="form-check-input"
                    >
                    <label class="form-check-label" for="priceTypeBooking">
                        {{ 'atraveo.accommodationbundle.price.perBookingOrPersons.booking' | translate }}
                    </label>
                </div>
                <div class="form-check form-group">
                    <input type="radio"
                           value="person"
                           formControlName="priceType"
                            class="form-check-input"
                            id="priceTypePerson">
                    <label class="form-check-label" for="priceTypePerson">
                        {{ 'atraveo.accommodationbundle.price.perBookingOrPersons.person' | translate }}
                    </label>
                </div>
            </div>

            <div class="col-12 col-lg-8">
                <div *ngIf="priceForm.get('priceType').value === 'person'" class="row form-group">
                    <div class="col-12 col-lg-5 form-group">
                        <label for="maxPersons">{{ 'atraveo.accommodationbundle.price.create.upToPersons.0' | translate }}</label>
                        <select class="form-control"
                                id="maxPersons"
                                formControlName="maxPersons">
                            <option value="0">{{ 'atraveo.accommodationbundle.price.create.choosePersons' | translate }}</option>
                            <option *ngFor="let person of getPersonRange()" value="{{ person }}">
                                <ng-template [ngIf]="person === 1">
                                    {{ 'atraveo.accommodationbundle.price.create.forPersons.1' | translate }}
                                </ng-template>
                                <ng-template [ngIf]="person > 1">
                                    {{ 'atraveo.accommodationbundle.price.create.forPersons.count' | translate:{count: person} }}
                                </ng-template>
                            </option>
                        </select>
                        <app-validation-error-messages [control]="priceForm.get('maxPersons')"></app-validation-error-messages>
                    </div>
                    <div class="col-12 col-lg-7 form-group">
                        <label for="pricePerAdditionalPerson">{{ 'atraveo.accommodationbundle.price.create.perAdditionalPerson' | translate }}</label>
                        <div class="input-group w-lg-50">
                            <input
                                type="number"
                                step="0.01"
                                class="form-control"
                                id="pricePerAdditionalPerson"
                                formControlName="pricePerAdditionalPerson">
                            <div class="input-group-append">
                                <span class="input-group-text">{{ ownerCurrency }}</span>
                            </div>
                        </div>
                        <app-validation-error-messages [control]="priceForm.get('pricePerAdditionalPerson')"></app-validation-error-messages>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr *ngIf="priceInformations && priceInformations.length">
    <div class="mt-3" *ngIf="priceInformations && priceInformations.length">
        {{ 'atraveo.accommodationbundle.price.create.periodmetadata.headline.chosenperiod' | translate }}
        <table class="table responsive-table">
            <thead>
            <tr>
                <th>{{ 'atraveo.accommodationbundle.price.field.daterange.0' | translate }}</th>
                <th>{{ 'atraveo.accommodationbundle.price.arrivaldays.minimum.rental' | translate }}</th>
                <th>{{ 'atraveo.accommodationbundle.price.arrivaldays.arrival.days' | translate }}</th>
            </tr>
            </thead>
            <tr *ngFor="let priceinformation of priceInformations">
                <td [attr.data-label]="'atraveo.accommodationbundle.price.field.daterange.0' | translate">
                    <span *ngIf=" priceinformation.travelPeriod?.from &&  priceinformation.travelPeriod?.to">
                        {{  priceinformation.travelPeriod?.from | date }} - {{  priceinformation.travelPeriod?.to | date }}
                    </span>
                    <span *ngIf="!(priceinformation.travelPeriod?.from &&  priceinformation.travelPeriod?.to)">
                        {{'atraveo.accommodationbundle.price.periodMetaData.deafult-type' | translate}}
                    </span>
                </td>
                <td [attr.data-label]="'atraveo.accommodationbundle.price.arrivaldays.minimum.rental' | translate">
                    {{ priceinformation.minDuration }}
                </td>
                <td [attr.data-label]="'atraveo.accommodationbundle.price.arrivaldays.arrival.days' | translate">
                    <div *ngIf="priceinformation.arrivalDays">
                        <span *ngIf="priceinformation.arrivalDays[0] && priceinformation.arrivalDays[0] === 'Y'">
                            {{ 'weekday.so' | translate }}
                        </span>
                        <span *ngIf="priceinformation.arrivalDays[1] && priceinformation.arrivalDays[1] === 'Y'">
                            {{ 'weekday.mo' | translate }}
                        </span>
                        <span *ngIf="priceinformation.arrivalDays[2] && priceinformation.arrivalDays[2] === 'Y'">
                            {{ 'weekday.di' | translate }}
                        </span>
                        <span *ngIf="priceinformation.arrivalDays[3] && priceinformation.arrivalDays[3] === 'Y'">
                            {{ 'weekday.mi' | translate }}
                        </span>
                        <span *ngIf="priceinformation.arrivalDays[4] && priceinformation.arrivalDays[4] === 'Y'">
                            {{ 'weekday.do' | translate }}
                        </span>
                        <span *ngIf="priceinformation.arrivalDays[5] && priceinformation.arrivalDays[5] === 'Y'">
                            {{ 'weekday.fr' | translate }}
                        </span>
                        <span *ngIf="priceinformation.arrivalDays[6] && priceinformation.arrivalDays[6] === 'Y'">
                            {{ 'weekday.sa' | translate }}
                        </span>
                    </div>
                </td>
            </tr>
        </table>
        <p>{{ 'atraveo.accommodationbundle.price.create.periodmetadata.description' | translate }}</p>
    </div>

    <div>
        <button
            type="submit"
            class="btn btn-success float-right save-button"
        >
            <i class="fas fa-cloud-upload-alt mr-2"></i>
            {{ 'atraveo.accommodationbundle.price.create.save' | translate }}
        </button>
    </div>
</form>
