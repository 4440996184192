<div class="banner {{ this.authService.currentUser.registration.idNowStatusText }}"  [class.inForm] = "inForm" *ngIf="this.authService.currentUser?.registration?.idNowStatusText">
  <p>
    <i class="fas fa-exclamation-triangle" *ngIf="['CREATED', 'CLICKED', 'ABORTED'].includes(this.authService.currentUser?.registration?.idNowStatusText)"></i>
    <i class="fas fa-times-circle" *ngIf="['FRAUD_SUSPICION_CONFIRMED'].includes(this.authService.currentUser?.registration?.idNowStatusText)"></i>
    <i class="fas fa-check" *ngIf="['SUCCESS', 'SUCCESS_DATA_CHANGED'].includes(this.authService.currentUser?.registration?.idNowStatusText)"></i>
    <span *ngIf="this.authService.currentUser?.registration?.idNowStatusText !== 'CLICKED'">
      {{ 'text.idnow.status.' + this.authService.currentUser.registration.idNowStatusText | translate }}
    </span>
    <span *ngIf="this.authService.currentUser?.registration?.idNowStatusText === 'CLICKED'">
      {{ 'text.idnow.status.CREATED' | translate }}
    </span>
    <span *ngIf="['FRAUD_SUSPICION_CONFIRMED'].includes(this.authService.currentUser?.registration?.idNowStatusText)" class="whatnow">
      <a href="#" data-toggle="modal" data-target="#whatnow-modal">({{ 'text.idnow.whatnow' | translate}})</a>
    </span>
    <span *ngIf="['CREATED', 'CLICKED', 'ABORTED'].includes(this.authService.currentUser?.registration?.idNowStatusText) && this.authService.currentUser?.registration?.idNowLink">
      <a target="_blank" href="{{  this.authService.currentUser.registration.idNowLink }}" (click)="changeStatus()">&raquo; {{ 'text.idnow.startnow' | translate }} &laquo;</a>
    </span>
    <span *ngIf="this.requestedNewIdNowCheck">
      {{ 'text.idnow.started' | translate }}
    </span>
  </p>
</div>
<div class="alert info" *ngIf="!this.authService.currentUser?.state?.checkedByOwnerService">
    <i class="fas fa-info-circle"></i>
    {{ 'atraveo.dashboardbundle.text.banner.verificationInfo' | translate }}
</div>

<div class="modal fade" id="whatnow-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [innerHTML]="'text.modal.whatnow' | translate">
      </div>
    </div>
  </div>
</div>