import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProgressCheckService {
    private subject = new Subject<any>();

    constructor() {}

    reloadProgressCheck() {
        this.subject.next(null);
    }

    getNewProgressState(): Observable<any> {
        return this.subject.asObservable();
    }
}
