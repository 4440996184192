import { Exclude } from 'class-transformer';
import { v4 as uuidv4 } from 'uuid';
import { AdditionalCostDto } from 'data-structures/lib/es6/dto/accommodation';

export enum AdditionalCostSource {
    GENERATED = 'generated', // Von Vakanzvorlage erstellt
    MANDATORY_COST_AUTO_CREATED = 'mandantory_auto_created' // Pflicht-Nebenkosten werden automatisch angelegt, nur im VRMB vorhanden

}

export class ExtendedAdditionalCost extends AdditionalCostDto {
    @Exclude()
    id: string = uuidv4(); // Nur für VRMB, nicht im Inventory
    @Exclude()
    index: number; // Nur für VRMB, nicht im Inventory
    @Exclude()
    deletable: boolean = true;
}
