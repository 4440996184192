<ng-container *ngIf="accommodation">
    <form [formGroup]="mainForm" (ngSubmit)="submitForm()">
        <div class="row">
            <div class="col-xl-5 col-12">
                <div class="mt-3">
                    <label for="input-name">{{ 'atraveo.accommodationbundle.description.ownName.label' | translate }}</label>
                    <app-tooltip [additionalClassList]="'mr-2'" [text]="'info.eigenbezeichnung' | translate"></app-tooltip>
                    <div class="input-group">
                        <input id="input-name"
                               class="form-control"
                               type="text"
                               name="input-name"
                               formControlName="name">
                    </div>
                </div>

                <div class="mt-5">
                    <app-feature-select [form]="mainForm" formControlName="type" featureId="HAUSART"></app-feature-select>
                </div>

                <div class="mt-3">
                    <app-feature-input [form]="mainForm" formControlName="maxPersons" featureId="MAXPERSON"></app-feature-input>
                    <div *ngIf="this.mainFeatureError" class="alert alert-warning alert-form">
                        {{ 'text.feature.form.MAXPERSON.ROOMS_2.start' | translate }}
                        <a routerLink="/accommodation/{{ accommodation.accommodationId }}/features">{{ 'atraveo.accommodationbundle.navigation.feature.title' | translate }}</a>
                        {{ 'text.feature.form.MAXPERSON.ROOMS_2.end' | translate }}
                    </div>
                </div>

                <div class="mt-3">
                    <app-feature-input [form]="mainForm" formControlName="minChildren" featureId="KINDER"></app-feature-input>
                </div>

                <div class="mt-3">
                    <app-feature-input [form]="mainForm" formControlName="livingSpace" featureId="WHNFLAECHE"></app-feature-input>
                </div>

                <div class="mt-5">
                    <label for="licenseNumber">{{ 'atraveo.accommodationbundle.description.additionalData.0' | translate }}</label>
                    <div class="input-group">
                        <input
                            id="licenseNumber"
                            name="licenseNumber"
                            class="form-control"
                            type="text"
                            formControlName="licenseNumber"
                        >
                    </div>
                    <app-validation-error-messages [control]="mainForm.get('licenseNumber')"></app-validation-error-messages>
                </div>

                <div *ngIf="accommodation.position?.geo?.countryId === 'CH'" class="mt-3">
                    <label for="classifications">{{ 'text.classification.stv' | translate }}</label>
                    <div class="input-group">
                        <select class="form-control" id="classifications" formControlName="classification">
                            <option value="">{{ 'text.select.empty' | translate }}</option>
                            <option *ngFor="let classification of classificationOptions" [value]="classification?.value">
                                <span *ngIf="classification?.value !== '0'">{{ classification?.value.substring(0, 1) }}</span> {{ 'text.classification.' + classification?.textKey | translate }}
                            </option>
                        </select>
                    </div>
                    <app-validation-error-messages [control]="mainForm.get('classification')"></app-validation-error-messages>
                </div>

                <div class="mt-3">
                    <label for="ownerAccommodationId">{{ 'atraveo.accommodationbundle.description.ownId.label' | translate }}</label>
                    <app-tooltip [additionalClassList]="'mr-2'" [text]="'info.eigenbezeichnung' | translate"></app-tooltip>
                    <div class="input-group">
                        <input
                            id="ownerAccommodationId"
                            name="ownerAccommodationId"
                            class="form-control"
                            type="text"
                            formControlName="ownerAccommodationId"
                        >
                    </div>
                    <app-validation-error-messages [control]="mainForm.get('ownerAccommodationId')"></app-validation-error-messages>
                </div>

                <div class="mt-5">
                    <label for="accommodationUrl">{{ 'atraveo.accommodationbundle.description.accommodationUrl.0' | translate }}</label>
                    <div class="input-group">
                        <input
                            id="accommodationUrl"
                            name="accommodationUrl"
                            class="form-control"
                            type="text"
                            formControlName="accommodationUrl"
                        >
                    </div>
                    <app-validation-error-messages [control]="mainForm.get('accommodationUrl')"></app-validation-error-messages>
                </div>

                <div *ngIf="authenticationService.currentUser?.isEmployee()" class="mt-3">
                    <app-feature-input [form]="mainForm" formControlName="contingent" featureId="KONTINGENT"></app-feature-input>
                </div>

                <div class="mt-5" *ngIf="contactsLength > 0">
                    <label for="contactPerson">{{ 'atraveo.accommodationbundle.description.contact.label' | translate }}</label>
                    <app-tooltip data-placement="right" [additionalClassList]="'mr-2'" [text]="'info.contactPerson' | translate"></app-tooltip>
                    <select
                        class="form-control"
                        formControlName="contactPerson"
                        name="contactPerson"
                        id="contactPerson">
                        <option value="">{{ 'text.select.empty' | translate }}</option>
                        <ng-container *ngFor="let contactId of contactList; let i = index">
                            <option *ngIf="contactId != this.authenticationService.currentUser.addressBook?.primaryId" value="{{ contactId }}">
                                {{ this.authenticationService.currentUser.addressBook.contacts[contactId]?.forename }} {{ this.authenticationService.currentUser.addressBook.contacts[contactId]?.surname }}
                            </option>
                        </ng-container>
                    </select>
                    <app-validation-error-messages [control]="mainForm.get('contactPerson')"></app-validation-error-messages>
                </div>

            </div>
        </div>

        <div class="text-right clear mt-3">
            <button type="submit" class="btn btn-success"><i class="fas fa-cloud-upload-alt mr-2"></i>{{ 'button.save' | translate }}</button>
        </div>
    </form>
</ng-container>
