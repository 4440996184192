import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shyReplace'
})
export class ShyReplacePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
      if (window.innerWidth < 1024) {
          if (value?.includes('- ') || value?.includes('-')) {
              return value.replace(/- |-/g, '');
          }
      } else {
          if (value?.includes('- ') || value?.includes('-')) {
              return value.replace(/- |-/g, '&shy;');
          }
      }

      return value;
  }

}
