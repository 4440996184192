import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { BookingConnectorService } from '../../../../../services/api-connector/booking-connector.service';
import { BookingEntity } from 'data-structures/lib/es6/entity/booking.entity';
import { BookingPaymentStatusEnum } from 'data-structures/lib/es6/enum/booking-payment-status.enum';
import { ChannelEnum } from 'data-structures/lib/es6/enum/channel.enum';
import { FellowCustomerDto } from 'data-structures/lib/es6/dto/fellow-customer.dto';
import { BookingDatesDto } from 'data-structures/lib/es6/dto/booking-dates.dto';
import { PriceDetailDto } from 'data-structures/lib/es6/dto/price-detail.dto';
import { PriceDto } from 'data-structures/lib/es6/dto/price.dto';
import { CustomerDto } from 'data-structures/lib/es6/dto/customer.dto';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { AccommodationUpdateService } from '../../../../../services/accommodation-update.service';
import { ComponentsEnum, FindAccommodationRequestDto } from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-request.dto';
import { StatusEnum } from 'data-structures/lib/es6/enum/status.enum';
import { BookingUpdateService } from '../../../../../services/booking-update.service';
import { DateService } from '../../../../../services/date/date.service';
import { DialogService } from '../../../../../services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-create-booking',
    templateUrl: './create-booking.component.html',
    styleUrls: ['./create-booking.component.scss'],
})
export class CreateBookingComponent implements OnInit, OnChanges {
    booking: BookingEntity;
    @Input() fromCalender: boolean = false;
    id: string; // Wird an die ID des Modals drangehängt
    @Output() saveEvent = new EventEmitter();
    currencies = environment.additionalCostCurrencies;
    languages = environment.customerLanguages;
    wrongAccommodationId: boolean = false;
    ownerCurrency: any = this.authenticationService.currentUser.getOwnerCurrency();

    constructor(
        readonly translationService: TranslateService,
        readonly bookingConnectorService: BookingConnectorService,
        readonly authenticationService: AuthenticationService,
        readonly apiConnectorService: ApiConnectorService,
        readonly accommodationUpdateService: AccommodationUpdateService,
        readonly bookingUpdateService: BookingUpdateService,
        readonly dialogService: DialogService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {
        this.id = this.data.bookingStatusType;
        this.fromCalender = this.data.fromCalender;
        if (this.data.newOrEditBooking) {
            this.booking = this.data.newOrEditBooking;
            if (this.fromCalender) {
                this.addMissingFields();
            }
        } else {
            this.addMissingFields();
        }
    }
    getPaymentStatus() {
        return BookingPaymentStatusEnum;
    }

    ngOnChanges() {
        this.addMissingFields();
    }

    async saveBooking() {
        if (!this.booking.accommodationId) {
            const input: any = document.getElementById('createBookingSearch-' + this.id);
            if (input?.value) {
                this.booking.accommodationId = Number(input.value);
            }
        }

        if (!this.booking.bookingId && this.booking?.priceDetail && Object.keys(this.booking.priceDetail).length) {
            for (const [key, value] of Object.entries(this.booking.priceDetail)) {
                if (value.currency) {
                    value.currency = this.ownerCurrency;
                }
            }
        }

        // Prüfen ob die Objektnummer auch existiert und zum Vermieter gehört
        const dto = new FindAccommodationRequestDto();
        dto.accommodationId = [Number(this.booking.accommodationId)];
        dto.ownerNumber = this.authenticationService.currentUser.ownerNumber;
        dto.components = [ComponentsEnum.AccommodationId];

        const result = await this.apiConnectorService.findAccommodations(dto);

        // Wenn man über den Kalender die Buchung anlegt, gibt es das Input nicht
        const input = document.getElementById('createBookingSearch-' + this.id);
        if (input && !result.accommodations?.length) {
            this.wrongAccommodationId = true;
            input.classList.add('error');
            return;
        } else if (input) {
            input.classList.remove('error');
            this.wrongAccommodationId = false;
        }
        const bookingModal = $('#create-booking' + this.id);
        if (bookingModal) {
            // @ts-ignore
            bookingModal.modal('hide');
        }
        if (this.booking.priceDetail.price && !this.booking.priceDetail.price.amount) {
            delete this.booking.priceDetail.price;
        }

        if (this.booking.priceDetail.additionalPrice && !this.booking.priceDetail.additionalPrice.amount) {
            delete this.booking.priceDetail.additionalPrice;
        }

        if (this.booking.priceDetail.locationPrice && !this.booking.priceDetail.locationPrice.amount) {
            delete this.booking.priceDetail.locationPrice;
        }

        if (this.booking.customer?.pets) {
            this.booking.customer.pets = Number(this.booking.customer.pets);
        }

        if (typeof this.booking.priceDetail?.price?.amount === 'string') {
            this.booking.priceDetail.price.amount = Number(this.booking.priceDetail.price.amount);
        }

        if (typeof this.booking.priceDetail?.additionalPrice?.amount === 'string') {
            this.booking.priceDetail.additionalPrice.amount = Number(this.booking.priceDetail.additionalPrice.amount);
        }

        if (typeof this.booking.priceDetail?.locationPrice?.amount === 'string') {
            this.booking.priceDetail.locationPrice.amount = Number(this.booking.priceDetail.locationPrice.amount);
        }

        if (typeof this.booking.accommodationId === 'string') {
            this.booking.accommodationId = Number(this.booking.accommodationId);
        }

        if (this.booking.arrivalDate && this.booking.departureDate && DateService.getDateFromString(this.booking.departureDate) < DateService.getDateFromString(this.booking.arrivalDate)) {
            const temp = this.booking.arrivalDate;
            this.booking.arrivalDate = this.booking.departureDate;
            this.booking.departureDate = temp;
        }

        this.booking.status = StatusEnum.Confirmed;

        await this.bookingConnectorService.saveBooking(this.booking);

        if (this.booking.accommodationId) {
            const accommodation = await this.apiConnectorService.getAccommodationByAccommodationId(this.booking.accommodationId, this.authenticationService.currentUser, true);
            this.accommodationUpdateService.sendUpdatedMessage(accommodation);
        }

        this.bookingUpdateService.sendCreatedMessage(this.booking);

        this.booking = new BookingEntity();
        this.addMissingFields();
        this.booking.channel = ChannelEnum.SelfManaged;

        this.saveEvent.emit();
        this.dialogService.closeDialog();
    }

    addMissingFields() {
        if (!this.booking) {
            this.booking = new BookingEntity();
        }

        this.booking.channel = ChannelEnum.SelfManaged;
        if (this.authenticationService.currentUser) {
            this.booking.ownerNumber = this.authenticationService.currentUser.ownerNumber;
        }

        if (!this.booking.bookingDates) {
            this.booking.bookingDates = new BookingDatesDto();
            if (!this.booking.bookingDates.bookingDate) {
                this.booking.bookingDates.bookingDate = new Date();
            }
        }

        if (!this.booking.priceDetail) {
            this.booking.priceDetail = new PriceDetailDto();
        }

        if (!this.booking.priceDetail.locationPrice) {
            this.booking.priceDetail.locationPrice = new PriceDto();
        }

        if (!this.booking.priceDetail.additionalPrice) {
            this.booking.priceDetail.additionalPrice = new PriceDto();
        }

        if (!this.booking.priceDetail.price) {
            this.booking.priceDetail.price = new PriceDto();
        }

        if (!this.booking.customer) {
            this.booking.customer = new CustomerDto();
        }

        this.booking.priceDetail.locationPrice.currency = this.ownerCurrency as any;
    }

    addFellowTravellers() {
        if (!this.booking.fellowCustomers) {
            this.booking.fellowCustomers = [];
        }
        this.booking.fellowCustomers.push(new FellowCustomerDto());
    }

    deleteFellowTraveler(i: number) {
        this.booking.fellowCustomers.splice(i, 1);
    }

    onSearch($event: any) {
        this.booking.accommodationId = Number($event);
        const inputs = document.getElementsByClassName('booking-search');
        if (inputs.length) {
            // @ts-ignore
            for (const input of inputs) {
                input.classList.remove('error');
            }
        }
        this.wrongAccommodationId = false;
    }

    closeDialog() {
        this.dialogService.closeDialog();
    }
}
