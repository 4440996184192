import { AfterViewInit, Component, NgZone, OnChanges, OnInit } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../entities/extendedAccommodationEntity';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { ConstantsService } from '../../../../../services/constants/constants.service';
import { Feature } from '../../../../../entities/feature';
import { FeatureDto, RoomDto } from 'data-structures/lib/es6/dto/accommodation';
import { FeatureGroupDefinitionsDto } from 'data-structures/lib/es6/dto/accommodation/feature/feature-group-definitions.dto';
import { FeatureDefinitionEnum, FeatureTypeInputEnum } from 'data-structures/lib/es6/dto/accommodation/feature/feature-definition.dto';
import { ConfirmationDialogService } from '../../../../global/confirmation-dialog/confirmation-dialog.service';
import { SourceTypeEnum } from 'data-structures/lib/es6/enum/source-type.enum';
import { OwnerImportTypeEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../../../../services/notification/notification.service';
import { Observable, Subscription } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash';
import { ComponentCanDeactivate } from '../../../../../guards/pending-changes-guard';
import { FeatureDefinitionsDto } from 'data-structures/lib/es6/dto/accommodation/feature/feature-definitions.dto';
declare const $: any;

import { PendingChangesComponentService } from '../../../../../services/pending-changes-component/pending-changes-component.service';

/** Kategorien aus den Featuredefinitionen im Backoffice / VRMB */
export enum RoomTypes {
    BedRoom = 2,
    BathRoom = 3,
}

export enum RoomFeatures {
    BedRoom = 'SZ',
    BathRoom = 'BZ',
}

interface AccommodationFeatures {
    [key: string]: FeatureDto;
}

export class OwnerRoom {
    features: FeatureDto[] = [];
    roomType: number;
    key: number;
}

interface Rooms {
    [key: string]: OwnerRoom;
}

interface RoomsByCategory {
    [key: string]: Rooms;
}

// tslint:disable-next-line:max-classes-per-file
@Component({
    selector: 'app-features',
    templateUrl: './features.component.html',
    styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    /** Die Räume (Schlafzimmer / Badezimmer sind leider super kompliziert:
     * Im Import werden Räume angelegt, jeder Raum hat einen Raumtypen. Siehe: http://jira.wlng.cu.ennit.net:7990/projects/IMP/repos/asellus-main/raw/app/lib/Asellus/Vendor/Inventory/Flyweight/Enum/RoomTypes.php
     * Diese Raumtypen gibt es so nicht im VRMB, im VRMB gibt es nur diese zwei Räume:
     * Schlafzimmer: Featurekategorie 2 in der features.json, zugeordnetes Merkmal SZ
     * Badezimmer: Featurekategorie 3 in der features.json, zugeordnetes Merkmal BZ
     *
     * Die Zuordnung RaumTyp zu Kategoie erfolgt über groupIdsToRoomTypeIds
     *
     * Damit man damit einigermaßen im VRMB arbeiten kann werden die Features und Räume aus den Accommodations in eine andere
     * Struktur gepackt (in ngOnChanges): this.rooms. Bei jeder Änderung müssen diese dann wieder zurück in die Accommodation gespeichert werden.
     */
    accommodation: ExtendedAccommodationEntity;
    accommodationClone: ExtendedAccommodationEntity;
    accommodationOriginal: ExtendedAccommodationEntity;

    accommodationFeatures: AccommodationFeatures = {};
    featureDefinitionGroups: FeatureGroupDefinitionsDto[];
    visibilityRules = {};
    checkInternetOption: boolean = false;
    rooms: RoomsByCategory = {
        [RoomTypes.BedRoom]: {},
        [RoomTypes.BathRoom]: {},
    };
    // RaumTypen, die sind natürlich völlig anders als in den FeatureDefinitionen :(
    // http://bitbucket.wolters-rundreisen.de:7990/projects/IMP/repos/asellus-main/raw/app/lib/Asellus/Vendor/Inventory/Flyweight/Enum/RoomTypes.php
    bathRoomTypeIds = [7, 32, 33, 34, 36];
    sleepRoomTypeIds = [4, 31, 35];
    groupIdsToRoomTypeIds = {
        [RoomTypes.BedRoom]: this.sleepRoomTypeIds,
        [RoomTypes.BathRoom]: this.bathRoomTypeIds,
    };
    groupIdToFeature = {
        [RoomTypes.BedRoom]: RoomFeatures.BedRoom,
        [RoomTypes.BathRoom]: RoomFeatures.BathRoom,
    };
    groupIdToRoomTypeId = {
        [RoomTypes.BedRoom]: 4,
        [RoomTypes.BathRoom]: 7,
    };

    selectedValue = {
        [RoomTypes.BedRoom]: null,
        [RoomTypes.BathRoom]: null,
    };

    roomsLength = {
        [RoomTypes.BedRoom]: null,
        [RoomTypes.BathRoom]: null,
    };

    requiredFeatures: string[] = [];
    internetOptions = ['INETKABEL', 'INETUSB', 'NOINTERNET', 'WLAN', 'WLANTEILW', 'INTERNETNU'];
    featureError: any = {};
    featureCloneError: any = {};
    featureErrorIds: number[] = [];
    featureErrorIdNames: string[] = [];
    accommodationsAreImported: boolean = false;
    currentLanguage: string = this.translationService.currentLang;
    roomsCounter = Array;
    currentYear: number;
    yearOfConstructionError: boolean = false;
    yearOfConstructionModernError: boolean = false;
    yearOfRenovationError: boolean = false;
    allFieldsReadonly: boolean = false;
    saveTriggerSubscription: Subscription;
    accommodationSubscription: Subscription;
    featureDefinitions: FeatureDefinitionsDto;
    translationServiceSubscription: Subscription;

    constructor(
        readonly apiConnectorService: ApiConnectorService,
        readonly translateService: TranslateService,
        readonly authenticationService: AuthenticationService,
        readonly constants: ConstantsService,
        readonly translationService: TranslateService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly ngZone: NgZone,
        readonly route: ActivatedRoute,
        readonly notificationService: NotificationService,
        readonly pendingChangesComponentService: PendingChangesComponentService,
    ) {}

    async ngOnInit() {
        this.accommodationSubscription = this.apiConnectorService.activeAccommodation$.subscribe(async (accommodation) => {
            if (accommodation) {
                this.accommodation = cloneDeep(accommodation);
                this.accommodationClone = cloneDeep(accommodation);
                this.accommodationOriginal = cloneDeep(accommodation);

                if (!this.featureDefinitions) {
                    this.featureDefinitions = await this.apiConnectorService.getFeatureDefinitions().then((featureDefinitions) => {
                        return featureDefinitions;
                    });
                }
                this.setFeaturesDefinition();
                this.ngOnChanges();
            }
        });

        this.saveTriggerSubscription = this.pendingChangesComponentService.getSaveTriggerObservable().subscribe(async () => {
            await this.save();
        });
    }

    ngOnDestroy() {
        this.saveTriggerSubscription?.unsubscribe();
        this.accommodationSubscription?.unsubscribe();
        this.translationServiceSubscription?.unsubscribe();
    }

    keepOriginalOrder = (a, b) => {
        return 0;
    };

    ngOnChanges() {
        this.requiredFeatures = ['SZ', 'TIERE', 'ROOMFEATURES'];
        if (!this.authenticationService.currentUser.isImported()) {
            this.requiredFeatures.push('KUECHENUTZ');
        }

        const featureErrorObjectCheck = this.accommodation.checkResults?.find((objectError) => Number(objectError.checkId) === 55);

        this.accommodationsAreImported = this.authenticationService.currentUser?.settings?.importType === OwnerImportTypeEnum.Imported;

        if (Object.keys(this.accommodation).length) {
            const accommodationFeatures: AccommodationFeatures = {};
            this.rooms = {
                [RoomTypes.BedRoom]: {},
                [RoomTypes.BathRoom]: {},
            };

            if (this.accommodation.features && this.accommodation.features.length) {
                for (const feature of this.accommodation.features) {
                    accommodationFeatures[feature.featureId] = feature;

                    // Räume anlegen und Features zuordnen
                    if (feature.roomId && feature.featureId) {
                        const roomType = this.getRoomTypeFromRoomId(Number(feature.roomId), feature);
                        const categoryId = this.getCategoryIdFromRoomType(roomType);
                        if (categoryId) {
                            if (!this.rooms[categoryId][feature.roomId]) {
                                this.rooms[categoryId][feature.roomId] = new OwnerRoom();
                                this.rooms[categoryId][feature.roomId].key = Number(feature.roomId);
                                this.rooms[categoryId][feature.roomId].roomType = roomType;
                            }

                            if (!('value' in feature) || feature.value === true) {
                                feature.value = 1;
                            }

                            for (let i = 0; i < feature.value; i++) {
                                this.rooms[categoryId][feature.roomId].features.push(feature);
                            }
                        }
                    }
                }

                if (Object.keys(this.rooms[RoomTypes.BedRoom]).length) {
                    this.roomsLength[RoomTypes.BedRoom] = Object.keys(this.rooms[RoomTypes.BedRoom]).length;
                    this.selectedValue[RoomTypes.BedRoom] = Object.values(this.rooms[RoomTypes.BedRoom]).filter((room) => room.roomType === 4).length;
                } else if (this.accommodationsAreImported && this.accommodation.getFeatureByKey(RoomFeatures.BedRoom)?.length) {
                    this.selectedValue[RoomTypes.BedRoom] = this.accommodation.getFirstFeatureValue(RoomFeatures.BedRoom);
                }

                if (Object.keys(this.rooms[RoomTypes.BathRoom]).length) {
                    this.roomsLength[RoomTypes.BathRoom] = Object.keys(this.rooms[RoomTypes.BathRoom]).length;
                    this.selectedValue[RoomTypes.BathRoom] = Object.values(this.rooms[RoomTypes.BathRoom]).filter((room) => Number(room.roomType) === 7).length;
                } else if (this.accommodationsAreImported && this.accommodation.getFeatureByKey(RoomFeatures.BathRoom)?.length) {
                    this.selectedValue[RoomTypes.BathRoom] = this.accommodation.getFirstFeatureValue(RoomFeatures.BathRoom);
                }
            }
            this.accommodationFeatures = accommodationFeatures;

            this.checkInternetOption = this.authenticationService.currentUser.isImported() || this.internetOptions.some((internetOption) => internetOption in this.accommodationFeatures);
        }

        this.featureError = featureErrorObjectCheck ? featureErrorObjectCheck : {};

        if (!this.featureCloneError) {
            this.featureCloneError = {};
        }

        this.translationServiceSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.currentLanguage = event.lang;
            this.updateSelectBoxes();
        });
        // erste mal ausführen, da der subscribe nur auf Änderungen reagiert
        this.updateSelectBoxes();

        if (
            (this.accommodationClone !== this.accommodation ||
                (this.featureError && this.featureCloneError && Object.values(this.featureCloneError).length !== Object.values(this.featureError).length)) &&
            Object.keys(this.featureDefinitions).length !== 0
        ) {
            this.accommodationClone = this.accommodation;
            const featureCloneObjectErrorCheck = this.accommodationClone.checkResults?.find((objectError) => Number(objectError.checkId) === 55);
            this.featureCloneError = featureCloneObjectErrorCheck ? featureCloneObjectErrorCheck : {};
            this.setFeaturesDefinition();
        }

        this.currentYear = new Date().getFullYear();

        this.allFieldsReadonly = this.authenticationService.currentUser?.settings?.importType === OwnerImportTypeEnum.Imported;
    }

    setFeaturesDefinition() {
        this.featureErrorIds = [];
        this.featureErrorIdNames = [];

        if (!this.featureDefinitions) {
            return;
        }

        this.featureDefinitionGroups = this.featureDefinitions.groups;

        if (!this.featureDefinitionGroups) {
            return;
        }

        for (const group of this.featureDefinitionGroups) {
            if (group.features?.length) {
                for (const featureDefinition of group.features) {
                    if (this.requiredFeatures.includes(featureDefinition.id)) {
                        const isFeatureDefined = this.accommodation.features ? this.accommodation.features.some((feature) => feature.featureId === featureDefinition.id) : false;

                        if (!isFeatureDefined && !this.featureErrorIds.includes(group.id)) {
                            this.featureErrorIds.push(group.id);
                        }
                    }

                    if (featureDefinition.id === 0 && featureDefinition.texts['de'] === 'Internet') {
                        let isFeatureDefined = false;

                        isFeatureDefined = this.accommodation.features ? this.accommodation.features.some((feature) => this.internetOptions.includes(feature.featureId)) : false;

                        if (!isFeatureDefined && !this.featureErrorIds.includes(group.id) && !this.authenticationService.currentUser.isImported()) {
                            this.featureErrorIds.push(group.id);
                        }
                    }

                    if (this.accommodation.checkResults) {
                        const isFeatureError = this.accommodation.checkResults.find((featureError) => Number(featureError.checkId) === 55);

                        if (isFeatureError) {
                            Object.values(isFeatureError).forEach((featureErrors) => {
                                if (Array.isArray(featureErrors)) {
                                    if (
                                        featureErrors.includes(featureDefinition.id) &&
                                        !['HAUSART', 'MAXPERSON', 'WHNFLAECHE', 'KINDER', 'KONTINGENT'].includes(featureDefinition.id) &&
                                        !this.featureErrorIds.includes(group.id)
                                    ) {
                                        this.featureErrorIds.push(group.id);
                                        this.featureErrorIdNames.push(featureDefinition.id);
                                    }
                                }
                            });
                        }

                        if (featureDefinition.id === 0 && featureDefinition.texts['de'] === 'Internet' && isFeatureError && isFeatureError['text.feature.form.MULTISELECT_5_0.error']) {
                            if (!this.featureErrorIds.includes(group.id)) {
                                this.featureErrorIds.push(group.id);
                                this.featureErrorIdNames.push(featureDefinition.id);
                            }
                        }

                        if (
                            isFeatureError &&
                            (isFeatureError['text.feature.form.PRIVATPARK.error'] || isFeatureError['text.feature.form.GARAGE.error'] || isFeatureError['text.feature.form.CARPORT.error'])
                        ) {
                            if (!this.featureErrorIds.includes(group.id)) {
                                this.featureErrorIds.push(6);
                            }
                        }
                    }

                    // SZ und BZ nicht mehr anzeigen, sondern automatisch setzen
                    if (['SZ', 'BZ'].includes(featureDefinition.id)) {
                        featureDefinition.visibility = FeatureDefinitionEnum.OwnerService;
                    }

                    if (featureDefinition.type === FeatureTypeInputEnum.MultiSelect) {
                        for (const tmpFeatureDefinitionFeatureMultiSelect of Object.entries(featureDefinition.values)) {
                            const tmpFeatureDefinitionFeatureKey = tmpFeatureDefinitionFeatureMultiSelect[0];
                            if (featureDefinition.visibilityRules) {
                                this.visibilityRules[tmpFeatureDefinitionFeatureKey] = featureDefinition.visibilityRules;
                            }
                        }
                    } else {
                        if (featureDefinition.visibilityRules) {
                            this.visibilityRules[featureDefinition.id] = featureDefinition.visibilityRules;
                        }
                    }
                }
            }

            // Schlafmöglichkeiten und Bad & Wellness
            if ([2, 3].includes(group.id)) {
                // @ts-ignore
                if (this.featureError?.featureNotSet?.includes(group.id === 2 ? 'PLACETOSLEEP' : 'BADWELLNESS') && !this.featureErrorIds.includes(group.id)) {
                    this.featureErrorIds?.push(group.id);
                }
            }
        }
        const isMaxPersonRoomError = this.accommodation.checkResults.find((featureError) => Number(featureError.checkId) === 56 && featureError['text.feature.form.MAXPERSON.ROOMS_2.error']);

        if (isMaxPersonRoomError) {
            this.featureErrorIds?.push(2);
            this.featureErrorIds?.push(3);
        }
    }

    changeFeature(obj: any, elementType: string) {
        let value = obj.value;
        let featureId = obj.getAttribute('data-featureId');
        let roomCountBefore = 0;
        switch (featureId) {
            case 'SZ':
                roomCountBefore = this.selectedValue[RoomTypes.BedRoom];
                this.selectedValue[RoomTypes.BedRoom] = value;
                this.updateRooms(RoomTypes.BedRoom, value, roomCountBefore);
                this.roomsLength[RoomTypes.BedRoom] = Object.keys(this.rooms[RoomTypes.BedRoom]).length;
                break;
            case 'BZ':
                roomCountBefore = this.selectedValue[RoomTypes.BathRoom];
                this.selectedValue[RoomTypes.BathRoom] = value;
                this.updateRooms(RoomTypes.BathRoom, value, roomCountBefore);
                this.roomsLength[RoomTypes.BathRoom] = Object.keys(this.rooms[RoomTypes.BathRoom]).length;
                break;
            case 'BJ':
                const renovationFeatureValue = Number(document.querySelector<HTMLInputElement>('[data-featureid=MODERN]').value);

                this.yearOfConstructionError = value > Number(this.currentYear);

                this.yearOfRenovationError = renovationFeatureValue && value >= renovationFeatureValue;
                break;
            case 'MODERN':
                const constructionFeatureValue = Number(document.querySelector<HTMLInputElement>('[data-featureid=BJ]').value);

                this.yearOfConstructionModernError = value > Number(this.currentYear);

                this.yearOfRenovationError = constructionFeatureValue && value <= constructionFeatureValue;
                break;
        }

        if (this.requiredFeatures.includes(featureId) || this.internetOptions.includes(featureId)) {
            document.querySelectorAll<HTMLSelectElement>('[data-featureid=' + featureId + ']')[0].required = !(value && value !== 'null');
        }

        const features = [];

        switch (elementType) {
            case this.constants.FEATUREDEFINITION_TYPE_CHECKBOX:
                value = obj.checked ? value : null;
                value = !isNaN(value) ? Number(value) : value;
                this.adjustFeature(featureId, value, features);
                break;

            case this.constants.FEATUREDEFINITION_TYPE_SELECT:
                value = value !== 'null' ? value : null;
                value = !isNaN(value) && value !== null ? Number(value) : value;
                if (this.accommodation.main.type !== 1 && ((featureId === 'POOL' && (value === 1 || value === 3)) || (featureId === 'GARTEN' && value === 1))) {
                    this.openConfirmationDialog(featureId, value, features);
                } else {
                    this.adjustFeature(featureId, value, features);
                }

                break;

            case this.constants.FEATUREDEFINITION_TYPE_MULTISELECT:
                const selectedFeatureIds = [...obj.options].filter((options) => options.selected).map((options) => options.value);

                const notSelectedFeatureIds = [...obj.options].filter((options) => !options.selected).map((options) => options.value);

                for (const selectedFeatureId of selectedFeatureIds) {
                    features.push(new Feature(selectedFeatureId, 1));
                }

                for (const notSelectedFeatureId of notSelectedFeatureIds) {
                    if (this.accommodation.features) {
                        const index = this.accommodation.features.findIndex((feature) => feature.featureId === notSelectedFeatureId);
                        if (index !== -1) {
                            this.accommodation.features.splice(index, 1);
                        }
                    }
                }

                value = value !== '' ? value : null;
                featureId = value;
                break;

            case this.constants.FEATUREDEFINITION_TYPE_INPUT:
                value = value !== '' ? value : null;
                value = !isNaN(value) && value !== null ? Number(value) : value;
                this.adjustFeature(featureId, value, features);
                break;
        }

        this.setFinalFeatures(featureId, value, features);
    }

    setFinalFeatures(featureId: string, value: number, features) {
        const finalFeatures = [];
        let featureFound = false;

        for (const feature of features) {
            featureFound = false;

            this.accommodation.features = this.accommodation.features ?? [];

            for (const accommodationFeature of this.accommodation.features) {
                if (!accommodationFeature.source) {
                    accommodationFeature.source = SourceTypeEnum.Owner;
                }
                if (accommodationFeature.featureId === feature.featureId) {
                    // update to the correct value
                    if (accommodationFeature.value !== feature.value) {
                        accommodationFeature.value = feature.value;
                    }
                    featureFound = true;
                    break;
                }
            }

            if (!featureFound) {
                finalFeatures.push({
                    featureId: feature.featureId,
                    value: feature.value,
                    valid: {},
                    source: SourceTypeEnum.Owner,
                });
            }
        }

        for (const finalFeature of finalFeatures) {
            this.accommodation.features.push(finalFeature);
        }

        this.applyVisibilityRules(featureId, value);
        this.createMissingRooms();
        this.updateRoomsAndFeatures();
        if (featureId !== 'SZ' && featureId !== 'BZ') {
            this.ngOnChanges();
        }
    }

    adjustFeature(featureId: string, value: number, features) {
        if (value !== null) {
            features.push(new Feature(featureId, value));
        } else {
            if (this.accommodation.features) {
                const index = this.accommodation.features.findIndex((feature) => feature.featureId === featureId);
                this.accommodation.features.splice(index, 1);
            }
        }
    }

    applyVisibilityRules(featureId: string, value: any) {
        for (const visibilityRule of Object.entries(this.visibilityRules) as any) {
            if (!!visibilityRule[1].items) {
                let isVisible = null;
                for (const featureIdRuleItem of Object.values(visibilityRule[1].items) as any) {
                    if (!!featureIdRuleItem.featureId && featureId && featureIdRuleItem.featureId.toUpperCase() === featureId.toUpperCase()) {
                        if (isVisible != null) {
                            switch (visibilityRule[1].link.toUpperCase()) {
                                case 'OR':
                                    isVisible = isVisible || this.checkVisibilityCondition(featureId, value, featureIdRuleItem);
                                    break;

                                case 'AND':
                                default:
                                    isVisible = isVisible && this.checkVisibilityCondition(featureId, value, featureIdRuleItem);
                                    break;
                            }
                        } else {
                            isVisible = this.checkVisibilityCondition(featureId, value, featureIdRuleItem);
                        }
                    }

                    if (isVisible != null) {
                        document.querySelectorAll('[data-featureDefinitionId="' + visibilityRule[0].toUpperCase() + '"]').forEach((domObject: any) => {
                            domObject.style.display = isVisible ? 'inline' : 'none';
                        });
                    }
                }
            }
        }
    }

    checkVisibilityCondition(featureId: string, value: any, condition: any): boolean {
        let result = false;
        switch (condition.comparison.toUpperCase()) {
            case 'ISSET':
                result = value != null && value !== '';
                break;

            case 'EQ':
                result = condition.value === value;
                break;

            case 'GT':
                result = !isNaN(value) && Number(value) > Number(condition.value);
                break;

            case 'IN':
                result = condition.value.split(',').includes(value !== null ? value.toString() : value);
                break;
        }

        if (condition.comparison.toUpperCase().substr(0, 4) === 'NOT ') {
            result = !result;
        }

        return result;
    }

    /** Wenn im Feature BZ oder SZ die Anzahl der Badezimmer oder Schlafzimmer verändert wird, muss im Objekt ein neuer Raum angelegt werden */
    createMissingRooms() {
        if (!this.accommodation?.features) {
            return;
        }

        for (const groupId of Object.keys(this.groupIdsToRoomTypeIds)) {
            const type: string = this.groupIdToFeature[groupId];
            const roomCountFromFeature: number = this.accommodationFeatures[type]?.value;
            let roomCountFromRooms: number = 0;

            for (const [key, room] of Object.entries(this.rooms[groupId])) {
                if (this.groupIdsToRoomTypeIds[groupId].includes(this.getRoomTypeFromRoomId(room.key))) {
                    roomCountFromRooms++;
                }
            }

            if (roomCountFromFeature > roomCountFromRooms) {
                this.createRooms(Number(groupId), roomCountFromFeature - roomCountFromRooms);
            }
        }
    }

    createRooms(categoryId: number, amount: number, singleRoom: boolean = false) {
        const emptyRoomId = this.getEmptyRoomId(categoryId);

        for (let i = 0; i < amount; i++) {
            const id = emptyRoomId + i;
            const room = new OwnerRoom();

            let roomKey = 0;
            if (this.accommodation.rooms && this.accommodation.rooms.length) {
                roomKey = this.accommodation.rooms.reduce((prev, current) => (prev.roomId > current.roomId ? prev : current)).roomId;
            }

            room.key = roomKey + 1;

            if (singleRoom) {
                room.roomType = null;
            } else {
                room.roomType = this.groupIdToRoomTypeId[categoryId];
            }

            this.rooms[categoryId][id] = room;
        }
    }

    getEmptyRoomId(categoryId: number): number {
        if (!Object.keys(this.rooms[categoryId]).length) {
            return 1;
        }

        const keys = Object.keys(this.rooms[categoryId]).sort((a, b) => Math.sign(Number(a) - Number(b)));
        const maxKey = [...keys].pop();

        return Number(maxKey) + 1;
    }

    getRoomTypeFromRoomId(id: number, feature?: FeatureDto): number | undefined {
        if (this.accommodation?.rooms?.length) {
            const room = this.accommodation.rooms.find((roomFind) => Number(roomFind.roomId) === Number(id));
            if (room) {
                return room.roomType;
            }
        }

        if (feature && this.featureDefinitionGroups) {
            const filteredFeatureDef = this.featureDefinitionGroups.filter((definition) => [RoomTypes.BedRoom, RoomTypes.BathRoom].includes(definition.id));

            for (const [, definition] of Object.entries(filteredFeatureDef)) {
                if (definition.features.find((f) => f.id === feature.featureId)) {
                    return definition.id === RoomTypes.BedRoom ? 4 : 7;
                }
            }
        }
    }

    private getCategoryIdFromRoomType(roomType: number): string {
        for (const [key, value] of Object.entries(this.groupIdsToRoomTypeIds)) {
            if (value.includes(roomType)) {
                return key;
            }
        }
    }

    addFeatureToRoom(category: number, roomKey: number) {
        const feature = new FeatureDto();
        feature.roomId = this.rooms[category][roomKey].key;
        this.rooms[category][roomKey].features.push(feature);
        this.updateRoomsAndFeatures();
    }

    updateRooms(groupId: number, value: number, valueBefore: number = 0) {
        if (Number(value) === 0) {
            this.deleteAllRooms(groupId);
            this.addRoom(groupId);
            this.addFeatureToRoom(groupId, 1);
        } else {
            // Lösche Räume, wenn die Anzahl der Räume reduziert wird
            if (Number(value) < Number(valueBefore)) {
                for (let i = Number(value); i < Number(valueBefore); i++) {
                    this.deleteRoom(groupId, Number(Object.keys(this.rooms[groupId])[Object.keys(this.rooms[groupId]).length - 1]));
                }
            }
            for (let i = Number(valueBefore); i < Number(value); i++) {
                this.addRoom(groupId);
                const roomKey = Number(Object.keys(this.rooms[groupId])[Object.keys(this.rooms[groupId]).length - 1]);
                this.addFeatureToRoom(groupId, roomKey);
            }
        }

        document.getElementById('features-' + groupId).classList.add('show');
    }

    updateSelectBoxes() {
        // Rendern des selectpicker verzögern um auf DOM zu warten

        this.ngZone.runOutsideAngular(() => {
            setTimeout(async () => {
                const $selectPickers = $('.selectpicker') as any;
                $selectPickers.selectpicker({ title: await this.translateService.get('select.none').toPromise() });
                $selectPickers.selectpicker('render');
                $selectPickers.selectpicker('refresh');
            }, 20);
        });
    }

    addRoom(groupId: number, singleRoom: boolean = false) {
        this.createRooms(groupId, 1, singleRoom);
        this.updateSelectBoxes();
        this.updateRoomsAndFeatures();
        this.roomsLength[groupId] = Object.keys(this.rooms[groupId]).length;
    }

    addSingleRoom(groupId: number) {
        this.addRoom(groupId, true);
        const roomKey = Number(Object.keys(this.rooms[groupId])[Object.keys(this.rooms[groupId]).length - 1]);
        this.addFeatureToRoom(groupId, roomKey);
    }

    deleteRoom(groupId: number, key: number) {
        delete this.rooms[groupId][key];
        this.updateRoomsAndFeatures();
        this.roomsLength[groupId] = Object.keys(this.rooms[groupId]).length;
    }

    deleteAllRooms(groupId: number) {
        if (Object.keys(this.rooms[groupId]).length !== 0) {
            this.rooms[groupId] = {};
            this.updateRoomsAndFeatures();
        }
    }

    deleteFeature(groupId: number, key: number, i: number) {
        this.rooms[Number(groupId)][key].features.splice(i, 1);
        this.updateRoomsAndFeatures();
    }

    /** Daten aus dem umgewandelten Format wieder ins original Format exportieren */
    updateRoomsAndFeatures() {
        if (!this.accommodation.features?.length) {
            this.accommodation.features = [];
        }

        // Alte Features in Schlafzimmer / Badezimmer löschen
        this.accommodation.features = this.accommodation.features.filter((feature) => {
            if (!feature.roomId) {
                return true;
            }

            const type = this.getRoomTypeFromRoomId(feature.roomId, feature);
            if (this.bathRoomTypeIds.includes(type) || this.sleepRoomTypeIds.includes(type)) {
                return false;
            }

            return true;
        });

        // Alte Räume Schlafzimmer / Badezimmer löschen
        for (const i in this.accommodation.rooms) {
            if (this.accommodation.rooms[i]) {
                const room = this.accommodation.rooms[i];
                if (this.bathRoomTypeIds.includes(Number(room.roomType)) || this.sleepRoomTypeIds.includes(Number(room.roomType))) {
                    this.accommodation.rooms.splice(Number(i), 1);
                }
            }
        }

        this.accommodation.rooms = [];

        // Räume und Features neu anlegen
        for (const [category, rooms] of Object.entries(this.rooms)) {
            for (const [id, room] of Object.entries(rooms)) {
                const newRoom = new RoomDto();
                newRoom.roomId = room.key;
                newRoom.roomType = Number(room.roomType);

                this.accommodation.rooms.push(newRoom);

                if (room.features?.length) {
                    for (const feature of room.features) {
                        // Einzelne Features zusammenfassen und die Anzahl in Value schreiben
                        const existingFeature = this.accommodation.features.find(
                            (existingFeatureLoop) => existingFeatureLoop.roomId === room.key && existingFeatureLoop.featureId === feature.featureId,
                        );
                        if (existingFeature) {
                            if (existingFeature.value === true) {
                                existingFeature.value = 1;
                            }

                            if (!existingFeature.value) {
                                existingFeature.value = 2;
                            } else {
                                existingFeature.value++;
                            }
                        } else {
                            feature.value = 1;
                            this.accommodation.features.push(feature);
                        }
                    }
                }
            }
        }

        // BZ und SZ aktualisieren
        this.updateFeature(RoomFeatures.BedRoom, this.getNumberOfBedRooms());
        this.updateFeature(RoomFeatures.BathRoom, this.getNumberOfBathRooms());

        this.updateSelectBoxes();
    }

    getNumberOfBathRooms(): number {
        // Raumtyp 7 = Badezimmer, soll nur gezählt werden
        return Object.values(this.rooms[RoomTypes.BathRoom]).filter((room) => Number(room.roomType) === 7).length;
    }

    getNumberOfBedRooms(): number {
        // Raumtyp 4 = Schlafzimmer soll nur gezählt werden
        return Object.values(this.rooms[RoomTypes.BedRoom]).filter((room) => Number(room.roomType) === 4).length;
    }

    updateFeature(key, value) {
        // VRMB-2399 SZ-Anzahl bei importierten Objekten in das entsprechende v3 Feld importieren
        if (value === 0 && this.accommodationsAreImported) {
            return;
        }

        const feature = this.accommodation.features.find((featureFind) => featureFind.featureId === key);
        if (feature) {
            feature.value = value;
        } else {
            const newFeature = new FeatureDto();
            newFeature.value = value;
            newFeature.featureId = key;
            this.accommodation.features.push(newFeature);
        }
    }

    openConfirmationDialog(featureId: string, value: number, features) {
        this.confirmationDialogService
            .confirm('atraveo.accommodationbundle.clone.confirm.content', featureId === 'POOL' ? 'text.feature.form.POOL.apartment.error' : 'text.feature.form.GARTEN.apartment.error')
            .then(async (confirmed) => {
                if (confirmed) {
                    this.adjustFeature(featureId, value, features);
                    this.setFinalFeatures(featureId, value, features);
                } else {
                    document.querySelector<HTMLSelectElement>('[data-featureid=' + featureId + ']').value = null;
                    this.adjustFeature(featureId, null, features);
                    return;
                }
            })
            .catch(() => undefined);
    }

    removeSpecialCharacters($event: Event) {
        const inputElement = $event.target as HTMLInputElement;

        if (['BJ', 'MODERN'].includes(inputElement.getAttribute('data-featureId'))) {
            inputElement.value = inputElement.value.replace(/[,\-]/g, '');
            if (inputElement.value !== '') {
                let num = Math.round(Number(inputElement.value));
                if (num <= 0) {
                    num = 0;
                }
                if (num >= this.currentYear) {
                    num = this.currentYear;
                }
                inputElement.value = String(num);
            }
        }
    }

    async save() {
        const features = [];
        let isEmptyRoom = false;
        let isRoomWithoutType = false;

        const unitSelects = document.getElementsByName('unit-select');
        for (let i = 0; i < unitSelects.length; i++) {
            if ((unitSelects[i] as HTMLSelectElement).value !== 'M' && (unitSelects[i] as HTMLSelectElement).value !== 'CM') {
                (unitSelects[i] as HTMLSelectElement).value = 'M';
                this.selectChange(unitSelects[i]);
            }
        }

        this.accommodation?.rooms?.forEach((room) => {
            if (room.roomType === 0) {
                isRoomWithoutType = true;
            }
        });

        this.accommodation?.features?.forEach((feature) => {
            features.push(feature);

            if (feature.roomId && !feature.featureId) {
                isEmptyRoom = true;
            }
        });

        if (isEmptyRoom || isRoomWithoutType || this.yearOfConstructionError || this.yearOfConstructionModernError || this.yearOfRenovationError) {
            await this.notificationService.add('text.validation.pleasecorrect', 'danger');
            return;
        }

        this.accommodation.features = features;

        const result = await this.apiConnectorService.saveAccommodationOrGroupForTab(this.route, this.accommodation);
        this.pendingChangesComponentService.saveComplete(!!result);
    }

    selectChange(target: HTMLElement) {
        const select = target as HTMLSelectElement;
        const options = select.getElementsByTagName('option');
        const factor = Number(options[select.selectedIndex === 0 ? 1 : 0].innerHTML);
        const input = (select.parentNode.parentNode as HTMLDivElement).getElementsByTagName('input')[0] as HTMLInputElement;
        input.value = input.value ? (Number(input.value.replace(',', '.')) * factor).toString() : '';
        input.dispatchEvent(new Event('change'));
    }

    canDeactivate(): Observable<boolean> | boolean {
        return isEqual(this.accommodation, this.accommodationOriginal);
    }
}
