import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { GroupComponentsEnum } from 'data-structures/lib/es6/dto/accommodation-group/find-accommodation-group/find-accommodation-group-request.dto';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { Router } from '@angular/router';
import { ExtendedAccommodationGroupEntity } from '../../../../../entities/extendedAccommodationGroupEntity';
import { ConfirmationDialogService } from '../../../../global/confirmation-dialog/confirmation-dialog.service';

@Component({
    selector: 'app-accommodation-groups',
    templateUrl: './accommodation-groups.component.html',
    styleUrls: ['./accommodation-groups.component.scss'],
})
export class AccommodationGroupsComponent implements OnInit {
    accommodationGroups: ExtendedAccommodationGroupEntity[];
    accommodationGroupsCount: number;
    currentPage: number = 1;
    selectedElementToDelete: HTMLElement;

    filter: {
        skip: number;
        take: number;
    };

    constructor(
        readonly apiConnector: ApiConnectorService,
        readonly authenticationService: AuthenticationService,
        readonly router: Router,
        readonly confirmationDialogService: ConfirmationDialogService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.filter = { skip: 0, take: 10 };
        await this.filterAccommodationGroups();
    }

    onSearchGroup(value: number) {
        this.router.navigate(['/accommodation-group/', value]);
    }

    async changePage(page: number) {
        this.filter.skip = this.filter.take * (page - 1);
        if (this.filter.skip < 0) {
            this.filter.skip = 0;
        }
        this.currentPage = page;
        this.filterAccommodationGroups();
    }

    async filterAccommodationGroups() {
        const components: GroupComponentsEnum[] = [GroupComponentsEnum.AccommodationGroupId, GroupComponentsEnum.Name, GroupComponentsEnum.GroupAccommodationIds];
        const result: any = await this.apiConnector.getAccommodationGroupsByOwnerNumber(
            this.authenticationService.currentUser.ownerNumber,
            Number(this.filter.skip),
            Number(this.filter.take),
            components,
        );
        this.accommodationGroups = result.accommodationGroups?.map((accommodationGroup) => {
            accommodationGroup.groupAccommodationIds = accommodationGroup.groupAccommodationIds ?? [];
            return accommodationGroup;
        });
        this.accommodationGroupsCount = result.count;
    }
    openConfirmationDialog(accommodationGroupId: number, event) {
        this.confirmationDialogService
            .confirm('confirm.title.delete', 'confirm.content.delete.0')
            .then(async (confirmed) => {
                if (confirmed) {
                    await this.apiConnector.deleteAccommodationGroup(accommodationGroupId, this.authenticationService.currentUser.ownerNumber);
                    event.target.parentNode.parentNode.remove();
                }
            })
            .catch(() => undefined);
    }
}
