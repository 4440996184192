<div class="container">
    <div class="header-menu">
        <div class="menu" [class.with-logo]="withLogo">
            <div class="site-logo">
                <a routerLink="/" ><img src="assets/img/Logo/logo_atraveo_by_edomizil.png" class="brand-logo" alt="company logo" title="Start"></a>
            </div>
            <div class="btn-group w-auto d-inline dropright">
                <button type="button" class="btn btn-link dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <span class="caret"></span>
                    {{ 'button.select.language'| translate }}
                </button>
                <div class="dropdown-menu p-0">
                    <div class="list-group m-0 p-0 border-0">
                        <a class="list-group-item list-group-item-action cursor-pointer" *ngFor="let language of environment.allowedLanguages" (click)="changeLanguage($event, language)">
                            <img src="assets/img/{{language}}.gif" class="align-middle">
                            {{ 'intl.language.name.' + language | translate }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
