<div *ngIf="!authenticationService.isLoggedIn()" id="register-login" class="p-5 clearfix">
    <section class="main">
        <div class="row">
            <div class="welcome-text col-12 col-lg-4">
                <h1 class="welcome">{{ 'text.welcome.title' | translate }}</h1>
                <div class="nav row">
                    <div class="home col-4">
                        <a href="{{ 'text.registration.url.home' | translate }}"  title="{{ 'text.welcome.homeTitle' | translate }}" target="atraveode" >
                            <div class="icon"></div>
                            <span class="text pt-3">{{ 'text.welcome.home' | translate }}</span>
                        </a>
                    </div>
                    <div class="tutorials col-4">
                        <a href="{{ 'text.registration.url.tutorials' | translate }}"  title="{{ 'text.welcome.tutorialsTitle' | translate }}" target="atraveode" >
                            <div class="icon"></div>
                            <span class="text pt-3">{{ 'text.welcome.tutorials' | translate }}</span>
                        </a>
                    </div>
                    <div class="faq col-4">
                        <a href="{{ 'text.registration.url.faq' | translate }}" title="{{ 'text.welcome.faqTitle' | translate }}" target="atraveode" >
                            <div class="icon"></div><span class="text pt-3">{{ 'text.welcome.faq' | translate }}</span>
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto pr-0">
                        <img class="w100p ofc h100p bdrsm " src="//cdn.hometogo.net/assets/media/pics/520_auto/64fb28a7ecd6a.png" width="30" loading="lazy">
                    </div>
                    <div class="col">
                        <p>{{ 'text.registration.info' | translate }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto pr-0">
                        <img class="w100p ofc h100p bdrsm " src="//cdn.hometogo.net/assets/media/pics/520_auto/64fb28a7ecd6a.png" width="30" loading="lazy">
                    </div>
                    <div class="col">
                        <p class="phone">
                            <a [href]="'tel:'+('text.registration.phone.link' | translate)">{{ 'text.registration.phone.display' | translate }}</a>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto pr-0">
                        <img class="w100p ofc h100p bdrsm " src="//cdn.hometogo.net/assets/media/pics/520_auto/64fb28a7ecd6a.png" width="30" loading="lazy">
                    </div>
                    <div class="col">
                        <p class="email">
                            <a [href]="'mailto:'+('text.registration.email.ownerservice' | translate)">{{ 'text.registration.email.ownerservice' | translate }}</a>
                        </p>
                    </div>
                </div>
                <div class="welove my-5"></div>
                <div class="small">
                    <app-footer></app-footer>
                </div>
            </div>
            <div class="col empty-space"></div>
            <div class="login-form p-5 col-12 col-lg-5">
                <div class="site-logo text-center d-lg-block d-none">
                    <img src="assets/img/Logo/logo_atraveo_by_edomizil.png" class="brand-logo" alt="company logo" title="Start">
                </div>
                <div class="login-box">
                    <form [formGroup]="loginForm" (ngSubmit)="login()" [class]="showResetPassword ? 'hide' : ''">
                        <div class="login-details">
                            <p>
                                <input type="text" placeholder="{{ 'text.login.username.label' | translate }}" id="txt-ownernumber" class="form-control" autocomplete='username' formControlName="userName">
                                <app-validation-error-messages [control]="loginForm.controls.userName"></app-validation-error-messages>
                            </p>
                            <p>
                                <input [type]="passwordType" placeholder="{{ 'text.login.password.0' | translate }}" class="form-control" id="txt-password" autocomplete='password' formControlName="password">
                                <app-validation-error-messages [control]="loginForm.controls.password"></app-validation-error-messages>
                            </p>
                            <div class="eye-icon-box">
                                <i class="fas fa-eye" (click)="togglePasswordVisibility()"></i>
                            </div>
                            <div *ngIf="router.url === '/special-login'">
                                <br>Dein Benutzertyp:
                                <select class="custom-select" id="select-type" formControlName="ownerType">
                                    <option value="backoffice">Backoffice</option>
                                    <option value="owner">Vermieter</option>
                                </select>
                                <app-validation-error-messages [control]="loginForm.controls.ownerType"></app-validation-error-messages>
                                <br><br>Einloggen als:
                                <select class="custom-select" id="select-role" formControlName="role">
                                    <option value="owner">Vermieter</option>
                                    <option value="admin_owner">Vermieterservice</option>
                                    <option value="destination">Destination</option>
                                    <option value="admin_overall">Technik</option>
                                </select>
                                <app-validation-error-messages [control]="loginForm.controls.role"></app-validation-error-messages>
                                <input placeholder="Login als anderer User" id="login-as-user" class="form-control mt-3" formControlName="loginAsUser">
                                <app-validation-error-messages [control]="loginForm.controls.loginAsUser"></app-validation-error-messages>
                            </div>
                            <div class="bg-danger text-white p-2 rounded" *ngIf="loginError">{{ loginError | translate }}</div>
                            <div class="password-forgotten-box row">
                                <div class="col-12 col-md-3 mb-2">
                                    <button *ngIf="!loading" type="submit" class="btn btn-success login-btn" name="submit">
                                        {{ 'text.login.submit' | translate }}
                                    </button>
                                </div>
                                <div class="col-12 col-md-9 text-right">
                                    <a href="javascript:void(0);" (click)="toggleResetPasswordBox()" class="password-forgotten-link">{{ 'text.login.password.forgotten' | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form [formGroup]="resetPasswordForm" (ngSubmit)="newPasswordRequest()" [class]="((showResetPassword) ? '' : 'hide')">
                        <div class="reset-password-box py-3">
                            <label>{{ 'text.login.password.forgotten' | translate }}</label>
                            <input type="text" id="user-login-data" class="form-control" formControlName="userName" placeholder="{{ 'text.login.username.0' | translate }}">
                            <app-validation-error-messages [control]="resetPasswordForm.controls.userName"></app-validation-error-messages>
                        </div>
                        <a href="javascript:void(0);" (click)="toggleResetPasswordBox()" class="password-forgotten-link">{{ 'Previous' | translate }}</a>
                        <button type="submit" class="btn btn-success float-right password-request-btn" [disabled]="passwordSendActive">{{ 'text.login.requestNewPassword' | translate }}</button>
                    </form>
                </div>
                <div>
                    <hr class="my-5"/>
                    <p *ngIf="infoTextPresent" class="login-box">
                        {{ 'text.login.box' | translate }}<br>
                    </p>
                    <button class="btn navigation-btn" *ngIf="!loading" (click)="navigateToCreateAccount()">{{ 'text.registration.title' | translate }}</button>
                </div>
            </div>
        </div>
    </section>
</div>
