import { Injectable, OnDestroy } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

export class DurationEntry {
    days: number;
    text: string;
}

@Injectable({
    providedIn: 'root',
})
export class DurationService implements OnDestroy {
    durationChoices: DurationEntry[] = [];
    translationServiceSubscription: Subscription;

    constructor(readonly translationService: TranslateService) {}

    ngOnDestroy() {
        this.translationServiceSubscription?.unsubscribe();
    }

    async getDurations() {
        if (this.durationChoices?.length) {
            return this.durationChoices;
        } else {
            return this.createDurationOptions();
        }
    }

    async getTextForDuration(days: number) {
        if (!this.durationChoices?.length) {
            //   await this.createDurationOptions();
        }
        return this.durationChoices.find((entry) => entry.days === days);
    }

    async createDurationOptions(): Promise<any[]> {
        if (this.durationChoices.length) {
            return this.durationChoices;
        }

        this.durationChoices = [];
        let text = await this.translationService.get('atraveo.frontendbundle.duration-option.one.day').toPromise();
        let entry = new DurationEntry();
        entry.days = 1;
        entry.text = text;
        this.durationChoices.push(entry);

        for (let i = 2; i <= 13; i++) {
            text = await this.translationService.get('atraveo.frontendbundle.duration-option.days', { number: i }).toPromise();
            entry = new DurationEntry();
            entry.days = i;
            entry.text = text;
            this.durationChoices.push(entry);
        }

        for (let i = 2; i <= 7; i++) {
            text = await this.translationService.get('atraveo.frontendbundle.duration-option.weeks', { number: i }).toPromise();
            entry = new DurationEntry();
            entry.days = i * 7;
            entry.text = text;
            this.durationChoices.push(entry);
        }

        for (let i = 1; i <= 3; i++) {
            text = await this.translationService.get('atraveo.frontendbundle.duration-option.months', { number: i }).toPromise();
            entry = new DurationEntry();
            entry.days = i * 30;
            entry.text = text;
            this.durationChoices.push(entry);
        }

        for (let i = 100; i <= 300; i += 50) {
            text = await this.translationService.get('atraveo.frontendbundle.duration-option.days', { number: i }).toPromise();
            entry = new DurationEntry();
            entry.days = i;
            entry.text = text;
            this.durationChoices.push(entry);
        }

        text = await this.translationService.get('atraveo.frontendbundle.duration-option.half.year').toPromise();
        entry = new DurationEntry();
        entry.days = 183;
        entry.text = text;
        this.durationChoices.push(entry);

        text = await this.translationService.get('atraveo.frontendbundle.duration-option.one.year').toPromise();
        entry = new DurationEntry();
        entry.days = 365;
        entry.text = text;
        this.durationChoices.push(entry);

        this.translationServiceSubscription = this.translationService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            await this.createDurationOptions();
        });

        return this.durationChoices;
    }
}
