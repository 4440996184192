import { Component, OnDestroy, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ApiConnectorService } from '../../../services/api-connector/api-connector.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { MessageCountService } from '../../../services/messages/message-count.service';
import { environment } from '../../../../environments/environment';
import { ErrorCountService } from '../../../services/accommodation/error-count.service';
import { AccommodationRatingService } from '../../../services/accommodation/accommodation-rating.service';
import { UrlLangDetectorService } from '../../../services/url-lang-detector/url-lang-detector.service';
import { AccommodationCountService } from '../../../services/accommodation/accommodation-count.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LogoutDialogComponent } from '../../global/dialogs/logout/logout-dialog.component';
import { DialogService } from '../../../services/dialog.service';
import { ContactComponent } from '../content/contact/contact.component';
import { Subscription } from 'rxjs';
import { ToogleMenuService } from '../../../services/toggle-menu/toggle-menu.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
    accommodationErrorCount: number;
    messagesCount: number;
    accommodationCount: number;
    accommodationUnreadRatingCount: number;
    currentYear: number;
    privacyHref: string = '#';
    imprintHref: string = '#';
    environment = environment;
    translationServiceSubscription: Subscription;
    errorCountSubscription: Subscription;
    accommodationCountSubscription: Subscription;
    messageCountSubscription: Subscription;
    accommodationRatingSubscription: Subscription;

    constructor(
        readonly translateService: TranslateService,
        readonly titleService: Title,
        readonly apiConnector: ApiConnectorService,
        readonly authenticationService: AuthenticationService,
        readonly messageCountService: MessageCountService,
        readonly errorCountService: ErrorCountService,
        readonly accommodationRatingService: AccommodationRatingService,
        readonly urlLangDetectorService: UrlLangDetectorService,
        readonly accommodationCountService: AccommodationCountService,
        readonly router: Router,
        readonly dialog: MatDialog,
        readonly dialogService: DialogService,
        readonly toggleMenuService: ToogleMenuService,
    ) {
        this.currentYear = new Date().getFullYear();

        this.errorCountSubscription = this.errorCountService.accommodationErrorCountChange.subscribe((value) => {
            this.accommodationErrorCount = value;
        });

        this.accommodationCountSubscription = this.accommodationCountService.accommodationCountChange.subscribe((value) => {
            this.accommodationCount = value;
        });

        this.messageCountSubscription = this.messageCountService.messagesCountChange.subscribe((value) => {
            this.messagesCount = value;
        });

        this.accommodationRatingSubscription = this.accommodationRatingService.accommodationUnreadRatingCountChange.subscribe((value) => {
            this.accommodationUnreadRatingCount = value;
        });
    }
    toggleMenu() {
        this.toggleMenuService.toggleMenu();
    }

    async ngOnInit() {
        this.translationServiceSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translateService
                .get('atraveo.application.title')
                .toPromise()
                .then((translation) => {
                    this.titleService.setTitle(translation);
                });

            this.determinePrivacyHref();
            this.determineImprintHref();
        });

        const promises = [];

        promises.push(this.errorCountService.getAccommodationErrorCount());
        promises.push(this.messageCountService.getMessagesCount());
        promises.push(this.accommodationRatingService.getAccommodationUnreadRatingCount());
        promises.push(this.determinePrivacyHref());
        promises.push(this.determineImprintHref());
        await Promise.all(promises);
    }

    ngOnDestroy() {
        this.translationServiceSubscription?.unsubscribe();
        this.errorCountSubscription?.unsubscribe();
        this.accommodationCountSubscription?.unsubscribe();
        this.messageCountSubscription?.unsubscribe();
        this.accommodationRatingSubscription?.unsubscribe();
    }

    async changeLanguage(event, lang) {
        await this.urlLangDetectorService.checkUrlLangAndRemove();
        this.translateService.use(lang);
    }

    determinePrivacyHref(): Promise<void> {
        return this.translateService
            .get(`urls.${environment.stage === 'prod' ? 'prodstage' : 'qastage'}.datenschutz`)
            .toPromise()
            .then((privacyHref) => {
                this.privacyHref = privacyHref;
            });
    }

    determineImprintHref(): Promise<void> {
        return this.translateService
            .get(`urls.${environment.stage === 'prod' ? 'prodstage' : 'qastage'}.impressum`)
            .toPromise()
            .then((imprintHref) => {
                this.imprintHref = imprintHref;
            });
    }

    contactUs() {
        this.dialogService.openDialog(ContactComponent, { dialogWidth: this.dialogService.dialogWidth.M });
    }

    thereAreTasksOpen(): boolean {
        return (
            this.messagesCount > 0 ||
            this.accommodationErrorCount > 0 ||
            this.accommodationUnreadRatingCount > 0 ||
            ['CREATED', 'CLICKED'].includes(this.authenticationService.currentUser?.registration?.idNowStatusText) ||
            this.accommodationCount === 0 ||
            this.authenticationService.currentUser.vat?.hasVatId === null ||
            this.authenticationService.currentUser.vat?.hasVatId === undefined
        );
    }

    async handleLogoutConfirmation() {
        try {
            await this.accommodationRatingService.getAccommodationUnreadRatingCount();
        } catch (e) {
            console.error(e);
        }

        if (this.thereAreTasksOpen()) {
            this.dialogService.openDialog(LogoutDialogComponent, { dialogWidth: this.dialogService.dialogWidth.SM });
        } else {
            this.logout();
        }
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/']);
        // location.reload();
    }
}
