import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../entities/extendedAccommodationEntity';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { OwnerImportTypeEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { MainDto } from 'data-structures/lib/es6/dto/accommodation';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ComponentsEnum, FindAccommodationRequestDto } from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-request.dto';
import { FindAccommodationFilterDto } from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-filter.dto';
import { NotificationService } from '../../../../../services/notification/notification.service';
import { PendingChangesComponentService } from '../../../../../services/pending-changes-component/pending-changes-component.service';
import { cloneDeep, isEqual } from 'lodash';
import { ComponentCanDeactivate } from '../../../../../guards/pending-changes-guard';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-accommodation-main',
    templateUrl: './accommodation-main.component.html',
    styleUrls: ['./accommodation-main.component.scss'],
})
export class AccommodationMainComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    accommodation: ExtendedAccommodationEntity;
    accommodationOriginal: ExtendedAccommodationEntity;
    mainFeatureError: any = {};
    saveTriggerSubscription: Subscription;
    accommodationSubscription: Subscription;
    classificationOptions = [
        { textKey: 'noClassification', value: '0' },
        { textKey: 'star', value: '1' },
        { textKey: 'superior', value: '1+' },
        { textKey: 'star', value: '2' },
        { textKey: 'superior', value: '2+' },
        { textKey: 'star', value: '3' },
        { textKey: 'superior', value: '3+' },
        { textKey: 'star', value: '4' },
        { textKey: 'superior', value: '4+' },
        { textKey: 'star', value: '5' },
        { textKey: 'superior', value: '5+' },
    ];
    contactsLength: number = 0;
    contactList: any;
    mainForm: FormGroup;
    maxPersonSubscription: Subscription;
    minChildrenSubscription: Subscription;

    constructor(
        readonly apiConnectorService: ApiConnectorService,
        readonly route: ActivatedRoute,
        readonly authenticationService: AuthenticationService,
        readonly ngZone: NgZone,
        readonly notificationService: NotificationService,
        readonly pendingChangesComponentService: PendingChangesComponentService,
    ) {}

    async ngOnInit() {
        this.mainForm = new FormGroup({
            name: new FormControl(''),
            type: new FormControl('', [Validators.required]),
            maxPersons: new FormControl('', [Validators.required]),
            minChildren: new FormControl(''),
            livingSpace: new FormControl('', [Validators.required]),
            contingent: new FormControl(''),
            licenseNumber: new FormControl(''),
            accommodationUrl: new FormControl('', Validators.pattern(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}(\/[a-zA-Z0-9-._~:\/?#[\]@!$&'()*+,;=]*)?$/)),
            classification: new FormControl(''),
            ownerAccommodationId: new FormControl(''),
            contactPerson: new FormControl(''),
        });

        this.maxPersonSubscription = this.mainForm.get('maxPersons')?.valueChanges.subscribe(() => {
            this.mainFeatureError = false;
            this.setMinChildrenError();
        });
        this.minChildrenSubscription = this.mainForm.get('minChildren')?.valueChanges.subscribe(() => this.setMinChildrenError());

        if (this.authenticationService.currentUser?.settings?.importType === OwnerImportTypeEnum.Imported) {
            this.mainForm.get('type').disable();
            this.mainForm.get('maxPersons').disable();
            this.mainForm.get('minChildren').disable();
            this.mainForm.get('livingSpace').disable();
        }

        this.accommodationSubscription = this.apiConnectorService.activeAccommodation$.subscribe(async (accommodation) => {
            if (accommodation) {
                this.accommodation = cloneDeep(accommodation);
                if (!this.accommodation.main) {
                    this.accommodation.main = new MainDto();
                }

                this.mainForm.patchValue({
                    name: this.accommodation.main?.name,
                    type: typeof this.accommodation.main?.type !== undefined && this.accommodation.main?.type !== null ? this.accommodation.main?.type.toString() : '',
                    maxPersons: this.accommodation.main?.maxPersons,
                    minChildren: this.accommodation.main?.minChildren,
                    livingSpace: this.accommodation.main?.livingSpace,
                    contingent: this.accommodation.main?.contingent,
                    licenseNumber: this.accommodation.main?.licenseNumber,
                    accommodationUrl: this.accommodation.main?.url,
                    classification: this.accommodation.main?.classification || '',
                    ownerAccommodationId: this.accommodation?.ownerAccommodationId,
                    contactPerson: this.accommodation.main?.managerId || '',
                });

                // für den Check, ob sich etwas geändert hat
                this.accommodationOriginal = cloneDeep(this.accommodation);

                this.mainFeatureError = this.accommodation.main?.maxPersons > 0 && this.accommodation.checkResults?.find((objectError) => Number(objectError.checkId) === 56);

                if ([511, 521].includes(this.accommodation.main?.productKey)) {
                    this.contactList = Object.keys(this.authenticationService.currentUser.addressBook.contacts);
                    this.contactsLength = this.contactList.length;
                }
            }
        });

        this.saveTriggerSubscription = this.pendingChangesComponentService.getSaveTriggerObservable().subscribe(async () => {
            await this.submitForm();
        });
    }

    ngOnDestroy() {
        this.saveTriggerSubscription?.unsubscribe();
        this.accommodationSubscription?.unsubscribe();
        this.maxPersonSubscription?.unsubscribe();
        this.minChildrenSubscription?.unsubscribe();
    }

    setMinChildrenError() {
        const maxPersons = this.mainForm.get('maxPersons')?.value;
        const minChildren = this.mainForm.get('minChildren')?.value;
        const minChildrenControl = this.mainForm.get('minChildren');

        if (minChildren >= maxPersons) {
            minChildrenControl?.setErrors({ minChildren: true });
        } else {
            minChildrenControl?.setErrors(null);
        }
    }

    async submitForm() {
        await this.checkOwnerAccommodationId();
        if (this.mainForm.valid) {
            this.accommodation.main.name = this.mainForm.get('name').value;
            this.accommodation.main.type = this.mainForm.get('type').value !== '' ? Number(this.mainForm.get('type').value) : null;
            this.accommodation.main.maxPersons = this.mainForm.get('maxPersons').value > 0 ? this.mainForm.get('maxPersons').value : null;
            this.accommodation.main.minChildren = this.mainForm.get('minChildren').value > 0 ? this.mainForm.get('minChildren').value : null;
            this.accommodation.main.livingSpace = this.mainForm.get('livingSpace').value > 0 ? this.mainForm.get('livingSpace').value : null;
            if (this.mainForm.get('contingent').value > 0) {
                this.accommodation.main.contingent = this.mainForm.get('contingent').value;
            } else {
                this.accommodation.deleteFeature('KONTINGENT');
                this.accommodation.main.contingent = null;
            }
            this.accommodation.main.licenseNumber = this.mainForm.get('licenseNumber').value;
            this.accommodation.main.url = this.mainForm.get('accommodationUrl').value;
            this.accommodation.main.classification = this.mainForm.get('classification').value;
            this.accommodation.ownerAccommodationId = this.mainForm.get('ownerAccommodationId').value;
            this.accommodation.main.managerId = this.mainForm.get('contactPerson').value;

            const result = await this.apiConnectorService.saveAccommodationOrGroupForTab(this.route, this.accommodation);
            this.pendingChangesComponentService.saveComplete(!!result);
        } else {
            this.mainForm.markAllAsTouched();
        }
    }

    async checkOwnerAccommodationId() {
        this.mainForm.get('ownerAccommodationId').setErrors(null);
        if (this.mainForm.get('ownerAccommodationId').value && this.authenticationService.currentUser) {
            const findDto = new FindAccommodationRequestDto();
            findDto.ownerNumber = this.authenticationService.currentUser.ownerNumber;
            findDto.filter = new FindAccommodationFilterDto();
            findDto.filter.ownerAccommodationId = this.mainForm.get('ownerAccommodationId').value;
            findDto.components = [ComponentsEnum.AccommodationId];

            const result = await this.apiConnectorService.findAccommodations(findDto);
            if (result.accommodations?.length) {
                result.accommodations = result.accommodations.filter((accommodation: ExtendedAccommodationEntity) => {
                    return accommodation.accommodationId !== this.accommodation.accommodationId;
                });
            }

            if (result.accommodations?.length) {
                this.mainForm.get('ownerAccommodationId').setErrors({ ownerAccommodationId: true, usedAccommodationId: result.accommodations[0].accommodationId });
            }
        }
    }

    canDeactivate(): Observable<boolean> | boolean {
        return isEqual(this.accommodation, this.accommodationOriginal);
    }
}
