import { NgModule } from '@angular/core';
import { ActivatedRoute, RouterModule, Routes } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

/**  Components **/
import { AccommodationsComponent } from './components/main/content/accommodations/accommodations.component';
import { DashboardComponent } from './components/main/content/dashboard/dashboard.component';
import { AccommodationComponent } from './components/main/content/accommodation/accommodation.component';
import { DescriptionComponent } from './components/main/content/accommodation/description/description.component';
import { ArrivalInformationComponent } from './components/main/content/arrival-information/arrival-information.component';
import { BookingListComponent } from './components/main/content/booking/booking-list/booking-list.component';
import { HistoryComponent } from './components/main/content/history/history.component';
import { ProfileComponent } from './components/main/content/user/profile/profile.component';
import { InterfaceComponent } from './components/main/content/interface/interface.component';
import { AdditionalCostTemplateComponent } from './components/main/content/additional-cost-template/additional-cost-template.component';
import { NotificationsComponent } from './components/main/notifications/notifications.component';
import { MessageContentComponent } from './components/main/notifications/messages/message-content/message-content.component';
import { RatingsComponent } from './components/main/content/ratings/ratings.component';
import { AccommodationGroupComponent } from './components/main/content/accommodation/accommodation-group/accommodation-group.component';
import { AccommodationGroupsComponent } from './components/main/content/accommodation/accommodation-groups/accommodation-groups.component';
import { NotFoundComponent } from './components/main/content/accommodation/not-found/not-found.component';
import { InvoicesComponent } from './components/main/content/invoices/invoices.component';
import { TermsComponent } from './components/main/content/terms/terms.component';
import { ChangeOwnerComponent } from './components/main/content/change-owner/change-owner.component';
import { DirectLoginComponent } from './components/global/direct-login/direct-login.component';
import { LoginComponent } from './components/login/login.component';
import { IdNowComponent } from './components/idNow/idNow.component';
import { DataProtectionComponent } from './components/main/content/data-protection/data-protection.component';
import { ImprintComponent } from './components/main/content/imprint/imprint.component';
import { PositionComponent } from './components/main/content/accommodation/position/position.component';
import { FeaturesComponent } from './components/main/content/accommodation/features/features.component';
import { ImagesComponent } from './components/main/content/accommodation/images/images.component';
import { PriceComponent } from './components/main/content/accommodation/price/price.component';
import { CalendarComponent } from './components/main/content/accommodation/calendar/calendar.component';
import { ImageComponent } from './components/main/content/accommodation/images/image/image.component';
import { AccommodationMainComponent } from './components/main/content/accommodation/accommodation-main/accommodation-main.component';
import { ObjectsComponent } from './components/main/content/accommodation/objects/objects.component';
import { AccommodationGroupOverviewComponent } from './components/main/content/accommodation/accommodation-group/accommodation-group-overview.component';
import { RegistrationFormComponent } from './components/main/content/user/registration-form/registration-form.component';
import { OnboardingComponent } from './components/main/content/user/onboarding/onboarding.component';
import { OwnerListComponent } from './components/main/content/destinations/owner-list/owner-list.component';
import { ModelComponent } from './components/main/content/accommodation/model/model.component';
import { FullComponent } from './components/layouts/full/full.component';
import { AuthRedirectGuard } from './guards/auth-redirect-guard.guard';
import { MainComponent } from './components/main/main.component';
import { PasswordForgotten } from './components/main/content/user/password-forgotten/password-forgotten';
import { PoolConfigComponent } from './components/main/content/pool-config/pool-config.component';

/** Guards **/
import { AuthGuard } from './guards/auth.guard';
import { OnboardingGuard } from './guards/onboarding.guard';
import { UserValidTokenGuard } from './guards/user-valid-token.guard';
import { PendingChangesGuard } from './guards/pending-changes-guard';

/*
 ALLE ROUTEN BRAUCHEN EINEN GUARD IN canActivate
 AuthGuard: Prüft ob der User wirklich eingeloggt ist und leitet dich auf die Loginseite um falls nicht
 UserValidTokenGuard: Prüft ob wenn ein User eingeloggt ist, ob er auch noch gültig ist und loggt ihn aus falls nicht. Aber uneingeloggte User lässt er durch
 */

const routes: Routes = [
    {
        path: 'idnow/:identificationCode',
        component: IdNowComponent,
    },
    {
        component: FullComponent,
        path: '',
        children: [
            {
                path: 'welcome/step/:nr',
                component: OnboardingComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'pub/data-protection',
                component: DataProtectionComponent,
                canActivate: [UserValidTokenGuard],
            },
            {
                path: 'pub/imprint',
                component: ImprintComponent,
                canActivate: [UserValidTokenGuard],
            },
            {
                path: 'login',
                component: LoginComponent,
                canActivate: [UserValidTokenGuard],
                data: { showFooter: false },
            },
            {
                path: 'special-login',
                component: LoginComponent,
                canActivate: [UserValidTokenGuard],
                data: { showFooter: false },
            },
            {
                path: 'create-account',
                component: RegistrationFormComponent,
                canActivate: [UserValidTokenGuard],
            },
            {
                path: 'direct-login/owner/:ownerNumber/:token',
                component: DirectLoginComponent,
                canActivate: [UserValidTokenGuard],
            },
            {
                path: 'direct-login/accommodation/:accommodationId/:token',
                component: DirectLoginComponent,
                canActivate: [UserValidTokenGuard],
            },
            {
                path: 'welcome',
                redirectTo: 'welcome/step/1',
            },
            {
                path: 'password-forgotten',
                component: PasswordForgotten,
                canActivate: [UserValidTokenGuard],
            },
            {
                path: '',
                canActivate: [AuthRedirectGuard],
                component: MainComponent,
            },
        ],
    },
    {
        component: MainComponent,
        path: '',
        children: [
            {
                path: 'accommodations',
                component: AccommodationsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'accommodations/:state',
                component: AccommodationsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'accommodation-not-found',
                component: NotFoundComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
                canActivate: [AuthGuard, OnboardingGuard],
            },
            {
                path: 'accommodation/fast',
                redirectTo: '/login',
            },
            {
                path: 'accommodation/register',
                redirectTo: '/accommodation/register/position',
                pathMatch: 'full',
            },
            {
                path: 'accommodation/:accommodationId',
                component: AccommodationComponent,
                canActivate: [AuthGuard],
                children: [
                    { path: '', redirectTo: 'calendar', pathMatch: 'full' },
                    { path: 'main', component: AccommodationMainComponent, canDeactivate: [PendingChangesGuard], data: { scrollTop: false } },
                    { path: 'position', component: PositionComponent, canDeactivate: [PendingChangesGuard], data: { scrollTop: false } },
                    { path: 'features', component: FeaturesComponent, canDeactivate: [PendingChangesGuard], data: { scrollTop: false } },
                    { path: 'description', component: DescriptionComponent, canDeactivate: [PendingChangesGuard], data: { scrollTop: false } },
                    { path: 'images', component: ImagesComponent, data: { scrollTop: false } },
                    { path: 'image/:order', component: ImageComponent, canDeactivate: [PendingChangesGuard], data: { title: 'ImageDetail', scrollTop: false } },
                    { path: 'prices', component: PriceComponent, canDeactivate: [PendingChangesGuard], data: { scrollTop: false } },
                    { path: 'calendar', component: CalendarComponent, canDeactivate: [PendingChangesGuard], data: { scrollTop: false } },
                    { path: 'model', component: ModelComponent, canDeactivate: [PendingChangesGuard], data: { scrollTop: false } },
                ],
            },
            {
                path: 'accommodation-groups',
                component: AccommodationGroupsComponent,
                canActivate: [AuthGuard],
                canDeactivate: [PendingChangesGuard],
            },
            {
                path: 'accommodation-group',
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'create',
                        component: AccommodationGroupComponent,
                        children: [{ path: '', component: ObjectsComponent, canDeactivate: [PendingChangesGuard] }],
                    },
                    {
                        path: ':accommodationGroupId',
                        component: AccommodationGroupComponent,
                        children: [
                            { path: '', redirectTo: 'objects', pathMatch: 'full' },
                            { path: 'overview', component: AccommodationGroupOverviewComponent, data: { scrollTop: false } },
                            { path: 'objects', component: ObjectsComponent, canDeactivate: [PendingChangesGuard], data: { scrollTop: false } },
                            { path: 'description', component: DescriptionComponent, canDeactivate: [PendingChangesGuard], data: { scrollTop: false } },
                            { path: 'position', component: PositionComponent, canDeactivate: [PendingChangesGuard], data: { scrollTop: false } },
                            { path: 'images', component: ImagesComponent, data: { scrollTop: false } },
                            { path: 'image/:order', component: ImageComponent, canDeactivate: [PendingChangesGuard], data: { title: 'ImageDetail', scrollTop: false } },
                        ],
                    },
                ],
            },
            {
                path: 'arrival',
                component: ArrivalInformationComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'bookings',
                component: BookingListComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'history',
                component: HistoryComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'profile',
                component: ProfileComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'interface',
                component: InterfaceComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'additional-cost-templates',
                component: AdditionalCostTemplateComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'notification',
                component: NotificationsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'notification/show/:messageId',
                component: MessageContentComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'ratings',
                component: RatingsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'invoices',
                component: InvoicesComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'terms',
                component: TermsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'change-owner/:accommodationId',
                component: ChangeOwnerComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'data-protection',
                component: DataProtectionComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'imprint',
                component: ImprintComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'pool-config',
                component: PoolConfigComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'owner-list',
                component: OwnerListComponent,
                canActivate: [AuthGuard],
            },
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'login',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            enableTracing: false,
            scrollPositionRestoration: 'disabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    userLanguage = navigator.language.slice(0, 2) || 'en';

    constructor(translate: TranslateService, readonly route: ActivatedRoute) {
        const localStorageKey = 'userSelectedLanguage';
        // Nutze die Sprache im localstorage, falls vorhanden und erlaubt
        const storageLanguage = localStorage.getItem(localStorageKey);
        if (storageLanguage && environment.allowedLanguages.includes(storageLanguage)) {
            this.userLanguage = storageLanguage;
        }

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        if (environment.allowedLanguages.includes(this.userLanguage)) {
            translate.use(this.userLanguage);
        } else {
            translate.use('en');
        }

        this.route.queryParams.subscribe((params) => {
            if (params.lang) {
                if (environment.allowedLanguages.includes(params.lang.toLowerCase())) {
                    translate.use(params.lang.toLowerCase());
                } else {
                    translate.use('en');
                }
            }
        });

        // speichere die aktuell ausgewählte Sprache im localstorage
        translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
            localStorage.setItem(localStorageKey, event.lang);
        });
    }
}
