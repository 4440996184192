<div *ngIf="user?.addressBook?.contacts" [attr.id]="newUser && !withoutLayout ? 'register-login' : ''">
    <h1 *ngIf="newUser && !withoutLayout">{{ 'atraveo.accommodationbundle.fast.registernow.0' | translate }}</h1>
    <form [formGroup]="registrationForm" (ngSubmit)="save()" id="registrationForm{{ primaryId }}" [class.create-account]="newUser && !withoutLayout">
        <div *ngIf="showAutoFillButton" class="mb-3">
            <a class="btn btn-secondary" (click)="autoFillForm()">Automatisch ausfüllen</a>
        </div>
        <div class="form-group row" *ngIf="newUser">
            <div class="col-12">
                <label for="input-type{{primaryId}}"
                       class="col-form-label">{{ 'text.ownerType' | translate }}</label>
                <div class="row mt-3">
                    <div class="owner-type-wrapper col-12">
                        <div class="col-sm-4 d-flex align-items-center pl-0 owner-type-box custom-radio" *ngIf="user.settings" [class.radio-error]="isSubmitted && ![0, 6].includes(registrationForm.controls.ownerType.value)">
                            <label for="owner" class="mb-0"><span class="label">{{ 'text.ownertype.eigentuemer' | translate }}</span>
                                <input class="owner-type" type="radio" name="ownerType" id="owner" [value]="0" formControlName="ownerType">
                                <span class="radio-btn"><i class="check-icon"></i></span>
                            </label>
                        </div>
                        <div class="col-sm-4 d-flex align-items-center pl-0 owner-type-box custom-radio" *ngIf="user.settings" [class.radio-error]="isSubmitted && ![0, 6].includes(registrationForm.controls.ownerType.value)">
                            <label for="agency" class="mb-0"><span class="label">{{ 'text.ownertype.agentur' | translate }}</span>
                                <input class="owner-type" type="radio" name="ownerType" id="agency" [value]="6" formControlName="ownerType">
                                <span class="radio-btn"><i class="check-icon"></i></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="isUserProfile">
            <ng-container *ngIf="['CREATED', 'CLICKED'].includes(this.authService.currentUser?.registration?.idNowStatusText)">
                <app-idnow-banner></app-idnow-banner>
            </ng-container>

            <div class="form-group row" *ngIf="this.authService.currentUser?.registration?.idNowStatusText && !['CREATED', 'CLICKED'].includes(this.authService.currentUser?.registration?.idNowStatusText)">
                <label class="col-12 col-form-label">Status</label>
                <div class="col-12">
                    <app-idnow-banner inForm="true"></app-idnow-banner>
                </div>
            </div>
        </ng-container>

        <p *ngIf="deletableReason" class="error" [innerHTML]="deletableReason | translate"></p>

        <div class="form-group row">
            <div class="col-12">
                <label for="input-salutation{{primaryId}}"
                       class="col-form-label required">{{ 'text.salutation.0' | translate }}</label>
                <select class="form-control inputSalutation" id="input-salutation{{primaryId}}"
                        name="salutation"
                        formControlName="salutation">
                    <option value="" disabled hidden>{{ 'text.select.empty' | translate }}</option>
                    <option value="Herr">{{ 'text.salutation.male' | translate }}</option>
                    <option value="Frau">{{ 'text.salutation.female' | translate }}</option>
                    <option value="Familie">{{ 'text.salutation.family' | translate }}</option>
                    <option value="Firma">{{ 'text.salutation.company' | translate }}</option>
                    <option value="Sonstige">{{ 'text.salutation.other' | translate }}</option>
                </select>
            </div>
            <div class="col-12">
                <app-validation-error-messages [control]="registrationForm.controls.salutation"></app-validation-error-messages>
            </div>
        </div>

        <ng-template [ngIf]="pageType == pageTypes.typePerson">
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="input-firstname{{primaryId}}"
                           class="col-form-label" [ngClass]="{'required': newUser}">{{ 'text.forename' | translate }}</label>
                    <div class="row mt-3">
                        <div class="col-12">
                            <input type="text" class="form-control" id="input-firstname{{primaryId}}"
                                   name="first_name"
                                   formControlName="forename">
                        </div>
                    </div>
                    <app-validation-error-messages [control]="registrationForm.controls.forename"></app-validation-error-messages>
                </div>
                <div class="col-sm-6">
                    <label for="input-lastname{{primaryId}}"
                           class="col-form-label required">{{ 'text.surname' | translate }}</label>
                    <div class="row mt-3">
                        <div class="col-12">
                            <input type="text" class="form-control" id="input-lastname{{primaryId}}"
                                   name="last_name"
                                   formControlName="surname">
                        </div>
                    </div>
                    <app-validation-error-messages [control]="registrationForm.controls.surname"></app-validation-error-messages>
                </div>
            </div>
        </ng-template>

        <ng-template [ngIf]="pageType == pageTypes.typeCompany">
            <div class="form-group row">
                <div class="col-12">
                    <label for="input-firstname{{primaryId}}"
                           class="col-form-label required">{{ 'registration.management' | translate }}
                    </label>
                </div>
                <div class="col-sm-6">
                    <div class="row mt-3">
                        <div class="col-12">
                            <input type="text" class="form-control" id="input-firstname{{primaryId}}"
                                   placeholder="{{ 'text.forename' | translate }}"
                                   formControlName="forename">
                        </div>
                    </div>
                    <app-validation-error-messages [control]="registrationForm.controls.forename"></app-validation-error-messages>
                </div>
                <div class="col-sm-6">
                    <div class="row mt-3">
                        <div class="col-12">
                            <input type="text" class="form-control" id="input-lastname{{primaryId}}"
                                   placeholder="{{ 'text.surname' | translate }}"
                                   formControlName="surname">
                        </div>
                    </div>
                    <app-validation-error-messages [control]="registrationForm.controls.surname"></app-validation-error-messages>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-12">
                    <label for="input-company{{primaryId}}"
                           class="col-form-label required">{{ 'text.company' | translate }}
                    </label>
                    <div class="row mt-3">
                        <div class="col-12">
                            <input type="text" class="form-control" id="input-company{{primaryId}}"
                                   formControlName="company">
                            <app-validation-error-messages [control]="registrationForm.controls.company"></app-validation-error-messages>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template [ngIf]="pageType == pageTypes.typeOther">
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="input-firstname{{primaryId}}"
                           class="col-form-label required">{{ 'text.forename' | translate }}</label>
                    <div class="row mt-3">
                        <div class="col-12">
                            <input type="text" class="form-control" id="input-firstname{{primaryId}}"
                                   formControlName="forename">
                        </div>
                    </div>
                    <div class="col-12">
                        <app-validation-error-messages [control]="registrationForm.controls.forename"></app-validation-error-messages>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="input-lastname{{primaryId}}"
                           class="col-form-label required">{{ 'text.surname' | translate }}</label>
                    <div class="row mt-3">
                        <div class="col-12">
                            <input type="text" class="form-control" id="input-lastname{{primaryId}}"
                                   formControlName="surname">
                        </div>
                    </div>
                    <div class="col-12">
                        <app-validation-error-messages [control]="registrationForm.controls.surname"></app-validation-error-messages>
                    </div>
                </div>
            </div>
        </ng-template>

        <div class="form-group row">
            <div class="col">
                <label for="input-street{{primaryId}}" class="col-form-label" [ngClass]="{'required': isUserProfile}">{{ 'text.street.0' | translate }}
                </label>
                <app-tooltip [text]="'info.privatadresse' | translate" *ngIf="isUserProfile"></app-tooltip>
                <div class="row mt-3">
                    <div class="col-12">
                        <input type="text" class="form-control" id="input-street{{primaryId}}"
                               name="street"
                               formControlName="street">
                    </div>
                </div>
                <app-validation-error-messages [control]="registrationForm.controls.street"></app-validation-error-messages>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-6">
                <label for="input-zip{{primaryId}}"
                       [ngClass]="{'required': isUserProfile}">{{ 'text.zip' | translate }}</label>
                <input type="text" class="form-control" id="input-zip{{primaryId}}"
                       name="zipCode"
                       formControlName="zipCode">
                <app-validation-error-messages [control]="registrationForm.controls.zipCode"></app-validation-error-messages>
            </div>
            <div class="col-sm-6">
                <label for="input-city{{primaryId}}"
                       [ngClass]="{'required': isUserProfile}">{{ 'text.city' | translate }}</label>
                <div class="row mt-3">
                    <div class="col-12">
                        <input type="text" class="form-control" id="input-city{{primaryId}}"
                               name="city"
                               formControlName="city">
                    </div>
                </div>
                <app-validation-error-messages [control]="registrationForm.controls.city"></app-validation-error-messages>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-12">
                <label for="input-country{{primaryId}}"
                       class="col-form-label" [ngClass]="{'required': isUserProfile}">{{ 'text.country' | translate }}</label>
                <div class="row mt-3">
                    <div class="col-12">
                        <app-country-select id="input-country{{primaryId}}" idKey="input-country{{primaryId}}" classKey="registration-form form-control"
                                            [isUserProfile]="isUserProfile"
                                            [form]="registrationForm"
                                            formControlName="countryISOCode"
                                            (setPhonePrefix)="setPhonePrefix($event)"
                        >
                        </app-country-select>
                    </div>
                </div>
                <app-validation-error-messages [control]="registrationForm.controls.countryISOCode"></app-validation-error-messages>
            </div>
        </div>

        <app-validation-error-messages *ngIf="!newUser" [control]="registrationForm"></app-validation-error-messages>
        <div *ngIf="!newUser" class="form-group row">
            <div class="col col-12">
                <label for="input-phone-prefix{{primaryId}}">{{ 'text.number.phone' | translate }}</label>
                <app-tooltip [text]="'info.festnetznummer' | translate" *ngIf="isUserProfile"></app-tooltip>
            </div>
            <div class="col-sm-6">
                <select class="form-control"
                        formControlName="prefix0">
                    <option *ngFor="let prefix of prefixes;" [value]="prefix.value" [disabled]="prefix?.disabled">
                            {{ prefix.name }}
                    </option>
                </select>
                <app-validation-error-messages [control]="registrationForm.controls.prefix0"></app-validation-error-messages>
            </div>
            <div class="col-sm-6">
                <div class="row mt-3">
                    <div class="col-12">
                        <input type="tel" class="form-control" id="input-phone{{primaryId}}"
                               formControlName="number0">
                        <app-validation-error-messages [control]="registrationForm.controls.number0"></app-validation-error-messages>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!newUser" class="form-group row">
            <div class="col col-12">
                <label for="input-phone-prefix{{primaryId}}">{{ 'text.number.phone' | translate }} 2</label>
                <app-tooltip [text]="'info.festnetznummer' | translate" *ngIf="isUserProfile"></app-tooltip>
            </div>
            <div class="col-sm-6">
                <select class="form-control"
                        formControlName="prefix1">
                    <option *ngFor="let prefix of prefixes;" [value]="prefix.value" [disabled]="prefix?.disabled">
                        {{ prefix.name }}
                    </option>
                </select>
                <app-validation-error-messages [control]="registrationForm.controls.prefix1"></app-validation-error-messages>
            </div>
            <div class="col-sm-6">
                <div class="row mt-3">
                    <div class="col-12">
                        <input type="tel" class="form-control" id="input-phone2{{primaryId}}"
                               formControlName="number1">
                        <app-validation-error-messages [control]="registrationForm.controls.number1"></app-validation-error-messages>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-12">
                <label *ngIf="newUser; else mobileNumber" for="input-phone-mobile-prefix{{primaryId}}" class="required">{{ 'text.number.mobile.0' | translate }}</label>
                <ng-template #mobileNumber>
                    <label for="input-phone-mobile-prefix{{primaryId}}">{{ 'text.number.mobile.phone' | translate }}</label>
                </ng-template>
                <app-tooltip [text]="'info.erreichbakeit' | translate" *ngIf="!isUserProfile"></app-tooltip>
            </div>
            <div class="col-sm-6">
                <select class="form-control" id="input-phone-mobile-prefix{{primaryId}}"
                        formControlName="prefix2">
                    <option *ngFor="let prefix of prefixes;" [value]="prefix.value" [disabled]="prefix?.disabled">
                        {{ prefix.name }}
                    </option>
                </select>
                <app-validation-error-messages [control]="registrationForm.controls.prefix2"></app-validation-error-messages>
            </div>
            <div class="col-sm-6">
                <div class="row mt-3">
                    <div class="col-12">
                        <input type="tel" class="form-control" id="input-phone-mobile{{primaryId}}"
                               name="mobile_phone_number"
                               formControlName="number2">
                    </div>
                </div>
                <app-validation-error-messages [control]="registrationForm.controls.number2"></app-validation-error-messages>
            </div>
        </div>
        <div class="form-group row" *ngIf="!newUser && isUserProfile">
            <div class="col">
                <div class="form-check d-flex align-items-center">
                    <input type="checkbox" id="sms{{primaryId}}" class="form-check-input" formControlName="smsNotifications">
                    <label for="sms{{primaryId}}" class="form-check-label sms">{{ 'text.notification.sms' | translate }}</label>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-12">
                <label
                       class="col-form-label" [ngClass]="{'required': isUserProfile}">{{ 'text.email' | translate }}
                </label>
                <div formArrayName="emails">
                    <div class="row" *ngFor="let email of emailArray.controls; let i = index;">
                        <div class="col-12 d-flex mb-2">
                            <div class="w-100 d-flex flex-column">
                                <input type="email" class="form-control"
                                       name="email"
                                       [formControlName]="i">
                                <app-validation-error-messages [control]="emailArray.controls[i]"></app-validation-error-messages>
                                <div class="uniqueEmailErr" *ngIf="!primaryDefaultEmailAddressUnique && i === 0">
                                    <span>{{ 'text.uniqueEmailErr' | translate }}</span><br>
                                </div>
                            </div>
                            <div *ngIf="i > 0">
                                <button class="btn btn-link" (click)="deleteEmail(i)" type="button">
                                    <i class="fas fa-trash-alt trash-email"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div *ngIf="!newUser" class="row">
                    <div class="col">
                        <a class="btn btn-secondary" (click)="addEmail()">
                            <i class="fas fa-plus mr-2"></i>
                            {{ 'text.addemail' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row" *ngIf="!isUserProfile">
            <div class="col-12">
                <label class="col-form-label">{{ 'text.contact.person.language' | translate }}</label>
                <div class="row mt-3">
                    <div class="col-12">
                        <button type="button" class="btn-group">
                            <button type="button" class="multiselect dropdown-toggle btn btn-select form-control" data-toggle="dropdown">
                                <span class="multiselect-selected-text" *ngIf="this.user.addressBook.contacts[this.primaryId]?.spokenLanguages && this.user.addressBook.contacts[this.primaryId]?.spokenLanguages.length === 0">
                                    {{ objectSelected }} {{ selected | translate }} {{ allSelected }}
                                </span>
                                <span class="multiselect-selected-text" *ngIf="this.user.addressBook.contacts[this.primaryId]?.spokenLanguages && this.user.addressBook.contacts[this.primaryId]?.spokenLanguages.length > 0">
                                    {{ this.user.addressBook.contacts[this.primaryId].spokenLanguages.length }} {{ 'select.n' | translate }}
                                </span>
                            </button>
                            <ul class="dropdown-menu" (click)="$event.stopPropagation()">
                                <li class="multiselect-item multiselect-all">
                                    <a class="multiselect-all">
                                        <label class="checkbox">
                                            <input type="checkbox" class="objectCheckAll" value="multiselect-all" (click)="changeStateAll($event)" />
                                            {{ 'select.all' | translate }}
                                        </label>
                                    </a>
                                </li>
                                <li *ngFor="let language of spokenLanguages">
                                    <a>
                                        <label class="checkbox"
                                               [ngClass]="{ active: this.user.addressBook.contacts[this.primaryId]?.spokenLanguages ? this.user.addressBook.contacts[this.primaryId].spokenLanguages?.indexOf(language) > -1 : false }">
                                            <input
                                                type="checkbox"
                                                [checked]="this.user.addressBook.contacts[this.primaryId]?.spokenLanguages ? this.user.addressBook.contacts[this.primaryId].spokenLanguages?.indexOf(language) > -1 : false"
                                                class="objectCheck"
                                                value="{{ language }}"
                                                (click)="changeState($event)"/>
                                            {{ 'intl.language.name.' + language | translate }}
                                        </label>
                                    </a>
                                </li>
                            </ul>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row" *ngIf="!newUser && isUserProfile">
            <div class="col col-12">
                <label for="input-website{{primaryId}}"
                       class="col-form-label">{{ 'text.homepage' | translate }}
                </label>
                <app-tooltip [text]="'info.homepagenutzung' | translate"></app-tooltip>
                <div class="row mt-3">
                    <div class="col-12" *ngIf="user.registration">
                        <input type="url" class="form-control" id="input-website{{primaryId}}" (change)="fixUrl()" formControlName="website">
                    </div>
                </div>
            </div>
        </div>
        <ng-template [ngIf]="pageType == pageTypes.typePerson">
            <div class="form-group row" *ngIf="!newUser && isUserProfile">
                <div class="col-12">
                    <label for="input-birthdate{{primaryId}}"
                           class="col-form-label">{{ 'text.profil.birthday' | translate }}
                    </label>
                    <div class="row mt-3">
                        <div class="col-sm-4">
                            <input type="date" class="form-control" id="input-birthdate{{primaryId}}" formControlName="birthdate">
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-container *ngIf="!newUser && isUserProfile">
            <div class="form-group row">
                <div class="col-12">
                    <label for="input-vatid{{primaryId}}"
                           class="col-form-label required">{{ 'text.preTaxDeduction' | translate }}
                    </label>
                </div>
                <div class="row mt-3 col-12">
                    <div class="owner-type-wrapper col-12">
                        <div class="col-sm-2 d-flex align-items-center pl-0 owner-type-box custom-radio" [class.radio-error]="registrationForm.controls.hasVatId.value === undefined">
                            <label for="taxAvailable" class="mb-0"><span class="label">{{ 'text.yes' | translate }}</span>
                                <input class="owner-type" type="radio" name="hasVatId" id="taxAvailable" [value]=true formControlName="hasVatId" (click)="setValidator(true)">
                                <span class="radio-btn"><i class="check-icon"></i></span>
                            </label>
                        </div>
                        <div class="col-sm-2 d-flex align-items-center pl-0 owner-type-box custom-radio" [class.radio-error]="registrationForm.controls.hasVatId.value === undefined">
                            <label for="taxNotAvailable" class="mb-0"><span class="label">{{ 'text.no' | translate }}</span>
                                <input class="owner-type" type="radio" name="hasVatId"  id="taxNotAvailable" [value]=false formControlName="hasVatId" (click)="setValidator(false)">
                                <span class="radio-btn"><i class="check-icon"></i></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <app-validation-error-messages [control]="registrationForm.controls.hasVatId"></app-validation-error-messages>
                </div>
            </div>
            <div class="form-group row" *ngIf="registrationForm.get('hasVatId').value !== undefined && registrationForm.get('hasVatId').value">
                <div class="col col-12">
                    <label for="input-vatid{{primaryId}}"
                           class="col-form-label required">{{ ustTextConst | translate }}
                    </label>
                    <div class="row mt-3">
                        <div class="col-12">
                            <input type="text" class="form-control input-vatid" formControlName="vatId">
                        </div>
                    </div>
                    <app-validation-error-messages [control]="registrationForm.controls.vatId"></app-validation-error-messages>
                </div>
            </div>
            <ng-template [ngIf]="pageType == pageTypes.typeCompany">
                <div class="form-group row" *ngIf="!newUser && isUserProfile">
                    <div class="col col-12">
                        <label for="input-registration-number{{primaryId}}"
                               class="col-form-label">{{ 'text.company_registration_number' | translate }}
                        </label>
                        <div class="row mt-3">
                            <div class="col-12">
                                <input type="text" class="form-control" formControlName="companyRegistrationNumber">
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template [ngIf]="pageType != pageTypes.typeCompany">
                <div class="form-group row">
                    <div class="col col-12">
                        <label for="input-tax-id{{primaryId}}"
                               class="col-form-label">{{ 'text.tax_id' | translate }}?
                        </label>
                    </div>
                    <div class="row mt-3 col-12">
                        <div class="owner-type-wrapper col-12">
                            <div class="col-sm-2 d-flex align-items-center pl-0 owner-type-box custom-radio">
                                <label class="mb-0"><span class="label">{{ 'text.yes' | translate }}</span>
                                    <input class="owner-type" type="radio" name="hasTaxId" id="taxIdAvailable" [value]=true formControlName="hasTaxId">
                                    <span class="radio-btn"><i class="check-icon"></i></span>
                                </label>
                            </div>
                            <div class="col-sm-2 d-flex align-items-center pl-0 owner-type-box custom-radio">
                                <label class="mb-0"><span class="label">{{ 'text.no' | translate }}</span>
                                    <input class="owner-type" type="radio" name="hasTaxId"  id="taxIdNotAvailable" [value]=false formControlName="hasTaxId">
                                    <span class="radio-btn"><i class="check-icon"></i></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="form-group row" *ngIf="registrationForm.get('hasTaxId').value || pageType == pageTypes.typeCompany">
                <div class="col col-12">
                    <label for="input-company-id-member{{primaryId}}"
                           class="col-form-label">{{ 'text.tax_id' | translate }} {{ 'text.and' | translate }} {{ 'text.member_state' | translate }}
                    </label>
                    <div formArrayName="taxIdDetails">
                        <div class="row mt-3 mb-1" *ngFor="let taxIdControl of taxIdDetailsControls; let i = index;" [formGroupName]="i">
                            <div class="col-sm-6 mb-3">
                                <input type="text" class="form-control" formControlName="id">
                            </div>
                            <div class="col-sm-5 mb-2">
                                <app-country-select id="input-company-id-member{{primaryId}}" idKey="input-company-id-member{{primaryId}}"
                                                    [classKey]="'registration-form'"
                                                    [form]="taxIdControl"
                                                    formControlName="countryISOCode">
                                </app-country-select>
                            </div>
                            <div *ngIf="i > 0" class="col-sm-1 popover-row info-delete-icons-row pr-0">
                                <a class="btn btn-link" (click)="deleteTaxId(i)">
                                    <i class="fas fa-trash-alt trash-email"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col">
                            <button type="button" class="btn btn-primary" (click)="addTaxId()">
                                <i class="fas fa-plus mr-2"></i>
                                {{ 'text.tax_id' | translate }} {{ 'text.add' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template [ngIf]="pageType != pageTypes.typeCompany && !registrationForm.get('hasTaxId').value">
                <div class="form-group row">
                    <div class="col col-12">
                        <label for="input-place-of-bird{{primaryId}}"
                               class="col-form-label">{{ 'text.profil.place_of_birth' | translate }}
                        </label>
                        <div class="row mt-3">
                            <div class="col-12">
                                <input type="text" class="form-control" formControlName="placeOfBirth">
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
        <div class="form-group row">
            <div class="col-12">
                <i>{{ 'text.mandatoryfield' | translate }}</i>
            </div>
        </div>
        <div class="form-group row" *ngIf="newUser">
            <div class="col-12">
                <p [innerHTML]="'form.data-protection.text' | translate"></p>
            </div>
        </div>
        <div class="form-group row mb-4">
            <div class="col-12 text-center save-btn" *ngIf="newUser">
                <button type="submit" class="btn btn-success" id="{{primaryId}}">
                    {{ 'atraveo.accommodationbundle.fast.registernow.button.text' | translate }}
                </button>
            </div>
            <div class="col-12 save-btn" *ngIf="!newUser">
                <button type="submit" class="btn btn-success" id="{{primaryId}}">
                    <i class="fas fa-cloud-upload-alt mr-2"></i>{{ 'button.save' | translate }}
                </button>
            </div>
        </div>
        <div>
            <span class="error">{{ errorMessage | translate }}</span>
        </div>
        <div class="form-group" *ngIf="newUser && isUserProfile && !withoutLayout">
            <p>{{ 'atraveo.accommodationbundle.fast.advertising.user' | translate }}</p>
        </div>
    </form>
</div>
