import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './services/authentication/authentication.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OwnerConnectorService } from './services/api-connector/owner-connector.service';
import { GoogleTagManagerService } from 'ngx-google-tag-manager';
import md5 from 'md5';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { DialogService } from './services/dialog.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ToogleMenuService } from './services/toggle-menu/toggle-menu.service';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    internalIpAddresses = ['213.138.63.193', '24.40.138.150', '87.193.233.146', '87.193.233.147', '116.203.0.228', '24.40.138.125'];
    translateServiceSubscription: Subscription;

    constructor(
        readonly titleService: Title,
        readonly translateService: TranslateService,
        readonly authenticationService: AuthenticationService,
        readonly router: Router,
        readonly route: ActivatedRoute,
        readonly ownerConnector: OwnerConnectorService,
        private gtmService: GoogleTagManagerService,
        readonly dialogService: DialogService,
        private breakpointObserver: BreakpointObserver,
        readonly toggleMenuService: ToogleMenuService,
    ) {
        this.setTitle();
    }

    async setTitle() {
        let translation = await this.translateService.get('atraveo.application.title').toPromise();
        this.titleService.setTitle(translation);

        this.translateServiceSubscription = this.translateService.onLangChange.subscribe(async () => {
            translation = await this.translateService.get('atraveo.application.title').toPromise();
            this.titleService.setTitle(translation);
        });
    }

    async ngOnInit() {
        // Responsive Tabelle:
        $(document).ready(() => {
            this.initTables();
            this.flexTable();

            $('body')
                .on('show.bs.collapse', '.collapse', function () {
                    $(this).parent().find('i.fa-chevron-right').removeClass('fa-chevron-right').addClass('fa-chevron-down');
                })
                .on('hide.bs.collapse', '.collapse', function () {
                    $(this).parent().find('i.fa-chevron-down').removeClass('fa-chevron-down').addClass('fa-chevron-right');
                });
        });

        $(window).on('resize', () => {
            this.flexTable();
        });
        this.breakpointObserver.observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).subscribe((result) => {
            if (result.matches) {
                this.resetViewport();
            }
        });
        const userIp = await this.ownerConnector.getIpAndLogInInventory();

        (window as any).dataLayer.push({
            event: 'propertyPush',
            internal_traffic: this.internalIpAddresses.includes(userIp) ? '1' : '0',
        });

        if (this.authenticationService?.currentUser && this.authenticationService?.currentUser?.ownerNumber) {
            (window as any).dataLayer.push({
                event: 'userID',
                userID: md5(this.authenticationService.currentUser.ownerNumber),
            });
        }

        this.gtmService.addGtmToDom();
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => {
                    let child = this.route.firstChild;
                    while (child.firstChild) {
                        child = child.firstChild;
                    }
                    return child.snapshot.data;
                }),
            )
            .subscribe((data: any) => {
                if (data.scrollTop !== false) {
                    window.scrollTo(0, 0);
                }
                this.toggleMenuService.toggleMenu(true);
            });

        this.translateService.setDefaultLang('en');
    }

    ngOnDestroy() {
        this.translateServiceSubscription?.unsubscribe();
    }
    resetViewport() {
        const viewportMeta = document.querySelector('meta[name="viewport"]');
        if (viewportMeta) {
            viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0');
            setTimeout(() => {
                viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1.0');
            }, 300);
        }
    }
    ngAfterContentChecked() {
        this.initTables();

        this.disableAutofill();
    }

    private disableAutofill() {
        document.querySelectorAll('input').forEach((element) => {
            if (!element.getAttribute('autocomplete')) {
                element.setAttribute('autocomplete', 'new-password');
            }
        });
    }

    flexTable() {
        if ($(window).width() < 768) {
            $('.table-responsive-stack').each(function () {
                $(this).find('.table-responsive-stack-thead').show();
                $(this).find('thead').hide();
            });
        } else {
            $('.table-responsive-stack').each(function () {
                $(this).find('.table-responsive-stack-thead').hide();
                $(this).find('thead').show();
            });
        }
    }

    initTables() {
        // Das ist vermutlich schlecht für die Perfomance ohne das .initialised
        $('.table-responsive-stack:not(.initalised)').each(function () {
            const $table = $(this);
            if ($(this).find('td').length) {
                if (!$(this).find('td .table-responsive-stack-thead').length) {
                    $(this)
                        .find('th')
                        .each(function (i) {
                            const $th = $(this);

                            $table.find('td:nth-child(' + (i + 1) + ')').each(function () {
                                if ($th.text()) {
                                    $(this).prepend('<span class="table-responsive-stack-thead">' + $th.text() + ':</span> ');
                                }
                            });
                            $('.table-responsive-stack-thead').hide();
                        });
                }

                // Wenn ich die Zeile reinmache geht es nicht mehr, anscheinend Rendert Angular nachträglich Teile der Tabelle neu
                // $(this).addClass('initalised');
            }
        });
    }
}
