import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ExtendedUserEntity } from '../../entities/extendedUserEntity';
import { plainToInstance } from 'class-transformer';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthenticationConnectorService } from '../api-connector/authentication-connector-service';
import { environment } from '../../../environments/environment';
import { OwnerService } from '../owner-service/owner.service';
import { ConstantsService } from '../constants/constants.service';
import { OwnerConnectorService } from '../api-connector/owner-connector.service';
import * as Sentry from '@sentry/angular-ivy';
import { NotificationService } from '../notification/notification.service';
import { cloneDeep } from 'lodash';
import { jwtDecode } from 'jwt-decode';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    currentUserSubject: BehaviorSubject<ExtendedUserEntity>;

    constructor(
        readonly router: Router,
        readonly authConnector: AuthenticationConnectorService,
        readonly route: ActivatedRoute,
        readonly ownerService: OwnerService,
        readonly ownerConnector: OwnerConnectorService,
        readonly notificationService: NotificationService,
    ) {
        const decoded = JSON.parse(localStorage.getItem('currentUser'));
        const entity = plainToInstance(ExtendedUserEntity, decoded);
        if (decoded?.lastLoginDate) {
            entity.lastLoginDate = decoded.lastLoginDate;
        }
        this.currentUserSubject = new BehaviorSubject<ExtendedUserEntity>(entity);

        this.router.events.subscribe(async (event) => {
            if (!this.currentUser) {
                return;
            }

            if (event instanceof NavigationEnd) {
                const reloadOwnerUrls = ['/interface', '/profile'];
                if (reloadOwnerUrls.includes(event.url)) {
                    const token = this.currentUser.token;
                    const roles = this.currentUser.roles;
                    const owner = await this.ownerConnector.getUserData(this.currentUser.ownerNumber);
                    owner.token = token;
                    owner.roles = roles;

                    if (owner.isDestination()) {
                        const responsePools: any = await this.ownerConnector.getDestinationPools(this.currentUser.ownerNumber);
                        if (responsePools) {
                            owner.destinationPools = responsePools.map((pool) => pool.poolId);
                        }
                    }
                    this.currentUserSubject.next(owner);
                    localStorage.setItem('currentUser', JSON.stringify(owner));
                }
            }
        });
    }

    get currentUser(): ExtendedUserEntity {
        return this.currentUserSubject.value;
    }

    get isTokenValid(): boolean {
        if (!this.currentUser) {
            return false;
        }
        const decoded = JSON.parse(atob(this.currentUser.token.split('.')[1]));
        const tokenValidUntil = new Date(decoded.exp * 1000);
        return tokenValidUntil > new Date();
    }

    isLoggedIn(): boolean {
        return this.currentUserSubject.value !== null;
    }

    userIsFromCh(): boolean {
        return this.currentUserSubject.value?.settings?.client === 5;
    }

    async callWithRetryIfFailure(call: () => Promise<any>, recursiveCounter: number = 0) {
        if (recursiveCounter > 0) {
            await new Promise((r) => setTimeout(r, 1000));
        }

        let result;
        try {
            result = await call();
        } catch (e) {
            Sentry.captureException(e);
            if (recursiveCounter < 2) {
                return this.callWithRetryIfFailure(call, ++recursiveCounter);
            } else {
                throw e;
            }
        }

        return result;
    }

    async registerUser(user: ExtendedUserEntity, destinationPool: number = null): Promise<void> {
        // User im Auth-Service anlegen
        const data = await this.callWithRetryIfFailure(() => this.authConnector.registerUser(user.getPrimaryContact().email[0], user));
        if (data.accessToken) {
            user.token = data.accessToken;
            user.jwtDate = new Date();
            user.ownerNumber = data.ownerNumber;
            user.changePasswordToken = data.changePasswordToken;
            user.roles = [ConstantsService.ROLE_OWNER];
            if (!destinationPool) {
                this.currentUserSubject.next(user);
            }

            const owner = await this.callWithRetryIfFailure(() => this.ownerConnector.saveUserData(user, true, !!destinationPool));
            if (owner.ownerNumber) {
                if (!destinationPool) {
                    await this.changeToUser(owner);
                    await this.router.navigate(['/dashboard']);
                } else {
                    await this.ownerConnector.saveDestinationPoolOwner({
                        ownerNumber: owner.ownerNumber,
                        poolId: destinationPool,
                    });
                }
            } else {
                // Wenn owner.ownerNumber nicht vorhanden ist, senden Sie eine Fehlermeldung an Sentry
                Sentry.captureMessage('Owner number not present in response', {
                    extra: {
                        ownerResponse: owner,
                        ownerSend: user,
                    },
                });
            }
        } else {
            // Wenn data.accessToken nicht vorhanden ist, senden Sie eine Fehlermeldung an Sentry
            Sentry.captureMessage('Accesstoken nicht vorhanden', {
                extra: {
                    dataResponse: data,
                    dataSend: user,
                },
            });
        }
    }

    async reloadUser() {
        const user = await this.ownerConnector.getUserData(this.currentUser.ownerNumber);
        await this.changeToUser(user);
        return this.currentUser;
    }

    async changeToUser(owner: ExtendedUserEntity): Promise<void> {
        const currentRoles = this.currentUser.roles;
        const token = this.currentUser.token;
        this.currentUserSubject.next(owner);
        this.currentUser.roles = currentRoles;
        this.currentUser.token = token;
        this.currentUser.lastLoginDate = await this.authConnector.getLastLoginDate(owner.ownerNumber.toString());

        if (this.currentUser.isDestination()) {
            const responsePools: any = await this.ownerConnector.getDestinationPools(this.currentUser.ownerNumber);
            if (responsePools) {
                this.currentUser.destinationPools = responsePools.map((pool) => pool.poolId);
            }
        }
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
    }

    async loadAndChangeToUser(ownerNumber: number, token: string = null): Promise<void> {
        const user = await this.ownerConnector.getUserData(ownerNumber, token);
        if (!user) {
            throw new Error('Ungültige Vermieternummer.');
        }
        await this.changeToUser(user);
    }

    async saveOwner(user: ExtendedUserEntity): Promise<number> {
        const preparedOwnerForInventory = await this.prepareOwnerForInventory(user);
        const token = preparedOwnerForInventory.token;
        const roles = preparedOwnerForInventory.roles;
        const newOwner = await this.ownerConnector.saveUserData(preparedOwnerForInventory);
        newOwner.token = token;
        newOwner.roles = roles;
        this.currentUserSubject.next(newOwner);
        localStorage.setItem('currentUser', JSON.stringify(newOwner));
        return newOwner.ownerNumber;
    }

    async login(username, password, role = null, loginAsUser = null, ownerType = null): Promise<void> {
        if (!this.currentUser) {
            if (environment.authDevEnvDisabled) {
                const fakeUser = this.ownerService.createNewOwner('de');
                fakeUser.ownerNumber = 666;
                fakeUser.addressBook.contacts[0].forename = 'Fake';
                fakeUser.addressBook.contacts[0].surname = 'User';
                fakeUser.username = '666';
                fakeUser.roles = [ConstantsService.ROLE_ADMIN];
                fakeUser.termsOfServiceSettings.firstAcceptedDateTime = new Date();
                localStorage.setItem('currentUser', JSON.stringify(fakeUser));
                this.currentUserSubject.next(fakeUser);
                return;
            }

            const user = await this.ownerConnector.login(username, password, role, loginAsUser, ownerType);
            user.jwtDate = new Date();
            // User erst aktualisieren, damit der Token da ist
            this.currentUserSubject.next(user);

            this.currentUser.lastLoginDate = await this.authConnector.getLastLoginDate(user.ownerNumber.toString());

            // Dann noch mal aktualisieren um das Datum zu setzen
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);

            const urlPath = this.route.snapshot.root.firstChild?.routeConfig?.path;

            if (['accommodations', 'bookings', 'arrival', 'ratings', 'profile', 'notification', 'accommodation-groups', 'additional-cost-templates', 'interface'].includes(urlPath)) {
                await this.router.navigate(['/' + urlPath]);
            } else {
                await this.router.navigate(['/dashboard']);
            }
        }
    }

    logout(withRedirect = true): void {
        this.currentUserSubject.next(null);
        localStorage.removeItem('currentUser');
        if (withRedirect) {
            this.router.navigate(['/login']);
            location.reload();
        }
    }

    async prepareOwnerForInventory(user: ExtendedUserEntity) {
        const userClone = plainToInstance(ExtendedUserEntity, cloneDeep(user));

        if (Object.keys(userClone.addressBook.contacts).length) {
            for (const contactId of Object.keys(userClone.addressBook.contacts)) {
                if (userClone.addressBook.primaryId === contactId) {
                    if (userClone.tax?.taxIds?.length) {
                        if (!userClone.tax?.hasTaxId && userClone.addressBook.contacts[contactId].salutation !== 'Firma') {
                            userClone.tax.taxIds = [];
                        } else {
                            for (let i = userClone.tax.taxIds.length - 1; i >= 0; i--) {
                                if (!Object.keys(userClone.tax.taxIds[i])?.length) {
                                    userClone.tax.taxIds.splice(i, 1);
                                }
                            }
                        }
                    }
                    if ((userClone.tax?.hasTaxId && userClone.addressBook.contacts[contactId]?.placeOfBirth !== '') || userClone.addressBook.contacts[contactId].salutation === 'Firma') {
                        delete userClone.addressBook.contacts[contactId].placeOfBirth;
                    }
                    if (userClone.tax?.companyRegistrationNumber && userClone.addressBook.contacts[contactId].salutation !== 'Firma') {
                        delete userClone.tax.companyRegistrationNumber;
                    }
                }
                if (userClone.addressBook.contacts[contactId].company !== '' && userClone.addressBook.contacts[contactId].salutation !== 'Firma') {
                    delete userClone.addressBook.contacts[contactId].company;
                }
            }
        }

        return userClone;
    }

    getDecodedToken(): any {
        return jwtDecode(this.currentUser.token);
    }
}
