import { Component, Inject, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { BookingEntity } from 'data-structures/lib/es6/entity/booking.entity';
import { BookingConnectorService } from '../../../../../services/api-connector/booking-connector.service';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { environment } from '../../../../../../environments/environment';
import { OwnerStatusEnum } from 'data-structures/lib/es6/enum/owner-status.enum';
import { DateService } from '../../../../../services/date/date.service';
import { OwnerCurrencyEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '../../../../../services/dialog.service';

@Component({
    selector: 'app-booking-view',
    templateUrl: './booking-view.component.html',
    styleUrls: ['./booking-view.component.scss'],
})
export class BookingViewComponent implements OnInit {
    @Input() bookingFromList: BookingEntity;
    @Input() id: string; // Wird an die ID des Modals drangehängt
    booking: BookingEntity = null;
    additionalCostDefinitions = {};
    format: string = '';
    translationServiceSubscribtion: any;
    prePaymentDate: Date;
    finalPaymentDate: Date;
    prepaymentToOwnerStrings: string[] = [];
    finalPaymentStrings: string[] = [];
    bookingIsInFuture: boolean = false;
    ownerCurrency: OwnerCurrencyEnum = this.authService.currentUser.getOwnerCurrency();
    countryName: string = '';
    translationMappingList = [];

    constructor(
        readonly translationService: TranslateService,
        readonly authService: AuthenticationService,
        readonly bookingConnectorService: BookingConnectorService,
        readonly apiConnector: ApiConnectorService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        readonly dialogService: DialogService,
    ) {
        this.bookingFromList = data.booking;
        this.id = data.id;
    }

    async updateData() {
        this.format = environment.dateFormats[this.translationService.currentLang];
        if (this.bookingFromList) {
            // Es wird einmal eine Buchung aus der Buchungsübersicht übergeben, die enthält aber nicht alle Felder die man für die Buchungsansicht / Buchung editieren benötigt
            if (!this.booking || this.bookingFromList.bookingId !== this.booking.bookingId) {
                this.booking = await this.bookingConnectorService.getBookingById(this.authService.currentUser.ownerNumber, this.bookingFromList.bookingId);
            }

            if (this.booking) {
                if (this.booking.payment?.prePaymentDateFromCustomer) {
                    this.prePaymentDate = this.booking.payment?.prePaymentDateFromCustomer;
                    this.prepaymentToOwnerStrings = (await this.translationService.get('atraveo.bookingbundle.prepaymentToOwner.message').toPromise()).replace('{{datum}}', '#').split('#');
                }

                if (this.booking.payment?.finalPaymentDateToOwner) {
                    this.finalPaymentDate = this.booking.payment?.finalPaymentDateToOwner;
                    this.finalPaymentStrings = (await this.translationService.get('atraveo.bookingbundle.finalPaymentToOwner.message').toPromise()).replace('{{datum}}', '#').split('#');
                }
            }
        }

        if (!this.additionalCostDefinitions || !Object.keys(this.additionalCostDefinitions).length) {
            this.additionalCostDefinitions = await this.apiConnector.getAdditionalCostDefinitions();
        }

        if (!this.booking) {
            return;
        }

        this.fillTranslationMappingList();

        if (DateService.getDateFromString(this.booking.departureDate) > new Date()) {
            this.bookingIsInFuture = true;
        } else {
            this.bookingIsInFuture = false;
        }

        if (this.booking.customer?.countryISOCode) {
            this.countryName = new Intl.DisplayNames([this.translationService.currentLang], { type: 'region' }).of(this.booking.customer?.countryISOCode);
        }
    }

    getTranslationOfObject(textObject) {
        if (textObject[this.translationService.currentLang]) {
            return textObject[this.translationService.currentLang];
        } else if (textObject[this.translationService.defaultLang]) {
            return textObject[this.translationService.defaultLang];
        }
        return undefined;
    }

    getTranslationFromKey(costName: string) {
        let returnString = undefined;
        if (costName in this.additionalCostDefinitions) {
            const selectedCostDefinition = this.additionalCostDefinitions[costName];
            returnString = this.getTranslationOfObject(selectedCostDefinition?.additionalCostName);
        } else {
            for (const key in this.additionalCostDefinitions) {
                if (Object.values(this.additionalCostDefinitions[key].additionalCostName).includes(costName)) {
                    const possibleCostDefinition = this.additionalCostDefinitions[key];
                    returnString = this.getTranslationOfObject(possibleCostDefinition?.additionalCostName);
                }
            }
        }
        return returnString || costName;
    }

    fillTranslationMappingList() {
        if (this.booking.additionalCosts?.length) {
            for (const additionalCost of this.booking.additionalCosts.filter((cost) => cost.hasToBePaidLocal)) {
                this.translationMappingList.push(this.getTranslationFromKey(additionalCost.additionalCost));
            }
        }
    }

    async ngOnInit() {
        this.translationServiceSubscribtion = this.translationService.onLangChange.subscribe(() => {
            this.format = environment.dateFormats[this.translationService.currentLang];
        });
        await this.updateData();
    }
    async ngOnDestroy() {
        this.translationServiceSubscribtion?.unsubscribe();
    }
    async ngOnChanges() {
        await this.updateData();
    }
    async downloadPDF(): Promise<void> {
        await this.bookingConnectorService.downloadBookingDetails(this.authService.currentUser.ownerNumber, this.booking.bookingId, this.translationService.currentLang);
    }

    async freeBooking() {
        const result = await this.bookingConnectorService.freeBooking(this.booking.bookingId, this.booking.ownerNumber);
        if (result) {
            this.booking.ownerStatus = OwnerStatusEnum.DeclinedButFree;
        }
    }
    closeDialog() {
        this.dialogService.closeDialog();
    }
}
