import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { BackOfficeConnectorService } from '../api-connector/back-office-connector-service';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MessageCountService {
    messagesCountChange: Subject<number> = new Subject<number>();
    messagesCount: number;

    constructor(readonly authenticationService: AuthenticationService, readonly backofficeApiConnector: BackOfficeConnectorService) {}

    async getMessagesCount() {
        if (!this.authenticationService.currentUser?.ownerNumber) {
            return 0;
        }

        this.messagesCount = await this.backofficeApiConnector.getUnreadMessagesCount(this.authenticationService.currentUser.ownerNumber);
        this.messagesCountChange.next(this.messagesCount);
        return this.messagesCount;
    }

    async removeMessagesCount(messagesToRemove: number) {
        this.messagesCount = this.messagesCount - messagesToRemove;
        this.messagesCountChange.next(this.messagesCount);
    }

    async addMessagesCount(messagesToAdd: number) {
        this.messagesCount = this.messagesCount + messagesToAdd;
        this.messagesCountChange.next(this.messagesCount);
    }
}
