<div class="form-group row">
    <div class="col">
    <select class="form-control custom-select"
            [(ngModel)]="currentLanguage"
            (change)="updateLength()">
        <option *ngFor="let language of this.allowedLanguages" value="{{ language }}"
                [class.present]="content[language]?.length">{{ 'intl.language.name.' + language | translate }}</option>
    </select>
    </div>
    <div class="col-sm-2">
        <a (click)="deleteCurrentLanguage()" class="btn btn-link"><i class="fa fa-trash-alt fa-lg"></i></a>
    </div>
</div>

<div class="form-group row">
    <div class="col">
        <textarea class="form-control" (keyup)="updateLength()" [(ngModel)]="content[currentLanguage]"></textarea>
        {{ lengthText }}
    </div>
    <div class="col-sm-2">
        <app-tooltip [text]="infoKey | translate" *ngIf="infoKey"></app-tooltip>
    </div>
</div>


