<div *ngIf="accommodationOrGroup" class="page-container">
    <!-- Header nur für Mobile -->
    <div class="mobile-view d-sm-none">
        <div *ngIf="imageCount > 0" class="container-progress-bar d-flex justify-content-center mt-5">
            <h2 class="headline">{{ 'text.images.uploaded' | translate: {images: imageCount} }}</h2>
        </div>

        <div class="justify-content-center p-1" *ngIf="!selectionStarted">
            <app-progress-bar [progressBarTotal]="imagesPercent" [classNameOverride]="progressBarClassName"></app-progress-bar>
        </div>

        <app-photos-guide></app-photos-guide>
    </div>

     <!-- Hauptbild und nur für Desktop Content daneben -->
    <div class="row mt-3" *ngIf="accommodationOrGroup?.media?.length && viewType === 'accommodation'">
        <div class="col-md-auto p-0 ml-3 mr-3">
            <div class="d-flex justify-content-center main-image-wrapper main-image-container align-items-center" id="mainImage" *ngIf="accommodationOrGroup?.media?.length" #mainImage >
                <div class="draggable element old-main-image" (contextmenu)="contextMenu($event)">
                    <a [routerLink]="!selectionStarted ? '/' + accommodationOrGroup.getRouteForAccommodationOrGroup() + '/' + accommodationOrGroup.getIdForAccommodationOrGroup() + '/image/' + accommodationOrGroup.media[0].order : null"
                       [class.selected-link] = "selectedImagesDisplayUrl.includes( accommodationOrGroup.media[0].displayUrl)"
                       [class.selection-started] = "selectionStarted"
                       (click)="selectOrUnselectImage( accommodationOrGroup.media[0].displayUrl)">
                        <div [class.selected] = "selectedImagesDisplayUrl.includes( accommodationOrGroup.media[0].displayUrl)">
                            <i *ngIf="selectedImagesDisplayUrl.includes( accommodationOrGroup.media[0].displayUrl)" class="fa fa-check"></i>
                        </div>
                        <picture [class.remote]="accommodationOrGroup.media[0]?.remoteUrl">
                            <source srcset="{{ accommodationOrGroup.media[0].displayUrl | imageUrlPipe: { x: 600, y: 400, format: 'webp', media: mainImage } }}" type="image/webp" media="(min-width: 600px)">
                            <source srcset="{{ accommodationOrGroup.media[0].displayUrl | imageUrlPipe: { x: 300, y: 200, format: 'webp', media: mainImage } }}" type="image/webp" media="(mix-width: 10px)">
                            <img src="{{ accommodationOrGroup.media[0].displayUrl | imageUrlPipe: { x: 600, y: 400, media: mainImage } }}" />
                        </picture>
                    </a>
                    <div class="title-text">{{ 'text.images.main' | translate }}</div>
                </div>
            </div>
        </div>

        <!-- Content neben Bild, nur für Desktop -->
        <div class="col d-none d-sm-block desktop-view">
            <div *ngIf="!selectionStarted">
                <div *ngIf="imageCount > 0" class="container-progress-bar d-flex">
                    <h3 class="headline">{{ 'text.images.uploaded' | translate: {images: imageCount} }}</h3>
                </div>

                <div class="d-flex mb-3">
                    <app-progress-bar [progressBarTotal]="imagesPercent" [classNameOverride]="progressBarClassName"></app-progress-bar>
                </div>
            </div>

            <ng-container *ngIf="!selectionStarted">
                <input type="file" class="d-none" id="upload-desktop" multiple (change)="addNewFilesFromButton($event)">
                <label class="btn btn-success desktop-upload-button mb-3" for="upload-desktop"> {{ 'atraveo.accommodationbundle.media.upload.0' | translate }}</label>
            </ng-container>

            <app-photos-guide></app-photos-guide>

            <div class="desktop-selection-buttons" *ngIf="selectionStarted">
                <!-- Fotos löschen Button -->
                <div class="d-flex mt-3">
                    <button class="btn btn-secondary" [disabled]="!selectedImagesDisplayUrl.length" (click)="openConfirmationDialog()"> {{ 'atraveo.accommodationbundle.media.remove' | translate }}</button>
                </div>

                <!-- Markierung abbrechen -->
                <div class="d-flex mt-3">
                    <button class="btn btn-danger" (click)="this.stopSelection()"> {{ 'button.cancel' | translate }}</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Bildergallerie -->
    <div class="gallery-wrapper pt-4" *ngIf="accommodationOrGroup?.media?.length">
        <div class="container-header">
            <button class="btn btn-secondary mt-3 mb-3" *ngIf="!selectionStarted" (click)="this.startSelection()">
                {{ 'text.images.select.select' | translate }}
            </button>
            <button class="btn btn-secondary mt-3 mb-3" *ngIf="selectionStarted && this.selectedImagesDisplayUrl.length !== this.accommodationOrGroup.media.length" (click)="selectAll()">
                {{ 'text.images.select.all' | translate }}
            </button>
            <button class="btn btn-secondary mt-3 mb-3" *ngIf="selectionStarted && this.selectedImagesDisplayUrl.length === this.accommodationOrGroup.media.length" (click)="deselectAll()">
                {{ 'text.images.select.none' | translate}}
            </button>
        </div>
        <div class="container-images" id="gallery" #gallery>
            <ng-container *ngFor="let image of accommodationOrGroup.media; let i = index">
                <div class="element draggable" *ngIf="i > 0 || viewType === 'accommodationGroup'" id="{{ image.order }}">
                    <a class="image-link selectabale"
                       [class.selected-link] = "selectedImagesDisplayUrl.includes(image.displayUrl)"
                       [class.selection-started] = "selectionStarted"
                       [routerLink]="!selectionStarted ? '/' + accommodationOrGroup.getRouteForAccommodationOrGroup() + '/' + accommodationOrGroup.getIdForAccommodationOrGroup() + '/image/' + image.order : null"
                       (click)="selectOrUnselectImage(image.displayUrl)">
                        <div [class.selected] = "selectedImagesDisplayUrl.includes(image.displayUrl)">
                            <i *ngIf="selectedImagesDisplayUrl.includes(image.displayUrl)" class="fa fa-check"></i>
                        </div>
                        <picture>
                           <source srcset="{{ image.displayUrl | imageUrlPipe: { x: 300, y: 200, format: 'webp', media: image } }}" type="image/webp" media="(min-width: 300px)" width="300" height="200">
                           <source srcset="{{ image.displayUrl | imageUrlPipe: { x: 150, y: 100, format: 'webp', media: image } }}" type="image/webp" media="(mix-width: 10px)" width="150" height="100">
                           <img src="{{ image.displayUrl | imageUrlPipe: { x: 150, y: 100, media: image } }}" width="150" height="100" />
                        </picture>
                    </a>
                </div>
            </ng-container>
        </div>
    </div>

    <!-- (Sticky) Upload Button Mobile -->
    <div class="d-sm-none d-flex justify-content-center mobile-sticky-button-container" [ngClass]="!accommodationOrGroup?.media?.length ? 'no-pictures' : ''" *ngIf="!selectionStarted">
        <div class="input-group">
            <input type="file" class="d-none" id="upload-mobile" multiple (change)="addNewFilesFromButton($event)">
            <label class="btn btn-success mobile-sticky-button" for="upload-mobile"> {{ 'atraveo.accommodationbundle.media.upload.0' | translate }}</label>
        </div>
    </div>

    <div class="row mt-5 justify-content-center" *ngIf="!selectionStarted">
        <!-- Seite wenn noch keine Bilder vorhanden sind -->
        <!-- Drag & Drpo Upload Feld Desktop -->
        <div class="col upload-field d-none d-sm-block m-3" [ngClass]="!accommodationOrGroup?.media?.length ? 'no-pictures' : ''" (drop)="dropNewFiles($event)" (dragover)="dragNewFile($event)">
            <div class="mx-auto text-center">
                <div>{{ 'text.images.dropFiles' | translate }}</div>
                <input type="file" class="d-none" id="upload-desktop-new" multiple (change)="addNewFilesFromButton($event)">
                <label class="btn btn-success align-self-center mt-2" for="upload-desktop-new"> {{ 'atraveo.accommodationbundle.media.upload.0' | translate }}</label>
            </div>
        </div>

        <div class="col explanation-wrapper" *ngIf="!accommodationOrGroup?.media?.length">
            <app-photos-guide></app-photos-guide>
        </div>
    </div>

    <!-- Fotos löschen Button -->
    <div class="d-flex justify-content-center mobile-sticky-button-container" *ngIf="selectionStarted">
        <div class="row">
            <button class="btn btn-secondary mt-3 mb-3 mr-3" [disabled]="!selectedImagesDisplayUrl.length" (click)="openConfirmationDialog()"> {{ 'atraveo.accommodationbundle.media.remove' | translate }}</button>
            <button class="btn btn-danger mt-3 mb-3 d-block d-sm-none" (click)="this.stopSelection()"> {{ 'button.cancel' | translate }}</button>
        </div>
    </div>

    <!-- Markierung abbrechen, nur Desktop-->
    <div class="d-sm-flex justify-content-center mt-1 d-none" *ngIf="selectionStarted">
        <button class="btn btn-danger" (click)="stopSelection()"> {{ 'button.cancel' | translate }}</button>
    </div>

</div>
