import { Injectable } from '@angular/core';
import { addDays, format, startOfToday } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

@Injectable({
    providedIn: 'root',
})
export class DateService {
    constructor() {}
    static getCurrentDateString() {
        let today = startOfToday();
        today = this.correctDate(today);
        return format(today, 'yyyy-MM-dd');
    }

    static getDateFromString(dateString: string): Date {
        if (dateString) {
            const date = utcToZonedTime(new Date(dateString), 'Europe/Berlin');
            date.setHours(0, 0, 0, 0);
            return date;
        }

        return null;
    }

    static getStringFromDate(date: Date) {
        date = this.correctDate(date);
        return format(date, 'yyyy-MM-dd');
    }

    static correctDate(date: Date): Date {
        // Wenn eingehendes Datum YYYY-MM-DD ist muss es angepasst werden, da sonst ein Versatz vorhanden ist.
        if (date.getHours() !== 0) {
            date.setHours(0, 0, 0, 0);
            date = addDays(date, 1);
        }

        return date;
    }

    getFormattedDate(dateString: string, language: string, followingYears: boolean) {
        if (dateString) {
            const date = new Date(new Date(dateString).toLocaleString('en-US', { timeZone: 'Europe/Berlin' }));
            date.setHours(0, 0, 0, 0);

            if (followingYears) {
                return date.toLocaleDateString(language, { day: 'numeric', month: 'numeric' });
            } else {
                return date.toLocaleDateString(language);
            }
        }
        return '';
    }
    getDayDiff(dateString1, dateString2) {
        const date1 = new Date(dateString1);
        const date2 = new Date(dateString2);
        const diffTime = Math.abs(date2.getTime() - date1.getTime());
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    isValidDate(dateString: string): boolean {
        const date = new Date(dateString);
        return date.getTime() === date.getTime();
    }
}
