import { Component, OnInit } from '@angular/core';
import { ComponentsEnum as FindAccommodationComponents } from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-request.dto';
import { ComponentsEnum as FindBookingComponents } from 'data-structures/lib/es6/enum/components.enum';
import { ApiConnectorService, FindAccommodationResultDto } from '../../../services/api-connector/api-connector.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { ExtendedAccommodationEntity } from '../../../entities/extendedAccommodationEntity';
import { BookingConnectorService } from '../../../services/api-connector/booking-connector.service';
import { BookingEntity } from 'data-structures/lib/es6/entity/booking.entity';
import { ActivatedRoute } from '@angular/router';
import { ContactComponent } from '../content/contact/contact.component';
import { DialogService } from '../../../services/dialog.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
    bookings: BookingEntity[];
    selectedTab: string;

    constructor(
        readonly apiConnector: ApiConnectorService,
        readonly authenticationService: AuthenticationService,
        readonly bookingConnector: BookingConnectorService,
        readonly activatedRoute: ActivatedRoute,
        readonly dialogService: DialogService,
    ) {}

    async ngOnInit() {
        const promises: Promise<any>[] = [this.bookingConnector.getBookingsByOwnerNumber(this.authenticationService.currentUser, [FindBookingComponents.BookingId])];

        await Promise.all(promises).then((resultValues) => {
            const resultBookings: any = resultValues[0];
            this.bookings = resultBookings.bookings;
        });

        this.activatedRoute.fragment.subscribe((fragment) => {
            // Tab setzen wenn Anker gesetzt ist
            if (!fragment) {
                this.selectedTab = 'inbox';
                return;
            }

            this.selectedTab = fragment;
        });
    }

    contactUs() {
        this.dialogService.openDialog(ContactComponent, { dialogWidth: this.dialogService.dialogWidth.M });
    }
}
