<tr class="error-box hide">
    <td colspan="12">
        <p class="error">{{ 'atraveo.accommodationbundle.entity.periodMetaData.arrivalWeekdays.not-null' | translate }}</p>
    </td>
</tr>
<tr [formGroup]="settingsGroup">
    <td *ngIf="!isDefault; else standard" [attr.data-label]="'atraveo.accommodationbundle.price.arrivaldays.period' | translate">
        <div class="row">
            <div class="col-12 col-lg-6 form-group">
                <input
                    type="date"
                    name="from"
                    class="form-control"
                    formControlName="from"
                    min="{{ minDateFrom }}"
                    max="{{ maxDateFrom }}"
                />
            </div>
            <div class="col-12 col-lg-6 form-group">
                <input
                    type="date"
                    name="to"
                    class="form-control"
                    formControlName="to"
                    min="{{ minDateTo }}"
                    max="{{ maxDateTo }}"
                />
            </div>
        </div>
        <div>
            <app-validation-error-messages [control]="settingsControl.get('from')"></app-validation-error-messages>
            <app-validation-error-messages [control]="settingsControl.get('to')"></app-validation-error-messages>
        </div>
        <div class="ml-4">
            <input type="checkbox"
                   name="appliesToAllYears"
                   class="form-check-input"
                   formControlName="followingYears"
                   [id]="'cb-' + exceptionId"/>
            <label class="form-check-label" for="cb-{{ exceptionId }}">{{ 'atraveo.accommodationbundle.price.create.period.date-range.ignore-year' | translate }}</label>
            <a class="btn btn-link mr-2" data-toggle="popover" attr.data-content="{{ 'info.jahresuebergreifend' | translate }}">
                <i class="fas fa-info-circle"></i>
            </a>
        </div>
    </td>
    <ng-template #standard>
        <td [attr.data-label]="'atraveo.accommodationbundle.price.arrivaldays.period' | translate">
            <span class="ml-2">{{ 'atraveo.accommodationbundle.price.create.period.0' | translate }}</span>
            <a class="btn btn-link mr-2" data-toggle="popover" attr.data-content="{{ 'info.standard' | translate }}">
                <i class="fas fa-info-circle"></i>
            </a>
        </td>
    </ng-template>
    <td [attr.data-label]="'atraveo.accommodationbundle.price.arrivaldays.minimum.rental' | translate">
        <select
            class="form-control duration"
            formControlName="minDuration">
            <option *ngFor="let duration of possibleDurations;" [value]="duration">
                <ng-container *ngIf="duration == 1">{{ duration }} {{ 'atraveo.dashboardbundle.panel.heading.upcoming.arrivals.night' | translate }}</ng-container>
                <ng-container *ngIf="duration > 1">{{ duration }} {{ 'atraveo.dashboardbundle.panel.heading.upcoming.arrivals.nights' | translate }}</ng-container>
            </option>
        </select>
    </td>
    <td *ngIf="!useDailyPrices" [attr.data-label]="'atraveo.accommodationbundle.price.arrivaldays.minimum.persons' | translate">
        <select
            class="form-control duration"
            formControlName="minOccupancy">
            <option *ngFor="let occupancy of possibleOccupancies;" [value]="occupancy">
                {{ occupancy > 0 ? occupancy : '' }}
            </option>
        </select>
        <div class="arrival-days-md text-center">
            {{ 'atraveo.accommodationbundle.price.arrivaldays.arrival.days' | translate }}
            <a class="btn btn-link mr-2" data-toggle="popover" attr.data-content="{{ 'info.anreisetage' | translate }}">
                <i class="fas fa-info-circle"></i>
            </a>
        </div>
    </td>
    <td *ngFor="let day of daysShortening" [attr.data-label]="'atraveo.accommodationbundle.price.arrivalWeekdays.' + day | translate">
        <div class="arrival-departure-days"
        [class]="submitted && settingsControl?.errors?.atLeastOneSelected ? 'invalid' : ''">
            <input
                type="checkbox"
                class="form-control"
                [formControlName]="day"
            />
        </div>
    </td>

    <td *ngIf="!isDefault; else noTrashIcon">
        <a class="btn btn-link" data-toggle="popover-advanced-setting" (click)="toggle()">
            <i class="fas" [class]="((settingsControl.enabled) ? 'fa-trash-alt' : 'fa-history')"></i>
        </a>
    </td>
    <ng-template #noTrashIcon><td></td></ng-template>
</tr>
