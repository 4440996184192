import { Component, Input, OnInit } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../entities/extendedAccommodationEntity';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';

@Component({
    selector: 'app-state',
    templateUrl: './state.component.html',
    styleUrls: ['./state.component.scss'],
})
export class StateComponent implements OnInit {
    @Input() accommodation: ExtendedAccommodationEntity;
    currentState: string;

    constructor(readonly authService: AuthenticationService) {}

    ngOnInit() {
        this.currentState = this.getState();
    }

    ngOnChanges() {
        this.currentState = this.getState();
    }

    getState(): string {
        if (this.accommodation?.state?.deleted) {
            return 'atraveo.accommodationbundle.check.icontext.deleted';
        }

        if (this.accommodation?.state?.paused || this.authService.currentUser?.state?.takeAllAccommodationsOffline || this.accommodation?.state?.deactivatedByOwnerService) {
            return 'atraveo.accommodationbundle.check.icontext.paused';
        }

        if (this.accommodation?.state?.active) {
            return 'atraveo.accommodationbundle.check.icontext.online';
        }

        if (!this.accommodation.state?.active && this.accommodation.state?.valid && !this.accommodation.state?.qualified && this.accommodation.state?.publishingRequested) {
            return 'atraveo.accommodationbundle.check.icontext.glasses';
        }

        return 'atraveo.accommodationbundle.check.icontext.offline';
    }
}
