<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title"
            id="create-booking-label">{{ 'atraveo.bookingbundle.button.new' | translate }}</h5>
        <button class="close" (click)="closeDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="container-fluid" *ngIf="booking.priceDetail">
            <form autocomplete="off">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group row">
                            <div class="col-6">
                                <strong><label for="search" class="required">{{ 'atraveo.bookingbundle.accommodation.0' | translate }}</label></strong>
                            </div>
                            <div class="col-6">
                                <ng-container *ngIf="fromCalender">{{ booking.accommodationId }}</ng-container>
                                <ng-container *ngIf="!fromCalender">
                                    <app-accommodation-ajax-search
                                        (onSearch)="onSearch($event)"
                                        hideLabel="true"
                                        hideIcons="true"
                                        classes="form-control d-inline typeahead booking-search"
                                        fieldRequired="true"
                                        htmlId="createBookingSearch-{{id}}"
                                        [selectedAccommodation]="booking.accommodationId">
                                    </app-accommodation-ajax-search>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row" *ngIf="wrongAccommodationId">
                            <div class="col">
                                <p class="error">
                                    {{ 'please.enter.vallid.objectnumber' | translate }}
                                </p>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-6">
                                <strong><label for="bookingdate" class="required">{{ 'atraveo.bookingbundle.booking.date' | translate }}</label></strong>
                            </div>
                            <div class="col-6">
                                <input type="date" [ngModel]="booking.bookingDates.bookingDate | date:'yyyy-MM-dd'"
                                       (ngModelChange)="booking.bookingDates.bookingDate = $event"
                                       class="form-control" required="required" id="bookingdate" name="booking-date">
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-6">
                                <strong><label for="arrivaldate" class="required">{{ 'atraveo.bookingbundle.arrival' | translate }}</label></strong>
                            </div>
                            <div class="col-6">
                                        <span
                                            *ngIf="fromCalender">{{ booking.arrivalDate | stringToDate | date: 'shortDate' : undefined: this.translationService.currentLang }}</span>
                                <input *ngIf="!fromCalender" type="date" [(ngModel)]="booking.arrivalDate" required="required"
                                       class="form-control" id="arrivaldate" name="arrival-date">
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-6">
                                <strong><label for="departuredate" class="required">{{ 'atraveo.bookingbundle.departure' | translate }}</label></strong>
                            </div>
                            <div class="col-6">
                                        <span
                                            *ngIf="fromCalender">{{ booking.departureDate | stringToDate | date: 'shortDate' : undefined: this.translationService.currentLang }}</span>
                                <input *ngIf="!fromCalender" type="date" [(ngModel)]="booking.departureDate"
                                       class="form-control" required="required" id="departuredate" name="departure-date" min="{{ booking.arrivalDate }}">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                {{ 'text.mandatoryfield' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="col-6" *ngIf="booking.priceDetail.price && booking.priceDetail.additionalPrice">
                        <div class="form-group row">
                            <div class="col-6">
                                {{ 'atraveo.bookingbundle.booking.number' | translate }}
                            </div>
                            <div class="col-6">
                                <input type="text" [(ngModel)]="booking.bookingNumber" class="form-control" name="booking-number">
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-6">
                                {{ 'atraveo.bookingbundle.price' | translate }}
                            </div>
                            <div class="col-6">
                                <div class="input-group">
                                    <input type="text" [(ngModel)]="booking.priceDetail.price.amount"
                                           class="form-control" name="amount">
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{ ownerCurrency }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-6">
                                {{ 'atraveo.bookingbundle.additionalPrice' | translate }}
                            </div>
                            <div class="col-6">
                                <div class="input-group">
                                    <input type="text" [(ngModel)]="booking.priceDetail.additionalPrice.amount"
                                           class="form-control" name="additional-price">
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{ ownerCurrency }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row location-price">
                            <div class="col-6">
                                {{ 'atraveo.bookingbundle.locationPrice' | translate }}
                            </div>
                            <div class="col-3 pr-0">
                                <input type="text" [(ngModel)]="booking.priceDetail.locationPrice.amount"
                                       class="form-control" name="location-price">
                            </div>
                            <div class="col-3 ml-0 pl-0">
                                <select class="select-picker form-control" name="currency"
                                        [(ngModel)]="booking.priceDetail.locationPrice.currency">
                                    <option *ngFor="let currency of currencies">{{ currency }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-6">
                                {{ 'atraveo.bookingbundle.status.payment' | translate }}
                            </div>
                            <div class="col-6">
                                <select [(ngModel)]="booking.paymentStatus" name="payment-status"
                                        [attr.data-select-all-text]= "'select.all' | translate"
                                        [attr.data-deselect-all-text]= "'select.none' | translate"
                                        class="form-control custom-select selectpicker">
                                    <option
                                        *ngFor="let status of getPaymentStatus() | keyvalue" value="{{ status.value }}">{{ 'atraveo.bookingbundle.paymentstatus.' + status.value | translate }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <hr>

        <div class="container-fluid">
            <div class="row">
                <div class="col-6">

                    <div class="form-group row">
                        <div class="col-6">
                            <strong>{{ 'atraveo.bookingbundle.fellowCustomerCount' | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <input type="number" [(ngModel)]="booking.fellowCustomerCount"
                                   class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-6">
                            <strong>{{ 'atraveo.bookingbundle.customer.forename' | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <input type="text" [(ngModel)]="booking.customer.forename" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-6">
                            <strong>{{ 'atraveo.bookingbundle.customer.surname' | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <input type="text" [(ngModel)]="booking.customer.surname" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-6">
                            <strong>{{ 'atraveo.bookingbundle.customer.phone' | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <input type="text" [(ngModel)]="booking.customer.numberPhone" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-6">
                            <strong>{{ 'atraveo.bookingbundle.customer.mobile' | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <input type="text" [(ngModel)]="booking.customer.numberMobile" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-6">
                            <strong>{{ 'atraveo.bookingbundle.customer.email' | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <input type="text" [(ngModel)]="booking.customer.email" class="form-control">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <div class="form-group row">
                                <div class="col-6">
                                    <strong>{{ 'atraveo.bookingbundle.customer.address.0' | translate }}</strong>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-6">
                            {{ 'atraveo.bookingbundle.customer.street' | translate }}
                        </div>
                        <div class="col-6">
                            <input type="text" [(ngModel)]="booking.customer.street" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-6">
                            {{ 'atraveo.bookingbundle.customer.streetNo' | translate }}
                        </div>
                        <div class="col-6">
                            <input type="text" [(ngModel)]="booking.customer.streetNumber" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-6">
                            {{ 'atraveo.bookingbundle.customer.postcode' | translate }}
                        </div>
                        <div class="col-6">
                            <input type="text" [(ngModel)]="booking.customer.postcode" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-6">
                            {{ 'atraveo.bookingbundle.customer.city' | translate }}
                        </div>
                        <div class="col-6">
                            <input type="text" [(ngModel)]="booking.customer.city" class="form-control">
                        </div>
                    </div>


                    <div class="form-group row">
                        <div class="col-6">
                            {{ 'atraveo.bookingbundle.customer.country' | translate }}
                        </div>
                        <div class="col-6">
                            <app-country-select [(ngModel)]="booking.customer.countryISOCode"
                                                [ngModelOptions]="{standalone: true}"
                                                [isOptional]="true"
                                                classKey="form-control custom-select"></app-country-select>
                        </div>
                    </div>

                </div>
                <div class="col-6">

                    <div class="form-group row">
                        <div class="col-6">
                            <strong>{{ 'atraveo.bookingbundle.customer.birthday' | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <input type="date" [(ngModel)]="booking.customer.birthday" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-6">
                            <strong>{{ 'atraveo.bookingbundle.customer.language' | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <select [(ngModel)]="booking.customer.language" class="form-control">
                                <option *ngFor="let language of languages" value="{{ language }}">
                                    {{ 'intl.language.name.' + language | translate }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-6">
                            <strong>{{ 'atraveo.bookingbundle.customer.pets' | translate }}</strong>
                        </div>
                        <div class="col-6">
                            <input type="text" [(ngModel)]="booking.customer.pets" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-6">
                            <strong>{{ 'atraveo.bookingbundle.create.fellowCustomer.title' | translate }}</strong>
                        </div>
                    </div>

                    <div *ngFor="let fellow of booking.fellowCustomers; let i = index">
                        <div class="row mt-2">
                            <div class="col-6">
                                <strong>{{ 'atraveo.bookingbundle.fellowCustomer.name' | translate }}</strong>
                            </div>
                            <div class="col-5">
                                <input type="text" [(ngModel)]="fellow.forename" class="form-control">
                            </div>

                            <div class="col-1">
                                <a (click)="deleteFellowTraveler(i)"><i class="fa fa-trash-alt fa-lg"></i></a>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6">
                                <strong>{{ 'atraveo.bookingbundle.fellowCustomer.birthday' | translate }}</strong>
                            </div>
                            <div class="col-6">
                                <input type="date" [(ngModel)]="fellow.birthday" class="form-control">
                            </div>
                        </div>
                    </div>

                    <button class="btn btn-secondary"
                            (click)="addFellowTravellers()">{{ 'atraveo.bookingbundle.button.add.fellowCustomer' | translate }}
                    </button>
                </div>

            </div>
        </div>

        <div class="modal-footer">
            <button type="button" (click)="saveBooking()" class="btn btn-success">{{ 'button.save' | translate }}</button>
        </div>
    </div>
</div>
