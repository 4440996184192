import { Component, EventEmitter, forwardRef, Input, NgZone, OnInit, Output } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../entities/extendedAccommodationEntity';
import { ApiConnectorService } from '../../../services/api-connector/api-connector.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { ComponentsEnum as FindAccommodationComponents, FindAccommodationRequestDto } from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-request.dto';
import { FindAccommodationStateDto } from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-state.dto';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { difference } from 'lodash';
import { FindAccommodationFilterDto } from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-filter.dto';
declare const $: any;

@Component({
    selector: 'app-accommodation-select',
    templateUrl: './accommodation-select.component.html',
    styleUrls: ['./accommodation-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AccommodationSelectComponent),
            multi: true,
        },
    ],
})
export class AccommodationSelectComponent implements OnInit, ControlValueAccessor {
    accommodations: ExtendedAccommodationEntity[];
    selectedAccommodationIds: number[] = [];
    @Input() idKey: string = '';
    @Input() classKey: string = '';
    @Input() includeAllObjects: boolean = true;
    @Input() noneSelectedDisplayAll = false; // Wenn nichts ausgewählt ist, soll "alle Objekte" angezeigt werden
    @Input() showAllIds = false;
    @Output() onFinish: EventEmitter<any> = new EventEmitter<any>();
    assignedAccommodationIdsToBankAccounts: number[] = [];

    constructor(readonly apiConnector: ApiConnectorService, readonly authService: AuthenticationService, readonly ngZone: NgZone) {}

    onChange: any = () => {};
    onTouched: any = () => {};

    async writeValue(obj: any): Promise<void> {
        this.selectedAccommodationIds = obj;

        const missingAccommodationIdsForSelection = difference(this.selectedAccommodationIds ?? [], this.accommodations?.map((accommodation) => accommodation.accommodationId) ?? [])?.filter(
            (missingId: number) => missingId !== null,
        );

        if (missingAccommodationIdsForSelection.length) {
            const result = await this.loadAccommodations(missingAccommodationIdsForSelection);

            if (result?.accommodations?.length) {
                for (const resultAccommodation of result.accommodations) {
                    if (!this.accommodations?.some((accommodation) => accommodation.accommodationId === resultAccommodation.accommodationId)) {
                        if (!this.accommodations) {
                            this.accommodations = [];
                        }
                        this.accommodations.push(resultAccommodation);
                    }
                }
            }
        }
    }

    get value() {
        return this.selectedAccommodationIds;
    }

    set value(v) {
        this.selectedAccommodationIds = v;
        this.onChange(this.selectedAccommodationIds);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    private loadAccommodations(accommodationIds?: number[]) {
        const components: FindAccommodationComponents[] = [FindAccommodationComponents.AccommodationId, FindAccommodationComponents.Main];
        const state: FindAccommodationStateDto = new FindAccommodationStateDto();
        state.deleted = false;

        if (accommodationIds?.length) {
            const findAccommodationRequest = new FindAccommodationRequestDto();
            findAccommodationRequest.ownerNumber = this.authService.currentUser.ownerNumber;
            findAccommodationRequest.accommodationId = accommodationIds;
            findAccommodationRequest.components = components;
            findAccommodationRequest.filter = new FindAccommodationFilterDto();
            findAccommodationRequest.filter.state = state;

            return this.apiConnector.findAccommodations(findAccommodationRequest);
        } else {
            let objectsTake = 1111;

            switch (this.authService.currentUser.ownerNumber) {
                case 5696:
                    objectsTake = 2000;
                    break;
                case 64070:
                    objectsTake = 4000;
                    break;
            }

            return this.apiConnector.getAccommodationsByOwnerNumber(this.authService.currentUser, 0, objectsTake, state, components);
        }
    }

    async ngOnInit(): Promise<void> {
        if (!this.accommodations?.length) {
            // Es ist irgendwie unmöglich den Haken bei "Alle Objekte" rein zu machen ohne das. Selbst wenn man [selected] = true macht, geht es nicht
            if (this.includeAllObjects && this.selectedAccommodationIds.length === 0) {
                this.selectedAccommodationIds = [null];
            }

            const result = await this.loadAccommodations();

            if (result) {
                this.accommodations = result.accommodations;
                // tslint:disable-next-line:forin
                for (const bankAccount in this.authService.currentUser?.payment?.bankAccounts) {
                    this.authService.currentUser.payment.bankAccounts[bankAccount].accommodationIds?.map((accommodationId) => this.assignedAccommodationIdsToBankAccounts.push(accommodationId));
                }
            }

            this.ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                    const $selectPickers = $('.selectpicker') as any;
                    $selectPickers.selectpicker('render');
                    $selectPickers.selectpicker('refresh');
                }, 100);
            });
        }

        $('body').on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
            // TODO: Bessere Möglichkeiten finde das der Selector oben greift falls man mehere Selectboxen auf einer Seite nutzen will und man die ID nicht mehr prüfen muss
            if (e.target.id === this.idKey) {
                if (this.selectedAccommodationIds?.length > 1) {
                    if (clickedIndex === 0) {
                        this.selectedAccommodationIds = [null];
                    } else if (clickedIndex > 0) {
                        this.selectedAccommodationIds = this.selectedAccommodationIds.filter((element) => element !== null);
                    }
                }

                this.value = this.selectedAccommodationIds;
                this.writeValue(this.selectedAccommodationIds);
                this.onFinish.emit(this.selectedAccommodationIds);

                this.ngZone.runOutsideAngular(() => {
                    setTimeout(() => {
                        const $selectPickers = $('.selectpicker') as any;
                        $selectPickers.selectpicker('render');
                        $selectPickers.selectpicker('refresh');
                    }, 10);
                });
            }
        });
    }
}
