import { Injectable } from '@angular/core';
import { StateDto } from 'data-structures/lib/es6/dto/accommodation';
import { ApiConnectorService } from '../api-connector/api-connector.service';
import { ExtendedAccommodationEntity } from '../../entities/extendedAccommodationEntity';

@Injectable({
  providedIn: 'root'
})

export class AccommodationQualifyService {

    constructor(readonly apiConnectorService: ApiConnectorService,
                ) {}

    async qualifyAccommodation(accommodation: ExtendedAccommodationEntity) {
        await this.apiConnectorService.qualifyAccommodation(accommodation.accommodationId).then( async () => {
            if (!accommodation.state) {
                accommodation.state = new StateDto();
            }
            accommodation.state.qualified = true;
        });
    }
}
