import { ExtendedPriceRule, PriceTypes } from './PriceRule/extended-price-rule';
import { addDays, startOfToday } from 'date-fns';
import { AccommodationEntity } from 'data-structures/lib/es6/entity/accommodation/accommodation.entity';
import { MainDto, MediaDto, PositionDto, StateDto } from 'data-structures/lib/es6/dto/accommodation';
import { ExtendedVacancy } from './extendedVacancy';
import { ExtendedPriceInformation } from './PriceInformation/extended-price-information';
import { Exclude, Type } from 'class-transformer';
import { ExtendedAdditionalCost } from './extended-addition-cost';
import { MetaDto } from 'data-structures/dto/accommodation';
import { SortEnum } from 'data-structures/lib/es6/dto/rating/owner/find-rating-sort-dto';
import { TravelPeriodDto } from 'data-structures/lib/es6/dto/accommodation/price-rule/travel-period.dto';
import { TravelPeriodService } from '../services/travel-period/travel-period.service';
import { AddressDto } from 'data-structures/lib/es6/dto/accommodation/position/address.dto';
import { LocationDto } from 'data-structures/lib/es6/dto/accommodation/position/location.dto';
import { DateService } from '../services/date/date.service';
import { ChannelTypeEnum } from 'data-structures/lib/es6/enum/channel-type.enum';
import { ExtendedDailyPriceRule } from './PriceRule/extended-daily-price-rule';

let dayHasPriceCache = [];
export class ExtendedAccommodationEntity extends AccommodationEntity {
    @Type(() => ExtendedAdditionalCost)
    additionalCosts: ExtendedAdditionalCost[];
    @Type(() => ExtendedPriceInformation)
    priceInformation: ExtendedPriceInformation = new ExtendedPriceInformation();
    @Type(() => ExtendedPriceRule)
    priceRules: ExtendedPriceRule[];
    @Type(() => ExtendedDailyPriceRule)
    dailyPriceRules: ExtendedDailyPriceRule[];
    @Type(() => ExtendedVacancy)
    vacancy: ExtendedVacancy = new ExtendedVacancy();
    @Exclude()
    updated: Date;
    @Exclude({ toPlainOnly: true })
    meta: MetaDto;
    @Exclude({ toPlainOnly: true })
    isGroup: boolean = false;
    checkResults: any[];
    destinationPool: number;

    // @ts-ignore
    getFeatureByKey(key: string): any[] {
        const foundFeatures = [];

        if (!this.features?.length) {
            return foundFeatures;
        }

        for (const feature of this.features) {
            if (feature.featureId.toUpperCase() === key.toUpperCase()) {
                foundFeatures.push(feature);
            }
        }

        return foundFeatures;
    }

    getFirstFeatureValue(key: string): any {
        const features = this.getFeatureByKey(key);
        if (features.length) {
            return features[0].value;
        }
    }

    createDayHasPriceCache(): void {
        dayHasPriceCache = [];

        if (!this.vacancy?.priceString) {
            return;
        }

        let nextDate = DateService.getDateFromString(this.vacancy.getStartDate());

        for (const dayPriceInfo of [...this.vacancy.priceString]) {
            if (dayPriceInfo === 'Y') {
                dayHasPriceCache.push(nextDate.toISOString());
            }
            nextDate = addDays(nextDate, 1);
        }
    }

    dayHasPrice(date: Date): boolean {
        if (!dayHasPriceCache.length) {
            this.createDayHasPriceCache();
        }

        return dayHasPriceCache.includes(date.toISOString());
    }

    orderAdditionalCostsByObligation() {
        if (this.additionalCosts && this.additionalCosts.length) {
            this.additionalCosts.sort((a, b) => {
                return a.obligation - b.obligation;
            });
        }
    }

    sortMedia() {
        if (!this.media || !this.media.length) {
            return;
        }

        this.media = this.media.sort((a: MediaDto, b: MediaDto): number => {
            return a.order - b.order;
        });
    }

    getMaximumNumberOfPeople() {
        return this.main?.maxPersons ?? 20;
    }

    hasPriceType(priceType: PriceTypes): boolean {
        if (this.priceRules?.length) {
            return this.priceRules.some((priceRule) => {
                return priceRule.priceType === priceType;
            });
        }
    }

    getSortPriceFromPriceRule(priceRule: ExtendedPriceRule): number {
        return priceRule.prices[0]?.price ?? 0;
    }

    getSortDateFromPriceRule(priceRule: ExtendedPriceRule): Date {
        if (priceRule.travelPeriod?.from) {
            return DateService.getDateFromString(priceRule.travelPeriod?.from);
        }
    }

    getPriceType(priceType: PriceTypes, sortPrice: SortEnum = null, sortDate: SortEnum = null): ExtendedPriceRule[] | undefined {
        let priceRules: ExtendedPriceRule[] = [];
        const returnValue: ExtendedPriceRule[] = [];
        const selectedPriceRules: any = [];

        if (this.priceRules?.length) {
            priceRules = this.priceRules.filter((priceRule) => priceRule.priceType === priceType);

            for (const priceRule of priceRules) {
                if (priceRule.travelPeriod.followingYears) {
                    const dateFrom = new Date(DateService.getDateFromString(priceRule.travelPeriod.from).setFullYear(startOfToday().getFullYear()));
                    let dateTo = new Date(DateService.getDateFromString(priceRule.travelPeriod.to).setFullYear(startOfToday().getFullYear()));

                    if (dateFrom > dateTo) {
                        dateTo = new Date(dateTo.setFullYear(dateTo.getFullYear() + 1));
                    }

                    selectedPriceRules.push({ id: priceRule.id, dateFrom: priceRule.travelPeriod.from, dateTo: priceRule.travelPeriod.to });

                    priceRule.travelPeriod.from = new Date(dateFrom).toDateString();
                    priceRule.travelPeriod.to = new Date(dateTo).toDateString();
                }

                returnValue.push(priceRule);
            }
        }

        if (returnValue.length && (sortPrice || sortDate)) {
            returnValue.sort((a, b) => {
                let priceSort;
                let dateSort;

                if (sortPrice && sortPrice === SortEnum.Asc) {
                    priceSort = this.getSortPriceFromPriceRule(a) - this.getSortPriceFromPriceRule(b);
                }

                if (sortPrice && sortPrice === SortEnum.Desc) {
                    priceSort = this.getSortPriceFromPriceRule(b) - this.getSortPriceFromPriceRule(a);
                }

                if (sortDate && sortDate === SortEnum.Asc) {
                    dateSort = this.getSortDateFromPriceRule(a).getTime() - this.getSortDateFromPriceRule(b).getTime();
                }

                if (sortDate && sortDate === SortEnum.Desc) {
                    dateSort = this.getSortDateFromPriceRule(b).getTime() - this.getSortDateFromPriceRule(a).getTime();
                }

                return priceSort || dateSort;
            });
        }

        returnValue.forEach((priceRule) => {
            const index = selectedPriceRules.findIndex((price) => price.id === priceRule.id);

            if (index !== -1) {
                priceRule.travelPeriod.from = selectedPriceRules[index].dateFrom;
                priceRule.travelPeriod.to = selectedPriceRules[index].dateTo;
            }
        });

        return returnValue;
    }

    /* Es gibt auf der Preise-Seite eine Tabelle mit Spalten für Preis für 1,2,x Personen. Diese Methode gibt die Zahlen für die Überschrift */
    getDifferentMaxPersonsForPriceType(priceType: PriceTypes): number[] {
        const maxPersons: Set<number> = new Set();
        const rules = this.getPriceType(priceType);

        if (rules) {
            for (const rule of rules) {
                if (rule.prices.length) {
                    for (const price of rule.prices) {
                        if (price.maxPersons) {
                            maxPersons.add(price.maxPersons);
                        }
                    }
                }
            }
        }

        const returnValue = Array.from(maxPersons);
        return returnValue.sort();
    }

    hasPerPersonPriceForPriceType(priceType: PriceTypes): boolean {
        const rules = this.getPriceType(priceType);
        if (rules) {
            for (const rule of rules) {
                if (rule.prices.length) {
                    for (const price of rule.prices) {
                        if (price.pricePerPerson) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    hasPricePerAdditionalPerson(priceType: PriceTypes): boolean {
        const rules = this.getPriceType(priceType);
        if (rules) {
            for (const rule of rules) {
                if (rule.prices.length) {
                    for (const price of rule.prices) {
                        if (price.pricePerAdditionalPerson) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    addIdsToPriceRules(priceRules = this.priceRules) {
        let i = 0;
        if (priceRules?.length) {
            for (const rule of priceRules) {
                rule.id = i;
                i++;
            }
        }
    }

    addIdsToDailyPriceRules(dailyPriceRules: ExtendedDailyPriceRule[] = this.dailyPriceRules) {
        let i = 1;
        if (dailyPriceRules?.length) {
            for (const rule of dailyPriceRules) {
                rule.id = i;
                i++;
            }
        }
    }

    // Wenn es einen anderen Preis gibt der in diesen Zeitraum fällt, wird die TravelPeriod dieses Preises zurück gegeben
    getPriceOverlap(inputPriceRule: ExtendedPriceRule): TravelPeriodDto {
        const travelPeriodService = new TravelPeriodService();

        if (!this.priceRules?.length || !inputPriceRule.travelPeriod?.from || !inputPriceRule.travelPeriod?.to) {
            return undefined;
        }

        for (const priceRule of this.priceRules) {
            // Es dürfen durchaus mehrere Preise pro Zeitraum gesetzt sein, so lange sie andere Preistypen haben
            if (priceRule.id !== inputPriceRule.id && priceRule.priceType === inputPriceRule.priceType) {
                const result = travelPeriodService.getPeriodOverlap(inputPriceRule.travelPeriod, priceRule.travelPeriod);
                if (result) {
                    return result;
                }
            }
        }
    }

    // Wenn es einen anderen Preis gibt der in diesen Zeitraum fällt, wird die TravelPeriod dieses Preises zurück gegeben
    getDailyPriceOverlap(inputDailyPriceRule: ExtendedDailyPriceRule): TravelPeriodDto {
        const travelPeriodService = new TravelPeriodService();

        if (!this.dailyPriceRules?.length || !inputDailyPriceRule.travelPeriod?.from || !inputDailyPriceRule.travelPeriod?.to) {
            return undefined;
        }

        for (const dailyPriceRule of this.dailyPriceRules) {
            // Es dürfen durchaus mehrere Preise pro Zeitraum gesetzt sein, so lange sie andere Preistypen haben
            if (dailyPriceRule.id !== inputDailyPriceRule.id) {
                const result = travelPeriodService.getPeriodOverlap(inputDailyPriceRule.travelPeriod, dailyPriceRule.travelPeriod);
                if (result) {
                    return result;
                }
            }
        }
    }

    addRequiredFields() {
        if (!this.main) {
            this.main = new MainDto();
        }

        if (!this.position) {
            this.position = new PositionDto();
        }

        if (!this.position.address) {
            this.position.address = new AddressDto();
        }

        if (!this.position.location) {
            this.position.location = new LocationDto();
        }

        if (!this.state) {
            this.state = new StateDto();
        }
    }

    checkChanges(): boolean {
        const accommodationElements = [this.position, this.vacancy];

        for (const accommodationElement of accommodationElements) {
            for (const [key, value] of Object.entries(accommodationElement)) {
                if (key === 'verified') {
                    continue;
                }
                if (value && key !== 'offsetDate') {
                    if (typeof value !== 'object') {
                        return true;
                    } else {
                        for (const subValue of Object.values(value)) {
                            if (subValue) {
                                return true;
                            }
                        }
                    }
                }
            }
        }

        return !!(this.features || this.descriptions);
    }

    getEdomizilId(): string {
        if (this.channels?.length) {
            for (const channel of this.channels) {
                if (channel.type === ChannelTypeEnum.EDOMIZIL) {
                    return channel.internalId;
                }
            }
        }
    }

    getShortenedName() {
        if (!this.accommodationId) {
            return '';
        }

        let name: string = this.accommodationId.toString();
        if (this.main?.name) {
            name += ' (' + this.main.name.substring(0, 20) + ')';
        }

        return name;
    }

    getIdForAccommodationOrGroup() {
        return this.accommodationId;
    }

    getRouteForAccommodationOrGroup() {
        return 'accommodation';
    }

    isNew() {
        return !this.accommodationId;
    }

    modelIsAllowed(user): boolean {
        if (user.userIsFromCh()) {
            return true;
        }
    }
}
