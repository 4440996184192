<form #priceTableForm>
    <p *ngIf="isDateRangeToLong" class="error">{{ 'atraveo.accommodationbundle.price.accommodation-daterange-too-long' | translate }}</p>
    <h3>{{ 'atraveo.accommodationbundle.price.create.step1' | translate }}</h3><br>
    <div class="col" *ngIf="price">
        <div class="row form-group fixed-period-row">
            <div class="col-sm-3 input-radio">
                <label>
                    <input type="radio" name="period-{{ price.priceType }}" value="dates" [(ngModel)]="dateType" id="input-duration" [checked]="dateType === 'dates'">
                    {{ 'atraveo.accommodationbundle.price.create.rate-per-period.5' | translate }}
                </label>
            </div>

            <div class="col-sm-9 duration-box" *ngIf="dateType === 'dates'">
                <p class="col-sm-2 input-label mandatoryIndicator">{{ 'atraveo.accommodationbundle.price.create.period.field.dateFrom' | translate }}</p>
                <div class="col-sm-4">
                    <input class="form-control travelPeriodInput" type="date" name="from" min="{{ minDate }}" max="{{ maxDate }}"
                           [(ngModel)]="price.travelPeriod.from"
                           (blur)="checkDates();"
                           [attr.required]="(submitted && price.travelPeriod.from === null && !price.travelPeriod.seasonId) ||
                                            (!editPriceModal && !price.travelPeriod.from && !price.travelPeriod.seasonId) ? 'required' : null">

                <p class="error" *ngIf="overlapFromDate && overlapToDate">
                    {{ 'atraveo.accommodationbundle.price.accommodation-daterange-overlap-violation.0' | translate: {dateRange: (overlapFromDate | date) + ' - ' + (overlapToDate | date)} }}
                </p>

                    <p *ngIf="submitted && !price.travelPeriod.from" class="error">
                        {{ 'atraveo.invalid-type-date' | translate }}
                    </p>

                    <p *ngIf="invalidYearFrom" class="error">
                        {{ 'atraveo.invalid-year' | translate }}
                    </p>
                </div>
                <p class="col-sm-2 input-label mandatoryIndicator">{{ 'atraveo.accommodationbundle.price.create.period.field.dateTo' | translate }}</p>
                <div class="col-sm-4">
                    <input class="form-control travelPeriodInput" type="date" name="to" min="{{ minDate }}" max="{{ maxDate }}"
                           [(ngModel)]="price.travelPeriod.to"
                           (blur)="checkDates();"
                           [attr.required]="(submitted && price.travelPeriod.to === null && !price.travelPeriod.seasonId) ||
                                            (!editPriceModal && !price.travelPeriod.to && !price.travelPeriod.seasonId) ? 'required' : null">

                <p class="error" *ngIf="overlapFromDate && overlapToDate">
                    {{ 'atraveo.accommodationbundle.price.accommodation-daterange-overlap-violation.0' | translate: {dateRange: (overlapFromDate | date) + ' - ' + (overlapToDate | date)} }}
                </p>

                    <p *ngIf="submitted && !price.travelPeriod.to" class="error">
                        {{ 'atraveo.invalid-type-date' | translate }}
                    </p>

                    <p *ngIf="invalidYearTo" class="error">
                        {{ 'atraveo.invalid-year' | translate }}
                    </p>
                </div>
                <div class="col-sm-3"></div>
                <div class="all-years mt-2 col-sm-9">
                    <label>
                        <input type="checkbox" name="all-years" [(ngModel)]="price.travelPeriod.followingYears" (change)="checkDates();" id="input-following-year">
                        {{ 'atraveo.accommodationbundle.price.create.period.date-range.ignore-year' | translate}}
                    </label>
                    <app-tooltip [additionalClassList]="'mr-2'" [text]="'info.jahresuebergreifend' | translate"></app-tooltip>
                </div>
            </div>
        </div>
    </div>

<div class="row mt-2" *ngIf="price">
    <div class="col">
        <label>
            <input type="radio"
                   name="period-{{ price.priceType }}"
                   value="season" [(ngModel)]="dateType" [checked]="dateType === 'season'"
                   id="input-season">
            {{ 'atraveo.accommodationbundle.price.create.period.1' | translate }}
        </label>
        <app-tooltip [additionalClassList]="'mr-2'" [text]="'info.saisonpreis' | translate"></app-tooltip>
    </div>
    <div class="col season-box" *ngIf="seasons && dateType === 'season'" id="">
        <select class="form-control custom-select season-select-box" [(ngModel)]="seasonValue" name="seasonValue" (change)="seasonValueChange()"
                [attr.required]="(dateType === 'season') ? 'required' : null">
            <ng-container *ngFor="let season of seasons.seasons">
                <option *ngIf="season.seasonId > 0" value="{{ season.seasonId }}">
                    {{ season.name[this.translationService.currentLang]}}
                    <ng-container *ngIf="season.travelPeriods[0]?.from && season.travelPeriods[0]?.to">
                        ({{ season.travelPeriods[0].from | stringToDate | date}} - {{ season.travelPeriods[0].to | stringToDate | date }})
                    </ng-container>
                </option>
            </ng-container>
        </select>
        <p class="error" *ngIf="overlapFromDate && overlapToDate">
            {{ 'atraveo.accommodationbundle.price.accommodation-daterange-overlap-violation.0' | translate: {dateRange: (overlapFromDate | date) + ' - ' + (overlapToDate | date)} }}
        </p>
    </div>
</div>

    <h3 class="mt-3 mandatoryIndicator">{{ 'atraveo.accommodationbundle.price.create.step2' | translate }}</h3><br>
    <div class="row">
        <div class="col-sm-3 input-group" *ngIf="price?.prices?.length">
            <input type="number" step="0.01" min="0" class="form-control" name="price" [(ngModel)]="price.prices[0].price"
                   [attr.required]="(submitted && price.prices[0].price === null) || (!editPriceModal && !price.prices[0].price) ? 'required' : null">
            <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2" [innerHTML]="ownerCurrency"></span>
            </div>
            <p *ngIf="submitted && !price.prices[0].price" class="error">{{ 'form.validation' | translate }}</p>
            <p *ngIf="submitted && price?.prices[0]?.price < 0" class="error">{{ 'validation.money.invalid' | translate }}</p>
        </div>
        <div class="col-sm-1 input-label">
            {{ 'atraveo.accommodationbundle.price.create.rate-per-period-prefix' | translate }}
        </div>
        <div class="col-sm-3" *ngIf="price">
            <select class="form-control custom-select" name="priceType" [(ngModel)]="price.priceType" (change)="checkDates();">
                <option value="ta">{{ 'atraveo.accommodationbundle.price.create.rate-per-period.0' | translate }}</option>
                <option value="wo">{{ 'atraveo.accommodationbundle.price.create.rate-per-period.1' | translate }}</option>
                <option value="mw">{{ 'atraveo.accommodationbundle.price.create.rate-per-period.2' | translate }}</option>
                <option value="kw">{{ 'atraveo.accommodationbundle.price.create.rate-per-period.3' | translate }}</option>
                <option value="we">{{ 'atraveo.accommodationbundle.price.create.rate-per-period.4' | translate }}</option>
            </select>
        </div>
    </div>

    <h3 class="mt-3">{{ 'atraveo.accommodationbundle.price.create.step3' | translate }}</h3><br>
    <div *ngIf="price?.prices[0]">
        <label>
            <input type="radio" value="booking" [checked]="priceType === 'booking'" name="pricePerBooking" [(ngModel)]="priceType" id="input-price-per-booking">

            {{ 'atraveo.accommodationbundle.price.perBookingOrPersons.booking' | translate }}
        </label> <br>
        <div class="row">
            <div class="col">
                <label>
                    <input type="radio" value="person" [checked]="priceType === 'person'" name="personDependent" [(ngModel)]="priceType" id="input-person-dependent">
                    {{ 'atraveo.accommodationbundle.price.perBookingOrPersons.person' | translate }}
                </label>
            </div>
            <div class="col-auto" *ngIf="priceType === 'person'">
                <label>
                    <input type="checkbox" *ngIf="price?.prices[0]" name="pricePerPerson" [(ngModel)]="price.prices[0].pricePerPerson" id="input-price-per-person">
                    {{ 'atraveo.accommodationbundle.price.create.perPerson.0' | translate }}
                </label><br>
                <span *ngIf="priceType === 'person'">
                    <label>
                        <input type="checkbox" *ngIf="price.prices[0]" name="inputUpToPerson" [(ngModel)]="validPersons" (change)="changeValidPersons($event)" id="input-upto-persons">
                        {{ 'atraveo.accommodationbundle.price.create.upToPersons.0' | translate }}
                    </label><br>
                    <ng-container *ngIf="validPersons">
                        <label>
                            <select class="form-control custom-select small"
                                    [(ngModel)]="price.prices[0].maxPersons"
                                    id="max-persons"
                                    name="maxPersons"
                                    [attr.required]="((price.prices[0].maxPersons ?? 0) < 1) ? 'required' : null">
                                <option *ngFor="let person of personArray" [ngValue]="person">
                                    {{ person }}
                                </option>
                            </select>
                            {{ 'atraveo.accommodationbundle.price.create.upToPersons.suffix' | translate }}
                        </label>
                        <p *ngIf="submitted && ((price.prices[0].maxPersons ?? 0) < 1)" class="error">{{ 'form.validation' | translate }}</p>
                    </ng-container>
                    <p *ngIf="price?.prices?.length && validPersons">
                        <label for="price-additional">
                            {{ 'atraveo.accommodationbundle.price.create.perPerson.maxPersons.additional-cost-default' | translate }}
                        </label>
                    </p>
                    <div class="input-group input-currency" *ngIf="price?.prices?.length && validPersons">
                        <input
                            type="number"
                            step="0.01"
                            class="form-control"
                            [(ngModel)]="price.prices[0].pricePerAdditionalPerson"
                            id="price-additional"
                            name="pricePerAdditionalPerson"
                            min="1"
                            [required]="validPersons">
                        <div class="input-group-append">
                            <span class="input-group-text" [innerHTML]="ownerCurrency"></span>
                        </div>
                        <p *ngIf="submitted && ((price.prices[0].pricePerAdditionalPerson ?? 0) < 1)" class="error">{{ 'form.validation' | translate }}</p>
                    </div>
                </span>
            </div>

            <div class="col" *ngIf= "priceType === 'person'"> <!-- Altersausnahmen -->
                <ng-container *ngIf="price?.prices[0]?.pricePerPerson || validPersons">
                    <label>
                        <input type="checkbox" name="ageExceptions" [(ngModel)]="ageExceptions" (click)="changeExceptions($event)" id="input-age-exceptions">
                        {{ 'atraveo.accommodationbundle.price.create.ageExceptionExist.0' | translate }}
                    </label>
                    <ng-container *ngIf="ageExceptions">
                        <ng-container *ngFor="let exception of price.prices[0].ageExceptions; let i = index">
                            <div class="row form-group mt-3" >
                                <label class="col-form-label pr-0" for="min-age-{{i}}">
                                    {{ 'atraveo.accommodationbundle.price.create.perPerson.age-exclusion.age-prefix' | translate }}
                                </label>
                                <div class="col-sm-3 ml-0 pl-2 pr-2">
                                    <input type="number" name="minAge" [(ngModel)]="exception.min" id="min-age-{{i}}" class="form-control" required="required" min="0">
                                    <p *ngIf="submitted && exception.min === undefined" class="error">{{ 'form.validation' | translate }}</p>
                                </div>
                                <label class="col-form-label pl-0 pr-0 no-break" for="max-age-{{i}}">
                                    {{ 'atraveo.accommodationbundle.price.create.perPerson.age-exclusion.age-glue' | translate }}
                                </label>
                                <div class="col-sm-3 ml-0 pl-2 pr-2">
                                    <input type="number" name="exceptionmax" [(ngModel)]="exception.max" id="max-age-{{i}}" class="form-control" required="required" min="0">
                                    <p *ngIf="submitted && !exception.max" class="error">{{ 'form.validation' | translate }}</p>
                                </div>
                                <div class="col-sm-2 ml-0 col-form-label pl-0 pr-0">
                                    {{ 'atraveo.accommodationbundle.price.create.perPerson.age-exclusion.age-suffix' | translate }}
                                </div>
                            </div>
                            <div class="row m20">
                                <div class="input-group input-currency">
                                    <input
                                        type="number"
                                        step="0.01"
                                        class="form-control"
                                        [(ngModel)]="exception.price"
                                        name="priceException"
                                        id="price-age-i"
                                        required="required"
                                        min="0">
                                    <div class="input-group-append">
                                        <span class="input-group-text" [innerHTML]="ownerCurrency"></span>
                                    </div>
                                    <p *ngIf="submitted && !exception.price" class="error">{{ 'validation.money.invalid' | translate }}</p>
                                </div>
                                <div class="col-auto">
                                    <a (click)="deleteAgeException(i)"><i class="fa fa-trash fa-2x"></i></a>
                                </div>

                            </div>
                            <hr>
                        </ng-container>
                        <button class="btn btn-primary mt-3" (click)="addAgeException()">
                            {{ 'atraveo.accommodationbundle.price.create.add.ageExceptionExist' | translate }}
                        </button>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>


    <div class="mt-5" *ngIf="(price?.travelPeriod?.from && price?.travelPeriod?.to) || price?.travelPeriod.seasonId">
        {{ 'atraveo.accommodationbundle.price.create.periodmetadata.headline.chosenperiod' | translate }}
        <table class="table responsive-table">
            <thead>
                <tr>
                    <th>{{ 'atraveo.accommodationbundle.price.field.daterange.0' | translate }}</th>
                    <th>{{ 'atraveo.accommodationbundle.price.arrivaldays.minimum.rental' | translate }}</th>
                    <th>{{ 'atraveo.accommodationbundle.price.arrivaldays.minimum.persons' | translate }}</th>
                    <th>{{ 'atraveo.accommodationbundle.price.arrivaldays.arrival.days' | translate }}</th>
                </tr>
            </thead>
            <tr *ngFor="let priceinformation of priceInformations">
                <td [attr.data-label]="'atraveo.accommodationbundle.price.field.daterange.0' | translate">
                    <span *ngIf=" priceinformation.travelPeriod?.from &&  priceinformation.travelPeriod?.to">
                        {{  priceinformation.travelPeriod?.from | stringToDate | date }} - {{  priceinformation.travelPeriod?.to | stringToDate | date }}
                    </span>
                </td>
                <td [attr.data-label]="'atraveo.accommodationbundle.price.arrivaldays.minimum.rental' | translate">
                    {{ priceinformation.minDuration }}
                </td>
                <td [attr.data-label]="'atraveo.accommodationbundle.price.arrivaldays.minimum.persons' | translate">
                    {{ priceinformation.minOccupancy }}
                </td>
                <td [attr.data-label]="'atraveo.accommodationbundle.price.arrivaldays.arrival.days' | translate">
                    <div *ngIf="priceinformation.arrivalDays">
                        <span *ngIf="priceinformation.arrivalDays[0] && priceinformation.arrivalDays[0] === 'Y'">
                            {{ 'weekday.so' | translate }}
                        </span>
                            <span *ngIf="priceinformation.arrivalDays[1] && priceinformation.arrivalDays[1] === 'Y'">
                            {{ 'weekday.mo' | translate }}
                        </span>
                            <span *ngIf="priceinformation.arrivalDays[2] && priceinformation.arrivalDays[2] === 'Y'">
                            {{ 'weekday.di' | translate }}
                        </span>
                            <span *ngIf="priceinformation.arrivalDays[3] && priceinformation.arrivalDays[3] === 'Y'">
                            {{ 'weekday.mi' | translate }}
                        </span>
                            <span *ngIf="priceinformation.arrivalDays[4] && priceinformation.arrivalDays[4] === 'Y'">
                            {{ 'weekday.do' | translate }}
                        </span>
                            <span *ngIf="priceinformation.arrivalDays[5] && priceinformation.arrivalDays[5] === 'Y'">
                            {{ 'weekday.fr' | translate }}
                        </span>
                            <span *ngIf="priceinformation.arrivalDays[6] && priceinformation.arrivalDays[6] === 'Y'">
                            {{ 'weekday.sa' | translate }}
                        </span>
                    </div>
                </td>
            </tr>
        </table>
        <p>{{ 'atraveo.accommodationbundle.price.create.periodmetadata.description' | translate }}</p>
    </div>
    <div class="modal-footer">
        <button (click)="save()" type="submit" class="btn btn-success">{{ 'button.save' | translate }}</button>
    </div>
</form>