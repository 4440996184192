import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { environment } from '../../environments/environment';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(readonly authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                // Internetverlust nach start des Requests
                if (err.status === 0) {
                    return throwError(err);
                }
                if ((request.url.includes(environment.apiUrl) || request.url.includes(environment.bookingConnectorUrl)) && err.status === 401) {
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout();
                    Sentry.captureException(err);
                }

                // Das sollten wir wahrscheinlich ganz raus nehmen damit man anständig Fehler behandeln kann, aber keine Ahnung was dann alles kaputt geht :(
                if (!request.url.includes('sentry.io')) {
                    const error = err.error?.error?.message || err.error?.message || err.statusText;
                    return throwError(error);
                }
            }),
        );
    }
}
