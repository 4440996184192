import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../../../entities/extendedAccommodationEntity';
import { TranslateService } from '@ngx-translate/core';
import { OfferDto } from 'data-structures/lib/es6/dto/accommodation';
import { ApiConnectorService } from '../../../../../../../services/api-connector/api-connector.service';
import { NotificationService } from '../../../../../../../services/notification/notification.service';
import { DurationEntry } from '../duration.service';
import { DateService } from '../../../../../../../services/date/date.service';
import { cloneDeep, isEqual } from 'lodash';
import { AuthenticationService } from '../../../../../../../services/authentication/authentication.service';
import { OwnerCurrencyEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { CurrencyEnum } from 'data-structures/lib/es6/enum/currency.enum';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '../../../../../../../services/dialog.service';

@Component({
    selector: 'app-create-special-offer',
    templateUrl: './create-special-offer.component.html',
    styleUrls: ['./create-special-offer.component.scss'],
})
export class CreateSpecialOfferComponent implements OnInit, OnChanges, OnDestroy {
    offerIndex: number;
    offer: OfferDto;
    durationChoices: DurationEntry[];
    @Output() onSave: EventEmitter<ExtendedAccommodationEntity> = new EventEmitter<ExtendedAccommodationEntity>();
    accommodation: ExtendedAccommodationEntity;
    accommodationSubscription: Subscription;
    activeTypeId: string;
    travelPeriodShow: boolean;
    bonusDaysBookError: boolean = false;
    offerTravelPeriodEnable: boolean = false;
    offerLimitationTripsEnable: boolean = false;
    ownerCurrency: OwnerCurrencyEnum = this.authService.currentUser.getOwnerCurrency();
    isSubmitted: boolean = false;

    constructor(
        readonly translationService: TranslateService,
        readonly apiConnector: ApiConnectorService,
        readonly notificationService: NotificationService,
        readonly authService: AuthenticationService,
        readonly dialogService: DialogService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.offerIndex = data.offerIndex;
        this.offer = data.offer;
        this.durationChoices = data.durationChoices;
    }

    async ngOnInit(): Promise<void> {
        this.accommodationSubscription = this.apiConnector.activeAccommodation$.subscribe(async (accommodation) => {
            if (accommodation) {
                this.accommodation = cloneDeep(accommodation);
            }
        });
        // // TODO: Den ganzen Block umbauen, wird sehr oft aufgerufen und braucht Perfomance laut Angular Dev Tools: (change) Auf das Element machen
        document.querySelector('#reductionType').addEventListener('change', (event) => {
            const element = event.currentTarget as HTMLInputElement;
            $('#reductionType').removeAttr('style');
            this.offer.typeId = this.activeTypeId = element.value;
            this.setActiveId();
            // Pre Selection Option for specialPrice
            if (this.offer.typeId === 'specialPrice') {
                this.travelPeriodShow = true;
                this.offer.priceType = 'ta';
                this.offer.pricePerPerson = false;
            }
            if (typeof this.offerIndex !== 'undefined' && this.offerIndex >= 0) {
                if (this.offer.typeId !== this.activeTypeId) {
                    this.resetFormValues();
                }
            }
        });

        await this.ngOnChanges(null);
    }

    ngOnDestroy() {
        this.accommodationSubscription?.unsubscribe();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (!this.offer) {
            this.offer = new OfferDto();
        }
        $('#reductionType').removeAttr('style');
        document.querySelector('.lastMinute').classList.add('hide');
        document.querySelector('.earlyBird').classList.add('hide');
        document.querySelector('.bonusDays').classList.add('hide');
        document.querySelector('.specialPrice').classList.add('hide');
        document.querySelector('.longStay').classList.add('hide');
        document.querySelector('.specialOffer').classList.add('hide');
        document.querySelectorAll('.discount-option').forEach((element) => element.setAttribute('disabled', 'disabled'));
        (document.querySelectorAll('[name="offerTypeBookingPeriod"]')[0] as HTMLInputElement).checked = true;
        (document.querySelectorAll('[name="rateType"]')[0] as HTMLInputElement).checked = true;
        (document.querySelector('[name="ratePercent"]') as HTMLInputElement).disabled = false;
        this.offerTravelPeriodEnable = false;
        this.offerLimitationTripsEnable = false;
        document.querySelector('.discount-new-price').classList.add('hide');
        document.querySelector('.absolute-discount').classList.add('hide');

        this.activeTypeId = this.offer.typeId;
        this.setActiveId();

        if (Object.keys(this.offer.duration).length) {
            this.offerLimitationTripsEnable = true;
        }

        if (this.offer.typeId) {
            const formGroup = document.querySelector('.form-group.' + this.activeTypeId);
            // Besonderer Fall
            if (['specialPrice', 'specialOffer'].includes(this.offer.typeId)) {
                if (this.offer.travelPeriod.from && this.offer.travelPeriod.to) {
                    this.offerTravelPeriodEnable = true;
                } else {
                    this.offerTravelPeriodEnable = false;
                }
                this.setSpecialOfferFormValues();
            } else {
                if (this.offer.travelPeriod.from && this.offer.travelPeriod.to) {
                    this.travelPeriodShow = true;

                    if (formGroup.querySelector('.bookingPeriod')) {
                        (formGroup.querySelector('.bookingPeriod') as HTMLInputElement).checked = false;
                    }

                    if (formGroup.querySelector('.bookingPeriodTravel')) {
                        (formGroup.querySelector('.bookingPeriodTravel') as HTMLInputElement).checked = true;
                    }

                    if (this.activeTypeId === 'specialPrice') {
                        (formGroup.querySelector('.bookingPeriodNights') as HTMLInputElement).checked = false;
                        formGroup.querySelector('.nightsFrom').setAttribute('disabled', 'disabled');
                        formGroup.querySelector('.nightsTo').setAttribute('disabled', 'disabled');
                    }

                    formGroup.querySelector('.dateFrom').removeAttribute('disabled');
                    formGroup.querySelector('.dateTo').removeAttribute('disabled');
                } else {
                    this.travelPeriodShow = false;

                    if (formGroup.querySelector('.bookingPeriod')) {
                        (formGroup.querySelector('.bookingPeriod') as HTMLInputElement).checked = true;
                    }

                    if (formGroup.querySelector('.bookingPeriodTravel')) {
                        (formGroup.querySelector('.bookingPeriodTravel') as HTMLInputElement).checked = false;
                    }

                    if (this.activeTypeId === 'specialPrice') {
                        (formGroup.querySelector('.bookingPeriodNights') as HTMLInputElement).checked = true;
                        formGroup.querySelector('.nightsFrom').removeAttribute('disabled');
                        formGroup.querySelector('.nightsTo').removeAttribute('disabled');
                    }

                    formGroup.querySelector('.dateFrom').setAttribute('disabled', 'disabled');
                    formGroup.querySelector('.dateTo').setAttribute('disabled', 'disabled');
                }
            }
        }
        this.offer.currency = CurrencyEnum[this.ownerCurrency];
    }

    optionCounter(i: number) {
        return new Array(i);
    }

    showTravelPeriod(event) {
        this.travelPeriodShow = true;
        this.offer.daysBeforeArrival.min = null;
        this.offer.daysBeforeArrival.max = null;

        if (this.activeTypeId === 'specialPrice') {
            this.offer.duration.min = null;
            this.offer.duration.max = null;
        }

        document
            .querySelector('.' + this.activeTypeId)
            .querySelector('.dateFrom')
            .removeAttribute('disabled');
        document
            .querySelector('.' + this.activeTypeId)
            .querySelector('.dateTo')
            .removeAttribute('disabled');

        if (this.activeTypeId === 'specialPrice' || this.activeTypeId === 'specialOffer') {
            document
                .querySelector('.' + this.activeTypeId)
                .querySelector('.nightsFrom')
                .setAttribute('disabled', 'disabled');
            document
                .querySelector('.' + this.activeTypeId)
                .querySelector('.nightsTo')
                .setAttribute('disabled', 'disabled');
        }

        document.querySelector('.' + event.target.id).classList.remove('hide');
    }

    hideTravelPeriod(event) {
        this.travelPeriodShow = false;
        this.offer.offerPeriod.from = null;
        this.offer.offerPeriod.to = null;

        if (this.activeTypeId !== 'specialOffer') {
            this.offer.travelPeriod.from = null;
            this.offer.travelPeriod.to = null;
        }

        document
            .querySelector('.' + this.activeTypeId)
            .querySelector('.dateFrom')
            .setAttribute('disabled', 'disabled');
        document
            .querySelector('.' + this.activeTypeId)
            .querySelector('.dateTo')
            .setAttribute('disabled', 'disabled');

        if (this.activeTypeId === 'specialPrice' || (this.activeTypeId === 'specialOffer' && event.target.classList.contains('offerTypeBookingPeriod'))) {
            document
                .querySelector('.' + this.activeTypeId)
                .querySelector('.nightsFrom')
                .removeAttribute('disabled');
            document
                .querySelector('.' + this.activeTypeId)
                .querySelector('.nightsTo')
                .removeAttribute('disabled');
        }

        document.querySelectorAll('.offer-radio-info').forEach((element) => element.classList.add('hide'));
    }

    changeDiscountType(event) {
        this.offer.percentDiscount = null;
        this.offer.daysToBook = null;
        this.offer.daysToPay = null;
        this.offer.newPrice = null;
        this.offer.absoluteDiscount = null;
        this.offer.priceType = null;
        this.offer.pricePerPerson = null;
        document.querySelectorAll('.discount-option').forEach((element) => element.setAttribute('disabled', 'disabled'));
        document.querySelectorAll('.' + event.target.classList[1]).forEach((element) => element.removeAttribute('disabled'));

        if (event.target.classList.contains('discount-option-three')) {
            document.querySelector('.discount-new-price').classList.remove('hide');
            document.querySelector('.absolute-discount').classList.add('hide');
        } else if (event.target.classList.contains('discount-option-four')) {
            this.offer.priceType = '1w';
            this.offer.pricePerPerson = false;
            document.querySelector('.discount-new-price').classList.add('hide');
            document.querySelector('.absolute-discount').classList.remove('hide');
        } else {
            document.querySelector('.discount-new-price').classList.add('hide');
            document.querySelector('.absolute-discount').classList.add('hide');
        }
    }

    changeCertainTravelPeriodRule() {
        this.offerTravelPeriodEnable = !this.offerTravelPeriodEnable;

        if (this.offerTravelPeriodEnable === false) {
            this.offer.travelPeriod.from = null;
            this.offer.travelPeriod.to = null;
        }
    }

    optionalRestrictionsRule() {
        this.offerLimitationTripsEnable = !this.offerLimitationTripsEnable;

        if (this.offerLimitationTripsEnable === false) {
            this.offer.occupancy.min = null;
            this.offer.occupancy.max = null;
        }
    }

    resetFormValues() {
        this.offer.travelPeriod.from = null;
        this.offer.travelPeriod.to = null;
        this.offer.offerPeriod.from = null;
        this.offer.offerPeriod.to = null;
        this.offer.percentDiscount = null;
        this.offer.daysBeforeArrival.min = null;
        this.offer.daysBeforeArrival.max = null;
        this.offer.daysToBook = null;
        this.offer.daysToPay = null;
        this.offer.duration.min = null;
        this.offer.duration.max = null;
        this.offer.newPrice = null;
        this.offer.absoluteDiscount = null;
        this.offer.priceType = null;
        this.offer.pricePerPerson = null;
        this.offer.occupancy.min = null;
        this.offer.occupancy.max = null;
    }

    setSpecialOfferFormValues() {
        if (this.offer.duration.min || this.offer.duration.max) {
            this.offerLimitationTripsEnable = true;
        } else {
            this.offerLimitationTripsEnable = false;
        }

        if (this.offer.offerPeriod.from || this.offer.offerPeriod.to) {
            (document.querySelectorAll('[name="offerTypeBookingPeriod"]')[1] as HTMLInputElement).checked = true;
        }

        if (this.offer.daysBeforeArrival.min || this.offer.daysBeforeArrival.max) {
            (document.querySelectorAll('[name="offerTypeBookingPeriod"]')[2] as HTMLInputElement).checked = true;
        }

        if (this.offer.percentDiscount) {
            document.querySelectorAll('.discount-option-one').forEach((element, index) => {
                if (index === 0) {
                    (element as HTMLInputElement).checked = true;
                } else {
                    (element as HTMLInputElement).disabled = false;
                }
            });
        }

        if (this.offer.daysToBook || this.offer.daysToPay) {
            document.querySelectorAll('.discount-option-two').forEach((element, index) => {
                if (index === 0) {
                    (element as HTMLInputElement).checked = true;
                } else {
                    (element as HTMLInputElement).disabled = false;
                }
            });
        }

        if (this.offer.newPrice) {
            document.querySelectorAll('.discount-option-three').forEach((element, index) => {
                if (index === 0) {
                    (element as HTMLInputElement).checked = true;
                } else {
                    (element as HTMLInputElement).disabled = false;
                }
                document.querySelector('.discount-new-price').classList.remove('hide');
            });
        }

        if (this.offer.absoluteDiscount) {
            document.querySelectorAll('.discount-option-four').forEach((element, index) => {
                if (index === 0) {
                    (element as HTMLInputElement).checked = true;
                } else {
                    (element as HTMLInputElement).disabled = false;
                }
                document.querySelector('.absolute-discount').classList.remove('hide');
            });
        }
    }

    setActiveId() {
        const selectorElements = document.querySelectorAll('.form-section');
        const currentSelector = document.querySelector('.' + this.activeTypeId);
        // tslint:disable-next-line:no-shadowed-variable
        selectorElements.forEach((element) => element.classList.add('hide'));
        if (this.activeTypeId) {
            currentSelector.classList.remove('hide');
        }
    }

    async checkDates() {
        if (
            this.offer.travelPeriod?.from &&
            DateService.getDateFromString(this.offer.travelPeriod?.from).getFullYear() > 1000 &&
            this.offer.travelPeriod?.to &&
            DateService.getDateFromString(this.offer.travelPeriod?.to).getFullYear() > 1000
        ) {
            if (DateService.getDateFromString(this.offer.travelPeriod.from) > DateService.getDateFromString(this.offer.travelPeriod.to)) {
                const from = this.offer.travelPeriod.from;
                this.offer.travelPeriod.from = this.offer.travelPeriod.to;
                this.offer.travelPeriod.to = from;
            }
        }

        if (
            this.offer.offerPeriod?.from &&
            DateService.getDateFromString(this.offer.offerPeriod?.from).getFullYear() > 1000 &&
            this.offer.offerPeriod?.to &&
            DateService.getDateFromString(this.offer.offerPeriod?.to).getFullYear() > 1000
        ) {
            if (DateService.getDateFromString(this.offer.offerPeriod.from) > DateService.getDateFromString(this.offer.offerPeriod.to)) {
                const from = this.offer.offerPeriod.from;
                this.offer.offerPeriod.from = this.offer.offerPeriod.to;
                this.offer.offerPeriod.to = from;
            }
        }
    }

    async onSubmit(): Promise<void> {
        this.isSubmitted = true;
        // @ts-ignore
        const result = $('#specialOfferForm')[0].checkValidity();

        if (!result) {
            // @ts-ignore
            $('#specialOfferForm')[0].reportValidity();
            await this.notificationService.add('validation.form.all.required', 'danger');
            return;
        }

        if (this.offer.typeId === '') {
            $('#reductionType').css('border-color', 'red');
            await this.notificationService.add('validation.form.all.required', 'danger');
            return;
        }
        if (this.offer.typeId === 'bonusDays' && this.offer.daysToPay > this.offer.daysToBook) {
            await this.notificationService.add('atraveo.accommodationbundle.price.offer.payForDays.invalid', 'danger');
            this.bonusDaysBookError = true;
            return;
        }

        if (this.isOfferDataEqual()) {
            if (this.offer.typeId === 'longStay') {
                await this.notificationService.add('atraveo.accommodationbundle.offer.error.duplicate', 'danger');
            } else {
                await this.notificationService.add(`atraveo.accommodationbundle.offer.${this.offer.typeId.toLowerCase()}.overlapping-occupancydate`, 'danger');
            }
            return;
        }

        this.offer.currency = CurrencyEnum[this.ownerCurrency];

        this.bonusDaysBookError = false;

        if (this.offerIndex >= 0) {
            this.accommodation.offers.splice(this.offerIndex, 1);
            this.accommodation.offers.push(this.offer);
            this.accommodation.offers.splice(this.offerIndex, 0, this.accommodation.offers[this.accommodation.offers.length - 1]).splice(-1);
            this.accommodation.offers.splice(-1);
        } else {
            this.accommodation.offers.push(this.offer);
        }
        this.apiConnector.saveAccommodation(this.accommodation);
        this.dialogService.closeDialog();
    }

    dateRangeOverlaps(startDate1: Date, endDate1: Date, startDate2: Date, endDate2: Date) {
        if (startDate1 <= startDate2 && startDate2 <= endDate1) return true; // b starts in a
        if (startDate1 <= endDate2 && endDate2 <= endDate1) return true; // b ends in a
        if (startDate2 < startDate1 && endDate1 < endDate2) return true; // a in b
        return false;
    }

    private isOfferDataEqual() {
        const inputOffer = this.offer;
        let offersAreEqual = true;

        if (this.accommodation.offers && this.accommodation.offers.length) {
            // tslint:disable-next-line:forin
            for (const i in this.accommodation.offers) {
                offersAreEqual = true;
                const offer = this.accommodation.offers[i];
                if (offer.typeId === inputOffer.typeId) {
                    if (Number(i) !== this.offerIndex) {
                        // tslint:disable-next-line:forin
                        for (const inputOfferProp in inputOffer) {
                            if (
                                typeof inputOffer[inputOfferProp] === 'object' &&
                                inputOffer[inputOfferProp] !== undefined &&
                                offer[inputOfferProp] !== undefined &&
                                Object.keys(inputOffer[inputOfferProp]).length &&
                                Object.keys(offer[inputOfferProp]).length &&
                                !isEqual(inputOffer[inputOfferProp], offer[inputOfferProp])
                            ) {
                                offersAreEqual = false;
                            } else if (typeof inputOffer[inputOfferProp] !== 'object' && inputOffer[inputOfferProp] && offer[inputOfferProp] && inputOffer[inputOfferProp] !== offer[inputOfferProp]) {
                                offersAreEqual = false;
                            }
                        }
                        if (offersAreEqual) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }
    closeDialog() {
        this.dialogService.closeDialog();
    }
}
