<ng-template [ngIf]="layout === 'full'">
    <section id="onboarding" class="main">
        <div class="container" >
            <p id="headline" class="text-center mb-5 pt-5">{{ 'onboarding.header' | translate }}</p>
            <div class="row mb-4" *ngFor="let step of steps[currentStep-1]" >
                <div class="col" [ngClass]=" step.class "  >
                    <app-card [nr]="step.nr" [identity]="step.ident" (clickEvent)="callFunction($event)" [description]="step.description" [btn1]="step.btn1" [btn2]="step.btn2"></app-card>
                </div>
            </div>
        </div>
    </section>
</ng-template>

<ng-template [ngIf]="layout === 'header' && !onBoardingReady">
    <div class="clearfix"></div>
    <div class="mt-2 alert info d-none d-md-block onboarding-header">
        <div class="row">
            <div class="col text-center px-1" *ngFor="let step of steps;  let i = index" >
                <app-card [nr]="step[i].nr" [identity]="step[i].ident" (clickEvent)="callFunction($event)" [btnCompleteNow]="((inProgressSteps[i + 1]) ? step[i].btnCompleteNow : '')" [btn1]="((completedSteps[i + 1] || inProgressSteps[i + 1]) ? '' : step[i].btn1)" layout="header" [success]="completedSteps[i + 1]" [successText]="step[i].success"></app-card>
            </div>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="layout === 'card' && !onBoardingReady">
    <div class="card border bg-transparent d-md-none">
        <div class="card-header">
            Todo
        </div>
        <ul class="list-group list-group-flush bg-transparent">
            <li class="list-group-item bg-transparent"  *ngFor="let step of steps;  let i = index" >
                <button class="btn btn-primary btn-block" *ngIf="!completedSteps[i + 1]" [routerLink]="(step[i].btn1.onclick) ? '' : step[i].btn1?.link" (click)="step[i].btn1.onclick && callFunction(step[i].btn1.onclick)">
                    <div class="close-icon float-right"></div> {{ step[i].ident | translate }}
                </button>
                <button class="btn btn-primary btn-block" disabled *ngIf="completedSteps[i + 1]">
                    {{ step[i].ident | translate }} <i class="fa fa-check-circle text-success"></i>
                </button>
            </li>
        </ul>
    </div>
</ng-template>
