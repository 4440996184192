import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../../entities/extendedAccommodationEntity';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApiConnectorService } from '../../../../../../services/api-connector/api-connector.service';
import { OfferDto } from 'data-structures/lib/es6/dto/accommodation';
import { TravelPeriodDto } from 'data-structures/lib/es6/dto/accommodation/offer/travel-period.dto';
import { ConfirmationDialogService } from '../../../../../global/confirmation-dialog/confirmation-dialog.service';
import { DurationEntry, DurationService } from './duration.service';
import { FromToDateDto } from 'data-structures/lib/es6/dto/accommodation/additional-cost/from-to-date.dto';
import { NotificationService } from '../../../../../../services/notification/notification.service';
import { AuthenticationService } from '../../../../../../services/authentication/authentication.service';
import { OwnerCurrencyEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../../../../services/dialog.service';
import { CreateSpecialOfferComponent } from './create-special-offer/create-special-offer.component';

@Component({
    selector: 'app-special-offer',
    templateUrl: './special-offer.component.html',
    styleUrls: ['./special-offer.component.scss'],
})
export class SpecialOfferComponent implements OnInit, OnDestroy {
    accommodation: ExtendedAccommodationEntity;
    @Output() onSave: EventEmitter<ExtendedAccommodationEntity> = new EventEmitter<ExtendedAccommodationEntity>();
    currentOffer: OfferDto;
    currentIndex: number;
    offer: OfferDto;
    language: string;
    durationChoices: DurationEntry[] = [];
    limitations = {
        // Key = Name im Offer-Objekt, Value = Übersetzungstext
        age: 'Age',
        occupancy: 'Occupancy',
        duration: 'Occupancy',
        // Beides Min-Max Werte im VRMB2, keine Ahnung was das im Inventory sein könnte:
        // ?: 'CalcForDay',
        // ??: 'CalcForPerson',
    };
    ownerCurrency: OwnerCurrencyEnum = this.authService.currentUser.getOwnerCurrency();
    accommodationSubscription: Subscription;
    translationServiceSubscription: Subscription;

    constructor(
        readonly translationService: TranslateService,
        readonly apiConnector: ApiConnectorService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly durationService: DurationService,
        readonly translateService: TranslateService,
        readonly notificationService: NotificationService,
        readonly authService: AuthenticationService,
        readonly dialogService: DialogService,
    ) {
        this.language = translationService.currentLang;
    }

    async ngOnInit() {
        this.accommodationSubscription = this.apiConnector.activeAccommodation$.subscribe(async (accommodation) => {
            if (accommodation) {
                this.accommodation = cloneDeep(accommodation);
                if (!this.accommodation.offers) {
                    this.accommodation.offers = [];
                    this.apiConnector.setActiveAccommodation(this.accommodation);
                }
            }
        });
        this.durationChoices = await this.durationService.getDurations();
    }

    async ngOnChanges() {
        this.translationServiceSubscription = this.translationService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.language = this.translationService.currentLang;
        });
    }

    async ngOnDestroy() {
        this.accommodationSubscription?.unsubscribe();
        this.translationServiceSubscription?.unsubscribe();
    }

    editOffer(index: number): void {
        this.currentIndex = index;
        this.currentOffer = cloneDeep(this.accommodation.offers[index]);

        if (!this.currentOffer.travelPeriod) {
            this.currentOffer.travelPeriod = new TravelPeriodDto();
        }

        if (!this.currentOffer.offerPeriod) {
            this.currentOffer.offerPeriod = new FromToDateDto();
        }

        this.openSpecialOfferDialog();
    }

    openSpecialOfferDialog() {
        this.dialogService.openDialog(
            CreateSpecialOfferComponent,
            { dialogWidth: this.dialogService.dialogWidth.M },
            {
                offer: this.currentOffer,
                offerIndex: this.currentIndex,
                durationChoices: this.durationChoices,
            },
        );
    }

    async cancelDeleteMultiple() {
        const deleteButton = document.querySelector('#delete-multiple-offers');
        const tableRows = document.querySelectorAll('table.offers tr');
        document.querySelector('#abort-delete-offers').classList.add('d-none');
        document.querySelector('.delete-explanation').classList.add('d-none');
        document.querySelector('#create-offer').classList.remove('d-none');
        tableRows.forEach((element) => {
            $(element).unbind();
            element.classList.remove('delete');
        });
        deleteButton.textContent = await this.translationService.get('atraveo.accommodationbundle.offer.delete.deleteMultiple').toPromise();
        deleteButton.classList.remove('started');
    }

    async deleteMultiple() {
        const deleteButton = document.querySelector('#delete-multiple-offers');

        if (deleteButton.classList.contains('started')) {
            const elementsToDelete = document.querySelectorAll('table.offers tr.delete');
            const elementsToDeleteArray = [].slice.call(elementsToDelete, 0).reverse();

            elementsToDeleteArray.forEach((element) => {
                const id = element.id;
                this.cancelDeleteMultiple();
                this.accommodation.offers.splice(id, 1);
            });
            await this.save(this.accommodation);
        } else {
            document.querySelector('#abort-delete-offers').classList.remove('d-none');
            document.querySelector('.delete-explanation').classList.remove('d-none');
            document.querySelector('#create-offer').classList.add('d-none');
            deleteButton.classList.add('started');

            const originalButtonText = await this.translationService.get('atraveo.accommodationbundle.price.delete.confirmDeleteNumberOfPrices').toPromise();
            let buttonText = originalButtonText.replace('%i', '0');

            deleteButton.textContent = buttonText;

            const availableElementsToDelete = document.querySelectorAll('table.offers tr');
            const availableElementsToDeleteArray = [].slice.call(availableElementsToDelete, 0);

            $('table.offers tr').on('click', (event) => {
                if (!event.target.classList.contains('btn') && !event.target.classList.contains('fas')) {
                    event.currentTarget.classList.toggle('delete');
                }

                const elementsToDelete = document.querySelectorAll('table.offers tr.delete').length;
                buttonText = originalButtonText.replace('%i', elementsToDelete.toString());
                deleteButton.textContent = buttonText;
            });
        }
    }

    createSpecialOffer(index: number): void {
        this.currentOffer = new OfferDto();
        this.currentOffer.travelPeriod = new TravelPeriodDto();
        this.currentOffer.offerPeriod = new FromToDateDto();
        this.openSpecialOfferDialog();
    }

    openConfirmationDialog(i: number) {
        this.confirmationDialogService
            .confirm('atraveo.accommodationbundle.price.delete.confirmDeleteSinglePrice', 'confirm.content.delete.0')
            .then(async (confirmed) => {
                if (confirmed) {
                    this.accommodation.offers.splice(i, 1);
                    await this.save(this.accommodation);
                }
            })
            .catch(() => undefined);
    }

    async save(accommodation) {
        this.onSave.emit(accommodation);
    }
}
