import { Component, Inject, ViewChild } from '@angular/core';
import { ArrivalInformationEntity } from 'data-structures/lib/es6/entity/arrival-information/arrival-information.entity';
import { ExtendedArrivalEntity } from '../../../../entities/extended-arrival-entity';
import { DialogService } from '../../../../services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateArrivalInformationComponent } from '../../../main/content/arrival-information/create-arrival-information/create-arrival-information.component';

@Component({
    selector: 'app-arrival-information-dialog',
    templateUrl: './arrival-information-dialog.component.html',
    styleUrl: './arrival-information-dialog.component.scss',
})
export class ArrivalInformationDialogComponent {
    @ViewChild(CreateArrivalInformationComponent) child: CreateArrivalInformationComponent;
    emptyArrivalInformation: ArrivalInformationEntity = new ExtendedArrivalEntity();

    constructor(readonly dialogService: DialogService, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.emptyArrivalInformation = data.emptyArrivalInformation;
    }

    save() {
        this.data.saveArrivalInfo(this.child);
    }

    closeDialog() {
        this.dialogService.closeDialog();
    }
}
