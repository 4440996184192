import { Component, ViewEncapsulation, OnInit, OnDestroy, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackOfficeConnectorService } from '../../../../../services/api-connector/back-office-connector-service';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { ComponentsEnum as FindAccommodationComponents } from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-request.dto';
import { OnboardingService } from '../../../../../services/onboarding/onboarding.service';
import { OwnerConnectorService } from '../../../../../services/api-connector/owner-connector.service';
@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrl: './onboarding.component.scss',
    encapsulation: ViewEncapsulation.None,
})
export class OnboardingComponent implements OnInit, OnDestroy {
    @Input() layout: string = 'full';
    currentStep: number = 1;
    steps = [];
    idNowLink = '';
    completedSteps: boolean[] = [];
    inProgressSteps: boolean[] = [];
    userSubscription: Subscription;
    onBoardingReady: boolean = false;
    onBoardingStepCheckSubscription: Subscription;

    private subscriber: Subscription;
    constructor(
        readonly translateService: TranslateService,
        readonly authService: AuthenticationService,
        readonly apiConnectorService: ApiConnectorService,
        readonly route: ActivatedRoute,
        readonly router: Router,
        readonly backOfficeConnectorService: BackOfficeConnectorService,
        readonly onboardingService: OnboardingService,
        readonly ownerConnector: OwnerConnectorService,
    ) {
        this.initSteps();
        this.onBoardingStepCheckSubscription = this.onboardingService.setSteps$.subscribe(() => {
            this.initSteps();
            this.setSteps();
        });
    }

    async initSteps() {
        const firstAccommodation = await this.apiConnectorService.getAccommodationsByOwnerNumber(this.authService.currentUser, 0, 1, { deleted: false }, [FindAccommodationComponents.AccommodationId]);
        this.steps = [
            [
                // step1
                {
                    nr: 1,
                    ident: 'onboarding.createObject.title',
                    description: 'onboarding.createObject.description',
                    btn1: { link: '/accommodation/register', text: 'onboarding.createObject.btn1', onclick: null },
                    btn2: { onclick: 'nextstep', text: 'onboarding.createObject.btn2' },
                    btnCompleteNow: {
                        link: firstAccommodation?.accommodations[0]?.accommodationId ? '/accommodation/' + firstAccommodation.accommodations[0].accommodationId : '',
                        text: 'onboarding.createObject.btnCompleteNow',
                        onclick: null,
                    },
                    success: 'onboarding.createObject.success',
                    class: 'active',
                },
                {
                    nr: 2,
                    ident: 'onboarding.profil.title',
                    description: 'onboarding.profil.descriptionInactive',
                    class: 'disabled',
                },
                {
                    nr: 3,
                    ident: 'onboarding.identity.title',
                    description: 'onboarding.identity.descriptionInactive',
                    class: 'disabled',
                },
            ],
            [
                // step2
                {
                    nr: 1,
                    ident: 'onboarding.createObject.title',
                    description: 'onboarding.createObject.descriptionInactive',
                    class: 'disabled',
                },
                {
                    nr: 2,
                    ident: 'onboarding.profil.title',
                    description: 'onboarding.profil.description',
                    btn1: { link: '/profile', text: 'onboarding.profil.btn1', onclick: null },
                    btn2: { onclick: 'nextstep', text: 'onboarding.profil.btn2' },
                    success: 'onboarding.profil.success',
                    class: 'active',
                },
                {
                    nr: 3,
                    ident: 'onboarding.identity.title',
                    description: 'onboarding.identity.descriptionInactive',
                    class: 'disabled',
                },
            ],
            [
                // step3
                { nr: 1, ident: 'onboarding.createObject.title', description: 'onboarding.createObject.descriptionInactive', class: 'disabled' },
                { nr: 2, ident: 'onboarding.profil.title', description: 'onboarding.profil.descriptionInactive', class: 'disabled' },
                {
                    nr: 3,
                    ident: 'onboarding.identity.title',
                    description: 'onboarding.identity.description',
                    btn1: { link: '/', onclick: 'gotoIDNow', text: 'onboarding.identity.btn1' },
                    btn2: { onclick: 'nextstep', text: 'onboarding.identity.btn2' },
                    btnCompleteNow: { link: '/', onclick: 'gotoIDNow', text: 'onboarding.identity.btnCompleteNow' },
                    success: 'onboarding.success',
                    class: 'active',
                },
            ],
        ];
        await this.checkIdNowStateSuccess();
    }

    async checkIdNowStateSuccess() {
        if (
            !this.authService.currentUser?.tracking?.onboarding.verificationCompleted &&
            ((!this.authService.currentUser?.registration?.idNowStatusText && this.authService.currentUser?.tracking?.onboarding.verificationStarted) || this.authService.currentUser?.state?.verified)
        ) {
            const currentUser = await this.ownerConnector.getUserData(this.authService.currentUser.ownerNumber);

            if (currentUser.tracking?.onboarding) {
                if (['SUCCESS', 'SUCCESS_DATA_CHANGED', 'FRAUD_SUSPICION_CONFIRMED'].includes(currentUser?.registration?.idNowStatusText) || currentUser?.state?.verified) {
                    this.authService.currentUser.tracking.onboarding.verificationCompleted = new Date();
                    this.authService.currentUser.registration.idNowStatusText = currentUser.registration.idNowStatusText;
                    await this.authService.saveOwner(this.authService.currentUser);
                    this.setSteps();
                }
            }
        }
    }

    setCurrentStep() {
        this.subscriber = this.route.params.subscribe(async (params) => {
            this.currentStep = +params.nr || 1;
        });
    }
    nextStep() {
        this.authService.currentUser.setSkippedOnBoardingSteps(this.currentStep);
        this.router.navigate(['/dashboard']);
    }
    callFunction(functionName: string): void {
        switch (functionName) {
            case 'nextstep':
                this.nextStep();
                break;
            case 'gotoIDNow':
                this.gotoIDNow();
                break;
            default:
                break;
        }
    }

    async gotoIDNow() {
        // speichere tracking für onboarding
        const userTracking = this.authService.currentUser?.tracking;
        if (userTracking?.onboarding) {
            if (!userTracking.onboarding.verificationStarted) {
                userTracking.onboarding.verificationStarted = new Date();
                this.authService.saveOwner(this.authService.currentUser);
            }
        }
        let idNowLink = '';
        await this.backOfficeConnectorService.getIDNowUrl().then((res) => (idNowLink = res));
        window.open(idNowLink, '_blank');
        this.router.navigate(['/dashboard']);
    }

    async ngOnInit() {
        this.userSubscription = this.authService.currentUserSubject.subscribe(async (user) => {
            if (user) {
                if (user.needsOnboarding()) {
                    this.setSteps();
                } else {
                    this.onBoardingReady = true;
                }
            }
        });

        if (this.authService.currentUser && this.authService.currentUser.needsOnboarding()) {
            this.setCurrentStep();
            this.setSteps();
        } else {
            this.onBoardingReady = true;
        }
    }

    setSteps() {
        if (this.authService.currentUser) {
            this.completedSteps = this.authService.currentUser.getCompletedOnboardingSteps();
            this.completedSteps.filter((step) => step === true).length === 3 ? (this.onBoardingReady = true) : (this.onBoardingReady = false);
            this.inProgressSteps = this.authService.currentUser.getInProgressOnboardingSteps();
        }
    }
    ngOnDestroy() {
        this.subscriber?.unsubscribe();
        this.userSubscription?.unsubscribe();
        this.onBoardingStepCheckSubscription?.unsubscribe();
    }
}
