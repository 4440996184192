import { BankAccountDto } from 'data-structures/lib/es6/dto/accommodation';

export class ExtendedBankAccountEntity extends BankAccountDto {
    // Gibt true zurück, wenn irgendwas im Konto ausgefüllt wurde, sonst false
    hasFilledFields(): boolean {
        for (const [key, value] of Object.entries(this)) {
            if (key === 'verified') {
                continue;
            }
            if (value) {
                if (typeof value !== 'object') {
                    return true;
                } else {
                    for (const subValue of Object.values(value)) {
                        if (subValue) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    // Bei Veränderung dieser Methode muss das Gegenstück im Inventory-Backoffice-Export Projekt ebenfalls angepasst werden.
    isSEPACountry(): boolean {
        return [
            'DE',
            'CH',
            'LI',
            'MC',
            'AD',
            'AT',
            'BE',
            'BG',
            'CY',
            'CZ',
            'DK',
            'EE',
            'ES',
            'FI',
            'FR',
            'GB',
            'GF',
            'GI',
            'GP',
            'GR',
            'HR',
            'HU',
            'IE',
            'IS',
            'IT',
            'LI',
            'LT',
            'LU',
            'LV',
            'MC',
            'MQ',
            'MT',
            'NL',
            'NO',
            'PL',
            'PT',
            'RO',
            'SE',
            'SI',
            'SK',
            '',
        ].includes(this.bankCountryISOCode);
    }

    removeUnnecessaryValues() {
        if (this.isSEPACountry()) {
            for (const [key, value] of Object.entries(this)) {
                if (['verified', 'bankCountryISOCode', 'accountHolder', 'iban', 'bic', 'accommodationIds'].includes(key)) {
                    continue;
                }

                delete this[key];
            }
        }
    }
}
