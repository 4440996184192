<!--<app-accommodation-header [selectedTab]="selectedTab" *ngIf="accommodation" class="d-lg-block d-xl-block d-xxl-block" [ngClass]="((selectedTab !== 'overview') ? 'd-none' : 'd-xs-block d-md-block')"></app-accommodation-header>-->
<app-accommodation-header [selectedTab]="selectedTab" *ngIf="accommodation"></app-accommodation-header>
<div class="tab-content d-flex flex-column justify-content-between" *ngIf="accommodation">
    <div style="order: 1"
         class="nav-item-sm mt-3 mb-0"
         (click)="handleClick('position')"
    >
        <i class="fa" [ngClass]="this.selectedTab == 'position' && showRouterContent ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.accommodationbundle.navigation.position.title' | translate }}
    </div>
    <div style="order: 3"
         (click)="handleClick('main')"
         class="nav-item-sm mt-3 mb-0"
    >
        <i class="fa" [ngClass]="this.selectedTab == 'main' && showRouterContent ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.accommodationbundle.navigation.main.title' | translate }}
    </div>
    <div *ngIf="accommodation.modelIsAllowed(authenticationService)"
         style="order: 5"
         (click)="handleClick('model')"
         class="nav-item-sm mt-3 mb-0">
        <i class="fa" [ngClass]="this.selectedTab == 'model' && showRouterContent ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.accommodationbundle.navigation.model.title' | translate }}
    </div>
    <div style="order: 7"
         (click)="handleClick('features')"
         class="nav-item-sm mt-3 mb-0">
        <i class="fa" [ngClass]="this.selectedTab == 'features' && showRouterContent ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.accommodationbundle.navigation.feature.title' | translate }}
    </div>
    <div style="order: 9"
         (click)="handleClick('description')"
         class="nav-item-sm mt-3 mb-0">
        <i class="fa" [ngClass]="this.selectedTab == 'description' && showRouterContent ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.accommodationbundle.navigation.description.title' | translate }}
    </div>
    <div style="order: 11"
         (click)="handleClick('images')"
         class="nav-item-sm mt-3 mb-0">
        <i class="fa" [ngClass]="['image', 'images'].includes(this.selectedTab) && showRouterContent ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.accommodationbundle.navigation.media.title' | translate }}
    </div>
    <div style="order: 13"
         (click)="handleClick('prices')"
         class="nav-item-sm mt-3 mb-0">
        <i class="fa" [ngClass]="this.selectedTab == 'prices' && showRouterContent ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.accommodationbundle.navigation.price.title' | translate }}
    </div>
    <div style="order: 15"
         (click)="handleClick('calendar')"
         class="nav-item-sm mt-3 mb-0">
        <i class="fa" [ngClass]="this.selectedTab == 'calendar' && showRouterContent ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.accommodationbundle.navigation.calendar.title' | translate }}
    </div>
    <div
        class="tab-panel"
        [class.d-none]="!showRouterContent"
        role="tabpanel"
        aria-labelledby="images-tab"
        *ngIf="selectedTab"
        [style]="'order: ' + selectedTabOrder"
    >
        <router-outlet></router-outlet>
    </div>
</div>
