import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../../environments/environment';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { OwnerService } from '../../../../../services/owner-service/owner.service';

@Component({
    selector: 'app-request-rating',
    templateUrl: './request-rating.component.html',
    styleUrls: ['./request-rating.component.scss'],
})
export class RequestRatingComponent implements OnInit {
    selectedAccommodationId: number;
    selectedLanguage: string;
    allowedLanguages = environment.allowedLanguages;
    subject: string;
    message: string;

    constructor(readonly translationService: TranslateService, readonly authService: AuthenticationService, readonly ownerService: OwnerService) {}

    ngOnInit(): void {
        this.selectedLanguage = this.translationService.currentLang;
    }

    async search($event: any = null) {
        // Objekt über Ajaxsuche ausgewählt
        if ($event) {
            this.selectedAccommodationId = $event;
        }
        const oldLanguage = this.translationService.currentLang;
        this.translationService.use(this.selectedLanguage);
        this.subject = await this.translationService.get('atraveo.customerratingbundle.invitation.template.subject', {}).toPromise();
        const text: string = await this.translationService.get('atraveo.customerratingbundle.invitation.template.message').toPromise();
        this.message = text
            .replace('{accommodationId}', this.selectedAccommodationId.toString())
            .replace('{sender}', await this.ownerService.getDisplayName(this.authService.currentUser, this.selectedLanguage));
        this.translationService.use(oldLanguage);
    }
}
