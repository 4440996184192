import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../entities/extendedAccommodationEntity';
import { ProgressCheckService } from '../../../services/accommodation/check/progress-check.service';

@Component({
    selector: 'app-accommodation-progress-bar',
    templateUrl: './accommodation-progress-bar.component.html',
    styleUrls: ['./accommodation-progress-bar.component.scss'],
})
export class AccommodationProgressBarComponent implements OnChanges, OnDestroy {
    @Input() accommodation: ExtendedAccommodationEntity;
    progressBarTotal: number = 0;
    progressBarClassName: string;
    progressCheckSubscription: any = null;

    constructor(readonly progressCheckService: ProgressCheckService) {
        // Ich bin ziemlich sicher das gelöscht werden kann (inklusive Service), das Event wird nur beim speichern geworfen, und wenn ich es auskommentiere verändert sich das Balken trotzdem
        this.progressCheckSubscription = this.progressCheckService.getNewProgressState().subscribe(async () => {
            await this.ngOnChanges();
        });
    }

    async ngOnChanges() {
        if (!this.accommodation.accommodationId) {
            return;
        }

        this.progressBarTotal = this.accommodation.main.checkResultProgressBarTotal;
    }

    async ngOnDestroy() {
        if (this.progressCheckSubscription) {
            this.progressCheckSubscription.unsubscribe();
        }
    }
}
