<table class="table responsive-table" *ngIf="dailyPrices?.length">
    <thead>
    <tr>
        <th>
            {{ 'atraveo.accommodationbundle.price.field.daterange.0' | translate }}
        </th>
        <th>
            {{ 'atraveo.accommodationbundle.price.create.pricePerNight' | translate }}
        </th>
        <th>
            {{ 'atraveo.accommodationbundle.price.create.pricePerWeekendNight' | translate }}
        </th>
        <th>
            {{ 'atraveo.accommodationbundle.price.create.priceForXPersons' | translate }}
        </th>
        <th>{{ 'atraveo.accommodationbundle.price.create.upToPersons.additionalPersons' | translate }}</th>
        <th><!-- Icons --></th>
    </tr>
    </thead>
    <ng-container *ngFor="let priceRule of dailyPrices;">
        <tr (click)="addOrRemoveDeleteElement(priceRule.id)" [class.selected]="this.deleteElements.includes(priceRule.id) && this.deleteMultipleInProgress">
            <td [attr.data-label]="'atraveo.accommodationbundle.price.field.daterange.0' | translate" *ngIf="!priceRule.travelPeriod?.seasonId">
                {{ dateService.getFormattedDate(priceRule.travelPeriod.from, translateService.currentLang, priceRule.travelPeriod?.followingYears) }}
                - {{ dateService.getFormattedDate(priceRule.travelPeriod.to, translateService.currentLang, priceRule.travelPeriod?.followingYears) }}
            </td>

            <td [attr.data-label]="'atraveo.accommodationbundle.price.field.daterange.0' | translate" *ngIf="priceRule.travelPeriod?.seasonId">
                <ng-container *ngFor="let season of seasons.seasons">
                    <div *ngIf="season.seasonId === priceRule.travelPeriod.seasonId">
                        <span>{{ season.name[this.translateService.currentLang] }}</span>
                    </div>
                </ng-container>
                {{ dateService.getFormattedDate(priceRule.travelPeriod.from, translateService.currentLang, priceRule.travelPeriod?.followingYears) }}
                - {{ dateService.getFormattedDate(priceRule.travelPeriod.to, translateService.currentLang, priceRule.travelPeriod?.followingYears) }}
            </td>
            <td [attr.data-label]="'atraveo.accommodationbundle.price.create.pricePerNight' | translate">
                {{ priceRule.prices[0].price | currency:priceRule.prices[0].currency || 'EUR' :true:'1.2-2' }}
            </td>
            <td [attr.data-label]="'atraveo.accommodationbundle.price.create.pricePerWeekendNight' | translate"
                [class]="priceRule.prices[0].weekEndPrice ? '' : 'd-none d-lg-table-cell'">
                <ng-template [ngIf]="priceRule.prices[0].weekEndPrice">
                    {{ priceRule.prices[0].weekEndPrice | currency:priceRule.prices[0].currency || 'EUR':true:'1.2-2' }}
                </ng-template>
                <ng-template [ngIf]="!priceRule.prices[0].weekEndPrice">
                    {{ priceRule.prices[0].price | currency:priceRule.prices[0].currency || 'EUR':true:'1.2-2' }}
                </ng-template>
            </td>
            <td [attr.data-label]="'atraveo.accommodationbundle.price.create.priceForXPersons' | translate"
                [class]="priceRule.prices[0].maxPersons > 0 ? '' : 'd-none d-lg-table-cell'">
                <ng-template [ngIf]="priceRule.prices[0].maxPersons === 1">
                    {{ 'atraveo.accommodationbundle.price.create.forPersons.1' | translate }}
                </ng-template>
                <ng-template [ngIf]="priceRule.prices[0].maxPersons > 1">
                    {{ 'atraveo.accommodationbundle.price.create.forPersons.count' | translate:{count: priceRule.prices[0].maxPersons} }}
                </ng-template>
                <ng-template  [ngIf]="!priceRule.prices[0].maxPersons">
                    {{  'atraveo.accommodationbundle.price.create.forBooking' | translate }}
                </ng-template>
            </td>
            <td [attr.data-label]="'atraveo.accommodationbundle.price.create.upToPersons.additionalPersons' | translate"
            [class]="priceRule.prices[0].pricePerAdditionalPerson ? '' : 'd-none d-lg-table-cell'">
                <ng-template [ngIf]="priceRule.prices[0].pricePerAdditionalPerson">
                    {{ priceRule.prices[0].pricePerAdditionalPerson | currency:priceRule.prices[0].currency || 'EUR':true:'1.2-2' }}
                </ng-template>
                <ng-template [ngIf]="!priceRule.prices[0].pricePerAdditionalPerson">
                    -
                </ng-template>
            </td>
            <td class="small text-nowrap text-right">
                <button class="btn p-0"
                        (click)="setCurrentDailyPriceId(priceRule.id)">
                    <i class="fa fa-pen fa-lg"></i>
                </button>
                <button type="button" class="btn btn-link p-0" (click)="deleteElements = [priceRule.id]; openConfirmationDialog();">
                    <i class="fa fa-trash-alt fa-lg ml-2"></i>
                </button>
            </td>
        </tr>
    </ng-container>
</table>

<div class="delete-multiple float-right mb-2" *ngIf="dailyPrices?.length">
    <span *ngIf="deleteMultipleInProgress" class="mr-3">
        {{ 'atraveo.accommodationbundle.price.delete.deleteMultiple' | translate }}
    </span>
    <button *ngIf="deleteMultipleInProgress" class="btn btn-primary mb-2" (click)="deleteMultiple()">
        {{ 'atraveo.accommodationbundle.price.delete.confirmDeleteNumberOfPricesNumber' | translate: {number: deleteElements.length} }}
    </button>
    <button *ngIf="deleteMultipleInProgress" class="btn btn-primary ml-3 mb-2" (click)="this.abortDeletePrice()">
        {{ 'button.cancel' | translate }}
    </button>
    <button *ngIf="!deleteMultipleInProgress" class="btn btn-primary ml-3" (click)="this.deleteMultipleInProgress = true">
        {{ 'atraveo.accommodationbundle.price.delete.deletePrices' | translate }}
    </button>
</div>

<div id="delete-popover" class="hide">
    <p>{{ 'confirm.content.delete.0' | translate }}</p>
    <div class="btn-group">
        <div class="btn btn-secondary btn-delete-price">{{ 'text.marketing.teaser.yes' | translate }}</div>
        <div class="btn btn-primary btn-close">{{ 'atraveo.accommodationbundle.description.existAtOtherProvider.choice1' | translate }}</div>
    </div>
</div>
