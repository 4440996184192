import { Component, Inject } from '@angular/core';
import { DialogService } from '../../../../services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExtendedPriceRule } from '../../../../entities/PriceRule/extended-price-rule';
import { ExtendedDailyPriceRule } from '../../../../entities/PriceRule/extended-daily-price-rule';
import { ExtendedAccommodationEntity } from '../../../../entities/extendedAccommodationEntity';

@Component({
    selector: 'app-price-dialog',
    templateUrl: './price-dialog.component.html',
    styleUrl: './price-dialog.component.scss',
})
export class PriceDialogComponent {
    accommodation: ExtendedAccommodationEntity;
    currentPrice: ExtendedPriceRule;
    currentDailyPrice: ExtendedDailyPriceRule;
    useDailyPrices: boolean = false;
    submitted: boolean = false;
    editPriceModal: boolean = true;
    dateOverlap: string = '';

    constructor(readonly dialogService: DialogService, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.accommodation = data.accommodation;
        this.currentPrice = data.currentPrice;
        this.currentDailyPrice = data.currentDailyPrice;
        this.useDailyPrices = data.useDailyPrices;
        this.submitted = data.submitted;
        this.editPriceModal = data.editPriceModal;
        this.dateOverlap = data.dateOverlap;
    }

    saveDailyPrice($event: ExtendedDailyPriceRule) {
        this.data.savePrice($event);
    }

    closeDialog() {
        this.dialogService.closeDialog();
    }
}
