import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { BookingConnectorService } from '../../../../../services/api-connector/booking-connector.service';
import { StatusEnum } from 'data-structures/lib/es6/enum/status.enum';

@Component({
    selector: 'app-booking-list',
    templateUrl: './booking-list.component.html',
    styleUrls: ['./booking-list.component.scss'],
})
export class BookingListComponent implements OnInit{
    selectedTab: StatusEnum | null = null;
    selectedTabOrder: number = 0;

    constructor(readonly activatedRoute: ActivatedRoute,
                readonly bookingConnectorService: BookingConnectorService,
                readonly authenticationService: AuthenticationService,
                ) {}

    async ngOnInit() {
        this.activatedRoute.fragment.subscribe((fragment) => {
            // Tab setzen, wenn Anker gesetzt ist
            if (!fragment) {
                this.setSelectedTab('confirmed');
                return;
            }

            this.setSelectedTab(fragment);
        });
    }

    setSelectedTab(tab: string): void {
        if (['open', 'confirmed', 'cancelled'].includes(tab)) {
            // für die Sortierung der Flexbox
            if (tab === 'open') {
                this.selectedTab = StatusEnum.Open;
                this.selectedTabOrder = 2;
            }
            if (tab === 'confirmed') {
                this.selectedTab = StatusEnum.Confirmed;
                this.selectedTabOrder = 4;
            }
            if (tab === 'cancelled') {
                this.selectedTab = StatusEnum.Cancelled;
                this.selectedTabOrder = 6;
            }
        } else {
            this.selectedTab = null;
        }
    }
}
