<!-- Angular kennt bei Selectboxen nur [disabled] aber nicht [readonly] -->
<ng-container *ngIf="form; else withoutForm" [formGroup]="form">
    <select *ngIf="allCountries.length"
            id="{{ idKey }}"
            class="{{ classKey }}"
            name="{{idKey}}countrySelectFormname"
            [formControlName]="formControlName"
            (change)="onCountryChange($event)"
    >
        <option *ngFor="let country of allCountries" [value]="country.country" [disabled]="country?.disabled">
            {{ country.text }}
        </option>
    </select>
</ng-container>
<ng-template #withoutForm>
    <select *ngIf="allCountries.length"
            id="{{ idKey }}"
            class="{{ classKey }}"
            [(ngModel)]="value"
            [attr.required]="isOptional || !isUserProfile ? null : 'required'"
            (ngModelChange)="setCurrentPhonePrefix(value)"
            [ngModelOptions]="{standalone: true}"
            [attr.data-none-selected-text]= "noneSelectedDisplayAll ? ('text.arrival.geo.path.all' | translate) : ('text.select.empty' | translate)"
            [attr.data-select-all-text]= "'select.all' | translate"
            [attr.data-deselect-all-text]= "'select.none' | translate"
            [disabled]="readOnly || isAccommodationOrGroupActive"
            name="{{idKey}}countrySelect">
        <option *ngFor="let country of allCountries" [value]="country.country" [disabled]="country?.disabled">
            {{ country.text }}
        </option>
    </select>
</ng-template>