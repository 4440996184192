<div *ngIf="accommodation && accommodation.modelIsAllowed(authenticationService)">
    <div *ngIf="models && models.length">
        <form [formGroup]="modelForm" (ngSubmit)="save()">
            <div class="row">
                <div *ngFor="let model of models" class="col-6">
                    <div class="card" [class]="((model.service && authenticationService.currentUser.isEmployee()) ? 'service' : '')">
                        <div class="card-header">
                            <div class="form-check-inline">
                                <input *ngIf="modelForm.enabled" type="radio" class="form-check-input" [id]="'model-' + model" name="model" [value]="model.model" formControlName="model">
                                <label class="form-check-label" [for]="'model-' + model">{{ 'atraveo.accommodationbundle.model.' + model.model | translate }}</label>
                                <app-validation-error-messages [control]="modelForm.controls.model"></app-validation-error-messages>
                            </div>
                        </div>
                        <div class="card-body" [innerHTML]="'atraveo.accommodationbundle.modeldescriptions.' + model.model | translate"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="modelForm.enabled">
                <button type="submit" class="btn btn-success"><i class="fas fa-cloud-upload-alt mr-2"></i>{{ 'button.save' | translate }}</button>
            </div>
        </form>
    </div>
    <div *ngIf="models && models.length === 0">
        {{ 'atraveo.accommodationbundle.model.no_models' | translate }}
    </div>
</div>
