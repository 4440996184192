import { Component, OnChanges, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../entities/extendedAccommodationEntity';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { ExtendedPriceRule, PriceTypes } from '../../../../../entities/PriceRule/extended-price-rule';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { EditPriceComponent } from './edit-price/edit-price.component';
import { NotificationService } from '../../../../../services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { PriceTableComponent } from './price-table/price-table.component';
import { TravelPeriodService } from '../../../../../services/travel-period/travel-period.service';
import { ActivatedRoute } from '@angular/router';
import { ExtendedPriceInformation } from '../../../../../entities/PriceInformation/extended-price-information';
import { Observable, Subscription } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash';
import { ComponentCanDeactivate } from '../../../../../guards/pending-changes-guard';
import { ExtendedDailyPriceRule } from '../../../../../entities/PriceRule/extended-daily-price-rule';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { addDays, format } from 'date-fns';
import { DialogService } from '../../../../../services/dialog.service';
import { PriceDialogComponent } from '../../../../global/dialogs/price-dialog/price-dialog.component';
import { AdvancedSettingsDialogComponent } from '../../../../global/dialogs/advanced-settings-dialog/advanced-settings-dialog.component';

@Component({
    selector: 'app-price',
    templateUrl: './price.component.html',
    styleUrls: ['./price.component.scss'],
})
export class PriceComponent implements OnInit, OnChanges, OnDestroy, ComponentCanDeactivate {
    @ViewChild(EditPriceComponent) editPrice: EditPriceComponent;
    @ViewChildren(PriceTableComponent) priceTableComponent: QueryList<PriceTableComponent>;
    accommodation: ExtendedAccommodationEntity;
    accommodationOriginal: ExtendedAccommodationEntity;
    currentPrice: ExtendedPriceRule;
    submitted: boolean = false;
    editPriceModal: boolean = true;
    // Für jeden Preistypen (pro Tag / pro Woche usw. wird eine eigene Tabelle angezeigt). Hier ist ein Eintrag für jeden AKTIVEN Preistyp drin
    activePriceTypes: PriceTypes[];
    dateOverlap: string = '';
    accommodationSubscription: Subscription;
    useDailyPrices: boolean = false;
    currentDailyPrice: ExtendedDailyPriceRule;
    currentDailyPriceId: number;

    constructor(
        readonly apiConnectorService: ApiConnectorService,
        readonly authenticationService: AuthenticationService,
        readonly notificationService: NotificationService,
        readonly translateService: TranslateService,
        readonly travelPeriodService: TravelPeriodService,
        readonly route: ActivatedRoute,
        readonly priceModal: NgbModal,
        readonly dialogService: DialogService,
    ) {}

    async ngOnInit() {
        this.accommodationSubscription = this.apiConnectorService.activeAccommodation$.subscribe(async (accommodation) => {
            if (accommodation) {
                if (!accommodation.priceRules) {
                    accommodation.priceRules = [];
                }
                if (!accommodation.dailyPriceRules) {
                    accommodation.dailyPriceRules = [];
                }
                this.accommodation = cloneDeep(accommodation);

                // wer nutzt die DailyPrices
                this.useDailyPrices = !this.accommodation.priceRules || this.accommodation.priceRules.length === 0;

                await this.ngOnChanges();
            }
        });
    }

    ngOnDestroy() {
        this.accommodationSubscription?.unsubscribe();
    }

    async ngOnChanges() {
        this.activePriceTypes = [];
        if (this.accommodation?.priceRules?.length) {
            for (const key of Object.values(PriceTypes)) {
                if (this.accommodation.hasPriceType(key)) {
                    this.activePriceTypes.push(key);
                }
            }
        }

        // für den Check, ob sich Daten geändert haben
        this.accommodationOriginal = cloneDeep(this.accommodation);
    }

    openAdvancedSettings() {
        this.dialogService.openDialog(AdvancedSettingsDialogComponent, { dialogWidth: this.dialogService.dialogWidth.L });
    }

    async createPriceRule() {
        if (this.useDailyPrices) {
            this.currentDailyPriceId = null;
            this.currentDailyPrice = new ExtendedDailyPriceRule();

            // berechne das Startdatum abhänging von dem größtem Datum in der Liste
            if (this.accommodation.dailyPriceRules.length > 0) {
                const maxDate = this.accommodation.dailyPriceRules.reduce((max, price) => (price.travelPeriod.to > max ? price.travelPeriod.to : max), '');
                this.currentDailyPrice.travelPeriod.from = format(addDays(new Date(maxDate), 1), 'yyyy-MM-dd');
            }
        } else {
            this.currentPrice = new ExtendedPriceRule();
        }
        this.editPriceModal = false;
        this.dateOverlap = '';
        this.dialogService.openDialog(
            PriceDialogComponent,
            { dialogWidth: this.dialogService.dialogWidth.L },
            {
                useDailyPrices: this.useDailyPrices,
                currentPrice: this.currentPrice,
                submitted: this.submitted,
                editPriceModal: this.editPriceModal,
                dateOverlap: this.dateOverlap,
                currentDailyPrice: this.currentDailyPrice,
                accommodation: this.accommodation,
                savePrice: this.saveDailyPrice.bind(this),
            },
        );
    }

    setCurrentDailyPriceId(id: number) {
        this.currentDailyPriceId = id;
        const selectedpriceRule = this.accommodation.dailyPriceRules.find((price) => price.id === id);
        if (selectedpriceRule) {
            this.currentDailyPrice = cloneDeep(selectedpriceRule);
        } else {
            this.createPriceRule();
        }
    }

    deleteDailyprices(ids: number[]) {
        this.accommodation.dailyPriceRules = this.accommodation.dailyPriceRules.filter((price) => !ids.includes(price.id));
        this.save(this.accommodation);
    }

    async saveDailyPrice(event: ExtendedDailyPriceRule) {
        if (!this.accommodation.dailyPriceRules) {
            this.accommodation.dailyPriceRules = [];
        }

        // lösche den alten
        if (this.currentDailyPriceId) {
            this.accommodation.dailyPriceRules = this.accommodation.dailyPriceRules.filter((price) => price.id !== this.currentDailyPriceId);
        }
        this.accommodation.dailyPriceRules.push(event);

        await this.save(this.accommodation);

        this.currentDailyPriceId = null;
        this.currentDailyPrice = null;
        this.dialogService.closeDialog();
    }

    // für die Nebenkosten, da diese verschiedene Speichermöglichkeiten haben
    async save(accommodation: ExtendedAccommodationEntity) {
        this.apiConnectorService.saveAccommodation(accommodation);
    }

    canDeactivate(): Observable<boolean> | boolean {
        return isEqual(this.accommodation, this.accommodationOriginal);
    }
}
