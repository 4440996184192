import { differenceInDays, format, subDays } from 'date-fns';
import { VacancyDto } from 'data-structures/lib/es6/dto/accommodation';
import { DateService } from '../services/date/date.service';
import { ExtraVacancyTypeEnum } from 'data-structures/lib/es6/enum/extra-vacancy-types.enum';
import { utcToZonedTime } from 'date-fns-tz';

export class ExtendedVacancy extends VacancyDto {
    static FILL_UNDEFINED_DAYS_WITH = 'Y';

    constructor(startDate: string = '', vacancy: string = '') {
        super();

        this.offsetDate = startDate;
        this.originalVacancy = vacancy;
        this.shift();
    }

    // Vakanzstring auf ein anderes Datum anpassen
    shift(toDate: Date = new Date()) {
        toDate.setHours(0, 0, 0, 0);

        const difference = differenceInDays(toDate, DateService.getDateFromString(this.offsetDate));

        let newValue = this.vacancyString;

        if (difference > 0 && newValue) {
            // Vorne abschneiden
            newValue = newValue.substring(difference);
        }

        if (difference < 0) {
            // Vorne auffüllen
            newValue = ExtendedVacancy.FILL_UNDEFINED_DAYS_WITH.repeat(-difference) + newValue;
        }

        this.offsetDate = format(toDate, 'yyyy-MM-dd');
        this.vacancyString = newValue;
    }

    // Wenn type angegeben ist, wird der Knoten mit dem Namen type aus extraVacancyStrings genommen
    getVacancyForDate(date: Date, type: string = null): string {
        const today = utcToZonedTime(new Date(), 'Europe/Berlin');
        today.setHours(0, 0, 0, 0);

        if (date < today) {
            return null;
        }

        const difference = differenceInDays(date, DateService.getDateFromString(this.offsetDate));

        if (type && (!this.extraVacancyStrings || !(type in this.extraVacancyStrings))) {
            return ExtendedVacancy.FILL_UNDEFINED_DAYS_WITH;
        }

        let vacancyString = this.vacancyString;

        if (type) {
            vacancyString = this.extraVacancyStrings[type];
        }

        if (vacancyString && vacancyString.length > difference) {
            return vacancyString.charAt(difference);
        }

        return ExtendedVacancy.FILL_UNDEFINED_DAYS_WITH;
    }
    getPriceForDate(date: Date): string {
        const today = utcToZonedTime(new Date(), 'Europe/Berlin');
        today.setHours(0, 0, 0, 0);

        if (date < today) {
            return null;
        }
        const difference = differenceInDays(date, DateService.getDateFromString(this.offsetDate));
        const dayPriceString = this.dayPriceString;

        if (dayPriceString && dayPriceString.length > difference) {
            const priceArray = dayPriceString.split(';');
            return this.formatPrice(priceArray[difference]);
        }
        return '';
    }
    formatPrice(price: string): string {
        const showPrice = price && !['0', '1'].includes(price);
        if (showPrice) {
            return `<span>${price}</span><span class="currency">,-</span>`;
            // const priceNumber = parseFloat(price);
            // let formattedNumber = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(priceNumber);
            // return `<span>${formattedNumber}</span>`;
        }
        return '';
    }

    isChangeDay(date: Date, state1: string, state2: string, type: ExtraVacancyTypeEnum = null): boolean {
        const dayState = this.getVacancyForDate(date, type); // Y
        const previousDate = subDays(date, 1);
        let previousDayState = this.getVacancyForDate(previousDate, type); // N

        if (!previousDayState) {
            previousDayState = ExtendedVacancy.FILL_UNDEFINED_DAYS_WITH;
        }

        return dayState !== null && dayState === state1 && previousDayState === state2;
    }

    isArrivalDay(date: Date): boolean {
        return this.isChangeDay(date, 'N', 'Y');
    }

    isDepartureDay(date: Date): boolean {
        return this.isChangeDay(date, 'Y', 'N');
    }

    isBlockedDayChangeStart(date: Date): boolean {
        return this.isChangeDay(date, 'C', 'Y');
    }

    isBlockedDayChangeEnd(date: Date): boolean {
        // closed > free or closed > occupied
        return this.isChangeDay(date, 'Y', 'C') || this.isChangeDay(date, 'N', 'C');
    }

    isBookingStart(date: Date): boolean {
        return this.isChangeDay(date, 'N', ' ', ExtraVacancyTypeEnum.Bookings) || this.isChangeDay(date, 'N', 'Y', ExtraVacancyTypeEnum.Bookings);
    }

    isBookingEnd(date: Date): boolean {
        return this.isChangeDay(date, ' ', 'N', ExtraVacancyTypeEnum.Bookings) || this.isChangeDay(date, 'Y', 'N', ExtraVacancyTypeEnum.Bookings);
    }

    isBookedDay(date: Date): boolean {
        const dayState = this.getVacancyForDate(date, 'bookings');
        return dayState === 'N';
    }

    setVacancyForDateRange(state: string, startDate: Date, endDate: Date, type: 'originalVacancy' | 'vacancyString' = 'originalVacancy'): ExtendedVacancy {
        if (state.length !== 1) {
            throw new RangeError('State muss exakt ein Zeichen lang sein! Aufgerufen mit State: ' + state);
        }

        if (this.originalVacancy === null) {
            this.originalVacancy = '';
        }

        if (type === 'vacancyString' && (this[type] === undefined || this[type] === null)) {
            this.vacancyString = this.originalVacancy;
        }

        const modifiedEndDateForVacancyString = subDays(endDate, 1);
        const startPosition = this.getStringPositionForDate(startDate);
        let endPosition = this.getStringPositionForDate(modifiedEndDateForVacancyString);
        let length = differenceInDays(modifiedEndDateForVacancyString, startDate);

        // Wenn nur ein Tag ausgewählt ist, stimmt die Logik nicht
        if (length === -1) {
            length = 0;
            endPosition++;
        }

        if (this[type].length < endPosition) {
            this[type] += ExtendedVacancy.FILL_UNDEFINED_DAYS_WITH.repeat(endPosition - this[type].length);
        }

        const newVacancyString = this[type].substring(0, startPosition) + state.repeat(length + 1) + this[type].substring(endPosition + 1);
        this[type] = newVacancyString;

        return this;
    }

    getStringPositionForDate(date: Date) {
        if (date < DateService.getDateFromString(this.offsetDate)) {
            throw new RangeError(`Datum muss nach dem Startdatum liegen. Datum: ${date}, Startdatum: ${this.offsetDate}`);
        }

        const difference = differenceInDays(date, DateService.getDateFromString(this.offsetDate));
        return difference;
    }

    getVacancy() {
        return this.vacancyString;
    }

    getStartDate() {
        return this.offsetDate;
    }
}
