import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { ApiConnectorService } from '../api-connector/api-connector.service';
import { AccommodationUpdateService } from '../accommodation-update.service';

@Injectable({
    providedIn: 'root',
})
export class AccommodationCountService implements OnDestroy {
    accommodationCountChange: Subject<number> = new Subject<number>();
    accommodationCount: number;
    accommodationCountSubscription: Subscription;

    constructor(readonly authenticationService: AuthenticationService, readonly apiConnectorService: ApiConnectorService, readonly accommodationUpdateService: AccommodationUpdateService) {
        this.accommodationCountSubscription = this.accommodationUpdateService.getUpdatedMessage().subscribe(async (updatedAccommodation) => {
            await this.getAccommodationCount();
        });
    }

    async getAccommodationCount(isNewAccommodation: boolean = true) {
        await this.apiConnectorService.getAccommodationCount(this.authenticationService.currentUser, isNewAccommodation).then((accommodationCount) => {
            this.accommodationCount = accommodationCount;
        });

        this.accommodationCountChange.next(this.accommodationCount);

        return this.accommodationCount;
    }

    ngOnDestroy() {
        this.accommodationCountSubscription?.unsubscribe();
    }
}
