export class Feature {

    constructor(featureId: string, value: any, valid: {} = {}) {
        this.featureId = featureId;
        this.value = value;
        this.valid = valid;
    }

    featureId: string;
    valid: {};
    value: any;
    building: number;
    floor: number;
    roomId: number;
    descriptions: {};

}
