import { Component } from '@angular/core';

@Component({
    selector: 'app-photos-guide',
    templateUrl: './photos-guide.component.html',
    styleUrl: './photos-guide.component.scss',
})
export class PhotosGuideComponent {
    isCollapsed: boolean = true;
}
