import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '../../../../services/dialog.service';
import { ExtendedAdditionalCost } from '../../../../entities/extended-addition-cost';
import { ExtendedAccommodationEntity } from '../../../../entities/extendedAccommodationEntity';
import { ExtendedAdditionalCostTemplateEntity } from '../../../../entities/extended-additional-cost-template-entity';
import { ExtendedAdditionalCostDefinitionEntity } from '../../../../entities/extended-additional-cost-definition-entity';

@Component({
    selector: 'app-additional-cost-dialog',
    templateUrl: './additional-cost-dialog.component.html',
    styleUrl: './additional-cost-dialog.component.scss',
})
export class AdditionalCostDialogComponent {
    currentAdditionalCostIsNew: boolean = false;
    currentAdditionalCost: ExtendedAdditionalCost;
    accommodationOrTemplate: ExtendedAccommodationEntity | ExtendedAdditionalCostTemplateEntity;
    idPrefix: string = '';
    additionalCostDefinitions: { [id: string]: ExtendedAdditionalCostDefinitionEntity } = {};
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, readonly dialogService: DialogService) {
        this.currentAdditionalCostIsNew = data.currentAdditionalCostIsNew;
        this.currentAdditionalCost = data.currentAdditionalCost;
        this.accommodationOrTemplate = data.accommodationOrTemplate;
        this.idPrefix = data.idPrefix;
        this.additionalCostDefinitions = data.additionalCostDefinitions;
    }

    save($event) {
        this.data.saveAdditionalCost($event);
    }

    closeDialog() {
        this.dialogService.closeDialog();
    }
}
