import { Component, ElementRef, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { ConstantsService } from '../../../../../services/constants/constants.service';
import { OwnerConnectorService } from '../../../../../services/api-connector/owner-connector.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageCountService } from '../../../../../services/messages/message-count.service';
import { ErrorCountService } from '../../../../../services/accommodation/error-count.service';
import { AccommodationRatingService } from '../../../../../services/accommodation/accommodation-rating.service';
import { AccommodationCountService } from '../../../../../services/accommodation/accommodation-count.service';
import { cloneDeep } from 'lodash';
import { NotificationService } from '../../../../../services/notification/notification.service';
import { ContactDialogComponent } from '../../../../global/dialogs/contact-dialog/contact-dialog.component';
import { DialogService } from '../../../../../services/dialog.service';

export interface NewOwnerParams {
    mandant: string;
    destinationPools: number[];
}

@Component({
    selector: 'app-owner-list',
    templateUrl: './owner-list.component.html',
    styleUrl: './owner-list.component.scss',
})
export class OwnerListComponent implements OnInit, OnChanges {
    @ViewChild('registrationForm') registrationForm: ElementRef;

    owners = [];
    filteredOwners = [];
    mainUserData: any;
    mainUserID: number = 0;
    poolsToUse: number[] = [];
    searchForm: FormGroup;
    noUserChangePossible = false;

    newOwnerParams: NewOwnerParams;

    constructor(
        readonly apiConnectorService: ApiConnectorService,
        readonly authService: AuthenticationService,
        readonly ownerConnector: OwnerConnectorService,
        readonly router: Router,
        readonly messageCountService: MessageCountService,
        readonly errorCountService: ErrorCountService,
        readonly accommodationRatingService: AccommodationRatingService,
        readonly accommodationCountService: AccommodationCountService,
        readonly notificationService: NotificationService,
        readonly dialogService: DialogService,
    ) {
        this.searchForm = new FormGroup({
            searchString: new FormControl(''),
        });
        this.searchForm.get('searchString').valueChanges.subscribe((value) => {
            this.filterOwners(value);
        });
    }
    ngOnInit() {
        this.ngOnChanges();
    }

    ngOnChanges() {
        this.mainUserData = this.authService.getDecodedToken();
        this.loadOwner();
    }
    /*
     * Lade alle Vermieter, die der aktuellen Destination zugewiesen sind
     * */
    async loadOwner() {
        // Prüfe welche UserID und wessen Pools wir nutzen müssen
        this.poolsToUse = this.authService.currentUser.destinationPools;
        this.setMainUserID(this.authService.currentUser.ownerNumber);
        if (this.mainUserData?.originalUser && this.mainUserData.role === ConstantsService.ROLE_DESTINATION && this.mainUserData.originalUser !== this.authService.currentUser.ownerNumber) {
            const responsePools: any = await this.ownerConnector.getDestinationPools(this.mainUserData.originalUser);
            if (responsePools) {
                this.poolsToUse = responsePools.map((pool) => pool.poolId);
            }
        }

        this.apiConnectorService.loadDestinationOwnerList(this.poolsToUse).subscribe(
            (response) => {
                this.owners = this.extractContacts(response);
                this.filterOwners();
                this.setNewOwnerParams();
            },
            (error) => {
                console.error('Error fetching owners:', error);
            },
        );
    }

    /**
     *
     * Hole die Kontaktdaten der Vermieter des Primary-Kontaktes und ergänze ein Suchfeld
     */
    extractContacts(response) {
        const ownerData = [];
        response.forEach((item) => {
            const primaryId = item.addressBook.primaryId;
            item.addressBook = item.addressBook.contacts[primaryId];
            item.addressBook.search =
                item.addressBook?.forename +
                ';' +
                item.addressBook?.surname +
                ';' +
                item.addressBook?.street +
                ';' +
                item.addressBook?.zipCode +
                ';' +
                item.addressBook?.city +
                ';' +
                item.addressBook?.email;
            ownerData.push(item);
        });
        return ownerData;
    }

    addOwner() {
        this.dialogService.openDialog(
            ContactDialogComponent,
            { dialogWidth: this.dialogService.dialogWidth.L },
            {
                newUser: true,
                isUserProfile: true,
                newOwnerParams: this.newOwnerParams,
                ownerWithPoolSaved: () => this.newOwnerWasSaved(),
                reloadOwner: () => this.ngOnChanges(),
            },
        );
    }

    newOwnerWasSaved() {
        this.loadOwner();
    }

    async changeUser(ownerNumber) {
        try {
            this.setMainUserID(ownerNumber);
            await this.authService.loadAndChangeToUser(ownerNumber);
            await this.router.navigate(['/profile']);

            // Reset für die Navigation
            const promises = [];
            promises.push(this.errorCountService.getAccommodationErrorCount());
            promises.push(this.messageCountService.getMessagesCount());
            promises.push(this.accommodationRatingService.getAccommodationUnreadRatingCount());
            await Promise.all(promises);
        } catch (error) {
            await this.notificationService.add('atraveo.destination.ownerlist.changeowner.error', 'danger');
        }
    }

    setMainUserID(ownerNumber) {
        this.mainUserID = ownerNumber === this.mainUserData?.originalUser ? 0 : this.mainUserData?.originalUser;
        if (this.mainUserData?.role !== ConstantsService.ROLE_DESTINATION) {
            this.noUserChangePossible = true;
        }
    }

    filterOwners(searchValue = '') {
        this.filteredOwners = this.owners.filter((owner) => {
            return owner.addressBook.search.toLowerCase().includes(searchValue.toLowerCase());
        });
        this.sortOwners();
    }

    sortOwners() {
        this.filteredOwners.sort((a, b) => {
            if (a.addressBook.surname < b.addressBook.surname) {
                return -1;
            }
            if (a.addressBook.surname > b.addressBook.surname) {
                return 1;
            }
            return 0;
        });
    }

    setNewOwnerParams() {
        this.newOwnerParams = {
            mandant: this.authService.currentUser.settings.client.toString(),
            destinationPools: cloneDeep(this.poolsToUse),
        };
    }
}
