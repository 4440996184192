<div class="panel">
    <div class="panel-heading">
        <h2 class="panel-title">{{ 'atraveo.dashboardbundle.panel.heading.quick.access.0' | translate }}</h2>
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="col-md-4 quickaccess-element">
                <a routerLink="/accommodation/register" title="{{ 'atraveo.dashboardbundle.panel.heading.quick.access.newobject.info' | translate }}">
                    <div class="quickaccess-icons">
                        <i class="fa fa-home quickaccess-icon quickaccess-icon-large"></i>
                        <i class="fa fa-plus quickaccess-icon quickaccess-icon-small"></i>
                    </div>
                    <div class="quickaccess-text">
                        {{ 'atraveo.dashboardbundle.panel.heading.quick.access.newobject.0' | translate }}
                    </div>
                </a>
            </div>
            <div class="col-md-4 quickaccess-element" *ngIf="firstActiveAccommodation">
                <a routerLink="/accommodation/{{ firstActiveAccommodation }}/prices"
                   title="{{ 'atraveo.dashboardbundle.panel.heading.quick.access.price.info' | translate }}">
                    <div class="quickaccess-icons">
                        <i class="fa fa-euro-sign quickaccess-icon quickaccess-icon-large"></i>
                        <i class="fa fa-pencil-alt quickaccess-icon quickaccess-icon-small"></i>
                    </div>
                    <div class="quickaccess-text">
                        {{ 'atraveo.dashboardbundle.panel.heading.quick.access.price.0' | translate }}
                    </div>
                </a>
            </div>
            <div class="col-md-4 quickaccess-element" *ngIf="firstActiveAccommodation">
                <a routerLink="/accommodation/{{ firstActiveAccommodation }}/calendar"
                   title="{{ 'atraveo.dashboardbundle.panel.heading.quick.access.bookingcalendar.info' | translate }}">
                    <div class="quickaccess-icons">
                        <i class="fa fa-calendar quickaccess-icon quickaccess-icon-large"></i>
                        <i class="fa fa-pencil-alt quickaccess-icon quickaccess-icon-small"></i>
                    </div>
                    <div class="quickaccess-text">
                        {{ 'atraveo.dashboardbundle.panel.heading.quick.access.bookingcalendar.0' | translate }}
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
