import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BackOfficeConnectorService } from '../../../../services/api-connector/back-office-connector-service';
import { TermsDto } from '../../../../entities/terms-dto';
import { OwnerConnectorService } from '../../../../services/api-connector/owner-connector.service';
import { OwnerTermsOfServiceSettingsDto } from 'data-structures/lib/es6/dto/owner/put-owner/owner-terms-of-service-settings.dto';
import * as Sentry from '@sentry/angular-ivy';
import { NotificationService } from '../../../../services/notification/notification.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageEnum } from 'data-structures/lib/es6/enum/language.enum';

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit, OnDestroy {
    terms: TermsDto;
    translationServiceSubscription: Subscription;

    constructor(
        readonly translateService: TranslateService,
        readonly backofficeConnector: BackOfficeConnectorService,
        readonly authenticationService: AuthenticationService,
        readonly ownerConnector: OwnerConnectorService,
        readonly notificationService: NotificationService,
        readonly router: Router,
    ) {}

    async ngOnInit(): Promise<void> {
        if (!this.authenticationService.currentUser?.termsOfServiceSettings?.acceptedId || this.router.url === '/terms') {
            await this.getTerms();

            this.translationServiceSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
                await this.getTerms();
            });
        }
    }

    ngOnDestroy(): void {
        this.translationServiceSubscription?.unsubscribe();
    }

    private async getTerms(): Promise<void> {
        if (this.authenticationService.currentUser.ownerNumber) {
            let lang = this.translateService.currentLang as LanguageEnum;
            if (
                ![LanguageEnum.German, LanguageEnum.English, LanguageEnum.French, LanguageEnum.Dutch, LanguageEnum.Italian, LanguageEnum.Spanish, LanguageEnum.Polish, LanguageEnum.Croatian].includes(
                    lang,
                )
            ) {
                lang = LanguageEnum.English;
            }
            this.terms = await this.backofficeConnector.getTerms(this.authenticationService.currentUser.ownerNumber, lang);
        }
    }

    async acceptTerms() {
        try {
            if (!this.authenticationService.currentUser.termsOfServiceSettings) {
                this.authenticationService.currentUser.termsOfServiceSettings = new OwnerTermsOfServiceSettingsDto();
            }

            this.authenticationService.currentUser.termsOfServiceSettings.acceptedId = Number(this.terms.data.useAgbId);
            this.authenticationService.currentUser.termsOfServiceSettings.acceptDateTime = new Date();

            await this.authenticationService.saveOwner(this.authenticationService.currentUser);

            await this.notificationService.add('form.save.success', 'success');
        } catch (e) {
            await this.notificationService.add('form.save.failure', 'danger');
            Sentry.captureException(e);
        }
    }
}
