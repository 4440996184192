import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { ExtendedUserEntity } from '../../entities/extendedUserEntity';
import { ComponentsEnum as FindBookingComponents } from 'data-structures/lib/es6/enum/components.enum';
import { classToPlain, instanceToPlain, plainToInstance } from 'class-transformer';
import { CacheService } from '../cache/cache.service';
import { BookingEntity } from 'data-structures/lib/es6/entity/booking.entity';
import { FindBookingDto } from 'data-structures/lib/es6/dto/find-booking/find-booking.dto';
import { FilterDto } from 'data-structures/lib/es6/dto/find-booking/filter.dto';
import { OrderByDto } from 'data-structures/lib/es6/dto/find-booking/order-by.dto';
import { SortableComponentsEnum } from 'data-structures/lib/es6/enum/order-by/sortable-components.enum';
import { DirectionEnum } from 'data-structures/lib/es6/enum/order-by/direction.enum';
import { StatusEnum } from 'data-structures/lib/es6/enum/status.enum';
import { ExportRequestDto } from 'data-structures/lib/es6/dto/export-request.dto';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map } from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';
import { NotificationService } from '../notification/notification.service';
import { format } from 'date-fns';
import { SetBookingFreeDto } from 'data-structures/lib/es6/dto/set-booking-free.dto';
import { OwnerChangeStatusEnum } from 'data-structures/lib/es6/enum/owner-change-status.enum';
import { FindBookingResultsDto } from 'data-structures/lib/es6/dto/find-booking/find-booking-results.dto';
import { Subscription, throwError } from 'rxjs';

// tslint:disable-next-line:max-classes-per-file
@Injectable({
    providedIn: 'root',
})
export class BookingConnectorService implements OnDestroy {
    baseUrl = environment.bookingConnectorUrl;
    downloadFileSubscription: Subscription;

    constructor(
        readonly httpClient: HttpClient,
        readonly authenticationService: AuthenticationService,
        readonly apiCache: CacheService,
        readonly translationService: TranslateService,
        readonly notificationService: NotificationService,
    ) {}

    ngOnDestroy() {
        this.downloadFileSubscription?.unsubscribe();
    }

    private getHeaders(): HttpHeaders {
        return new HttpHeaders({
            'content-type': 'application/json',
        });
    }

    async findBookings(ownerNumber: number, findBookingDto: FindBookingDto): Promise<FindBookingResultsDto> {
        const apiResult: any = await this.httpClient.post(this.baseUrl + '/booking/find', instanceToPlain(findBookingDto)).toPromise();
        const result: FindBookingResultsDto = new FindBookingResultsDto();
        result.count = apiResult.count;
        result.result = apiResult.result.map((booking) => plainToInstance(BookingEntity, booking));

        return result;
    }

    async getBookingById(ownerNumber: number, id: number): Promise<BookingEntity> | undefined {
        const findBookingDto = new FindBookingDto();
        findBookingDto.filter = new FilterDto();
        findBookingDto.filter.ownerNumber = ownerNumber;
        findBookingDto.filter.bookingId = Number(id);

        const result = await this.findBookings(ownerNumber, findBookingDto);

        if (result.result?.length) {
            return result.result[0];
        }
    }

    async getBookingsByOwnerNumber(user: ExtendedUserEntity, components: FindBookingComponents[] = []): Promise<FindBookingResultsDto> {
        if (!user) {
            return null;
        }

        const findBookingDto = new FindBookingDto();
        findBookingDto.filter = new FilterDto();
        findBookingDto.filter.ownerNumber = user.ownerNumber;

        if (components.length) {
            findBookingDto.components = components;
        }

        return this.findBookings(user.ownerNumber, findBookingDto);
    }

    async getNextTravels(user: ExtendedUserEntity): Promise<FindBookingResultsDto> {
        if (!user) {
            return null;
        }

        const apiResult: any = await this.httpClient.get(this.baseUrl + '/booking/getNextTravels/' + user.ownerNumber, { headers: this.getHeaders() }).toPromise();
        const result: FindBookingResultsDto = new FindBookingResultsDto();
        result.count = apiResult.length;
        result.result = apiResult.map((booking) => plainToInstance(BookingEntity, booking));

        return result;
    }

    async saveBooking(booking: BookingEntity) {
        try {
            // @ts-ignore
            delete booking.setVacancy; // Keine Ahnung wo das herkommt, hab ganz VRMB und Inventory nach setVacancy durchsucht und nichts gefunden

            await this.httpClient.post(this.baseUrl + '/booking', classToPlain(booking)).toPromise();
            await this.notificationService.add('form.save.success', 'success');
        } catch (e) {
            if (e.includes('BookingError1')) {
                const words = e.split(' ');
                const bookingNumber = words[words.length - 1];
                let text = await this.translationService.get('atraveo.accommodationbundle.calendar.info.blocked.by.booking').toPromise();
                text = text.replace('%s', bookingNumber);
                await this.notificationService.add(text);
                return;
            }

            if (e.includes('BookingError2')) {
                await this.notificationService.add('atraveo.accommodationbundle.calendar.info.blocked.in.calendar');
                return;
            }

            await this.notificationService.add('form.save.failure', 'danger');
            Sentry.captureException(e);
        }
    }

    async downloadOverview(type: 'pdf' | 'csv', findBookingDto: FindBookingDto) {
        const url = '/export';
        let exportRequestDto = new ExportRequestDto();
        exportRequestDto.filter = new FilterDto();
        // @ts-ignore
        exportRequestDto.language = this.translationService.currentLang;
        exportRequestDto.filter.ownerNumber = this.authenticationService.currentUser.ownerNumber;
        exportRequestDto.orderBy = [];
        const order = new OrderByDto();
        order.component = SortableComponentsEnum.ArrivalDate;
        order.direction = DirectionEnum.Ascending;
        exportRequestDto.orderBy.push(order);
        // @ts-ignore
        exportRequestDto.exportType = type;
        exportRequestDto.take = 9999999;
        exportRequestDto.includeCount = true;
        exportRequestDto = Object.assign({}, exportRequestDto, findBookingDto);

        this.downloadFileSubscription = this.downloadFile(url, type, exportRequestDto).subscribe((res) => {
            const fileURL = URL.createObjectURL(res);

            // Link erstellen damit man die Datei richtig benennen kann
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = fileURL;
            a.download = 'bookings.' + type;
            a.click();
            a.remove();
            URL.revokeObjectURL(fileURL);
        });
    }

    downloadFile(url, type, dto, method = 'post') {
        const types = {
            pdf: 'application/pdf',
            csv: 'text/csv',
        };

        if (method.toLowerCase() === 'post') {
            return this.httpClient.post(this.baseUrl + url, dto, { responseType: 'blob' }).pipe(
                map((res) => {
                    // @ts-ignore
                    return new Blob([res], { type: types[type] });
                }),
                catchError((err) => {
                    this.notificationService.add('text.booking.download.maximum.size.exceeded', 'danger');
                    Sentry.captureException(err);
                    return throwError(err);
                }),
            );
        } else if (method.toLowerCase() === 'get') {
            return this.httpClient.get(this.baseUrl + url, { responseType: 'blob' }).pipe(
                map((res) => {
                    // @ts-ignore
                    return new Blob([res], { type: types[type] });
                }),
                catchError((err) => {
                    this.notificationService.add('text.booking.download.maximum.size.exceeded', 'danger');
                    Sentry.captureException(err);
                    return throwError(err);
                }),
            );
        }
    }

    async deleteBooking(id: number) {
        try {
            const url = `/booking/owner/${this.authenticationService.currentUser.ownerNumber.toString()}/${id}`;
            await this.httpClient.delete(this.baseUrl + url).toPromise();
            await this.notificationService.add('form.save.success', 'success');
        } catch (e) {
            await this.notificationService.add('form.save.failure', 'danger');
            Sentry.captureException(e);
        }
    }

    downloadConfirmation(ownerNumber: number, bookingNumber: number, currentLang: string): void {
        const url = `/export/owner/${ownerNumber.toString()}/confirmation/${bookingNumber.toString()}/${currentLang}`;
        this.downloadFileSubscription = this.downloadFile(url, 'pdf', null, 'get').subscribe((res) => {
            const fileURL = URL.createObjectURL(res);
            this.createDownLoadLink(fileURL, `confirmation-${bookingNumber}.pdf`);
        });
    }

    downloadBookingDetails(ownerNumber: number, bookingNumber: number, currentLang: string): void {
        const url = `/export/owner/${ownerNumber.toString()}/details/${bookingNumber.toString()}/${currentLang}`;
        this.downloadFileSubscription = this.downloadFile(url, 'pdf', null, 'get').subscribe((res) => {
            const fileURL = URL.createObjectURL(res);
            this.createDownLoadLink(fileURL, `details-${bookingNumber}.pdf`);
        });
    }

    createDownLoadLink(fileUrl: string, fileName: string) {
        // Link erstellen damit man die Datei richtig benennen kann
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = fileUrl;
        a.download = fileName;
        a.click();
        a.remove();
        URL.revokeObjectURL(fileUrl);
    }

    async freeBooking(bookingId: number, ownerNumber: number) {
        try {
            const dto = new SetBookingFreeDto();
            dto.bookingId = bookingId;
            dto.ownerNumber = ownerNumber;
            dto.newStatus = OwnerChangeStatusEnum.DeclinedButFree;

            const url = this.baseUrl + '/booking/set-declined-but-free';
            const result = await this.httpClient.post(url, dto).toPromise();
            await this.notificationService.add('form.save.success', 'success');
            return result;
        } catch (e) {
            Sentry.captureException(e);
            await this.notificationService.add('form.save.failure', 'danger');
        }
    }
}
