import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageRequestDto } from '../../../../entities/Messages/messageRequestDto';
import { BookingEntity } from 'data-structures/lib/es6/entity/booking.entity';
import { DialogService } from '../../../../services/dialog.service';

@Component({
    selector: 'app-messages-filter-dialog',
    templateUrl: './messages-filter-dialog.component.html',
    styleUrl: './messages-filter-dialog.component.scss',
})
export class MessagesFilterDialogComponent {
    bookings: BookingEntity[];
    messageRequest: MessageRequestDto;
    accountIds: {
        ownerService: string;
        customerService: string;
        customerRatings: string;
    };
    constructor(readonly dialogService: DialogService, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.bookings = data.bookings;
        this.messageRequest = data.messageRequest;
        this.accountIds = data.accountIds;
    }
    resetMessagesFilter() {
        this.data.reset();
    }

    filterMessages() {
        this.data.filter();
    }
    closeDialog() {
        this.dialogService.closeDialog();
    }
}
