import { Component, EventEmitter, Input, OnChanges, Output, Renderer2, ViewChild } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../../entities/extendedAccommodationEntity';
import { ApiConnectorService } from '../../../../../../services/api-connector/api-connector.service';
import { TranslateService } from '@ngx-translate/core';
import { ExtendedAdditionalCostDefinitionEntity } from '../../../../../../entities/extended-additional-cost-definition-entity';
import { AdditionalCostSource, ExtendedAdditionalCost } from '../../../../../../entities/extended-addition-cost';
import { ExtendedAdditionalCostTemplateEntity } from '../../../../../../entities/extended-additional-cost-template-entity';
import { AuthenticationService } from '../../../../../../services/authentication/authentication.service';
import { FromToDateDto } from 'data-structures/lib/es6/dto/accommodation/additional-cost/from-to-date.dto';
import { MinMaxDto } from 'data-structures/lib/es6/dto/accommodation/additional-cost/min-max.dto';
import { ConfirmationDialogService } from '../../../../../global/confirmation-dialog/confirmation-dialog.service';
import { CreateComponent } from './create/create.component';
import { DateService } from '../../../../../../services/date/date.service';
import { cloneDeep } from 'lodash';
import { DialogService } from '../../../../../../services/dialog.service';
import { AdditionalCostDialogComponent } from '../../../../../global/dialogs/additional-cost-dialog/additional-cost-dialog.component';

@Component({
    selector: 'app-additional-cost',
    templateUrl: './additional-cost.component.html',
    styleUrls: ['./additional-cost.component.scss'],
})
export class AdditionalCostComponent implements OnChanges {
    @Input() accommodationOrTemplateInput: ExtendedAccommodationEntity | ExtendedAdditionalCostTemplateEntity;
    @Input() idPrefix: string = '';
    @Input() includeUpperButton: boolean = false;
    @Output() onSave: EventEmitter<ExtendedAccommodationEntity | ExtendedAdditionalCostTemplateEntity> = new EventEmitter<ExtendedAccommodationEntity | ExtendedAdditionalCostTemplateEntity>();
    accommodationOrTemplate: ExtendedAccommodationEntity | ExtendedAdditionalCostTemplateEntity;
    mandatoryAdditionalCostKeys: string[] = ['BETTWAESCH', 'ENDREIN', 'ENERGIE', 'HANDTUCH', 'KURTAXE', 'KAUTION'];
    mandatoryOptionalAdditionalCostKeys: string[] = ['BETTWAESCH', 'ENDREIN', 'ENERGIE', 'HANDTUCH'];
    @ViewChild(CreateComponent) createComponent: CreateComponent;

    currentAdditionalCost: ExtendedAdditionalCost;
    currentAdditionalCostIsNew: boolean = false;

    additionalCostDefinitions: { [id: string]: ExtendedAdditionalCostDefinitionEntity } = {};
    dataReady: boolean = false;
    modalOpenDateTime: Date;
    deleteMultipleInProgress: boolean = false;
    idsToDelete: number[] = [];
    deleteMultipleTranslation: string;

    errors = {};

    constructor(
        readonly apiConnector: ApiConnectorService,
        readonly authenticationService: AuthenticationService,
        readonly translationService: TranslateService,
        readonly renderer: Renderer2,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly dateService: DateService,
        readonly dialogService: DialogService,
    ) {}

    async ngOnChanges() {
        // Durch die Änderungen an den Tabs wird das hier aufgerufen bevor die Daten da sind
        if (!this.accommodationOrTemplateInput) {
            return;
        }

        this.accommodationOrTemplate = cloneDeep(this.accommodationOrTemplateInput);
        this.dataReady = false;
        if (Object.keys(this.accommodationOrTemplate)) {
            this.accommodationOrTemplate.orderAdditionalCostsByObligation();
            if (!this.additionalCostDefinitions.length) {
                this.additionalCostDefinitions = await this.apiConnector.getAdditionalCostDefinitions();
            }

            if (!this.accommodationOrTemplate.additionalCosts) {
                this.accommodationOrTemplate.additionalCosts = [];
            }

            if (this.accommodationOrTemplate instanceof ExtendedAccommodationEntity) {
                this.addMissingMandatoryCostsToAccommodation();
                this.addMandatoryPropertytoDefinitions();
                this.errors = await this.getErrors();
            }

            this.dataReady = true;
        }

        if (this.accommodationOrTemplate.additionalCosts?.length) {
            for (const additionalCost of this.accommodationOrTemplate.additionalCosts) {
                additionalCost.deletable = true;

                if (this.mandatoryAdditionalCostKeys.includes(additionalCost.additionalCost) && !('additionalCostTemplateId' in this.accommodationOrTemplate)) {
                    additionalCost.deletable = false;

                    if (additionalCost.additionalCost === 'KURTAXE') {
                        if (this.getAdditionalCostsByKey('KURTAXE').length > 1) {
                            additionalCost.deletable = true;
                        }
                    }
                }
            }
        }
    }

    async save(event) {
        await this.onSave.emit(event);
        this.currentAdditionalCost = null;
        this.dialogService.closeDialog();
    }

    getAdditionalCostByKey(key: string): ExtendedAdditionalCost | undefined {
        return this.accommodationOrTemplate.additionalCosts && this.accommodationOrTemplate.additionalCosts.find((cost) => cost.additionalCost === key);
    }

    getAdditionalCostsByKey(key: string): ExtendedAdditionalCost[] | undefined {
        return this.accommodationOrTemplate.additionalCosts && this.accommodationOrTemplate.additionalCosts.filter((cost) => cost.additionalCost === key);
    }

    addMandatoryPropertytoDefinitions(): void {
        if (!Object.keys(this.additionalCostDefinitions).length) {
            return;
        }

        for (const costKey of this.mandatoryAdditionalCostKeys) {
            this.additionalCostDefinitions[costKey].ownerHasToEdit = true;
        }
    }

    addMissingMandatoryCostsToAccommodation(): void {
        const hasLaundryPackage = this.getAdditionalCostsByKey('BETOWAESCH')?.length > 0;

        for (const costKey of this.mandatoryAdditionalCostKeys) {
            let cost: ExtendedAdditionalCost = this.getAdditionalCostByKey(costKey);
            if (!cost) {
                if (['HANDTUCH', 'BETTWAESCH'].includes(costKey) && hasLaundryPackage) {
                    continue;
                }
                cost = new ExtendedAdditionalCost();
                cost.additionalCost = costKey;
                cost.source = AdditionalCostSource.MANDATORY_COST_AUTO_CREATED;

                if (this.mandatoryOptionalAdditionalCostKeys.includes(costKey)) {
                    cost.obligation = 0;
                } else {
                    cost.obligation = 8;
                }

                if (this.authenticationService.currentUser?.settings?.client) {
                    cost.hasToBePaidLocal = true;
                }

                if (!this.accommodationOrTemplate.additionalCosts) {
                    this.accommodationOrTemplate.additionalCosts = [];
                }

                this.accommodationOrTemplate.additionalCosts.push(cost);
            } else {
                if (['HANDTUCH', 'BETTWAESCH'].includes(costKey) && hasLaundryPackage) {
                    if (cost.source === AdditionalCostSource.MANDATORY_COST_AUTO_CREATED || this.isUnmodifiedRequriedAddionalCost(cost)) {
                        this.accommodationOrTemplate.additionalCosts = this.accommodationOrTemplate.additionalCosts.filter((additionalCost) => additionalCost.id !== cost.id);
                    }
                }
            }
        }
    }

    async cancelDeleteMultiple() {
        const $deleteButton = $('#' + this.idPrefix + '-delete-multiple-additional-costs');
        const $tableRows = $('table.additional-costs tr');
        document.querySelectorAll('.row-required').forEach((rowElement) => {
            rowElement.classList.remove('disabled');
        });
        this.idsToDelete = [];
        $('#' + this.idPrefix + '-abort-delete-additional-costs').addClass('d-none');
        $('.delete-explanation').addClass('d-none');
        $tableRows.each((index, element) => {
            $(element).removeClass('delete').off();
        });
        $deleteButton.text(await this.translationService.get('atraveo.accommodationbundle.additionalCost.delete.deleteMultiple').toPromise());
        $deleteButton.removeClass('started');
        $('#collapse' + this.idPrefix + ' .additional-costs .fa').show();

        // Accoridion wieder freigeben
        $('.card-header').off('click');
    }

    async updateDeleteMultipleButton() {
        this.deleteMultipleTranslation = await this.translationService.get('atraveo.accommodationbundle.price.delete.confirmDeleteNumberOfPrices').toPromise();
        this.deleteMultipleTranslation = this.deleteMultipleTranslation.replace('%i', this.idsToDelete.length.toString());
        const $deleteButton = $('#' + this.idPrefix + '-delete-multiple-additional-costs');
        $deleteButton.text(this.deleteMultipleTranslation);
    }

    async deleteMultiple() {
        const $deleteButton = $('#' + this.idPrefix + '-delete-multiple-additional-costs');
        if ($deleteButton.hasClass('started')) {
            const $elementsToDelete = $('table tr.selected');
            const idsToDelete = [];
            $elementsToDelete.each((index, element) => {
                idsToDelete.push($(element).attr('data-id'));
            });
            this.accommodationOrTemplate.additionalCosts = this.accommodationOrTemplate.additionalCosts.filter((a) => !idsToDelete.includes(a.id));
            this.save(this.accommodationOrTemplate);
            this.cancelDeleteMultiple();
            this.idsToDelete = [];
        } else {
            document.querySelectorAll('.row-required').forEach((rowElement) => {
                rowElement.classList.add('disabled');
            });
            $('#' + this.idPrefix + '-abort-delete-additional-costs').removeClass('d-none');
            $('.delete-explanation').removeClass('d-none');
            $deleteButton.addClass('started');
            // Accordion sperren
            $('.card-header').on('click', () => {
                return false;
            });
            $('#collapse' + this.idPrefix + ' .additional-costs .fa').hide();
            const originalButtonText = await this.translationService.get('atraveo.accommodationbundle.price.delete.confirmDeleteNumberOfPrices').toPromise();
            let buttonText = originalButtonText.replace('%i', '0');
            $deleteButton.text(buttonText);

            $('table .selected').on('click', (event) => {
                const $target = $(event.target);

                if (!$target.hasClass('btn') && !$target.hasClass('fas') && !$(event.currentTarget).data('nondeleteable')) {
                    event.currentTarget.classList.toggle('delete');
                }
                const elementsToDelete = document.querySelectorAll('table .selected').length;
                buttonText = originalButtonText.replace('%i', elementsToDelete.toString());
                $deleteButton.text(buttonText);
            });
        }
    }
    addOrRemoveDeleteElement(id: number, event) {
        if (event.currentTarget.classList.contains('disabled')) {
            return;
        }
        this.deleteMultipleInProgress = true;
        if (this.deleteMultipleInProgress) {
            const $deleteButton = $('#' + this.idPrefix + '-delete-multiple-additional-costs');
            if ($deleteButton.hasClass('started')) {
                if (this.idsToDelete.includes(id)) {
                    const index = this.idsToDelete.indexOf(id);
                    if (index !== -2) {
                        this.idsToDelete.splice(index, 1);
                    }
                } else {
                    this.idsToDelete.push(id);
                }
                this.updateDeleteMultipleButton();
            }
        }
    }

    editAddtionalCost(additionalCost: ExtendedAdditionalCost) {
        this.modalOpenDateTime = new Date(); // ngChanges von CreateComponent triggern
        this.currentAdditionalCost = cloneDeep(additionalCost);
        this.initMandantory(this.currentAdditionalCost);
        this.currentAdditionalCostIsNew = false;
        if (this.currentAdditionalCost.source === AdditionalCostSource.MANDATORY_COST_AUTO_CREATED) {
            delete this.currentAdditionalCost.source;
        }
        this.openAdditionalCostDialog();
    }

    createAdditionalCost() {
        this.modalOpenDateTime = new Date(); // ngChanges von CreateComponent triggern
        this.currentAdditionalCost = new ExtendedAdditionalCost();
        this.initMandantory(this.currentAdditionalCost);
        this.currentAdditionalCostIsNew = true;
        this.openAdditionalCostDialog();
    }

    openAdditionalCostDialog() {
        this.dialogService.openDialog(
            AdditionalCostDialogComponent,
            { dialogWidth: this.dialogService.dialogWidth.M },
            {
                currentAdditionalCostIsNew: this.currentAdditionalCostIsNew,
                currentAdditionalCost: this.currentAdditionalCost,
                accommodationOrTemplate: this.accommodationOrTemplate,
                idPrefix: this.idPrefix,
                additionalCostDefinitions: this.additionalCostDefinitions,
                saveAdditionalCost: this.save.bind(this),
            },
        );
    }

    private initMandantory(currentAdditionalCost: ExtendedAdditionalCost) {
        currentAdditionalCost.currency = currentAdditionalCost.currency ?? this.authenticationService.currentUser.getOwnerCurrency();
        currentAdditionalCost.travelPeriod = currentAdditionalCost?.travelPeriod ?? new FromToDateDto();
        currentAdditionalCost.travelPeriod.from = currentAdditionalCost.travelPeriod.from ?? '';
        currentAdditionalCost.travelPeriod.to = currentAdditionalCost.travelPeriod.to ?? '';
        currentAdditionalCost.duration = currentAdditionalCost?.duration ?? new MinMaxDto();
        currentAdditionalCost.occupancy = currentAdditionalCost?.occupancy ?? new MinMaxDto();
        currentAdditionalCost.calcAges = currentAdditionalCost?.calcAges ?? new MinMaxDto();
        currentAdditionalCost.calcDays = currentAdditionalCost?.calcDays ?? new MinMaxDto();
        currentAdditionalCost.calcPersons = currentAdditionalCost?.calcPersons ?? new MinMaxDto();
    }

    openConfirmationDialog(additionalCost) {
        this.confirmationDialogService
            .confirm('atraveo.accommodationbundle.price.delete.confirmDeleteSinglePrice', 'confirm.content.delete.0')
            .then(async (confirmed) => {
                if (confirmed) {
                    this.accommodationOrTemplate.additionalCosts = this.accommodationOrTemplate.additionalCosts.filter((a) => {
                        return a.id !== additionalCost.id;
                    });
                    this.save(this.accommodationOrTemplate);
                }
            })
            .catch(() => undefined);
    }

    isUnmodifiedRequriedAddionalCost(additionalCost: ExtendedAdditionalCost) {
        // VRMB-3213: Bei importierten Objekten soll Pflichtfeld sofort angezeigt werden
        if (this.accommodationOrTemplate instanceof ExtendedAccommodationEntity && this.accommodationOrTemplate?.state?.imported) {
            return false;
        }
        // Autom. angelegte Nebenkosten haben nur den NKCode, Pflicht und ggf. vor Ort zu bezahlen bei Mandant
        // Nachdem speichern kommt mindestens die Währung dazu
        if (this.mandatoryAdditionalCostKeys.includes(additionalCost.additionalCost) && !additionalCost.currency) {
            const obligation = Number(additionalCost.obligation);

            if (this.mandatoryOptionalAdditionalCostKeys.includes(additionalCost.additionalCost) && obligation === 0) {
                return true;
            }
            if (!this.mandatoryOptionalAdditionalCostKeys.includes(additionalCost.additionalCost) && obligation === 8) {
                return true;
            }
        }

        return false;
    }

    async getErrors() {
        const result = {};

        if ('accommodationId' in this.accommodationOrTemplate) {
            for (const additionalCost of this.accommodationOrTemplate.additionalCosts) {
                const errors = this.accommodationOrTemplate.checkResults?.find((v) => v.additionalCostsAreNotValid)?.additionalCostsAreNotValid.find((v) => additionalCost.additionalCost in v);
                const error = errors && additionalCost.additionalCost in errors ? errors[additionalCost.additionalCost] : null;
                if (error) {
                    for (const key of Object.keys(error)) {
                        const currentValue = error[key].current;
                        let translationKey = currentValue;
                        switch (currentValue) {
                            case currentValue === true:
                                translationKey = '1';
                                break;
                            case currentValue === false:
                                translationKey = '0';
                                break;

                            default:
                                if (!currentValue) {
                                    if (['unit'].includes(key)) {
                                        translationKey = 'blank';
                                    } else {
                                        translationKey = '0';
                                    }
                                }
                        }

                        const valueTranslationKey = `atraveo.accommodationbundle.price.create.additionalcost.field.${key}.${translationKey}`;
                        let valueTranslated = await this.translationService.get(valueTranslationKey).toPromise();

                        if (valueTranslated === valueTranslationKey) {
                            valueTranslated = currentValue ?? '';
                        }

                        result[additionalCost.additionalCost] = result[additionalCost.additionalCost] ?? [];
                        result[additionalCost.additionalCost].push(
                            `<li>${await this.translationService.get(`atraveo.accommodationbundle.price.create.additionalcost.errors.${key}`, { current: valueTranslated }).toPromise()}</li>`,
                        );
                    }
                }
            }

            return result;
        }
    }
}
