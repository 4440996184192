import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../entities/extendedAccommodationEntity';
import { ExtendedUserEntity } from '../../../entities/extendedUserEntity';
import { StateDto } from 'data-structures/lib/es6/dto/accommodation';
import { ApiConnectorService } from '../../../services/api-connector/api-connector.service';
import { ProgressCheckService } from '../../../services/accommodation/check/progress-check.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';

@Component({
    selector: 'app-publication-request-btn',
    templateUrl: './publication-request-btn.component.html',
    styleUrls: ['./publication-request-btn.component.scss'],
})
export class PublicationRequestBtnComponent implements OnChanges, OnDestroy, OnInit {
    @Input() accommodation: ExtendedAccommodationEntity;
    @Input() owner: ExtendedUserEntity;
    @Output() onSetStates: EventEmitter<any> = new EventEmitter<any>();
    progressCheckSubscription: any = null;
    btnDisabled = false;
    constructor(readonly apiConnectorService: ApiConnectorService, readonly progressCheckService: ProgressCheckService, readonly authenticationService: AuthenticationService) {
        // Ich bin ziemlich sicher das gelöscht werden kann (inklusive Service), das Event wird nur beim speichern geworfen, und wenn ich es auskommentiere erscheint und verschwindet der Button trotzdem
        this.progressCheckSubscription = this.progressCheckService.getNewProgressState().subscribe(async () => {
            await this.ngOnChanges();
        });
    }

    ngOnInit() {
        this.btnDisabled = (!this.accommodation?.state?.valid && !this.accommodation?.state?.qualified) || !this.accommodation.accommodationId;
    }

    async ngOnChanges() {
        this.btnDisabled = (!this.accommodation?.state?.valid && !this.accommodation?.state?.qualified) || !this.accommodation.accommodationId;
    }

    async requestPublication(accommodation: ExtendedAccommodationEntity) {
        if (!accommodation?.state) {
            accommodation.state = new StateDto();
        }
        accommodation.state.publishingRequested = new Date();
        await this.apiConnectorService.saveAccommodation(accommodation);
        this.onSetStates.emit();
    }

    async ngOnDestroy() {
        if (this.progressCheckSubscription) {
            this.progressCheckSubscription.unsubscribe();
        }
    }
}
