import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogService } from '../components/global/confirmation-dialog/confirmation-dialog.service';
import { PendingChangesComponentService } from '../services/pending-changes-component/pending-changes-component.service';
import { first } from 'rxjs/operators';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements OnDestroy {
    message: string;
    ignoreGuard: boolean = false;
    translationServiceSubscription: Subscription;
    constructor(
        readonly translateService: TranslateService,
        private confirmationDialogService: ConfirmationDialogService,
        private router: Router,
        private pendingChangesComponentService: PendingChangesComponentService,
    ) {
        this.setTranslation();
    }

    async setTranslation() {
        this.message = await this.translateService.get('pendingChanges.warning.text').toPromise();

        this.translationServiceSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.message = await this.translateService.get('pendingChanges.warning.text').toPromise();
        });
    }

    ngOnDestroy() {
        this.translationServiceSubscription?.unsubscribe();
    }
    async canDeactivate(component: ComponentCanDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
        if (component && typeof component.canDeactivate === 'function') {
            const componentCanDeactivate = component.canDeactivate();
            if (componentCanDeactivate || this.ignoreGuard) {
                return true;
            } else {
                if (!this.message) {
                    await this.setTranslation();
                }

                try {
                    const destination = nextState ? nextState.url : this.router.url;
                    const saveChanges = await this.confirmationDialogService.confirm(
                        'pendingChanges.warning.title',
                        this.message,
                        'pendingChanges.warning.btn.save',
                        'pendingChanges.warning.btn.cancel',
                        'lg',
                        'btn-secondary',
                        'btn-danger',
                    );

                    if (saveChanges) {
                        this.pendingChangesComponentService.triggerSave();
                        const saveSuccessful = await this.pendingChangesComponentService.getSaveResponseObservable().pipe(first()).toPromise();
                        if (saveSuccessful) {
                            await this.router.navigateByUrl(destination);
                            return true;
                        }
                    } else {
                        // endlosschleife verhindern, indem wir den guard kurz ignorieren
                        this.ignoreGuard = true;
                        await this.router.navigateByUrl(destination);
                        this.ignoreGuard = false;
                    }
                } catch (error) {
                    // leerer catch, da saveChanges undefined ist, wenn der Dialog einfach geschlossen wird
                }
                return false;
            }
        }

        return true;
    }
}
