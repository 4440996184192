import { Component, OnChanges, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { TransferOwnerDataDto } from 'data-structures/lib/es6/dto/admin/transfer-owner-data.dto';
import { ActivatedRoute } from '@angular/router';
import { ApiConnectorService } from '../../../../services/api-connector/api-connector.service';
import { NotificationService } from '../../../../services/notification/notification.service';
import { ConstantsService } from '../../../../services/constants/constants.service';
import * as Sentry from '@sentry/angular-ivy';

@Component({
    selector: 'app-change-owner',
    templateUrl: './change-owner.component.html',
    styleUrls: ['./change-owner.component.scss'],
})
export class ChangeOwnerComponent implements OnInit, OnChanges {
    transferDto: TransferOwnerDataDto;
    isAdminOverall: boolean = false;

    constructor(readonly authService: AuthenticationService, readonly route: ActivatedRoute, readonly apiConnector: ApiConnectorService, readonly notificationService: NotificationService) {}

    ngOnInit(): void {
        this.transferDto = new TransferOwnerDataDto();
        this.transferDto.oldOwnerNumber = this.authService.currentUser.ownerNumber;
        this.checkUserStatus();

        this.route.params.subscribe((params) => {
            this.transferDto.accommodationId = Number(params.accommodationId);
        });
    }

    ngOnChanges(): void {
        this.transferDto.oldOwnerNumber = this.authService.currentUser.ownerNumber;
        this.checkUserStatus();
    }

    selectAccommodation($event: any) {
        this.transferDto.accommodationId = $event;
    }

    async startTransfer() {
        try {
            this.transferDto.newOwnerNumber = Number(this.transferDto.newOwnerNumber);
            await this.apiConnector.transferAccommodationToOtherOwner(this.transferDto);

            this.transferDto.ratings = false;
            this.transferDto.bookings = false;
            this.transferDto.newOwnerNumber = 0;
            await this.notificationService.add('form.save.success', 'success');
        } catch (e) {
            Sentry.captureException(e);
            await this.notificationService.add('form.save.failure', 'danger');
        }
    }

    private checkUserStatus() {
        this.isAdminOverall = this.authService.currentUser.roles.includes(ConstantsService.ROLE_ADMIN);
        if (!this.isAdminOverall) {
            this.transferDto.bookings = false;
            this.transferDto.ratings = false;
        }
    }
}
