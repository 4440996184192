import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { NotificationService } from '../notification/notification.service';
import { ExtendedUserEntity } from '../../entities/extendedUserEntity';
import { cloneDeep } from 'lodash';

export class RegisterUserAnswerDto {
    ownerNumber: number;
    accessToken: string;
    changePasswordToken: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthenticationConnectorService {
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
    };

    constructor(readonly httpClient: HttpClient, readonly notificationService: NotificationService) {}

    async changePasswordByToken(newPassword: string, token: string) {
        const url = 'user/save/password/owner/by-token';
        const body = {
            changeDataToken: token,
            newPassword,
        };

        await this.httpClient.patch<any>(environment.authServiceUrl + url, body, { observe: 'response' }).toPromise();
    }

    async changePassword(ownerNumber: number, newPassword: string, currentPassword: string) {
        const url = 'user/save/password/owner/';
        const body = {
            ownerNumber: ownerNumber,
            newPassword: newPassword,
            currentPassword: currentPassword,
        };

        await this.httpClient.patch<any>(environment.authServiceUrl + url, body, { observe: 'response' }).toPromise();
    }

    async getLastLoginDate(userName: string) {
        const url = 'query/last-login/';
        const body = {
            username: userName,
            userType: 'owner',
        };

        const response: any = await this.httpClient.put<any>(environment.authServiceUrl + url, body).toPromise();
        return response;
    }

    async registerUser(email: string, user: ExtendedUserEntity): Promise<RegisterUserAnswerDto> {
        const url = 'owner/create';
        const userCopy = cloneDeep(user);
        delete userCopy.roles;

        const body = {
            emailAddress: email,
            payload: userCopy,
        };

        const response: RegisterUserAnswerDto = await this.httpClient.post<any>(environment.authServiceUrl + url, body).toPromise();
        return response;
    }

    async newPasswordRequest(userData: string) {
        if (userData === '') {
            await this.notificationService.add('text.validation.pleasecorrect', 'danger');
            return;
        }

        const url = 'user/request/password/owner';
        let body = {};

        if (userData.includes('@')) {
            body = {
                emailAddress: userData,
            };
        } else {
            body = {
                ownerNumber: Number(userData),
            };
        }

        try {
            await this.httpClient.patch<any>(environment.authServiceUrl + url, body, { observe: 'response' }).toPromise();
            await this.notificationService.add('text.requestPassword.Success', 'success');
            return true;
        } catch (e) {
            await this.notificationService.add('text.requestPassword.error.NotFound', 'danger');
            Sentry.captureException(e);
            return false;
        }
    }
}
