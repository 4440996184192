import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'textSearch' })
export class TextSearchPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @returns {any[]}
   */
  transform(listItems: any[], searchTextContent: string): any[] {
    if (!listItems) {
      return [];
    }
    if (!searchTextContent) {
      return listItems;
    }
    searchTextContent = searchTextContent.toLocaleLowerCase();

    return listItems.filter(it => {
      return it.accommodationId.toLocaleLowerCase().includes(searchTextContent);
    });
  }
}
