import { Injectable } from '@angular/core';
import { NotificationService } from '../notification/notification.service';
import { ImageTypeEnum } from 'data-structures/lib/es6/enum/image-type.enum';

@Injectable({
    providedIn: 'root',
})
export class MediaService {
    constructor(readonly notificationService: NotificationService) {}

    isSuitable(file, context: ImageTypeEnum): boolean {
        const allowedTypes = { 'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png'] };
        if (context === ImageTypeEnum.ArrivalInformation) {
            allowedTypes['application/pdf'] = ['.pdf'];
        }
        const maxSize = 20 * 1024 * 1024;
        const isValidMimeType = $.inArray(file.type, Object.keys(allowedTypes)) > -1;

        if (!isValidMimeType) {
            this.notificationService.add('atraveo.fileuploadbundle.validation.type', 'danger', {
                fileName: file.name,
                givenType: file.type,
                allowedTypes: Object.keys(allowedTypes).join(', '),
            });
            return false;
        }

        if (file.size > maxSize) {
            this.notificationService.add('atraveo.fileuploadbundle.validation.size', 'danger', {
                fileName: file.name,
                givenSize: file.size,
                maxSize,
            });
            return false;
        }

        return true;
    }
}
