import { Component } from '@angular/core';
import {AuthenticationService} from '../../../../../services/authentication/authentication.service';
import {AuthenticationConnectorService} from '../../../../../services/api-connector/authentication-connector-service';
import {NotificationService} from '../../../../../services/notification/notification.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {

    currentPassword: string = '';
    newPassword: string = '';
    confirmPassword: string = '';
    errorMessages: string[] = [];

    constructor(readonly authenticationService: AuthenticationService,
                readonly authConnector: AuthenticationConnectorService,
                readonly notificationService: NotificationService) { }

    resetFormValues() {
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.errorMessages = [];
    }

    getErrorMessageFromPassword() {
        this.errorMessages = [];

        if (this.newPassword.length < 8) {
            this.errorMessages.push('user.validation.password.length');
        }

        if (!/\d/.test(this.newPassword)) {
            this.errorMessages.push('user.validation.password.number');
        }

        if (this.newPassword.toUpperCase() === this.newPassword) {
            this.errorMessages.push('user.validation.password.lowercase');
        }

        if (this.newPassword.toLowerCase() === this.newPassword) {
            this.errorMessages.push('user.validation.password.uppercase');
        }

        if (this.newPassword.includes(String(this.authenticationService.currentUser.ownerNumber))) {
            this.errorMessages.push('user.validation.password.username');
        }

        if (this.newPassword !== this.confirmPassword) {
            this.errorMessages.push('user.validation.password.equal');
        }

        if (this.currentPassword === this.newPassword) {
            this.errorMessages.push('user.validation.password.different');
        }
    }

    async onSubmit(): Promise<void> {
        this.getErrorMessageFromPassword();

        if (this.errorMessages.length) {
            return;
        }

        try {
            await this.authConnector.changePassword(this.authenticationService.currentUser.ownerNumber, this.newPassword, this.currentPassword);
            await this.notificationService.add('text.change.password.success', 'success');
            this.resetFormValues();
        } catch (error) {
            this.errorMessages.push('user.password.invalid');
        }
    }

    togglePassword(){
        const newPasswordElement = document.querySelectorAll('.password-field');

        newPasswordElement.forEach( (passwordField) => {
            const passwordFieldInput = passwordField as HTMLInputElement;

            if (passwordFieldInput.type === 'password') {
                passwordFieldInput.type = 'text';
            } else {
                passwordFieldInput.type = 'password';
            }
        });
    }
}
