<h2 class="page-heading">
    {{ 'label.owner.number' | translate }}:
    {{ authenticationService.currentUser.ownerNumber }}
</h2>
<div *ngIf="destinationUser">
    <p>
        <i class="fas fa-info-circle"></i>
        {{ 'atraveo.dashboardbundle.destination.title' | translate }}
    </p>
    <div [innerHTML]="'atraveo.dashboardbundle.destination.description' | translate"></div>
</div>


<span *ngIf="['CREATED', 'CLICKED', 'ABORTED'].includes(this.authenticationService.currentUser?.registration?.idNowStatusText)">
    <app-idnow-banner></app-idnow-banner>
</span>
<app-dashboard-banner></app-dashboard-banner>
<div class="row">
    <div class="col-lg-6">
        <app-task-display></app-task-display>
        <app-status-quo></app-status-quo>
        <app-quick-access></app-quick-access>
    </div>
    <div class="col-lg-6">
        <app-dashboard-next-travels></app-dashboard-next-travels>
    </div>
</div>
