import { eachDayOfInterval, format, startOfDay } from 'date-fns';
import { TravelPeriodDto } from 'data-structures/lib/es6/dto/accommodation/price-rule/travel-period.dto';
import { PriceInformationExceptionDto } from 'data-structures/lib/es6/dto/accommodation/price-information-exception.dto';
import { DateService } from '../../services/date/date.service';

export class ExtendedPriceInformationException extends PriceInformationExceptionDto {

    constructor() {
        super();
        this.travelPeriod = new TravelPeriodDto();
    }

    getArrivalDates(): any {
        return this.getStatusFromString(this.arrivalDays);
    }

    getArrivalForDay(day: number) {
        return ExtendedPriceInformationException.getStatusForDay(day, this.arrivalDays);
    }

    static getStatusForDay(day: number, dayString: string): boolean { // 0 = Sonntag
        if (dayString) {
            const value = dayString.charAt(day);

            return value === 'Y';
        }

        return false;
    }

    getStatusFromString(status: string): any {
        const returnValue = {};

        try {
            const dateRange = eachDayOfInterval({
                start: startOfDay(DateService.getDateFromString(this.travelPeriod.from)),
                end: startOfDay(DateService.getDateFromString(this.travelPeriod.to)),
            });

            for (const date of dateRange) {
                const dayString = format(
                    startOfDay(date),
                    'yyyy-MM-dd',
                );
                returnValue[dayString] = this.getArrivalForDay(date.getDay());
            }
        } catch(e) {
            // Invalides Daatumsformat
        }

        return returnValue;
    }
}
