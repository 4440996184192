<table class="table responsive-table">
    <thead>
        <tr>
            <th width="12%">
                <!-- Datum Gültigkeit -->
                <app-sort-arrows [(ngModel)]="sortDate" [translationKey]="'atraveo.accommodationbundle.price.field.daterange.0'" (ngModelChange)="setPriceInformation()"></app-sort-arrows>
            </th>
            <th width="13%">
                <!-- Preis pro Woche / Tag / X -->
                <app-sort-arrows [(ngModel)]="sortPrice" translation="{{'atraveo.accommodationbundle.price.create.rate-per-period-prefix' | translate }} {{'atraveo.accommodationbundle.price.create.rate-per-period.' + keyToTranslation[priceType] | translate }}" (ngModelChange)="setPriceInformation()"></app-sort-arrows>
            </th>
            <ng-container *ngIf="maxPersons.length">
                <ng-container *ngFor="let person of maxPersons; let i = index">
                    <th>
                        <!-- Preis für X Personen -->
                        {{ 'atraveo.accommodationbundle.price.create.upToPersons.prefix' | translate }}
                        {{ person }}
                        {{ 'atraveo.accommodationbundle.price.create.upToPersons.suffix' | translate }}
                    </th>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="hasPricePerAdditionalPerson">
                <th>{{ 'atraveo.accommodationbundle.price.create.upToPersons.additionalPersons' | translate }}</th>
            </ng-container>
            <ng-container *ngIf="hasPerPersonPriceForPriceType">
                <th>
                    <!-- Besonderheiten -->
                    {{ 'atraveo.accommodationbundle.price.field.specifics.0' | translate }}
                </th>
            </ng-container>
            <th><!-- Icons --></th>
        </tr>
    </thead>
    <ng-container *ngFor="let priceRule of priceRules; trackBy: trackByFn; let i = index">
        <tr *ngIf="priceRule.prices?.length" (click)="addOrRemoveDeleteElement(priceRule.id)" [class.selected]="this.deleteElements.includes(priceRule.id) && this.deleteMultipleInProgress">
            <td [attr.data-label]="'atraveo.accommodationbundle.price.field.daterange.0' | translate" *ngIf="!priceRule.travelPeriod.seasonId">
                {{ dateService.getFormattedDate(priceRule.travelPeriod.from, translateService.currentLang, priceRule.travelPeriod?.followingYears) }}
                - {{ dateService.getFormattedDate(priceRule.travelPeriod.to, translateService.currentLang, priceRule.travelPeriod?.followingYears) }}
            </td>

            <td [attr.data-label]="'atraveo.accommodationbundle.price.field.daterange.0' | translate" *ngIf="priceRule.travelPeriod.seasonId">
                <ng-container *ngFor="let season of seasons.seasons">
                    <div *ngIf="season.seasonId === priceRule.travelPeriod.seasonId">
                        <span>{{ season.name[this.translateService.currentLang] }}</span>
                    </div>
                </ng-container>
                {{ dateService.getFormattedDate(priceRule.travelPeriod.from, translateService.currentLang, priceRule.travelPeriod?.followingYears) }}
                - {{ dateService.getFormattedDate(priceRule.travelPeriod.to, translateService.currentLang, priceRule.travelPeriod?.followingYears) }}
            </td>


            <td attr.data-label="{{'atraveo.accommodationbundle.price.create.rate-per-period-prefix' | translate }} {{'atraveo.accommodationbundle.price.create.rate-per-period.' + keyToTranslation[priceType] | translate }}">
                <!-- Preis. Bleibt leer wenn mehrere Preise bis x Personen vorhanden -->
                <ng-container *ngIf="priceRule.prices?.length <= 1 && !priceRule.prices[0].maxPersons">{{ priceRule.prices[0].price | currency: ownerCurrency }}</ng-container>
            </td>

            <!-- Preis für X Personen -->
            <ng-container *ngIf="maxPersons.length">
                <ng-container *ngFor="let price of pricesWithMaxPersons[i]; trackBy: trackByFn; let i = index">
                    <ng-container *ngIf="price; else noPriceBox">
                        <td [attr.data-label]="('atraveo.accommodationbundle.price.create.upToPersons.prefix' | translate) + ' ' + maxPersons[i] +
                                ' ' + ('atraveo.accommodationbundle.price.create.upToPersons.suffix' | translate)">
                            {{ price | currency: ownerCurrency }}
                        </td>
                    </ng-container>
                    <ng-template #noPriceBox>
                        <td class="d-none d-lg-table-cell"></td>
                    </ng-template>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="priceRule.prices && hasPricePerAdditionalPerson">
                <td [attr.data-label]="'atraveo.accommodationbundle.price.create.upToPersons.additionalPersons' | translate">{{ priceRule.prices[0].pricePerAdditionalPerson | currency: ownerCurrency}}</td>
            </ng-container>

            <ng-container *ngIf="priceRule.prices[0]?.pricePerPerson">
                <td [attr.data-label]="'atraveo.accommodationbundle.price.field.specifics.0' | translate">
                    {{ 'atraveo.accommodationbundle.price.field.specifics.pricePerPerson' | translate }}<br>
                    <ng-container *ngIf="priceRule.prices[0]?.ageExceptions">
                        <p *ngFor="let ageException of priceRule.prices[0]?.ageExceptions">
                            <ng-container *ngIf="ageException.min && ageException.max && ageException.price">
                                {{ 'atraveo.accommodationbundle.price.index.ageExceptionExist.prefix' | translate }} {{ ageException.min }}
                                {{ 'atraveo.accommodationbundle.price.index.ageExceptionExist.glue' | translate }} {{ ageException.max }}
                                {{ 'atraveo.accommodationbundle.price.index.ageExceptionExist.suffix' | translate }}
                                {{ 'atraveo.accommodationbundle.price.index.ageExceptionExist.rate.prefix' | translate }} {{ ageException.price | currency: ownerCurrency }}
                            </ng-container>
                        </p>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container *ngIf="!priceRule.prices[0]?.pricePerPerson && hasPerPersonPriceForPriceType">
                <td></td>
            </ng-container>

            <td class="small text-nowrap text-right">
                <!-- Icons -->
                <a [attr.data-target]="'#priceModal-' + priceType" (click)="setCurrentPrice(priceRule)" class="btn btn-link p-0">
                    <i class="fa fa-pen fa-lg"></i>
                </a>
                <button type="button" aria-label="Delete" class="btn btn-link p-0" (click)="deleteElements = [priceRule.id]; openConfirmationDialog()">
                    <i class="fa fa-trash-alt fa-lg ml-2" aria-hidden="true"></i>
                </button>
            </td>
        </tr>
    </ng-container>
</table>

<div class="delete-multiple mt-4 mb-4 float-right col-md-12">
    <span *ngIf="deleteMultipleInProgress" class="mr-3">
        {{ 'atraveo.accommodationbundle.price.delete.deleteMultiple' | translate }}
    </span>
    <button *ngIf="deleteMultipleInProgress" class="btn btn-primary" (click)="deleteMultiple()">
        {{ 'atraveo.accommodationbundle.price.delete.confirmDeleteNumberOfPricesNumber' | translate:{number: deleteElements.length} }}
    </button>
    <button *ngIf="deleteMultipleInProgress" class="btn btn-primary ml-3" (click)="this.abortDeletePrice()">
        {{ 'button.cancel' | translate }}
    </button>
    <button *ngIf="!deleteMultipleInProgress" class="btn btn-primary ml-3" (click)="this.deleteMultipleInProgress = true">
        {{ 'atraveo.accommodationbundle.price.delete.deletePrices' | translate }}
    </button>
</div>

<div id="delete-title" class="hide">
    <p>{{ 'atraveo.accommodationbundle.price.delete.0' | translate }}</p>
</div>

<div id="delete-popover" class="hide">
    <p>{{ 'confirm.content.delete.0' | translate }}</p>
    <div class="btn-group">
        <div class="btn btn-secondary btn-delete-price">{{ 'text.marketing.teaser.yes' | translate }}</div>
        <div class="btn btn-primary btn-close">{{ 'atraveo.accommodationbundle.description.existAtOtherProvider.choice1' | translate }}</div>
    </div>
</div>
