import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NavigationComponent } from './components/main/navigation/navigation.component';
import { ContentComponent } from './components/main/content/content.component';
import { DashboardComponent } from './components/main/content/dashboard/dashboard.component';
import { MainHeaderComponent } from './components/main/header/main-header.component';
import { HeaderComponent } from './components/global/header/header.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ApiConnectorService } from './services/api-connector/api-connector.service';
import { BackOfficeConnectorService } from './services/api-connector/back-office-connector-service';
import { CacheService } from './services/cache/cache.service';
import { AccommodationsComponent } from './components/main/content/accommodations/accommodations.component';
import { LoadingIndicatorComponent } from './components/main/loading-indicator/loading-indicator.component';
import { LoaderService } from './services/loader-service/loader.service';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { NotificationDisplayComponent } from './components/main/notification-display/notification-display.component';
import { NotificationService } from './services/notification/notification.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { LoginComponent } from './components/login/login.component';
import { RegistrationFormComponent } from './components/main/content/user/registration-form/registration-form.component';
import { IdNowComponent } from './components/idNow/idNow.component';
import { PositionComponent } from './components/main/content/accommodation/position/position.component';
import { AccommodationComponent } from './components/main/content/accommodation/accommodation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StateComponent } from './components/main/content/accommodation/state/state.component';
import { DescriptionComponent } from './components/main/content/accommodation/description/description.component';
import { FeaturesComponent } from './components/main/content/accommodation/features/features.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ConsolePipe } from './pipes/console.pipe';
import { PriceComponent } from './components/main/content/accommodation/price/price.component';
import { AdditionalCostComponent } from './components/main/content/accommodation/price/additional-cost/additional-cost.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { CalendarComponent } from './components/main/content/accommodation/calendar/calendar.component';
import 'reflect-metadata';
import { CreateComponent } from './components/main/content/accommodation/price/additional-cost/create/create.component';
import { ArrivalInformationComponent } from './components/main/content/arrival-information/arrival-information.component';
import { ImagesComponent } from './components/main/content/accommodation/images/images.component';
import { CreateBookingComponent } from './components/main/content/booking/create-booking/create-booking.component';
import { SpecialOfferComponent } from './components/main/content/accommodation/price/special-offer/special-offer.component';
import { DaysBeforeArrivalPipe } from './components/main/content/accommodation/price/special-offer/daysBeforeArrivalPipe';
import { BookingListComponent } from './components/main/content/booking/booking-list/booking-list.component';
import { BookingListTabComponent } from './components/main/content/booking/booking-list-tab/booking-list-tab.component';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeDe from '@angular/common/locales/de';
import localeDa from '@angular/common/locales/da';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localeSv from '@angular/common/locales/sv';
import localeHr from '@angular/common/locales/hr';
import { registerLocaleData } from '@angular/common';
import { BookingViewComponent } from './components/main/content/booking/booking-view/booking-view.component';
import { CountrySelectComponent } from './components/global/country-select/country-select.component';
import { HistoryComponent } from './components/main/content/history/history.component';
import { AccommodationAjaxSearchComponent } from './components/global/accommodation-ajax-search/accommodation-ajax-search.component';
import { PasswordForgotten } from './components/main/content/user/password-forgotten/password-forgotten';
import { ServiceSidebarComponent } from './components/main/service-sidebar/service-sidebar.component';
import { ProfileComponent } from './components/main/content/user/profile/profile.component';
import { BankAccountComponent } from './components/main/content/bank-account/bank-account.component';
import { InterfaceComponent } from './components/main/content/interface/interface.component';
import { ContactComponent } from './components/main/content/contact/contact.component';
import { AdditionalCostTemplateComponent } from './components/main/content/additional-cost-template/additional-cost-template.component';
import { GeoTripletComponent } from './components/global/geo-triplet/geo-triplet.component';
import { AccommodationSelectComponent } from './components/global/accommodation-select/accommodation-select.component';
import { AccommodationGroupSelectComponent } from './components/global/accommodation-group-select/accommodation-group-select.component';
import { ChangePasswordComponent } from './components/main/content/user/change-password/change-password.component';
import { NotificationsComponent } from './components/main/notifications/notifications.component';
import { InboxComponent } from './components/main/notifications/messages/inbox/inbox.component';
import { OutboxComponent } from './components/main/notifications/messages/outbox/outbox.component';
import { MessageContentComponent } from './components/main/notifications/messages/message-content/message-content.component';
import { CreateArrivalInformationComponent } from './components/main/content/arrival-information/create-arrival-information/create-arrival-information.component';
import { ConditionsSelectComponent } from './components/global/conditions-select/conditions-select.component';
import { HourSelectComponent } from './components/global/hour-select/hour-select.component';
import { LanguageDtoFormComponent } from './components/global/language-dto-form/language-dto-form.component';
import { RatingsComponent } from './components/main/content/ratings/ratings.component';
import { SortArrowsComponent } from './components/global/sort-arrows/sort-arrows.component';
import { RequestRatingComponent } from './components/main/content/ratings/request-rating/request-rating.component';
import { DisplayRatingIconsComponent } from './components/main/content/ratings/display-rating-icons/display-rating-icons.component';
import { AccommodationGroupComponent } from './components/main/content/accommodation/accommodation-group/accommodation-group.component';
import { AccommodationGroupsComponent } from './components/main/content/accommodation/accommodation-groups/accommodation-groups.component';
import { ObjectsComponent } from './components/main/content/accommodation/objects/objects.component';
import { TextSearchPipe } from './pipes/text-search.pipe';
import { SearchByObjectComponent } from './components/global/accommodation-group-ajax-search/search-by-object/search-by-object.component';
import { SearchByGroupComponent } from './components/global/accommodation-group-ajax-search/search-by-group/search-by-group.component';
import { DashboardBannerComponent } from './components/main/content/dashboard/dashboard-banner/dashboard-banner.component';
import { EditPriceComponent } from './components/main/content/accommodation/price/edit-price/edit-price.component';
import { PriceTableComponent } from './components/main/content/accommodation/price/price-table/price-table.component';
import { QuickAccessComponent } from './components/main/content/dashboard/quick-access/quick-access.component';
import { AdvancedSettingsComponent } from './components/main/content/accommodation/price/advanced-settings/advanced-settings.component';
import { AdvancedSettingRecordComponent } from './components/main/content/accommodation/price/advanced-settings/advanced-setting-record/advanced-setting-record.component';
import { TaskDisplayComponent } from './components/main/content/dashboard/task-display/task-display.component';
import { NextTravelsComponent } from './components/main/content/dashboard/next-travels/next-travels.component';
import { CityInformationComponent } from './components/main/content/city-information/city-information.component';
import { StatusQuoComponent } from './components/main/content/dashboard/status-quo/status-quo.component';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { CreateSpecialOfferComponent } from './components/main/content/accommodation/price/special-offer/create-special-offer/create-special-offer.component';
import { NotFoundComponent } from './components/main/content/accommodation/not-found/not-found.component';
import { ConfirmationDialogComponent } from './components/global/confirmation-dialog/confirmation-dialog.component';
import { ErrorsComponent } from './components/main/content/accommodation/errors/errors.component';
import { InvoicesComponent } from './components/main/content/invoices/invoices.component';
import { InvoicesOverviewComponent } from './components/main/content/invoices/invoices-overview/invoices-overview.component';
import { CommissionInvoicesComponent } from './components/main/content/invoices/commission-invoices/commission-invoices.component';
import { TermsComponent } from './components/main/content/terms/terms.component';
import { StringToDatePipe } from './pipes/string-to-date.pipe';
import { OwnerProfileComponent } from './components/main/content/user/profile/owner-profile/owner-profile.component';
import { ContactPersonComponent } from './components/main/content/user/profile/contact-person/contact-person.component';
import { ThumbnailComponent } from './components/main/content/accommodation/images/thumbnail/thumbnail.component';
import { GetTranslationFromLanguageDtoPipe } from './pipes/get-translation-from-languagedto-pipe';
import { ImageUrlPipePipe } from './pipes/image-url-pipe.pipe';
import { ChangeOwnerComponent } from './components/main/content/change-owner/change-owner.component';
import { DirectLoginComponent } from './components/global/direct-login/direct-login.component';
import { ShyReplacePipe } from './pipes/shy-replace.pipe';
import { DataProtectionComponent } from './components/main/content/data-protection/data-protection.component';
import { ImprintComponent } from './components/main/content/imprint/imprint.component';
import { BackToTopButtonComponent } from './components/global/back-to-top-button/back-to-top-button.component';
import { AccommodationProgressBarComponent } from './components/main/accommodation-progress-bar/accommodation-progress-bar.component';
import { PublicationRequestBtnComponent } from './components/main/publication-request-btn/publication-request-btn.component';
import { ProgressBarComponent } from './components/global/progress-bar/progress-bar.component';
import { AccommodationHeaderComponent } from './components/main/content/accommodation/accommodation-header/accommodation-header.component';
import { IdnowBannerComponent } from './components/main/content/dashboard/idnow-banner/idnow-banner.component';
import { ImageComponent } from './components/main/content/accommodation/images/image/image.component';
import { PendingChangesGuard } from './guards/pending-changes-guard';
import { SubNavigationComponent } from './components/global/sub-navigation/sub-navigation.component';
import { AccommodationMainComponent } from './components/main/content/accommodation/accommodation-main/accommodation-main.component';
import { AccommodationGroupOverviewComponent } from './components/main/content/accommodation/accommodation-group/accommodation-group-overview.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccommodationChangeSelectComponent } from './components/global/accommodation-change-select/accommodation-change-select.component';
import { DailyPriceComponent } from './components/main/content/accommodation/price/daily-price/daily-price.component';
import { EditComponent } from './components/main/content/accommodation/price/daily-price/edit/edit.component';
import { ValidationErrorMessagesComponent } from './components/main/validation-error-messages/validation-error-messages.component';
import { OnboardingComponent } from './components/main/content/user/onboarding/onboarding.component';
import { FooterComponent } from './components/global/footer/footer.component';
import { CardComponent } from './components/main/content/user/onboarding/card/card.component';
import { MatTooltip } from '@angular/material/tooltip';
import { ShowTooltipOnClickDirective } from './directives/show-tooltip-on-click.directive';
import { TooltipComponent } from './components/global/tooltip/tooltip.component';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { LogoutDialogComponent } from './components/global/dialogs/logout/logout-dialog.component';
import { MessagesFilterDialogComponent } from './components/global/dialogs/messages-filter-dialog/messages-filter-dialog.component';
import { BookingFilterDialogComponent } from './components/global/dialogs/booking-filter-dialog/booking-filter-dialog.component';
import { PropertiesFilterDialogComponent } from './components/global/dialogs/properties-filter-dialog/properties-filter-dialog.component';
import { PriceDialogComponent } from './components/global/dialogs/price-dialog/price-dialog.component';
import { IcalDialogComponent } from './components/global/dialogs/ical-dialog/ical-dialog.component';
import { ArrivalInformationDialogComponent } from './components/global/dialogs/arrival-information-dialog/arrival-information-dialog.component';
import { NotEditableDialogComponent } from './components/global/dialogs/not-editable-dialog/not-editable-dialog.component';
import { FinishSelectionDialogComponent } from './components/global/dialogs/finish-selection-dialog/finish-selection-dialog.component';
import { AdvancedSettingsDialogComponent } from './components/global/dialogs/advanced-settings-dialog/advanced-settings-dialog.component';
import { IssueCollectorComponent } from './components/global/issue-collector/issue-collector.component';
import { PauseAccommodationConfirmationDialogComponent } from './components/global/dialogs/pause-accommodation-confirmation-dialog/pause-accommodation-confirmation-dialog.component';
import { FullComponent } from './components/layouts/full/full.component';
import { AdditionalCostDialogComponent } from './components/global/dialogs/additional-cost-dialog/additional-cost-dialog.component';
import { AdditionalCostTemplateDialogComponent } from './components/global/dialogs/additional-cost-template-dialog/additional-cost-template-dialog.component';
import { ModelComponent } from './components/main/content/accommodation/model/model.component';
import { FeatureInputComponent } from './components/main/content/accommodation/features/feature-input/feature-input.component';
import { FeatureSelectComponent } from './components/main/content/accommodation/features/feature-select/feature-select.component';
import { FeatureMultiSelectComponent } from './components/main/content/accommodation/features/feature-multi-select/feature-multi-select.component';
import { FeatureCheckboxComponent } from './components/main/content/accommodation/features/feature-checkbox/feature-checkbox.component';
import { PhotosGuideComponent } from './components/global/photos-guide/photos-guide.component';
import { BankAccountDialogComponent } from './components/global/dialogs/bankdata-dialog/bank-account-dialog.component';
import { ContactDialogComponent } from './components/global/dialogs/contact-dialog/contact-dialog.component';
import { PoolConfigComponent } from './components/main/content/pool-config/pool-config.component';
import { OwnerListComponent } from './components/main/content/destinations/owner-list/owner-list.component';

registerLocaleData(localeDe);
registerLocaleData(localeDa);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeNl);
registerLocaleData(localePl);
registerLocaleData(localeSv);
registerLocaleData(localeEs);
registerLocaleData(localeHr);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        FeaturesComponent,
        AppComponent,
        MainComponent,
        NavigationComponent,
        ContentComponent,
        DashboardComponent,
        MainHeaderComponent,
        HeaderComponent,
        AccommodationsComponent,
        LoadingIndicatorComponent,
        NotificationDisplayComponent,
        LoginComponent,
        RegistrationFormComponent,
        IdNowComponent,
        PositionComponent,
        AccommodationComponent,
        StateComponent,
        DescriptionComponent,
        ConsolePipe,
        PriceComponent,
        CalendarComponent,
        AdditionalCostComponent,
        ReplacePipe,
        GetTranslationFromLanguageDtoPipe,
        CreateComponent,
        ArrivalInformationComponent,
        ImagesComponent,
        CreateBookingComponent,
        SpecialOfferComponent,
        DaysBeforeArrivalPipe,
        BookingListComponent,
        BookingListTabComponent,
        BookingViewComponent,
        CountrySelectComponent,
        HistoryComponent,
        AccommodationAjaxSearchComponent,
        PasswordForgotten,
        ServiceSidebarComponent,
        ProfileComponent,
        BankAccountComponent,
        ContactComponent,
        InterfaceComponent,
        AccommodationGroupComponent,
        AccommodationGroupsComponent,
        ObjectsComponent,
        TextSearchPipe,
        AdditionalCostTemplateComponent,
        GeoTripletComponent,
        AccommodationSelectComponent,
        AccommodationGroupSelectComponent,
        NotificationsComponent,
        InboxComponent,
        OutboxComponent,
        MessageContentComponent,
        ChangePasswordComponent,
        RatingsComponent,
        CreateArrivalInformationComponent,
        ConditionsSelectComponent,
        HourSelectComponent,
        LanguageDtoFormComponent,
        SortArrowsComponent,
        RequestRatingComponent,
        DisplayRatingIconsComponent,
        EditPriceComponent,
        DashboardBannerComponent,
        PriceTableComponent,
        QuickAccessComponent,
        TaskDisplayComponent,
        AdvancedSettingsComponent,
        AdvancedSettingRecordComponent,
        NextTravelsComponent,
        SearchByObjectComponent,
        SearchByGroupComponent,
        CityInformationComponent,
        StatusQuoComponent,
        CreateSpecialOfferComponent,
        NotFoundComponent,
        ConfirmationDialogComponent,
        ErrorsComponent,
        InvoicesComponent,
        InvoicesOverviewComponent,
        CommissionInvoicesComponent,
        TermsComponent,
        StringToDatePipe,
        OwnerProfileComponent,
        ContactPersonComponent,
        ThumbnailComponent,
        ImageUrlPipePipe,
        ChangeOwnerComponent,
        DirectLoginComponent,
        ShyReplacePipe,
        DataProtectionComponent,
        ImprintComponent,
        FullComponent,
        BackToTopButtonComponent,
        AccommodationProgressBarComponent,
        PublicationRequestBtnComponent,
        ProgressBarComponent,
        AccommodationHeaderComponent,
        IdnowBannerComponent,
        ImageComponent,
        SubNavigationComponent,
        AccommodationMainComponent,
        AccommodationGroupOverviewComponent,
        AccommodationChangeSelectComponent,
        ValidationErrorMessagesComponent,
        OnboardingComponent,
        FooterComponent,
        CardComponent,
        DailyPriceComponent,
        EditComponent,
        ShowTooltipOnClickDirective,
        TooltipComponent,
        LogoutDialogComponent,
        MessagesFilterDialogComponent,
        BookingFilterDialogComponent,
        PropertiesFilterDialogComponent,
        PriceDialogComponent,
        IcalDialogComponent,
        ArrivalInformationDialogComponent,
        NotEditableDialogComponent,
        FinishSelectionDialogComponent,
        AdvancedSettingsDialogComponent,
        IssueCollectorComponent,
        PauseAccommodationConfirmationDialogComponent,
        AdditionalCostDialogComponent,
        AdditionalCostTemplateDialogComponent,
        ModelComponent,
        FeatureInputComponent,
        FeatureSelectComponent,
        FeatureMultiSelectComponent,
        FeatureCheckboxComponent,
        PhotosGuideComponent,
        ContactDialogComponent,
        BankAccountDialogComponent,
        OwnerListComponent,
        PoolConfigComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        NgbModule,
        NgSelectModule,
        MatTooltip,
        MatDialogModule,
    ],
    providers: [
        TranslateService,
        ApiConnectorService,
        BackOfficeConnectorService,
        CacheService,
        LoaderService,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        AuthenticationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useFactory: (translate: TranslateService) => {
                return translate.currentLang;
            },
            deps: [TranslateService],
        },
        NotificationService,
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: 'googleTagManagerId',
            useValue: 'GTM-K2T7XMW',
        },
        PendingChangesGuard,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(trace: Sentry.TraceService) {}
}
