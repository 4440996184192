export class Contact {
    ownerId: number;
    sender: string;
    receiver: string;
    dateTime: string;
    subject: string;
    text: string;
    isImportant: boolean;
    accommodationId: string;
    bookingId: string;
    accommodationGroupId: number;
    file: string;

    constructor(authenticationService) {
        this.receiver = 'ownerservice';
        this.accommodationId = '';
        this.bookingId = '';
        this.subject = '';
        this.text = '';
        this.isImportant = false;
        this.ownerId = authenticationService.currentUser.ownerNumber;
        this.sender = authenticationService.currentUser.getPrimaryContact()?.email;
    }

    getDateTime(timestamp: number) {
        const date = new Date(timestamp);
        const dateTime = date.toISOString().match(/(\d{4}\-\d{2}\-\d{2})T(\d{2}:\d{2}:\d{2})/);

        this.dateTime = dateTime[1] + ' ' + dateTime[2];
    }

    getRecieverEmailAddress(receiver: string) {
        switch (receiver) {
            case 'ownerservice':
                return 'vermieterservice@atraveo.de';
                break;
            case 'customerservice':
                return 'kundenservice@atraveo.de';
                break;
            default:
                return 'bewertung@atraveo.de';
        }
    }

    resetFormValues() {
        this.receiver = 'ownerservice';
        this.accommodationId = '';
        this.bookingId = '';
        this.subject = '';
        this.text = '';
        this.isImportant = false;
        this.file = '';
    }
}
