export class MessageRequestDto {
    accommodationId: number = null;
    bookingNumber: number = null;
    accountId: string = null;
    searchInSubject: string = '';
    page: string = '1';
    limit: number = 10;
    sortBy: string = 'aoa.date DESC,aoa.mailId';
    sortTo: string = 'desc';
    isSend: number = null;
    resetFilterValues() {
        this.accommodationId = null;
        this.bookingNumber = null;
        this.accountId = null;
    }
}
