<form class="bankAccountForm" [formGroup]="bankAccountForm" (ngSubmit)="submitForm()">
    <div class="row">
        <div
            [class]="'col-12' + ((!editBankAccount.isSEPACountry()) ? ' col-lg-4' : '')"
        >
            <p>
                <strong *ngIf="default">{{ 'text.bank.account.MainBankaccount' | translate }}</strong>
                <strong *ngIf="!default">{{ 'text.bank.account.ObjectBankaccount' | translate }}</strong>
            </p>
            <div class="form-group" *ngIf="!default">
                <label for="bank-accommodations" class="required">{{ 'atraveo.additionalcosttemplatebundle.create.accommodations.0' | translate }}</label>
                <app-accommodation-select
                    [(ngModel)]="selectedAccommodations"
                    [ngModelOptions]="{ standalone: true }"
                    classKey="form-control custom-select selectpicker"
                    id="bank-accommodations"
                    [noneSelectedDisplayAll]="false"
                    [includeAllObjects]="false"
                >
                </app-accommodation-select>
                <app-validation-error-messages [control]="bankAccountForm.controls.accommodations"></app-validation-error-messages>
            </div>

            <div class="form-group">
                <label for="input-bank-account-country" class="required">
                    {{ 'text.bank.account.country' | translate }}
                </label>
                <app-country-select id="input-bank-account-country"
                                    classKey="form-control custom-select"
                                    [form]="bankAccountForm"
                                    formControlName="bankCountryISOCode">
                </app-country-select>
                <app-validation-error-messages [control]="bankAccountForm.controls.bankCountryISOCode"></app-validation-error-messages>
            </div>

            <div class="form-group">
                <label for="input-account-holder" class="required">{{ 'text.bank.account.holder.0' | translate }}</label>
                <input
                    type="text"
                    id="input-account-holder"
                    name="input-account-holder"
                    class="form-control"
                    formControlName="accountHolder"
                />
                <app-validation-error-messages [control]="bankAccountForm.controls.accountHolder"></app-validation-error-messages>
            </div>

            <div class="form-group">
                <label for="input-iban" [class.required]="editBankAccount.isSEPACountry()">{{ 'text.bank.account.iban' | translate }}</label>
                <input
                    type="text"
                    id="input-iban"
                    name="input-iban"
                    class="form-control"
                    formControlName="iban"
                />
                <app-validation-error-messages [control]="bankAccountForm.controls.iban"></app-validation-error-messages>
            </div>

            <div class="form-group">
                <label for="input-bic" class="required">{{ 'text.bank.account.bic' | translate }}</label>
                <input
                    type="text"
                    id="input-bic"
                    name="input-bic"
                    class="form-control"
                    formControlName="bic"
                />
                <app-validation-error-messages [control]="bankAccountForm.controls.bic"></app-validation-error-messages>
            </div>

            <div class="form-group" *ngIf="!editBankAccount.isSEPACountry()">
                <label for="input-account-number">{{ 'text.bank.account.number' | translate }}</label>
                <input
                    type="text"
                    id="input-account-number"
                    name="input-account-number"
                    class="form-control"
                    formControlName="accountNumber"
                />
                <app-validation-error-messages [control]="bankAccountForm.controls.accountNumber"></app-validation-error-messages>
            </div>

            <div class="form-group" *ngIf="!editBankAccount.isSEPACountry()">
                <label for="input-routing-number">{{ 'text.bank.account.routingNumber' | translate }}</label>
                <input
                    type="text"
                    id="input-routing-number"
                    name="input-routing-number"
                    class="form-control"
                    formControlName="routingNumber"
                />
                <app-validation-error-messages [control]="bankAccountForm.controls.routingNumber"></app-validation-error-messages>
            </div>
        </div>
        <div class="col-12 col-lg-4" *ngIf="!editBankAccount.isSEPACountry()">
            <p>
                <strong>{{ 'text.bank.account.holder.address' | translate }}:</strong>
            </p>

            <div class="form-group">
                <label for="input-account-address-street" class="required">{{ 'text.bank.account.holder.street' | translate }}</label>
                <input
                    type="text"
                    id="input-account-address-street"
                    name="input-account-address-street"
                    class="form-control"
                    formControlName="accountHolderStreetAndNumber"
                />
                <app-validation-error-messages [control]="bankAccountForm.controls.accountHolderStreetAndNumber"></app-validation-error-messages>
            </div>

            <div class="form-group">
                <label for="input-account-address-zip" class="required">{{ 'text.bank.account.holder.postcode' | translate }}</label>
                <input
                    type="text"
                    id="input-account-address-zip"
                    name="input-account-address-zip"
                    class="form-control"
                    formControlName="accountHolderZip"
                />
                <app-validation-error-messages [control]="bankAccountForm.controls.accountHolderZip"></app-validation-error-messages>
            </div>

            <div class="form-group">
                <label for="input-account-address-city" class="required">{{ 'text.bank.account.holder.city' | translate }}</label>
                <input
                    type="text"
                    id="input-account-address-city"
                    name="input-account-address-city"
                    class="form-control"
                    formControlName="accountHolderCity"
                />
                <app-validation-error-messages [control]="bankAccountForm.controls.accountHolderCity"></app-validation-error-messages>
            </div>

            <div class="form-group">
                <label for="input-account-address-country" class="required">{{ 'text.country' | translate }}</label>
                <app-country-select
                    idKey="input-account-address-country"
                    classKey="form-control custom-select"
                    formControlName="accountHolderCountryISOCode"
                    [form]="bankAccountForm"
                >
                </app-country-select>
                <app-validation-error-messages [control]="bankAccountForm.controls.accountHolderCountryISOCode"></app-validation-error-messages>
            </div>
        </div>
        <div class="col-12 col-lg-4" *ngIf="!editBankAccount.isSEPACountry()">
            <p>
                <strong>{{ 'text.bank.account.bank.address' | translate }}:</strong>
            </p>

            <div class="form-group">
                <label for="input-bank-name" class="required">{{ 'text.bank.account.bank.name' | translate }}</label>
                <input
                    type="text"
                    id="input-bank-name"
                    name="input-bank-name"
                    class="form-control"
                    formControlName="bankName"
                />
                <app-validation-error-messages [control]="bankAccountForm.controls.bankName"></app-validation-error-messages>
            </div>

            <div class="form-group">
                <label for="input-bank-address-street" class="required">{{ 'text.bank.account.bank.street' | translate }}</label>
                <input
                    type="text"
                    id="input-bank-address-street"
                    name="input-bank-address-street"
                    class="form-control"
                    formControlName="bankStreetAndNumber"
                />
                <app-validation-error-messages [control]="bankAccountForm.controls.bankStreetAndNumber"></app-validation-error-messages>
            </div>

            <div class="form-group">
                <label for="input-bank-address-zip" class="required">{{ 'text.bank.account.holder.postcode' | translate }}</label>
                <input
                    type="text"
                    id="input-bank-address-zip"
                    name="input-bank-address-zip"
                    class="form-control"
                    formControlName="bankZip"
                />
                <app-validation-error-messages [control]="bankAccountForm.controls.bankZip"></app-validation-error-messages>
            </div>

            <div class="form-group">
                <label for="input-bank-address-city" class="required">{{ 'text.bank.account.holder.city' | translate }}</label>
                <input
                    type="text"
                    id="input-bank-address-city"
                    name="input-bank-address-city"
                    class="form-control"
                    formControlName="bankCity"
                />
                <app-validation-error-messages [control]="bankAccountForm.controls.bankCity"></app-validation-error-messages>
            </div>

            <div class="form-group">
                <label for="input-bank-address-country" class="required">{{ 'text.country' | translate }}</label>
                <app-country-select
                    idKey="input-bank-address-country"
                    classKey="form-control custom-select"
                    formControlName="bankAddressCountryISOCode"
                    [form]="bankAccountForm"
                >
                </app-country-select>
                <app-validation-error-messages [control]="bankAccountForm.controls.bankAddressCountryISOCode"></app-validation-error-messages>
            </div>
        </div>
    </div>

    <p>{{ 'text.mandatoryfield' | translate }}</p>

    <p *ngIf="editBankAccount.verified && editBankAccount.verified === 'verified'">
        {{ 'atraveo.profilebundle.entity.user.bankaccountverified' | translate }}
    </p>

    <p *ngIf="(!editBankAccount.verified || editBankAccount.verified === 'unverified') && editBankAccount.validBic && ((editBankAccount.validIban && editBankAccount.isSEPACountry()) || !editBankAccount.isSEPACountry())">
        {{ 'atraveo.profilebundle.entity.user.bankaccountverifying' | translate }}
    </p>

    <p *ngIf="editBankAccount.verified && editBankAccount.verified === 'declined'">
        {{ 'text.payment.declined' | translate }}
    </p>

    <button
        class="btn btn-success"
        type="submit"
        [disabled]="isAdmin"
        ><i class="fas fa-cloud-upload-alt mr-2"></i>{{ 'button.save' | translate }}
    </button>
    <p id="bankAccount-note" *ngIf="default"><i class="fas fa-info-circle"></i> {{ 'user.profile.addBankAccount.note' | translate }}</p>
</form>
