import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UrlLangDetectorService {

  constructor(readonly route: ActivatedRoute,
              readonly router: Router,
              ) { }

  async checkUrlLangAndRemove() {
    const queryParams = { ...this.route.snapshot.queryParams };

    if (queryParams.lang && queryParams.lang !== '') {
      queryParams.lang = '';
      await this.router.navigate([], { queryParams });
    }
  }
}
