<p>{{ 'atraveo.customerratingbundle.invitation.description' | translate }}</p>
<form>
    <div class="form-group row">
        <div class="col-sm-2">
            <label>
                <strong>{{ 'atraveo.accommodationbundle.entity.accommodation.0' | translate }}</strong>
            </label>
        </div>
        <div class="col">
            <app-accommodation-ajax-search (onSearch)="search($event)" [hideLabel]="true"></app-accommodation-ajax-search>
            <app-tooltip [additionalClassList]="'mr-2'" [text]="'info.bewertungeinholen' | translate"></app-tooltip>
        </div>
    </div>
    <div *ngIf="selectedAccommodationId" class="form-group row">
        <div class="col-sm-2">
            <label for="subject">
                <strong>{{ 'atraveo.customerratingbundle.invitation.subject' | translate}}</strong>
            </label>
        </div>
        <div class="col">
            <select [(ngModel)]="this.selectedLanguage" [ngModelOptions]="{standalone: true}" name="select-language" class="form-control custom-select" (change)="search()">
                <option *ngFor="let language of this.allowedLanguages; let i = index" value="{{ language }}" [selected]="i === 0">
                    {{ 'intl.language.name.' + language | translate }}
                </option>
            </select>
        </div>
    </div>
    <div *ngIf="selectedAccommodationId" class="form-group row">
        <div class="col-sm-2">
            <label for="subject">
                <strong>{{ 'atraveo.customerratingbundle.invitation.subject' | translate}}</strong>
            </label>
        </div>
        <div class="col">
            <input id="subject" type="text" value="{{ subject }}" class="form-control">
        </div>
    </div>
    <div *ngIf="selectedAccommodationId" class="form-group row">
        <div class="col-sm-2">
            <label for="message">
                <strong>{{ 'atraveo.customerratingbundle.invitation.message' | translate}}</strong>
            </label>
        </div>
        <div class="col">
            <textarea id="message" class="form-control">{{ message }}</textarea>
        </div>
    </div>
</form>

