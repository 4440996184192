<div *ngIf="this.accommodationOrTemplate && this.accommodationOrTemplate.additionalCosts && this.dataReady">
    <button
        *ngIf="includeUpperButton"
        (click)="createAdditionalCost()"
        id="{{ idPrefix + '-' }}create-additional-cost-top"
        class="btn btn-secondary mb-2"
    >
        + {{ 'atraveo.accommodationbundle.additionalcost.add-additionalcost' | translate }}
    </button>

    <table class="table responsive-table">
        <thead>
        <tr>
            <th>{{ 'atraveo.accommodationbundle.additionalcost.field.required' | translate }}</th>
            <th>{{ 'atraveo.accommodationbundle.additionalcost.field.type' | translate }}</th>
            <th>{{ 'atraveo.accommodationbundle.additionalcost.field.rate.0' | translate }}</th>
            <th>{{ 'atraveo.accommodationbundle.additionalcost.field.perDuration' | translate }}</th>
            <th>{{ 'atraveo.accommodationbundle.additionalcost.field.payable' | translate }}</th>
            <th>{{ 'atraveo.accommodationbundle.additionalcost.field.limitations' | translate }}</th>
            <th></th>
            <!-- Editieren und löschen-->
        </tr>
        </thead>
        <ng-container *ngFor="let cost of this.accommodationOrTemplate.additionalCosts; index as i">
            <tr
                *ngIf="cost?.additionalCost !== 'ASENT' || this.authenticationService.currentUser.isEmployee()"
                [attr.data-id]="cost?.id"
                [attr.data-nondeleteable]="cost.deletable"
                (click)="addOrRemoveDeleteElement(cost?.id, $event);"
                [class.selected]="this.idsToDelete.includes(cost?.id) && this.deleteMultipleInProgress"
                [class.row-required]="this.additionalCostDefinitions[cost?.additionalCost]?.ownerHasToEdit"
            >
                <td [attr.data-label]="'atraveo.accommodationbundle.additionalcost.field.required' | translate"
                    *ngIf="cost?.obligation !== undefined">
                    <ng-container *ngIf='isUnmodifiedRequriedAddionalCost(cost); else isUnmodifiedRequriedAddionalCostElse'>
                        <app-tooltip data-placement="right" [text]="'atraveo.accommodationbundle.additionalcost.is-missing' | translate"></app-tooltip>
                    </ng-container>
                    <ng-template #isUnmodifiedRequriedAddionalCostElse>
                        {{ 'atraveo.accommodationbundle.price.create.additionalcost.field.obligation.' + cost?.obligation.toString() | translate }}
                    </ng-template>
                </td>
                <td [attr.data-label]="'atraveo.accommodationbundle.additionalcost.field.required' | translate"
                    *ngIf="cost?.obligation === undefined && this.additionalCostDefinitions[cost?.additionalCost]?.ownerHasToEdit">
                    <app-tooltip data-placement="right" [text]="'atraveo.accommodationbundle.additionalcost.is-missing' | translate"></app-tooltip>
                </td>

                <td [attr.data-label]="'atraveo.accommodationbundle.additionalcost.field.type' | translate">
                    {{ this.additionalCostDefinitions[cost?.additionalCost]?.additionalCostName[this.translationService.currentLang] }}
                    <span *ngIf="this.additionalCostDefinitions[cost?.additionalCost]?.ownerHasToEdit">*</span>
                    <span *ngIf="this.additionalCostDefinitions[cost?.additionalCost]?.deprecated">
                        <span data-placement="right" class="mat-tooltip" #tooltip="matTooltip" appShowTooltipOnClick
                           matTooltip="{{ 'atraveo.accommodationbundle.additionalcost.is-invalid' | translate }}<ul><li>{{
                                'atraveo.accommodationbundle.price.create.additionalcost.errors.nkcode' | translate
                            }}</li></ul>">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
                    </span>

                    <ng-container *ngIf='this.errors && this.errors[cost.additionalCost]'>
                        <span data-placement="right" class="mat-tooltip" #tooltip="matTooltip" appShowTooltipOnClick
                           matTooltip="{{ 'atraveo.accommodationbundle.additionalcost.is-invalid' | translate }}<ul>{{ this.errors[cost.additionalCost].join('') }}</ul>">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
                    </ng-container>

                </td>
                <td [attr.data-label]="'atraveo.accommodationbundle.additionalcost.field.rate.0' | translate">
                    <ng-container *ngIf="cost?.price > 0">
                        {{ cost?.price | currency: (cost?.currency ? cost.currency: 'EUR'):undefined:undefined:this.translationService.currentLang }}
                        <span
                            *ngIf="cost?.unit === 'PERSON'">{{ 'atraveo.accommodationbundle.additionalcost.unit-per-person' | translate }}</span>
                        <span
                            *ngIf="cost?.unit === 'HAUSTIER'">{{ 'atraveo.accommodationbundle.additionalcost.unit-per-pet' | translate }}</span>
                        <span
                            *ngIf="cost?.unit === 'STUECK'">{{ 'atraveo.accommodationbundle.additionalcost.unit-per-unit' | translate }}</span>
                        <span *ngIf="cost?.unit && !['PERSON', 'HAUSTIER', 'STUECK'].includes(cost.unit)">
                        / {{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.0' | translate }}
                            {{ 'atraveo.accommodationbundle.price.create.additionalcost.field.unit.' + cost.unit | translate }}
                        </span>
                    </ng-container>
                    <ng-container *ngIf='cost.percent'>
                        {{ cost.percent + '% ' }}
                        {{ 'atraveo.accommodationbundle.additionalcost.of.rentprice' | translate }}
                    </ng-container>
                </td>
                <td [attr.data-label]="'atraveo.accommodationbundle.additionalcost.field.perDuration' | translate">
                    <span *ngIf="cost?.interval">
                        {{ 'atraveo.accommodationbundle.price.create.additionalcost.field.interval.' + cost.interval.toString() | translate }}
                    </span>
                </td>
                <td [attr.data-label]="'atraveo.accommodationbundle.additionalcost.field.payable' | translate">
                    <span *ngIf="cost?.hasToBePaidLocal && cost.additionalCost != 'KAUTION' && !this.isUnmodifiedRequriedAddionalCost(cost)">
                        {{ 'atraveo.accommodationbundle.price.create.additionalcost.field.payableAtLocation.short' | translate }}
                    </span>
                    <span
                        *ngIf="cost?.paymentType === 'LOCAL_CASH'">{{ 'atraveo.accommodationbundle.deposit.paymenthandling.CashAtLocation' | translate }}</span>
                    <span
                        *ngIf="cost?.paymentType === 'CREDIT_CARD'">{{ 'atraveo.accommodationbundle.deposit.paymenthandling.ccAtLocation' | translate }}</span>
                    <span
                        *ngIf="cost?.paymentType === 'IN_ADVANCE'">{{ 'atraveo.accommodationbundle.deposit.paymenthandling.inAdvance' | translate }}</span>
                    <span *ngIf="cost?.paymentType && cost?.refundType"> / </span>
                    <span
                        *ngIf="cost?.refundType === 'LOCAL_CASH'">{{ 'atraveo.accommodationbundle.deposit.refundHandling.cashAtLocation' | translate }}</span>
                    <span
                        *ngIf="cost?.refundType === 'BANK_TRANSFER'">{{ 'atraveo.accommodationbundle.deposit.refundHandling.bankTransfer' | translate }}</span>
                    <span
                        *ngIf="cost?.refundType === 'CREDIT_CARD'">{{ 'atraveo.accommodationbundle.deposit.refundHandling.cc' | translate }}</span>
                </td>
                <td [attr.data-label]="'atraveo.accommodationbundle.additionalcost.field.limitations' | translate">
                    <p *ngIf="cost?.travelPeriod?.from || cost?.travelPeriod?.to">
                        <ng-container *ngIf="cost.travelPeriod?.from && !cost.travelPeriod?.to">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.DateFrom' | translate }}
                            {{ dateService.getFormattedDate(cost.travelPeriod.from, translationService.currentLang, cost.travelPeriod?.followingYears) }}
                        </ng-container>

                        <ng-container *ngIf="!cost.travelPeriod?.from && cost.travelPeriod?.to">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.DateTo' | translate }}
                            {{ dateService.getFormattedDate(cost.travelPeriod.to, translationService.currentLang, cost.travelPeriod?.followingYears) }}
                        </ng-container>

                        <ng-container *ngIf="cost.travelPeriod?.from && cost.travelPeriod?.to">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Date.prefix' | translate }}
                            {{ dateService.getFormattedDate(cost.travelPeriod.from, translationService.currentLang, cost.travelPeriod?.followingYears) }}
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Date.glue' | translate }}
                            {{ dateService.getFormattedDate(cost.travelPeriod.to, translationService.currentLang, cost.travelPeriod?.followingYears) }}
                        </ng-container>
                    </p>
                    <p *ngIf="cost?.calcAges?.min || cost?.calcAges?.max">
                        <ng-container *ngIf="cost.calcAges?.min && !cost.calcAges?.max">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.AgeFrom' | translate }}
                            {{ cost.calcAges.min }}
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Age.suffix' | translate }}
                        </ng-container>

                        <ng-container *ngIf="!cost.calcAges?.min && cost.calcAges?.max">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.AgeTo' | translate }}
                            {{ cost.calcAges.max }}
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Age.suffix' | translate }}
                        </ng-container>

                        <ng-container *ngIf="cost.calcAges?.min && cost.calcAges?.max">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Age.prefix' | translate }}
                            {{ cost.calcAges.min }}
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Age.glue' | translate }}
                            {{ cost.calcAges.max }}
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Age.suffix' | translate }}
                        </ng-container>
                    </p>
                    <p *ngIf="cost?.calcPersons?.min || cost?.calcPersons?.max">
                        <ng-container *ngIf="cost.calcPersons?.min && !cost.calcPersons?.max">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.PersonsFrom' | translate }}
                            {{ cost.calcPersons.min }}.
                            {{ 'atraveo.accommodationbundle.price.list.limitations.CalcForPerson.suffix' | translate }}
                        </ng-container>

                        <ng-container *ngIf="!cost.calcPersons?.min && cost.calcPersons?.max">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.PersonsTo' | translate }}
                            {{ cost.calcPersons.max }}.
                            {{ 'atraveo.accommodationbundle.price.list.limitations.CalcForPerson.suffix' | translate }}
                        </ng-container>

                        <ng-container *ngIf="cost.calcPersons?.min && cost.calcPersons?.max">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.CalcForPerson.prefix' | translate }}
                            {{ cost.calcPersons.min }}.
                            {{ 'atraveo.accommodationbundle.price.list.limitations.CalcForPerson.glue' | translate }}
                            {{ cost.calcPersons.max }}.
                            {{ 'atraveo.accommodationbundle.price.list.limitations.CalcForPerson.suffix' | translate }}
                        </ng-container>
                    </p>
                    <p *ngIf="cost?.calcDays?.min || cost?.calcDays?.max">
                        <ng-container *ngIf="cost.calcDays?.min && !cost.calcDays?.max">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.CalcForDayFrom' | translate }}
                            {{ cost.calcDays.min }}.
                            {{ 'atraveo.accommodationbundle.price.list.limitations.CalcForDay.suffix' | translate }}
                        </ng-container>

                        <ng-container *ngIf="!cost.calcDays?.min && cost.calcDays?.max">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.CalcForDayTo' | translate }}
                            {{ cost.calcDays.max }}.
                            {{ 'atraveo.accommodationbundle.price.list.limitations.CalcForDay.suffix' | translate }}
                        </ng-container>

                        <ng-container *ngIf="cost.calcDays?.min && cost.calcDays?.max">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.CalcForDay.prefix' | translate }}
                            {{ cost.calcDays.min }}.
                            {{ 'atraveo.accommodationbundle.price.list.limitations.CalcForDay.glue' | translate }}
                            {{ cost.calcDays.max }}.
                            {{ 'atraveo.accommodationbundle.price.list.limitations.CalcForDay.suffix' | translate }}
                        </ng-container>
                    </p>
                    <p *ngIf="cost?.occupancy?.min || cost?.occupancy?.max">
                        <ng-container *ngIf="cost.occupancy?.min && !cost.occupancy?.max">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.PersonsFrom' | translate }}
                            {{ cost.occupancy.min }}
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Persons.suffix' | translate }}
                        </ng-container>

                        <ng-container *ngIf="!cost.occupancy?.min && cost.occupancy?.max">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.PersonsTo' | translate }}
                            {{ cost.occupancy.max }}
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Persons.suffix' | translate }}
                        </ng-container>

                        <ng-container *ngIf="cost.occupancy?.min && cost.occupancy?.max">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Persons.prefix' | translate }}
                            {{ cost.occupancy.min }}
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Persons.glue' | translate }}
                            {{ cost.occupancy.max }}
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Persons.suffix' | translate }}
                        </ng-container>
                    </p>
                    <p *ngIf="cost?.duration?.min || cost?.duration?.max">
                        <ng-container *ngIf="cost.duration?.min && !cost.duration?.max">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.OccupancyFrom' | translate }}
                            {{ cost.duration.min }}
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Occupancy.suffix' | translate }}
                        </ng-container>

                        <ng-container *ngIf="!cost.duration?.min && cost.duration?.max">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.OccupancyTo' | translate }}
                            {{ cost.duration.max }}
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Occupancy.suffix' | translate }}
                        </ng-container>

                        <ng-container *ngIf="cost.duration?.min && cost.duration?.max">
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Occupancy.prefix' | translate }}
                            {{ cost.duration.min }}
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Occupancy.glue' | translate }}
                            {{ cost.duration.max }}
                            {{ 'atraveo.accommodationbundle.price.list.limitations.Occupancy.suffix' | translate }}
                        </ng-container>
                    </p>

                    <ng-container *ngIf='cost.minPrice || cost.maxPrice'>
                        <p *ngIf='cost.minPrice'>
                            {{ 'atraveo.accommodationbundle.price.list.limitations.MinRate' | translate: {
                            rate: cost.minPrice,
                            currency: cost.currency
                        } }}
                        </p>
                        <p *ngIf='cost.maxPrice'>
                            {{ 'atraveo.accommodationbundle.price.list.limitations.MaxRate' | translate: {
                            rate: cost.maxPrice,
                            currency: cost.currency
                        } }}
                        </p>
                    </ng-container>
                </td>
                <td class="text-nowrap text-right">
                    <span *ngIf="cost?.source !== 'generated' && cost?.additionalCost !== 'ASENT'">
                        <a (click)="editAddtionalCost(cost)">
                            <i class="fa fa-pen fa-lg"></i>
                        </a>

                        <a
                            *ngIf="cost.deletable"
                            data-toggle="popover-additional-cost"
                            tabindex="0"
                            data-trigger="focus"
                            (click)="openConfirmationDialog(cost)"
                        >
                            <i class="fa fa-trash-alt fa-lg ml-2"></i>
                        </a>
                    </span>
                    <span *ngIf="cost?.source === 'generated'">
                        <a routerLink="/additional-cost-templates" routerLinkActive="true">
                            <i class="fas fa-external-link-square-alt fa-lg"></i>
                        </a>
                    </span>
                </td>
            </tr>
        </ng-container>
    </table>

    <div class="row">
        <div class="col-lg-6 col-12 mb-2">
            <button id="{{ idPrefix + '-' }}create-additional-cost" (click)="createAdditionalCost()" class="btn btn-secondary">
                + {{ 'atraveo.accommodationbundle.additionalcost.add-additionalcost' | translate }}
            </button>
        </div>
        <div class="col-lg-6 col-12">
            <ng-container *ngIf="accommodationOrTemplate.additionalCosts.length > 1">
                <span
                    class="delete-explanation d-none">{{ 'atraveo.accommodationbundle.price.delete.deleteMultiple' | translate }}</span>
                <button id="{{ idPrefix + '-' }}abort-delete-additional-costs" (click)="this.cancelDeleteMultiple()"
                        class="btn btn-primary float-right d-none">{{ 'button.cancel' | translate }}</button>
                <button id="{{ idPrefix + '-' }}delete-multiple-additional-costs"  (click)="this.deleteMultiple()"
                        class="btn btn-primary float-right">
                    {{ 'atraveo.accommodationbundle.additionalCost.delete.deleteMultiple' | translate }}
                </button>
            </ng-container>
        </div>
    </div>

    <span class="float-right">{{ 'text.mandatoryfield' | translate }}</span>
</div>
