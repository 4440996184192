import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { ToogleMenuService } from '../../../services/toggle-menu/toggle-menu.service';

@Component({
    selector: 'app-main-header',
    templateUrl: './main-header.component.html',
    styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {
    constructor(readonly authenticationService: AuthenticationService, private toggleMenuService: ToogleMenuService) {}
    ngOnInit() {}

    toggleMenu() {
        this.toggleMenuService.toggleMenu();
    }
}
