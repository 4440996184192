import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-imprint',
    templateUrl: './imprint.component.html',
    styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent implements OnInit {
    constructor(readonly translationService: TranslateService) {}

    ngOnInit(): void {}
}
