<div class="row">
    <div class="col-12">
        <h1>{{ 'ownerlist.title' | translate }} <button class="btn btn-danger" *ngIf="mainUserID" (click)="changeUser(mainUserID)"><i class="fas fa-user mr-2"></i> {{ 'ownerlist.backto' | translate }} {{ mainUserID }}</button></h1>
        <div class="row">
            <div class="col-12 col-lg-6 col-xl-3">
                <form [formGroup]="searchForm">
                    <input type="text" class="form-control" placeholder="{{ 'button.search' | translate }}" formControlName="searchString">
                </form>
            </div>
            <div class="col-12 col-lg-6 col-xl-9 text-right">
                <button class="btn btn-success my-2" (click)="addOwner()">{{ 'ownerlist.addOwner' | translate }}</button>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>{{ 'text.owner.number' | translate }}</th>
                        <th>{{ 'atraveo.accommodationbundle.entity.accommodationgroup.name' | translate }}</th>
                        <th>{{ 'atraveo.profilebundle.entity.user.email' | translate }}</th>
                        <th>{{ 'atraveo.profilebundle.entity.user.street' | translate }}</th>
                        <th>{{ 'atraveo.profilebundle.entity.user.postcode' | translate }} {{ 'atraveo.profilebundle.entity.user.postcode' | translate }}</th>
                        <th>{{ 'atraveo.profilebundle.entity.user.country' | translate }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let owner of filteredOwners">
                        <td>{{ owner.ownerNumber}}</td>
                        <td>{{ owner.addressBook.surname}}, {{ owner.addressBook.forename}}</td>
                        <td>{{ owner.addressBook.email}}</td>
                        <td>{{ owner.addressBook.street}}</td>
                        <td>{{ owner.addressBook.zipCode}} {{ owner.addressBook.city}}</td>
                        <td>{{ owner.addressBook.countryISOCode}}</td>
                        <td><button class="btn btn-secondary" (click)="changeUser(owner.ownerNumber)" [disabled]="noUserChangePossible"><i class="fas fa-user mr-2"></i>{{ 'text.edit' | translate }}</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
