import { Component } from '@angular/core';
import { DialogService } from '../../../../services/dialog.service';

@Component({
    selector: 'app-advanced-settings-dialog',
    templateUrl: './advanced-settings-dialog.component.html',
    styleUrl: './advanced-settings-dialog.component.scss',
})
export class AdvancedSettingsDialogComponent {
    constructor(readonly dialogService: DialogService) {}

    closeDialog() {
        this.dialogService.closeDialog();
    }
}
