<p>{{ 'atraveo.invoice.homepage.header' | translate }}</p>

<div class="row form-group col-sm-6">
    <div class="col-sm-1 field-label">
        <label>{{ 'atraveo.accommodationbundle.price.create.period.field.dateFrom' | translate }}</label>
    </div>
    <div class="col-sm-5">
        <input type="date" name="from" class="form-control" [(ngModel)]="invoiceRequest.dateFrom" [ngModelOptions]="{standalone: true}">
    </div>
    <div class="col-sm-1 field-label">
        <label>{{ 'atraveo.accommodationbundle.price.create.period.field.dateTo' | translate }}</label>
    </div>
    <div class="col-sm-5">
        <input type="date" name="to" class="form-control" [(ngModel)]="invoiceRequest.dateTo" [ngModelOptions]="{standalone: true}">
    </div>
</div>
<div class="row form-group col-sm-6">
    <div class="col-sm-3">
        <p>{{ 'text.booking.number' | translate }}</p>
    </div>
    <div class="col-sm-6">
        <input type="text" name="invoice-filter" class="form-control" [(ngModel)]="invoiceRequest.bookingNumber" [ngModelOptions]="{standalone: true}">
    </div>
    <div class="col-sm-3 filter-btn">
        <a (click)="filterInvoices()" class="btn btn-success">{{ 'button.filter' | translate }}</a>
    </div>
</div>

<table class="responsive-table">
    <thead>
        <tr>
            <th><app-sort-arrows (ngModelChange)="refresh($event, 'date')" [(ngModel)]="sortDate" [translationKey]="'text.date.0'"></app-sort-arrows></th>
            <th><app-sort-arrows (ngModelChange)="refresh($event, 'price')" [(ngModel)]="sortPrice" [translationKey]="'text.amount'"></app-sort-arrows></th>
            <th><!-- Icons --></th>
        </tr>
    </thead>

    <ng-container *ngFor="let invoice of invoices; let invoiceIndex = index">
        <tr *ngIf="invoiceIndex < invoices.length - 1">
            <td [attr.data-label]="'text.date.0' | translate">{{ invoice.date }}</td>
            <td [attr.data-label]="'text.amount' | translate">{{ invoice.sum }}</td>
            <td><a (click)="getPdf(invoice.id)" class="btn btn-link p-0"><i class="fas fa-download fa-2x ml-2"></i></a></td>
        </tr>
    </ng-container>
</table>

<div *ngFor="let invoice of invoices | paginate: { id: 'invoice-pagination', itemsPerPage: invoiceRequest.limit, currentPage: invoiceRequest.page, totalItems: invoicesCount }"></div>

<pagination-controls id="invoice-pagination" class="float-right pagination-control"
                     (pageChange)="changePage($event)"
                     previousLabel=""
                     nextLabel="">
</pagination-controls>
