import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { RegistrationDataService } from '../../services/registration/registration-data.service';
import { TranslateService } from '@ngx-translate/core';
import { BackOfficeConnectorService } from '../../services/api-connector/back-office-connector-service';
import { OwnerTermsOfServiceSettingsDto } from 'data-structures/lib/es6/dto/owner/put-owner/owner-terms-of-service-settings.dto';
import { ToogleMenuService } from '../../services/toggle-menu/toggle-menu.service';
import { environment } from '../../../environments/environment';
@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
    constructor(
        readonly authenticationService: AuthenticationService,
        readonly registrationData: RegistrationDataService,
        readonly translateService: TranslateService,
        readonly backofficeConnector: BackOfficeConnectorService,
        public toggleMenuService: ToogleMenuService,
    ) {}

    async ngOnInit() {
        if (this.authenticationService?.currentUser?.isEmployee()) {
            environment.allowedLanguages.push('da', 'sv');
        }
        // @ts-ignore
        $('.example-popover').popover({
            container: 'body',
        });

        if (!this.authenticationService?.currentUser?.termsOfServiceSettings && this.authenticationService?.currentUser?.ownerNumber) {
            const terms = await this.backofficeConnector.getTerms(this.authenticationService.currentUser.ownerNumber, this.translateService.currentLang);
            this.authenticationService.currentUser.termsOfServiceSettings = new OwnerTermsOfServiceSettingsDto();
            this.authenticationService.currentUser.termsOfServiceSettings.acceptedId = Number(terms.data.acceptedId);
        }

        if (this.authenticationService?.currentUser?.settings?.language && this.authenticationService.currentUser.isOwner()) {
            this.translateService.use(this.authenticationService.currentUser.settings.language);
        }
    }
}
