import { OwnerEntity } from 'data-structures';
import { OwnerContactDto } from 'data-structures/lib/es6/dto/owner/put-owner/owner-contact.dto';
import { Exclude, plainToInstance, Type } from 'class-transformer';
import { ConstantsService } from '../services/constants/constants.service';
import { ExtendedOwnerPaymentDto } from './extened-owner-payment.dto';
import { ExtendedBankAccountEntity } from './extended-bank-account-entity';
import { ChannelTypeEnum } from 'data-structures/lib/es6/enum/channel-type.enum';
import { OwnerCurrencyEnum, OwnerImportTypeEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root',
})
export class ExtendedUserEntity extends OwnerEntity {
    @Exclude()
    username: string;
    @Exclude()
    password: string;
    @Exclude({ toPlainOnly: true })
    token: string;
    roles: string[] = [ConstantsService.ROLE_OWNER];
    @Exclude()
    countryAccommodation: string = ''; // Leerer String damit die Selectbox richtig funktioniert
    @Exclude()
    accommodationCount: number;
    @Exclude()
    lastLoginDate: Date; // Letzter Login vor diesem
    @Exclude()
    jwtDate: Date; // Alter des JWT-Tokens. Wir nehmen das nicht aus dem Token, weil die Uhr abweichen könnte
    @Exclude()
    tokenValidUntil: Date; // Gültig bis wann der Token ist

    @Type(() => ExtendedOwnerPaymentDto)
    payment: ExtendedOwnerPaymentDto;

    @Exclude()
    skippedSteps: boolean[] = [];

    destinationPools: number[] = [];

    hasRole(roleName: string): boolean {
        return this.roles.indexOf(roleName) !== -1;
    }

    isEmployee() {
        return this.hasRole(ConstantsService.ROLE_ADMIN) || this.hasRole(ConstantsService.ROLE_OWNERSERVICE);
    }
    isDestination() {
        return this.hasRole(ConstantsService.ROLE_DESTINATION) || this.hasRole(ConstantsService.ROLE_ADMIN) || this.hasRole(ConstantsService.ROLE_OWNERSERVICE);
    }

    isOwner() {
        return this.hasRole(ConstantsService.ROLE_OWNER) && !this.isEmployee();
    }

    isAdmin() {
        return this.hasRole(ConstantsService.ROLE_ADMIN);
    }

    isImported() {
        return this.settings?.importType === OwnerImportTypeEnum.Imported;
    }

    hasClient(): boolean {
        return !!this.settings?.client;
    }

    getPrimaryContact(): OwnerContactDto {
        if (this.addressBook?.contacts && this.addressBook.primaryId !== null) {
            return this.addressBook.contacts[this.addressBook.primaryId];
        }

        return new OwnerContactDto();
    }
    getTakeAllAccommodationsOffline(): boolean {
        return this.state?.takeAllAccommodationsOffline;
    }

    // Vor dem schicken ans Inventory unnötige / verbotene Felder entfernen
    removeUnnecessaryValues() {
        if (this.payment?.bankAccounts && Object.keys(this.payment.bankAccounts).length) {
            for (const index in this.payment.bankAccounts) {
                if (!this.payment.bankAccounts[index].hasFilledFields()) {
                    delete this.payment.bankAccounts[index];
                }
            }

            for (const index in this.payment.bankAccounts) {
                if (this.payment.bankAccounts[index] && !Object.keys(this.payment.bankAccounts[index]).some((key) => this.payment.bankAccounts[index][key].length)) {
                    delete this.payment.bankAccounts[index];
                } else {
                    this.payment.bankAccounts[index].removeUnnecessaryValues();
                }
            }
        }

        // Es gibt angelegte Telefonnummern wo die Nummern fehlen
        // Es gibt angelegte Lage wo die Latitude oder longitude fehlen
        if (this.addressBook?.contacts && Object.keys(this.addressBook?.contacts).length) {
            Object.values(this.addressBook.contacts).forEach((contact, contactId) => {
                if (contact.phoneNumbers?.length) {
                    for (let index = contact.phoneNumbers.length; index--; ) {
                        if (!contact.phoneNumbers[index].prefix && !contact.phoneNumbers[index].number) {
                            contact.phoneNumbers.splice(index, 1);
                        }
                    }
                }
                if (contact.location && Object.keys(contact.location).length) {
                    for (const index in contact.location) {
                        if (!contact.location[index]) {
                            delete contact.location[index];
                        }
                    }
                }
            });
        }

        if (this.registration?.countryAccommodationISOCode === '') {
            delete this.registration.countryAccommodationISOCode;
        }

        // Es gibt keinen Grund jemals den State vom Vermieter ans Inventory zu schicken und führt auch zu Problemen
        delete this.state;

        for (const field of ['activateWithoutQualify', 'collectionType', 'hasOwnContract', 'importType']) {
            if (this.settings?.hasOwnProperty(field)) {
                delete this.settings[field];
            }
        }

        for (const field of ['checkDateTime', 'checked']) {
            if (this.vat?.hasOwnProperty(field)) {
                delete this.vat[field];
            }
        }

        for (const field of ['addressActivation', 'recommendationRate', 'priceSettings', 'travelInsuranceCertificate']) {
            if (this.hasOwnProperty(field)) {
                delete this[field];
            }
        }

        if (this.vat && !Object.keys(this.vat).length) {
            delete this.vat;
        }
    }

    convertBankAccounts() {
        if (this.payment?.bankAccounts && Object.keys(this.payment?.bankAccounts).length) {
            for (const [key, value] of Object.entries(this.payment?.bankAccounts)) {
                this.payment.bankAccounts[key] = plainToInstance(ExtendedBankAccountEntity, value);
            }
        }
    }

    getChannelByType(channelType: ChannelTypeEnum) {
        if (!this.channels?.length) {
            return null;
        }

        this.channels.find((channel) => {
            return channelType === channel.type;
        });
    }

    getOwnerCurrency() {
        return this?.settings?.currency ? this.settings.currency : OwnerCurrencyEnum.EUR;
    }

    // Muss der User ins Onboarding?
    needsOnboarding() {
        return this.tracking?.onboarding?.registrationStarted ? true : false;
    }

    /**
     * Gibt den aktuellen Schritt im Onboarding zurück, bestehende User bekommen das onboarding property nicht,
     * haben somit immer 0 und kommen direkt auf das dashboard
     */
    getOnboardingStep() {
        const registrationDone = this.tracking?.onboarding?.registrationStarted;
        const verificationStarted = this.tracking?.onboarding?.verificationStarted;
        const accommodationCreationStarted = this.tracking?.onboarding?.accommodationCreationStarted;
        const profileDone = this.tracking?.onboarding?.completeProfileStarted;

        let actStep = 0;
        if (registrationDone) {
            if (verificationStarted || accommodationCreationStarted || profileDone) {
                actStep = 4;
                return actStep;
            }
            actStep++;
            if (accommodationCreationStarted || this.skippedSteps[1] === true) {
                actStep++;
                if (profileDone || this.skippedSteps[2] === true) {
                    actStep++;
                    if (verificationStarted || this.skippedSteps[3] === true) {
                        actStep++;
                    }
                }
            }
        }
        return actStep;
    }

    getCompletedOnboardingSteps() {
        const registrationDone = this.tracking?.onboarding?.registrationStarted;
        const verificationDone = this.tracking?.onboarding?.verificationCompleted;
        const accommodationCreated = this.tracking?.onboarding?.accommodationCreationCompleted;
        const profileDone = this.tracking?.onboarding?.completeProfileStarted;

        const completedSteps = [];
        if (registrationDone) {
            if (accommodationCreated) {
                completedSteps[1] = true;
            }
            if (profileDone) {
                completedSteps[2] = true;
            }
            if (verificationDone) {
                completedSteps[3] = true;
            }
        }
        return completedSteps;
    }

    getInProgressOnboardingSteps() {
        const registrationDone = this.tracking?.onboarding?.registrationStarted;
        const accommodationCreationStarted = this.tracking?.onboarding?.accommodationCreationStarted && !this.tracking?.onboarding?.accommodationCreationCompleted;
        const verificationStarted = this.tracking?.onboarding?.verificationStarted && !this.tracking?.onboarding?.verificationCompleted;

        const inProgressSteps = [];
        if (registrationDone) {
            if (accommodationCreationStarted) {
                inProgressSteps[1] = true;
            }
            if (verificationStarted) {
                inProgressSteps[3] = true;
            }
        }
        return inProgressSteps;
    }

    setSkippedOnBoardingSteps(step) {
        this.skippedSteps[step] = true;
    }
}
