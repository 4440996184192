<div class="explanation-with-images alert info m-0 p-0 flex-column align-items-lg-start">
    <button (click)="isCollapsed = !isCollapsed" data-toggle="collapse" [attr.data-target]="'#collapseGuidelines'" class="btn btn-link d-block d-sm-none guideline-info d-flex align-items-center text-left text-decoration-none">
        <i class="fas fa-info-circle mr-2"></i>{{ 'text.images.community.guidelines.info' | translate }}
        <i class="fa arrow-sm ml-2" [ngClass]="isCollapsed ? 'fa-chevron-down' : 'fa-chevron-up'"></i>
    </button>
    <div class="d-none d-sm-block guideline-info align-items-center"><i class="fas fa-info-circle mr-2"></i>{{ 'text.images.community.guidelines.info' | translate }}</div>
    <div class="collapse" id="collapseGuidelines">
        <ul class="pt-1 pb-3">
            <li><i class="fa fa-check mr-2"></i>{{ 'text.images.community.guidelines.format' | translate }}</li>
            <li><i class="fa fa-check mr-2"></i>{{ 'text.images.community.guidelines.size' | translate }}</li>
            <li><i class="fa fa-check mr-2"></i>{{ 'text.images.community.guidelines.minor' | translate }}</li>
            <li><i class="fa fa-check mr-2"></i>{{ 'text.images.community.guidelines.adults' | translate }}</li>
            <li><i class="fa fa-check mr-2"></i>{{ 'text.images.community.guidelines.thirdpartysources' | translate }}</li>
            <li><i class="fa fa-check mr-2"></i>{{ 'text.images.community.guidelines.additionaldata' | translate }}</li>
        </ul>
        <p [innerHTML]="'text.images.community.guidelines.agreement' | translate"></p>
    </div>
</div>
