import { Injectable } from '@angular/core';
import { ExtendedUserEntity } from '../../entities/extendedUserEntity';
import { OwnerAddressBookDto } from 'data-structures/lib/es6/dto/owner/OwnerAddressBookDto';
import { OwnerContactDto } from 'data-structures/lib/es6/dto/owner/put-owner/owner-contact.dto';
import { PhoneNumber, PhoneNumberTypeEnum } from 'data-structures/lib/es6/dto/common/phone-number';
import { OwnerAcquisitionEnum, OwnerStateDto } from 'data-structures/lib/es6/dto/owner/put-owner/owner-state.dto';
import { OwnerImportTypeEnum, OwnerSettingsDto, OwnerTypeEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { OwnerRegistrationDto } from 'data-structures/lib/es6/dto/owner/put-owner/owner-registration.dto';
import { OwnerTermsOfServiceSettingsDto } from 'data-structures/lib/es6/dto/owner/put-owner/owner-terms-of-service-settings.dto';
import { TranslateService } from '@ngx-translate/core';
import { SalutationEnum } from 'data-structures/lib/es6/enum/salutation.enum';
import { ExtendedOwnerPaymentDto } from '../../entities/extened-owner-payment.dto';
import { LocationDto } from 'data-structures/lib/es6/dto/accommodation/position/location.dto';
import { v4 as uuidv4 } from 'uuid';
import { OwnerTrackingDto } from 'data-structures/lib/es6/dto/owner/OwnerTrackingDto';

@Injectable({
    providedIn: 'root',
})
export class OwnerService {
    constructor(readonly translationService: TranslateService) {}

    createNewOwner(language: string): ExtendedUserEntity {
        const user = new ExtendedUserEntity();
        const newUserId = uuidv4();
        user.addressBook = new OwnerAddressBookDto();
        user.addressBook.contacts = {};
        user.addressBook.contacts[newUserId] = new OwnerContactDto();
        user.addressBook.contacts[newUserId].phoneNumbers = [];
        user.addressBook.contacts[newUserId].phoneNumbers.push(this.createPhoneNumber(PhoneNumberTypeEnum.phone));
        user.addressBook.contacts[newUserId].phoneNumbers.push(this.createPhoneNumber(PhoneNumberTypeEnum.phone));
        user.addressBook.contacts[newUserId].phoneNumbers.push(this.createPhoneNumber(PhoneNumberTypeEnum.mobile));
        user.addressBook.contacts[newUserId].email = [''];
        user.addressBook.primaryId = newUserId;
        user.state = new OwnerStateDto();
        user.state.active = false;
        user.state.acquisition = OwnerAcquisitionEnum.New;
        user.settings = new OwnerSettingsDto();
        user.settings.importType = OwnerImportTypeEnum.SelfRecorded;
        user.settings.ownerType = OwnerTypeEnum.PrivateOwner;
        user.settings.client = 1;
        user.settings.clientOrigin = 14;
        user.settings.language = language;
        user.registration = new OwnerRegistrationDto();
        user.payment = new ExtendedOwnerPaymentDto();
        user.payment.bankAccounts = {};
        user.termsOfServiceSettings = new OwnerTermsOfServiceSettingsDto();
        user.tracking = new OwnerTrackingDto();

        return user;
    }

    createNewContact(user: ExtendedUserEntity, primaryId: string) {
        user.addressBook.contacts[primaryId] = new OwnerContactDto();
        user.addressBook.contacts[primaryId].location = new LocationDto();
        user.addressBook.contacts[primaryId].spokenLanguages = [];
        user.addressBook.contacts[primaryId].phoneNumbers = [];
        user.addressBook.contacts[primaryId].phoneNumbers.push(this.createPhoneNumber(PhoneNumberTypeEnum.phone));
        user.addressBook.contacts[primaryId].phoneNumbers.push(this.createPhoneNumber(PhoneNumberTypeEnum.phone));
        user.addressBook.contacts[primaryId].phoneNumbers.push(this.createPhoneNumber(PhoneNumberTypeEnum.mobile));
    }

    createPhoneNumber(type: PhoneNumberTypeEnum): PhoneNumber {
        const phone = new PhoneNumber();
        phone.type = type;
        return phone;
    }

    async getDisplayName(owner: ExtendedUserEntity, language: string = null): Promise<string> {
        const salutationTranslations = {
            [SalutationEnum.Mr]: 'male',
            [SalutationEnum.Ms]: 'female',
            [SalutationEnum.Company]: 'company',
            [SalutationEnum.Family]: 'family',
            [SalutationEnum.Other]: 'other',
        };

        if (!language) {
            language = this.translationService.currentLang;
        }

        const oldLanguage = this.translationService.currentLang;
        this.translationService.use(language);

        const contact = owner.getPrimaryContact();
        const nameComponents = [];

        if (contact.salutation && contact.salutation in salutationTranslations) {
            const translationKey = 'text.salutation.' + salutationTranslations[contact.salutation];
            const salutation = await this.translationService.get(translationKey).toPromise();
            nameComponents.push(salutation);
        }

        if (contact.forename) {
            nameComponents.push(contact.forename);
        }

        if (contact.surname) {
            nameComponents.push(contact.surname);
        }

        this.translationService.use(oldLanguage);

        return nameComponents.join(' ');
    }
}
