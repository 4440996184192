<p [innerHTML]="'atraveo.profilebundle.homepage.header.1' | translate"></p>
<p [innerHTML]="'atraveo.profilebundle.homepage.header.2' | translate"></p>

<div class="profile-wrapper">
    <div id="accordion" class="accordion">
        <div class="card mb-2">
            <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <i class="fa fa-chevron-down"></i> {{ 'text.contact.info.atraveo' | translate }}
                    </button>
                </h5>
            </div>

            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body pl-1">
                    <app-registration-form [newUser]="false" [isUserProfile]="true" [primaryId]="ownerProfile"></app-registration-form>
                </div>
            </div>
        </div>

        <div class="card mb-2">
            <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <i class="fa fa-chevron-down"></i> {{ 'text.languages' | translate }}
                    </button>
                </h5>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body pl-1">
                    <div class="row">
                        <div class="col">
                            <label for="main-language">{{ 'text.language.0' | translate }}</label>
                        </div>

                        <div class="col" *ngIf="owner.settings && languages">
                            <select class="form-control custom-select" id="main-language" name="main-language" [(ngModel)]="owner.settings.language">
                                <option *ngFor="let language of languages" value="{{ language }}">
                                    {{ 'intl.language.name.' + language | translate }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <button (click)="save()" class="btn btn-success"><i class="fas fa-cloud-upload-alt mr-2"></i>{{ 'button.save' | translate }} </button>
                </div>
            </div>
        </div>

        <!--
        <div class="card mb-2">
            <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree"
                            aria-expanded="false" aria-controls="collapseThree">
                        <i class="fa fa-chevron-down"></i> {{ 'text.absences' | translate }}
                    </button>
                </h5>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                <div class="card-body">
                    <p>{{ 'text.absence.info.contact' | translate }}</p>

                    <div *ngIf="owner?.settings?.absences?.length">
                        <div *ngFor="let absence of owner.settings.absences">
                            <div class="row">
                                <div class="col">
                                    <label for="from-date">{{ 'text.date.start' | translate }}</label>
                                </div>
                                <div class="col">
                                    <input type="date" id="from-date" [(ngModel)]="absence.absence.from">

                                </div>
                                <div class="col">
                                    <label for="to-date">{{ 'text.date.end' | translate }}</label>
                                </div>
                                <div class="col">
                                    <input type="date" id="to-date" [(ngModel)]="absence.absence.to">
                                </div>

                            </div>
                        </div>
                    </div>

                    <a (click)="addAbsence()" class="btn btn-primary">
                        <i class="fas fa-plus mr-2"></i>{{ 'text.absence.add' | translate }}
                    </a>
                    <a (click)="save()" class="btn btn-success">
                        <i class="fas fa-cloud-upload-alt mr-2"></i>{{ 'button.save' | translate }}
                    </a>
                </div>
            </div>
        </div>
        -->

        <div class="card mb-2">
            <div class="card-header" id="headingBookingStop">
                <h5 class="mb-0">
                    <button class="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#bookingStop"
                            aria-expanded="false"
                            aria-controls="bookingStop">
                        <i class="fa fa-chevron-down"></i>
                        <span *ngIf="isBookingModelUser; else bookingSettings">
                            {{ 'atraveo.profilebundle.entity.user.bookingsettings' | translate }}
                        </span>
                        <ng-template #bookingSettings>
                            {{ 'atraveo.profilebundle.entity.user.bookingstopdays' | translate }}
                        </ng-template>
                    </button>
                </h5>
            </div>
            <div id="bookingStop" class="collapse" aria-labelledby="headingBookingStopbookingStop" data-parent="#accordion">
                <div class="card-body pl-1">
                    <form [formGroup]="bookingTypeForm" (ngSubmit)="save()">
                        <div class="row">
                            <div class="col-12">
                                <label for="bookingstopdays"><strong>{{ 'atraveo.profilebundle.entity.user.bookingstopdays' | translate }}</strong></label>
                            </div>
                            <div class="d-flex w-100">
                                <div class="col d-flex align-items-center">
                                    <div *ngIf="owner.settings" class="mr-3">
                                        <select id="bookingstopdays"
                                                class="form-control custom-select"
                                                name="bookingstopdays"
                                                [(ngModel)]="owner.settings.bookingStopDays"
                                                [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let i of this.bookingStopSelectBoxArray" [ngValue]="i">{{ i }}</option>
                                        </select>
                                    </div>
                                    {{ 'text.booking.stop.arrival' | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3" *ngIf="isBookingModelUser">
                            <div class="col-12 mt-3">
                                <strong>{{ 'atraveo.profilebundle.entity.user.bookingreceive.0' | translate }}</strong>
                            </div>
                            <div class="col-12 mt-2 d-flex">
                                <div class="col-sm-3 d-flex align-items-center pl-0 mr-5 custom-radio">
                                    <label for="bookingTypeDirect" class="mb-0"><span class="label">{{ 'atraveo.profilebundle.entity.user.bookingreceive.directly' | translate }}</span>
                                        <input type="radio" name="bookingType" id="bookingTypeDirect" formControlName="bookingType" [value]="true">
                                        <span class="radio-btn"><i class="check-icon"></i></span>
                                    </label>
                                </div>
                                <div class="col-sm-3 d-flex align-items-center pl-0 custom-radio">
                                    <label for="bookingTypeOnRequest" class="mb-0"><span class="label">{{ 'atraveo.profilebundle.entity.user.bookingreceive.inquiry' | translate }}</span>
                                        <input type="radio" name="bookingType" id="bookingTypeOnRequest" formControlName="bookingType" [value]="false">
                                        <span class="radio-btn"><i class="check-icon"></i></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-success mt-3">{{ 'button.save' | translate }} </button>
                        <div class="row" *ngIf="isBookingModelUser">
                            <div class="col mt-3">
                                <p [innerHTML]="'atraveo.profilebundle.entity.user.bookingreceive.info' | translate"></p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="card mb-2">
            <div class="card-header" id="headingSix">
                <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        <i class="fa fa-chevron-down"></i> {{ 'text.banking.information' | translate }}
                    </button>
                </h5>
            </div>
            <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                <div class="card-body pl-1" *ngIf="bankAccounts && bankAccounts.length">
                    <table class="table responsive-table">
                        <thead>
                            <tr>
                                <th>{{ 'atraveo.accommodationbundle.entity.additionalcost.type' | translate }}</th>
                                <th>{{ 'text.bank.account.holder.0' | translate }}</th>
                                <th>{{ 'text.bank.account.number' | translate }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let bankAccount of bankAccounts"
                                (click)="addOrRemoveDeleteBankAccount(bankAccount.id)" [class.selected]="this.toDeleteBankAccounts.includes(bankAccount.id) && this.deleteMultipleBankAccountsInProgress">
                                <td>
                                    <label *ngIf="bankAccount.default">{{ 'text.bank.account.MainBankaccount' | translate }}</label>
                                    <label *ngIf="!bankAccount.default">{{ 'text.bank.account.ObjectBankaccount' | translate }}</label>
                                    <p *ngIf="bankAccount.accommodations.length" class="small">{{ bankAccount.accommodations.join(', ') }}</p>
                                </td>
                                <td>{{ bankAccount.accountHolder }}</td>
                                <td>{{ bankAccount.accountNumber }}</td>
                                <td class="small text-nowrap text-right">
                                    <button class="btn p-0"
                                            (click)="editBankAccount(bankAccount.id)">
                                        <i class="fa fa-pen fa-lg"></i>
                                    </button>
                                    <button type="button" class="btn btn-link p-0" (click)="openConfirmationDialog(bankAccount.id)" [disabled]="authService.currentUser.isAdmin() || !bankAccount.accountNumber">
                                        <i class="fa fa-trash-alt fa-lg ml-2"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <div class="col-12 col-lg-6 mb-2">
                            <button *ngIf='this.hasMainBankAccount()'
                                    class="btn btn-secondary"
                                    (click)="addBankAccount()"
                                    [disabled]="authService.currentUser.isAdmin()">
                                <i class="fas fa-plus mr-2"></i>
                                {{ 'user.profile.addBankAccount.0' | translate }}
                            </button>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="delete-multiple text-right">
                                <span *ngIf="deleteMultipleBankAccountsInProgress" class="mr-3">
                                    {{ 'atraveo.accommodationbundle.delete.multipleTitle' | translate }}
                                </span>
                                <button *ngIf="deleteMultipleBankAccountsInProgress" class="btn btn-primary mb-2" (click)="deleteMultipleBankAccounts()">
                                    {{ 'atraveo.accommodationbundle.delete.confirmDeleteMultipleWithNumber' | translate: {number: toDeleteBankAccounts.length} }}
                                </button>
                                <button *ngIf="deleteMultipleBankAccountsInProgress" class="btn btn-primary ml-3 mb-2" (click)="this.abortDeleteMultiple()">
                                    {{ 'button.cancel' | translate }}
                                </button>
                                <button *ngIf="!deleteMultipleBankAccountsInProgress" class="btn btn-primary" (click)="this.deleteMultipleBankAccountsInProgress = true" [disabled]="authService.currentUser.isAdmin()">
                                    {{ 'atraveo.accommodationbundle.delete.deleteMultiple' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-2" *ngIf="showDiscountBox">
            <div class="card-header" id="headingSeven">
                <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        <i class="fa fa-chevron-down"></i> {{ 'text.discount.label' | translate }}
                    </button>
                </h5>
            </div>
            <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                <div class="card-body pl-1">
                    <ng-container *ngIf="!hasDiscountCode; else hasDiscountCodeTemplate">
                        <div class="row">
                            <div class="col">
                                <label for="discount-code">{{ 'text.discount.label' | translate }}</label>
                            </div>
                            <div class="col">
                                <input type="text" id="discount-code" [(ngModel)]="owner.settings.discountCode">
                            </div>
                        </div>
                        <a (click)="save()" class="btn btn-success discount-save-button"><i class="fas fa-cloud-upload-alt mr-2"></i>{{ 'button.save' | translate }} </a>
                    </ng-container>
                    <ng-template #hasDiscountCodeTemplate>
                        <p><i class="fa fa-check-circle discount" aria-hidden="true"></i> {{ 'text.discount.saved' | translate }}</p>
                    </ng-template>
                </div>
            </div>
        </div>

        <div class="card mb-2">
            <div class="card-header" id="headingEight">
                <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                        <i class="fa fa-chevron-down"></i> {{ 'text.login.password.0' | translate }}
                    </button>
                </h5>
            </div>
            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                <div class="card-body pl-1">
                    <app-change-password></app-change-password>
                </div>
            </div>
        </div>
    </div>

    <div class="profile-btn-group">
        <div *ngIf="0 && acceptDateTime" class="terms-box">
            {{ 'atraveo.profilebundle.info.contractsince' | translate: { date: acceptDateTime } }}
        </div>
    </div>
</div>
