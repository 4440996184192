import { Component} from '@angular/core';

@Component({
    selector: 'app-accommodation-group-overview',
    templateUrl: './accommodation-group-overview.component.html',
    styleUrls: ['./accommodation-group-overview.component.scss'],
})
export class AccommodationGroupOverviewComponent {

    constructor() {}
}
