import { Component, OnChanges, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BankAccountComponent } from '../../../bank-account/bank-account.component';
import { environment } from '../../../../../../../environments/environment';
import { AuthenticationService } from '../../../../../../services/authentication/authentication.service';
import { ApiConnectorService } from '../../../../../../services/api-connector/api-connector.service';
import { NotificationService } from '../../../../../../services/notification/notification.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { OwnerConnectorService } from '../../../../../../services/api-connector/owner-connector.service';
import { ExtendedOwnerPaymentDto } from '../../../../../../entities/extened-owner-payment.dto';
import { ExtendedBankAccountEntity } from '../../../../../../entities/extended-bank-account-entity';
import { OwnerAbsenceDto } from 'data-structures/lib/es6/dto/owner/put-owner/owner-absence.dto';
import { FromToDateDto } from 'data-structures/lib/es6/dto/common/from-to-date.dto';
import { plainToInstance } from 'class-transformer';
import { OwnerSettingsDto } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { RegistrationFormComponent } from '../../registration-form/registration-form.component';
import * as Sentry from '@sentry/angular-ivy';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfirmationDialogService } from '../../../../../global/confirmation-dialog/confirmation-dialog.service';
import { DialogService } from '../../../../../../services/dialog.service';
import { BankAccountDialogComponent } from '../../../../../global/dialogs/bankdata-dialog/bank-account-dialog.component';

export interface BankAccount {
    id: string;
    default: boolean;
    accountNumber: string;
    accountHolder: string;
    accommodations: number[];
}

@Component({
    selector: 'app-owner-profile',
    templateUrl: './owner-profile.component.html',
    styleUrls: ['./owner-profile.component.scss'],
})
export class OwnerProfileComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChildren(BankAccountComponent) bankAccountComponents: QueryList<BankAccountComponent>;
    @ViewChild(RegistrationFormComponent) registrationFormComponent: RegistrationFormComponent;
    bookingTypeForm: FormGroup;
    languages = [];
    owner = this.authService.currentUser;
    ownerProfile: string;
    acceptDateTime: string;
    bookingStopSelectBoxArray: number[] = [];
    translationServiceSubscribtion: any;
    hasDiscountCode: boolean;
    showDiscountBox: boolean = false;
    useBookingsModelMinOwnerNumber: number = 105329;
    isBookingModelUser: boolean = false;
    bankAccounts: BankAccount[] = [];
    deleteMultipleBankAccountsInProgress: boolean = false;
    toDeleteBankAccounts: string[] = [];

    constructor(
        readonly authService: AuthenticationService,
        readonly apiConnector: ApiConnectorService,
        readonly notificationService: NotificationService,
        readonly translationService: TranslateService,
        readonly ownerConnector: OwnerConnectorService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly dialogService: DialogService,
    ) {
        for (let i = 0; i < 32; i++) {
            this.bookingStopSelectBoxArray.push(i);
        }
    }

    async ngOnInit(): Promise<void> {
        this.languages = environment.allowedLanguages;
        if (this.owner.addressBook?.contacts) {
            this.ownerProfile = Object.keys(this.owner.addressBook.contacts).find((key) => key === this.owner.addressBook.primaryId);
        }
        const date = this.owner.termsOfServiceSettings?.acceptDateTime;

        if (date) {
            this.acceptDateTime = date.toLocaleString(this.translationService.currentLang);
            this.translationServiceSubscribtion = this.translationService.onLangChange.subscribe(async (event: LangChangeEvent) => {
                this.acceptDateTime = date.toLocaleString(this.translationService.currentLang);
            });
        }

        if (!this.owner.payment) {
            this.owner.payment = new ExtendedOwnerPaymentDto();
        }

        if (!this.owner.payment.bankAccounts) {
            this.owner.payment.bankAccounts = {};
        }

        // Format der Bankkonten hat sich geändert. Wenn noch das alte Format vorhanden ist, umwandeln, sonst kann man nicht speichern
        // @ts-ignore
        if (this.owner.payment.bankAccount) {
            // @ts-ignore
            this.owner.payment.bankAccounts[0] = plainToInstance(ExtendedBankAccountEntity, this.owner.payment.bankAccount);
            this.owner.payment.mainBankAccount = '0';
            // @ts-ignore
            delete this.owner.payment.bankAccount;
        }

        if (!Object.keys(this.owner.payment.bankAccounts).length) {
            this.owner.payment.bankAccounts[0] = new ExtendedBankAccountEntity();
            this.owner.payment.mainBankAccount = '0';
        }

        this.hasDiscountCode = !!this.owner?.settings?.discountCode;
        this.showDiscountBox = environment.showDiscountAndCommissionForOwners.includes(this.authService?.currentUser.ownerNumber);

        this.bookingTypeForm = new FormGroup<any>({
            bookingType: new FormControl(''),
        });

        this.bookingTypeForm.patchValue({
            bookingType: this.owner?.settings?.directBooking,
        });

        this.isBookingModelUser = this.owner?.settings?.directBooking !== undefined && this.owner?.ownerNumber >= this.useBookingsModelMinOwnerNumber && !this.owner?.interfaces?.booking;

        this.bankAccounts = [];
        const mainBankAccount = this.owner?.payment?.mainBankAccount;
        for (const key of Object.keys(this.owner.payment.bankAccounts)) {
            if (this.owner.payment.bankAccounts.hasOwnProperty(key)) {
                const bankAccount = this.owner.payment.bankAccounts[key];
                const newAccount: BankAccount = {
                    id: key,
                    default: !mainBankAccount ? true : key === mainBankAccount,
                    accountNumber: bankAccount.iban ? this.encodeIban(bankAccount.iban) : bankAccount.accountNumber,
                    accountHolder: bankAccount.accountHolder,
                    accommodations: bankAccount?.accommodationIds || [],
                };
                this.bankAccounts.push(newAccount);
            }
        }
    }

    async ngOnChanges() {
        if (!this.owner.settings) {
            this.owner.settings = new OwnerSettingsDto();
        }

        if (this.owner.addressBook?.contacts) {
            this.ownerProfile = Object.keys(this.owner.addressBook.contacts).find((key) => key === this.owner.addressBook.primaryId);
        }

        this.showDiscountBox = environment.showDiscountAndCommissionForOwners.includes(this.authService?.currentUser.ownerNumber);
    }

    async ngOnDestroy() {
        if (this.translationServiceSubscribtion) {
            this.translationServiceSubscribtion.unsubscribe();
        }
    }

    async saveBankAccount(bankAccount: ExtendedBankAccountEntity, key: string) {
        if (!this.owner.payment.bankAccounts) {
            this.owner.payment.bankAccounts = {};
        }

        this.owner.payment.bankAccounts[key] = bankAccount;
        await this.save();
    }

    async save(): Promise<void> {
        try {
            this.owner.settings.directBooking = this.bookingTypeForm.get('bookingType')?.value;
            this.registrationFormComponent.validateEmails(this.owner);
            await this.authService.saveOwner(this.owner);
            await this.notificationService.add('form.save.success', 'success');
            this.hasDiscountCode = !!this.owner?.settings?.discountCode;
            const userTracking = this.owner?.tracking;
            if (userTracking?.onboarding) {
                userTracking.onboarding.completeProfileStarted = new Date();
            }
            this.owner = await this.ownerConnector.getUserData(this.owner.ownerNumber);
            await this.authService.changeToUser(this.owner);
            this.ngOnInit();
        } catch (e) {
            Sentry.captureException(e);
            await this.notificationService.add('form.save.failure', 'danger');
        }
    }

    addAbsence() {
        if (!this.owner.settings.absences) {
            this.owner.settings.absences = [];
        }

        const abscence = new OwnerAbsenceDto();
        abscence.absence = new FromToDateDto();

        this.owner.settings.absences.push(abscence);
    }

    getFreeBankAccountId() {
        let newId = 1;

        if (Object.keys(this.owner.payment.bankAccounts).length) {
            newId = Number(Object.keys(this.owner.payment.bankAccounts).reduce((a, b) => (this.owner.payment.bankAccounts[a] > this.owner.payment.bankAccounts[b] ? a : b))) + 1;
        }

        return newId.toString();
    }

    addBankAccount() {
        const id = this.getFreeBankAccountId();
        this.owner.payment.bankAccounts[id] = new ExtendedBankAccountEntity();
        this.editBankAccount(id);
    }

    openConfirmationDialog(bankId: string) {
        this.confirmationDialogService.confirm('atraveo.accommodationbundle.price.delete.confirmDeleteSinglePrice', 'confirm.content.delete.0').then(async (confirmed) => {
            if (confirmed) {
                delete this.owner.payment.bankAccounts[bankId];
                await this.save();
            }
        });
    }

    hasMainBankAccount() {
        if (!this.owner?.payment?.mainBankAccount || !this.owner?.payment?.bankAccounts) {
            return false;
        }

        if (!(this.owner.payment.mainBankAccount in this.owner.payment.bankAccounts)) {
            return false;
        }

        const mainBankAccount = this.owner.payment.bankAccounts[this.owner.payment.mainBankAccount];
        return !!mainBankAccount.bankCountryISOCode;
    }

    encodeIban(iban: string): string {
        if (iban?.length > 5) {
            return iban.replace(iban.substring(2, iban.length - 3), 'X'.repeat(iban.length - 5));
        }
        return iban;
    }

    editBankAccount(bankAccountId: string) {
        this.dialogService.openDialog(
            BankAccountDialogComponent,
            { dialogWidth: this.dialogService.dialogWidth.L },
            {
                bankAccountId,
                bankAccount: this.owner.payment.bankAccounts[bankAccountId],
                isDefault: this.bankAccounts.find((bankAccount) => bankAccount.id === bankAccountId)?.default || false,
                saveBankAccount: (bankAccount, bankAccountId) => this.saveBankAccount(bankAccount, bankAccountId),
            },
        );
    }

    abortDeleteMultiple() {
        this.deleteMultipleBankAccountsInProgress = false;
        this.toDeleteBankAccounts = [];
    }

    deleteMultipleBankAccounts() {
        this.confirmationDialogService.confirm('atraveo.accommodationbundle.delete.confirmDeleteMuliple', 'confirm.content.delete.0').then(async (confirmed) => {
            if (confirmed) {
                for (const bankId of this.toDeleteBankAccounts) {
                    delete this.owner.payment.bankAccounts[bankId];
                }
                await this.save();
                this.deleteMultipleBankAccountsInProgress = false;
            }
        });
    }

    addOrRemoveDeleteBankAccount(bankAccountId: string) {
        if (!this.authService.currentUser.isAdmin()) {
            if (this.toDeleteBankAccounts.includes(bankAccountId)) {
                this.toDeleteBankAccounts = this.toDeleteBankAccounts.filter((id) => id !== bankAccountId);
            } else {
                this.toDeleteBankAccounts.push(bankAccountId);
            }
        }
    }
}
