<div class="col-xs-12">
    <form [formGroup]="settingsForm" (submit)="onSubmit()">
        <p class="error" *ngIf="isDateRangeToLong">{{ 'atraveo.accommodationbundle.price.accommodation-daterange-too-long' | translate }}</p>
        <table class="table responsive-table">
            <thead>
                <tr>
                    <th class="break-long-words text-center" rowspan="2" colspan="1">
                        {{ 'atraveo.accommodationbundle.price.arrivaldays.period' | translate }}
                    </th>
                    <th class="break-long-words text-center" rowspan="2" colspan="1">
                        {{ 'atraveo.accommodationbundle.price.arrivaldays.minimum.rental' | translate }}
                        <a class="btn btn-link mr-2" data-toggle="popover" attr.data-content="{{ 'info.mindestmietdauer' | translate }}">
                            <i class="fas fa-info-circle"></i>
                        </a>
                    </th>
                    <th class="break-long-words text-center" rowspan="2" colspan="1" *ngIf="!useDailyPrices">
                        {{ 'atraveo.accommodationbundle.price.arrivaldays.minimum.persons' | translate }}
                        <a class="btn btn-link mr-2" data-toggle="popover" attr.data-content="{{ 'info.mindestbelegung' | translate }}">
                            <i class="fas fa-info-circle"></i>
                        </a>
                    </th>
                    <th class="break-long-words text-center" colspan="7">
                        {{ 'atraveo.accommodationbundle.price.arrivaldays.arrival.days' | translate }}
                        <a class="btn btn-link mr-2" data-toggle="popover" attr.data-content="{{ 'info.anreisetage' | translate }}">
                            <i class="fas fa-info-circle"></i>
                        </a>
                    </th>
                    <th rowspan="2" colspan="2"></th>
                </tr>
                <tr>
                    <th class="text-center">{{ 'atraveo.accommodationbundle.price.arrivalWeekdays.mo' | translate }}</th>
                    <th class="text-center">{{ 'atraveo.accommodationbundle.price.arrivalWeekdays.tu' | translate }}</th>
                    <th class="text-center">{{ 'atraveo.accommodationbundle.price.arrivalWeekdays.we' | translate }}</th>
                    <th class="text-center">{{ 'atraveo.accommodationbundle.price.arrivalWeekdays.th' | translate }}</th>
                    <th class="text-center">{{ 'atraveo.accommodationbundle.price.arrivalWeekdays.fr' | translate }}</th>
                    <th class="text-center">{{ 'atraveo.accommodationbundle.price.arrivalWeekdays.sa' | translate }}</th>
                    <th class="text-center">{{ 'atraveo.accommodationbundle.price.arrivalWeekdays.su' | translate }}</th>
                </tr>
            </thead>
            <app-advanced-setting-record class="table-line"
                                         [settingsControl]="settingsForm.get('default')"
                                         [isDefault]="true"
                                         [submitted]="submitted"
                                         [useDailyPrices]="useDailyPrices"
            >
            </app-advanced-setting-record>
            <app-advanced-setting-record  *ngFor="let exception of exceptionsFormArray.controls; let i = index"
                                         [settingsControl]="exception"
                                         (deleteEvent)="deleteException(i)"
                                          [exceptionId]="i"
                                          [submitted]="submitted"
                                          [useDailyPrices]="useDailyPrices"
                                         class="table-line">
            </app-advanced-setting-record>
        </table>
        <div class="container ">
            <div class="row ">
                <button type="button" class="btn btn-primary col-10 offset-1 offset-lg-0 col-lg-5 mb-2" (click)="addException()">
                    <span><i class="fas fa-plus mr-2"></i>{{ 'atraveo.accommodationbundle.price.create.add.periodMetaData' | translate }}</span>
                </button>
                <button class="btn btn-success col-10 offset-1 offset-lg-2 col-lg-5 mb-2" type="submit">
                    <ng-template [ngIf]="disabledCounter > 0">
                        {{ 'atraveo.accommodationbundle.price.create.deleteWithCounter' | translate:{ count: disabledCounter } }}
                    </ng-template>
                    <i class="fas fa-cloud-upload-alt mr-2"></i> {{ 'atraveo.accommodationbundle.price.create.save' | translate }}
                </button>
            </div>
        </div>
    </form>
</div>
