/// <reference types="@angular/localize" />

import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'reflect-metadata';
import { enableDebugTools } from '@angular/platform-browser';

if (environment.stage !== 'dev') {
    Sentry.init({
        dsn: 'https://392248d60e6d451493b64f43ff14ddd6@o35088.ingest.sentry.io/5893005',
        integrations: [
            new Sentry.BrowserTracing({
                // Example: tracingOrigins: ['localhost', 'https://yourserver.io/api'],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],
        release: environment.release,
        environment: environment.stage,
        tracePropagationTargets: [environment.stage, environment.stage],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0,
        beforeSend(event) {
            if (['Unauthorized', 'Token is not valid'].includes(event.message)) {
                return null;
            }
            return event;
        },
    });
}

if (environment.production) {
    enableProdMode();
}

if (environment.stage !== 'dev') {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));
} else {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .then((module) => enableDebugTools(module.injector.get(ApplicationRef).components[0]))
        .catch((err) => console.error(err));
    // Lokal kann man dann z.B. das in der Browserkonsole machen:
    // ng.profiler.timeChangeDetection()
}
