import { Component, Inject, OnInit } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../entities/extendedAccommodationEntity';
import { DialogService } from '../../../../services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { IcalDto } from 'data-structures/lib/es6/dto/accommodation/ical.dto';
import { ApiConnectorService } from '../../../../services/api-connector/api-connector.service';

@Component({
    selector: 'app-ical-dialog',
    templateUrl: './ical-dialog.component.html',
    styleUrls: ['./ical-dialog.component.scss'],
})
export class IcalDialogComponent implements OnInit {
    accommodation: ExtendedAccommodationEntity;
    icalForm: FormGroup;
    disabledCounter = 0;

    constructor(readonly dialogService: DialogService, private fb: FormBuilder, private apiConnectorService: ApiConnectorService, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.accommodation = data.accommodation;
    }

    ngOnInit() {
        this.icalForm = this.fb.group({
            icalUrls: this.fb.array([]),
            foo: [''], // Dummy, damit das Formular valid ist, wenn keine enabled icalUrls enthalten sind
        });
        for (const icalUrl of this.accommodation.vacancy.importUrls) {
            this.addIcalUrl(icalUrl);
        }
    }

    get icalUrlsFormArray() {
        return this.icalForm.get('icalUrls') as FormArray;
    }

    addIcalUrl(icalObject: IcalDto = new IcalDto()) {
        const control = new FormControl(icalObject?.url || 'http://', { validators: [Validators.required] });
        if (icalObject?.lastHttpStatusMessage && icalObject.lastHttpStatusMessage !== 'OK') {
            control.setErrors({ invalidUrl: true });
        }
        this.icalUrlsFormArray.push(control);
    }

    toggleIcalUrl(index: number) {
        const control = this.icalUrlsFormArray.at(index);
        if (control.enabled) {
            control.disable({ onlySelf: true });
            control.setErrors(null);
            this.disabledCounter++;
        } else {
            this.disabledCounter--;
            control.enable();
            control.updateValueAndValidity();
        }
    }

    async saveIcals() {
        if (this.isFormValid()) {
            const icalUrlsDtoArray = [];
            const icalUrlsArray = [];
            for (let i = 0; i < this.icalUrlsFormArray.length; i++) {
                const control = this.icalUrlsFormArray.at(i);
                if (control.enabled) {
                    control.markAsTouched();
                    const newUrlDto = new IcalDto();
                    newUrlDto.url = control.value;
                    icalUrlsDtoArray.push(newUrlDto);
                    icalUrlsArray.push(newUrlDto.url);
                } else {
                    control.setErrors(null);
                }
            }

            // Checken, ob alle URLs valide sind
            if (icalUrlsArray.length > 0) {
                await this.apiConnectorService.checkIcalLinks(icalUrlsArray).then((result) => {
                    if (result) {
                        for (const url of Object.values(result)) {
                            for (let i = 0; i < this.icalUrlsFormArray.length; i++) {
                                const control = this.icalUrlsFormArray.at(i);
                                if (url === control.value) {
                                    control.setErrors({ invalidUrl: true });
                                }
                            }
                        }
                    }
                });
            }

            if (this.isFormValid()) {
                this.data.save(icalUrlsDtoArray);
            }
        } else {
            this.markAllAsTouched();
        }
    }

    isFormValid(): boolean {
        const enabledControls = this.icalUrlsFormArray.controls.filter((control) => control.enabled);
        return enabledControls.every((control) => control.valid);
    }

    markAllAsTouched() {
        this.icalForm.markAllAsTouched();
        this.icalUrlsFormArray.controls.forEach((control) => {
            control.markAsTouched();
        });
    }

    closeDialog() {
        this.dialogService.closeDialog();
    }
}
