<div class="container my-2" *ngIf="this.translationService.currentLang === 'de'">
    <div class="content">
            <h1>SICHERHEIT & DATENSCHUTZ</h1>
            <h2>Datenschutzerklärung zur Datennutzung</h2>
            <h3>1. Überblick</h3>
            <p>Die folgende Datenschutzerklärung informiert Sie über Art und Umfang der Bearbeitung von Personendaten durch die e-domizil AG als Verantwortliche nach Massgabe des Datenschutzgesetzes (DSG) und der Datenschutzverordnung (DSV) sowie nach den Vorgaben der Datenschutz-Grundverordnung (DSGVO). Personendaten sind Informationen, die Ihrer Person direkt oder mittelbar zuzuordnen sind bzw. zugeordnet werden können, wie zum Beispiel Ihr Name oder Ihre E-Mail-Adresse. Die e-domizil AG ist mit anderen Unternehmen eine Gesellschaft der e-domizil Gruppe und wird direkt von der e-domizil GmbH, Taunusstr. 21, 60329 Frankfurt a.M., Deutschland, kontrolliert.</p>
            <h3>2. Name und Kontaktdaten des für die Datenbearbeitung Verantwortlichen sowie der Datenschutzbeauftragten</h3>
            <p>Diese Datenschutzerklärung gilt für die Datenbearbeitung durch die e-domizil AG, Binzstr. 38, 8045 Zürich, Schweiz (Verantwortlicher, im Weiteren «e-domizil», «wir», «uns»), erreichbar unter vermieterservice&commat;atraveo.de, und für folgende Website bzw. Applikation: https://owner.atraveo.com.</p>
            <p>Die Datenschutzbeauftragte der e-domizil Gruppe ist für alle Anliegen von e-domizil zuständig und ist über datenschutz&commat;atraveo.de, atraveo GmbH, Peter-Müller-Str. 10, 40468 Düsseldorf, Deutschland, erreichbar (benannt ist Claudia Gorzolla).</p>
            <h3>3. Zwecke der Datenverarbeitung, Rechtsgrundlagen und berechtigte Interessen, die von e-domizil oder einem Dritten verfolgt werden, sowie Kategorien von Empfängern</h3>
            <strong>3.1 Aufrufen unserer Website/Applikation</strong>
            <p>Beim Aufrufen unserer Website/Applikation werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website/Applikation gesendet und temporär in einem sogenannten Log-File gespeichert. Hierauf haben wir keinen Einfluss. Folgende Informationen werden dabei auch ohne Ihr Zutun erfasst und bis zur automatisierten Löschung bzw. Verfremdung gespeichert:</p>
            <ul>
                <li>die IP-Adresse des anfragenden internetfähigen Gerätes,</li>
                <li>das Datum und die Uhrzeit des Zugriffs,</li>
                <li>der Name und die URL der abgerufenen Datei,</li>
                <li>die Website/Applikation, von der aus der Zugriff erfolgte (Referrer-URL),</li>
                <li>der von Ihnen verwendete Browser und ggfs. das Betriebssystem Ihres internetfähigen Rechners sowie der Name Ihres Access-Providers,</li>
                <li>das genutzte Endgerät (z. B. Desktop oder Smartphone),</li>
                <li>die Sprache des von Ihnen verwendeten Browsers.</li>
            </ul>
            <p>An dieser Stelle möchten wir darauf hinweisen, dass uns aus den erhobenen Daten keine unmittelbaren Rückschlüsse auf Ihre Identität möglich sind und durch uns auch nicht gezogen werden.</p>
            <p>Die IP-Adresse Ihres Endgerätes sowie die weiteren oben aufgelisteten Daten werden durch uns für folgende Zwecke genutzt:</p>
            <ul>
                <li>Gewährleistung eines reibungslosen Verbindungsaufbaus,</li>
                <li>Gewährleistung einer komfortablen Nutzung unserer Website/Applikation,</li>
                <li>Auswertung der Systemsicherheit und -stabilität.</li>
            </ul>
            <p>Die IP-Adresse wird gelöscht, sobald sie für die Erreichung des Zwecks ihrer Erhebung nicht mehr erforderlich ist. Im Fall der Erfassung der Daten zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist. Im Falle der Speicherung der Daten in Log-Files ist dies spätestens nach 6 Wochen der Fall. Eine darüber hinaus gehende Speicherung erfolgt nicht. In diesem Fall werden die IP-Adressen der Nutzer gelöscht, sodass eine Zuordnung des aufrufenden Nutzers nicht mehr möglich ist.</p>
            <p>Wir bearbeiten in erster Linie die Personendaten, die wir im Rahmen unserer Geschäftsbeziehung mit unseren Kunden, Vermietern und anderen Partnern von diesen und weiteren daran beteiligten Personen erhalten oder die wir beim Betrieb unserer Websites, Apps und weiteren Anwendungen von deren Nutzern erheben.</p>
            <p>Wir verwenden die von uns erhobenen Personendaten in erster Linie, um unsere Dienstleistungen zur Vermittlung von Ferienunterkünften anzubieten und darauf gerichtete Verträge abzuwickeln, sowie um unseren gesetzlichen Pflichten im In- und Ausland nachzukommen.</p>
            <p>Des Weiteren verwenden wir für unsere Website/Applikation sogenannte Cookies, Tracking-Tools sowie Targeting-Verfahren. Um welche Verfahren es sich dabei genau handelt und wie Ihre Daten dafür verwendet werden, wird nachfolgend genauer erläutert.</p>
            <p><strong>3.2 Anlegung und Nutzung eines Benutzerkontos; Buchungen und Buchungsanfragen</strong></p>
            <p><strong>3.2.1 Anlegung eines Kontos</strong></p>
            <p>Wenn Sie bei uns ein Nutzerkonto anlegen, verarbeiten wir Personendaten, und zwar:</p>
            <ul>
                <li>beim Einloggen über E-Mail Ihre E-Mail-Adresse.</li>
            </ul>
            <p>Bei jedem Einloggen werden technische Informationen über Ihr Endgerät und den Browser sowie Informationen über Ihre Suchvorgänge gespeichert. Dies dient dazu, Ihr Nutzererlebnis auf der Website und das Angebot insgesamt zu verbessern, sowie dem Schutz Ihrer Identität und der Vermeidung von Betrugsversuchen.</p>
            <p>Die erhobenen Daten löschen wir spätestens mit der Beendigung Ihres Nutzungsvertrages mit unserer Plattform. Eine Löschung des Benutzerkontos und der darin enthaltenen Personendaten erfolgt auf Anfrage innerhalb der gesetzlichen Frist, sofern sich keine anderen Vorgaben zur Aufbewahrung aus dem Gesetz ergeben.</p>
            <p><strong>3.2.2 Buchungen und Buchungsanfragen sowie Zahlungsabwicklung</strong></p>
            <p>Bei Buchungsanfragen erfassen wir – primär zur Weiterleitung an unsere Partner sowie um unsere Direktbuchung anbieten zu können:</p>
            <ul>
                <li>die gewünschten An- und Abreisetage;</li>
                <li>Ihren Vor- und Nachnamen;</li>
                <li>die Gästezahl;</li>
                <li>Ihre E-Mail-Adresse;</li>
                <li>(optional) Ihre Nachricht an den Vermieter. </li>
            </ul>
            <p>Wenn Sie eine Buchung vornehmen, erfassen wir zudem:</p>
            <ul>
                <li>An- und Abreisetage;</li>
                <li>Ihre Postanschrift;</li>
                <li>Ihre Telefonnummer;</li>
                <li>(optional) ausgewählte Extras;</li>
                <li>die Zahlungsmethode.</li>
            </ul>
            <p>Wenn Sie über uns eine Buchung vornehmen, kann die Zahlungsabwicklung alternativ durch einen Zahlungsdienstleister durchgeführt werden, mit dem wir einen Vertrag über die Auftragsbearbeitung geschlossen haben.</p>
            <p>Dieser Zahlungsdienstleister verarbeitet alle Daten (Name, Zahlungsdetails wie Kreditkarten- / Banküberweisungsinformationen, Rechnungsadresse, Cookie-Informationen oder andere Daten), die für eine sichere und valide Zahlungstransaktion, Betrugserkennung, Risikobewertung oder Abwicklungsmethode notwendig sind, um die Zahlung innerhalb einer Buchung durchzuführen, indem er den entsprechenden Betrag an den richtigen Partner und die Servicegebühr, sofern anwendbar, an e-domizil auszahlt. Der umgekehrte Vorgang gilt für Stornierungen, wenn eine vollständige oder teilweise Rückerstattung angewiesen wird, wobei der Zahlungsdienstleister Ihre Zahlungsdaten verwendet, um den Betrag der Rückerstattung auf Ihr ursprünglich für die Zahlung verwendetes Konto zurückzuzahlen.</p>
            <p>Wir möchten darauf hinweisen, dass wir Ihre sensiblen Zahlungsdaten wie Kreditkartendetails nicht erfassen. Transaktionen werden von dem zuvor erwähnten PCI/DSS-konformen Zahlungsdienst verarbeitet. Durch Anklicken einer der angebotenen Zahlungsmethoden (z.B. Kreditkarte, Rechnung) stimmen Sie der Nutzung des Zahlungsdienstes, der Auslagerung eines solchen Dienstes und der damit verbundenen Übertragung und Verarbeitung Ihrer Daten zu.</p>
            <p>Ihre Daten werden entsprechend den gültigen Speicher- und Löschfristen zur Erfüllung unserer gesetzlichen Verpflichtungen, wie insbesondere zur Aufbewahrung von Geschäftsunterlagen nach GeBüV und zur Buchführung und Bilanzierung nach Artikel 962 OR und Erfüllung der Pflichten gemäss EU-Vorschrift DAC 7, aufbewahrt und somit unter Beachtung der gesetzlichen Fristen gespeichert.</p>
            <p><strong>3.3 Datenverarbeitung zu Werbezwecken</strong></p>
            <p><strong>3.3.1 Newsletterversand an Vermieter</strong></p>
            <p>Wenn Sie sich bei uns als Vermieter registriert haben, erhalten Sie von uns per E-Mail wichtige Informationen zu unserer Zusammenarbeit und zur Verbesserung der Online-Präsentation Ihrer Ferienimmobilie. Sie können Ihre erklärte Einwilligung, die Sie zusammen mit der Anmeldung zu unserem Vermieter-Bereich erteilt haben, jederzeit mit Wirkung für die Zukunft widerrufen. Hierfür genügt das Betätigen des Abmelde-Links.</p>
            <p><strong>3.3.2 Widerspruchsrecht</strong></p>
            <p>Gegen die Datenverarbeitung zu vorgenannten Zwecken können Sie jederzeit kostenfrei, für den jeweiligen Kommunikationskanal gesondert und mit Wirkung für die Zukunft Widerspruch einlegen. Hierfür genügt eine E-Mail an vermieterservice&commat;atraveo.com oder ein postalisches Schreiben an die unter Ziffer 2 genannte Adresse von e-domizil.</p>
            <p>Soweit Sie Widerspruch einlegen, wird die betroffene Kontaktadresse für die weitere werbliche Datenverarbeitung gesperrt. Wir weisen darauf hin, dass es in Ausnahmefällen auch noch nach Eingang Ihres Widerspruchs vorübergehend noch zu einem Versand von Werbematerial kommen kann. Dies ist technisch durch die nötige Vorlaufzeit von Werbeanzeigen bedingt und bedeutet nicht, dass wir Ihren Widerspruch nicht umsetzen. Vielen Dank für Ihr Verständnis.</p>
            <p><strong>3.4 Cookies – Allgemeine Hinweise</strong></p>
            <p>Wir setzen Cookies, Pixel, Web-Beacons oder ähnliche Technologien ein. Bei Cookies handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät gespeichert werden, wenn Sie unsere Website besuchen. Pixel und Web-Beacons sind kleine Grafiken und Codes, die der Messung von Aktivitäten auf unserer Website dienen. Im Weiteren werden Pixel, Web-Beacons und ähnliche Technologien, ebenso unter den Begriff der «Cookies» gefasst.</p>
            <p>Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware. In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten. Bei Aufruf unserer Website erscheint ein Hinweis zum Einsatz von Cookies.</p>
            <p>Einige Cookies helfen uns dabei, unseren Nutzern einen bestmöglichen und funktionalen Service sowie eine sichere Erfahrung auf unserer Website zu bieten (funktionelle Cookies). Funktionelle Cookies sind technisch notwendig, da sie die Sicherheit, Nutzererfahrung und Einstellungen ermöglichen (z.B. zur Durchführung einer Buchung, Erstellung eines Nutzerkontos, Speicherung präferierter Suchen). Wir setzen sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben oder sich bereits in Ihrem Nutzerkonto angemeldet haben. Diese werden nach Verlassen unserer Website automatisch gelöscht. Darüber hinaus setzen wir ebenfalls zum Zweck der Erfüllung unserer Nutzungsverträge temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Website erneut, um unsere Dienste in Anspruch zu nehmen, wird von den Cookies automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt haben, um Ihnen unsere Leistungen zur Verfügung stellen zu können.</p>
            <p>Zum anderen verwenden wir analytische Cookies, um die Nutzung unserer Website statistisch zu erfassen und unser Angebot zu optimieren, sowie Marketing-Cookies, um speziell auf Sie zugeschnittene Informationen und personalisierte Inhalte einzublenden. Diese Cookies werden erst gesetzt, wenn Sie dazu ausdrücklich eingewilligt haben. Sie können Ihre Einwilligung jederzeit widerrufen. Die Rechtmässigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung wird nicht berührt. Den Widerruf können Sie jederzeit erklären, in dem Sie die Cookie-Einstellungen anpassen. Sie können ausserdem Ihren Browser so konfigurieren, dass keine Cookies auf Ihrem Endgerät gespeichert werden oder stets ein Hinweis erscheint, bevor ein neues Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website nutzen können.</p>
            <p>Die Speicherdauer von Cookies ist abhängig von ihrem Verwendungszweck und daher variabel. In jedem Fall werden Cookies nach einer jeweils definierten Zeit automatisch gelöscht. Im Folgenden finden Sie nähere Informationen zu allen von uns verwendeten analytischen Cookies und Marketing Cookies.</p>
            <p><strong>3.5 Analytische Cookies und Tools</strong></p>
            <p>Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Websites und Apps nutzen wir, soweit Sie dazu eingewilligt haben, Cookies und Werkzeuge (Tools), die uns eine Analyse der Nutzung unserer Website ermöglichen.</p>
            <p><strong>3.5.1 Google Analytics</strong></p>
            <p>Wir nutzen Google Analytics, einen Webanalysedienst von Google. Dabei werden pseudonymisierte Nutzungsprofile erstellt und Cookies verwendet. Die durch den Cookie erzeugten Informationen über Ihre Benutzung der Website wie</p>
            <ul>
                <li>Browser-Typ/-Version,</li>
                <li>verwendetes Betriebssystem,</li>
                <li>Referrer-URL (die zuvor besuchte Seite),</li>
                <li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
                <li>Uhrzeit der Serveranfrage,</li>
            </ul>
            <p>werden an einen Server von Google in den USA übertragen und dort gespeichert. Google beachtet die Datenschutzbestimmungen nach Massgabe der Standardvertragsklauseln. Google arbeitet in unserem Auftrag.</p>
            <p>Die Informationen werden verwendet, um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen. Auch werden diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google zusammengeführt. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist (sogenanntes IP-Masking).</p>
            <p>Sie können die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern; wir weisen jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen unserer Website vollumfänglich genutzt werden können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie dieses Browser-Add-on (<a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>) herunterladen und installieren.</p>
            <p>Alternativ zum Browser-Add-on, insbesondere bei Browsern auf mobilen Endgeräten, können Sie die Erfassung durch Google Analytics zudem verhindern, indem Sie auf diesen Link klicken. Es wird ein Opt-Out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert. Das Opt-Out-Cookie gilt nur in diesem Browser und nur für unsere Website und wird auf Ihrem Gerät abgelegt. Löschen Sie die Cookies in diesem Browser, müssen Sie das Opt-Out-Cookie erneut setzen.</p>
            <p>Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie hier: <a href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245?hl=de</a></p>
            <p><strong>3.5.2 Google Tag Manager</strong></p>
            <p>Wir nutzen den Google Tag Manager. Durch diesen Google Dienst können Website-Tags über eine Oberfläche verwaltet werden. Es werden lediglich Tags implementiert, d.h. es werden keine Cookies gesetzt und keine Personendaten erfasst. Der Tag Manager sorgt für die Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen. Google Tag Manager greift nicht auf diese Daten zu. Wenn auf Domain- oder Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für alle Tracking-Tags bestehen, die mit Google Tag Manager implementiert werden. Weitere Informationen finden Sie in den Nutzungsrichtlinien für den Google Tag Manager: <a href="https://www.google.com/intl/de/tagmanager/use-policy.html">https://www.google.com/intl/de/tagmanager/use-policy.html</a>.</p>
            <p><strong>3.5.3 Google Looker Studio</strong></p>
            <p>Wir können ein zusätzliches Data-Management Tool (Google Looker Studio) zur visuellen Erstellung benutzerdefinierter Berichte und interaktiver dynamischer Dashboards einsetzen. Hierbei verwenden wir die Daten aus Google Analytics sowie Schnittstellen zu weiteren Datenquellen.</p>
            <p>Weitergehende Hinweise zur Nutzung von Google Looker Studio finden Sie hier: <a href="https://support.google.com/datastudio/answer/6283323?hl=de">https://support.google.com/datastudio/answer/6283323?hl=de</a></p>
            <p><strong>3.6 Marketing Cookies</strong></p>
            <p>Die im Folgenden aufgeführten und von uns eingesetzten Marketing- und Targeting-Massnahmen werden auf Grundlage des Artikel 31 Satz 2 Buchstabe b. DSG durchgeführt, soweit Sie dazu eingewilligt haben. Das Targeting dient der zielgerichteten Werbung. Über die zum Einsatz kommenden Marketing- und Targeting-Massnahmen wollen wir sicherstellen, dass Ihnen an Ihren Interessen orientierte Werbung auf Ihren Endgeräten eingeblendet wird.</p>
            <p><strong>3.6.1 Google Ads</strong></p>
            <p>e-domizil verwendet den Dienst Google Ads und nutzt hierüber das Conversion-Tracking, um die Effektivität einzelner Anzeigen, Angebote und Funktionen zu messen. Hierfür wird ein Cookie gesetzt, sobald Sie eine Google-Anzeige anklicken. Dieser Cookie dient nicht der persönlichen Identifizierung, sondern soll es ermöglichen, festzustellen, ob Sie während der Gültigkeit des Cookies von 30 Tagen wieder auf die Seite mit dem konkreten Angebot zurückkehren.</p>
            <p>Jeder Google Ads-Kunde erhält ein anderes Cookie. Cookies können somit nicht über die Website von Google Ads-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken für Google Ads-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Wir erfahren die Gesamtanzahl der Nutzer, die auf eine Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden.</p>
            <p>Sie können die Speicherung des Google-Conversion-Cookies durch eine entsprechende Einstellung Ihrer Browser-Software dauerhaft verhindern. Googles Datenschutzerklärung zum Conversion-Tracking finden Sie hier:  <a href="https://services.google.com/sitestats/de.html">https://services.google.com/sitestats/de.html</a>.</p>
            <p><strong>3.6.2 Google AdSense</strong></p>
            <p>Wir verwenden für die Einblendung von Werbeanzeigen auf unserer Website Google AdSense. Auf diese Weise können wir Ihnen Werbung anzeigen, die für Sie von Interesse sein kann. Diese Werbeanzeigen sind durch den Hinweis «Google Anzeigen» erkennbar. Google verwendet dafür Web-Beacons und setzt Cookies (siehe Ziffer 3.5.). Die durch Cookies und Web Beacons erzeugten Informationen über die Benutzung der Website (einschliesslich Ihrer IP-Adresse) und Auslieferung von Werbeformaten werden an einen Server von Google in den USA übertragen und dort gespeichert. Diese Informationen können von Google an Dritte weitergegeben werden.</p>
            <p>Wir haben Google AdSense-Anzeigen von Drittanbietern aktiviert. Die Daten können an Drittanbieter (benannt unter <a href="https://support.google.com/dfp_sb/answer/94149">https://support.google.com/dfp_sb/answer/94149</a>) übertragen werden.</p>
            <p>Sie können die Installation von Cookies durch Google AdSense verhindern durch Deaktivierung der interessenbezogenen Anzeigen bei Google über den Link <a href="http://www.google.de/ads/preferences">http://www.google.de/ads/preferences</a>.</p>
            <p>Weitergehende Informationen und die Datenschutzbestimmungen finden Sie in der Datenschutzerklärung von Google unter: <a href="https://www.google.com/policies/technologies/ads">https://www.google.com/policies/technologies/ads</a>.</p>
            <p><strong>3.6.3 Widerspruchs-/Opt-Out-Möglichkeit</strong></p>
            <p>Die erläuterten Targeting-Technologien können Sie durch eine entsprechende Cookie-Einstellung in Ihrem Browser unterbinden (vgl. auch Ziffer 3.5.). Daneben haben Sie die Möglichkeit, präferenzbasierte Werbung mit Hilfe des nachfolgenden Präferenzmanagers zu deaktivieren: <a href="https://www.youronlinechoices.com/ch-de/praferenzmanagement">https://www.youronlinechoices.com/ch-de/praferenzmanagement</a></p>
            <h3>4. Einsatz von Diensten und Plugins Dritter</h3>
            <p><strong>4.1 Google Maps</strong></p>
            <p>Wir nutzen den Kartendienst der Google Maps API zur Darstellung interaktiver Karten für die Anzeige der Resultate der Suchanfrage von Ferienunterkünften direkt auf der Website und ermöglichen die komfortable Nutzung der Kartenfunktionen. Zur Nutzung der Funktionen von Google Maps wird Ihre IP-Adresse sowie Informationen zur Nutzung der Karten durch Ihren Browser an einen Server von Google in den USA übertragen und dort gespeichert.</p>
            <p>Zudem bindet die Google Maps Karte auch sogenannte Google Fonts ein. Hierbei handelt es sich um Schriftarten von Google. Zur Darstellung der Google Fonts auf der Google Maps Karte wird ebenfalls eine Verbindung von Ihrem Browser zum Server von Google hergestellt und es wird Ihre IP-Adresse durch Ihren Browser an Google zur eigenständigen Datenverarbeitung übermittelt. Es gelten zudem die zusätzlichen Nutzungsbedingungen und Datenschutzbestimmungen von Google Maps/Google Earth.</p>
            <p>Die Nutzungsbedingungen für Google Maps finden Sie hier: <a href="https://www.google.com/intl/de_de/help/terms_maps.html">https://www.google.com/intl/de_de/help/terms_maps.html</a>. Weitergehende Informationen finden Sie in der Datenschutzerklärung von Google (<a href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>).</p>
            <p><strong>4.2 Google Fonts</strong></p>
            <p>Wir nutzen auf unserer Website Schriftarten von Google («Google Fonts»).
                Dazu lädt Ihr Browser beim Aufrufen von e-domizil die benötigte Schriftart in Ihren Browsercache. Dies ist notwendig, damit der Browser eine optisch verbesserte Darstellung unserer Texte anzeigen kann. Wenn Ihr Browser diese Funktion nicht unterstützt, wird eine Standardschrift von Ihrem Computer zur Anzeige genutzt. Die Einbindung dieser Schriftarten erfolgt durch einen Serveraufruf, in der Regel ein Server von Google in den USA. Hierdurch wird an den Server übermittelt, welche e-domizil-Website Sie besucht haben.
                Beim Aufruf der Website werden keine Cookies von Nutzern an die Google Fonts API gesendet. Daten, die im Zusammenhang mit dem Aufruf übermittelt werden, werden auf ressourcenspezifischen Domains wie fonts.googleapis.com oder fonts.gstatic.com gesendet. Sie werden nicht mit Daten in Verbindung gebracht, die ggf. im Zusammenhang mit der parallelen Nutzung von authentifizierten Google-Diensten wie Gmail erhoben oder genutzt werden. Sie können Ihren Browser so einstellen, dass die Schriftarten nicht von den Google-Servern geladen werden.</p>
            <p>e-domizil nutzt Google Fonts im Interesse einer ansprechenden Darstellung des Onlineangebots.
                Weitere Informationen finden Sie in der Datenschutzerklärung von Google (<a href="https://policies.google.com/privacy?hl=de&gl=de">https://policies.google.com/privacy?hl=de&gl=de</a>).</p>
            <p><strong>4.3 Font Awesome</strong></p>
            <p>Um Icons unseres Internetauftritts für Sie optimal darstellen zu können, setzen wir die visuellen Elemente von Font Awesome ein. Bei dem Aufruf unseres Internetauftritts wird keine Verbindung zum Server von Font Awesome aufgebaut.</p>
            <h3>5. Unternehmensgruppe</h3>
            <p>e-domizil ist ein Unternehmen der HomeToGo-Gruppe. Wir geben Ihre Personendaten gelegentlich an unsere verbundenen Unternehmen innerhalb der HomeToGo-Gruppe weiter. Die HomeToGo-Gruppe umfasst alle (gegenwärtigen und zukünftigen) Unternehmen, die direkt oder indirekt von der HomeToGo GmbH («HomeToGo») kontrolliert werden oder unter gemeinsamer Kontrolle von HomeToGo stehen. Die Weitergabe der Daten innerhalb der Unternehmensgruppe erfolgt für folgende Zwecke:</p>
            <p>a) Bereitstellung unserer Vermittler-Dienstleistungen und unserer Plattform</p>
            <p>b) Erbringung von Dienstleistungen im Rahmen des Kundenservices</p>
            <p>c) Versand von Werbung mit Ihrer Einwilligung oder sofern nach geltendem Recht erlaubt</p>
            <p>d) Analyse, Optimierung und Verbesserung unserer Website (und Applikation)</p>
            <p>e) Ermittlung und Untersuchung von Betrugsfällen sowie illegalen Aktivitäten</p>
            <p>f) Compliance (Befolgung und Einhaltung von Gesetzen)</p>
            <p>Die Datenweitergabe ermöglicht uns die Erfüllung unserer vertraglichen Verpflichtungen, die sich aus unseren Nutzungsbedingungen ergeben.</p>
            <h3>6. Ihre Rechte</h3>
            <p><strong>6.1 Überblick</strong></p>
            <p>Neben dem Recht auf Widerspruch gegen unsere Datenbearbeitungen, insbesondere jener für Zwecke des Direktmarketings, des für Direktwerbung betriebenen Profilings und weiterer berechtigter Interessen an der Bearbeitung Ihrer uns gegenüber erteilten Einwilligungen stehen Ihnen bei Vorliegen der jeweiligen gesetzlichen Voraussetzungen die folgenden weiteren Rechte zu:</p>
            <ul>
                <li>Recht auf Auskunft über Ihre bei uns gespeicherten persönlichen Daten gem. Artikel25 DSG und Artikel 16ff. DSV,</li>
                <li>Recht auf Berichtigung unrichtiger oder auf Vervollständigung richtiger Daten gem. Artikel 6 Absatz 5 DSG,</li>
                <li>Recht auf Löschung Ihrer bei uns gespeicherten Daten gem. Artikel 6 Absatz 5 DSG,</li>
                <li>Recht auf Datenübertragbarkeit gem. Artikel 28f. DSG und Artikel 20ff. DSV.</li>
            </ul>
            <p><strong>6.2 Wahrnehmung </strong></p>
            <p>Die Ausübung solcher Rechte setzt in der Regel voraus, dass Sie Ihre Identität eindeutig nachweisen (z.B. durch eine Ausweiskopie, wo Ihre Identität sonst nicht klar ist bzw. verifiziert werden kann). Zur Geltendmachung Ihrer Rechte können Sie uns unter der in Ziffer 1 angegebenen Adresse kontaktieren.</p>
            <p>Jede betroffene Person hat überdies das Recht, ihre Ansprüche gerichtlich durchzusetzen oder bei der zuständigen Datenschutzbehörde eine Beschwerde einzureichen. Die zuständige Datenschutzbehörde der Schweiz ist der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte (<a href="https://www.edoeb.admin.ch">https://www.edoeb.admin.ch</a>).</p>
            <h3>7. Änderungen</h3>
            <p>Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt die jeweils aktuelle, auf unserer Website publizierte Fassung.</p>
            <p>Version 3 – Gültig ab dem 26. September 2023</p>
        </div>
</div>

<div class="container" *ngIf="this.translationService.currentLang === 'fr'">
    <div class="row">
        <div class="col-12">
            <h1>SÉCURITÉ & PROTECTION DES DONNÉES</h1>
            <h2>Politique de confidentialité sur l'utilisation des données</h2>
            <h3>1. Vue d'ensemble</h3>
            <p>La déclaration de protection des données suivante vous informe sur la nature et l'étendue du traitement des données personnelles par e-domizil AG en tant que responsable conformément à la loi sur la protection des données (DSG) et à l'ordonnance sur la protection des données (DSV) et conformément aux exigences du règlement sur la protection des données (DSGVO). Les données personnelles sont des informations qui vous sont directement ou indirectement attribuables ou qui peuvent vous être attribuées, telles que votre nom ou votre adresse électronique. Avec d'autres sociétés, e-domizil AG est une société du groupe e-domizil et est directement contrôlée par e-domizil GmbH, Taunusstr. 21, 60329 Frankfurt a.M., Allemagne.</p>
            <h3>2. Le nom et les coordonnées du responsable du traitement des données et des délégués à la protection des données</h3>
            <p>Cette politique de confidentialité s'applique au traitement des données par e-domizil AG, Binzstr. 38, 8045 Zurich, Suisse (responsable, ci-après "e-domizil", "nous"), accessible à l'adresse serviceproprietaire&commat;atraveo.fr, et au site web ou à l'application suivants : https://owner.atraveo.com.</p>
            <p>Le délégué à la protection des données du groupe e-domizil est responsable de toutes les questions relatives à e-domizil et peut être contacté via datenschutz&commat;atraveo.de, atraveo GmbH, Peter-Müller-Str. 10, 40468 Düsseldorf, Allemagne (Claudia Gorzolla).</p>
            <h3>3. Finalités du traitement des données, bases légales et intérêts légitimes poursuivis par e-domizil ou un tiers, ainsi que les catégories de destinataires</h3>
            <strong>3.1 Appeler notre site web/application</strong>
            <p>Lorsque vous accédez à notre site web/application, le navigateur utilisé sur votre appareil final envoie automatiquement des informations au serveur de notre site web/application et les stocke temporairement dans un fichier dit "journal". Nous n'avons aucune influence sur ce point. Les informations suivantes sont également collectées sans votre intervention et stockées jusqu'à ce qu'elles soient automatiquement supprimées ou aliénées :</p>
            <ul>
                <li>l'adresse IP de l'appareil connecté à Internet qui fait la demande,</li>
                <li>la date et l'heure d'accès,</li>
                <li>le nom et l'URL du fichier récupéré,</li>
                <li>le site web/l'application à partir duquel l'accès a été effectué (URL de référence),</li>
                <li>le navigateur que vous utilisez et, le cas échéant, le système d'exploitation de votre ordinateur connecté à Internet ainsi que le nom de votre fournisseur d'accès,</li>
                <li>l'appareil final utilisé (par exemple, ordinateur de bureau ou smartphone),</li>
                <li>la langue du navigateur que vous utilisez.</li>
            </ul>
            <p>À ce stade, nous tenons à souligner que nous ne sommes pas en mesure de tirer des conclusions directes sur votre identité à partir des données collectées et que nous ne le ferons pas.</p>
            <p>L'adresse IP de votre terminal et les autres données énumérées ci-dessus sont utilisées par nous aux fins suivantes :</p>
            <ul>
                <li>Garantir un établissement de la connexion sans heurts,</li>
                <li>assurer une utilisation confortable de notre site web/application,</li>
                <li>évaluation de la sécurité et de la stabilité du système.</li>
            </ul>
            <p>L'adresse IP est effacée dès qu'elle n'est plus nécessaire pour atteindre l'objectif pour lequel elle a été collectée. Dans le cas de la collecte de données pour la mise à disposition du site web, c'est le cas lorsque la session respective est terminée. Dans le cas du stockage de données dans des fichiers journaux, c'est le cas après 6 semaines au plus tard. Le stockage au-delà de cette période n'a pas lieu. Dans ce cas, les adresses IP des utilisateurs sont supprimées, de sorte qu'il n'est plus possible d'identifier l'utilisateur appelant.</p>
            <p>Nous traitons principalement les données à caractère personnel que nous recevons de nos clients et autres partenaires dans le cadre de notre relation commerciale avec eux et d'autres personnes impliquées dans cette relation, ou que nous collectons auprès de leurs utilisateurs dans le cadre de l'exploitation de nos sites web, apps et autres applications.</p>
            <p>Nous utilisons les données personnelles que nous collectons principalement pour offrir nos services de médiation de logements de vacances et pour traiter les contrats conclus à cette fin, ainsi que pour remplir nos obligations légales en Allemagne et à l'étranger.</p>
            <p>En outre, nous utilisons ce que l'on appelle des cookies, des outils de suivi, des méthodes de ciblage et des plug-ins de médias sociaux pour notre site web/application. Les procédures exactes et la manière dont vos données sont utilisées à cette fin sont expliquées plus en détail ci-dessous.</p>
            <p><strong>3.2 Création et utilisation d'un compte d'utilisateur ; réservations et demandes de réservation</strong></p>
            <p><strong>3.2.1 Création d'un compte</strong></p>
            <p>Lorsque vous créez un compte d'utilisateur chez nous, nous traitons des données à caractère personnel, à savoir :</p>
            <ul>
                <li>lors de la connexion par courrier électronique, votre adresse électronique.</li>
            </ul>
            <p>À chaque fois que vous vous connectez, des informations techniques sur votre terminal et votre navigateur, ainsi que des informations sur vos recherches, sont enregistrées. Ces informations servent à améliorer votre expérience d'utilisateur sur le site web et l'offre dans son ensemble, ainsi qu'à protéger votre identité et à prévenir les tentatives de fraude.</p>
            <p>Nous supprimons les données collectées au plus tard à la fin de votre contrat d'utilisateur avec notre plateforme. La suppression du compte d'utilisateur et des données personnelles qu'il contient s'effectue sur demande dans le délai légal, à moins que la loi n'impose d'autres exigences en matière de conservation.</p>
            <p><strong>3.2.2 Réservations et demandes de réservation et traitement des paiements</strong></p>
            <p>Pour les demandes de réservation, nous enregistrons les données, principalement pour les transmettre à nos partenaires et pour pouvoir proposer une réservation directe :</p>
            <ul>
                <li>les jours d'arrivée et de départ souhaités ;</li>
                <li>vos nom et prénom ;</li>
                <li>le nombre d'invités ;</li>
                <li>votre adresse électronique ;</li>
                <li>(facultatif) Votre message au propriétaire.</li>
            </ul>
            <p>Lorsque vous effectuez une réservation, nous enregistrons également :</p>
            <ul>
                <li>jours d'arrivée et de départ ;</li>
                <li>votre adresse postale ;</li>
                <li>votre numéro de téléphone ;</li>
                <li>(en option) des suppléments sélectionnés ;</li>
                <li>le mode de paiement.</li>
            </ul>
            <p>Si vous effectuez une réservation par notre intermédiaire, le traitement du paiement peut également être effectué par un prestataire de services de paiement avec lequel nous avons conclu un contrat de traitement des commandes.</p>
            <p>Ce prestataire de services de paiement traite toutes les données (nom, détails de paiement tels que les informations relatives à la carte de crédit/au virement bancaire, adresse de facturation, informations relatives aux cookies ou autres données) nécessaires à une transaction de paiement sécurisée et valide, à la détection des fraudes, à l'évaluation des risques ou à la méthode de règlement pour effectuer le paiement dans le cadre d'une réservation en versant le montant approprié au partenaire adéquat et les frais de service, s'il y a lieu, à e-domizil. Le processus inverse s'applique aux annulations lorsqu'un remboursement total ou partiel est demandé, le prestataire de services de paiement utilisant vos données de paiement pour reverser le montant du remboursement sur votre compte initialement utilisé pour le paiement.</p>
            <p>Nous tenons à souligner que nous ne collectons pas vos données de paiement sensibles telles que les détails de votre carte de crédit. </p>
            <p>Vos données seront conservées conformément aux délais de conservation et de suppression en vigueur pour l'accomplissement de nos obligations légales, notamment pour la conservation des documents commerciaux conformément au GeBüV et pour la tenue des livres et la comptabilité conformément à l'article 962 du CO, ainsi que pour l'accomplissement des obligations conformément au règlement européen DAC 7, et seront donc conservées conformément aux délais légaux.</p>
            <p><strong>3.3 Traitement des données à des fins publicitaires</strong></p>
            <p><strong>3.3.1 Envoi de la lettre d'information</strong></p>
            <p>Si vous vous êtes enregistré chez nous en tant que propriétaire, vous recevrez par e-mail des informations importantes sur notre collaboration et sur l'amélioration de la présentation en ligne de votre logement de vacances. Vous pouvez à tout moment révoquer le consentement que vous avez donné en vous inscrivant dans notre espace propriétaire, avec effet pour l'avenir. Il vous suffit pour cela de cliquer sur le lien de désinscription.</p>
            <p><strong>3.3.2 Droit d'opposition</strong></p>
            <p>Vous pouvez vous opposer à tout moment et gratuitement au traitement des données aux fins susmentionnées, séparément pour le canal de communication concerné et avec effet pour l'avenir. Pour ce faire, il suffit d'envoyer un courriel à serviceproprietaire&commat;atraveo.fr ou une lettre à l'adresse d'e-domizil mentionnée au point 2.</p>
            <p>Si vous vous y opposez, l'adresse de contact concernée sera bloquée pour tout traitement ultérieur des données à des fins publicitaires. Nous tenons à souligner que, dans des cas exceptionnels, du matériel publicitaire peut encore être envoyé temporairement après réception de votre objection. Cela s'explique techniquement par le délai d'exécution nécessaire des publicités et ne signifie pas que nous ne donnerons pas suite à votre objection. Nous vous remercions de votre compréhension.</p>
            <p><strong>3.4 Cookies - Informations générales</strong></p>
            <p>Nous utilisons des cookies, des pixels, des balises web ou des technologies similaires sur notre site web et dans notre appli. Les cookies sont de petits fichiers créés automatiquement par votre navigateur et stockés sur votre appareil lorsque vous visitez notre site web ou utilisez notre appli. Les pixels et les balises web sont de petits graphiques et codes utilisés pour mesurer l'activité sur notre site web. En outre, les pixels, les balises web et les technologies similaires sont également inclus dans le terme "cookies".</p>
            <p>Les cookies ne causent aucun dommage à votre appareil final et ne contiennent pas de virus, de chevaux de Troie ou d'autres logiciels malveillants. Les informations stockées dans le cookie sont liées à l'appareil final spécifique utilisé. Cela ne signifie toutefois pas que nous ayons directement connaissance de votre identité. Lorsque vous appelez notre site web ou notre application, une note sur l'utilisation des cookies apparaît.</p>
            <p>Certains cookies nous aident à fournir à nos utilisateurs le meilleur service possible et fonctionnel ainsi qu'une expérience sûre sur notre site web et dans notre appli (cookies fonctionnels). Les cookies fonctionnels sont techniquement nécessaires car ils permettent la sécurité, l'expérience utilisateur et les réglages (par exemple, pour effectuer une réservation, créer un compte utilisateur, stocker les recherches préférées). Nous utilisons des cookies dits de session pour reconnaître que vous avez déjà visité des pages individuelles de notre site web ou que vous vous êtes déjà connecté à votre compte d'utilisateur. Ces cookies sont automatiquement supprimés lorsque vous quittez notre site web. En outre, nous utilisons également des cookies temporaires dans le but de remplir nos contrats d'utilisation, qui sont stockés sur votre terminal pendant une période déterminée. Si vous visitez à nouveau notre site web pour utiliser nos services, les cookies reconnaissent automatiquement que vous avez déjà été chez nous et quelles entrées et paramètres vous avez effectués afin de pouvoir vous fournir nos services. Si vous avez un compte d'utilisateur et que vous êtes connecté ou que vous activez la fonction "rester connecté", les informations stockées dans les cookies seront ajoutées à votre compte d'utilisateur.</p>
            <p>D'autre part, nous utilisons des cookies analytiques pour enregistrer statistiquement l'utilisation de notre site web et optimiser notre offre, ainsi que des cookies marketing pour afficher des informations et du contenu personnalisé qui vous sont spécifiquement destinés. Ces cookies ne sont installés que si vous y avez expressément consenti. Vous pouvez révoquer votre consentement à tout moment. La légalité du traitement effectué sur la base du consentement jusqu'à la révocation n'est pas affectée. Vous pouvez déclarer la révocation à tout moment en modifiant les paramètres des cookies. Vous pouvez également configurer votre navigateur de manière à ce qu'aucun cookie ne soit stocké sur votre appareil final ou qu'un avis apparaisse toujours avant la création d'un nouveau cookie. La désactivation complète des cookies peut toutefois avoir pour conséquence que vous ne puissiez pas utiliser toutes les fonctions de notre site web ou de notre appli.</p>
            <p>La durée de conservation des cookies dépend de leur finalité et est donc variable. Dans tous les cas, les cookies sont automatiquement supprimés après une période définie. Vous trouverez ci-dessous des informations plus détaillées sur tous les cookies analytiques et les cookies de marketing que nous utilisons.</p>
            <p><strong>3.5 Cookies et outils d'analyse</strong></p>
            <p>Dans le but d'une conception orientée vers la demande et d'une optimisation permanente de nos sites web et de nos apps, nous utilisons des cookies et des outils (outils) qui nous permettent d'analyser l'utilisation de notre site web et de notre app, à condition que vous y ayez consenti.</p>
            <p><strong>3.5.1 Google Analytics</strong></p>
            <p>Nous utilisons Google Analytics, un service d'analyse du Web de Google. Des profils d'utilisation pseudonymisés sont créés et des cookies sont utilisés. Les informations générées par le cookie concernant votre utilisation du site web, telles que</p>
            <ul>
                <li>type/version du navigateur,</li>
                <li>le système d'exploitation utilisé,</li>
                <li>URL de référence (la page visitée précédemment),</li>
                <li>le nom d'hôte de l'ordinateur accédant (adresse IP),</li>
                <li>l'heure de la demande du serveur,</li>
            </ul>
            <p>sont transmises à un serveur de Google aux Etats-Unis et y sont stockées. Google respecte les dispositions relatives à la protection des données conformément aux clauses contractuelles standard. Google travaille pour notre compte.</p>
            <p>Ces informations sont utilisées pour évaluer l'utilisation du site web, pour établir des rapports sur l'activité du site et pour fournir d'autres services liés à l'utilisation du site web et de l'Internet à des fins d'étude de marché et de conception de ces pages Internet en fonction de la demande. Ces informations peuvent également être transmises à des tiers si la loi l'exige ou si des tiers traitent ces données en notre nom. Votre adresse IP ne sera en aucun cas fusionnée avec d'autres données de Google. Les adresses IP sont rendues anonymes de manière à ce qu'une attribution ne soit pas possible (ce que l'on appelle le masquage IP). </p>
            <p>Vous pouvez refuser l'utilisation de cookies en sélectionnant les paramètres appropriés de votre navigateur. Nous attirons toutefois votre attention sur le fait que, dans ce cas, vous ne pourrez peut-être pas utiliser toutes les fonctionnalités de ce site web. Vous pouvez également empêcher la collecte des données générées par les cookies et liées à votre utilisation du site (y compris votre adresse IP) ainsi que le traitement de ces données par Google en téléchargeant et en installant ce module complémentaire de navigateur (<a href="https://tools.google.com/dlpage/gaoptout?hl=fr">https://tools.google.com/dlpage/gaoptout?hl=fr</a>).</p>
            <p>Vous pouvez également empêcher la collecte de données par Google Analytics en cliquant sur le lien suivant, en particulier pour les navigateurs sur les appareils mobiles. Un cookie de désactivation sera mis en place, ce qui empêchera la collecte future de vos données lors de la visite de ce site web. Le cookie de désactivation n'est valable que dans ce navigateur et uniquement pour notre site web, et il est stocké sur votre appareil. Si vous supprimez les cookies dans ce navigateur, vous devez à nouveau installer le cookie opt-out. Vous trouverez de plus amples informations sur la protection des données dans le cadre de Google Analytics à l'adresse suivante : <a href="https://support.google.com/analytics/answer/6004245?hl=fr">https://support.google.com/analytics/answer/6004245?hl=fr</a>.</p>
            <p><strong>3.5.2 Google Tag Manager</strong></p>
            <p>Nous utilisons le Google Tag Manager. Ce service de Google permet de gérer les balises d'un site web via une interface. Seules les balises sont mises en œuvre, c'est-à-dire qu'aucun cookie n'est installé et qu'aucune donnée personnelle n'est collectée. Le gestionnaire de balises se charge de déclencher d'autres balises, qui peuvent à leur tour collecter des données. Google Tag Manager n'accède pas à ces données. Si une désactivation a été définie au niveau du domaine ou du cookie, elle restera en place pour toutes les balises de suivi mises en œuvre avec Google Tag Manager. Pour plus d'informations, voir le guide d'utilisation de Google Tag Manager : <a href="https://www.google.com/intl/fr/tagmanager/use-policy.html">https://www.google.com/intl/fr/tagmanager/use-policy.html</a>.</p>
            <p><strong>3.5.3 Google Looker Studio</strong></p>
            <p>Nous pouvons utiliser un outil de gestion des données supplémentaire (Google Looker Studio) pour créer visuellement des rapports personnalisés et des tableaux de bord dynamiques interactifs. Nous utilisons à cet effet les données de Google Analytics ainsi que des interfaces vers d'autres sources de données.</p>
            <p>Vous trouverez de plus amples informations sur l'utilisation de Google Looker Studio ici : <a href="https://support.google.com/datastudio/answer/6283323?hl=fr">https://support.google.com/datastudio/answer/6283323?hl=fr</a></p>
            <p><strong>3.6 Cookies marketing</strong></p>
            <p>Les mesures de marketing et de ciblage énumérées ci-dessous et utilisées par nous sont effectuées sur la base de l'article 31, phrase 2, lettre b. DSG, dans la mesure où vous y avez consenti. Le ciblage sert à faire de la publicité ciblée. Grâce aux mesures de marketing et de ciblage utilisées, nous voulons nous assurer que vous recevez sur vos appareils finaux de la publicité adaptée à vos intérêts.</p>
            <p><strong>3.6.1 Google Ads</strong></p>
            <p>e-domizil utilise le service Google Ads et a recours au suivi des conversions pour mesurer l'efficacité des différentes annonces, offres et fonctions. À cette fin, un cookie est installé dès que vous cliquez sur une annonce Google. Ce cookie n'est pas utilisé à des fins d'identification personnelle, mais pour déterminer si vous revenez sur la page contenant l'offre spécifique pendant la durée de validité du cookie, qui est de 30 jours.</p>
            <p>Chaque client de Google Ads reçoit un cookie différent. Les cookies ne peuvent donc pas être suivis via le site web des clients de Google Ads. Les informations collectées à l'aide du cookie de conversion sont utilisées pour créer des statistiques de conversion pour les clients de Google Ads qui ont opté pour le suivi des conversions. Nous connaissons le nombre total d'utilisateurs qui ont cliqué sur une annonce et ont été redirigés vers une page munie d'une balise de suivi des conversions.</p>
            <p>Vous pouvez empêcher définitivement l'enregistrement du cookie de conversion de Google en adaptant votre logiciel de navigation en conséquence. La politique de confidentialité de Google concernant le suivi des conversions est disponible à l'adresse suivante : <a href="https://services.google.com/sitestats/fr.html">https://services.google.com/sitestats/fr.html</a>.</p>
            <p><strong>3.6.2 Google AdSense</strong></p>
            <p>Nous utilisons Google AdSense pour afficher des publicités sur notre site web. Cela nous permet de vous montrer des publicités susceptibles de vous intéresser. Ces publicités sont reconnaissables à la mention "Google Ads". Google utilise des balises web et installe des cookies à cette fin (voir section 3.5.). Les informations générées par les cookies et les balises web concernant l'utilisation du site web (y compris votre adresse IP) et la diffusion de formats publicitaires seront transmises et stockées par Google sur des serveurs situés aux États-Unis. Ces informations peuvent être transmises à des tiers par Google.</p>
            <p>Nous avons activé les annonces tierces de Google AdSense. Les données peuvent être transférées à des fournisseurs tiers (nommés à <a href="https://support.google.com/dfp_sb/answer/94149">https://support.google.com/dfp_sb/answer/94149</a>).</p>
            <p>Vous pouvez empêcher Google AdSense d'installer des cookies en désactivant les annonces basées sur les centres d'intérêt de Google via le lien <a href="http://www.google.com/ads/preferences">http://www.google.com/ads/preferences</a>.</p>
            <p>Pour plus d'informations et pour connaître les règles de confidentialité, veuillez consulter les règles de confidentialité de Google à l'adresse suivante : <a href="https://www.google.com/policies/technologies/ads">https://www.google.com/policies/technologies/ads</a>.</p>
            <p><strong>3.6.3 Option d'objection/exclusion</strong></p>
            <p>Vous pouvez désactiver les technologies de ciblage expliquées en paramétrant votre navigateur en fonction des cookies (voir également la section 3.5.). Vous avez également la possibilité de désactiver la publicité basée sur les préférences en utilisant le gestionnaire de préférences suivant : <a href="https://www.youronlinechoices.com/ch-fr/controler-ses-cookies">https://www.youronlinechoices.com/ch-fr/controler-ses-cookies</a>.</p>
            <h3>4. Utilisation de services et de plugins de tiers</h3>
            <p><strong>4.1 Google Maps</strong></p>
            <p>Nous utilisons le service cartographique de l'API Google Maps pour afficher des cartes interactives afin de présenter les résultats de la recherche de logements de vacances directement sur le site web et de permettre une utilisation confortable des fonctions cartographiques. Pour utiliser les fonctions de Google Maps, votre adresse IP et les informations relatives à l'utilisation des cartes par votre navigateur sont transmises à un serveur de Google aux États-Unis et y sont stockées. </p>
            <p>En outre, la carte de Google Maps intègre également des polices dites Google Fonts. Il s'agit de polices de caractères de Google. Pour afficher les polices Google sur la carte Google Maps, une connexion est également établie entre votre navigateur et le serveur Google et votre adresse IP est transmise par votre navigateur à Google pour un traitement indépendant des données. Les conditions d'utilisation supplémentaires et les règles de confidentialité de Google Maps/Google Earth s'appliquent également.</p>
            <p>Les conditions d'utilisation de Google Maps sont disponibles à l'adresse suivante : <a href="https://www.google.com/intl/ch_fr/help/terms_maps/">https://www.google.com/intl/ch_fr/help/terms_maps/</a>. De plus amples informations sont disponibles dans les règles de confidentialité de Google (<a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=fr</a>).</p>
            <p><strong>4.2 Google Fonts</strong></p>
            <p>Nous utilisons des polices de Google ("Google Fonts") sur notre site web.</p>
            <p>À cette fin, votre navigateur charge la police de caractères requise dans la mémoire cache de votre navigateur lorsque vous appelez e-domizil. Cette opération est nécessaire pour que le navigateur puisse afficher une représentation visuellement améliorée de nos textes. Si votre navigateur ne prend pas en charge cette fonction, votre ordinateur utilise une police standard pour l'affichage. L'intégration de ces polices est effectuée par un appel de serveur, généralement un serveur de Google aux États-Unis. Celui-ci transmet au serveur le site web d'e-domizil que vous avez visité.</p>
            <p>Lors de l'appel du site web, aucun cookie n'est envoyé par les utilisateurs à l'API Google Fonts. Les données transmises dans le cadre de l'appel sont envoyées sur des domaines spécifiques aux ressources, tels que fonts.googleapis.com ou fonts.gstatic.com. Elles ne sont pas associées à des données susceptibles d'être collectées ou utilisées dans le cadre de l'utilisation parallèle de services Google authentifiés tels que Gmail. Vous pouvez configurer votre navigateur de manière à ce que les polices ne soient pas chargées à partir des serveurs de Google.</p>
            <p>e-domizil utilise Google Fonts dans l'intérêt d'une présentation attrayante de l'offre en ligne. </p>
            <p>Pour plus d'informations, veuillez consulter les règles de confidentialité de Google (<a href="https://policies.google.com/privacy?hl=fr">https://policies.google.com/privacy?hl=fr</a>).</p>
            <p><strong>4.3 Font Awesome</strong></p>
            <p>Afin de pouvoir vous présenter les icônes de notre site Internet de manière optimale, nous utilisons les éléments visuels de Font Awesome. Lors de la consultation de notre site Internet, aucune connexion n'est établie avec le serveur de Font Awesome.</p>
            <h3>5. Groupe de sociétés</h3>
            <p>e-domizil est une société du groupe HomeToGo. Nous partageons occasionnellement vos données personnelles avec nos filiales au sein du groupe HomeToGo. Le groupe HomeToGo comprend toutes les entreprises (actuelles et futures) qui sont directement ou indirectement contrôlées par HomeToGo GmbH ("HomeToGo") ou sous le contrôle commun de HomeToGo. Le transfert de données au sein du groupe d'entreprises a lieu aux fins suivantes :</p>
            <p>a) Fourniture de nos services d'intermédiaire et de notre plateforme.</p>
            <p>b) Fourniture de services dans le cadre du service à la clientèle.</p>
            <p>c) L'envoi de publicité avec votre consentement ou si la loi applicable le permet.</p>
            <p>d) Analyse, optimisation et amélioration de notre site web (et de l'application).</p>
            <p>e) Enquêtes et recherches sur les fraudes et les activités illégales.</p>
            <p>f) Conformité (observation et respect des lois).</p>
            <p>Le transfert de données nous permet de remplir nos obligations contractuelles découlant de nos conditions d'utilisation.</p>
            <h3>6. Vos droits</h3>
            <p><strong>6.1 Vue d'ensemble</strong></p>
            <p>Outre le droit de s'opposer à notre traitement des données, en particulier à des fins de marketing direct, de profilage, de publicité directe et d'autres intérêts légitimes dans le traitement, vous disposez des droits supplémentaires suivants si les exigences légales respectives sont remplies :</p>
            <ul>
                <li>Droit à l'information sur vos données personnelles enregistrées par nous conformément à l'article 25 DSG et à l'article 16ff. DSV.</li>
                <li>Droit de rectifier les données inexactes ou de compléter les données correctes conformément à l'article 6, paragraphe 5, de la DSG.</li>
                <li>Droit à l'effacement des données stockées par nos soins, conformément à l'article 6, paragraphe 5, de la loi sur la protection des données (DSG).</li>
                <li>Droit à la portabilité des données conformément à l'article 28f. DSG et article 20ff. DSV. </li>
            </ul>
            <p><strong>6.2 Perception</strong></p>
            <p>L'exercice de ces droits exige généralement que vous prouviez clairement votre identité (par exemple au moyen d'une copie de votre carte d'identité, lorsque votre identité n'est pas claire ou ne peut pas être vérifiée). Pour exercer vos droits, vous pouvez nous contacter à l'adresse indiquée au point 1.</p>
            <p>En outre, toute personne concernée a le droit de faire valoir ses droits en justice ou de déposer une plainte auprès de l'autorité compétente en matière de protection des données. En Suisse, l'autorité compétente en matière de protection des données est le Préposé fédéral à la protection des données et à la transparence (<a href="https://www.edoeb.admin.ch">https://www.edoeb.admin.ch</a>).</p>
            <h3>7. Changements</h3>
            <p>Nous pouvons modifier cette politique de confidentialité à tout moment et sans préavis. La version actuelle publiée sur notre site web s'applique.</p>
            <p>Version 2 - Valable à partir du 26 septembre 2023</p>
        </div>
    </div>
</div>

<div class="container" *ngIf="this.translationService.currentLang === 'it'">
    <div class="row">
        <div class="col-12">
            <h1>SICUREZZA E PROTEZIONE DEI DATI</h1>
            <h2>Informativa sulla privacy per l'utilizzo dei dati</h2>
                <h3>1. Panoramica</h3>
                <p>La seguente dichiarazione sulla protezione dei dati vi informa sulla natura e sull'entità del trattamento dei dati personali da parte di e-domizil AG, in qualità di responsabile, ai sensi della Legge sulla protezione dei dati (DSG) e dell'Ordinanza sulla protezione dei dati (DSV) e in conformità ai requisiti del Regolamento sulla protezione dei dati (DSGVO). I dati personali sono informazioni che possono essere attribuite direttamente o indirettamente all'utente o che possono essere attribuite all'utente stesso, come ad esempio il suo nome o il suo indirizzo e-mail. Insieme ad altre società, e-domizil AG è una società del gruppo e-domizil ed è direttamente controllata da e-domizil GmbH, Taunusstr. 21, 60329 Francoforte sul Meno, Germania.</p>
                <h3>2. Il nome e i dati di contatto del titolare del trattamento e dei responsabili della protezione dei dati</h3>
                <p>La presente informativa sulla privacy si applica al trattamento dei dati da parte di e-domizil AG, Binzstr. 38, 8045 Zurigo, Svizzera (responsabile, di seguito "e-domizil", "noi", "ci"), raggiungibile all'indirizzo assistenzalocatore&commat;atraveo.it, e al seguente sito web o applicazione: https://owner.atraveo.com.</p>
                <p>Il responsabile della protezione dei dati del Gruppo e-domizil è responsabile di tutti i problemi di e-domizil e può essere contattato tramite datenschutz&commat;atraveo.de, atraveo GmbH, Peter-Müller-Str. 10, 40468 Düsseldorf, Germania (il nome è Claudia Gorzolla).</p>
                <h3>3. Finalità del trattamento dei dati, basi giuridiche e interessi legittimi perseguiti da e-domizil o da un terzo, nonché categorie di destinatari</h3>
                <strong>3.1 Richiamo del nostro sito/applicazione</strong>
                <p>Quando accedete al nostro sito/applicazione, il browser utilizzato sul vostro dispositivo finale invia automaticamente informazioni al server del nostro sito/applicazione e le memorizza temporaneamente in un cosiddetto file di log. Non abbiamo alcuna influenza su questo. Anche le seguenti informazioni vengono raccolte senza il vostro intervento e memorizzate fino alla loro cancellazione o alienazione automatica:</p>
                <ul>
                    <li>l'indirizzo IP del dispositivo abilitato a Internet richiedente,</li>
                    <li>la data e l'ora di accesso,</li>
                    <li>il nome e l'URL del file recuperato,</li>
                    <li>il sito web/applicazione da cui è stato effettuato l'accesso (URL di riferimento),</li>
                    <li>il browser utilizzato e, se del caso, il sistema operativo del vostro computer abilitato a Internet, nonché il nome del vostro fornitore di accesso,</li>
                    <li>il dispositivo finale utilizzato (ad esempio, desktop o smartphone),</li>
                    <li>la lingua del browser in uso.</li>
                </ul>
                <p>A questo punto, vorremmo sottolineare che non siamo in grado di trarre conclusioni dirette sulla vostra identità dai dati raccolti e che non lo faremo.</p>
                <p>L'indirizzo IP del vostro terminale e gli altri dati sopra elencati vengono utilizzati da noi per i seguenti scopi:</p>
                <ul>
                    <li>garantire una connessione fluida,</li>
                    <li>garantire un uso confortevole del nostro sito web/app,</li>
                    <li>valutazione della sicurezza e della stabilità del sistema.</li>
                </ul>
                <p>L'indirizzo IP viene cancellato non appena non è più necessario per raggiungere lo scopo per cui è stato raccolto. Nel caso di raccolta di dati per la fornitura del sito web, ciò avviene al termine della relativa sessione. Nel caso della memorizzazione dei dati nei file di log, ciò avviene al più tardi dopo 6 settimane. La memorizzazione oltre questo periodo non ha luogo. In questo caso, gli indirizzi IP degli utenti vengono cancellati, in modo che non sia più possibile l'assegnazione dell'utente chiamante. </p>
                <p>Trattiamo principalmente i dati personali che riceviamo dai nostri clienti e da altri partner nel corso del nostro rapporto commerciale con loro e con altre persone coinvolte, o che raccogliamo dai loro utenti nel funzionamento dei nostri siti web, app e altre applicazioni.</p>
                <p>Utilizziamo i dati personali raccolti principalmente per offrire i nostri servizi di mediazione di alloggi per vacanze e per elaborare i contratti diretti a questo scopo, nonché per adempiere ai nostri obblighi legali in patria e all'estero.</p>
                <p>Inoltre, per il nostro sito web/applicazione utilizziamo i cosiddetti cookie, strumenti di tracciamento, metodi di targeting e plug-in per i social media. Le procedure esatte e il modo in cui i vostri dati vengono utilizzati a questo scopo sono spiegati in modo più dettagliato qui di seguito.</p>
                <p><strong>3.2 Creazione e utilizzo di un account utente; prenotazioni e richieste di prenotazione</strong></p>
                <p><strong>3.2.1 Creazione di un account</strong></p>
                <p>Quando si crea un account utente con noi, trattiamo i dati personali, in particolare:</p>
                <ul>
                    <li>quando si accede via e-mail, l'indirizzo e-mail.</li>
                </ul>
                <p>Un account utente viene creato anche quando si inserisce il proprio indirizzo e-mail quando si effettua una prenotazione o una richiesta di prenotazione tramite il nostro sito web (vedere sezione 3.2.2.). Per questi servizi, la creazione di un account utente è necessaria per motivi tecnici, in cui vengono memorizzati l'indirizzo e-mail, il nome e i dati di viaggio.
                    Ad ogni accesso vengono memorizzate informazioni tecniche sul vostro terminale e sul vostro browser, nonché informazioni sulle vostre ricerche. Ciò serve a migliorare l'esperienza dell'utente sul sito web e l'offerta nel suo complesso, nonché a proteggere la sua identità e a prevenire tentativi di frode.</p>
                <p>I dati raccolti vengono cancellati al più tardi con la cessazione del contratto d'uso con la nostra piattaforma. La cancellazione dell'account utente e dei dati personali in esso contenuti avverrà su richiesta entro i termini di legge, a meno che la legge non preveda altri requisiti di conservazione.</p>
                <p><strong>3.2.2 Prenotazioni e richieste di prenotazione ed elaborazione dei pagamenti</strong></p>
                <p>Per le richieste di prenotazione, registriamo - principalmente per inoltrarle ai nostri partner e per poter offrire la nostra prenotazione diretta:</p>
                <ul>
                    <li>i giorni di arrivo e partenza desiderati;</li>
                    <li>nome e cognome;</li>
                    <li>il numero di ospiti;</li>
                    <li>il vostro indirizzo e-mail;</li>
                    <li>(facoltativo) Il vostro messaggio al locatore.</li>
                </ul>
                <p>Quando si effettua una prenotazione, registriamo anche:</p>
                <ul>
                    <li>giorni di arrivo e partenza;</li>
                    <li>il vostro indirizzo postale;</li>
                    <li>il vostro numero di telefono;</li>
                    <li>(opzionale) extra selezionati;</li>
                    <li>il metodo di pagamento.</li>
                </ul>
                <p>Se si effettua una prenotazione tramite noi, l'elaborazione del pagamento può essere effettuata in alternativa da un fornitore di servizi di pagamento con cui abbiamo stipulato un contratto di elaborazione degli ordini.</p>
                <p>Questo fornitore di servizi di pagamento elabora tutti i dati (nome, dettagli del pagamento come informazioni sulla carta di credito/ bonifico bancario, indirizzo di fatturazione, informazioni sui cookie o altri dati) necessari per una transazione di pagamento sicura e valida, per il rilevamento delle frodi, per la valutazione del rischio o per il metodo di regolamento per effettuare il pagamento nell'ambito di una prenotazione pagando l'importo appropriato al partner corretto e la commissione di servizio, se applicabile, a e-domizil. Il processo inverso si applica alle cancellazioni quando viene richiesto un rimborso totale o parziale, in cui il fornitore di servizi di pagamento utilizza i dati di pagamento dell'utente per restituire l'importo del rimborso al suo conto originariamente utilizzato per il pagamento.</p>
                <p>Desideriamo sottolineare che non raccogliamo i vostri dati di pagamento sensibili, come quelli della carta di credito. </p>
                <p>I vostri dati saranno conservati secondo i termini di archiviazione e cancellazione validi per l'adempimento dei nostri obblighi legali, come in particolare per l'archiviazione dei documenti aziendali secondo la GeBüV e per la tenuta dei libri e della contabilità secondo l'articolo 962 OR e l'adempimento degli obblighi secondo il regolamento UE DAC 7, e quindi conservati in conformità con i termini legali.</p>
                <p><strong>3.3 Trattamento dei dati a fini pubblicitari</strong></p>
                <p><strong>3.3.1 Invio della newsletter</strong></p>
                <p>Se vi siete registrati come locatori, riceverete via e-mail importanti informazioni sulla nostra collaborazione e sul miglioramento della presentazione online della vostra struttura ricettiva. Potete revocare in qualsiasi momento il consenso dichiarato al momento della registrazione nella nostra area riservata ai locatori, con effetto per il futuro. Tutto ciò che dovete fare è cliccare sul link di annullamento dell'iscrizione.</p>
                <p><strong>3.3.2 Diritto di obiezione</strong></p>
                <p>Potete opporvi al trattamento dei dati per le finalità sopra indicate in qualsiasi momento, gratuitamente, separatamente per il rispettivo canale di comunicazione e con effetto per il futuro. A tal fine, è sufficiente un'e-mail all'indirizzo assistenzalocatore&commat;atraveo.it o una lettera postale all'indirizzo di e-domizil indicato al punto 2.</p>
                <p>In caso di opposizione, l'indirizzo di contatto in questione sarà bloccato per l'ulteriore trattamento dei dati a fini pubblicitari. Desideriamo sottolineare che, in casi eccezionali, il materiale pubblicitario può essere inviato temporaneamente anche dopo il ricevimento della vostra obiezione. Ciò è tecnicamente dovuto ai tempi necessari per la realizzazione degli annunci pubblicitari e non significa che non daremo seguito alla vostra obiezione. Vi ringraziamo per la vostra comprensione.</p>
                <p><strong>3.4 Cookie - Informazioni generali</strong></p>
                <p>Sul nostro sito web e nella nostra app utilizziamo cookie, pixel, web beacon o tecnologie simili. I cookie sono piccoli file creati automaticamente dal vostro browser e memorizzati sul vostro dispositivo quando visitate il nostro sito web o utilizzate la nostra app. I pixel e i web beacon sono piccoli grafici e codici utilizzati per misurare l'attività sul nostro sito web. Inoltre, pixel, web beacon e tecnologie simili sono inclusi nel termine "cookie".</p>
                <p>I cookie non causano alcun danno al dispositivo finale, non contengono virus, trojan o altro malware. Nel cookie vengono memorizzate informazioni relative allo specifico dispositivo finale utilizzato. Tuttavia, ciò non significa che veniamo a conoscenza direttamente della vostra identità. Quando si richiama il nostro sito web o la nostra app, viene visualizzata una nota sull'uso dei cookie.</p>
                <p>Alcuni cookie ci aiutano a fornire ai nostri utenti il miglior servizio possibile e funzionale, nonché un'esperienza sicura sul nostro sito web e nella nostra app (cookie funzionali). I cookie funzionali sono tecnicamente necessari in quanto consentono la sicurezza, l'esperienza dell'utente e le impostazioni (ad esempio, per effettuare una prenotazione, creare un account utente, memorizzare le ricerche preferite). Utilizziamo i cosiddetti cookie di sessione per riconoscere che avete già visitato singole pagine del nostro sito web o che avete già effettuato l'accesso al vostro account utente. Questi vengono cancellati automaticamente dopo che l'utente ha lasciato il nostro sito web. Inoltre, per adempiere ai nostri contratti con gli utenti, utilizziamo anche cookie temporanei che vengono memorizzati sul vostro terminale per un determinato periodo di tempo. Se visitate nuovamente il nostro sito web per utilizzare i nostri servizi, i cookie riconoscono automaticamente che siete già stati con noi e quali inserimenti e impostazioni avete effettuato per potervi fornire i nostri servizi. Se disponete di un account utente e siete connessi o attivate la funzione "resta connesso", le informazioni memorizzate nei cookie verranno aggiunte al vostro account utente.</p>
                <p>D'altro canto, utilizziamo cookie analitici per registrare statisticamente l'utilizzo del nostro sito web e ottimizzare la nostra offerta, nonché cookie di marketing per visualizzare informazioni e contenuti personalizzati su misura per voi. Questi cookie vengono impostati solo se l'utente ha espressamente acconsentito. Potete revocare il vostro consenso in qualsiasi momento. La legalità del trattamento effettuato sulla base del consenso fino alla revoca non è pregiudicata. È possibile dichiarare la revoca in qualsiasi momento modificando le impostazioni dei cookie. Potete anche configurare il vostro browser in modo che non venga memorizzato alcun cookie sul vostro dispositivo finale o che venga sempre visualizzato un avviso prima della creazione di un nuovo cookie. Tuttavia, la disattivazione completa dei cookie può comportare l'impossibilità di utilizzare tutte le funzioni del nostro sito web o della nostra app.</p>
                <p>Il periodo di conservazione dei cookie dipende dalla loro finalità ed è pertanto variabile. In ogni caso, i cookie vengono eliminati automaticamente dopo un determinato periodo di tempo. Di seguito troverete informazioni più dettagliate su tutti i cookie analitici e di marketing da noi utilizzati.</p>
                <p><strong>3.5 Cookie e strumenti analitici</strong></p>
                <p>Ai fini della progettazione orientata alla domanda e dell'ottimizzazione continua dei nostri siti web e delle nostre app, utilizziamo cookie e strumenti (tool) che ci consentono di analizzare l'utilizzo del nostro sito web e delle nostre app, a condizione che l'utente abbia dato il proprio consenso.</p>
                <p><strong>3.5.1 Google Analytics</strong></p>
                <p>Utilizziamo Google Analytics, un servizio di analisi web di Google. Nel processo vengono creati profili di utilizzo pseudonimizzati e vengono utilizzati dei cookie. Le informazioni generate dal cookie sull'utilizzo del sito web da parte dell'utente, come ad esempio</p>
                <ul>
                    <li>tipo/versione del browser,</li>
                    <li>sistema operativo utilizzato,</li>
                    <li>URL di riferimento (la pagina visitata in precedenza),</li>
                    <li>nome host del computer di accesso (indirizzo IP),</li>
                    <li>ora della richiesta del server,</li>
                </ul>
                <p>vengono trasmessi a un server di Google negli USA e lì memorizzati. Google rispetta le disposizioni sulla protezione dei dati secondo le clausole contrattuali standard. Google lavora per nostro conto.</p>
                <p>Le informazioni vengono utilizzate per valutare l'uso del sito web, per compilare rapporti sull'attività del sito web e per fornire altri servizi relativi all'uso del sito web e di Internet ai fini della ricerca di mercato e della progettazione orientata alla domanda di queste pagine Internet. Queste informazioni possono anche essere trasferite a terzi se ciò è richiesto dalla legge o se terzi trattano questi dati per nostro conto. In nessun caso il vostro indirizzo IP verrà unito ad altri dati di Google. Gli indirizzi IP vengono anonimizzati in modo che non sia possibile un'attribuzione (il cosiddetto mascheramento IP).</p>
                <p>Potete rifiutarvi di usare i cookie selezionando l'impostazione appropriata sul vostro browser; tuttavia, tenete presente che in tal caso potreste non essere in grado di utilizzare tutte le funzionalità di questo sito web. Potete anche impedire la raccolta dei dati generati dal cookie e relativi al vostro utilizzo del sito web (compreso il vostro indirizzo IP) e l'elaborazione di questi dati da parte di Google scaricando e installando questo componente aggiuntivo del browser (<a href="https://tools.google.com/dlpage/gaoptout?hl=it">https://tools.google.com/dlpage/gaoptout?hl=it</a>).</p>
                <p>In alternativa al componente aggiuntivo del browser, in particolare per i browser dei dispositivi mobili, è possibile impedire la raccolta dei dati da parte di Google Analytics facendo clic sul link. Verrà impostato un cookie di opt-out che impedirà la futura raccolta dei vostri dati durante la visita di questo sito web. Il cookie di opt-out è valido solo per questo browser e solo per il nostro sito web e viene memorizzato sul vostro dispositivo. Se si cancellano i cookie in questo browser, è necessario impostare nuovamente il cookie di opt-out. Ulteriori informazioni sulla protezione dei dati in relazione a Google Analytics sono disponibili qui:  <a href="https://support.google.com/analytics/answer/6004245?hl=it">https://support.google.com/analytics/answer/6004245?hl=it</a>.</p>
                <p><strong>3.5.2 Google Tag Manager</strong></p>
                <p>Utilizziamo Google Tag Manager. Attraverso questo servizio di Google, i tag dei siti web possono essere gestiti tramite un'interfaccia. Vengono implementati solo i tag, ossia non vengono impostati cookie e non vengono raccolti dati personali. Il Tag Manager si occupa di attivare altri tag, che a loro volta possono raccogliere dati. Google Tag Manager non accede a questi dati. Se è stata impostata una disabilitazione a livello di dominio o di cookie, questa rimarrà attiva per tutti i tag di tracciamento implementati con Google Tag Manager. Per ulteriori informazioni, consultare le Linee guida per l'utilizzo di Google Tag Manager: <a href="https://www.google.com/tagmanager/use-policy.html">https://www.google.com/tagmanager/use-policy.html</a>.</p>
                <p><strong>3.5.3 Google Looker Studio</strong></p>
                <p>Possiamo utilizzare un ulteriore strumento di gestione dei dati (Google Looker Studio) per creare visivamente report personalizzati e cruscotti dinamici interattivi. In questo caso utilizziamo i dati di Google Analytics e le interfacce con altre fonti di dati.</p>
                <p>Ulteriori informazioni sull'uso di Google Looker Studio sono disponibili qui: <a href="https://support.google.com/datastudio/answer/6283323?hl=it">https://support.google.com/datastudio/answer/6283323?hl=it</a></p>
                <p><strong>3.6 Cookie di marketing</strong></p>
                <p>Le misure di marketing e di targeting elencate di seguito e da noi utilizzate vengono eseguite sulla base dell'articolo 31, comma 2, lettera b. DSG, nella misura in cui l'utente ha dato il proprio consenso. Il targeting ha come scopo la pubblicità mirata. Attraverso le misure di marketing e di targeting utilizzate, vogliamo assicurarci che vi venga mostrata sui vostri dispositivi finali una pubblicità orientata ai vostri interessi.</p>
                <p><strong>3.6.1 Google Ads</strong></p>
                <p>e-domizil utilizza il servizio AdWords di Google e utilizza il monitoraggio delle conversioni per misurare l'efficacia di singoli annunci, offerte e funzioni. A tal fine viene impostato un cookie non appena si clicca su un annuncio di Google. Questo cookie non viene utilizzato per l'identificazione personale, ma per determinare se l'utente ritorna alla pagina con l'offerta specifica durante la validità del cookie di 30 giorni.</p>
                <p>Ogni cliente AdWords riceve un cookie diverso. I cookie non possono quindi essere tracciati attraverso il sito web dei clienti AdWords. Le informazioni raccolte tramite il cookie di conversione vengono utilizzate per creare statistiche di conversione per i clienti AdWords che hanno optato per il conversion tracking. In questo modo si apprende il numero totale di utenti che hanno fatto clic su un annuncio e sono stati reindirizzati a una pagina contrassegnata da un tag di monitoraggio delle conversioni.</p>
                <p>È possibile impedire in modo permanente la memorizzazione del cookie di conversione di Google modificando di conseguenza il software del browser. L'informativa sulla privacy di Google sul monitoraggio delle conversioni è disponibile qui:  <a href="https://services.google.com/sitestats/it.html">https://services.google.com/sitestats/it.html</a>.</p>
                <p><strong>3.6.2 Google AdSense</strong></p>
                <p>Utilizziamo Google AdSense per visualizzare gli annunci pubblicitari sul nostro sito web. Questo ci permette di mostrarvi pubblicità che potrebbero interessarvi. Questi annunci sono riconoscibili dalla dicitura "Annunci Google". A tale scopo Google utilizza web beacon e imposta cookie (si veda il paragrafo 3.5.). Le informazioni generate dai cookie e dai web beacon sull'utilizzo del sito web (compreso il vostro indirizzo IP) e sulla consegna dei formati pubblicitari saranno trasmesse e memorizzate da Google su un server negli Stati Uniti. Queste informazioni possono essere trasmesse a terzi da Google.</p>
                <p>Abbiamo attivato gli annunci di terze parti di Google AdSense. I dati possono essere trasferiti a fornitori terzi (indicati su <a href="https://support.google.com/dfp_sb/answer/94149">https://support.google.com/dfp_sb/answer/94149</a>).</p>
                <p>È possibile impedire a Google AdSense di installare i cookie disabilitando gli annunci basati sugli interessi di Google tramite il link  <a href="http://www.google.com/ads/preferences">http://www.google.com/ads/preferences</a>.</p>
                <p>Per ulteriori informazioni e per l'informativa sulla privacy, consultare l'informativa sulla privacy di Google all'indirizzo: <a href="https://www.google.com/policies/technologies/ads">https://www.google.com/policies/technologies/ads</a>.</p>
                <p><strong>3.6.3 Opzione Obiezione/Opt-Out</strong></p>
                <p>È possibile disattivare le tecnologie di targeting spiegate effettuando l'impostazione dei cookie appropriata nel browser (vedere anche la sezione 3.5.). Avete anche la possibilità di disattivare la pubblicità basata sulle preferenze utilizzando il seguente gestore di preferenze: <a href="https://www.youronlinechoices.com/ch-it/le-tue-scelte">https://www.youronlinechoices.com/ch-it/le-tue-scelte</a>.</p>
                <h3>4. Utilizzo di servizi e plugin di terze parti</h3>
                <p><strong>4.1 Google Maps</strong></p>
                <p>Utilizziamo il servizio di mappe dell'API di Google Maps per visualizzare mappe interattive per la visualizzazione dei risultati della ricerca di alloggi per le vacanze direttamente sul sito web e consentire un comodo utilizzo delle funzioni delle mappe. Per utilizzare le funzioni di Google Maps, il vostro indirizzo IP e le informazioni sull'utilizzo delle mappe da parte del vostro browser vengono trasmessi a un server di Google negli Stati Uniti e lì memorizzati.</p>
                <p>Inoltre, la mappa di Google Maps integra anche i cosiddetti Google Fonts. Si tratta di font di Google. Per visualizzare i Google Fonts sulla mappa di Google Maps, viene stabilita anche una connessione dal vostro browser al server di Google e il vostro indirizzo IP viene trasmesso dal vostro browser a Google per un'elaborazione indipendente dei dati. Si applicano anche le ulteriori condizioni di utilizzo e l'informativa sulla privacy di Google Maps/Google Earth.</p>
                <p>Le condizioni di utilizzo di Google Maps sono disponibili qui: <a href="https://www.google.com/intl/ch_it/help/terms_maps/">https://www.google.com/intl/ch_it/help/terms_maps/</a>. Ulteriori informazioni sono disponibili nell'informativa sulla privacy di Google (<a href="https://policies.google.com/privacy?hl=it">https://policies.google.com/privacy?hl=it</a>).</p>
                <p><strong>4.2 Google Fonts</strong></p>
                <p>Sul nostro sito web utilizziamo font di Google ("Google Fonts").</p>
                <p>A tal fine, quando si richiama e-domizil, il browser carica il font richiesto nella cache del browser. Ciò è necessario affinché il browser possa visualizzare una rappresentazione visivamente migliore dei nostri testi. Se il browser non supporta questa funzione, il computer utilizza un font standard per la visualizzazione. L'integrazione di questi font avviene tramite una chiamata al server, di solito un server di Google negli Stati Uniti. Questo trasmette al server quale sito web di e-domizil avete visitato.</p>
                <p>Quando il sito web viene richiamato, gli utenti non inviano alcun cookie all'API di Google Fonts. I dati trasmessi in relazione alla chiamata vengono inviati su domini specifici della risorsa, come fonts.googleapis.com o fonts.gstatic.com. Non sono associati ai dati che possono essere raccolti o utilizzati in relazione all'uso parallelo dei servizi Google autenticati, come Gmail. È possibile impostare il browser in modo che i font non vengano caricati dai server di Google.</p>
                <p>e-domizil utilizza Google Fonts nell'interesse di una presentazione accattivante dell'offerta online.</p>
                <p>Per ulteriori informazioni, consultare l'informativa sulla privacy di Google  (<a href="https://policies.google.com/privacy?hl=it">https://policies.google.com/privacy?hl=it</a>).</p>
                <p><strong>4.3 Font Awesome</strong></p>
                <p>Per visualizzare in modo ottimale le icone del nostro sito web, utilizziamo gli elementi visivi di Font Awesome. Quando si accede al nostro sito web, non viene stabilita alcuna connessione al server di Font Awesome.</p>
                <h3>5.Gruppo di aziende</h3>
                <p>e-domizil è una società del Gruppo HomeToGo. Occasionalmente condividiamo i dati personali dell'utente con le nostre affiliate all'interno del Gruppo HomeToGo. Il Gruppo HomeToGo comprende tutte le società (attuali e future) controllate direttamente o indirettamente da HomeToGo GmbH ("HomeToGo") o sotto il comune controllo di HomeToGo. Il trasferimento dei dati all'interno del gruppo di società avviene per le seguenti finalità:</p>
                <p>a) Fornitura dei nostri servizi di intermediazione e della nostra piattaforma.</p>
                <p>b) Fornitura di servizi nell'ambito del servizio clienti.</p>
                <p>c) Invio di pubblicità con il vostro consenso o se consentito dalla legge applicabile.</p>
                <p>d) Analisi, ottimizzazione e miglioramento del nostro sito web (e dell'applicazione).</p>
                <p>e) Indagini e accertamenti su frodi e attività illegali.</p>
                <p>f) Conformità (rispetto e adesione alle leggi).</p>
                <p>Il trasferimento dei dati ci consente di adempiere agli obblighi contrattuali derivanti dalle nostre condizioni di utilizzo.</p>
                <h3>6. I vostri diritti</h3>
                <p><strong>6.1 Panoramica</strong></p>
                <p>Oltre al diritto di opporsi al nostro trattamento dei dati, in particolare per finalità di marketing diretto, di profilazione per la pubblicità diretta e per altri interessi legittimi nel trattamento, l'utente ha i seguenti ulteriori diritti se sono soddisfatti i rispettivi requisiti legali:</p>
                <ul>
                    <li>Diritto all'informazione sui vostri dati personali da noi memorizzati ai sensi dell'articolo 25 DSG e dell'articolo 16ss. DSV.</li>
                    <li>Diritto alla rettifica dei dati inesatti o al completamento dei dati corretti ai sensi dell'articolo 6, paragrafo 5, DSG.</li>
                    <li>Diritto alla cancellazione dei vostri dati da noi memorizzati ai sensi dell'articolo 6, paragrafo 5, DSG.</li>
                    <li>Diritto alla portabilità dei dati ai sensi dell'articolo 28f. DSG e articolo 20ss. DSV.</li>
                </ul>
                <p><strong>6.2 La percezione</strong></p>
                <p>L'esercizio di tali diritti richiede di norma che l'utente dimostri chiaramente la propria identità (ad esempio, mediante una copia della carta d'identità, qualora la sua identità non sia chiara o non possa essere verificata). Per esercitare i vostri diritti, potete contattarci all'indirizzo indicato al punto 1.</p>
                <p>Inoltre, ogni interessato ha il diritto di far valere i propri diritti in tribunale o di presentare un reclamo all'autorità competente per la protezione dei dati.  L'autorità competente per la protezione dei dati in Svizzera è l'Incaricato federale della protezione dei dati e delle informazioni (<a href="https://www.edoeb.admin.ch">https://www.edoeb.admin.ch</a>).</p>
                <h3>7. Modifiche</h3>
                <p>La presente informativa sulla privacy può essere modificata in qualsiasi momento senza preavviso. Si applica la versione attuale pubblicata sul nostro sito web. </p>
                <p>Versione 2 - Valida dal 26 settembre 2023</p>
        </div>
    </div>
</div>

<div class="content" *ngIf="!['de', 'fr', 'it'].includes(this.translationService.currentLang)">
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1>SECURITY AND DATA PROTECTION</h1>
                    <h2>Privacy policy on the use of data</h2>
                    <h3>1. Overview</h3>
                    <p>The following data protection statement informs you about the nature and extent of the processing of personal data by e-domizil AG as the responsible party in accordance with the Data Protection Act (DSG) and the Data Protection Ordinance (DSV) and in accordance with the requirements of the Data Protection Regulation (DSGVO). Personal data is information that can be directly or indirectly attributed to you or can be attributed to you, such as your name or your e-mail address. Together with other companies, e-domizil AG is a company of the e-domizil group and is directly controlled by e-domizil GmbH, Taunusstr. 21, 60329 Frankfurt a.M., Germany.</p>
                    <h3>2. The name and contact details of the data controller and the data protection officers</h3>
                    <p>This privacy policy applies to data processing by e-domizil AG, Binzstr. 38, 8045 Zurich, Switzerland (responsible party, hereinafter "e-domizil", "we", "us"), reachable at ownerservice&commat;atraveo.com, and to the following website or application: https://owner.atraveo.com.</p>
                    <p>The data protection officer of the e-domizil Group is responsible for all concerns of e-domizil and can be contacted via datenschutz&commat;atraveo.de, atraveo GmbH, Peter-Müller-Str. 10, 40468 Düsseldorf, Germany (named is Claudia Gorzolla).</p>
                    <h3>3. Purposes of the data processing, legal bases and legitimate interests pursued by e-domizil or a third party, as well as categories of recipients</h3>
                    <strong>3.1 Calling up our website/application</strong>
                    <p>When you access our website/application, the browser used on your end device automatically sends information to the server of our website/application and temporarily stores it in a so-called log file. We have no influence on this. The following information is also collected without your intervention and stored until automated deletion or alienation:</p>
                    <ul>
                        <li>the IP address of the requesting Internet-enabled device,</li>
                        <li>the date and time of access,</li>
                        <li>the name and URL of the retrieved file,</li>
                        <li>the website/application from which the access was made (referrer URL),</li>
                        <li>the browser you use and, if applicable, the operating system of your Internet-enabled computer as well as the name of your access provider,</li>
                        <li>the end device used (e.g. desktop or smartphone),</li>
                        <li>the language of the browser you are using.</li>
                    </ul>
                    <p>At this point, we would like to point out that we are not able to draw any direct conclusions about your identity from the collected data and that we will not do so.</p>
                    <p>The IP address of your terminal device and the other data listed above are used by us for the following purposes:</p>
                    <ul>
                        <li>Ensure smooth connection establishment,</li>
                        <li>Ensuring a comfortable use of our website/app,</li>
                        <li>Evaluation of system security and stability.</li>
                    </ul>
                    <p>The IP address is deleted as soon as it is no longer required to achieve the purpose for which it was collected. In the case of the collection of data for the provision of the website, this is the case when the respective session has ended. In the case of storage of data in log files, this is the case after 6 weeks at the latest. Storage beyond this period does not take place. In this case, the IP addresses of the users are deleted, so that an assignment of the calling user is no longer possible. </p>
                    <p>We primarily process the personal data that we receive from our customers and other partners in the course of our business relationship with them and other persons involved in it, or that we collect from their users in the operation of our websites, apps and other applications. </p>
                    <p>We use the personal data we collect primarily to offer our services for the mediation of vacation accommodations and to process contracts directed to this end, as well as to fulfill our legal obligations at home and abroad.</p>
                    <p>Furthermore, we use so-called cookies, tracking tools, targeting methods and social media plug-ins for our website/application. The exact procedures involved and how your data is used for this purpose are explained in more detail below.</p>
                    <p><strong>3.2 Creation and use of a user account; bookings and booking requests</strong></p>
                    <p><strong>3.2.1 Creation of an account</strong></p>
                    <p>When you create a user account with us, we process personal data, namely:</p>
                    <ul>
                        <li>your email address (when logging in via email).</li>
                    </ul>
                    <p>Each time you log in, technical information about your terminal device and browser, as well as information about your searches, is stored. This serves to improve your user experience on the website and the offer as a whole, as well as to protect your identity and prevent fraud attempts.</p>
                    <p>We delete the collected data at the latest with the termination of your user contract with our platform. A deletion of the user account and the personal data contained therein will take place upon request within the statutory period, unless other requirements for retention arise from the law.</p>
                    <p><strong>3.2.2 Bookings and booking requests and payment processing</strong></p>
                    <p>For booking requests, we record - primarily for forwarding to our partners as well as to be able to offer our direct booking:</p>
                    <ul>
                        <li>the desired arrival and departure days;</li>
                        <li>your first and last name;</li>
                        <li>the number of guests;</li>
                        <li>your email address;</li>
                        <li>(optional) your message to the owner. </li>
                    </ul>
                    <p>When you make a booking, we also record:</p>
                    <ul>
                        <li>arrival and departure days;</li>
                        <li>your mailing address;</li>
                        <li>your phone number;</li>
                        <li>(optional) selected extras;</li>
                        <li>the payment method.</li>
                    </ul>
                    <p>If you make a booking through us, the payment processing can alternatively be carried out by a payment service provider with whom we have concluded an order processing contract.</p>
                    <p>This payment service provider processes all data (name, payment details such as credit card/bank transfer information, billing address, cookie information or other data) necessary for a secure and valid payment transaction, fraud detection, risk assessment or settlement method to make the payment within a booking by paying the appropriate amount to the correct partner and the service fee, if applicable, to e-domizil. The reverse process applies to cancellations when a full or partial refund is instructed, where the payment service provider uses your payment details to return the amount of the refund to your account originally used for the payment.</p>
                    <p>We would like to point out that we do not collect your sensitive payment data such as credit card details. </p>
                    <p>Your data will be stored according to the valid storage and deletion periods for the fulfillment of our legal obligations, such as in particular for the storage of business documents according to GeBüV and for bookkeeping and accounting according to Article 962 OR and fulfillment of the obligations according to EU regulation DAC 7, and thus stored in compliance with the legal periods.</p>
                    <p><strong>3.3 Data processing for advertising purposes</strong></p>
                    <p><strong>3.3.1 Newsletter dispatch</strong></p>
                    <p>If you have registered with us as an owner, you will receive important information from us by e-mail about our cooperation and about improving the online presentation of your holiday home. You can revoke consent which you declared in the process of registration to our owner area at any time with effect for the future. All you need to do is click on the unsubscribe link.</p>
                    <p><strong>3.3.2 Right of objection</strong></p>
                    <p>You may object to data processing for the aforementioned purposes at any time, free of charge, separately for the respective communication channel and with effect for the future. For this purpose, an e-mail to vermieterservice&commat;atraveo.com or a postal letter to the address of e-domizil mentioned in section 2 is sufficient.</p>
                    <p>If you object, the contact address concerned will be blocked from further data processing for advertising purposes. We would like to point out that in exceptional cases, advertising material may still be sent temporarily after receipt of your objection. This is technically due to the necessary lead time of advertisements and does not mean that we will not implement your objection. Thank you for your understanding.</p>
                    <p><strong>3.4 Cookies - General Information</strong></p>
                    <p>We use cookies, pixels, web beacons or similar technologies on our website and in our app. Cookies are small files that are automatically created by your browser and stored on your device when you visit our website or use our app. Pixels and web beacons are small graphics and codes that are used to measure activity on our website. Furthermore, pixels, web beacons and similar technologies are also included under the term "cookies".</p>
                    <p>Cookies do not cause any damage to your end device, do not contain viruses, Trojans or other malware. Information is stored in the cookie that arises in connection with the specific end device used. However, this does not mean that we gain direct knowledge of your identity. When you call up our website or app, a note on the use of cookies appears.</p>
                    <p>Some cookies help us to provide our users with the best possible and functional service as well as a safe experience on our website and in our app (functional cookies). Functional cookies are technically necessary as they enable security, user experience and settings (e.g. to make a booking, create a user account, store preferred searches). We use so-called session cookies to recognize that you have already visited individual pages of our website or have already logged into your user account. These are automatically deleted after you leave our website. In addition, we also use temporary cookies for the purpose of fulfilling our user contracts, which are stored on your terminal device for a certain fixed period of time. If you visit our website again in order to use our services, the cookies automatically recognize that you have already been with us and which entries and settings you have made in order to be able to provide you with our services. If you have a user account and are logged in or activate the "stay logged in" function, the information stored in cookies will be added to your user account.</p>
                    <p>On the other hand, we use analytical cookies to statistically record the use of our website and to optimize our offer, as well as marketing cookies to display information and personalized content tailored specifically to you. These cookies are only set if you have expressly consented to this. You can revoke your consent at any time. The legality of the processing carried out on the basis of the consent until the revocation is not affected. You can declare the revocation at any time by adjusting the cookie settings. You can also configure your browser so that no cookies are stored on your end device or a notice always appears before a new cookie is created. However, the complete deactivation of cookies may mean that you cannot use all the functions of our website or app.</p>
                    <p>The storage period of cookies depends on their purpose and is therefore variable. In any case, cookies are automatically deleted after a defined period of time. Below you will find more detailed information on all analytical cookies and marketing cookies used by us.</p>
                    <p><strong>3.5 Analytical cookies and tools</strong></p>
                    <p>For the purpose of demand-oriented design and ongoing optimization of our websites and apps, we use cookies and tools (tools) that allow us to analyze the use of our website and app, provided you have consented to this.</p>
                    <p><strong>3.5.1 Google Analytics</strong></p>
                    <p>We use Google Analytics, a web analytics service from Google. In the process, pseudonymized usage profiles are created and cookies are used. The information generated by the cookie about your use of the website, such as</p>
                    <ul>
                        <li>browser type/version,</li>
                        <li>operating system used,</li>
                        <li>referrer URL (the previously visited page),</li>
                        <li>host name of the accessing computer (IP address),</li>
                        <li>time of the server request,</li>
                    </ul>
                    <p>are transmitted to a Google server in the USA and stored there. Google observes the data protection provisions in accordance with the standard contractual clauses. Google works on our behalf.</p>
                    <p>The information is used to evaluate the use of the website, to compile reports on website activity and to provide other services related to the use of the website and the Internet for the purposes of market research and demand-oriented design of these Internet pages. This information may also be transferred to third parties if this is required by law or if third parties process this data on our behalf. Under no circumstances will your IP address be merged with other Google data. The IP addresses are anonymized so that an assignment is not possible (so-called IP masking). </p>
                    <p>You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. You can also prevent the collection of data generated by the cookie and related to your use of the website (including your IP address) and the processing of this data by Google by downloading and installing this browser add-on (<a href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>).</p>
                    <p>As an alternative to the browser add-on, especially for browsers on mobile devices, you can also prevent the collection by Google Analytics by clicking on the link. An opt-out cookie will be set, which prevents the future collection of your data when visiting this website. The opt-out cookie is only valid in this browser and only for our website and is stored on your device. If you delete the cookies in this browser, you must set the opt-out cookie again. Further information on data protection in connection with Google Analytics can be found here: <a href="https://support.google.com/analytics/answer/6004245?hl=en">https://support.google.com/analytics/answer/6004245?hl=en</a>.</p>
                    <p><strong>3.5.2 Google Tag Manager</strong></p>
                    <p>We use the Google Tag Manager. Through this Google service, website tags can be managed via an interface. Only tags are implemented, i.e. no cookies are set and no personal data is collected. The Tag Manager takes care of triggering other tags, which in turn may collect data. Google Tag Manager does not access this data. If a disable has been set at the domain or cookie level, it will remain in place for all tracking tags implemented with Google Tag Manager. For more information, see the Google Tag Manager Usage Guidelines:  <a href="https://www.google.com/tagmanager/use-policy.html">https://www.google.com/tagmanager/use-policy.html</a>.</p>
                    <p><strong>3.5.3 Google Looker Studio</strong></p>
                    <p>We may use an additional data management tool (Google Looker Studio) to visually create custom reports and interactive dynamic dashboards. Here we use the data from Google Analytics as well as interfaces to other data sources.</p>
                    <p>Further information on the use of Google Looker Studio can be found here: <a href="https://support.google.com/datastudio/answer/6283323?hl=en">https://support.google.com/datastudio/answer/6283323?hl=en</a></p>
                    <p><strong>3.6 Marketing Cookies</strong></p>
                    <p>The marketing and targeting measures listed below and used by us are carried out on the basis of Article 31 sentence 2 letter b. DSG, insofar as you have consented to this. The targeting serves the purpose of targeted advertising. Through the marketing and targeting measures used, we want to ensure that you are shown advertising on your end devices that is oriented to your interests.</p>
                    <p><strong>3.6.1 Google Ads</strong></p>
                    <p>e-domizil uses the service Google Ads and uses conversion tracking to measure the effectiveness of individual ads, offers and functions. For this purpose, a cookie is set as soon as you click on a Google ad. This cookie is not used for personal identification, but to determine whether you return to the page with the specific offer during the cookie's validity of 30 days.</p>
                    <p>Each Google Ads customer receives a different cookie. Cookies can therefore not be tracked via the website of Google Ads customers. The information collected using the conversion cookie is used to create conversion statistics for Google Ads customers who have opted in to conversion tracking. We learn the total number of users who clicked on an ad and were redirected to a page tagged with a conversion tracking tag.</p>
                    <p>You can permanently prevent the storage of the Google conversion cookie by adjusting your browser software accordingly. Google's privacy policy on conversion tracking can be found here: <a href="https://services.google.com/sitestats/en.html">https://services.google.com/sitestats/en.html</a>.</p>
                    <p><strong>3.6.2 Google AdSense</strong></p>
                    <p>We use Google AdSense to display advertisements on our website. This allows us to show you advertisements that may be of interest to you. These advertisements are recognizable by the reference "Google Ads". Google uses web beacons and sets cookies for this purpose (see section 3.5.). The information generated by cookies and web beacons about the use of the website (including your IP address) and delivery of advertising formats will be transmitted to and stored by Google on servers in the United States. This information may be passed on to third parties by Google.</p>
                    <p>We have enabled Google AdSense third-party ads. The data may be transferred to third-party providers (named at <a href="https://support.google.com/dfp_sb/answer/94149">https://support.google.com/dfp_sb/answer/94149</a>).</p>
                    <p>You can prevent Google AdSense from installing cookies by disabling Google's interest-based ads via the link <a href="http://www.google.com/ads/preferences">http://www.google.com/ads/preferences</a>.</p>
                    <p>For more information and the privacy policy, please see Google's privacy policy at: <a href="https://www.google.com/policies/technologies/ads">https://www.google.com/policies/technologies/ads</a>.</p>
                    <p><strong>3.6.3 Objection/Opt-Out Option</strong></p>
                    <p>You can disable the targeting technologies explained by making the appropriate cookie setting in your browser (see also section 3.5.). You also have the option of deactivating preference-based advertising using the following preference manager: <a href="https://www.youronlinechoices.com/ch-de/praferenzmanagement">https://www.youronlinechoices.com/ch-de/praferenzmanagement</a></p>
                    <h3>4. Use of third-party services and plugins</h3>
                    <p><strong>4.1 Google Maps</strong></p>
                    <p>We use the map service of the Google Maps API to display interactive maps for the display of the results of the search query of vacation accommodations directly on the website and enable the comfortable use of the map functions. To use the functions of Google Maps, your IP address and information about the use of the maps by your browser is transmitted to a Google server in the USA and stored there.</p>
                    <p>In addition, the Google Maps map also integrates so-called Google Fonts. These are fonts from Google. To display the Google Fonts on the Google Maps map, a connection is also established from your browser to the Google server and your IP address is transmitted by your browser to Google for independent data processing. The additional terms of use and privacy policy of Google Maps/Google Earth also apply: <a href="https://maps.google.com/help/terms_maps/?hl=en">https://maps.google.com/help/terms_maps/?hl=en</a>.</p>
                    <p>The terms of use for Google Maps can be found here: <a href="https://www.google.com/intl/en/help/terms_maps.html">https://www.google.com/intl/en/help/terms_maps.html</a>. Further information can be found in Google's privacy policy (<a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>).</p>
                    <p><strong>4.2 Google Fonts</strong></p>
                    <p>We use fonts from Google ("Google Fonts") on our website.</p>
                    <p>For this purpose, your browser loads the required font into your browser cache when you call up e-domizil. This is necessary so that the browser can display a visually improved representation of our texts. If your browser does not support this function, a standard font is used by your computer for display. The integration of these fonts is done by a server call, usually a server of Google in the USA. This transmits to the server which e-domizil website you have visited.</p>
                    <p>When the website is called up, no cookies are sent by users to the Google Fonts API. Data transmitted in connection with the call-up is sent on resource-specific domains such as fonts.googleapis.com or fonts.gstatic.com. They are not associated with data that may be collected or used in connection with the parallel use of authenticated Google services such as Gmail. You can set your browser so that the fonts are not loaded from Google servers.</p>
                    <p>e-domizil uses Google Fonts in the interest of an appealing presentation of the online offer. </p>
                    <p>For more information, please see Google's privacy policy (<a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>).</p>
                    <p><strong>4.3 Font Awesome</strong></p>
                    <p>In order to optimally display the icons of our website for you, we use the visual elements of Font Awesome. When you access our website, no connection to the Font Awesome server is established.</p>
                    <h3>5. Group of companies</h3>
                    <p>e-domizil is a company of the HomeToGo Group. We occasionally share your personal data with our affiliates within the HomeToGo Group. The HomeToGo Group includes all (current and future) companies that are directly or indirectly controlled by HomeToGo GmbH ("HomeToGo") or under common control of HomeToGo. The transfer of data within the group of companies takes place for the following purposes:</p>
                    <p>a) Provision of our intermediary services and our platform.</p>
                    <p>b) Provision of services within the scope of customer service.</p>
                    <p>c) Sending advertising with your consent or if permitted by applicable law.</p>
                    <p>d) Analysis, optimization and improvement of our website (and application).</p>
                    <p>e) Investigation and investigation of fraud as well as illegal activities.</p>
                    <p>f) Compliance (observance of and adherence to laws).</p>
                    <p>The data transfer enables us to fulfill our contractual obligations arising from our terms of use.</p>
                    <h3>6. Your rights</h3>
                    <p><strong>6.1 Overview</strong></p>
                    <p>In addition to the right to object to our data processing, in particular for direct marketing purposes, profiling direct advertising and other legitimate interests in processing, you have the following additional rights if the respective legal requirements are met:</p>
                    <ul>
                        <li>Right to information about your personal data stored by us according to Article 25 DSG and Article 16ff. DSV.</li>
                        <li>Right to rectification of inaccurate data or completion of correct data in accordance with Article 6(5) DSG.</li>
                        <li>Right to have your data stored by us deleted in accordance with Article 6(5) DSG.</li>
                        <li>Right to data portability according to article 28f. DSG and article 20ff. DSV.</li>
                    </ul>
                    <p><strong>6.2 Perception</strong></p>
                    <p>The exercise of such rights usually requires that you clearly prove your identity (e.g. by means of a copy of your ID card, where your identity is otherwise not clear or cannot be verified). To exercise your rights, you can contact us at the address given in section 1.</p>
                    <p>In addition, every data subject has the right to enforce his or her claims in court or to lodge a complaint with the competent data protection authority.  The competent data protection authority in Switzerland is the Federal Data Protection and Information Commissioner (<a href="https://www.edoeb.admin.ch">https://www.edoeb.admin.ch</a>).</p>
                    <h3>7. Changes</h3>
                    <p>We may amend this privacy policy at any time without prior notice. The current version published on our website applies.</p>
                    <p>Version 2 - Valid from 26 September 2023</p>
                </div>
            </div>
        </div>
    </div>
</div>
