import { Component, Inject } from '@angular/core';
import { BookingEntity } from 'data-structures/lib/es6/entity/booking.entity';
import { MessageRequestDto } from '../../../../entities/Messages/messageRequestDto';
import { DialogService } from '../../../../services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-properties-filter-dialog',
    templateUrl: './properties-filter-dialog.component.html',
    styleUrl: './properties-filter-dialog.component.scss',
})
export class PropertiesFilterDialogComponent {
    stateByParam: string;
    filter: {
        skip: number;
        take: number;
        state: {};
    };
    accountIds: {};
    filterTakeValue = 10;
    accommodationStateValue = '';
    constructor(readonly dialogService: DialogService, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.stateByParam = data.stateByParam;
        this.filter = data.filter;
        this.filterTakeValue = this.filter.take;
        this.accommodationStateValue = Object.entries(data.filter.state)
            .filter(([key, value]) => key !== 'deleted' || value !== false)
            .map(([key, value]) => `${key}:${value}`)
            .join(':');
    }

    setState(keyValue: string) {
        this.data.state(keyValue);
    }

    filterAccommodations(isFilterBtnEvent: boolean = false) {
        this.data.accommodationFilter(isFilterBtnEvent);
    }

    onChange(value: number) {
        this.filterTakeValue = value;
        this.filter.take = this.filterTakeValue;
    }

    closeDialog() {
        this.dialogService.closeDialog();
    }
}
