import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class OnboardingGuard implements CanActivate {
    constructor(private authService: AuthenticationService, readonly router: Router) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authService.currentUser;
        if (currentUser) {
            const currentStep = +currentUser.getOnboardingStep();
            if (!currentUser.isImported() && currentStep > 0 && currentStep <= 3) {
                await this.router.navigate(['/welcome/step/' + currentStep]);
                return false;
            }
        }
        return true;
    }
}
