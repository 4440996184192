import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit{
    owner = this.authService.currentUser;
    selectedTab: string;

    constructor(
        readonly authService: AuthenticationService,
        readonly activatedRoute: ActivatedRoute,
    ) {}

    async ngOnInit() {
        this.activatedRoute.fragment.subscribe((fragment) => {
            // Tab setzen wenn Anker gesetzt ist
            if (!fragment) {
                this.selectedTab = 'profile';
                return;
            }

            this.selectedTab = fragment;
        });
    }
}
