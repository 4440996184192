// issue-collector.component.ts
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { Subscription } from 'rxjs';
import { ApiConnectorService } from '../../../services/api-connector/api-connector.service';

@Component({
    selector: 'app-issue-collector',
    templateUrl: './issue-collector.component.html',
    styleUrls: ['./issue-collector.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueCollectorComponent implements OnInit, OnDestroy {
    constructor(readonly router: Router, readonly authenticationService: AuthenticationService, readonly route: ActivatedRoute, readonly apiConnectorService: ApiConnectorService) {}
    private user: any;
    private currentUrl;
    private accommodationId;
    private accommodationSubscription: Subscription;
    ngOnInit(): void {
        this.removeIssueCollectorElements();
        this.accommodationSubscription = this.apiConnectorService.activeAccommodation$.subscribe((accommodation) => {
            this.accommodationId = accommodation?.accommodationId || null;
        });
        this.loadJiraIssueCollector();
        this.user = this.authenticationService.currentUser;
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.url;
                // verzögere den Aufruf des Issue-Collectors, da dieser sonst zu früh (NavigationEnd) auf die Accommodation Subscription zugreift
                setTimeout(() => {
                    this.loadJiraIssueCollector();
                }, 2000);
            }
        });
    }
    ngOnDestroy(): void {
        this.accommodationSubscription.unsubscribe();
    }

    loadJiraIssueCollector() {
        this.removeIssueCollectorElements();
        const srcUrl =
            'https://holidaydomain.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-vg1gsr/b/8/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-US';
        const script = document.createElement('script');
        script.id = 'jira-issue-collector';
        script.src = srcUrl + '&collectorId=' + environment.collectorId;
        script.async = true;
        script.onload = () => {
            // setze alle Felder, die im Issue-Collector vorausgefüllt werden sollen, bevor es in den DOM geschrieben wird
            this.setupIssueCollector();
        };
        document.body.appendChild(script);
    }
    removeIssueCollectorElements() {
        const issueCollectorElementIds = ['atlwdg-loading', 'jira-issue-collector', 'atlwdg-trigger', 'atlwdg-frame', 'atlwdg-blanket', 'atlwdg-container'];
        for (const elementId of issueCollectorElementIds) {
            document.getElementById(elementId)?.remove();
        }
    }
    setupIssueCollector() {
        // Überprüfe, ob ATL_JQ_PAGE_PROPS existiert, und initialisiere es, falls nicht
        (window as any).ATL_JQ_PAGE_PROPS = (window as any).ATL_JQ_PAGE_PROPS || {};
        const spacer = '-'.repeat(100);
        const lnbr = '\n';
        const ownerNumber = this.user.ownerNumber ? 'Owner: \t' + this.user.ownerNumber + lnbr : '';
        let accommodationId = this.accommodationId ? 'Accommodation: \t' + this.accommodationId + lnbr : '';
        let url = 'Location: \t' + this.currentUrl;
        const checkFrames = () => {
            return new Promise<void>((resolve, reject) => {
                let checkInterval = window.setInterval(() => {
                    const container = document.getElementById('atlwdg-container');
                    if (container) {
                        const iframes = container.getElementsByTagName('iframe');
                        if (iframes.length > 0) {
                            while (iframes.length > 1) {
                                iframes.item(0).remove(); // remove all iframes except the last one
                            }
                            if (iframes.length === 1) {
                                clearInterval(checkInterval);
                                resolve();
                            }
                        }
                    } else {
                        clearInterval(checkInterval);
                        reject(new Error('Container not found'));
                    }
                }, 1000);
            });
        };

        function cleanupIframes() {
            return checkFrames();
        }

        (window as any).ATL_JQ_PAGE_PROPS = {
            [environment.collectorId]: {
                fieldValues: {
                    description: ownerNumber + accommodationId + url + '\n' + spacer + ' \n\n\n\n\n',
                    recordWebInfo: '1', // field Name
                    recordWebInfoConsent: ['1'], // field Id
                },
                triggerFunction: function (showCollectorDialog) {
                    const openCollectorDialog = (event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        showCollectorDialog();
                        cleanupIframes();
                    };
                    document.getElementById('trigger-issue-collector').removeEventListener('click', openCollectorDialog);
                    document.getElementById('trigger-issue-collector').addEventListener('click', openCollectorDialog);
                },
            },
        };
    }
}
