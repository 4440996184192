import { Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { BookingEntity } from 'data-structures/lib/es6/entity/booking.entity';
import { BookingConnectorService } from '../../../../../services/api-connector/booking-connector.service';
import { ExtendedFindBookingDto } from '../../../../../entities/extended-find-booking.dto';
import { ExtendedBookingFilterDto } from '../../../../../entities/extended-booking-filter.dto';
import { ChannelEnum } from 'data-structures/lib/es6/enum/channel.enum';
import { OrderByDto } from 'data-structures/lib/es6/dto/find-booking/order-by.dto';
import { SortableComponentsEnum } from 'data-structures/lib/es6/enum/order-by/sortable-components.enum';
import { ConfirmationDialogService } from '../../../../global/confirmation-dialog/confirmation-dialog.service';
import { BookingUpdateService } from '../../../../../services/booking-update.service';
import { DirectionEnum } from 'data-structures/lib/es6/enum/order-by/direction.enum';
import { DocumentTypeEnum } from 'data-structures/lib/es6/enum/document-type.enum';
import { DateService } from '../../../../../services/date/date.service';
import { FindBookingDto } from 'data-structures/lib/es6/dto/find-booking/find-booking.dto';
import { cloneDeep } from 'lodash';
import { AccommodationAjaxSearchComponent } from '../../../../global/accommodation-ajax-search/accommodation-ajax-search.component';
import { StatusEnum } from 'data-structures/lib/es6/enum/status.enum';
import { Subscription } from 'rxjs';
import { BookingFilterDialogComponent } from '../../../../global/dialogs/booking-filter-dialog/booking-filter-dialog.component';
import { DialogService } from '../../../../../services/dialog.service';
import { CreateBookingComponent } from '../create-booking/create-booking.component';
import { BookingViewComponent } from '../booking-view/booking-view.component';

@Component({
    selector: 'app-booking-list-tab',
    templateUrl: './booking-list-tab.component.html',
    styleUrls: ['./booking-list-tab.component.scss'],
})
export class BookingListTabComponent implements OnInit, OnDestroy {
    @Input() bookingStatusType: StatusEnum;
    @ViewChild(AccommodationAjaxSearchComponent) ajaxSearch: AccommodationAjaxSearchComponent;
    bookings: BookingEntity[];
    findBookingDto: ExtendedFindBookingDto;
    standardFindBookingDto: ExtendedFindBookingDto;
    bookingCount: number;
    viewBooking: BookingEntity;
    newOrEditBooking: BookingEntity = new BookingEntity();
    channelEnum: ChannelEnum;
    currentPage: number;
    isPageChanged: boolean = false;
    bookingDateSort: OrderByDto = {
        component: SortableComponentsEnum.BookingDate,
        direction: null,
    };
    arrivalDateSort: OrderByDto = {
        component: SortableComponentsEnum.ArrivalDate,
        direction: DirectionEnum.Descending,
    };
    departureDateSort: OrderByDto = {
        component: SortableComponentsEnum.DepartureDate,
        direction: null,
    };
    ownerCurrency: any = this.authenticationService.currentUser.getOwnerCurrency();
    channelString: string;
    bookingUpdateSubscription: Subscription;

    constructor(
        readonly bookingConnectorService: BookingConnectorService,
        readonly translationService: TranslateService,
        readonly authenticationService: AuthenticationService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly bookingUpdateService: BookingUpdateService,
        readonly dateService: DateService,
        readonly dialogService: DialogService,
    ) {
        this.findBookingDto = new ExtendedFindBookingDto();
        this.findBookingDto.filter = new ExtendedBookingFilterDto();
        this.findBookingDto.filter.ownerNumber = this.authenticationService.currentUser.ownerNumber;
        this.findBookingDto.skip = 0;
        this.findBookingDto.take = 10;
        this.findBookingDto.orderBy = [this.arrivalDateSort];
        this.findBookingDto.includeCount = true;
        this.channelString = 'all';
        this.currentPage = 1;

        this.standardFindBookingDto = Object.assign({}, this.findBookingDto);
        this.standardFindBookingDto.filter = Object.assign({}, this.findBookingDto.filter);
    }

    async ngOnInit() {
        this.bookingUpdateSubscription = this.bookingUpdateService.getCreatedMessage().subscribe(async () => {
            await this.bookingSaved();
        });
    }

    ngOnDestroy() {
        this.bookingUpdateSubscription?.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.bookingStatusType) {
            // setze bei wechsel der Seite wieder auf die erste Seite
            this.findBookingDto.skip = 0;
            this.currentPage = 1;
            this.setAndSearchType();
        }
    }

    setAndSearchType() {
        if (['open', 'confirmed', 'cancelled'].includes(this.bookingStatusType)) {
            this.findBookingDto.filter.status = this.bookingStatusType;
            this.standardFindBookingDto.filter.status = this.bookingStatusType;
            this.loadData();
        }
    }

    async loadData() {
        const clonedFindBooking = this.cloneFindBookingDtoAndPrepareFilter();
        const result = await this.bookingConnectorService.findBookings(this.authenticationService.currentUser.ownerNumber, clonedFindBooking);
        this.bookings = result.result;
        this.bookingCount = result.count;
    }

    async resetData() {
        this.channelString = 'all';
        this.findBookingDto = cloneDeep(this.standardFindBookingDto);
        await this.loadData();
    }

    async updateSort(type: 'arrivalDate' | 'bookingDate' | 'departureDate') {
        // Man soll jetzt nur noch nach einem sortieren können, nicht nach beiden gleichzeitig

        if (type === 'arrivalDate') {
            this.findBookingDto.orderBy = [this.arrivalDateSort];
            this.bookingDateSort.direction = null;
            this.departureDateSort.direction = null;
        } else if (type === 'bookingDate') {
            this.findBookingDto.orderBy = [this.bookingDateSort];
            this.arrivalDateSort.direction = null;
            this.departureDateSort.direction = null;
        } else if (type === 'departureDate') {
            this.findBookingDto.orderBy = [this.departureDateSort];
            this.arrivalDateSort.direction = null;
            this.bookingDateSort.direction = null;
        } else {
            this.findBookingDto.orderBy = null;
        }

        // Obwohl das Event ngModelChange heißt, ist die Variable noch auf dem alten Stand wenn man hier nicht wartet
        window.setTimeout(async () => {
            await this.loadData();
        }, 50);
    }

    cloneFindBookingDtoAndPrepareFilter(): FindBookingDto {
        const clonedFindBooking = cloneDeep(this.findBookingDto);
        if (this.findBookingDto.orderBy?.length) {
            clonedFindBooking.orderBy = this.findBookingDto.orderBy.filter((order) => order.direction);
        }

        // remove dates if empty
        if (clonedFindBooking.filter?.arrivalDate === '') {
            delete clonedFindBooking.filter.arrivalDate;
        }
        if (clonedFindBooking.filter?.departureDate === '') {
            delete clonedFindBooking.filter.departureDate;
        }

        clonedFindBooking.take = Number(clonedFindBooking.take);

        if (this.channelString === 'all') {
            delete clonedFindBooking.filter.channel;
        } else if (this.channelString === 'AT') {
            clonedFindBooking.filter.channel = ['AT', 'ED'];
        } else {
            clonedFindBooking.filter.channel = ['VM'];
        }

        clonedFindBooking.filter.status = this.bookingStatusType;
        return clonedFindBooking;
    }

    async changePage(page: number) {
        this.bookings = [];
        this.currentPage = page;
        this.findBookingDto.skip = this.findBookingDto.take * (page - 1);
        if (this.findBookingDto.skip < 0) {
            this.findBookingDto.skip = 0;
        }
        this.isPageChanged = true;
        await this.loadData();
    }

    getEdomizilBookingPdfLink(booking: BookingEntity) {
        if (booking.documents?.length) {
            const result = booking.documents.find((document) => document.type === DocumentTypeEnum.Confirmation);
            if (result) {
                return result.url;
            }
        }
    }

    async downloadBookingConfirmation(bookingId: number) {
        await this.bookingConnectorService.downloadConfirmation(this.authenticationService.currentUser.ownerNumber, bookingId, this.translationService.currentLang);
    }

    async downloadOverview(type: 'pdf' | 'csv') {
        const clonedFindBooking = this.cloneFindBookingDtoAndPrepareFilter();
        delete clonedFindBooking.take;
        delete clonedFindBooking.skip;
        await this.bookingConnectorService.downloadOverview(type, clonedFindBooking);
    }

    async deleteBooking(id: number) {
        await this.bookingConnectorService.deleteBooking(id);
        await this.loadData();
    }

    async bookingSaved() {
        // Unterkomponente hat eine neue Buchung angelegt
        await this.loadData();
    }

    // Irgendwie will dieses Modal nicht aufgehen, deswegen machen wir das hier selbst:
    openModal() {
        if (this.bookingStatusType) {
            this.dialogService.openDialog(
                BookingFilterDialogComponent,
                { dialogWidth: this.dialogService.dialogWidth.SM },
                {
                    channelString: this.channelString,
                    findBookingDto: this.findBookingDto,
                    reset: () => this.resetData(),
                    load: (result) => this.onSubmit(result),
                },
            );
        }
    }

    onSubmit(data) {
        this.currentPage = 1;
        this.findBookingDto = data.findBookingDto;
        this.channelString = data.channelString;
        this.loadData();
        this.dialogService.openDialog(
            BookingFilterDialogComponent,
            { dialogWidth: this.dialogService.dialogWidth.SM },
            {
                bookingStatusType: this.bookingStatusType,
                channelString: this.channelString,
                findBookingDto: this.findBookingDto,
                reset: this.resetData.bind(this),
                load: this.loadData.bind(this),
                search: this.onSearch.bind(this),
            },
        );
    }

    onSearch($event: any) {
        const usedNumber = Number($event);
        if (!isNaN(usedNumber)) {
            this.findBookingDto.filter.accommodationIds = [usedNumber];
        } else {
            this.findBookingDto.filter.accommodationIds = [];
        }
    }

    openConfirmationDialog(id: number) {
        this.confirmationDialogService
            .confirm('atraveo.accommodationbundle.price.delete.confirmDeleteSinglePrice', 'confirm.content.delete.0')
            .then(async (confirmed) => {
                if (confirmed) {
                    await this.deleteBooking(id);
                }
            })
            .catch(() => undefined);
    }

    openBookingDialog(newOrEdit: any) {
        this.newOrEditBooking = newOrEdit;
        this.dialogService.openDialog(
            CreateBookingComponent,
            { dialogWidth: this.dialogService.dialogWidth.M },
            {
                newOrEditBooking: this.newOrEditBooking,
                bookingStatusType: this.bookingStatusType,
            },
        );
    }
    openBookingViewDialog() {
        this.dialogService.openDialog(
            BookingViewComponent,
            { dialogWidth: this.dialogService.dialogWidth.M },
            {
                booking: this.viewBooking,
                id: this.bookingStatusType,
            },
        );
    }
}
