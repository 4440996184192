<ul class="nav nav-tabs nav-justified" id="accommodation-tab" role="tablist">
    <li class="nav-item">
        <a class="nav-link"
           [class.active]="this.selectedTab == 'inbox'"
           (click)="this.selectedTab='inbox'"
           id="inbox-tab" data-toggle="tab" href="#inbox" role="tab" aria-controls="inbox" aria-selected="true">
            {{ 'text.notification.option.inbox' | translate }}
        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link"
           [class.active]="this.selectedTab == 'outbox'"
           (click)="this.selectedTab='outbox'"
           id="outbox-tab" data-toggle="tab" href="#outbox" role="tab" aria-controls="outbox" aria-selected="false">
            {{ 'text.notification.option.outbox' | translate }}
        </a>
    </li>
</ul>

<div class="tab-content">
    <div class="nav-item-sm mt-3" [class.active]="this.selectedTab == 'inbox'" (click)="this.selectedTab= this.selectedTab == 'inbox' ? '' : 'inbox'">
        <i class="fa" [ngClass]="this.selectedTab == 'inbox' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'text.notification.option.inbox' | translate }}
    </div>
    <div class="tab-pane"
         [ngClass]="{'active': this.selectedTab == 'inbox', 'show': this.selectedTab == 'inbox', 'fade': this.selectedTab != 'inbox'}"
         id="inbox" role="tabpanel" aria-labelledby="inbox-tab">
        <app-inbox [bookings]="bookings"></app-inbox>
    </div>
    <div class="nav-item-sm mt-3" [class.active]="this.selectedTab == 'outbox'" (click)="this.selectedTab= this.selectedTab == 'outbox' ? '' : 'outbox'">
        <i class="fa" [ngClass]="this.selectedTab == 'outbox' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'text.notification.option.outbox' | translate }}
    </div>
    <div class="tab-pane"
         [ngClass]="{'active': this.selectedTab == 'outbox', 'show': this.selectedTab == 'outbox', 'fade': this.selectedTab != 'outbox'}"
         id="outbox" role="tabpanel" aria-labelledby="outbox-tab">
        <app-outbox [bookings]="bookings"></app-outbox>
    </div>
</div>

<div class="btn-group">
    <a class="btn btn-success" (click)="contactUs()"><i class="fas fa-plus"></i>{{ 'text.notification.option.new-notification' | translate }}</a>
</div>
