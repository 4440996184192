<div class="modal-content">
    <div class="modal-header">
        <h1 class="modal-title"
            id="templateModalLabel">{{ 'atraveo.additionalcosttemplatebundle.overview.create.button' | translate }}</h1>
        <button class="close" (click)="closeDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="container-fluid">
            <h3>{{ 'atraveo.additionalcosttemplatebundle.create.name.title' | translate }}</h3>

            <div class="row">
                <div class="col">
                    <label
                        for="template-name">{{ 'atraveo.additionalcosttemplatebundle.create.name.0' | translate }}</label>
                </div>
                <div class="col">
                    <input id="template-name" class="form-control" type="text"
                           [(ngModel)]="currentTemplate.templateName" [ngModelOptions]="{ standalone: true }"/>
                </div>
            </div>

            <h3>{{ 'atraveo.additionalcosttemplatebundle.create.accommodations.title' | translate }}</h3>
            <app-conditions-select [condition]="currentTemplate.conditions"></app-conditions-select>
        </div>
    </div>

    <div class="modal-footer">
        <button (click)="save(currentTemplate)" type="button" class="btn btn-primary"
                data-dismiss="modal">{{ 'button.save' | translate }}</button>
    </div>
</div>
