<div class="history-wrapper">
    <div class="row">
        <div class="col">
            <app-accommodation-ajax-search hideIcons="True" htmlId="history-ajax"
                                           (onSearch)="search($event)"></app-accommodation-ajax-search>
        </div>
        <div class="col">
            <div class="float-right">
                Filter:
                <span *ngIf="filter.component">{{ filter.component}}</span>
                <span *ngIf="filter.dateFrom"> ab {{ filter.dateFrom | stringToDate | date}}</span>
                <span *ngIf="filter.dateTo"> bis {{ filter.dateTo | stringToDate | date }}</span>
                <button class="btn btn-primary" type="button" data-toogle="modal" (click)="openFilterModal()">
                    <i class="fa fa-filter mr-2"></i>
                    {{ 'filter' | translate }}
                </button>
            </div>
        </div>
    </div>


    <!--        <a data-toggle="collapse" href="#filter-layer" type="button"-->
    <!--           class="btn btn-atraveoTertiary btn-icon-only btn-icon&#45;&#45;filter pull-right">-->
    <!--            <i class="fas fa-filter fa-2x"></i>-->
    <!--        </a>-->


    <div class="modal fade" id="filter-layer" tabindex="-1" role="dialog" aria-labelledby="filter-layer"
         aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content collapse mt-2 show">
                <div class="filter-modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="card card-body">
                    <div class="form-group row">
                        <div class="col">
                            History Typ
                        </div>
                        <div class="col">
                            <select class="form-control custom-select" [(ngModel)]="filter.component"
                                    [ngModelOptions]="{standalone: true}">
                                <option value="">{{'text.select.empty' | translate}}</option>
                                <option *ngFor="let component of this.components">{{ component }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col">
                            Im Zeitraum von
                        </div>
                        <div class="col">
                            <input class="form-control" type="date" [(ngModel)]="filter.dateFrom"
                                   [ngModelOptions]="{standalone: true}" />
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col">
                            Im Zeitraum bis
                        </div>
                        <div class="col">
                            <input class="form-control" type="date" [(ngModel)]="filter.dateTo"
                                   [ngModelOptions]="{standalone: true}" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col filter-btn">
                            <button class="btn btn-success m-2" (click)="loadHistory()">Filtern</button>
                            <button class="btn btn-secondary m-2" (click)="resetFilter()">Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <ng-container *ngFor="let historyEntry of this.history; let i = index">
        <div class="wrapper">
            <table class="history">
                <thead>
                <tr>
                    <td>Datum</td>
                    <td>Benutzer</td>
                    <td>Objekt</td>
                    <td>Name/Id</td>
                    <td>Typ</td>
                    <td>Änderung</td>
                </tr>
                </thead>

                <tr class="change-{{i}}">
                    <td>{{ historyEntry.created | date:'medium' }}</td>
                    <td>{{ historyEntry.ownerNumber }}</td>
                    <td>{{ historyEntry.entityId }}</td>
                    <td>{{ historyEntry.userName }}</td>
                    <td>{{ historyEntry.contentType }}</td>
                    <td>
                        <div *ngIf="historyEntry.diff">
                            <a class="btn btn-link mr-2" (click)="hide(i)">Einklappen</a>
                            <!--<a class="btn btn-link mr-2" (click)="hideUnchanged(i)">Nur Änderungen anzeigen</a>-->
                            <a class="btn btn-link" (click)="showAll(i)">Alles anzeigen</a>
                            <div class="entry-wrapper">
                                <div [innerHTML]="historyEntry.diff"></div>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </ng-container>

    <div class="load-more pt-4" *ngIf="!endReached">
        <button class="btn btn-primary" (click)="this.loadHistory(true)">Mehr laden</button>
    </div>
</div>