<div id="step{{nr}}" class="card">
    <div class="card-body">
        <h1 class="card-title"><span class="badge-info badge-circle"><span>{{nr}}</span></span>&nbsp;<span>{{identity | translate}}</span></h1>
        <p class="card-text m-4 text-center">{{ description | translate }}</p>
        <div [class]="((layout === 'full') ? 'full-card-layout d-flex align-items-center flex-column d-grid gap-2 col-12 col-sm-6 col-lg-6 mx-auto' : '')">
            <a *ngIf="btn1" [routerLink]="((btn1.onclick && layout === 'header') ? '' : btn1.link)" (click)="btn1.onclick !== null && emitClick(btn1.onclick)" class="btn btn-success btn-block text-center" >{{ btn1.text | translate }}</a>
            <a *ngIf="btnCompleteNow" [routerLink]="((btnCompleteNow.onclick && layout === 'header') ? '' : btnCompleteNow.link)" (click)="btnCompleteNow.onclick !== null && emitClick(btnCompleteNow.onclick)" class="btn btn-success btn-block text-center" >{{ btnCompleteNow.text | translate }}</a>
            <a *ngIf="btn2 && btn2.link;else useButton" routerLink="{{ btn2.link }}" target="{{ btn2.target }}" (click)="emitClick(btn2.onclick) " class="btn btn-link btn-block text-center" >{{ btn2.text | translate }}</a>
            <ng-template #useButton>
                <button *ngIf="btn2" (click)="emitClick(btn2.onclick) " class="btn btn-link btn-block text-center" >{{ btn2.text | translate }}</button>
            </ng-template>
            <span *ngIf="success">
                <div class="btn btn-block text-center">{{ successText | translate }} <i *ngIf="success" class="fa fa-check-circle text-success"></i></div>
            </span>
        </div>
    </div>
</div>
