<div class="modal fade" id="password-forgotten" tabindex="-1" role="dialog" aria-labelledby="password-forgotten"
     aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'text.login.requestNewPassword' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="change-wrapper">
                    <div class="row">
                        <div class="col">
                            <p>{{'text.change.pw.criteria' | translate}}</p>
                            <ul>
                                <li>{{ 'text.change.pw.length' | translate }}</li>
                                <li>{{ 'text.change.pw.uppercase' | translate }}</li>
                                <li>{{ 'text.change.pw.lowercase' | translate }}</li>
                                <li>{{ 'text.change.pw.number' | translate }}</li>
                                <li>{{ 'text.change.pw.special.characters' | translate }}</li>
                            </ul>
                        </div>
                    </div>
                    <!--                <div class="row mt-1">-->
                    <!--                    <div class="col">{{ 'user.password.current' |translate }}</div>-->
                    <!--                    <div class="col"><input type="password"></div>-->
                    <!--                </div>-->
                    <div class="row mt-1">
                        <div class="col">{{ 'user.password.new' |translate }}</div>
                        <div class="col"><input type="password" name="new" [(ngModel)]="this.password"
                                                (blur)="checkPassword()"></div>
                    </div>
                    <div class="row mt-1">
                        <div class="col">{{ 'user.password.repeat' |translate }}</div>
                        <div class="col"><input type="password" name="repeat" [(ngModel)]="this.confirmPassword"></div>
                    </div>

                    <div class="row">
                        <div *ngFor="let errorMessage of errorMessages"  class="col-md-12 error">
                            {{ errorMessage | translate }}
                        </div>
                    </div>
                </div>

                <div class="success">
                    {{ 'text.change.password.success' | translate }}
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" (click)="submitPassword()"
                        class="btn btn-secondary">{{ 'text.change.pw.0' | translate }}</button>
            </div>
        </div>
    </div>
</div>
