import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageDto } from 'data-structures/lib/es6/dto/common/language.dto';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-language-dto-form',
    templateUrl: './language-dto-form.component.html',
    styleUrls: ['./language-dto-form.component.scss'],
})
export class LanguageDtoFormComponent implements OnInit {
    @Input() content: LanguageDto;
    @Input() infoKey: string = null;
    @Output() contentChanged: EventEmitter<LanguageDto> = new EventEmitter();
    allowedLanguages: string[] = environment.allowedLanguages;
    currentLanguage: string;
    maxLength: number = 10000;
    lengthText: string;

    constructor(readonly translateService: TranslateService) {}

    ngOnInit(): void {
        this.currentLanguage = this.translateService.currentLang;

        if (!Object.keys(this.content).length) {
            this.content[this.translateService.currentLang] = '';
        }

        this.updateLength();
    }

    async updateLength() {
        const text = await this.translateService.get('text.textarea.maxlength').toPromise();
        if (this.content[this.currentLanguage]) {
            const currentLength = this.content[this.currentLanguage].length;
            const lengthLeft = this.maxLength - currentLength;
            this.lengthText = text.replace('%d', lengthLeft.toString());
        } else {
            this.lengthText = text.replace('%d', this.maxLength.toString());
        }
    }

    deleteCurrentLanguage() {
        this.content[this.currentLanguage] = null;
        this.updateLength();
    }
}
