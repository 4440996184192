<div *ngIf="condition">
    <div class="row">
        <div class="col">
            <div class="radio">
                <input type="radio" [checked]="selectedType === 'accommodation'" name="condition-type" value="accommodation" (click)="updateType($event)" id="template-accommodations" />
            </div>
            <label for="template-accommodations">{{ 'text.filter.accommodation.0' | translate }}</label>
        </div>
        <div class="col" *ngIf="selectedType === 'accommodation'">
            <app-accommodation-select [(ngModel)]="condition.accommodationIds" [showAllIds]="true" classKey="form-control custom-select selectpicker show-tick" idKey="template-accommodations" [noneSelectedDisplayAll]="true">
            </app-accommodation-select>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="radio">
                <input type="radio" [checked]="selectedType === 'accommodation-group'" name="condition-type" value="accommodation-group" (click)="updateType($event)" id="template-accommodation-groups"/>
            </div>
            <label for="template-accommodation-groups">{{ 'text.filter.accommodation.group.0' | translate }}</label>
        </div>
        <div class="col" *ngIf="selectedType === 'accommodation-group' || !selectedType">
            <app-accommodation-group-select
                [(ngModel)]="condition.accommodationGroupIds"
                classKey="form-control custom-select selectpicker"
                idKey="template-accommodation-groups"
                [addEmptyOption]="true"
                [noneSelectedDisplayAll]="true"
                [multipleAccommodationGroups]=multipleAccommodationGroups
            >
            </app-accommodation-group-select>
        </div>
    </div>
</div>

<div class="row" *ngIf="geoDto">
    <div class="col">
        <div class="radio">
            <input type="radio" [checked]="selectedType === 'geo'" name="condition-type" value="geo" (click)="updateType($event)" id="template-geo" />
        </div>
        <label for="template-geo">{{ 'text.arrival.geo.path.0' | translate }}</label>
    </div>
    <div class="col" *ngIf="selectedType === 'geo'">
        <app-geo-triplet
            idKeyPrefix="template-geo"
            classKey="form-control custom-select selectpicker"
            [geoDto]="this.geoDto"
            [(ngModel)]="geoDto"
            (change)="updateGeoTriplett()"
            [noneSelectedDisplayAll]="true"
        ></app-geo-triplet>
    </div>
</div>
