import { Injectable } from '@angular/core';

import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class ConfirmationDialogService {
    private result: boolean = false;
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>;
    constructor(private dialog: MatDialog) {}
    readonly DEFAULT_BUTTON_OK_TEXT = 'text.marketing.teaser.yes';
    readonly DEFAULT_BUTTON_CANCEL_TEXT = 'button.cancel';
    readonly DEFAULT_BUTTON_OK_CLASS = 'btn-secondary';
    readonly DEFAULT_BUTTON_CANCEL_CLASS = 'btn-primary';

    async confirm(
        title: string,
        message: string,
        btnOkText: string = this.DEFAULT_BUTTON_OK_TEXT,
        btnCancelText: string = this.DEFAULT_BUTTON_CANCEL_TEXT,
        dialogSize: 'sm' | 'lg' = 'sm',
        btnOkClass: string = this.DEFAULT_BUTTON_OK_CLASS,
        btnCancelClass: string = this.DEFAULT_BUTTON_CANCEL_CLASS,
    ): Promise<boolean> {
        const dialogData = {
            title: title,
            message: message,
            btnOkText: btnOkText,
            btnCancelText: btnCancelText,
            btnOkClass: btnOkClass,
            btnCancelClass: btnCancelClass,
        };

        this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: dialogSize === 'sm' ? '300px' : '600px',
            data: dialogData,
        });
        this.dialogRef.afterOpened().subscribe(() => {
            document.body.classList.add('dialog-open');
        });
        this.dialogRef.afterClosed().subscribe(() => {
            document.body.classList.remove('dialog-open');
        });
        return await firstValueFrom(this.dialogRef.afterClosed());
    }
    setResult(result) {
        this.dialogRef = result;
        this.dialog.closeAll();
    }
    closeDialog(callback?: () => void): void {
        this.dialog.closeAll();
        if (callback) {
            callback();
        }
    }
}
