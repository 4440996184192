import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { BookingConnectorService } from '../../../../../services/api-connector/booking-connector.service';
import { TranslateService } from '@ngx-translate/core';
import { BookingEntity } from 'data-structures/lib/es6/entity/booking.entity';
import { differenceInDays } from 'date-fns';
import { ExtendedBookingEntity } from '../../../../../entities/extended-booking-entity';
import { plainToInstance } from 'class-transformer';
import { DateService } from '../../../../../services/date/date.service';
import { BookingViewComponent } from '../../booking/booking-view/booking-view.component';
import { DialogService } from '../../../../../services/dialog.service';

@Component({
    selector: 'app-dashboard-next-travels',
    templateUrl: './next-travels.component.html',
    styleUrls: ['./next-travels.component.scss', './../dashboard.component.scss'],
})
export class NextTravelsComponent implements OnInit {
    nextTravels: ExtendedBookingEntity[] = [];
    viewBooking: ExtendedBookingEntity;

    constructor(
        readonly bookingConnectorService: BookingConnectorService,
        readonly authenticationService: AuthenticationService,
        readonly translationService: TranslateService,
        readonly dialogService: DialogService,
    ) {}

    async ngOnInit() {
        const results = await this.bookingConnectorService.getNextTravels(this.authenticationService.currentUser);
        const bookings = results.result;
        for (const booking of bookings) {
            const nextTravel: ExtendedBookingEntity = plainToInstance(ExtendedBookingEntity, booking);
            nextTravel.lengthText = await this.getBookingLengthText(nextTravel);
            this.nextTravels.push(nextTravel);
        }
    }

    getBookingLength(booking: BookingEntity) {
        return differenceInDays(DateService.getDateFromString(booking.departureDate), DateService.getDateFromString(booking.arrivalDate));
    }

    async getBookingLengthText(booking: BookingEntity) {
        let text = await this.translationService.get('atraveo.frontendbundle.duration-option.days').toPromise();
        text = text.replace('{{number}}', this.getBookingLength(booking));
        return text;
    }

    openBookingViewDialog() {
        this.dialogService.openDialog(
            BookingViewComponent,
            { dialogWidth: this.dialogService.dialogWidth.M },
            {
                booking: this.viewBooking,
                id: 'bookingView',
            },
        );
    }
}
