import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Overlay, ScrollStrategyOptions } from '@angular/cdk/overlay';
@Injectable({
    providedIn: 'root',
})
export class DialogService {
    backdropClick$: Subject<void> = new Subject<void>();
    dialogWidth = {
        L: '1080px',
        M: '800px',
        SM: '600px',
    };
    constructor(readonly dialog: MatDialog, readonly breakpointObserver: BreakpointObserver, private overlay: Overlay, private scrollStrategyOptions: ScrollStrategyOptions) {}
    dialogRef: any;
    openDialog(dialogName: ComponentType<any> | TemplateRef<any>, options: { dialogWidth: string; panelClass?: string | null }, dialogData: any = null) {
        const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 600px)');
        this.dialogRef = this.dialog.open(dialogName, {
            height: 'auto',
            width: isSmallScreen ? '90vw' : options.dialogWidth,
            maxWidth: '90vw',
            maxHeight: '90vh',
            data: dialogData,
            scrollStrategy: this.getScrollStrategy(),
            position: { top: '5vh' },
            panelClass: options?.panelClass,
        });

        document.body.classList.add('dialog-open');

        const dialogRefSubscription = this.dialogRef.afterClosed().subscribe(() => {
            document.body.classList.remove('dialog-open');
            dialogRefSubscription.unsubscribe();
        });

        if (dialogData?.fromCalender) {
            this.dialogRef.backdropClick().subscribe(() => {
                this.backdropClick$.next();
            });
        }
    }
    getScrollStrategy() {
        // Blockiert das Scrollen des Hauptinhalts
        return this.scrollStrategyOptions.block();
    }
    closeDialog(callback?: () => void): void {
        if (this.dialog.openDialogs.length > 0) {
            this.dialog.closeAll();
            this.dialog.openDialogs.forEach((dialog) => dialog.close());
            if (callback) {
                callback();
            }
        }
    }
}
