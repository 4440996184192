import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../../../entities/extendedAccommodationEntity';
import { ExtendedAdditionalCostDefinitionEntity } from '../../../../../../../entities/extended-additional-cost-definition-entity';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApiConnectorService } from '../../../../../../../services/api-connector/api-connector.service';
import { AdditionalCostService } from '../../../../../../../services/additional-cost/additional-cost-service';
import { ExtendedAdditionalCost } from '../../../../../../../entities/extended-addition-cost';
import { AdditionalCostRuleEntity } from 'data-structures/lib/es6/entity/additional-cost/rules/rules.entity';
import { AuthenticationService } from '../../../../../../../services/authentication/authentication.service';
import { ExtendedAdditionalCostTemplateEntity } from '../../../../../../../entities/extended-additional-cost-template-entity';
import { environment } from '../../../../../../../../environments/environment';
import { cloneDeep, isEqual } from 'lodash';
import { NotificationService } from '../../../../../../../services/notification/notification.service';
import { MinMaxDto } from 'data-structures/lib/es6/dto/accommodation/additional-cost/min-max.dto';
import { FromToDateDto } from 'data-structures/lib/es6/dto/accommodation/additional-cost/from-to-date.dto';
import { ConfirmationDialogService } from '../../../../../../global/confirmation-dialog/confirmation-dialog.service';
import { TravelPeriodService } from '../../../../../../../services/travel-period/travel-period.service';
import { OwnerCurrencyEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { IntervalTypes, ObligationTypes } from 'data-structures/lib/es6/dto/accommodation/additional-cost/additional-cost.dto';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TravelPeriodDto } from 'data-structures/lib/es6/dto/accommodation/price-rule/travel-period.dto';
import { addDays, addYears, format, subYears } from 'date-fns';

/*
 Kurzbeschreibung wie die allowedValues funktionieren:
 - local: [1] oder local: 1 bedeutet, dass er 1 für Local übernehmen soll
 - hideLocal: 1 bedeutet, dass er Local Checkbox anhaken soll und gleichzeitig ausblenden
 - Pauschal werden erstmal alle Container eingeblendet. Wenn beispielsweise der pricecontainer Eintrag vorhanden und true ist,
  dann Block anzeigen. Bei false ausblenden.
 - local: [true, false] bedeutet, dass Local an und abgehakt werden kann. Sprich mögliche Werte.
*/

@Component({
    selector: 'app-create',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit, OnChanges, OnDestroy {
    @Input() modalId: string;
    @Input() additionalCost: ExtendedAdditionalCost;
    @Input() additionalCostIsNew: boolean;
    @Input() additionalCostDefinitionsObject: { [id: string]: ExtendedAdditionalCostDefinitionEntity } = {};
    @Output() onSave: EventEmitter<ExtendedAccommodationEntity | ExtendedAdditionalCostTemplateEntity> = new EventEmitter<ExtendedAccommodationEntity | ExtendedAdditionalCostTemplateEntity>();
    @Input() accommodationOrTemplateInput: ExtendedAccommodationEntity | ExtendedAdditionalCostTemplateEntity;
    accommodationOrTemplate: ExtendedAccommodationEntity | ExtendedAdditionalCostTemplateEntity;

    isAdmin: boolean = false;
    ownerDefaultCurrency: OwnerCurrencyEnum;

    sortedAdditionalCostDefinitions: ExtendedAdditionalCostDefinitionEntity[] = [];
    additionalCostRules: AdditionalCostRuleEntity[] = [];
    currentAllowedValues: any;
    isEnergyCost: boolean = false; // Nebenkosten ist Energie, und damit sind die Preise optional
    priceIsOptional: boolean = false;
    mixMaxFieldsAndTranslations = [
        {
            // gilt bei einer Reisedauer von
            variable: 'duration',
            info: 'info.reisedauer',
            texts: 'Occupancy',
        },
        {
            // gilt bei
            variable: 'occupancy', // Occupancy wurde im Inventory für ein anderes Feld benutzt :(
            info: 'info.gruppengroesse',
            texts: 'Persons',
        },
        {
            // gilt bei einem Alter von
            variable: 'calcAges',
            info: 'info.alter',
            texts: 'Age',
        },
        {
            // gilt nur für Tag
            variable: 'calcDays',
            info: 'info.calcForDay',
            texts: 'CalcForDay',
            steps: 1,
        },
        {
            // gilt nur für Person
            variable: 'calcPersons',
            info: 'info.calcForPerson',
            texts: 'CalcForPerson',
        },
    ];
    activeMixMaxFieldsAndTranslations: any = [];
    currencies = environment.additionalCostCurrencies;
    private $modal: any;
    notRequiredCosts = ['ENERGIE', 'GAS', 'HEISSWASSE', 'HEIZKOSTEN', 'HEIZOEL', 'KLIMAANL', 'SCHBADHT', 'SAUNA', 'SOLARIUM', 'STROM', 'WARMWASSER'];

    isDateRangeToLong: boolean = false;
    showLimitationsBecauseEmployee: boolean = false;
    editableDepositBecauseEmployee: boolean = false;

    private originalAdditionalCost: ExtendedAdditionalCost;
    usedAdditionalCost: ExtendedAdditionalCost;
    createForm: FormGroup;
    createFormValues: any = {};
    formObligations: any = [];
    formIntervals: any = [];
    formUnits: any = [];
    formContainerVisibilty: Map<string, boolean> = new Map<string, boolean>();
    minDateFrom: string;
    maxDateFrom: string;
    minDateTo: string;
    maxDateTo: string;

    translationServiceSubscription: Subscription;
    valueChangeSubscription: Subscription;
    pricetypeChangeSubscription: Subscription;

    constructor(
        readonly translationService: TranslateService,
        readonly apiConnectorService: ApiConnectorService,
        readonly additionalCostService: AdditionalCostService,
        readonly authenticationService: AuthenticationService,
        readonly notificationService: NotificationService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly travelPeriodService: TravelPeriodService,
    ) {
        const minDate = subYears(new Date(), 10);
        const maxDate = addYears(new Date(), 20);

        this.minDateFrom = format(minDate, 'yyyy-MM-dd');
        this.maxDateFrom = format(maxDate, 'yyyy-MM-dd');

        this.minDateTo = format(minDate, 'yyyy-MM-dd');
        this.maxDateTo = format(maxDate, 'yyyy-MM-dd');

        this.createForm = new FormGroup({
            // Form Controls
            additionalCost: new FormControl('', Validators.required),
            obligation: new FormControl(''),
            interval: new FormControl(''),
            unit: new FormControl(''),
            maxPieceCount: new FormControl(''),
            paymentType: new FormControl(''),
            refundType: new FormControl(''),
            price: new FormControl(''),
            currency: new FormControl(''),
            priceType: new FormControl(''),
            percent: new FormControl(''),
            dailyRental: new FormControl(''),
            hasToBeOrdered: new FormControl(''),
            hasToBePaidLocal: new FormControl(''),
            minPrice: new FormControl('', [Validators.min(1), Validators.max(100000)]),
            maxPrice: new FormControl('', [Validators.min(1), Validators.max(100000)]),
            travelPeriodFrom: new FormControl(''),
            travelPeriodTo: new FormControl(''),
            followingYears: new FormControl(''),
            durationMin: new FormControl('', [Validators.min(1), Validators.max(999)]),
            durationMax: new FormControl('', [Validators.min(1), Validators.max(999)]),
            occupancyMin: new FormControl('', [Validators.min(1), Validators.max(999)]),
            occupancyMax: new FormControl('', [Validators.min(1), Validators.max(999)]),
            calcAgesMin: new FormControl('', [Validators.min(1), Validators.max(999)]),
            calcAgesMax: new FormControl('', [Validators.min(1), Validators.max(999)]),
            calcDaysMin: new FormControl('', [Validators.min(1), Validators.max(999)]),
            calcDaysMax: new FormControl('', [Validators.min(1), Validators.max(999)]),
            calcPersonsMin: new FormControl('', [Validators.min(1), Validators.max(999)]),
            calcPersonsMax: new FormControl('', [Validators.min(1), Validators.max(999)]),
        });

        // Subscriber für Wertänderungen im Formular
        this.valueChangeSubscription = this.createForm.valueChanges.subscribe((values) => {
            if (this.additionalCost.additionalCost) {
                values.additionalCost = this.additionalCost.additionalCost;
            }
            // nur wenn values ungleich der gespeicherten Werte sind, dann speichern
            if (isEqual(this.createFormValues, values)) {
                return;
            }
            this.createFormValues = cloneDeep(values);
            this.usedAdditionalCost.additionalCost = values.additionalCost;
            this.usedAdditionalCost.obligation = values.obligation;
            this.usedAdditionalCost.interval = values.interval;
            this.usedAdditionalCost.unit = values.unit;
            this.usedAdditionalCost.maxPieceCount = values.maxPieceCount;
            this.usedAdditionalCost.paymentType = values.paymentType;
            this.usedAdditionalCost.price = values.price;
            this.usedAdditionalCost.refundType = values.refundType;
            this.usedAdditionalCost.currency = values.currency;
            this.usedAdditionalCost.percent = values.percent;
            this.usedAdditionalCost.dailyRental = values.dailyRental;
            this.usedAdditionalCost.hasToBeOrdered = values.hasToBeOrdered;
            this.usedAdditionalCost.hasToBePaidLocal = values.hasToBePaidLocal;
            this.usedAdditionalCost.minPrice = values.minPrice;
            this.usedAdditionalCost.maxPrice = values.maxPrice;
            this.usedAdditionalCost.travelPeriod = {
                from: values.travelPeriodFrom,
                to: values.travelPeriodTo,
                followingYears: values.followingYears,
            };
            this.usedAdditionalCost.duration = { min: values.durationMin, max: values.durationMax };
            this.usedAdditionalCost.occupancy = { min: values.occupancyMin, max: values.occupancyMax };
            this.usedAdditionalCost.calcAges = { min: values.calcAgesMin, max: values.calcAgesMax };
            this.usedAdditionalCost.calcDays = { min: values.calcDaysMin, max: values.calcDaysMax };
            this.usedAdditionalCost.calcPersons = { min: values.calcPersonsMin, max: values.calcPersonsMax };

            this.isEnergyCost = this.notRequiredCosts.includes(this.usedAdditionalCost.additionalCost);
            this.priceIsOptional = this.isEnergyCost && this.usedAdditionalCost.interval === IntervalTypes.PER_USAGE;

            if (values.travelPeriodFrom) {
                this.minDateTo = format(addDays(new Date(values.travelPeriodFrom), 1), 'yyyy-MM-dd');
            }
            if (values.travelPeriodTo) {
                this.maxDateFrom = format(addDays(new Date(values.travelPeriodTo), 1), 'yyyy-MM-dd');
            }

            // prüfe die Werte und passe die Formularfelder an und aktiviere/deaktiviere die Felder/Validatoren
            this.updateFormFields(values);
        });

        // Subscriber für Input-Änderungen beim feld PriceType
        this.pricetypeChangeSubscription = this.createForm.get('priceType').valueChanges.subscribe((value) => {
            this.updateValidators();
        });
    }
    async ngOnInit() {
        this.isAdmin = this.authenticationService.currentUser.isAdmin() || environment.stage === 'dev';
        this.accommodationOrTemplate = cloneDeep(this.accommodationOrTemplateInput);
        this.ownerDefaultCurrency = this.authenticationService.currentUser.settings?.currency ?? OwnerCurrencyEnum.EUR;

        this.$modal = $('#createCost-' + this.modalId);

        if (!this.sortedAdditionalCostDefinitions.length) {
            this.sortedAdditionalCostDefinitions = [];

            const language = this.translationService.currentLang;

            for (const [key, definition] of Object.entries(this.additionalCostDefinitionsObject)) {
                if (!definition.deprecated && definition.additionalCostName && definition.additionalCostName[language]) {
                    this.sortedAdditionalCostDefinitions.push(definition);
                }
            }
            this.sortAdditionalCostsByName();
        }

        if (!this.additionalCostRules.length) {
            this.additionalCostRules = await this.apiConnectorService.getAdditionalCostRules();
        }

        this.translationServiceSubscription = this.translationService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.sortAdditionalCostsByName();
        });

        if (this.additionalCost) {
            this.usedAdditionalCost = cloneDeep(this.additionalCost);
        }
        this.updateFormValues();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.accommodationOrTemplateInput && !changes.accommodationOrTemplateInput.firstChange) {
            this.accommodationOrTemplate = cloneDeep(this.accommodationOrTemplateInput);
        }

        if (changes.additionalCost && !changes.additionalCost.firstChange) {
            this.usedAdditionalCost = cloneDeep(this.additionalCost);
            this.updateFormValues();
            this.createForm.markAsTouched();
        }
    }

    ngOnDestroy() {
        this.translationServiceSubscription?.unsubscribe();
        this.valueChangeSubscription?.unsubscribe();
        this.pricetypeChangeSubscription?.unsubscribe();
    }

    setAdditionCostsActivity() {
        if (this.additionalCost.additionalCost) {
            this.createForm.get('additionalCost').disable();
        } else {
            this.createForm.get('additionalCost').enable();
        }
    }

    private updateFormValues() {
        this.createForm.patchValue({
            additionalCost: this.usedAdditionalCost.additionalCost,
            obligation: this.usedAdditionalCost.obligation,
            interval: this.usedAdditionalCost.interval,
            unit: this.usedAdditionalCost.unit,
            maxPieceCount: this.usedAdditionalCost.maxPieceCount,
            paymentType: this.usedAdditionalCost.paymentType,
            refundType: this.usedAdditionalCost.refundType,
            price: this.usedAdditionalCost.price,
            currency: this.usedAdditionalCost.currency,
            percent: this.usedAdditionalCost.percent,
            dailyRental: this.usedAdditionalCost.dailyRental,
            hasToBeOrdered: this.usedAdditionalCost.hasToBeOrdered,
            hasToBePaidLocal: this.usedAdditionalCost.hasToBePaidLocal,
            minPrice: this.usedAdditionalCost.minPrice,
            maxPrice: this.usedAdditionalCost.maxPrice,
            travelPeriodFrom: this.usedAdditionalCost.travelPeriod?.from,
            travelPeriodTo: this.usedAdditionalCost.travelPeriod?.to,
            followingYears: this.usedAdditionalCost.travelPeriod?.followingYears,
            durationMin: this.usedAdditionalCost.duration?.min,
            durationMax: this.usedAdditionalCost.duration?.max,
            occupancyMin: this.usedAdditionalCost.occupancy?.min,
            occupancyMax: this.usedAdditionalCost.occupancy?.max,
            calcAgesMin: this.usedAdditionalCost.calcAges?.min,
            calcAgesMax: this.usedAdditionalCost.calcAges?.max,
            calcDaysMin: this.usedAdditionalCost.calcDays?.min,
            calcDaysMax: this.usedAdditionalCost.calcDays?.max,
            calcPersonsMin: this.usedAdditionalCost.calcPersons?.min,
            calcPersonsMax: this.usedAdditionalCost.calcPersons?.max,
        });
        this.createForm.markAsUntouched();
    }

    private updateFormFields(values) {
        const costRules = this.additionalCostService.applyAdditionalCostRules(
            this.additionalCostService.standardAllowedValues(),
            this.usedAdditionalCost,
            this.additionalCostRules,
            this.additionalCostIsNew,
        );

        if (Number(values.obligation) === ObligationTypes.MANDATORY) {
            if (values.currency === this.ownerDefaultCurrency && !['KURTAXE', 'KURTAXEHU', 'UMWELT'].includes(values.additionalCost)) {
                costRules.allowedValues.local = [0, 1];
            } else {
                costRules.allowedValues.local = [1];
            }
        }

        // aktuelle Formular-Values
        const originalValues = cloneDeep(values);

        // Feld Obligation
        this.formObligations = costRules.allowedValues.obligation;
        // reset auf "bitte wählen" wenn es nicht mehr in der Liste ist
        if (this.usedAdditionalCost.obligation == null || !this.formObligations.includes(this.additionalCostService.getValueForType('obligation', this.usedAdditionalCost))) {
            values.obligation = '';
        }

        // Feld Interval
        this.formIntervals = costRules.allowedValues.interval;
        // reset auf "bitte wählen" wenn es nicht mehr in der Liste ist
        if (
            this.usedAdditionalCost.interval == null ||
            (this.usedAdditionalCost?.interval && !this.formIntervals.includes(this.additionalCostService.getValueForType('interval', this.usedAdditionalCost)))
        ) {
            values.interval = '';
        }

        // Feld Unit
        this.formUnits = costRules.allowedValues.unit.filter((value) => !!value);
        if (['KURTAXE'].includes(this.additionalCost.additionalCost) && values.priceType === 'percent') {
            this.formUnits = [];
        }

        // reset auf "bitte wählen" wenn es nicht mehr in der Liste ist
        if (this.usedAdditionalCost.unit == null || (this.usedAdditionalCost?.unit && !this.formUnits.includes(this.additionalCostService.getValueForType('unit', this.usedAdditionalCost)))) {
            values.unit = '';
        }
        // nach Verbrauch
        if (values.interval === 'nv' && values.unit === '') {
            values.unit = this.formUnits[0];
        }

        // Feld paymentType
        if (this.usedAdditionalCost.paymentType == null) {
            values.paymentType = '';
        }

        // Feld refundType
        if (this.usedAdditionalCost.refundType == null) {
            values.refundType = '';
        }

        // speziell für Kaution
        if (['KAUTION'].includes(this.usedAdditionalCost.additionalCost)) {
            // Es muss beides erlaubt werden, damit die Auswahl von Admins nicht verloren geht bzw diese als Admin gewählt werden dürfen
            costRules.allowedValues.local = [0, 1];

            if (this.authenticationService.currentUser.isOwner() || this.authenticationService.currentUser.isEmployee()) {
                this.editableDepositBecauseEmployee = true;
                costRules.allowedValues.localcheckboxcontainer = true;
            }

            if (this.createForm.get('hasToBePaidLocal').enabled) {
                // Admin möchte neue Kaution anlegen, dann muss vor Ort (default) gewählt sein
                if (this.editableDepositBecauseEmployee && this.additionalCostIsNew) {
                    values.hasToBePaidLocal = true;
                }

                // Vermieter nimmt Änderung vor
                if (!this.editableDepositBecauseEmployee) {
                    values.hasToBePaidLocal = this.additionalCostIsNew ? true : this.originalAdditionalCost?.hasToBePaidLocal;
                }
                if (values.hasToBePaidLocal === true) {
                    this.createForm.get('hasToBePaidLocal').disable();
                }
            }
        }

        // default priceType setzen
        if (values.priceType === '') {
            if (values.dailyRental) {
                values.priceType = 'dailyRental';
            } else if (values.percent) {
                values.priceType = 'percent';
            } else values.priceType = 'price';
        }

        // default für localpayment und localbackpay setzen
        if (['KAUTION', 'KAUTIONHAU'].includes(this.usedAdditionalCost.additionalCost)) {
            if (values.paymentType === '') {
                values.paymentType = 'LOCAL_CASH';
            }
            if (values.refundType === '') {
                values.refundType = 'LOCAL_CASH';
            }
        }

        // für Kaution und prozent muss die Einheit deaktiviert werden
        if (['KURTAXE'].includes(this.usedAdditionalCost.additionalCost) && values.priceType === 'percent') {
            this.formContainerVisibilty.set('unitcontainer', false);
        }

        // Formular-Values aktualisieren
        if (!isEqual(originalValues, values)) {
            this.createForm.patchValue(values);
            return;
        }
        const actFormContainerVisibilty = cloneDeep(this.formContainerVisibilty);

        // Schalte die einzelnen Divs ein oder aus
        const containers = [
            'intervalcontainer',
            'unitcontainer',
            'perunitcontainer',
            'pricerow',
            'pricecontainer',
            'minmaxcontainer',
            'checkboxcontainer',
            'localcontainer',
            'localcheckboxcontainer',
            'ordercontainer',
            'pricefieldcontainer',
            'percentfieldcontainer',
            'dailyrentalfieldcontainer',
            'piececountcontainer',
            'obligationcontainer',
            'localdepositcontainer',
        ];

        const hideAll = !this.usedAdditionalCost?.additionalCost;
        for (const container of containers) {
            this.formContainerVisibilty.set(container, !(hideAll || costRules.allowedValues[container] === false));
        }

        // korrigiere subcontainer
        if (this.formContainerVisibilty.get('pricerow') === false) {
            this.formContainerVisibilty.set('localdepositcontainer', false);
            this.formContainerVisibilty.set('pricecontainer', false);
            this.formContainerVisibilty.set('checkboxcontainer', false);
        }

        if (this.formContainerVisibilty.get('pricecontainer') === false) {
            this.formContainerVisibilty.set('percentfieldcontainer', false);
            this.formContainerVisibilty.set('dailyrentalfieldcontainer', false);
        }

        if (this.formContainerVisibilty.get('percentfieldcontainer') === false) {
            this.formContainerVisibilty.set('percentfield', false);
        }

        if (this.formContainerVisibilty.get('dailyrentalfieldcontainer') === false) {
            this.formContainerVisibilty.set('dailyRentalField', false);
        }

        if (this.formContainerVisibilty.get('checkboxcontainer') === false) {
            this.formContainerVisibilty.set('ordercontainer', false);
            this.formContainerVisibilty.set('localcheckboxcontainer', false);
        }

        // speziell für die Einschränkungen
        const isEDomizil = this.additionalCostService.getTransformedValueForTypeFromOwner('eDomizil');
        this.formContainerVisibilty.set('limitationscontainer', true);
        this.activeMixMaxFieldsAndTranslations = this.mixMaxFieldsAndTranslations;
        this.showLimitationsBecauseEmployee = false;

        if (isEDomizil) {
            // Alle verstecken, bis auf die in der Liste
            const allowedAdditionalCosts = ['UMWELT', 'KURTAXE', 'KLIMAANL', 'BRENNHOLZ', 'GAS', 'HEIZKOSTEN', 'KURZAUFH'];
            this.showLimitationsBecauseEmployee = !allowedAdditionalCosts.includes(this.usedAdditionalCost.additionalCost);

            if (allowedAdditionalCosts.includes(this.usedAdditionalCost.additionalCost) || (this.usedAdditionalCost.additionalCost && this.authenticationService.currentUser.isEmployee())) {
                if (['KURTAXE', 'KURZAUFH'].includes(this.usedAdditionalCost.additionalCost) || (this.usedAdditionalCost.additionalCost && this.authenticationService.currentUser.isEmployee())) {
                    // Alle anzeigen
                    if (costRules.allowedValues.minmaxcontainer !== false) {
                        this.formContainerVisibilty.set('minmaxcontainer', true);
                    }
                } else {
                    // Nur Reisezeitraum
                    this.activeMixMaxFieldsAndTranslations = [];
                    if (costRules.allowedValues.minmaxcontainer !== true) {
                        this.formContainerVisibilty.set('minmaxcontainer', false);
                    }
                }
            } else {
                this.formContainerVisibilty.set('limitationscontainer', false);
            }
        }

        // Korrektur für leere Values
        if (this.formUnits.length === 0 && this.formContainerVisibilty.get('unitcontainer')) {
            this.formContainerVisibilty.set('unitcontainer', false);
        }

        // default currency setzen
        if (this.createForm.get('currency').value === '') {
            this.createForm.get('currency').setValue(this.ownerDefaultCurrency);
        }

        if (this.createForm.get('priceType').value === 'price') {
            this.formContainerVisibilty.set('pricefield', true);
            this.formContainerVisibilty.set('percentfield', false);
            this.formContainerVisibilty.set('dailyRentalField', false);
        } else if (this.createForm.get('priceType').value === 'percent') {
            this.formContainerVisibilty.set('percentfield', true);
            this.formContainerVisibilty.set('pricefield', false);
            this.formContainerVisibilty.set('dailyRentalField', false);
        } else if (this.createForm.get('priceType').value === 'dailyRental') {
            this.formContainerVisibilty.set('dailyRentalField', true);
            this.formContainerVisibilty.set('pricefield', false);
            this.formContainerVisibilty.set('percentfield', false);
        }

        // enabled/disable die Checkboxen
        this.updateCheckBoxes(costRules.allowedValues);

        // const debug = false;
        // if (debug) {
        //     for (const container of containers) {
        //         this.formContainerVisibilty.set(container, true);
        //     }
        //     this.formContainerVisibilty.set('pricefield', true);
        //     this.formContainerVisibilty.set('percentfield', true);
        //     this.formContainerVisibilty.set('dailyRentalField', true);
        // }

        // müssen die Validatoren wieder aktualisiert werden?
        if (!isEqual(actFormContainerVisibilty, this.formContainerVisibilty)) {
            this.updateValidators();
        }
    }

    updateValidators() {
        // erstmal alle Validatoren entfernen
        for (const controlName in this.createForm.controls) {
            if (
                ![
                    'additionalCost',
                    'minPrice',
                    'maxPrice',
                    'durationMin',
                    'durationMax',
                    'occupancyMin',
                    'occupancyMax',
                    'calcAgesMin',
                    'calcAgesMax',
                    'calcDaysMin',
                    'calcDaysMax',
                    'calcPersonsMin',
                    'calcPersonsMax',
                ].includes(controlName)
            ) {
                if (this.createForm.controls.hasOwnProperty(controlName)) {
                    const control = this.createForm.get(controlName);

                    // Validatoren entfernen
                    control.clearValidators();
                }
            }
        }

        // abhängig von den aktiven formContainerVisibilty-Elementen werden die Validatoren gesetzt
        if (this.formContainerVisibilty.get('obligationcontainer')) {
            this.createForm.get('obligation').setValidators([Validators.required]);
        }
        this.createForm.get('obligation').updateValueAndValidity();

        if (this.formContainerVisibilty.get('intervalcontainer')) {
            this.createForm.get('interval').setValidators([Validators.required]);
        }
        this.createForm.get('interval').updateValueAndValidity();

        if (this.formContainerVisibilty.get('piececountcontainer')) {
            this.createForm.get('maxPieceCount').setValidators([Validators.min(1), Validators.max(50)]);
        }
        this.createForm.get('maxPieceCount').updateValueAndValidity();

        if (this.formContainerVisibilty.get('localdepositcontainer')) {
            this.createForm.get('paymentType').setValidators([Validators.required]);
            this.createForm.get('refundType').setValidators([Validators.required]);
        }
        this.createForm.get('paymentType').updateValueAndValidity();

        const priceValidators = [Validators.min(0.01), Validators.max(1000000)];
        if (!this.priceIsOptional) {
            priceValidators.push(Validators.required);
        }
        if (this.formContainerVisibilty.get('pricecontainer') && this.createForm.get('priceType').value === 'price') {
            this.createForm.get('price').setValidators(priceValidators);
            this.createForm.get('currency').setValidators([Validators.required]);
        }

        if (this.formContainerVisibilty.get('percentfieldcontainer') && this.createForm.get('priceType').value === 'percent') {
            this.createForm.get('percent').setValidators(priceValidators);
        }

        if (this.formContainerVisibilty.get('dailyrentalfieldcontainer') && this.createForm.get('priceType').value === 'dailyRental') {
            this.createForm.get('dailyRental').setValidators(priceValidators);
        }
        this.createForm.get('price').updateValueAndValidity();
        this.createForm.get('currency').updateValueAndValidity();
        this.createForm.get('percent').updateValueAndValidity();
        this.createForm.get('dailyRental').updateValueAndValidity();

        if (this.formContainerVisibilty.get('localcheckboxcontainer') && ['KAUTION'].includes(this.usedAdditionalCost.additionalCost)) {
            this.createForm.get('hasToBePaidLocal').setValidators([Validators.requiredTrue]);
        }

        this.setAdditionCostsActivity();
    }

    async onSubmit() {
        if (this.createForm.valid) {
            // hole die Werte aus dem Formular
            const values = this.createForm.getRawValue();
            this.additionalCost.additionalCost = values.additionalCost;
            this.additionalCost.obligation = values.obligation;
            this.additionalCost.interval = values.interval;
            this.additionalCost.unit = values.unit;
            this.additionalCost.maxPieceCount = values.maxPieceCount;
            this.additionalCost.paymentType = values.paymentType;
            this.additionalCost.price = values.price;
            this.additionalCost.currency = values.currency;
            this.additionalCost.percent = values.percent;
            this.additionalCost.dailyRental = values.dailyRental;
            this.additionalCost.refundType = values.refundType;
            this.additionalCost.hasToBeOrdered = !!values.hasToBeOrdered;
            this.additionalCost.hasToBePaidLocal = !!values.hasToBePaidLocal;
            this.additionalCost.minPrice = values.minPrice;
            this.additionalCost.maxPrice = values.maxPrice;

            const travelPeriod = new FromToDateDto();
            if (values.travelPeriodFrom) travelPeriod.from = values.travelPeriodFrom;
            if (values.travelPeriodTo) travelPeriod.to = values.travelPeriodTo;
            travelPeriod.followingYears = values.followingYears;
            this.additionalCost.travelPeriod = travelPeriod;

            const duration = new MinMaxDto();
            if (values.durationMin) duration.min = values.durationMin;
            if (values.durationMax) duration.max = values.durationMax;
            this.additionalCost.duration = duration;

            const occupancy = new MinMaxDto();
            if (values.occupancyMin) occupancy.min = values.occupancyMin;
            if (values.occupancyMax) occupancy.max = values.occupancyMax;
            this.additionalCost.occupancy = occupancy;

            const calcAges = new MinMaxDto();
            if (values.calcAgesMin) calcAges.min = values.calcAgesMin;
            if (values.calcAgesMax) calcAges.max = values.calcAgesMax;
            this.additionalCost.calcAges = calcAges;

            const calcDays = new MinMaxDto();
            if (values.calcDaysMin) calcDays.min = values.calcDaysMin;
            if (values.calcDaysMax) calcDays.max = values.calcDaysMax;
            this.additionalCost.calcDays = calcDays;

            const calcPersons = new MinMaxDto();
            if (values.calcPersonsMin) calcPersons.min = values.calcPersonsMin;
            if (values.calcPersonsMax) calcPersons.max = values.calcPersonsMax;
            this.additionalCost.calcPersons = calcPersons;

            // bereinige Werte die nicht mehr sichtbar sind
            if (!this.formContainerVisibilty.get('intervalcontainer')) {
                this.additionalCost.interval = null;
            }
            if (!this.formContainerVisibilty.get('unitcontainer')) {
                this.additionalCost.unit = null;
            }
            if (!this.formContainerVisibilty.get('piececountcontainer')) {
                this.additionalCost.maxPieceCount = null;
            }

            if (!this.formContainerVisibilty.get('pricerow') || !this.formContainerVisibilty.get('pricecontainer')) {
                this.additionalCost.price = null;
                // this.additionalCost.currency = null; // wenn keine currency gibt es Fehler in der Darstellung bei Pflichtfeldern
            }
            if (!this.formContainerVisibilty.get('pricerow') || !this.formContainerVisibilty.get('percentfieldcontainer')) {
                this.additionalCost.percent = null;
            }
            if (!this.formContainerVisibilty.get('pricerow') || !this.formContainerVisibilty.get('dailyrentalfieldcontainer')) {
                this.additionalCost.dailyRental = null;
            }
            if (!this.formContainerVisibilty.get('pricerow') || !this.formContainerVisibilty.get('localdepositcontainer')) {
                this.additionalCost.paymentType = null;
                this.additionalCost.refundType = null;
            }

            if (!this.formContainerVisibilty.get('checkboxcontainer') || !this.formContainerVisibilty.get('localcheckboxcontainer')) {
                this.additionalCost.hasToBePaidLocal = null;
            }
            if (!this.formContainerVisibilty.get('checkboxcontainer') || !this.formContainerVisibilty.get('ordercontainer')) {
                this.additionalCost.hasToBeOrdered = null;
            }

            // reset von den Felder, die nicht dem priceType entsprechen
            if (values.priceType === 'price') {
                this.additionalCost.percent = null;
                this.additionalCost.dailyRental = null;
            } else if (values.priceType === 'percent') {
                this.additionalCost.price = null;
                this.additionalCost.dailyRental = null;
            } else if (values.priceType === 'dailyRental') {
                this.additionalCost.price = null;
                this.additionalCost.percent = null;
            }

            // Überprüfe ob die Reisedauer zu lang ist
            if (this.travelPeriodService.isDateRangeToLong(this.additionalCost.travelPeriod)) {
                this.isDateRangeToLong = true;
                return;
            }

            if (this.additionalCost.travelPeriod?.from && this.additionalCost.travelPeriod?.to && !this.additionalCost.travelPeriod?.followingYears) {
                if (new Date(this.additionalCost.travelPeriod.to) < new Date(this.additionalCost.travelPeriod.from)) {
                    const fromDate = this.additionalCost.travelPeriod.from;
                    this.additionalCost.travelPeriod.from = this.additionalCost.travelPeriod.to;
                    this.additionalCost.travelPeriod.to = fromDate;
                }
            }

            // Kaution keine vor Ort ergibt keinen Sinn. Price und Intervall dürfen hier aber nicht pauschal entfernt werden.
            // Die Nk-Regeln setzt ggf. wichtige Werte die hier dann verloren gehen.
            if ([ObligationTypes.NONE, ObligationTypes.INCLUSIVE].includes(Number(this.additionalCost.obligation))) {
                this.additionalCost.hasToBePaidLocal = false;
            }

            let found = false;

            this.additionalCost.obligation = Number(this.additionalCost.obligation);
            const indexToUpdate = this.accommodationOrTemplate.additionalCosts.findIndex((additionalCost) => additionalCost.id === this.additionalCost.id);
            if (indexToUpdate !== -1) {
                this.accommodationOrTemplate.additionalCosts[indexToUpdate] = this.additionalCost;
                found = true;
            }

            // Detaillierte Prüfung, ob es Nebenkosten mit überschneidungen gibt
            if (this.isOverlapping()) {
                await this.notificationService.add('atraveo.accommodationbundle.additionalcost.accommodation-uniqueness-violation', 'danger');
                return;
            }

            if (!found) {
                this.additionalCost.obligation = Number(this.additionalCost.obligation);
                this.accommodationOrTemplate.additionalCosts.push(this.additionalCost);
            }
            const clone = this.getCloneWithEmptyValuesRemoved();

            this.onSave.next(clone);
            this.$modal.modal('hide');
        } else {
            // Formularfelder als touched markieren, damit Fehlermeldungen angezeigt werden
            this.createForm.markAllAsTouched();
        }
    }

    sortAdditionalCostsByName() {
        const language = this.translationService.currentLang;
        this.sortedAdditionalCostDefinitions.sort((a, b) => {
            return a.additionalCostName[language].localeCompare(b.additionalCostName[language]);
        });
    }

    private updateCheckBoxes(allowedValues: any) {
        const checkBoxes = {
            local: 'hasToBePaidLocal',
            order: 'hasToBeOrdered',
        };

        for (const [key, formFieldName] of Object.entries(checkBoxes)) {
            const availableValues = allowedValues[key];
            // @ts-ignore
            if (availableValues.length === 1 && !['KURZAUFH'].includes(this.usedAdditionalCost.additionalCost)) {
                this.createForm.get(formFieldName).setValue(availableValues[0]);
                this.createForm.get(formFieldName).disable();
            } else {
                if (!['KAUTION'].includes(this.usedAdditionalCost.additionalCost)) {
                    this.createForm.get(formFieldName).enable();
                }
            }
        }
    }

    genericCheckIfOverlap(from1: number, to1: number, from2: number, to2: number) {
        if (!from1) {
            from1 = 0;
        }
        if (!from2) {
            from2 = 0;
        }
        if (!to1) {
            to1 = 10000000;
        }
        if (!to2) {
            to2 = 10000000;
        }
        return Number(to1) >= Number(from2) && Number(from1) <= Number(to2);
    }

    /**
     * Gibt die Anzahl der Tage seit Stunde 0 zurück
     * @param date
     */
    getTimestampInDaysOrNull(date: any): number | null {
        return date ? Number(Math.floor(new Date(date).getTime() / 60 / 60 / 24)) : null;
    }

    private checkIfCalcPersonsOverlap(additionalCostToCheck: ExtendedAdditionalCost, additionalCostToCheckAgainst: ExtendedAdditionalCost): boolean {
        return this.genericCheckIfOverlap(
            additionalCostToCheck.calcPersons?.min,
            additionalCostToCheck.calcPersons?.max,
            additionalCostToCheckAgainst.calcPersons?.min,
            additionalCostToCheckAgainst.calcPersons?.max,
        );
    }

    private checkIfCalcDaysOverlap(additionalCostToCheck: ExtendedAdditionalCost, additionalCostToCheckAgainst: ExtendedAdditionalCost): boolean {
        return this.genericCheckIfOverlap(
            additionalCostToCheck.calcDays?.min,
            additionalCostToCheck.calcDays?.max,
            additionalCostToCheckAgainst.calcDays?.min,
            additionalCostToCheckAgainst.calcDays?.max,
        );
    }

    private checkIfCalcAgesOverlap(additionalCostToCheck: ExtendedAdditionalCost, additionalCostToCheckAgainst: ExtendedAdditionalCost): boolean {
        return this.genericCheckIfOverlap(
            additionalCostToCheck.calcAges?.min,
            additionalCostToCheck.calcAges?.max,
            additionalCostToCheckAgainst.calcAges?.min,
            additionalCostToCheckAgainst.calcAges?.max,
        );
    }

    private checkIfOccupancyOverlap(additionalCostToCheck: ExtendedAdditionalCost, additionalCostToCheckAgainst: ExtendedAdditionalCost): boolean {
        return this.genericCheckIfOverlap(
            additionalCostToCheck.occupancy?.min,
            additionalCostToCheck.occupancy?.max,
            additionalCostToCheckAgainst.occupancy?.min,
            additionalCostToCheckAgainst.occupancy?.max,
        );
    }

    private checkIfDurationOverlap(additionalCostToCheck: ExtendedAdditionalCost, additionalCostToCheckAgainst: ExtendedAdditionalCost): boolean {
        return this.genericCheckIfOverlap(
            additionalCostToCheck.duration?.min,
            additionalCostToCheck.duration?.max,
            additionalCostToCheckAgainst.duration?.min,
            additionalCostToCheckAgainst.duration?.max,
        );
    }

    private checkIfTravelPeriodOverlap(additionalCostToCheck: ExtendedAdditionalCost, additionalCostToCheckAgainst: ExtendedAdditionalCost): boolean {
        if (additionalCostToCheck.travelPeriod?.from && additionalCostToCheckAgainst.travelPeriod?.from) {
            const travelPeriodService = new TravelPeriodService();
            const actualPeriod = new TravelPeriodDto();
            actualPeriod.from = additionalCostToCheck.travelPeriod?.from;
            actualPeriod.to = additionalCostToCheck.travelPeriod?.to;
            actualPeriod.followingYears = additionalCostToCheck.travelPeriod?.followingYears;

            const periodToCheck = new TravelPeriodDto();
            periodToCheck.from = additionalCostToCheckAgainst.travelPeriod?.from;
            periodToCheck.to = additionalCostToCheckAgainst.travelPeriod?.to;
            periodToCheck.followingYears = additionalCostToCheckAgainst.travelPeriod?.followingYears;

            const result = travelPeriodService.getPeriodOverlap(actualPeriod, periodToCheck);
            if (result?.from) {
                return true;
            }
        }
        if (!additionalCostToCheck.travelPeriod?.from && !additionalCostToCheckAgainst.travelPeriod?.from) {
            return true;
        }
        return false;
    }

    private isOverlapping(): boolean {
        const additionalCostToCheck = this.additionalCost;

        for (const additionalCostToCheckAgainst of this.accommodationOrTemplate.additionalCosts) {
            if (additionalCostToCheckAgainst.additionalCost === additionalCostToCheck.additionalCost && additionalCostToCheckAgainst !== additionalCostToCheck) {
                const extendedCheck =
                    this.checkIfCalcDaysOverlap(additionalCostToCheck, additionalCostToCheckAgainst) && this.checkIfCalcPersonsOverlap(additionalCostToCheck, additionalCostToCheckAgainst);

                if (
                    extendedCheck &&
                    this.checkIfDurationOverlap(additionalCostToCheck, additionalCostToCheckAgainst) &&
                    this.checkIfOccupancyOverlap(additionalCostToCheck, additionalCostToCheckAgainst) &&
                    this.checkIfCalcAgesOverlap(additionalCostToCheck, additionalCostToCheckAgainst) &&
                    this.checkIfTravelPeriodOverlap(additionalCostToCheck, additionalCostToCheckAgainst) &&
                    this.refuseDoubleType(additionalCostToCheck, additionalCostToCheckAgainst)
                ) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Kontrolliert das Nebeneinander-Existieren eines gleichen Nebenleistungstyps
     * mit unterschiedlichen Werten (unabhängig von optionalen Einschränkungen)
     *
     * Gibt FALSE zurück, falls der gleiche Typ zweimal existieren darf
     *
     * Beispiel 1:                                      Beispiel 2:
     * Abendessen optional 20€ pro Tag                  Bettwäschenwechsel inklusive pro Woche
     * Abendessen optional 50€ pro Woche                Bettwäschenwechsel optional 5€ pro Benutzung
     *
     * @return bool
     */
    private refuseDoubleType($additionalCost: ExtendedAdditionalCost, $compareAdditionalCost: ExtendedAdditionalCost): boolean {
        const unit = $additionalCost.unit;
        const type = $additionalCost.additionalCost;
        const obligation = Number($additionalCost.obligation);
        const interval = $additionalCost.interval;

        const currentInterval = $compareAdditionalCost.interval;
        const currentUnit = $compareAdditionalCost.unit;
        const currentObligation = Number($compareAdditionalCost.obligation);

        // Wenn pro Tag/pro Woche/nach Verbrauch (nur Stunde?), dann muss es (optional + optional)
        //   ODER (verpflichtend + verpflichtend)
        //   ODER (wenn Haustier + wenn Haustier) sein. alles andere nicht erlaubt.
        if (
            (['ta', 'wo'].includes(interval) || (interval === 'nv' && unit === 'STUNDE')) &&
            (['ta', 'wo'].includes(currentInterval) || (currentInterval === 'nv' && currentUnit === 'STUNDE')) &&
            [ObligationTypes.OPTIONAL, ObligationTypes.MANDATORY, ObligationTypes.MANDATORY_WITH_PETS].includes(obligation) &&
            obligation === currentObligation &&
            interval !== currentInterval
        ) {
            return false;
        }

        // Wenn eines von BETTREIN, SCHBADREIN, ZWISCHREIN,
        // dann muss es (pro Tag ODER pro Benutzung) optional + pro Woche inklusive sein.
        if (
            ['BETTREIN', 'SCHBADREIN', 'ZWISCHREIN'].includes(type) &&
            (((interval === 'ta' || (interval === 'nv' && ['NUTZUNG', 'BETT', 'STUNDE'].includes(unit))) &&
                obligation === ObligationTypes.OPTIONAL &&
                currentInterval === 'wo' &&
                currentObligation === ObligationTypes.INCLUSIVE) ||
                ((currentInterval === 'ta' || (currentInterval === 'nv' && ['NUTZUNG', 'BETT', 'STUNDE'].includes(unit))) &&
                    currentObligation === ObligationTypes.OPTIONAL &&
                    interval === 'wo' &&
                    obligation === ObligationTypes.INCLUSIVE))
        ) {
            return false;
        }

        // Wenn eines von HANDTUCHWE, WECHSEL, dann muss es (pro Wechsel ODER pro Person/Wechsel) optional + pro Woche inklusive sein
        if (
            ['HANDTUCHWE', 'WECHSEL', 'BETOWECHSL'].includes(type) &&
            ((interval === 'nv' &&
                ['WECHSEL', 'PERSWECHSL', 'STUECK'].includes(unit) &&
                obligation === ObligationTypes.OPTIONAL &&
                currentInterval === 'wo' &&
                currentObligation === ObligationTypes.INCLUSIVE) ||
                (currentInterval === 'nv' &&
                    ['WECHSEL', 'PERSWECHSL', 'STUECK'].includes(currentUnit) &&
                    currentObligation === ObligationTypes.OPTIONAL &&
                    interval === 'wo' &&
                    obligation === ObligationTypes.INCLUSIVE))
        ) {
            return false;
        }

        return true;
    }

    private getCloneWithEmptyValuesRemoved(): ExtendedAccommodationEntity | ExtendedAdditionalCostTemplateEntity {
        const clone = cloneDeep(this.accommodationOrTemplate);

        if (clone.additionalCosts?.length) {
            for (const additionalCost in clone.additionalCosts) {
                if (clone.additionalCosts[additionalCost]) {
                    for (const key of Object.keys(clone.additionalCosts[additionalCost])) {
                        if (
                            (clone.additionalCosts[additionalCost][key] === undefined ||
                                clone.additionalCosts[additionalCost][key] === '' ||
                                clone.additionalCosts[additionalCost][key] === ObligationTypes.OPTIONAL ||
                                clone.additionalCosts[additionalCost][key] === '0' ||
                                clone.additionalCosts[additionalCost][key] === null) &&
                            key !== 'obligation'
                        ) {
                            // Obligation darf als einziges Feld 0 enthalten
                            delete clone.additionalCosts[additionalCost][key];
                        } else if (
                            clone.additionalCosts[additionalCost][key] instanceof MinMaxDto &&
                            !clone.additionalCosts[additionalCost][key].min &&
                            !clone.additionalCosts[additionalCost][key].max
                        ) {
                            delete clone.additionalCosts[additionalCost][key];
                        } else if (
                            clone.additionalCosts[additionalCost][key] instanceof FromToDateDto &&
                            !clone.additionalCosts[additionalCost][key].from &&
                            !clone.additionalCosts[additionalCost][key].to
                        ) {
                            delete clone.additionalCosts[additionalCost][key];
                        }
                    }
                }
            }
        }

        return clone;
    }

    noticeIfOrigin() {
        const isChecked = this.createForm.get('hasToBePaidLocal').value;
        const isDisabled = this.createForm.get('hasToBePaidLocal').disabled;

        if (this.editableDepositBecauseEmployee) {
            return;
        }
        if (isChecked && !isDisabled && this.authenticationService.currentUser.hasClient() && this.additionalCost.additionalCost !== 'KURTAXE') {
            this.confirmationDialogService
                .confirm('text.pdf.importantnotice', 'atraveo.accommodationbundle.additionalcost.remove.onlocation', 'button.ok', this.confirmationDialogService.DEFAULT_BUTTON_CANCEL_TEXT, 'lg')
                .then(async (confirmed) => {
                    this.createForm.get('hasToBePaidLocal').setValue(!confirmed);
                })
                .catch(() => undefined);
            return false;
        }
    }

    debug() {
        console.log(this.usedAdditionalCost);
        console.log('Formular ist nicht valide', this.createForm.errors);
        // console log of all errors of all controls
        for (const controlName in this.createForm.controls) {
            if (this.createForm.controls.hasOwnProperty(controlName)) {
                const control = this.createForm.get(controlName);
                if (control.errors) {
                    console.log(controlName + ' nicht valide', control.errors);
                }
            }
        }
    }
}
