<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">{{ data.title | translate }}</h4>
        <button type="button" class="close" aria-label="Close" [mat-dialog-close]="false">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{ data.message | translate }}
    </div>
    <div class="modal-footer">
        <button class="btn btn-modal-footer btn-secondary" type="button"  #acceptButton  [mat-dialog-close]="true">
            {{ data.btnOkText | translate }}
        </button>
        <button class=" btn btn-modal-footer btn-danger" type="button" #cancelButton [mat-dialog-close]="false">
            {{ data.btnCancelText | translate }}
        </button>
    </div>
</div>