import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ApiConnectorService } from '../../../../services/api-connector/api-connector.service';
import { ExtendedAccommodationEntity } from '../../../../entities/extendedAccommodationEntity';
import { Type } from 'class-transformer';
import { NotificationService } from '../../../../services/notification/notification.service';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentsEnum } from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-request.dto';
import { AccommodationUpdateService } from '../../../../services/accommodation-update.service';
import { FindAccommodationGroupRequestDto, GroupComponentsEnum } from 'data-structures/lib/es6/dto/accommodation-group/find-accommodation-group/find-accommodation-group-request.dto';
import { FindAccommodationOrderByDto } from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-order-by.dto';
import { ConfirmationDialogService } from '../../../global/confirmation-dialog/confirmation-dialog.service';
import { AccommodationQualifyService } from '../../../../services/accommodation/accommodation-qualify.service';
import * as Sentry from '@sentry/angular-ivy';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../../services/dialog.service';
import { PropertiesFilterDialogComponent } from '../../../global/dialogs/properties-filter-dialog/properties-filter-dialog.component';
import { PauseAccommodationConfirmationDialogComponent } from '../../../global/dialogs/pause-accommodation-confirmation-dialog/pause-accommodation-confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-accommodations',
    templateUrl: './accommodations.component.html',
    styleUrls: ['./accommodations.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AccommodationsComponent implements OnInit, OnDestroy {
    @Type(() => ExtendedAccommodationEntity)
    @ViewChild('copyModal')
    copyModal: TemplateRef<any>;
    accommodations: ExtendedAccommodationEntity[];

    groupedAccommodations: object = {};

    filter: {
        skip: number;
        take: number;
        state: {};
    };

    descriptionTexts: {};
    accommodationCount: number;
    currentPage: number = 1;
    stateByParam: string;
    copyAccommodationNumber: number;
    isFilterBtnEvent: boolean = false;
    accommodationsCanBeQualified: { [key: number]: boolean } = {};
    mobileAccommodationOverviewLink: string = '';
    translationServiceSubscription: Subscription;
    translationChangeServiceSubscription: Subscription;
    breakpointSubscription: Subscription;
    accommodationUpdateServiceSubscription: Subscription;

    constructor(
        readonly apiConnectorService: ApiConnectorService,
        readonly notificationService: NotificationService,
        readonly authenticationService: AuthenticationService,
        readonly translationService: TranslateService,
        readonly router: Router,
        readonly activatedRoute: ActivatedRoute,
        readonly accommodationUpdateService: AccommodationUpdateService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly accommodationQualifyService: AccommodationQualifyService,
        readonly dialogService: DialogService,
        public dialog: MatDialog,
    ) {}

    async ngOnInit() {
        this.translationChangeServiceSubscription = this.translationService.onLangChange.subscribe(() => {
            this.filterAccommodations();
        });
        try {
            this.filter = { skip: 0, take: 10, state: {} };
            this.descriptionTexts = {};
            this.activatedRoute.params.subscribe((params) => {
                if (params.state === 'active') {
                    this.filter.state = { active: true };
                    this.stateByParam = params.state;
                }
            });

            await this.filterAccommodations();
            await this.generateAccommodationData();

            this.accommodationUpdateServiceSubscription = this.accommodationUpdateService.getNewAccommodationMessage().subscribe(async () => {
                await this.filterAccommodations();
                await this.generateAccommodationData();
            });
        } catch (error) {
            Sentry.captureException(error);
            await this.notificationService.add('error.message.couldNotLoad', 'danger');
        }
    }

    async ngOnDestroy() {
        this.translationServiceSubscription?.unsubscribe();
        this.breakpointSubscription?.unsubscribe();
        this.accommodationUpdateServiceSubscription?.unsubscribe();
        this.translationChangeServiceSubscription?.unsubscribe();
    }

    async onSearchForCopyAccommodation(value: number) {
        this.copyAccommodationNumber = value;
    }

    async copyAccommodation() {
        await this.apiConnectorService.copyAccommodation(this.authenticationService.currentUser.ownerNumber, this.copyAccommodationNumber);
        await this.filterAccommodations();
        this.closeMatDialog();
    }

    async getShortDescription(accommodation: ExtendedAccommodationEntity) {
        let translation = '';
        const positionTranslations: string[] = [];
        const roomTexts = [];
        const bathRooms = accommodation.getFirstFeatureValue('bz');
        const bedRooms = accommodation.getFirstFeatureValue('sz');

        if (accommodation.main?.type >= 0) {
            const type = accommodation.main.type;
            translation = await this.translationService.get('atraveo.accommodationbundle.features.import.hausart.' + type).toPromise();
            translation +=
                ' ' + (await this.getSingleOrPluralText('atraveo.accommodationbundle.overview.description.maxperson', accommodation.main.maxPersons, { maxperson: accommodation.main.maxPersons }));
        }

        const city = this.getPositionTranslation(accommodation.shortGeoName?.city);
        const region = this.getPositionTranslation(accommodation.shortGeoName?.mainRegion);

        if (city) {
            positionTranslations.push(city);
        }

        if (region) {
            positionTranslations.push(region);
        }

        let text = '';

        if (positionTranslations.length) {
            text = await this.translationService.get('atraveo.accommodationbundle.overview.description.position').toPromise();
            text = text.replace('{{position}}', positionTranslations.join(', '));
        }

        translation += ' ' + text;
        translation = translation.trim();

        if (accommodation.main && accommodation.main.livingSpace) {
            roomTexts.push(await this.getSingleOrPluralText('atraveo.accommodationbundle.overview.description.whnflaeche', 1, { whnflaeche: accommodation.main.livingSpace }));
        }

        if (bathRooms !== undefined) {
            roomTexts.push(await this.getSingleOrPluralText('atraveo.accommodationbundle.overview.description.bz', bathRooms, { bz: bathRooms }));
        }

        if (bedRooms !== undefined) {
            roomTexts.push(await this.getSingleOrPluralText('atraveo.accommodationbundle.overview.description.sz', bedRooms, { sz: bedRooms }));
        }

        return translation + '<br>' + roomTexts.join(', ');
    }

    getPositionTranslation(source): string {
        if (source?.hasOwnProperty(this.translationService.currentLang)) {
            return source[this.translationService.currentLang];
        }

        if (source?.hasOwnProperty('en')) {
            return source.en;
        }

        if (source?.hasOwnProperty('de')) {
            return source.de;
        }

        return '';
    }

    async getSingleOrPluralText(key: string, amount: number, params?: object): Promise<string> {
        const translation = await this.translationService.get(key, params).toPromise();
        const translations = translation.split('|');

        if (Number(amount) === 1) {
            return translations[0];
        } else if (Number(amount) > 1) {
            return translations[1];
        }

        return '';
    }

    async changePage(page: number) {
        this.filter.skip = this.filter.take * (page - 1);
        if (this.filter.skip < 0) {
            this.filter.skip = 0;
        }
        this.currentPage = page;

        await this.filterAccommodations(this.isFilterBtnEvent).then(() => {
            window.scrollTo({
                top: 0,
            });
        });
    }

    async generateAccommodationData() {
        for (const accommodation of this.accommodations) {
            this.descriptionTexts[accommodation.accommodationId] = await this.getShortDescription(accommodation);
        }
    }

    resetPage() {
        this.currentPage = 1;
        this.filter.skip = 0;
    }

    async filterAccommodations(isFilterBtnEvent: boolean = false) {
        this.isFilterBtnEvent = isFilterBtnEvent;
        this.accommodations = [];
        this.groupedAccommodations = {};

        const components = [
            ComponentsEnum.AccommodationId,
            ComponentsEnum.OwnerNumber,
            ComponentsEnum.Media,
            ComponentsEnum.CheckResults,
            ComponentsEnum.Features,
            ComponentsEnum.State,
            ComponentsEnum.Main,
            ComponentsEnum.DescriptionLanguages,
            ComponentsEnum.RegionTextShort,
            ComponentsEnum.AccommodationGroupId,
            ComponentsEnum.Channels,
            ComponentsEnum.Vacancy,
        ];

        const result: any = await this.apiConnectorService.getAccommodationsByOwnerNumber(
            this.authenticationService.currentUser,
            Number(this.filter.skip),
            Number(this.filter.take),
            this.filter.state,
            components,
            [
                {
                    component: ComponentsEnum.AccommodationGroupId,
                } as FindAccommodationOrderByDto,
                {
                    component: ComponentsEnum.AccommodationId,
                } as FindAccommodationOrderByDto,
            ],
        );

        if (result && result?.accommodations) {
            this.accommodations = result.accommodations;
            this.accommodationCount = result.count;

            await this.addAccommodationGroupName(result);

            this.groupedAccommodations = this.groupByAccommodationGroupId(this.accommodations);

            await this.generateAccommodationData();
            await this.setAccommodationsAreQualifyable();
            this.dialogService.closeDialog();
        }
    }

    private async addAccommodationGroupName(result: any) {
        const accommodationGroupIdsHelper = {};

        result.accommodations.map((accommodation) => {
            if (Number(accommodation.accommodationGroupId) > 0) {
                accommodationGroupIdsHelper[accommodation.accommodationGroupId] = true;
            }
        });

        const accommodationGroupIds = Object.keys(accommodationGroupIdsHelper).map((id) => Number(id));

        if (accommodationGroupIds.length) {
            const findAccommodationGroupRequestDto = new FindAccommodationGroupRequestDto();
            findAccommodationGroupRequestDto.ownerNumber = this.authenticationService.currentUser.ownerNumber;
            findAccommodationGroupRequestDto.components = [GroupComponentsEnum.AccommodationGroupId, GroupComponentsEnum.Name];
            findAccommodationGroupRequestDto.accommodationGroupId = accommodationGroupIds;
            const accommodationGroups: any = await this.apiConnectorService.findAccommodationGroups(findAccommodationGroupRequestDto);

            if (accommodationGroups.accommodationGroups.length) {
                result.accommodations.map(async (accommodation) => {
                    if (Number(accommodation.accommodationGroupId) > 0) {
                        const filteredAccommodationGroup = accommodationGroups.accommodationGroups.find(
                            (accommodationGroup) => accommodationGroup.accommodationGroupId === accommodation.accommodationGroupId,
                        );

                        if (filteredAccommodationGroup) {
                            accommodation.accommodationGroupName = filteredAccommodationGroup.name;
                            if (accommodation.accommodationGroupName === 'atraveo.accommodationbundle.group.new') {
                                accommodation.accommodationGroupName = await this.translationService.get('atraveo.accommodationbundle.group.new').toPromise();
                            }
                        }
                    }
                });
            }
        }
    }

    groupByAccommodationGroupId(accommodations: ExtendedAccommodationEntity[]) {
        const result = {};

        accommodations.map((accommodation) => {
            result[accommodation.accommodationGroupId] = result[accommodation.accommodationGroupId] ?? [];
            result[accommodation.accommodationGroupId].push(accommodation);
        });

        return result;
    }

    getAccommodationById(accommodationId: number): ExtendedAccommodationEntity {
        return this.accommodations.find((accommodation) => accommodation.accommodationId === accommodationId);
    }

    async toggleDeactivateByOwnerServerAndDeleted(accommodationToChange: ExtendedAccommodationEntity) {
        accommodationToChange.state.deactivatedByOwnerService = !accommodationToChange.state.deactivatedByOwnerService;
        if (accommodationToChange.state.deleted) {
            accommodationToChange.state.deleted = !accommodationToChange.state.deleted;
        }
        await this.apiConnectorService.saveAccommodation(accommodationToChange);
        await this.filterAccommodations();
    }

    async toggleAccommodationPaused(accommodation: ExtendedAccommodationEntity) {
        accommodation.state.paused = !accommodation.state.paused;
        await this.apiConnectorService.saveAccommodation(accommodation);
        await this.filterAccommodations();
    }

    setState(keyValue: string) {
        this.filter.state = {
            deleted: false,
        };

        if (!keyValue) {
            return;
        }

        let key1, value1, key2, value2;

        if (keyValue.split(':').length > 2) {
            [key1, value1, key2, value2] = keyValue.split(':');
            this.filter.state[key1] = value1 === 'true';
            this.filter.state[key2] = value2 === 'true';
        } else {
            [key1, value1] = keyValue.split(':');
            this.filter.state[key1] = value1 === 'true';
        }
    }

    openFilterModal() {
        this.dialogService.openDialog(
            PropertiesFilterDialogComponent,
            { dialogWidth: this.dialogService.dialogWidth.SM },
            {
                stateByParam: this.stateByParam,
                filter: this.filter,
                state: this.setState.bind(this),
                accommodationFilter: this.filterAccommodations.bind(this),
            },
        );
    }

    openQualifyAccommodationConfirmationDialog(accommodation: ExtendedAccommodationEntity) {
        this.confirmationDialogService
            .confirm('atraveo.accommodationbundle.qualify.confirm.title', 'atraveo.accommodationbundle.qualify.confirm.content')
            .then(async (confirmed) => {
                if (confirmed) {
                    await this.accommodationQualifyService.qualifyAccommodation(accommodation).then(async () => {
                        await this.filterAccommodations();
                    });
                }
            })
            .catch(() => undefined);
    }
    openCopyObjectData() {
        this.dialogService.openDialog(this.copyModal, { dialogWidth: this.dialogService.dialogWidth.SM, panelClass: 'overflow-visible' }, {});
    }
    closeMatDialog() {
        this.dialogService.closeDialog();
    }
    private async setAccommodationsAreQualifyable() {
        for (const accommodation of this.accommodations) {
            this.accommodationsCanBeQualified[accommodation.accommodationId] =
                !accommodation.state?.active &&
                accommodation.state?.valid &&
                !accommodation.state?.qualified &&
                accommodation.state?.publishingRequested &&
                this.authenticationService.currentUser?.isEmployee() &&
                !this.authenticationService.currentUser?.isImported() &&
                !this.authenticationService.currentUser?.state?.takeAllAccommodationsOffline;
        }
    }

    openPauseAccommodationConfirmationDialog(accommodation: ExtendedAccommodationEntity) {
        this.dialogService.openDialog(
            PauseAccommodationConfirmationDialogComponent,
            { dialogWidth: this.dialogService.dialogWidth.M },
            {
                selectedAccommodation: accommodation,
                pauseAccommodation: this.toggleAccommodationPaused.bind(this),
            },
        );
    }
}
