<div class="panel">
    <div class="panel-heading">
        <h2 class="panel-title">{{ 'atraveo.dashboardbundle.panel.heading.upcoming.arrivals.0' | translate }}</h2>
    </div>
    <div class="panel-body">
        <table class="table responsive-table">
            <thead>
            <tr>
                <th>{{ 'atraveo.dashboardbundle.panel.heading.upcoming.arrivals.arrival' | translate }}</th>
                <th>{{ 'atraveo.dashboardbundle.panel.heading.upcoming.arrivals.object' | translate }}</th>
                <th>{{ 'atraveo.dashboardbundle.panel.heading.upcoming.arrivals.customer' | translate }}</th>
            </tr>
            </thead>
            <tbody>
                <tr class="text-table">
                    <td *ngIf="nextTravels?.length === 0 || !nextTravels" colspan="3">
                        <span *ngIf="this.authenticationService.currentUser.isEmployee()" class="service">{{ 'atraveo.dashboardbundle.panel.upcoming.arrivals.norecords.servicemessage' | translate }}</span>
                        <span *ngIf="!this.authenticationService.currentUser.isEmployee()">{{ 'atraveo.dashboardbundle.panel.upcoming.arrivals.norecords.0' | translate }}</span>
                    </td>
                </tr>
                <ng-container *ngIf="nextTravels?.length > 0">
                    <tr *ngFor="let booking of this.nextTravels">
                        <td [attr.data-label]="'atraveo.dashboardbundle.panel.heading.upcoming.arrivals.arrival' | translate">
                            <a (click)="this.viewBooking = booking" (click)="openBookingViewDialog()" class="btn btn-link">
                                {{ booking.arrivalDate | stringToDate | date: 'shortDate':undefined:this.translationService.currentLang }}<br>
                            </a>
                            <br>{{ booking.lengthText }}
                        </td>
                        <td [attr.data-label]="'atraveo.dashboardbundle.panel.heading.upcoming.arrivals.object' | translate">
                            <a routerLink="/accommodation/{{ booking.accommodationId }}"
                               routerLinkActive="active"
                               class="btn btn-link">
                                {{ booking.accommodationId }}
                            </a>
                        </td>
                        <td [attr.data-label]="'atraveo.dashboardbundle.panel.heading.upcoming.arrivals.customer' | translate">{{ booking?.customer?.forename }} {{ booking?.customer?.surname }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
<div class="bookings">
    <a routerLink="bookings">
        <button type="button" class="btn btn-primary">{{ 'atraveo.dashboardbundle.panel.heading.upcoming.arrivals.bookingmask' | translate }}</button>
    </a>
</div>
