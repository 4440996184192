import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../../entities/extendedAccommodationEntity';
import { ExtendedAccommodationGroupEntity } from '../../../../../../entities/extendedAccommodationGroupEntity';
import { ApiConnectorService } from '../../../../../../services/api-connector/api-connector.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaDto } from 'data-structures/lib/es6/dto/accommodation';
import { environment } from '../../../../../../../environments/environment';
import { ConfirmationDialogService } from '../../../../../global/confirmation-dialog/confirmation-dialog.service';
import { AuthenticationService } from '../../../../../../services/authentication/authentication.service';
import { ImageConnectorService } from '../../../../../../services/api-connector/image-connector.service';
import { cloneDeep, isEqual } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { PendingChangesComponentService } from '../../../../../../services/pending-changes-component/pending-changes-component.service';
import { ComponentCanDeactivate } from '../../../../../../guards/pending-changes-guard';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    protected readonly history = history;
    @Input() viewType: 'accommodation' | 'accommodationGroup' = 'accommodation';
    accommodationOrGroup: ExtendedAccommodationEntity | ExtendedAccommodationGroupEntity;
    accommodationOrGroupOriginal: ExtendedAccommodationEntity & ExtendedAccommodationGroupEntity;
    image: MediaDto;
    order: number;
    allowedLanguages = environment.allowedLanguages;
    backLink: string[] = [];
    prevImageLink: string = '';
    nextImageLink: string = '';
    imageRotatedMedia: MediaDto[] = [];
    private saveAction: boolean = false;
    saveTriggerSubscription: Subscription;
    accommodationSubscription: Subscription;
    returnLinkAfterDelete: string = '';
    isAccommodation: boolean = false;

    constructor(
        readonly router: Router,
        readonly authenticationService: AuthenticationService,
        readonly apiConnector: ApiConnectorService,
        readonly route: ActivatedRoute,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly imageConnectorService: ImageConnectorService,
        readonly ngZone: NgZone,
        readonly pendingChangesComponentService: PendingChangesComponentService,
    ) {}

    async ngOnInit() {
        this.accommodationSubscription = this.apiConnector.activeAccommodation$.subscribe((accommodation) => {
            if (accommodation) {
                // nach dem löschen einen Bildes
                if (this.returnLinkAfterDelete !== '') {
                    this.router.navigate([this.returnLinkAfterDelete]);
                }
                this.accommodationOrGroup = cloneDeep(accommodation);
                this.isAccommodation = this.accommodationOrGroup instanceof ExtendedAccommodationEntity;

                if (this.accommodationOrGroup?.media) {
                    for (const media of this.accommodationOrGroup.media) {
                        for (const language of this.allowedLanguages) {
                            media.descriptions = media.descriptions ?? {};
                            if (!media.descriptions.hasOwnProperty(language)) {
                                media.descriptions[language] = null;
                            }
                        }
                    }
                }

                this.accommodationOrGroupOriginal = cloneDeep(this.accommodationOrGroup);
                this.route.params.subscribe(async () => {
                    this.createPrevNextImageLinks();
                });
                this.backLink = this.accommodationOrGroup.isGroup
                    ? ['accommodation-group', this.accommodationOrGroup.accommodationGroupId]
                    : ['accommodation', this.accommodationOrGroup['accommodationId'], 'images'];
                this.accommodationOrGroupOriginal = cloneDeep(this.accommodationOrGroup);

                const urlParts = this.route.snapshot.url;
                if (urlParts?.length === 2 && urlParts[0].path === 'image') {
                    // @ts-ignore
                    this.viewType = urlParts[0].path;
                    this.order = Number(urlParts[1].path);
                    this.image = this.accommodationOrGroup.media.find((image) => {
                        return image.order === this.order;
                    });
                }

                this.backLink = this.accommodationOrGroup.isGroup
                    ? ['accommodation-groups', this.accommodationOrGroup.accommodationGroupId]
                    : ['accommodation', this.accommodationOrGroup['accommodationId'], 'images'];
            }
        });

        this.saveAction = false;

        if (this.imageRotatedMedia.length) {
            this.accommodationOrGroup.media = this.imageRotatedMedia;
            this.imageRotatedMedia = [];
        }

        this.saveTriggerSubscription = this.pendingChangesComponentService.getSaveTriggerObservable().subscribe(async () => {
            await this.saveAccommodation();
        });
    }
    createPrevNextImageLinks() {
        const urlParts = this.route.snapshot.url;
        let accommodationOrGroupId = this.accommodationOrGroup.accommodationGroupId;
        if (!(this.accommodationOrGroup instanceof ExtendedAccommodationGroupEntity)) {
            accommodationOrGroupId = this.accommodationOrGroup.accommodationId;
        }
        this.order = this.route.snapshot.params.order;
        if (urlParts?.length === 2 && urlParts[0].path === 'image') {
            this.order = Number(this.order);
            const urlPart = this.accommodationOrGroup.isGroup ? 'accommodation-group' : 'accommodation';
            if (this.order > 1) {
                this.prevImageLink = `/${urlPart}/${accommodationOrGroupId}/image/${this.order - 1}`;
            } else {
                this.prevImageLink = '';
            }
            if (this.order < this.accommodationOrGroup.media.length) {
                this.nextImageLink = `/${urlPart}/${accommodationOrGroupId}/image/${this.order + 1}`;
            } else {
                this.nextImageLink = '';
            }
            this.image = this.accommodationOrGroup.media.find((image) => {
                return image.order === this.order;
            });
        }
    }

    async ngOnDestroy() {
        const modalBackdrop = document.querySelector('.modal-backdrop');

        if (modalBackdrop) {
            modalBackdrop.remove();
        }

        this.saveTriggerSubscription?.unsubscribe();
        this.accommodationSubscription?.unsubscribe();
    }

    getUrlPart(): string {
        let urlPart: string = '';

        if (this.accommodationOrGroup instanceof ExtendedAccommodationEntity) {
            urlPart = 'accommodation?accommodationId=' + Number(this.accommodationOrGroup.accommodationId);
        }

        if (this.accommodationOrGroup instanceof ExtendedAccommodationGroupEntity) {
            urlPart = 'accommodation-group?accommodationGroupId=' + Number(this.accommodationOrGroup.accommodationGroupId);
        }

        if (!urlPart) {
            return;
        }

        urlPart += '&ownerNumber=' + Number(this.authenticationService.currentUser.ownerNumber);
        return urlPart;
    }

    openConfirmationDialog(imgIndex: number) {
        this.confirmationDialogService
            .confirm('confirm.title.delete', 'confirm.content.delete.0')
            .then(async (confirmed) => {
                if (confirmed) {
                    const urlPart = this.getUrlPart();
                    const mediaToDelete = this.accommodationOrGroup.media[imgIndex];

                    if (!urlPart || !mediaToDelete) {
                        return;
                    }

                    await this.apiConnector.deleteImage(urlPart, mediaToDelete);
                    this.returnLinkAfterDelete = '/' + this.accommodationOrGroup.getRouteForAccommodationOrGroup() + '/' + this.accommodationOrGroup.getIdForAccommodationOrGroup() + '/images/';
                }
            })
            .catch(() => undefined);
    }

    async rotateImage(index: number) {
        let type;
        let accommodationOrGroupId;

        const mediaToRotate = this.accommodationOrGroup.media[index];
        let fileName = mediaToRotate.displayUrl.substring(mediaToRotate.displayUrl.lastIndexOf('/') + 1);
        fileName = fileName.replace(/([?|&]v=\d+)/, '');

        if (this.accommodationOrGroup.isGroup) {
            type = 'accommodation-group';
            accommodationOrGroupId = this.accommodationOrGroup.accommodationGroupId;
        } else {
            type = 'accommodation';
            accommodationOrGroupId = this.accommodationOrGroup['accommodationId'];
        }
        const ownerNumber = this.authenticationService.currentUser.ownerNumber;

        mediaToRotate.angle = mediaToRotate.angle ?? 0;
        mediaToRotate.angle = (mediaToRotate.angle >= 270 ? -90 : mediaToRotate.angle) + 90;

        await this.imageConnectorService.rotateImageAndLoad(ownerNumber, type, accommodationOrGroupId, fileName, mediaToRotate.angle);
        await this.apiConnector.notifyImageIsRotated(this.accommodationOrGroup, ownerNumber, type, accommodationOrGroupId, mediaToRotate);
    }

    async saveAccommodation() {
        this.saveAction = true;

        const result = await this.apiConnector.saveAccommodationOrGroupForTab(this.route, this.accommodationOrGroup);
        this.pendingChangesComponentService.saveComplete(!!result);
    }

    async goBack() {
        await this.router.navigate(this.backLink, this.accommodationOrGroup.accommodationGroupId ? { fragment: 'images' } : {});
    }

    async setCoverPhoto(image: MediaDto) {
        const objectId = this.accommodationOrGroup instanceof ExtendedAccommodationEntity ? this.accommodationOrGroup?.accommodationId : this.accommodationOrGroup?.accommodationGroupId;
        [this.accommodationOrGroup.media[0], this.accommodationOrGroup.media[image.order - 1]] = [this.accommodationOrGroup.media[image.order - 1], this.accommodationOrGroup.media[0]];
        this.accommodationOrGroup.media[0].lastModified = new Date();
        this.accommodationOrGroup.media[image.order - 1].order = image.order;
        this.accommodationOrGroup.media[image.order - 1].lastModified = new Date();
        this.accommodationOrGroup.media[0].order = 1;
        await this.saveAccommodation();
        await this.router.navigate(['accommodation/' + objectId + '/image/1']);
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.saveAction) {
            this.saveAction = false;
            return true;
        }
        // @ts-ignore
        if (this.route?.snapshot?.url?.length >= 2 && this.route.snapshot.url[0].path === 'accommodation' && this.accommodationOrGroup?.state?.deleted) {
            return true;
        }
        return isEqual(this.accommodationOrGroup, this.accommodationOrGroupOriginal);
    }
}
