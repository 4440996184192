import { AfterContentChecked, Component, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../entities/extendedAccommodationEntity';
import { ExtendedAccommodationGroupEntity } from '../../../../../entities/extendedAccommodationGroupEntity';
import { MediaDto } from 'data-structures/lib/es6/dto/accommodation';
import { TranslateService } from '@ngx-translate/core';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { ImageTypeEnum } from 'data-structures/lib/es6/enum/image-type.enum';
import { MediaService } from '../../../../../services/media/media.service';
import { cloneDeep } from 'lodash';
import { ConfirmationDialogService } from '../../../../global/confirmation-dialog/confirmation-dialog.service';
import { Plugins, Sortable, SortableStopEvent } from '@shopify/draggable';
import { Subscription } from 'rxjs';
import { AccommodationHeaderComponent } from '../accommodation-header/accommodation-header.component';

@Component({
    selector: 'app-images',
    templateUrl: './images.component.html',
    styleUrls: ['./images.component.scss'],
})
export class ImagesComponent implements OnInit, OnChanges, OnDestroy, AfterContentChecked {
    accommodationOrGroup: ExtendedAccommodationEntity | ExtendedAccommodationGroupEntity;
    @Input() viewType: 'accommodation' | 'accommodationGroup' = 'accommodation';
    imagesPercent: number = null; // Wie viel Prozent der empfohlenen Anzahl an Bildern wurde hochgeladen
    imageCountSuggested = 15;
    progressBarClassName: string;
    headline: string = null;
    mainImage: any = null; // accommodation.media[0] geht irgendwie nicht im Template, deswegen so
    selectionStarted: boolean = false; // Bilderauswahl wurde gestartet, Ansicht ändert sich leicht
    selectedImagesDisplayUrl: string[] = [];
    deleteImageSubscription: any = null;
    dragStartDelay = 200;
    @ViewChild('gallery') galleryContainer;
    @ViewChild('mainImage') mainImageContainer;
    @ViewChild(AccommodationHeaderComponent) header: AccommodationHeaderComponent;
    sortable: Sortable;
    dragStartPositionX: number;
    dragStartPositionY: number;
    imageCount: number = 0;
    accommodationSubscription: Subscription;

    constructor(
        readonly translateService: TranslateService,
        readonly apiConnectorService: ApiConnectorService,
        readonly route: ActivatedRoute,
        readonly authService: AuthenticationService,
        readonly mediaService: MediaService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly router: Router,
    ) {}

    async ngOnInit() {
        this.accommodationSubscription = this.apiConnectorService.activeAccommodation$.subscribe((accommodation) => {
            this.accommodationOrGroup = cloneDeep(accommodation);
            this.ngOnChanges();
        });
    }

    async ngOnChanges() {
        if (this.accommodationOrGroup?.media?.length) {
            if (this.sortable) {
                this.sortable.destroy();
                this.sortable = null;
            }
            this.sortMedia();
            this.imagesPercent = (this.accommodationOrGroup.media.length * 100) / this.imageCountSuggested;
            if (this.imagesPercent > 100) {
                this.imagesPercent = 100;
            }

            if (this.accommodationOrGroup.media.length <= 4) {
                this.progressBarClassName = 'danger';
            } else if (this.accommodationOrGroup.media.length >= 5 && this.accommodationOrGroup.media.length <= 14) {
                this.progressBarClassName = 'progress';
            } else {
                this.progressBarClassName = 'success';
            }

            this.imageCount = this.accommodationOrGroup.media.length;
            this.mainImage = this.accommodationOrGroup.media[0];
        }
    }

    ngAfterContentChecked() {
        if (this.galleryContainer?.nativeElement?.children?.length && this.mainImageContainer?.nativeElement?.children?.length && !this.sortable) {
            this.sortable = new Sortable([this.galleryContainer.nativeElement, this.mainImageContainer.nativeElement], {
                draggable: 'div.element',
                delay: 'ontouchstart' in document.documentElement ? this.dragStartDelay : 0,
                sortAnimation: {
                    duration: 200,
                    easingFunction: 'ease-in-out',
                },
                plugins: [Plugins.SortAnimation],
            });

            this.sortable.on('sortable:start', (e) => {
                // Hauptbild soll erst mal nicht verschoben werden können, dafür gibt es dann ein extra Ticket
                if (this.selectionStarted || e.dragEvent?.sourceContainer.id === 'mainImage') {
                    e.cancel();
                }

                this.dragStartPositionX = e.dragEvent.sensorEvent.clientX;
                this.dragStartPositionY = e.dragEvent.sensorEvent.clientY;
            });

            this.sortable.on('sortable:sort', (e: any) => {
                if (e.dragEvent?.overContainer?.id === 'mainImage') {
                    // Die Breite des Containers ist komplett dynamisch, wir müssen hier die Größe abfragen und und als Style setzen
                    this.mainImageContainer.nativeElement.style.width = this.mainImageContainer.nativeElement.offsetWidth + 'px';
                    this.mainImageContainer.nativeElement.style.height = this.mainImageContainer.nativeElement.clientHeight + 'px';
                } else {
                    this.mainImageContainer.nativeElement.style.width = 'auto';
                    this.mainImageContainer.nativeElement.style.height = 'auto';
                }
            });

            this.sortable.on('sortable:stop', async (e) => {
                // Click auf ein Bild abfangen, weil es sonst kein Event gibt das man abfangen kann
                if (e.newIndex === e.oldIndex && (this.dragStartPositionX === e.dragEvent.sensorEvent.clientX || this.dragStartPositionY === e.dragEvent.sensorEvent.clientY)) {
                    const htmlElement = e.dragEvent.originalSource as HTMLElement;
                    const link = '/' + this.accommodationOrGroup.getRouteForAccommodationOrGroup() + '/' + this.accommodationOrGroup.getIdForAccommodationOrGroup() + '/image/' + htmlElement.id;

                    await this.router.navigate([link]);
                } else {
                    await this.drop(e);
                }
            });
        }
    }

    sortMedia() {
        this.accommodationOrGroup.media = this.accommodationOrGroup.media.sort((a: MediaDto, b: MediaDto): number => {
            return a.order - b.order;
        });
    }

    async addNewFilesFromButton($event: Event): Promise<void> {
        const target = $event.target as HTMLInputElement;
        if (target.files?.length) {
            return this.uploadNewFiles(target.files);
        }
    }

    async dropNewFiles($event: DragEvent): Promise<void> {
        $event.preventDefault();
        if ($event?.dataTransfer?.files?.length) {
            return this.uploadNewFiles($event.dataTransfer.files);
        }
    }

    async uploadNewFiles(files: FileList): Promise<void> {
        const urlPart = this.getUrlPart();
        if (!urlPart) {
            return;
        }
        for (const file of files) {
            if (!this.mediaService.isSuitable(file, ImageTypeEnum.Accommodation)) {
                continue;
            }

            const formData = new FormData();
            formData.append('file', file, file.name);
            await this.apiConnectorService.uploadData(urlPart, formData);
        }
        await this.ngOnChanges();
    }

    dragNewFile($event: DragEvent) {
        $event.preventDefault();
    }

    getUrlPart(): string {
        let urlPart: string = '';

        if (this.accommodationOrGroup instanceof ExtendedAccommodationEntity) {
            urlPart = 'accommodation?accommodationId=' + Number(this.accommodationOrGroup.accommodationId);
        }

        if (this.accommodationOrGroup instanceof ExtendedAccommodationGroupEntity) {
            urlPart = 'accommodation-group?accommodationGroupId=' + Number(this.accommodationOrGroup.accommodationGroupId);
        }

        if (!urlPart) {
            return;
        }

        urlPart += '&ownerNumber=' + Number(this.authService.currentUser.ownerNumber);
        return urlPart;
    }

    selectOrUnselectImage(displayUrl: string) {
        if (!this.selectionStarted) {
            return;
        }

        if (!this.selectedImagesDisplayUrl.includes(displayUrl)) {
            this.selectedImagesDisplayUrl.push(displayUrl);
        } else {
            this.selectedImagesDisplayUrl = this.selectedImagesDisplayUrl.filter((element) => element !== displayUrl);
        }
    }

    selectAll() {
        this.selectedImagesDisplayUrl = [];
        for (const media of this.accommodationOrGroup.media) {
            this.selectedImagesDisplayUrl.push(media.displayUrl);
        }
    }

    deselectAll() {
        this.selectedImagesDisplayUrl = [];
    }

    async deleteImages() {
        const deleteImages = cloneDeep(this.selectedImagesDisplayUrl);
        this.selectedImagesDisplayUrl = [];
        let finalMedia: MediaDto[];

        for (const deleteDisplayUrl of deleteImages) {
            finalMedia = await this.deleteImage(deleteDisplayUrl);
        }

        this.accommodationOrGroup.media = finalMedia;

        if (this.accommodationOrGroup.media.length === 0) {
            this.stopSelection();
        }
    }

    // Achtung: Löscht das Bild nur, speichert aber danach nicht die Entity!
    async deleteImage(displayUrl: string): Promise<MediaDto[]> {
        const urlPart = this.getUrlPart();
        const mediaToDelete = this.accommodationOrGroup.media.find((media) => media.displayUrl === displayUrl);

        if (!urlPart || !mediaToDelete) {
            return;
        }

        return this.apiConnectorService.deleteImage(urlPart, mediaToDelete);
    }

    openConfirmationDialog() {
        this.confirmationDialogService
            .confirm('confirm.title.delete', 'confirm.content.delete.0')
            .then(async (confirmed) => {
                if (confirmed) {
                    await this.deleteImages();
                }
            })
            .catch(() => undefined);
    }

    async drop($event: SortableStopEvent) {
        if ($event.oldIndex === $event.newIndex && $event.oldContainer == $event.newContainer) {
            return;
        }

        const { oldIndex, newIndex } = this.getIndexFromEvent($event);

        this.arrayMove(this.accommodationOrGroup.media, oldIndex, newIndex);
        this.sortMedia();

        await this.apiConnectorService.saveAccommodationOrGroupForTab(this.route, this.accommodationOrGroup);
    }

    getIndexFromEvent($event: SortableStopEvent): { oldIndex: number; newIndex: number } {
        let oldIndex = $event.oldIndex;
        let newIndex = $event.newIndex;

        // index ist immer eins zu niedrig, weil das Hauptbild mitgezählt wird
        oldIndex++;
        newIndex++;

        if ($event.newContainer.id === 'mainImage') {
            // Es gibt nur ein Hauptbild, deswegen ist newIndex immer 0
            newIndex = 0;
        }

        return { oldIndex, newIndex };
    }

    async ngOnDestroy() {
        if (this.deleteImageSubscription) {
            if (!this.router.url.includes('/image/')) {
                this.deleteImageSubscription?.unsubscribe();
            }
        }

        if (this.sortable) {
            this.sortable.destroy();
        }
        this.accommodationSubscription?.unsubscribe();
    }

    contextMenu($event) {
        if ('ontouchstart' in document.documentElement) {
            // Verhindern, das Standard handy Menu aufgeht, wenn man auf einen Link/Bild gedrückt hält
            $event.preventDefault();
        }
    }

    arrayMove(array: MediaDto[], oldIndex, newIndex) {
        const element = array[oldIndex];
        array.splice(oldIndex, 1);
        array.splice(newIndex, 0, element);

        array.forEach((media, i) => {
            const newOrder = i + 1; // Im JSON fängt die Order bei 1 an, im Array bei 0

            if (media.order !== newOrder) {
                media.lastModified = new Date();
            }

            media.order = i + 1;
        });
    }

    startSelection() {
        this.selectionStarted = true;
        this.selectedImagesDisplayUrl = [];
        if (this.isMobileOrTablet()) {
            history.pushState('select', null);
        }
    }

    stopSelection() {
        this.selectionStarted = false;
        this.selectedImagesDisplayUrl = [];
        if (this.isMobileOrTablet()) {
            history.pushState('', null);
        }
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        if (this.selectionStarted && this.isMobileOrTablet()) {
            this.stopSelection();
        } else {
            history.back();
        }
    }

    // Back Button soll, sich auf Desktop UNBEDINGT anders verhalten als auf Mobile oder Tablet
    isMobileOrTablet() {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }

    goBack() {
        // Nötig, weil wir unbedingt die Standardtaste vom Handy umbiegen mussten
        const link = '/' + this.accommodationOrGroup.getRouteForAccommodationOrGroup() + '/' + this.accommodationOrGroup.getIdForAccommodationOrGroup();
        return this.router.navigate([link]);
    }
}
