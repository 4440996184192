<div class="modal-content">
    <div class="filter-modal-header">
        <button class="close" (click)="closeDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <form [formGroup]="filterForm" (ngSubmit)="loadData()">
                <div class="form">
                    <div class="row">
                        <div class="col">
                            <strong>{{ 'atraveo.bookingbundle.filter.label' | translate }}</strong>:
                        </div>
                        <div class="col align-right">
                            {{ 'text.filter.elements.per.page' | translate }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group mt-2 d-flex">
                            <input id="showAllBookings" type="radio" name="channelString" class="mr-2"
                                   value="all"
                                   formControlName="channelString">
                            <label for="showAllBookings">{{ 'text.filter.all.bookings' | translate }}</label>
                        </div>
                        <div class="col -align-right form-group">
                            <select class="form-control" name="elementsPerPage"
                                    formControlName="take">
                                <option value="1">1</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group d-flex">
                            <input id="showAtraveoBookings" type="radio" class="mr-2"
                                   name="channelString" value="AT"
                                   formControlName="channelString">
                            <label for="showAtraveoBookings">{{ 'atraveo.bookingbundle.choice.filter.filter_only_atraveo_bookings' | translate }}</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group d-flex">
                            <input id="showOwnBookings" type="radio" class="mr-2"
                                   name="channelString" value="VM"
                                   formControlName="channelString">
                            <label for="showOwnBookings">{{ 'atraveo.bookingbundle.choice.filter.filter_only_own_bookings' | translate }}</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <strong>{{ 'atraveo.bookingbundle.datefilter.label' | translate }}</strong>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            {{ 'text.date.start' | translate }}
                        </div>
                        <div class="col">
                            {{ 'text.date.end' | translate }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <input type="date" class="form-control" formControlName="arrivalDate">
                        </div>
                        <div class="col">
                            <input type="date" class="form-control" formControlName="departureDate">
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <strong><label
                                for="filterObjectnumber">{{ 'text.filter.accommodation.number' | translate }}</label></strong>
                        </div>
                        <div class="col">
                            <app-accommodation-ajax-search
                                (onSearch)="addAccommodation($event)"
                                hideLabel="true"
                                hideIcons="true"
                                classes="form-control d-inline typeahead"
                                id="filterObjectnumber"
                                htmlId="search">
                            </app-accommodation-ajax-search>
                            <p *ngIf="accommodations.length > 0" class="mt-2">
                                <span *ngFor="let accommodationID of accommodations; let i = index" class="badge badge-primary badge-accommodation mr-2">
                                    {{ accommodationID }}
                                    <button
                                        type="button"
                                        class="close ml-1"
                                        aria-label="Close"
                                        (click)="removeAccommodation(accommodationID)">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </span>
                            </p>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <strong><label
                                for="filterBookingNumber">{{ 'text.filter.booking.number' | translate }}</label></strong>
                        </div>
                        <div class="col">
                            <input id="filterBookingNumber" class="form-control"
                                   formControlName="bookingNumber">
                        </div>
                    </div>
                </div>
                <div class="modal-footer mt-3">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"
                            (click)="resetData()">{{ 'button.reset.filter' | translate }}</button>
                    <button class="btn btn-success" data-dismiss="modal" type="submit">{{ 'button.filter' | translate }}</button>
                </div>
            </form>
        </div>
    </div>
</div>