import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup } from '@angular/forms';
import { ApiConnectorService } from '../../../../../../services/api-connector/api-connector.service';
import { FeatureDefinitionDto } from 'data-structures/dto/accommodation/feature/feature-definition.dto';
import { AuthenticationService } from '../../../../../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-feature-input',
    templateUrl: './feature-input.component.html',
    styleUrls: ['./feature-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FeatureInputComponent),
            multi: true,
        },
    ],
})
export class FeatureInputComponent implements ControlValueAccessor {
    @Input() form: FormGroup;
    @Input() formControlName: string;
    @Input() featureId: string;
    @Input() type: string = 'number';

    isRequired: boolean = false;
    featureDefinitions: { [key: string]: FeatureDefinitionDto } = {};
    featureDefinition: FeatureDefinitionDto;
    currentLanguage: string = '';

    private _value: any;

    constructor(readonly apiConnectorService: ApiConnectorService, readonly authenticationService: AuthenticationService, readonly translationService: TranslateService) {}

    async ngOnInit() {
        this.currentLanguage = this.translationService.currentLang;
        this.featureDefinitions = await this.apiConnectorService.getFeatureDefinitionsWithoutGroups();
        this.featureDefinition = this.featureDefinitions[this.featureId];
        this.isRequired = this.form.controls[this.formControlName].validator?.({} as any)?.required ?? false;
    }

    get value(): any {
        return this._value;
    }

    set value(val: any) {
        this._value = val;
        this.onChange(val);
        this.onTouched();
    }

    // ControlValueAccessor methods
    writeValue(value: any): void {
        this._value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        // Optionally implement this if you want to control the disabled state of your component
    }

    onChange: any = () => {};
    onTouched: any = () => {};
}
