import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SeasonsDto } from 'data-structures/lib/es6/dto/season/put-season/seasons.dto';
import { ApiConnectorService } from '../../../../../../../services/api-connector/api-connector.service';
import { TranslateService } from '@ngx-translate/core';
import { OwnerCurrencyEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { AuthenticationService } from '../../../../../../../services/authentication/authentication.service';
import { cloneDeep } from 'lodash';
import { TravelPeriodDto } from 'data-structures/lib/es6/dto/accommodation/price-rule/travel-period.dto';
import { ExtendedDailyPriceRule } from '../../../../../../../entities/PriceRule/extended-daily-price-rule';
import { ExtendedAccommodationEntity } from '../../../../../../../entities/extendedAccommodationEntity';
import { PriceInformationExceptionDto } from 'data-structures/lib/es6/dto/accommodation/price-information-exception.dto';
import { addDays, addYears, format, subDays, subYears } from 'date-fns';
import { Subscription } from 'rxjs';
import { TravelPeriodService } from '../../../../../../../services/travel-period/travel-period.service';

@Component({
    selector: 'app-edit-daily-price',
    templateUrl: './edit.component.html',
    styleUrl: './edit.component.scss',
})
export class EditComponent implements OnInit, OnChanges, OnDestroy {
    @Input() accommodation: ExtendedAccommodationEntity;
    @Input() price: ExtendedDailyPriceRule;
    @Output() onSave: EventEmitter<ExtendedDailyPriceRule> = new EventEmitter<ExtendedDailyPriceRule>();
    @Input() modalID: string; // welche modalID hat es geöffnet
    usedPrice: ExtendedDailyPriceRule;
    priceForm: FormGroup;
    seasons: SeasonsDto = new SeasonsDto();
    seasonsOriginals: SeasonsDto = new SeasonsDto();
    seasonsAliases: any[] = [];
    maxAllowedPersons: number; // this.accommodation.main.maxPersons
    minDateFrom: string;
    maxDateFrom: string;
    minDateTo: string;
    maxDateTo: string;
    ownerCurrency: OwnerCurrencyEnum = this.authService.currentUser.getOwnerCurrency();
    showWeekendPrice: boolean = false;
    showWeekendPriceToggleDescription: string = 'open';
    priceInformations: PriceInformationExceptionDto[] = [];
    step1Completed: boolean = false;
    step2Completed: boolean = false;
    showSeasons: boolean = false;
    highlightTravelPeriodFrom: boolean = false;
    highlightTravelPeriodTo: boolean = false;
    seasonChanged: boolean = false;

    priceTypeSubscription: Subscription;
    periodFromSubscription: Subscription;
    periodToSubscription: Subscription;
    seasonIdSubscription: Subscription;
    priceSubscription: Subscription;

    constructor(readonly apiConnectorService: ApiConnectorService, readonly translationService: TranslateService, readonly authService: AuthenticationService) {}

    async ngOnInit() {
        const minDate = subYears(new Date(), 10);
        const maxDate = addYears(new Date(), 20);

        this.minDateFrom = format(minDate, 'yyyy-MM-dd');
        this.maxDateFrom = format(maxDate, 'yyyy-MM-dd');

        this.minDateTo = format(minDate, 'yyyy-MM-dd');
        this.maxDateTo = format(maxDate, 'yyyy-MM-dd');

        this.priceForm = new FormGroup(
            {
                travelPeriodFrom: new FormControl('', [Validators.required, this.dateBetweenMinMaxValidator(new Date(this.minDateFrom), new Date(this.maxDateFrom))]),
                travelPeriodTo: new FormControl('', [Validators.required, this.dateBetweenMinMaxValidator(new Date(this.minDateFrom), new Date(this.maxDateFrom))]),
                followingYears: new FormControl(''),
                seasonId: new FormControl(''),
                price: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100000)]),
                priceWeekend: new FormControl('', [Validators.min(0), Validators.max(100000)]),
                priceType: new FormControl('booking', Validators.required),
                maxPersons: new FormControl(''),
                pricePerAdditionalPerson: new FormControl(''),
            },
            { validators: [this.overlapCheckValidator(), this.dateOrderValidator(), this.dateRangeToLongValidator()] },
        );

        this.priceTypeSubscription = this.priceForm.get('priceType').valueChanges.subscribe((value) => {
            if (value === 'person') {
                this.priceForm.get('maxPersons').setValidators([Validators.required, Validators.min(1)]);
                this.priceForm.get('pricePerAdditionalPerson').setValidators([Validators.required, Validators.min(1), Validators.max(100000)]);
            } else {
                this.priceForm.get('maxPersons').clearValidators();
                this.priceForm.get('pricePerAdditionalPerson').clearValidators();
            }
            this.priceForm.get('maxPersons').updateValueAndValidity();
            this.priceForm.get('pricePerAdditionalPerson').updateValueAndValidity();
        });

        this.periodFromSubscription = this.priceForm.get('travelPeriodFrom').valueChanges.subscribe(() => {
            if (this.priceForm.get('travelPeriodFrom').value) {
                // setze das maxDateTo auf das ausgewählte Datum + 1 Tag
                this.minDateTo = format(addDays(new Date(this.priceForm.get('travelPeriodFrom').value), 1), 'yyyy-MM-dd');
                this.emptySeasonIfNotCorrectDates();
            }
            this.getArrivalInformation();
        });

        this.periodToSubscription = this.priceForm.get('travelPeriodTo').valueChanges.subscribe(() => {
            // setze das maxDateTo auf das ausgewählte Datum - 1 Tag
            if (this.priceForm.get('travelPeriodTo').value) {
                this.maxDateFrom = format(subDays(new Date(this.priceForm.get('travelPeriodTo').value), 1), 'yyyy-MM-dd');
                this.emptySeasonIfNotCorrectDates();
            }
            this.getArrivalInformation();
        });

        // saison wechsel
        this.seasonIdSubscription = this.priceForm.get('seasonId').valueChanges.subscribe((value) => {
            if (value) {
                // hole den Start und Ende von der ausgewählten Saison
                const season = this.seasons.seasons.find((s) => s.seasonId === Number(value));
                if (season) {
                    this.seasonChanged = true;
                    this.priceForm.patchValue({
                        travelPeriodFrom: season.travelPeriods[0].from,
                        travelPeriodTo: season.travelPeriods[0].to,
                    });
                    this.seasonChanged = false;
                    this.highlightTravelPeriodFrom = true;
                    this.highlightTravelPeriodTo = true;
                } else {
                    this.priceForm.patchValue({
                        travelPeriodFrom: '',
                        travelPeriodTo: '',
                    });
                }
            }
        });

        this.priceSubscription = this.priceForm.get('price').valueChanges.subscribe((value) => {
            this.step2Completed = !!(value && this.maxAllowedPersons);
        });
    }

    emptySeasonIfNotCorrectDates() {
        if (!this.seasonChanged && this.priceForm.get('seasonId').value) {
            const season = this.seasons.seasons.find((s) => s.seasonId === Number(this.priceForm.get('seasonId').value));
            if (season) {
                if (this.priceForm.get('travelPeriodFrom').value !== season.travelPeriods[0].from || this.priceForm.get('travelPeriodTo').value !== season.travelPeriods[0].to) {
                    this.priceForm.get('seasonId').setValue('', { emitEvent: false });
                }
            }
        }
    }

    ngOnDestroy() {
        this.priceTypeSubscription?.unsubscribe();
        this.periodFromSubscription?.unsubscribe();
        this.periodToSubscription?.unsubscribe();
        this.seasonIdSubscription?.unsubscribe();
        this.priceSubscription?.unsubscribe();
    }

    async ngOnChanges() {
        this.maxAllowedPersons = this.accommodation.main.maxPersons - 1;
        this.usedPrice = cloneDeep(this.price);
        if (!this.seasons.seasons?.length) {
            this.seasonsOriginals = await this.apiConnectorService.getSeasons();

            // Saisons mit multiple Perioden aufsplitten
            this.seasons = new SeasonsDto();
            this.seasons.seasons = [];
            let seasonId = 0;
            const today = new Date();
            for (const season of this.seasonsOriginals.seasons) {
                if (season.travelPeriods.length > 1) {
                    for (const period of season.travelPeriods) {
                        if (new Date(period.from) > today) {
                            this.seasonsAliases[seasonId] = {
                                seasonID: season.seasonId,
                                from: period.from,
                            };
                            this.seasons.seasons.push({
                                seasonId,
                                name: season.name,
                                travelPeriods: [period],
                            });
                            seasonId++;
                        }
                    }
                } else {
                    if (season.travelPeriods[0] && new Date(season.travelPeriods[0].from) > today) {
                        this.seasonsAliases[seasonId] = {
                            seasonID: season.seasonId,
                            from: season.travelPeriods[0].from,
                        };
                        season.seasonId = seasonId;
                        this.seasons.seasons.push(season);
                        seasonId++;
                    }
                }
            }
            // sortiere nach travelPeriods
            this.seasons.seasons = this.seasons.seasons.sort((a, b) => {
                if (a.travelPeriods[0].from < b.travelPeriods[0].from) {
                    return -1;
                }
                if (a.travelPeriods[0].from > b.travelPeriods[0].from) {
                    return 1;
                }
                return 0;
            });
        }

        // korrigiere Personen
        if (this.usedPrice.prices?.[0]?.maxPersons > this.maxAllowedPersons) {
            this.usedPrice.prices[0].maxPersons = 0;
        }

        for (const control of Object.values(this.priceForm.controls)) {
            control.markAsUntouched();
        }

        // richtige neue seasonID finden
        const seasonId = this.seasonsAliases.findIndex((season) => season.seasonID === this.usedPrice.travelPeriod?.seasonId && season.from === this.usedPrice.travelPeriod?.from);

        // fülle mit den Werten des übergebenen Preises
        this.priceForm.patchValue({
            travelPeriodFrom: this.usedPrice.travelPeriod?.from,
            travelPeriodTo: this.usedPrice.travelPeriod?.to,
            followingYears: this.usedPrice.travelPeriod?.followingYears,
            price: this.usedPrice.prices?.[0]?.price,
            priceWeekend: this.usedPrice.prices?.[0]?.weekEndPrice,
            priceType: this.usedPrice.prices?.[0]?.maxPersons ? 'person' : 'booking',
            pricePerAdditionalPerson: this.usedPrice.prices?.[0]?.pricePerAdditionalPerson,
            maxPersons: this.usedPrice.prices?.[0]?.maxPersons || 0,
        });
        if (seasonId !== -1) {
            this.priceForm.get('seasonId').setValue(seasonId, { emitEvent: false });
            this.toggleSeasons();
        }
        this.priceForm.updateValueAndValidity();

        this.showWeekendPrice = !!this.usedPrice.prices?.[0]?.weekEndPrice;
        this.setWeekendPriceButtonDescription();
    }

    async onSubmit() {
        if (this.priceForm.valid) {
            let seasonToSave = null;
            const values = this.priceForm.getRawValue();
            if (values.seasonId) {
                seasonToSave = this.seasonsAliases[values.seasonId].seasonID;
            }

            this.usedPrice.travelPeriod = {
                from: values.travelPeriodFrom,
                to: values.travelPeriodTo,
                followingYears: values.followingYears,
            } as TravelPeriodDto;
            if (seasonToSave !== null) {
                this.usedPrice.travelPeriod.seasonId = seasonToSave;
            }

            this.usedPrice.prices = [
                {
                    price: values.price,
                    weekEndPrice: Number(values.priceWeekend) > 0 ? Number(values.priceWeekend) : undefined,
                    pricePerAdditionalPerson: values.priceType === 'person' ? values.pricePerAdditionalPerson : undefined,
                    maxPersons: values.priceType === 'person' && values.maxPersons > 0 ? Number(values.maxPersons) : undefined,
                    currency: this.ownerCurrency,
                },
            ];
            await this.onSave.emit(this.usedPrice);
            ($(this.modalID) as any).modal('hide');
        } else {
            this.priceForm.markAllAsTouched();
        }
    }

    toggleWeekendPrice() {
        this.showWeekendPrice = !this.showWeekendPrice;
        this.setWeekendPriceButtonDescription();
    }

    toggleSeasons() {
        this.showSeasons = !this.showSeasons;
    }

    setWeekendPriceButtonDescription() {
        this.showWeekendPriceToggleDescription = this.showWeekendPrice ? 'close' : 'open';
    }

    overlapCheckValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const fromDate = control.get('travelPeriodFrom').value;
            const toDate = control.get('travelPeriodTo').value;
            if (!fromDate || !toDate || fromDate > toDate) {
                return null;
            }

            const priceCheck: ExtendedDailyPriceRule = {
                travelPeriod: {
                    from: fromDate,
                    to: toDate,
                    followingYears: control.get('followingYears').value,
                } as TravelPeriodDto,
                id: this.price?.id,
                prices: [
                    {
                        price: 1,
                        weekEndPrice: 1,
                        pricePerAdditionalPerson: 1,
                        maxPersons: 1,
                        currency: 'EUR',
                    },
                ],
            };

            const overlapResult = this.accommodation.getDailyPriceOverlap(priceCheck);
            if (overlapResult?.from && overlapResult?.to) {
                return {
                    overlapError: {
                        from: overlapResult.from,
                        to: overlapResult.to,
                    },
                };
            }

            // Wenn fromDate oder toDate nicht definiert sind, wird keine Überlappung angenommen
            return null;
        };
    }

    dateOrderValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const travelPeriodFrom = control.get('travelPeriodFrom')?.value;
            const travelPeriodTo = control.get('travelPeriodTo')?.value;

            if (travelPeriodFrom && travelPeriodTo && travelPeriodFrom > travelPeriodTo) {
                return { dateOrderError: true };
            }

            return null;
        };
    }

    dateRangeToLongValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const travelPeriodFrom = control.get('travelPeriodFrom')?.value;
            const travelPeriodTo = control.get('travelPeriodTo')?.value;
            const followingYears = control.get('followingYears')?.value;
            const travelPeriod = new TravelPeriodDto();
            travelPeriod.from = travelPeriodFrom;
            travelPeriod.to = travelPeriodTo;
            travelPeriod.followingYears = followingYears;

            const travelPeriodService: TravelPeriodService = new TravelPeriodService();
            if (travelPeriodService.isDateRangeToLong(travelPeriod)) {
                return { dateRangeToLongError: true };
            }

            return null;
        };
    }

    dateBetweenMinMaxValidator(min: Date, max: Date): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const date = new Date(control.value);
            if (date < min || date > max) {
                return { dateOuterMinMax: true };
            }

            return null;
        };
    }

    getPersonRange() {
        return Array.from({ length: this.maxAllowedPersons }, (_, i) => i + 1);
    }

    getArrivalInformation() {
        if (this.priceForm.get('travelPeriodFrom').value && this.priceForm.get('travelPeriodTo').value) {
            this.step1Completed = true;
            this.priceInformations = this.accommodation.priceInformation?.getInformationForDateRange({
                from: this.priceForm.get('travelPeriodFrom').value,
                to: this.priceForm.get('travelPeriodTo').value,
            } as TravelPeriodDto);
        } else {
            this.step1Completed = false;
            this.priceInformations = [];
        }
    }

    resetHighlight(field: string) {
        if (field === 'travelPeriodFrom') {
            this.highlightTravelPeriodFrom = false;
        } else if (field === 'travelPeriodTo') {
            this.highlightTravelPeriodTo = false;
        }
    }
}
