<div class="panel">
    <div class="panel-heading">
        <h2 class="mb-0">
            {{ 'atraveo.dashboardbundle.panel.heading.statusquo.0' | translate }}
        </h2>
    </div>
    <div class="panel-body">
        <div class="row">
            <div
                class="align-self-center"
                [ngClass]="$any(authService.currentUser.features)?.VRMBESTACCOUNT ? 'col-8' : 'col-12'"
            >
                <div class="d-flex align-items-baseline">
                    <span class="status-quo-value online-accommodation-count">
                        <a routerLink="/accommodations/active">{{ countOnlineAccommodations }}</a>
                    </span>
                    <span>{{ 'atraveo.dashboardbundle.panel.heading.statusquo.objectsonline' | translate }}</span>
                </div>
                <ng-container *ngIf="this.showCommission && this.authService.currentUser?.settings?.commission?.percent">
                    <div class="d-flex align-items-baseline">
                        <span class="status-quo-value commission">{{ this.authService.currentUser.settings.commission.percent }}%</span>
                        <span>{{ 'text.commision.percent' | translate }}</span>
                    </div>
                </ng-container>
            </div>
            <ng-container *ngIf="$any(authService.currentUser.features)?.VRMBESTACCOUNT === true">
                <div class="col-4 mb-3 mb-sm-0 mb-lg-3 mb-xl-0 d-flex justify-content-center mt-0 mt-sm-n5 mt-lg-0 mt-xl-n5">
                    <img src="assets/img/label/top-host.svg" class="top-host-label" alt="Top Host 2023" />
                </div>
            </ng-container>
        </div>
    </div>
</div>
