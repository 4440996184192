import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../../../services/authentication/authentication.service';
import { ApiConnectorService } from '../../../../../../services/api-connector/api-connector.service';
import { ExtendedAccommodationEntity } from '../../../../../../entities/extendedAccommodationEntity';
import { ExtendedPriceInformationException } from '../../../../../../entities/PriceInformation/extended-price-information-exception';
import { NotificationService } from '../../../../../../services/notification/notification.service';
import { ErrorAnimateService } from '../../../../../../services/error-animate/error-animate.service';
import { DateService } from '../../../../../../services/date/date.service';
import { ExtendedPriceInformation } from '../../../../../../entities/PriceInformation/extended-price-information';
import { TravelPeriodService } from '../../../../../../services/travel-period/travel-period.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TravelPeriodDto } from 'data-structures/lib/es6/dto/accommodation/price-rule/travel-period.dto';
import { DialogService } from '../../../../../../services/dialog.service';

@Component({
    selector: 'app-advanced-settings',
    templateUrl: './advanced-settings.component.html',
    styleUrls: ['./advanced-settings.component.scss'],
})
export class AdvancedSettingsComponent implements OnInit, OnDestroy {
    accommodation: ExtendedAccommodationEntity;
    isDateRangeToLong: boolean = false;
    accommodationSubscription: Subscription;
    settingsForm: FormGroup;
    settingsGroupControls: any;
    submitted: boolean = false;
    exceptionCounter: number = 0;
    disabledCounter: number = 0;
    useDailyPrices: boolean = false;
    useDailyPricesMinOwnerNumber: number = 105490;
    settingFormSubscription: Subscription;

    constructor(
        readonly authenticationService: AuthenticationService,
        readonly apiConnectorService: ApiConnectorService,
        readonly notificationService: NotificationService,
        readonly translationService: TranslateService,
        private fb: FormBuilder,
        readonly dialogService: DialogService,
    ) {}

    ngOnInit() {
        this.settingsGroupControls = {
            from: new FormControl('', [Validators.required, this.overlapCheckValidator()]),
            to: new FormControl('', [Validators.required, this.overlapCheckValidator()]),
            su: new FormControl(true),
            mo: new FormControl(true),
            tu: new FormControl(true),
            we: new FormControl(true),
            th: new FormControl(true),
            fr: new FormControl(true),
            sa: new FormControl(true),
            minDuration: new FormControl(1, [Validators.required, Validators.min(1)]),
            minOccupancy: new FormControl(1, [Validators.required, Validators.min(1)]),
            followingYears: new FormControl(false),
            enabled: new FormControl(true),
        };

        this.settingsForm = new FormGroup({
            default: new FormGroup(
                {
                    minDuration: new FormControl(1, [Validators.required, Validators.min(1)]),
                    minOccupancy: new FormControl(1, [Validators.required, Validators.min(1)]),
                    su: new FormControl(true),
                    mo: new FormControl(true),
                    tu: new FormControl(true),
                    we: new FormControl(true),
                    th: new FormControl(true),
                    fr: new FormControl(true),
                    sa: new FormControl(true),
                },
                { validators: [this.atLeastOneSelectedValidator()] },
            ),
            exceptions: this.fb.array([]),
        });

        // wieviele exceptions sind disabled
        this.settingFormSubscription = this.settingsForm.valueChanges.subscribe((value) => {
            this.disabledCounter = Number(this.exceptionCounter - (value?.exceptions?.length || 0));
        });

        this.accommodationSubscription = this.apiConnectorService.activeAccommodation$.subscribe(async (accommodation) => {
            if (accommodation) {
                this.accommodation = cloneDeep(accommodation);

                // wer nutzt die DailyPrices
                this.useDailyPrices = this.accommodation.ownerNumber > this.useDailyPricesMinOwnerNumber;

                this.ngOnChanges();
            }
        });
    }

    addException() {
        this.submitted = true;
        if (this.settingsForm.valid) {
            // Verwendung der "default"-Werte für die Ausnahmen
            const newException = new FormGroup(cloneDeep(this.settingsGroupControls), { validators: [this.atLeastOneSelectedValidator()] });
            newException.get('to').disable();
            this.exceptionsFormArray.push(newException);
            this.exceptionCounter++;
            this.submitted = false;
        } else {
            this.settingsForm.markAllAsTouched();
        }
    }

    get exceptionsFormArray() {
        return this.settingsForm.get('exceptions') as FormArray;
    }

    ngOnDestroy() {
        this.accommodationSubscription?.unsubscribe();
        this.settingFormSubscription?.unsubscribe();
    }

    async onSubmit() {
        this.submitted = true;
        if (this.settingsForm.valid) {
            const values = this.settingsForm.getRawValue();
            const priceInformations = new ExtendedPriceInformation();
            let arrivalDays = '';
            arrivalDays += values.default.su ? 'Y' : 'N';
            arrivalDays += values.default.mo ? 'Y' : 'N';
            arrivalDays += values.default.tu ? 'Y' : 'N';
            arrivalDays += values.default.we ? 'Y' : 'N';
            arrivalDays += values.default.th ? 'Y' : 'N';
            arrivalDays += values.default.fr ? 'Y' : 'N';
            arrivalDays += values.default.sa ? 'Y' : 'N';

            priceInformations.arrivalDays = arrivalDays;
            priceInformations.departureDays = arrivalDays;
            priceInformations.minDuration = Number(values.default.minDuration);
            priceInformations.minOccupancy = this.useDailyPrices ? 1 : Number(values.default.minOccupancy);

            // entferne alle die nicht disabled sind und formatiere korrekt
            priceInformations.exceptions = values.exceptions
                .filter((exception) => exception.enabled)
                .map((exception) => {
                    const newException = new ExtendedPriceInformationException();
                    let arrivalDays = '';
                    arrivalDays += exception.su ? 'Y' : 'N';
                    arrivalDays += exception.mo ? 'Y' : 'N';
                    arrivalDays += exception.tu ? 'Y' : 'N';
                    arrivalDays += exception.we ? 'Y' : 'N';
                    arrivalDays += exception.th ? 'Y' : 'N';
                    arrivalDays += exception.fr ? 'Y' : 'N';
                    arrivalDays += exception.sa ? 'Y' : 'N';
                    newException.arrivalDays = arrivalDays;
                    newException.departureDays = arrivalDays;
                    newException.minDuration = Number(exception.minDuration);
                    newException.minOccupancy = this.useDailyPrices ? 1 : Number(exception.minOccupancy);
                    newException.travelPeriod = new TravelPeriodDto();
                    newException.travelPeriod.from = exception.from;
                    newException.travelPeriod.to = exception.to;
                    newException.travelPeriod.followingYears = exception.followingYears;
                    return newException;
                });
            this.accommodation.priceInformation = priceInformations;
            await this.apiConnectorService.saveAccommodation(this.accommodation);
            this.submitted = false;
            this.dialogService.closeDialog();
        } else {
            this.settingsForm.markAllAsTouched();
            this.settingsForm.updateValueAndValidity();
        }
    }

    // Validator-Funktion für mindestens ein ausgefülltes Feld
    atLeastOneSelectedValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const selectedFields = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
            const isAtLeastOneSelected = selectedFields.some((field) => control.get(field)?.value === true);

            return isAtLeastOneSelected ? null : { atLeastOneSelected: true };
        };
    }

    overlapCheckValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const travelPeriodService = new TravelPeriodService();
            const parentFormGroup = control.parent as FormGroup;

            if (!parentFormGroup) {
                return null; // Es gibt kein Elternelement, daher kein Validierungsfehler
            }

            const fromControl = parentFormGroup.get('from');
            const toControl = parentFormGroup.get('to');

            if (!fromControl || !toControl) {
                return null; // Wenn from oder to nicht gefunden wurden, wird keine Überlappungsprüfung durchgeführt
            }
            const controlTravelPeriod: TravelPeriodDto = {
                from: fromControl.value || toControl.value,
                to: toControl.value || fromControl.value,
                followingYears: parentFormGroup.get('followingYears').value,
                periodType: 1,
                seasonId: null,
            };

            for (const exception of this.exceptionsFormArray.controls) {
                if (exception !== parentFormGroup && exception.enabled) {
                    const parentTravelPeriod = {
                        from: exception.get('from').value,
                        to: exception.get('to').value,
                        followingYears: exception.get('followingYears').value,
                        periodType: 1,
                        seasonId: null,
                    };

                    const result = travelPeriodService.getPeriodOverlap(controlTravelPeriod, parentTravelPeriod);
                    if (result) {
                        return {
                            overlapError: {
                                from: result.from,
                                to: result.to,
                            },
                        };
                    }
                }
            }
        };
    }

    ngOnChanges() {
        if (this.settingsForm) {
            // reset der exceptions
            this.exceptionsFormArray.clear();
            this.disabledCounter = 0;
            this.exceptionCounter = 0;

            if (!this.accommodation.priceInformation) {
                this.accommodation.priceInformation = new ExtendedPriceInformation();
                this.accommodation.priceInformation.arrivalDays = 'NNNNNNN';
                this.accommodation.priceInformation.departureDays = 'NNNNNNN';
                this.accommodation.priceInformation.minDuration = 1;
                this.accommodation.priceInformation.minOccupancy = 1;
            }

            this.settingsForm.get('default').patchValue(this.parsePriceInformation(this.accommodation.priceInformation));

            if (this.accommodation?.priceInformation?.exceptions && this.accommodation.priceInformation.exceptions.length) {
                this.accommodation.priceInformation.exceptions.sort((a, b) => {
                    return this.getSortDateFromException(a).getTime() - this.getSortDateFromException(b).getTime();
                });

                this.accommodation.priceInformation.exceptions.forEach((exception) => {
                    const newException = cloneDeep(new FormGroup(this.settingsGroupControls));
                    newException.patchValue(this.parsePriceInformation(exception));
                    this.exceptionsFormArray.push(newException);
                    this.exceptionCounter++;
                });
            }
        }
    }

    parsePriceInformation(priceInformation: ExtendedPriceInformation | ExtendedPriceInformationException) {
        const arrivalDays = priceInformation.arrivalDays.split('');
        const parsedInfo: any = {
            su: arrivalDays[0] === 'Y',
            mo: arrivalDays[1] === 'Y',
            tu: arrivalDays[2] === 'Y',
            we: arrivalDays[3] === 'Y',
            th: arrivalDays[4] === 'Y',
            fr: arrivalDays[5] === 'Y',
            sa: arrivalDays[6] === 'Y',
            minDuration: priceInformation.minDuration,
            minOccupancy: priceInformation.minOccupancy,
        };

        if ('travelPeriod' in priceInformation) {
            parsedInfo.followingYears = priceInformation.travelPeriod?.followingYears;
            parsedInfo.from = priceInformation.travelPeriod?.from;
            parsedInfo.to = priceInformation.travelPeriod?.to;
        }

        return parsedInfo;
    }

    deleteException(exceptionId: number) {
        this.accommodation.priceInformation.exceptions.splice(exceptionId, 1);
        this.apiConnectorService.saveAccommodation(this.accommodation);
    }

    getSortDateFromException(exception: ExtendedPriceInformationException): Date {
        if (exception.travelPeriod?.from) {
            return DateService.getDateFromString(exception.travelPeriod?.from);
        }
    }
}
