import { Component } from '@angular/core';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { DialogService } from '../../../../services/dialog.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout-dialog.component.html',
    styleUrl: './logout-dialog.component.scss',
})
export class LogoutDialogComponent {
    constructor(readonly authService: AuthenticationService, readonly router: Router, readonly dialogService: DialogService) {}
    logout() {
        this.authService.logout();
        this.closeDialog();
    }
    closeDialog() {
        this.dialogService.closeDialog();
    }
}
