<p><strong>{{ 'text.change.pw.0' | translate}}</strong></p>
<div class="change-wrapper">
    <div class="row">
        <div class="col">
            <p class="col-sm-10 criteria-text">{{'text.change.pw.criteria' | translate}}</p>
            <ul>
                <li>{{ 'text.change.pw.length' | translate }}</li>
                <li>{{ 'text.change.pw.uppercase' | translate }}</li>
                <li>{{ 'text.change.pw.lowercase' | translate }}</li>
                <li>{{ 'text.change.pw.number' | translate }}</li>
                <li>{{ 'text.change.pw.special.characters' | translate }}</li>
                <li>{{ 'text.change.pw.username' | translate }}</li>
            </ul>
        </div>
    </div>
</div>
<form>
    <div class="form-group row">
        <label for="current-password"
               class="col-sm-5 col-form-label required">{{ 'user.password.current' | translate }}
        </label>
        <div class="col-sm-5">
            <input type="password" name="current-password" id="current-password" class="form-control password-field" required
                   [(ngModel)]="this.currentPassword"
                   [ngModelOptions]="{standalone: true}">
        </div>
    </div>
    <div class="form-group row">
        <label for="new-password"
               class="col-sm-5 col-form-label required">{{ 'user.password.new' | translate }}
        </label>
        <div class="col-sm-5">
            <input type="password" id="new-password" name="new-password" class="form-control password-field" required
                   [(ngModel)]="this.newPassword"
                   [ngModelOptions]="{standalone: true}">
        </div>
    </div>

    <div class="form-group row">
        <label for="repeat-password"
               class="col-sm-5 col-form-label required">{{ 'user.password.repeat' | translate }}
        </label>
        <div class="col-sm-5">
            <input type="password" id="repeat-password" name="repeat-password" class="form-control password-field" required
                   [(ngModel)]="this.confirmPassword"
                   [ngModelOptions]="{standalone: true}">
        </div>
    </div>

    <div class="form-group row">
        <label class="col-sm-5 col-form-label"></label>

        <div class="col-sm-5">
            <input type="checkbox" class="toggle-password-checkbox" (click)="togglePassword()" id="toggle-password">
            <label for="toggle-password">{{ 'text.login.showpassword' | translate }}</label>
        </div>
    </div>

    <div class="form-group row">
        <label class="col-sm-3 col-form-label">
            {{ 'text.mandatoryfield' | translate }}
        </label>
    </div>
    <div class="row">
        <div *ngFor="let errorMessage of errorMessages" class="col-md-12 error">
            {{ this.errorMessage | translate }}
        </div>
    </div>
    <button (click)="onSubmit()" type="submit" class="btn btn-success"><i _ngcontent-ng-c2582920112="" class="fas fa-cloud-upload-alt mr-2"></i>{{ 'text.change.pw.0' | translate }}</button>
</form>
