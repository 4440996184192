import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date/date.service';

@Pipe({
    name: 'stringToDate'
})
export class StringToDatePipe implements PipeTransform{

    transform(value: any, ...args: any[]): Date {
        if (typeof value !== 'string') {
            return value;
        }

        return DateService.getDateFromString(value);
    }
}
