import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrl: './card.component.scss',
    encapsulation: ViewEncapsulation.None,
})
export class CardComponent {
    @Input()
    description: string;
    @Input()
    nr: number;
    @Input()
    identity: string;

    @Input()
    btn1: {
        link: string | null;
        text: string;
        target: string;
        onclick: string;
    };
    @Input()
    btn2: {
        link: string | null;
        text: string;
        target: string;
        onclick: string;
    };
    @Input()
    btnCompleteNow: {
        link: string | null;
        text: string;
        target: string;
        onclick: string;
    };
    @Input()
    success: boolean = false;
    @Input()
    layout: string = 'full';
    @Input()
    successText: string = '';

    @Output()
    clickEvent = new EventEmitter<string>();

    emitClick(callback: string): void {
        this.clickEvent.emit(callback);
    }
}
