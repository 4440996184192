<div class="modal-content">
    <div class="filter-modal-header">
        <button class="close" (click)="closeDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="form-group row">
                <div class="col-sm-3 col-form-label inboxText">
                    {{ 'text.filter.accommodation.0' | translate }}:
                </div>
                <div class="col-sm-9">
                    <app-accommodation-ajax-search (onSearch)="messageRequest.accommodationId = $event"
                                                   [(ngModel)]="messageRequest.accommodationId"
                                                   hideLabel="true"
                                                   hideIcons="true"
                                                   classes="form-control d-inline typeahead"
                                                   htmlId="filterObjectnumberInbox">
                    </app-accommodation-ajax-search>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-3 col-form-label inboxText">
                    {{ 'text.filter.booking.number' | translate }}:
                </div>
                <div class="col-sm-9">
                    <select class="form-control" (change)="messageRequest.bookingNumber = $event.target.value" [(ngModel)]="messageRequest.bookingNumber">
                        <option value="">{{ 'text.filter.accommodation.all' | translate }}</option>
                        <option *ngFor="let booking of bookings" [value]="booking.bookingId">{{ booking.bookingId }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-3 col-form-label inboxText">
                    {{ 'text.notification.field.fromUser' | translate }}:
                </div>
                <div class="col-sm-9">
                    <select class="form-control" (change)="messageRequest.accountId = $event.target.value" [(ngModel)]="messageRequest.accountId">
                        <option value="">{{ 'text.filter.accommodation.all' | translate }}</option>
                        <option value="{{ accountIds?.ownerService }}">{{ 'vermieterservice' | translate }}</option>
                        <option value="{{ accountIds?.customerService }}">{{ 'kundenservice' | translate }}</option>
                        <option value="{{ accountIds?.customerRatings }}">{{ 'kundenmeinung' | translate }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary"
                (click)="resetMessagesFilter()">{{ 'button.reset.filter' | translate }}</button>
        <button (click)="filterMessages()" class="btn btn-success filter-btn" type="button">
            <i class="fa fa-check"></i>
            {{ 'button.filter' | translate }}
        </button>
    </div>
</div>