<p [innerHTML]="'atraveo.notificationbundle.explanation' | translate"></p>

<div class="row">
    <div class="col">
        <strong class="large">{{ 'atraveo.notificationbundle.filter.form.searchText' | translate }}:</strong>
        <input type="text" class="form-control w-auto d-inline typeahead" id="search" [(ngModel)]="messageRequest.searchInSubject" [ngModelOptions]="{ standalone: true }" />
        <a><i class="fa fa-search fa-lg ml-2" (click)="filterMessages()"></i></a>
        <a><i class="fa fa-undo fa-lg" (click)="clearFilter()"></i></a>
    </div>

    <div class="col-md-5">
        <div class="float-right">
            <div class="col">
                <button class="btn btn-primary float-right" type="button" (click)="openFilterModal()">
                    <i class="fa fa-filter mr-2"></i>
                    {{ 'filter' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<table class="responsive-table">
    <thead>
        <tr>
            <th>
                <a href="javascript:void(0);" (click)="selectAllMessages()">{{ 'atraveo.accommodationbundle.mark-all.short-text' | translate }}</a>
            </th>
            <th>{{ 'text.notification.field.sendDate' | translate }}</th>
            <th>{{ 'text.notification.field.fromUser' | translate }}</th>
            <th>{{ 'text.notification.field.subject' | translate }}</th>
            <th>{{ 'text.notification.field.relation' | translate }}</th>
            <th>{{ 'text.notification.field.options' | translate }}</th>
        </tr>
    </thead>

    <ng-container *ngFor="let inboxMessage of messages; let messageIndex = index">
        <tr *ngIf="messageIndex < messages.length - 1" class="notification-content-inbox" [class.not-read]="!inboxMessage.isRead">
            <td [attr.data-label]="'atraveo.accommodationbundle.mark-all.short-text' | translate">
                <input name="inbox-checkbox" type="checkbox" class="inbox-message-checkbox" (change)="changeState()" id="{{ inboxMessage.id }}" />
            </td>
            <td [attr.data-label]="'text.notification.field.sendDate' | translate">{{ inboxMessage.date | stringToDate | date }}</td>
            <td [attr.data-label]="'text.notification.field.fromUser' | translate">{{ inboxMessage.sender }}</td>
            <td [attr.data-label]="'text.notification.field.subject' | translate">
                <a routerLink="/notification/show/{{ inboxMessage.id }}" [queryParams]="{ ownerid: authenticationService.currentUser.ownerNumber }" routerLinkActive="active">{{
                    inboxMessage.subject
                }}</a>
            </td>
            <td [attr.data-label]="'text.notification.field.relation' | translate"></td>
            <td [attr.data-label]="'text.notification.field.options' | translate">
                <a routerLink="/notification/show/{{ inboxMessage.id }}" [queryParams]="{ ownerid: authenticationService.currentUser.ownerNumber }" routerLinkActive="active"
                    ><i class="fas fa-eye"></i
                ></a>

                <i *ngIf="inboxMessage.attachments?.length" class="fas fa-paperclip"></i>
            </td>
        </tr>
    </ng-container>
</table>

<div class="btn-group notification-inbox-buttons">
    <button class="btn btn-secondary" [disabled]="noSelectedMessages" (click)="setReadStatus()" title="{{'atraveo.notification.messages.unmarked.read' | translate }}">{{ 'text.notification.button.mark.read' | translate }}</button>
    <button class="btn btn-secondary" [disabled]="noSelectedMessages" (click)="setUnreadStatus()" title="{{'atraveo.notification.messages.unmarked.unread' | translate }}">{{ 'text.notification.button.mark.unread' | translate }}</button>
    <button class="btn btn-secondary"   [disabled]="noSelectedMessages" (click)="openConfirmationDialog()" title="{{'atraveo.notification.messages.unmarked.deleted' | translate }}">{{ 'text.notification.button.mark.delete' | translate }}</button>
</div>

<div *ngFor="let inboxMessage of messages | paginate: { id: 'inbox-pagination', itemsPerPage: messageRequest.limit, currentPage: messageRequest.page, totalItems: messagesCount }"></div>

<pagination-controls id="inbox-pagination" class="float-right pagination-control" (pageChange)="changePage($event)" previousLabel="" nextLabel=""> </pagination-controls>
