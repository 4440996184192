<h1>{{ 'button.interfaces' | translate }}</h1>

<p>
    {{ 'atraveo.interfacebundle.intro.text' | translate }}
</p>

<ul class="nav nav-tabs nav-justified" role="tablist">
    <li class="nav-item">
        <a class="nav-link"
           [class.active]="this.selectedTab == 'vacancy'"
           (click)="this.selectedTab='vacancy'"
           role="tab" data-toggle="tab"
           href="#vacancy">{{ 'atraveo.interfacebundle.availability.tab.title' | translate }}</a>
    </li>
    <li class="nav-item">
        <a class="nav-link"
           [class.active]="this.selectedTab == 'booking'"
           (click)="this.selectedTab='booking'"
           role="tab" data-toggle="tab"
           href="#booking">{{ 'atraveo.interfacebundle.booking.tab.title' | translate }}</a>
    </li>
    <li class="nav-item">
        <a class="nav-link"
           [class.active]="this.selectedTab == 'documentations'"
           (click)="this.selectedTab='documentations'"
           role="tab" data-toggle="tab"
           href="#documentations">{{ 'atraveo.interfacebundle.entity.documentations' | translate }}</a>
    </li>
</ul>

<div class="tab-content p-3" *ngIf="owner?.interfaces">
    <div class="nav-item-sm mt-3" [class.active]="this.selectedTab == 'vacancy'" (click)="this.selectedTab= this.selectedTab == 'vacancy' ? '' : 'vacancy'">
        <i class="fa" [ngClass]="this.selectedTab == 'vacancy' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.interfacebundle.availability.tab.title' | translate }}
    </div>
    <div id="vacancy" role="tabpanel" class="tab-pane"
         [ngClass]="{'active': this.selectedTab == 'vacancy', 'show': this.selectedTab == 'vacancy', 'fade': this.selectedTab != 'vacancy'}">
        <h2>{{ 'atraveo.interfacebundle.availability.tab.title' | translate }}</h2>

        <ng-container *ngIf="!ownerHasIcal || this.authentificationService.currentUser.isEmployee()">

            <ng-container *ngIf="!owner?.interfaces?.importSource || owner?.interfaces?.importSource === 'atraveo'">
                <p><label for="url">{{ 'atraveo.interfacebundle.availability.input.label' | translate }}</label></p>

                <form name="interface">
                    <div class="row">
                        <div class="col-sm-8 interface-url">
                            <input type="url" id="url" class="form-control" [(ngModel)]="owner.interfaces.vacancy"
                                   [ngModelOptions]="{standalone: true}">
                        </div>
                        <div class="col">
                            <a (click)="saveVacancy()" class="btn btn-success">
                                <i class="fas fa-cloud-upload-alt mr-2"></i>
                                {{ 'button.save' | translate }}
                            </a>
                        </div>
                    </div>
                </form>
            </ng-container>

            <p *ngIf="owner.interfaces?.vacancy" class="success">
                <span
                    *ngIf="owner.interfaces.vacancyCheckResult?.valid && owner.interfaces.vacancyCheckResult?.type === 'both'">
                    {{ 'text.flashbag.interfacebundle.availability.checksuccessful' | translate }}
                </span>
                    <span
                        *ngIf="owner.interfaces.vacancyCheckResult?.valid && owner.interfaces.vacancyCheckResult?.type === 'vacancy'">
                    {{ 'text.flashbag.interfacebundle.availability.onlyimport' | translate}}
                </span>
                    <span
                        *ngIf="owner.interfaces.vacancyCheckResult?.valid && owner.interfaces.vacancyCheckResult?.type === 'price'">
                    {{ 'text.flashbag.interfacebundle.availability.onlylive' | translate}}
                </span>
            </p>

            <p *ngIf="owner.interfaces?.vacancy && owner.interfaces?.vacancyCheckResult?.valid === false" class="error">
                <span
                    *ngIf="owner.interfaces?.vacancyCheckResult?.lastImportHttpStatusCode !== 404 && !owner.interfaces.vacancyCheckResult.checkErrors?.includes(1)">
                    {{ 'text.flashbag.interfacebundle.availability.nointerface' | translate }}
                </span>
                <span *ngIf="owner.interfaces?.vacancyCheckResult?.lastImportHttpStatusCode === 404">
                    {{ 'text.flashbag.interfacebundle.availability.notaccessible' | translate }} <br>
                        {{ owner.interfaces.vacancyCheckResult.lastUsedVacancyUrl }} <br>
                        {{ owner.interfaces.vacancyCheckResult.lastUsedPriceUrl }}
                </span>
                <span *ngIf="owner?.interfaces?.vacancyCheckResult?.lastImportHttpStatusMessage === 'Invalid URL'">{{ 'atraveo.interfacebundle.invalid.url' | translate }} </span>
                <span *ngIf="owner?.interfaces?.vacancyCheckResult?.checkErrors?.includes(1)">{{ 'vacancy.check.noOwnerAccommodationIds' | translate }}</span>
            </p>

            <div class="mt-3" *ngIf="!owner?.interfaces?.importSource || owner?.interfaces?.importSource === 'atraveo'">
            <span>
                {{ 'atraveo.interfacebundle.availability.special.needotherquestion' | translate }} <a
                class="btn btn-link pt-0" data-toggle="modal"
                data-target="#source-modal">{{ 'atraveo.interfacebundle.availability.special.select' | translate }}</a>
            </span>
            </div>

            <div class="mt-3" *ngIf="owner?.interfaces?.importSource && owner?.interfaces?.importSource !== 'atraveo'">
                <p *ngIf="owner.interfaces.importSource == 'fewoline'">
                    {{ 'atraveo.interfacebundle.availability.special.fewoline.text' | translate}} <a
                    class="btn btn-link pt-0" data-toggle="modal"
                    data-target="#source-modal">{{ 'atraveo.interfacebundle.availability.special.select' | translate }}</a>
                </p>
                <p *ngIf="owner.interfaces.importSource == 'fewoline'">
                    {{ 'atraveo.interfacebundle.availability.special.fewoline.tokeninfo' | translate }}
                </p>

                <p *ngIf="owner.interfaces.importSource == 'fungiwo'">
                    {{ 'atraveo.interfacebundle.availability.special.fungiwo.text' | translate}} <a
                    class="btn btn-link pt-0" data-toggle="modal"
                    data-target="#source-modal">{{ 'atraveo.interfacebundle.availability.special.select' | translate }}</a>
                </p>
                <p *ngIf="owner.interfaces.importSource == 'fungiwo'">
                    {{ 'atraveo.interfacebundle.availability.special.fungiwo.tokeninfo' | translate }}
                </p>
                <div class="text-center"><strong>{{ owner.interfaces.password}}</strong></div>
            </div>
        </ng-container>
        <ng-container *ngIf="ownerHasIcal && !this.authentificationService.currentUser.isEmployee()">
            {{ 'atraveo.accommodationbundle.error.alreadyHasIcalInterface' | translate }}
        </ng-container>

        <div class="interface-pdf">
            <a [href]="translationService.currentLang === 'de' ? 'assets/pdfs/documentation_vacancy_interface_de.pdf' : '/assets/pdfs/documentation_vacancy_interface_en.pdf'"
               target="_blank">
                {{ 'atraveo.interfacebundle.entity.vacancy.interface.download' | translate }}:
                <i class="fas fa-download fa-lg ml-1"></i>
            </a>
        </div>
    </div>

    <div class="nav-item-sm mt-3" [class.active]="this.selectedTab == 'booking'" (click)="this.selectedTab= this.selectedTab == 'booking' ? '' : 'booking'">
        <i class="fa" [ngClass]="this.selectedTab == 'booking' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.interfacebundle.booking.tab.title' | translate }}
    </div>

    <div id="booking" role="tabpanel" class="tab-pane"
         [ngClass]="{'active': this.selectedTab == 'booking', 'show': this.selectedTab == 'booking', 'fade': this.selectedTab != 'booking'}">
        <p><label for="booking-url">{{ 'atraveo.interfacebundle.booking.input.label' | translate }}</label></p>

        <form name="interface">
            <div class="row">
                <div class="col-sm-8 interface-url">
                    <input type="url" name="booking-url" id="booking-url" class="form-control"
                           [(ngModel)]="owner.interfaces.booking"
                           [ngModelOptions]="{standalone: true}">
                </div>
                <div class="col">
                    <a (click)="saveBooking()" class="btn btn-success">
                        <i class="fas fa-cloud-upload-alt mr-2"></i>
                        {{ 'button.save' | translate }}
                    </a>
                </div>
            </div>
        </form>

        <div class="interface-pdf">
            <a [href]="translationService.currentLang === 'de' ? 'assets/pdfs/documentation_booking_interface_de.pdf' : '/assets/pdfs/documentation_booking_interface_en.pdf'"
               target="_blank">
                {{ 'atraveo.interfacebundle.entity.booking.interface.download' | translate }}:
                <i class="fas fa-download fa-lg ml-1"></i>
            </a>
        </div>
    </div>

    <div class="nav-item-sm mt-3" [class.active]="this.selectedTab == 'documentations'"
         (click)="this.selectedTab= this.selectedTab == 'documentations' ? '' : 'documentations'">
        <i class="fa" [ngClass]="this.selectedTab == 'documentations' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        {{ 'atraveo.interfacebundle.entity.documentations' | translate }}
    </div>

    <div id="documentations" role="tabpanel" class="tab-pane"
         [ngClass]="{'active': this.selectedTab == 'documentations', 'show': this.selectedTab == 'documentations', 'fade': this.selectedTab != 'documentations'}">

        <div class="interface-pdf">
            <a [href]="translationService.currentLang === 'de' ? 'assets/pdfs/documentation_vacancy_interface_de.pdf' : '/assets/pdfs/documentation_vacancy_interface_en.pdf'"
               target="_blank">
                {{ 'atraveo.interfacebundle.entity.vacancy.interface.download' | translate }}:
                <i class="fas fa-download fa-lg ml-1"></i>
            </a>
        </div>

        <div class="interface-pdf">
            <a [href]="translationService.currentLang === 'de' ? 'assets/pdfs/documentation_booking_interface_de.pdf' : 'assets/pdfs/documentation_booking_interface_en.pdf'"
               target="_blank">
                {{ 'atraveo.interfacebundle.entity.booking.interface.download' | translate }}:
                <i class="fas fa-download fa-lg ml-1"></i>
            </a>
        </div>

        <div class="interface-pdf">
            <a [href]="translationService.currentLang === 'de' ? 'assets/pdfs/documentation_import_interface_de.pdf' : 'assets/pdfs/documentation_import_interface_en.pdf'"
               target="_blank">
                {{ 'atraveo.interfacebundle.entity.import.interface.download' | translate }}:
                <i class="fas fa-download fa-lg ml-1"></i>
            </a>
        </div>
        <div class="interface-pdf">
            <a [href]="translationService.currentLang === 'de' ? 'assets/pdfs/documentation_price_interface_de.pdf' : 'assets/pdfs/documentation_price_interface_en.pdf'"
               target="_blank">
                {{ 'atraveo.interfacebundle.entity.price.interface.download' | translate }}:
                <i class="fas fa-download fa-lg ml-1"></i>
            </a>
        </div>
    </div>
</div>

<div class="modal fade" id="source-modal" tabindex="-1" role="dialog" aria-labelledby="source-modal-Label"
     aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="source-modal-Label">{{ 'atraveo.interfacebundle.availability.special.title' | translate }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body container-fluid" *ngIf="owner.interfaces">
                <p>{{ 'atraveo.interfacebundle.availability.special.info' | translate }}

                <div class="row">
                    <div class="col-sm-3">
                        <label
                            for="atraveo">{{ 'atraveo.interfacebundle.availability.special.standard.name' | translate }}</label>
                    </div>
                    <div class="col">
                        <input type="radio" name="source" value="atraveo" id="atraveo"
                               [(ngModel)]="owner.interfaces.importSource">
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <label
                            for="fewoline">{{ 'atraveo.interfacebundle.availability.special.fewoline.name' | translate }}</label>
                    </div>
                    <div class="col">
                        <input type="radio" name="source" value="fewoline" id="fewoline"
                               [(ngModel)]="owner.interfaces.importSource">
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <label for="fungiwo">
                            {{ 'atraveo.interfacebundle.availability.special.fungiwo.name' | translate }}
                        </label>
                    </div>
                    <div class="col">
                        <input type="radio" name="source" value="fungiwo" id="fungiwo"
                               [(ngModel)]="owner.interfaces.importSource">
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                        (click)="saveSource()">{{ 'button.save' | translate }}</button>
            </div>
        </div>
    </div>
</div>
