import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace',
})
export class ReplacePipe implements PipeTransform {
    transform(value: string, regexValue: any, replaceValue: any): any {
        let regex = new RegExp(regexValue, 'g');
        if (value) {
            return value.replace(regex, replaceValue);
        }
    }
}
