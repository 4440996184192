<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'atraveo.accommodationbundle.price.edit' | translate }}</h5>
        <button class="close" (click)="closeDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <app-edit-price *ngIf="!useDailyPrices && currentPrice" modalID="#priceModalNew" [price]="currentPrice" [submitted]="submitted" [editPriceModal]="editPriceModal" [dateOverlap]="dateOverlap"></app-edit-price>
            <app-edit-daily-price *ngIf="useDailyPrices && currentDailyPrice" modalID="#priceModalNew" [accommodation]="accommodation" [price]="currentDailyPrice" (onSave)="saveDailyPrice($event)"></app-edit-daily-price>
        </div>
    </div>
</div>