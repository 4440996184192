import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { ApiConnectorService } from '../api-connector/api-connector.service';
import { FindRatingRequestDto } from 'data-structures/lib/es6/dto/rating/owner/find-rating-request.dto';
import { FindRatingFilterDto } from 'data-structures/lib/es6/dto/rating/owner/find-rating-filter.dto';
import { FindRatingSortDto } from 'data-structures/lib/es6/dto/rating/owner/find-rating-sort-dto';

@Injectable({
    providedIn: 'root',
})
export class AccommodationRatingService {
    accommodationUnreadRatingCountChange: Subject<number> = new Subject<number>();
    accommodationUnreadRatingCount: number;

    constructor(readonly authenticationService: AuthenticationService, readonly apiConnectorService: ApiConnectorService) {}

    async getAccommodationUnreadRatingCount() {
        if (!this.authenticationService.currentUser.ownerNumber) {
            return 0;
        }

        const ratingsRequest = new FindRatingRequestDto();
        ratingsRequest.ownerNumber = this.authenticationService.currentUser.ownerNumber;

        ratingsRequest.filter = new FindRatingFilterDto();
        ratingsRequest.filter.onlyUnread = true;
        ratingsRequest.sort = new FindRatingSortDto();
        ratingsRequest.take = 1;
        ratingsRequest.skip = 0;

        const ratingsResponse = await this.apiConnectorService.findRatings(ratingsRequest);

        this.accommodationUnreadRatingCount = ratingsResponse.count;
        this.accommodationUnreadRatingCountChange.next(this.accommodationUnreadRatingCount);

        return this.accommodationUnreadRatingCount;
    }
}
