import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { ApiConnectorService } from '../../../services/api-connector/api-connector.service';
import { OwnerImportTypeEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';
import { Router } from '@angular/router';
import { AccommodationCountService } from '../../../services/accommodation/accommodation-count.service';
import { Subscription } from 'rxjs';
import { DateService } from '../../../services/date/date.service';
import { MessageCountService } from '../../../services/messages/message-count.service';
import { ErrorCountService } from '../../../services/accommodation/error-count.service';
import { AccommodationRatingService } from '../../../services/accommodation/accommodation-rating.service';
import { AutoCreateObjectDataService } from '../../../services/auto-create-object-data/auto-create-object-data.service';

interface ProductKey {
    productKey: string;
    count: number;
}

@Component({
    selector: 'app-service-sidebar',
    templateUrl: './service-sidebar.component.html',
    styleUrls: ['./service-sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ServiceSidebarComponent implements OnInit {
    accommodationCount: number;
    imported: number = OwnerImportTypeEnum.Imported;
    changeToUser: number;
    changeToAccommodation: string;
    serviceSidebarVisible: boolean;
    errorChangeToUser: string;
    private storageEventHandler: (event: StorageEvent) => void;
    accommodationCountSubscription: Subscription;
    registrationDate: Date;
    lastLoginDate: Date;
    notesBilling: string = '';
    notesActive: boolean = false;
    usedProductKeys: [ProductKey];

    constructor(
        readonly authService: AuthenticationService,
        readonly apiConnector: ApiConnectorService,
        readonly router: Router,
        readonly accommodationCountService: AccommodationCountService,
        readonly changeDetectorRef: ChangeDetectorRef,
        readonly dateService: DateService,
        readonly messageCountService: MessageCountService,
        readonly errorCountService: ErrorCountService,
        readonly accommodationRatingService: AccommodationRatingService,
        readonly autoCreateObjectDataService: AutoCreateObjectDataService,
    ) {
        const serviceSidebarVisible = JSON.parse(localStorage.getItem('serviceSidebarVisible'));
        this.serviceSidebarVisible = serviceSidebarVisible !== null ? serviceSidebarVisible : true;
    }

    async ngOnInit(): Promise<void> {
        this.accommodationCountSubscription = await this.accommodationCountService.accommodationCountChange.subscribe((value) => {
            this.accommodationCount = value;
        });

        await this.apiConnector.getAccommodationCount(this.authService.currentUser).then((accommodationCount) => {
            this.accommodationCount = accommodationCount;
        });

        if (this.authService.userIsFromCh()) {
            if (this.authService.currentUser.notes?.billing) {
                this.notesBilling = this.authService.currentUser.notes.billing;
            }

            await this.apiConnector.getProductKeyCount(this.authService.currentUser).then((usedProductKeys) => {
                this.usedProductKeys = usedProductKeys;
            });
            this.notesActive = this.usedProductKeys.some((product) => ['511'].includes(product.productKey));
        }

        // watch for changes in other tabs
        this.storageEventHandler = (event) => {
            if (event.key === 'serviceSidebarVisible') {
                const newValue = JSON.parse(localStorage.getItem('serviceSidebarVisible'));
                if (newValue !== this.serviceSidebarVisible) {
                    this.serviceSidebarVisible = newValue;
                    // trigger change detection
                    this.changeDetectorRef.detectChanges();
                }
            }
        };
        window.addEventListener('storage', this.storageEventHandler);

        this.registrationDate = this.authService.currentUser?.registration?.registrationDate;
        if (!this.dateService.isValidDate(this.registrationDate.toString())) {
            // wenn das Datum nicht valide ist, benutze es nicht in der Komponente
            this.registrationDate = null;
        }
        this.lastLoginDate = this.authService.currentUser?.lastLoginDate;
    }

    ngOnDestroy(): void {
        this.accommodationCountSubscription?.unsubscribe();
        window.removeEventListener('storage', this.storageEventHandler);
    }

    async changeUser() {
        this.errorChangeToUser = '';
        try {
            await this.authService.loadAndChangeToUser(this.changeToUser);

            // Reset für die Navigation
            const promises = [];
            promises.push(this.errorCountService.getAccommodationErrorCount());
            promises.push(this.messageCountService.getMessagesCount());
            promises.push(this.accommodationRatingService.getAccommodationUnreadRatingCount());
            await Promise.all(promises);
            this.router.navigate(['/dashboard']);
        } catch (error) {
            this.errorChangeToUser = 'Ungültige Vermieternummer.';
        }
    }

    async changeAccommodation() {
        const user = await this.apiConnector.getUserDataByAccommodationId(this.changeToAccommodation);
        await this.authService.loadAndChangeToUser(user.ownerNumber);
        await this.router.navigate(['/accommodation/' + this.changeToAccommodation]);

        // Reset für die Navigation
        const promises = [];
        promises.push(this.errorCountService.getAccommodationErrorCount());
        promises.push(this.messageCountService.getMessagesCount());
        promises.push(this.accommodationRatingService.getAccommodationUnreadRatingCount());
        await Promise.all(promises);
    }

    toggleServiceSidebar(serviceSidebarVisible: boolean) {
        this.serviceSidebarVisible = serviceSidebarVisible;
        localStorage.setItem('serviceSidebarVisible', JSON.stringify(serviceSidebarVisible));
    }

    saveNotes() {
        if (!this.authService.currentUser.notes) {
            this.authService.currentUser.notes = {
                billing: '',
            };
        }
        this.authService.currentUser.notes.billing = this.notesBilling;
        this.authService.saveOwner(this.authService.currentUser);
    }

    async autoCreateAndSave() {
        const completedAccommodation = await this.autoCreateObjectDataService.getAutoCompletedAccommodation();
        await this.apiConnector.saveAccommodation(completedAccommodation, true, true, true);
        await this.autoCreateObjectDataService.createImagesForSelectedAccommodation(completedAccommodation);
        const userTracking = this.authService.currentUser?.tracking;
        if (userTracking?.onboarding) {
            if (!userTracking.onboarding.accommodationCreationStarted) {
                userTracking.onboarding.accommodationCreationStarted = new Date();
                this.authService.saveOwner(this.authService.currentUser);
            }
        }
    }
}
