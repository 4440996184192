import { Component } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiConnectorService } from '../../services/api-connector/api-connector.service';

@Component({
    selector: 'app-idNow',
    templateUrl: './idNow.component.html',
    styleUrls: ['./idNow.component.scss'],
})
export class IdNowComponent {
    page: string;
    trustUrl: SafeResourceUrl;
    identificationCode: string;
    currentYear: number;

    constructor(private sanitizer: DomSanitizer, readonly router: Router, readonly authenticationService: AuthenticationService, readonly apiConnectorService: ApiConnectorService) {
        const parts = this.router.url.split('/');
        if (parts[1] === 'idnow') {
            this.apiConnectorService.trackLink(this.router.url.toString());

            this.identificationCode = parts[2];
            this.page = 'https://go.idnow.de/' + this.identificationCode;
            this.sanitizer.bypassSecurityTrustResourceUrl(this.page);
            this.trustUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.page);
        }

        this.currentYear = new Date().getFullYear();
    }
}
