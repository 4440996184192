import { Injectable } from '@angular/core';
import { ApiConnectorService } from '../api-connector/api-connector.service';
import { AdditionalCostRuleEntity } from 'data-structures/lib/es6/entity/additional-cost/rules/rules.entity';
import { ExtendedAdditionalCost } from '../../entities/extended-addition-cost';
import { AuthenticationService } from '../authentication/authentication.service';
import { OwnerTypeEnum } from 'data-structures/lib/es6/dto/owner/put-owner/owner-settings.dto';

@Injectable({
    providedIn: 'root',
})
export class AdditionalCostService {
    constructor(readonly apiConnectorService: ApiConnectorService, readonly authenticationService: AuthenticationService) {}

    standardAllowedValues(): { [id: string]: any } {
        return {
            obligation: [0, 1, 2, 3, 4, 8, 9],
            interval: ['ko', 'wo', 'ta', 'nv'],
            unit: [
                '',
                'BETT',
                'EINHEIT',
                'GASFLASCHE',
                'GB',
                'HAUSTIER',
                'KILO',
                'KUBIKMETER',
                'KWH',
                'LITER',
                'NACHT',
                'NUTZUNG',
                'PAKET',
                'PERSON',
                'PERSWECHSL',
                'RAUMMETER',
                'STUECK',
                'STUNDE',
                'WECHSEL',
            ],
            local: [true, false],
            order: [true, false],
            price: true,
            percent: true,
            dailyRental: true,
            min: true,
            max: true,
        };
    }

    applyAdditionalCostRules(allowedValues, additionalCost: ExtendedAdditionalCost, additionalCostRules: AdditionalCostRuleEntity[], isNew: boolean = false): { allowedValues: any; errors: string[] } {
        const errors: any[] = [];

        for (const rule of additionalCostRules) {
            if (this.ifRulesTrue(rule.if, additionalCost)) {
                this.reduceAllowedValuesByThen(rule.then, allowedValues, errors, rule, additionalCost, isNew);
            }
        }

        if (['KAUTIONHAU', 'KURTAXEHU', 'ENDREINHT'].includes(additionalCost.additionalCost)) {
            if (!allowedValues.obligation?.length) {
                allowedValues.obligation = [2];
            }

            allowedValues.local = [1];
        }

        // VRMB-3389, ergänze obligation 9 bei UMWELT, falls sie nicht existiert
        if (additionalCost.additionalCost === 'UMWELT' && !allowedValues.obligation?.includes(9)) {
            allowedValues.obligation.push(9);
        }

        return { allowedValues, errors };
    }

    // Then Rule kann ein Objekt sein oder ein String :(
    reduceAllowedValuesByThen(thenRule: any, allowedValues: { [p: string]: any }, errors: any[], rule: any, additionalCost: ExtendedAdditionalCost, isNew: boolean = false) {
        if (typeof thenRule === 'string') {
            errors.push(thenRule);
        }

        if (typeof thenRule !== 'string') {
            for (const [thenKey, thenValue] of Object.entries(thenRule)) {
                if (thenKey.startsWith('hide')) {
                    const realKey = thenKey.substring(4, 5).toLowerCase() + thenKey.substring(5);
                    allowedValues[realKey] = [thenValue];
                } else if (thenKey.startsWith('set')) {
                    const realKey = thenKey.substring(3, 4).toLowerCase() + thenKey.substring(4);
                    if (realKey === 'local' && isNew) {
                        additionalCost.hasToBePaidLocal = true;
                    }
                } else {
                    // Werte werden scheinbar immer nur reduziert, nicht gesetzt order erweitert so wie hier:
                    // allowedValues[thenKey] = thenValue;

                    if (allowedValues[thenKey] !== undefined && typeof allowedValues[thenKey] !== 'boolean') {
                        allowedValues[thenKey] = allowedValues[thenKey].filter((element) => {
                            // @ts-ignore
                            return thenValue.includes(element);
                        });
                    } else {
                        if (allowedValues[thenKey] !== false) {
                            allowedValues[thenKey] = thenValue;
                        }
                    }
                }
            }
        }
    }

    ifRulesTrue(ifRules: { [id: string]: any }, additionalCost: ExtendedAdditionalCost) {
        for (let [type, allowedValues] of Object.entries(ifRules)) {
            let invert = false;

            if (type.endsWith('Not')) {
                type = type.substring(0, type.length - 3);
                invert = true;
            }

            // hier scheint es den "Not"-Fall nicht zu geben
            if (['ownerType', 'eDomizil', 'hasClient'].includes(type)) {
                if (!allowedValues.includes(this.getTransformedValueForTypeFromOwner(type))) {
                    return false;
                }
            } else {
                const checkValue = this.getValueForType(type, additionalCost);

                if (checkValue !== undefined) {
                    if (!invert && !allowedValues.includes(checkValue)) {
                        return false;
                    }

                    if (invert && allowedValues.includes(checkValue)) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    getValueForType(type: string, additionalCost: ExtendedAdditionalCost) {
        const value = additionalCost[type];

        if (value && ['obligation', 'price', 'percent', 'dailyRental'].includes(type) && typeof value === 'string') {
            return Number(value);
        }

        if (!value) {
            switch (type) {
                case 'unit':
                    if (!value) return '';
                    break;
                case 'obligation':
                case 'price':
                case 'percent':
                case 'dailyRental':
                    return 0;
            }
        }

        return value;
    }

    getTransformedValueForTypeFromOwner(type: string): any {
        switch (type) {
            case 'ownerType':
                const ownerType = this.authenticationService.currentUser.settings?.ownerType;

                if (ownerType === undefined) {
                    return 'EIGENTUEMER';
                }

                // andere Fälle gibts derzeit nicht
                switch (ownerType) {
                    case OwnerTypeEnum.PrivateOwner:
                        return 'EIGENTUEMER';
                }

                break;

            // Wenn currentUser.settings?.client irgendwie gesetzt ist, dann muss diese Regel greifen, egal was drin steht (außer null und undefined)
            case 'eDomizil':
                const client = this.authenticationService.currentUser.settings?.client;
                return client !== null && client !== undefined ? 1 : 0;
                break;

            case 'hasClient':
                return this.authenticationService.currentUser.settings?.client ? 1 : 0;
        }

        return null;
    }
}
