import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import {SortEnum} from 'data-structures/lib/es6/dto/rating/owner/find-rating-sort-dto';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-sort-arrows',
    templateUrl: './sort-arrows.component.html',
    styleUrls: ['./sort-arrows.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SortArrowsComponent),
            multi: true
        }
    ]
})
export class SortArrowsComponent implements OnInit, ControlValueAccessor {
    direction: SortEnum | null;
    @Input() translationKey: string;
    @Input() translation: string;

    constructor() {
    }

    ngOnInit(): void {
    }

    onChange: any = () => {
    };
    onTouched: any = () => {
    };

    writeValue(obj: any): void {
        this.direction = obj;
    }

    get value() {
        return this.direction;
    }

    set value(v) {
        this.direction = v;
        this.onChange(this.direction);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    changeDirection() {
        if (this.direction === undefined || this.direction === null) {
            this.direction = SortEnum.Asc;
        } else if (this.direction === SortEnum.Asc) {
            this.direction = SortEnum.Desc
        } else {
            this.direction = null;
        }

        this.onChange(this.direction);
    }
}
