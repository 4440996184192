<div>
    <select [multiple]="multipleAccommodationGroups"
            id="{{ idKey }}"
            class="{{ classKey }}"
            data-actions-box="true"
            data-live-search="true"
            [attr.data-header]="'text.arrival.type' | translate"
            [attr.data-select-all-text]= "'select.all' | translate"
            [attr.deselect-all-text]= "'select.none' | translate"
            [attr.data-none-selected-text]= "noneSelectedDisplayAll ? ('atraveo.accommodationbundle.group.overview.title' | translate) : ('select.none' | translate)"
            [(ngModel)]="selectedAccommodationGroups"
            [ngModelOptions]="{standalone: true}"
    >
        <ng-container *ngIf="accommodationGroups">
            <option *ngIf="addEmptyOption" [ngValue]="null">{{ 'select.none' | translate }}</option>
            <option *ngFor="let accommodationGroup of this.accommodationGroups" [ngValue]="accommodationGroup.accommodationGroupId" value="{{ accommodationGroup.accommodationGroupId }}">
                {{ accommodationGroup.accommodationGroupId }}
                <span *ngIf="accommodationGroup.name">
                    ({{accommodationGroup.name}})
                </span>
            </option>
        </ng-container>
    </select>
</div>
