import { Component, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../../../../entities/extendedAccommodationEntity';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { AccommodationUpdateService } from '../../../../../services/accommodation-update.service';
import { ApiConnectorService } from '../../../../../services/api-connector/api-connector.service';
import { cloneDeep } from 'lodash';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';

class ErrorMessage {
    link: string;
    message: string;
}

@Component({
    selector: 'app-errors',
    templateUrl: './errors.component.html',
    styleUrls: ['./errors.component.scss'],
})
export class ErrorsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() accommodation: ExtendedAccommodationEntity;
    @Input() isAccommodationList: boolean;
    errors: ErrorMessage[] = [];
    ownerServiceErrors: ErrorMessage[] = [];
    translationServiceSubscription: Subscription;

    constructor(
        readonly translationService: TranslateService,
        readonly authService: AuthenticationService,
        readonly accommodationUpdateService: AccommodationUpdateService,
        readonly apiConnectorService: ApiConnectorService,
    ) {
        this.translationServiceSubscription = this.translationService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.errors = await this.getErrorTexts();
            this.ownerServiceErrors = await this.getErrorTexts(true);
        });
    }

    async ngOnInit() {
        this.ngOnChanges();
    }

    async ngOnDestroy() {
        this.translationServiceSubscription?.unsubscribe();
    }

    async ngOnChanges() {
        if (this.accommodation) {
            this.accommodation = cloneDeep(this.accommodation);
            this.errors = await this.getErrorTexts();
            this.ownerServiceErrors = await this.getErrorTexts(true);
        }
    }

    private async getTranslatedLanguages(languages: string[]): Promise<string[]> {
        const languageTranslations = await this.translationService.get('intl.language.name').toPromise();
        return languages.map((language) => languageTranslations[language]);
    }

    async getErrorTexts(onlyForOwnerService: boolean = false): Promise<any[]> {
        const errors = [];

        // reset, damit die UI die Änderung mitbekommt
        if (!onlyForOwnerService) {
            this.errors = errors;
        } else {
            this.ownerServiceErrors = errors;
        }

        if (!this.accommodation.checkResults?.length) {
            return errors;
        }

        const filteredResults = this.accommodation.checkResults.filter((result) => (onlyForOwnerService ? result.type === 'ownerService' : result.type !== 'ownerService'));

        if (!filteredResults?.length) {
            return errors;
        }

        for (const [id, value] of Object.entries(filteredResults)) {
            const returnValue: ErrorMessage = new ErrorMessage();
            let errorKey = '';

            if (value.checkId !== 55) {
                errorKey = 'atraveo.accommodationbundle.check.error.' + value.checkId;
            } else {
                errorKey = 'atraveo.accommodationbundle.check.error.' + value.checkId + '.0';
            }

            let errorText = await this.translationService.get(errorKey).toPromise();

            if ([1, 41].includes(value.checkId)) {
                const additionalErrorInfo = value[value.checkId === 1 ? 'descriptionNotLongEnoughInLanguages' : 'descriptionInWrongLanguage'] ?? [];
                if (additionalErrorInfo.length) {
                    errorText += ' ' + (await this.getTranslatedLanguages(additionalErrorInfo)).join(', ');
                }
            }
            if (value.checkId === 10) {
                const formatedDaysWithouthPrice = [];
                let groupOfDaysWithouthPrice = [];

                value.daysWithoutPrice.forEach((dayWithoutPrice, index) => {
                    groupOfDaysWithouthPrice.push(formatDate(dayWithoutPrice, 'shortDate', this.translationService.currentLang));
                    if (index % 2 !== 0) {
                        const formatedDayWithouthPrice = groupOfDaysWithouthPrice.join(' - ');
                        formatedDaysWithouthPrice.push(formatedDayWithouthPrice);
                        groupOfDaysWithouthPrice = [];
                    }
                });

                errorText += ' ' + formatedDaysWithouthPrice.join(', ');

                if ('moreErrors' in value) {
                    errorText += ' ' + (await this.translationService.get('error.message.morePeriods').toPromise());
                }
            }

            // In den Fehlertexten sind links drin, die müssen wir umbiegen
            const $errorText = $('<span>' + errorText + '</span>');
            const $link = $errorText.find('a');
            if ($link.length) {
                const oldLink: string = $link.attr('href');
                const mapping = {
                    accommodation_main: 'main',
                    accommodation_position_change: 'position',
                    accommodation_price_change: 'prices',
                    accommodation_calendar_change: 'calendar',
                    accommodation_feature_change: 'features',
                    accommodation_description_change: 'description',
                    accommodation_media_change: 'images',
                    profile: 'profile',
                };

                returnValue.link = oldLink;

                for (const key in mapping) {
                    if (oldLink.includes(key)) {
                        returnValue.link = mapping[key];
                    }
                }
            }

            $link.replaceWith(function () {
                return '<u>' + $(this).text() + '</u>';
            });

            returnValue.message = $errorText.html();

            errors.push(returnValue);
        }

        return errors;
    }
}
