import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
    selector: 'app-display-rating-icons',
    templateUrl: './display-rating-icons.component.html',
    styleUrls: ['./display-rating-icons.component.scss']
})
export class DisplayRatingIconsComponent implements OnInit, OnChanges {
    @Input() rating: number; // 0 - 5
    fullStars: any[] = []; // Man kann in Angular keine normalen For-Schleifen machen, deswegen array :(
    emptyStars: any[] = [];
    maxRating: number = 5;
    iconFull = 'fas fa-home solid';
    iconEmpty = 'fas fa-home'
    isHalfRating: boolean = false;

    constructor() {}

    ngOnInit(): void {
        if (!Number.isNaN(this.rating)) {
            this.fullStars = new Array(Math.floor(this.rating));
            if (this.rating % 1 === 0.5) {
                this.isHalfRating = true;
                this.emptyStars = new Array(this.maxRating - (this.fullStars.length + 1));
            } else {
                this.emptyStars = new Array(this.maxRating - this.fullStars.length);
            }
        }
    }

    ngOnChanges(): void {
        this.fullStars = [];
        this.emptyStars = [];
        this.isHalfRating = false;
        this.ngOnInit();
    }
}
