<div *ngIf="accommodationGroup">
    <div class="row">
        <div class="col">
            <h1>{{ 'atraveo.accommodationbundle.group.overview.title' | translate }}</h1>
        </div>
    </div>

    <div class="row">
        <div class="col back-to-groups">
            <a routerLink="/accommodation-groups" [queryParams]="{ ownerid: authenticationService.currentUser.ownerNumber }" routerLinkActive="active"
            ><i class="fas fa-chevron-left"></i>{{ 'text.notification.option.back' | translate }}</a
            >
        </div>
    </div>

    <hr />

    <ul class="nav nav-tabs nav-justified" id="accommodation-tab" role="tablist">
        <li class="nav-item">
            <a
                class="nav-link"
                [routerLink]="['/accommodation-group', accommodationGroup?.accommodationGroupId, 'objects']"
                [routerLinkActive]="['active']"
                [ngClass]="selectedTab == 'create' ? 'active' : ''"
                id="assignment-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="objects"
            >
                {{ 'atraveo.accommodationbundle.navigation.assignment.title' | translate }}
            </a>
        </li>
        <li class="nav-item" [class.disabled]="!accommodationGroup?.accommodationGroupId">
            <a
                class="nav-link"
                [class.disabled]="!accommodationGroup?.accommodationGroupId"
                [routerLink]="['/accommodation-group', accommodationGroup?.accommodationGroupId, 'position']"
                [routerLinkActive]="['active']"
                id="position-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="position"
            >
                {{ 'atraveo.accommodationbundle.navigation.position.title' | translate }}
            </a>
        </li>
        <li class="nav-item" [class.disabled]="!accommodationGroup?.accommodationGroupId">
            <a
                class="nav-link"
                [class.disabled]="!accommodationGroup?.accommodationGroupId"
                [routerLink]="['/accommodation-group', accommodationGroup?.accommodationGroupId, 'description']"
                [routerLinkActive]="['active']"
                id="description-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="description"
            >
                {{ 'atraveo.accommodationbundle.navigation.description.title' | translate }}
            </a>
        </li>
        <li class="nav-item" [class.disabled]="!accommodationGroup?.accommodationGroupId">
            <a
                class="nav-link"
                [class.disabled]="!accommodationGroup?.accommodationGroupId"
                [routerLink]="['/accommodation-group', accommodationGroup?.accommodationGroupId, 'images']"
                [routerLinkActive]="['active']"
                [ngClass]="selectedTab == 'image' ? 'active' : ''"
                id="images-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="images"
            >
                {{ 'atraveo.accommodationbundle.navigation.media.title' | translate }}
            </a>
        </li>
    </ul>

    <div class="tab-content d-flex flex-column justify-content-between">
        <div style="order: 1" [routerLink]="['/accommodation-group', accommodationGroup?.accommodationGroupId, ((selectedTab == 'objects') ? 'overview' : 'objects')]" class="nav-item-sm mt-3 mb-0" [class.active]="this.selectedTab == 'assignment'">
            <i class="fa" [ngClass]="this.selectedTab == 'objects' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
            {{ 'atraveo.accommodationbundle.navigation.assignment.title' | translate }}
        </div>
        <div style="order: 3" [routerLink]="['/accommodation-group', accommodationGroup?.accommodationGroupId, ((selectedTab == 'position') ? 'overview' : 'position')]" class="nav-item-sm mt-3 mb-0" [class.active]="this.selectedTab == 'position'">
            <i class="fa" [ngClass]="this.selectedTab == 'position' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
            {{ 'atraveo.accommodationbundle.navigation.position.title' | translate }}
        </div>
        <div style="order: 5" [routerLink]="['/accommodation-group', accommodationGroup?.accommodationGroupId, ((selectedTab == 'description') ? 'overview' : 'description')]" class="nav-item-sm mt-3 mb-0" [class.active]="this.selectedTab == 'description'">
            <i class="fa" [ngClass]="this.selectedTab == 'description' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
            {{ 'atraveo.accommodationbundle.navigation.description.title' | translate }}
        </div>
        <div style="order: 7" [routerLink]="['/accommodation-group', accommodationGroup?.accommodationGroupId, ((selectedTab == 'images') ? 'overview' : 'images')]" class="nav-item-sm mt-3" [class.active]="this.selectedTab == 'images'">
            <i class="fa" [ngClass]="this.selectedTab == 'images' || this.selectedTab == 'image' ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
            {{ 'atraveo.accommodationbundle.navigation.media.title' | translate }}
        </div>
        <div
            class="tab-panel"
            role="tabpanel"
            aria-labelledby="images-tab"
            *ngIf="selectedTab"
            [style]="'order: ' + selectedTabOrder"
        >
            <router-outlet></router-outlet>
        </div>
    </div>

    <div class="clearfix"></div>
    <div class="action-buttons-group">
        <a *ngIf="accommodationGroup?.accommodationGroupId" class="btn btn-primary delete-group" (click)="openConfirmationDialog(accommodationGroup.accommodationGroupId)">
            <i class="fas fa-trash-alt"></i>{{ 'atraveo.accommodationbundle.accommodation.group.delete' | translate }}
        </a>
    </div>

    <div id="delete-group-popover-title" class="hide">
        <p>{{ 'atraveo.accommodationbundle.accommodation.delete.0' | translate }}</p>
    </div>

    <div id="delete-group-popover" class="hide">
        <p>{{ 'confirm.content.delete.0' | translate }}</p>
        <div class="btn-group">
            <div class="btn btn-secondary btn-delete-group">{{ 'text.marketing.teaser.yes' | translate }}</div>
            <div class="btn btn-primary btn-close">{{ 'atraveo.accommodationbundle.description.existAtOtherProvider.choice1' | translate }}</div>
        </div>
    </div>
</div>
