import { Injectable } from '@angular/core';
import { ExtendedAccommodationEntity } from '../../entities/extendedAccommodationEntity';
import { SourceTypeEnum } from 'data-structures/lib/es6/enum/source-type.enum';
import { DescriptionCategory, DescriptionType } from 'data-structures/lib/es6/dto/accommodation/descriptions/description.dto';
import { MinMaxDto } from 'data-structures/lib/es6/dto/accommodation/additional-cost/min-max.dto';
import { IntervalTypes, LocalPayTypes, RefundTypes } from 'data-structures/lib/es6/dto/accommodation/additional-cost/additional-cost.dto';
import { ImageTypeEnum } from 'data-structures/lib/es6/enum/image-type.enum';
import { AuthenticationService } from '../authentication/authentication.service';
import { ApiConnectorService } from '../api-connector/api-connector.service';
import { MediaService } from '../media/media.service';

@Injectable({
    providedIn: 'root',
})
export class AutoCreateObjectDataService {
    constructor(readonly authService: AuthenticationService, readonly apiConnector: ApiConnectorService, readonly mediaService: MediaService) {}

    async getAutoCompletedAccommodation() {
        const dateFrom = new Date();
        const dateTo = new Date();
        dateTo.setFullYear(dateTo.getFullYear() + 1);
        const formattedDateFrom = dateFrom.toISOString().split('T')[0];
        const formattedDateTo = dateTo.toISOString().split('T')[0];
        const accommodation = new ExtendedAccommodationEntity();
        accommodation.addRequiredFields();
        accommodation.position.address = {
            street: ' Peter-Müller-Straße',
            houseNumber: '10',
            city: 'Düsseldorf',
            country: 'Deutschland',
            countryISOCode: 'DE',
            zipCode: '40468',
            region: 'Nordrhein-Westfalen',
            subRegion: 'Düsseldorf',
        };
        accommodation.position.location = {
            latitude: 51.2758962,
            longitude: 6.7707788,
            zoomLimit: false,
        };
        accommodation.main = {
            name: '',
            type: 0,
            maxPersons: 2,
            minChildren: 0,
            livingSpace: 150,
            contingent: 0,
            licenseNumber: '123',
            url: '',
            managerId: '',
            groupName: '',
            additionalData: '',
            stars: 0,
            holidayPark: '',
            checkResultProgressBarTotal: 0,
            classification: '',
            productKey: 0,
        };
        accommodation.features = [
            {
                featureId: 'TIERE',
                value: 1,
                valid: {
                    from: '2020-01-01',
                    to: '2025-12-31',
                    followingYears: false,
                },
                source: SourceTypeEnum.Owner,
                buildingId: 0,
                floor: 0,
                roomId: 1,
                descriptions: undefined,
            },
            {
                value: 1,
                featureId: 'SZ',
                source: SourceTypeEnum.Owner,
                valid: {
                    from: '2020-01-01',
                    to: '2025-12-31',
                    followingYears: false,
                },
                buildingId: 1,
                floor: 1,
                roomId: 1,
                descriptions: undefined,
            },
            {
                value: 1,
                featureId: 'BZ',
                source: SourceTypeEnum.Owner,
                valid: {
                    from: '2020-01-01',
                    to: '2025-12-31',
                    followingYears: false,
                },
                buildingId: 1,
                floor: 1,
                roomId: 1,
                descriptions: undefined,
            },
            {
                featureId: 'KUECHENUTZ',
                value: 1,
                valid: {
                    from: '2020-01-01',
                    to: '2025-12-31',
                    followingYears: false,
                },
                source: SourceTypeEnum.Owner,
                buildingId: 1,
                floor: 1,
                roomId: 1,
                descriptions: undefined,
            },
            {
                featureId: 'INETKABEL',
                value: 1,
                valid: {
                    from: '2020-01-01',
                    to: '2025-12-31',
                    followingYears: false,
                },
                source: SourceTypeEnum.Owner,
                buildingId: 1,
                floor: 1,
                roomId: 1,
                descriptions: undefined,
            },
            {
                roomId: 1,
                value: 1,
                featureId: 'DOPPELBET2',
                source: SourceTypeEnum.Owner,
                valid: {
                    from: '2020-01-01',
                    to: '2025-12-31',
                    followingYears: false,
                },
                buildingId: 1,
                floor: 1,
                descriptions: undefined,
            },
            {
                roomId: 2,
                value: 1,
                featureId: 'WC',
                source: SourceTypeEnum.Owner,
                valid: {
                    from: '2020-01-01',
                    to: '2025-12-31',
                    followingYears: false,
                },
                buildingId: 1,
                floor: 1,
                descriptions: undefined,
            },
        ];
        accommodation.descriptions = [
            {
                type: DescriptionType.caption,
                category: DescriptionCategory.accommodation,
                content: {
                    de: 'Beschreibung Test Objekt',
                },
            },
            {
                type: DescriptionType.complete,
                category: DescriptionCategory.accommodation,
                content: {
                    de: 'Beschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test ObjektBeschreibung Test Objekt',
                },
            },
        ];
        accommodation.priceInformation = {
            arrivalDays: 'NYYNNNN',
            departureDays: 'NYYNNNN',
            minDuration: 1,
            minOccupancy: 1,
            exceptions: [],
            createDayIsArrivalDayCache: () => {},
            dayIsArrivalDay: () => false,
            getInformationForDateRange: () => [],
        };

        accommodation.dailyPriceRules = [
            {
                travelPeriod: {
                    from: formattedDateFrom,
                    to: formattedDateTo,
                    followingYears: true,
                    periodType: 0,
                    seasonId: 0,
                },
                prices: [
                    {
                        price: 350,
                        currency: 'EUR',
                        maxPersons: 0,
                        weekEndPrice: 0,
                        pricePerAdditionalPerson: 0,
                    },
                ],
                id: 1,
            },
        ];

        accommodation.additionalCosts = [
            {
                id: '2399f1b6-990f-446d-b8ef-2cdce8d7f4a8',
                additionalCost: 'BETTWAESCH',
                currency: 'EUR',
                hasToBeOrdered: false,
                hasToBePaidLocal: false,
                obligation: 9,
                index: 0,
                deletable: false,
                backPay: '',
                calcAges: new MinMaxDto(),
                calcDays: new MinMaxDto(),
                calcPersons: new MinMaxDto(),
                dailyRental: 0,
                refundType: RefundTypes.LOCAL_CASH,
                paymentType: LocalPayTypes.LOCAL_CASH,
                descriptions: undefined,
                duration: new MinMaxDto(),
                interval: IntervalTypes.ONCE,
                maxPieceCount: 0,
                maxPrice: 0,
                minPrice: 0,
                percent: 0,
                occupancy: new MinMaxDto(),
                price: 0,
                travelPeriod: {
                    from: '2020-01-01',
                    to: '2025-12-31',
                    followingYears: false,
                },
                unit: '',
                source: '',
            },
            {
                id: 'ed2bccfd-5126-46d3-a947-afe96f7d0137',
                additionalCost: 'ENDREIN',
                currency: 'EUR',
                hasToBeOrdered: false,
                hasToBePaidLocal: false,
                obligation: 9,
                index: 0,
                deletable: false,
                backPay: '',
                calcAges: new MinMaxDto(),
                calcDays: new MinMaxDto(),
                calcPersons: new MinMaxDto(),
                dailyRental: 0,
                refundType: RefundTypes.LOCAL_CASH,
                paymentType: LocalPayTypes.LOCAL_CASH,
                descriptions: undefined,
                duration: new MinMaxDto(),
                interval: IntervalTypes.ONCE,
                maxPieceCount: 0,
                maxPrice: 0,
                minPrice: 0,
                percent: 0,
                occupancy: new MinMaxDto(),
                price: 0,
                travelPeriod: {
                    from: '2020-01-01',
                    to: '2025-12-31',
                    followingYears: false,
                },
                unit: '',
                source: '',
            },
            {
                id: '9bfd2269-057a-423a-adaf-3f43a3e14110',
                additionalCost: 'KURTAXE',
                currency: 'EUR',
                hasToBeOrdered: false,
                hasToBePaidLocal: false,
                obligation: 9,
                index: 0,
                deletable: false,
                backPay: '',
                calcAges: new MinMaxDto(),
                calcDays: new MinMaxDto(),
                calcPersons: new MinMaxDto(),
                dailyRental: 0,
                refundType: RefundTypes.LOCAL_CASH,
                paymentType: LocalPayTypes.LOCAL_CASH,
                descriptions: undefined,
                duration: new MinMaxDto(),
                interval: IntervalTypes.ONCE,
                maxPieceCount: 0,
                maxPrice: 0,
                minPrice: 0,
                percent: 0,
                occupancy: new MinMaxDto(),
                price: 0,
                travelPeriod: {
                    from: '2020-01-01',
                    to: '2025-12-31',
                    followingYears: false,
                },
                unit: '',
                source: '',
            },
            {
                id: '8abac58b-002a-46ed-8fbb-be9679d521fd',
                additionalCost: 'ENERGIE',
                currency: 'EUR',
                hasToBeOrdered: false,
                hasToBePaidLocal: false,
                obligation: 9,
                index: 0,
                deletable: false,
                backPay: '',
                calcAges: new MinMaxDto(),
                calcDays: new MinMaxDto(),
                calcPersons: new MinMaxDto(),
                dailyRental: 0,
                refundType: RefundTypes.LOCAL_CASH,
                paymentType: LocalPayTypes.LOCAL_CASH,
                descriptions: undefined,
                duration: new MinMaxDto(),
                interval: IntervalTypes.ONCE,
                maxPieceCount: 0,
                maxPrice: 0,
                minPrice: 0,
                percent: 0,
                occupancy: new MinMaxDto(),
                price: 0,
                travelPeriod: {
                    from: '2020-01-01',
                    to: '2025-12-31',
                    followingYears: false,
                },
                unit: '',
                source: '',
            },
            {
                id: '8f25914d-bc28-40a1-a800-eb2886564249',
                additionalCost: 'HANDTUCH',
                currency: 'EUR',
                hasToBeOrdered: false,
                hasToBePaidLocal: false,
                obligation: 9,
                index: 0,
                deletable: false,
                backPay: '',
                calcAges: new MinMaxDto(),
                calcDays: new MinMaxDto(),
                calcPersons: new MinMaxDto(),
                dailyRental: 0,
                refundType: RefundTypes.LOCAL_CASH,
                paymentType: LocalPayTypes.LOCAL_CASH,
                descriptions: undefined,
                duration: new MinMaxDto(),
                interval: IntervalTypes.ONCE,
                maxPieceCount: 0,
                maxPrice: 0,
                minPrice: 0,
                percent: 0,
                occupancy: new MinMaxDto(),
                price: 0,
                travelPeriod: {
                    from: '2020-01-01',
                    to: '2025-12-31',
                    followingYears: false,
                },
                unit: '',
                source: '',
            },
            {
                id: 'dce72878-7a98-43fd-a010-d190f114af0d',
                additionalCost: 'KAUTION',
                obligation: 8,
                hasToBePaidLocal: false,
                currency: 'EUR',
                index: 0,
                deletable: false,
                backPay: '',
                calcAges: new MinMaxDto(),
                calcDays: new MinMaxDto(),
                calcPersons: new MinMaxDto(),
                dailyRental: 0,
                refundType: RefundTypes.LOCAL_CASH,
                paymentType: LocalPayTypes.LOCAL_CASH,
                descriptions: undefined,
                duration: new MinMaxDto(),
                hasToBeOrdered: false,
                interval: IntervalTypes.ONCE,
                maxPieceCount: 0,
                maxPrice: 0,
                minPrice: 0,
                percent: 0,
                occupancy: new MinMaxDto(),
                price: 0,
                travelPeriod: {
                    from: '2020-01-01',
                    to: '2025-12-31',
                    followingYears: false,
                },
                unit: '',
                source: '',
            },
        ];

        for (const feature of accommodation.features) {
            delete feature.buildingId;
            delete feature.floor;
        }

        delete accommodation.priceInformation.createDayIsArrivalDayCache;
        delete accommodation.priceInformation.dayIsArrivalDay;
        delete accommodation.priceInformation.getInformationForDateRange;

        for (const additionalCost of accommodation.additionalCosts) {
            delete additionalCost.unit;
            delete additionalCost.backPay;
        }

        accommodation.ownerNumber = this.authService.currentUser.ownerNumber;

        return accommodation;
    }

    async createImagesForSelectedAccommodation(accommodation: ExtendedAccommodationEntity) {
        const imagePaths = [
            'assets/img/accommodations/ferienhaus1.jpg',
            'assets/img/accommodations/ferienhaus2.jpg',
            'assets/img/accommodations/ferienhaus3.jpg',
            'assets/img/accommodations/ferienhaus4.jpg',
            'assets/img/accommodations/ferienhaus5.jpg',
        ];

        for (const imagePath of imagePaths) {
            const fileName = imagePath.split('/').pop() || '';
            if (!fileName) return;

            const file = await this.convertToFile(imagePath, fileName);

            if (this.mediaService.isSuitable(file, ImageTypeEnum.Accommodation)) {
                const formData = new FormData();
                formData.append('file', file, file.name);
                const urlPart = 'accommodation?accommodationId=' + Number(accommodation.accommodationId) + '&ownerNumber=' + Number(this.authService.currentUser.ownerNumber);
                await this.apiConnector.uploadData(urlPart, formData);
            }
        }
        await this.apiConnector.saveAccommodation(accommodation, true, true, true);
    }

    async convertToFile(imagePath: string, fileName: string): Promise<File> {
        const response = await fetch(imagePath);
        const blob = await response.blob();
        return new File([blob], fileName, { type: blob.type });
    }
}
