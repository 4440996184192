import { TravelPeriodDto } from 'data-structures/lib/es6/dto/accommodation/price-rule/travel-period.dto';
import { Exclude } from 'class-transformer';
import { DailyPriceRuleDto } from 'data-structures/lib/es6/dto/accommodation/price-rule/daily-price-rule.dto';

export class ExtendedDailyPriceRule extends DailyPriceRuleDto {
    @Exclude()
    id: number;

    constructor() {
        super();
        this.travelPeriod = new TravelPeriodDto();
    }
}
