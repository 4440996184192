<p>{{ 'atraveo.accommodationbundle.group.add.accommodations' | translate }}</p>

<button class="btn-group">
    <button type="button" class="multiselect dropdown-toggle btn btn-select form-control" data-toggle="dropdown">
        <span class="multiselect-selected-text" *ngIf="accommodationGroup.groupAccommodationIds && accommodationGroup.groupAccommodationIds.length === 0">
            {{ objectSelected }} {{ selected | translate }} {{ allSelected }}
        </span>
        <span class="multiselect-selected-text" *ngIf="accommodationGroup.groupAccommodationIds && accommodationGroup.groupAccommodationIds.length > 0">
            {{ accommodationGroup.groupAccommodationIds.length }} {{ 'select.n' | translate }}
        </span>
        <b class="caret"></b>
    </button>
    <ul class="dropdown-menu" (click)="$event.stopPropagation()">
        <li class="multiselect-item multiselect-all">
            <a class="multiselect-all">
                <label class="checkbox">
                    <input type="checkbox" class="objectCheckAll" value="multiselect-all" (click)="changeStateAll($event)" />
                    {{ 'select.all' | translate }}
                </label>
            </a>
        </li>
        <li *ngFor="let accommodation of accommodations | textSearch: objectSearch">
            <a>
                <label
                    class="checkbox"
                    [ngClass]="{ active: accommodationGroup.groupAccommodationIds ? accommodationGroup.groupAccommodationIds?.indexOf(convertToNumber(accommodation.accommodationId)) > -1 : false }"
                >
                    <input
                        type="checkbox"
                        class="objectCheck"
                        [checked]="accommodationGroup.groupAccommodationIds ? accommodationGroup.groupAccommodationIds?.indexOf(convertToNumber(accommodation.accommodationId)) > -1 : false"
                        value="{{ accommodation.accommodationId }}"
                        [attr.data-geo]="accommodation?.position?.geo?.cityId"
                        (click)="changeState($event)"
                    />
                    {{ accommodation.accommodationId }}
                </label>
            </a>
        </li>
    </ul>
</button>

<div class="error group-geo-error hide">{{ 'atraveo.accommodationbundle.validation.group.cities.different' | translate }}</div>

<form id="accommodationGroupForm">
    <ul class="list-unstyled" id="selected-object-list">
        <li *ngFor="let groupAccommodationId of accommodationGroup.groupAccommodationIds" class="group-object">{{ groupAccommodationId }}</li>
    </ul>

    <a class="btn btn-primary remove-objects" (click)="removeObjects()"><i class="fas fa-undo-alt"></i> {{ 'button.reset.0' | translate }}</a>
    <br /><br />

    <a (click)="saveAccommodationGroup()" class="btn btn-success mb-3"><i class="fas fa-cloud-upload-alt mr-2"></i>{{ 'button.save' | translate }}</a>
</form>

