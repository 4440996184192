import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ArrivalInformationEntity, FromToTime } from 'data-structures/lib/es6/entity/arrival-information/arrival-information.entity';
import { ApiConnectorService } from '../../../../services/api-connector/api-connector.service';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { PutArrivalInformationDto } from 'data-structures/lib/es6/dto/arrival-information/put-arrival-information.dto';
import { SortEnum } from 'data-structures/lib/es6/dto/rating/owner/find-rating-sort-dto';
import { GetArrivalRequestDto } from 'data-structures/lib/es6/dto/arrival-information/getArrivalRequest.dto';
import { TranslateService } from '@ngx-translate/core';
import { LanguageDto } from 'data-structures/dto/common/language.dto';
import { ExtendedArrivalEntity } from '../../../../entities/extended-arrival-entity';
import { plainToInstance } from 'class-transformer';
import { CreateArrivalInformationComponent } from './create-arrival-information/create-arrival-information.component';
import { ConfirmationDialogService } from '../../../global/confirmation-dialog/confirmation-dialog.service';
import { DateService } from '../../../../services/date/date.service';
import { KeyHandoverDto, KeyHandoverType } from 'data-structures/lib/es6/dto/arrival-information/key-handover.dto';
import { cloneDeep } from 'lodash';
import { AccommodationAjaxSearchComponent } from '../../../global/accommodation-ajax-search/accommodation-ajax-search.component';
import { DialogService } from '../../../../services/dialog.service';
import { ArrivalInformationDialogComponent } from '../../../global/dialogs/arrival-information-dialog/arrival-information-dialog.component';
declare const $: any;

@Component({
    selector: 'app-arrival-information',
    templateUrl: './arrival-information.component.html',
    styleUrls: ['./arrival-information.component.scss'],
})
export class ArrivalInformationComponent implements OnInit {
    arrivalInformations: ExtendedArrivalEntity[] = [];
    emptyArrivalInformation: ArrivalInformationEntity = new ExtendedArrivalEntity();
    page: number = 1;
    resultsPerPage = 20;
    sort: SortEnum = null;
    arrivalCount: number;
    @ViewChild(AccommodationAjaxSearchComponent) ajaxSearch: AccommodationAjaxSearchComponent;
    showFilter = false;
    filterDto = new GetArrivalRequestDto();

    constructor(
        readonly apiConnector: ApiConnectorService,
        readonly authService: AuthenticationService,
        readonly translateService: TranslateService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly ngZone: NgZone,
        readonly dialogService: DialogService,
    ) {}

    async ngOnInit() {
        this.createEmptyArrivalInformation();
        await this.load(this.page);
    }

    private createEmptyArrivalInformation() {
        this.emptyArrivalInformation = new ArrivalInformationEntity();
        this.emptyArrivalInformation.createDate = DateService.getCurrentDateString();
        this.emptyArrivalInformation.arrival = new FromToTime();
        this.emptyArrivalInformation.departure = new FromToTime();
        this.emptyArrivalInformation.keyHandover = new KeyHandoverDto();
        this.emptyArrivalInformation.keyHandover.type = KeyHandoverType.Accommodation;
    }

    async geoCheck(arrivalInformation: any) {
        if (!arrivalInformation[0]?.conditions.cityId) {
            delete arrivalInformation[0].conditions.cityId;
        }
        if (!arrivalInformation[0]?.conditions.countryId) {
            delete arrivalInformation[0].conditions.countryId;
        }
        if (!arrivalInformation[0]?.conditions.regionId) {
            delete arrivalInformation[0].conditions.regionId;
        }
    }

    async save(child: CreateArrivalInformationComponent) {
        const validated = child.validate();

        if (!validated) {
            return;
        }

        const putArrivalInformation = new PutArrivalInformationDto();

        putArrivalInformation.arrivalInformation = [this.emptyArrivalInformation];
        await this.geoCheck(putArrivalInformation.arrivalInformation);
        await this.apiConnector.putArrivalInformation(this.authService.currentUser.ownerNumber, putArrivalInformation);

        await this.load(this.page);
        this.createEmptyArrivalInformation();
        this.dialogService.closeDialog();
    }

    async load(page: number) {
        this.page = page;

        this.filterDto.skip = this.resultsPerPage * (page - 1);
        this.filterDto.take = this.resultsPerPage;
        this.filterDto.sortDate = this.sort;
        this.filterDto.includeNames = true;

        if (this.filterDto.accommodationId) {
            this.filterDto.accommodationId = Number(this.filterDto.accommodationId);
        }

        if (this.filterDto.countryIsoCode === 'null') {
            this.filterDto.countryIsoCode = null;
        }

        if (this.filterDto.skip < 0) {
            this.filterDto.skip = 0;
        }

        // Die Antwort von der AccommodationGroupSelectComponent ist ein Array, es muss aber im DTO eine Zahl sein
        const filterClone = cloneDeep(this.filterDto);
        if (Array.isArray(filterClone.accommodationGroupId) && filterClone.accommodationGroupId.length) {
            // @ts-ignore
            filterClone.accommodationGroupId = filterClone.accommodationGroupId[0];
        } else {
            filterClone.accommodationGroupId = null;
        }

        const response = await this.apiConnector.getArrivalInformation(this.authService.currentUser.ownerNumber, filterClone);
        this.arrivalCount = response.count;
        this.arrivalInformations = response.result.map((entity) => {
            const arrival = plainToInstance(ExtendedArrivalEntity, entity);
            if (arrival?.conditions?.accommodationIds?.length === 0) {
                arrival.conditions.accommodationIds = [null];
            }
            return arrival;
        });
    }

    async getPdf(id: string) {
        await this.apiConnector.getArrivalPdf(this.authService.currentUser.ownerNumber, id, this.translateService.currentLang);
    }

    createNew() {
        this.emptyArrivalInformation = new ArrivalInformationEntity();
        this.delaySelectPickerRendering();
        this.dialogService.openDialog(
            ArrivalInformationDialogComponent,
            { dialogWidth: this.dialogService.dialogWidth.M },
            {
                emptyArrivalInformation: this.emptyArrivalInformation,
                saveArrivalInfo: this.save.bind(this),
            },
        );
    }

    getTranslation(text: LanguageDto) {
        if (!text) {
            return '';
        }

        if (text[this.translateService.currentLang]) {
            return text[this.translateService.currentLang];
        }

        if (text.de) {
            return text.de;
        }
    }

    async refresh(event) {
        this.sort = event;
        await this.load(this.page);
    }

    setActive(i: number) {
        this.emptyArrivalInformation = this.arrivalInformations[i];
        this.delaySelectPickerRendering();
        this.dialogService.openDialog(
            ArrivalInformationDialogComponent,
            { dialogWidth: this.dialogService.dialogWidth.M },
            {
                emptyArrivalInformation: this.emptyArrivalInformation,
                saveArrivalInfo: this.save.bind(this),
            },
        );
    }

    delaySelectPickerRendering() {
        // Rendern des selectpicker verzögern um auf DOM zu warten

        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                ($('.selectpicker') as any).selectpicker('render');
                ($('.selectpicker') as any).selectpicker('refresh');
            }, 50);
        });
    }

    openConfirmationDialog(arrivalInformation: ExtendedArrivalEntity, $event) {
        this.confirmationDialogService
            .confirm('atraveo.accommodationbundle.price.delete.confirmDeleteSinglePrice', 'confirm.content.delete.0')
            .then(async (confirmed) => {
                if (confirmed) {
                    await this.apiConnector.deleteArrival(this.authService.currentUser.ownerNumber, arrivalInformation.id);
                    $event.target.parentNode.parentNode.parentNode.remove();
                }
            })
            .catch(() => undefined);
    }

    changeShowFilter() {
        this.showFilter = !this.showFilter;
    }

    onSearch($event: any) {
        this.filterDto.accommodationId = Number($event);
    }

    async resetFilter() {
        this.filterDto.accommodationId = null;
        this.filterDto.accommodationGroupId = null;
        this.filterDto.countryIsoCode = null;
        this.ajaxSearch.reset();
        await this.load(1);
    }
}
