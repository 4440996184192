<h1>{{ 'atraveo.additionalcosttemplatebundle.overview.title' | translate }}</h1>
<p>{{ 'atraveo.additionalcosttemplatebundle.overview.description' | translate }}</p>

<div *ngIf="additionalCostTemplates.length" id="accordion" class="accordion">
    <div *ngFor="let additionalCostTemplate of additionalCostTemplates; let i = index" [attr.data-index]="i">
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header" id="button{{ i }}">
                        <h2 class="mb-0">
                            <button [attr.aria-expanded]="i == 0 ? true : false" class="btn btn-link" [class.collapsed]="i !== 0" type="button" data-toggle="collapse"
                                    [attr.data-target]="'#collapse' + i" [attr.aria-controls]="'collapse' + i">
                                <i class="fa fa-chevron-down"></i> {{ additionalCostTemplate.templateName }}
                            </button>
                            <div class="float-right">
                                <a class="btn btn-link"
                                   (click)="setCurrentTemplate(additionalCostTemplate)">
                                    <i class="fa fa-pen fa-lg"></i>
                                </a>
                                <a class="btn btn-link p-0" (click)="deleteElement = i; openConfirmationDialog()">
                                    <i class="fa fa-trash-alt fa-lg ml-2"></i>
                                </a>
                            </div>
                        </h2>
                    </div>

                    <div class="description">
                        <span *ngIf="additionalCostTemplate.conditions?.accommodationIds">
                            <ng-container
                                *ngFor="let id of additionalCostTemplate.conditions.accommodationIds; last as isLast;">
                                <a class="routeObject" routerLink="/accommodation/{{ id }}"
                                   routerLinkActive="active">{{ id }}
                                    <span *ngIf="names.accommodation[id]">({{names.accommodation[id]}})</span>
                                </a>
                                <span *ngIf="!isLast">, </span>
                            </ng-container>
                        </span>

                        <span *ngIf="additionalCostTemplate.conditions?.accommodationGroupIds">
                            <ng-container *ngFor="let id of additionalCostTemplate.conditions.accommodationGroupIds">
                                {{ id }}<span *ngIf="names.accommodationGroup[id]">({{ names.accommodationGroup[id] }}
                                )</span>
                            </ng-container>
                        </span>

                        <span
                            *ngIf="additionalCostTemplate.geoName"> {{ additionalCostTemplate.geoName | getTranslation}} </span>
                        <span *ngIf="!additionalCostTemplate.geoName &&
                                     !additionalCostTemplate.conditions?.accommodationGroupIds?.length
                                     && (
                                     (additionalCostTemplate.conditions?.accommodationIds?.length ===1 && additionalCostTemplate.conditions?.accommodationIds[0] === null) || additionalCostTemplate.conditions?.accommodationIds?.length === 0)">
                            {{ 'text.additionalcosttemplate.accommodation.all' | translate }}
                        </span>
                    </div>

                    <div id="collapse{{ i }}" class="collapse" [ngClass]="{ show: i == 0 }"
                         [attr.aria-labelledby]="'button' + i" data-parent="#accordion">
                        <div class="card-body">
                            <app-additional-cost [includeUpperButton]="false" idPrefix="{{ i }}"
                                                 [accommodationOrTemplateInput]="additionalCostTemplate" (onSave)="save($event)"></app-additional-cost>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<button *ngIf="currentTemplate?.conditions?.accommodationIds" class="mt-3 btn btn-primary" (click)="setNewTemplate()">
    {{ 'atraveo.additionalcosttemplatebundle.overview.create.button' | translate }}
</button>