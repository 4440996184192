<div *ngIf="accommodation">
    <div class="row">
        <div *ngIf="authenticationService.currentUser" class="col-md icalInfo mt-3">
            <p>{{ 'atraveo.accommodationbundle.calendar.introduction.first' | translate }}</p>
            <p [innerHTML]=icalInfoHtml></p>
        </div>
    </div>

    <div class="row">
        <div class="col-md">
            <p *ngIf="!authenticationService.currentUser" class="calendar-info-box">{{ 'atraveo.accommodationbundle.fast.advertising.calendar' | translate }}</p>
            <div class="calendar" id="calendar-display"></div>
        </div>
    </div>
    <div class="row text-center">
        <a *ngIf="!buttonClicked" href="javascript:void(0)" class="btn btn-primary show-more" (click)="showMore()">
            <i class="fas fa-sort-down"></i>
            {{ 'atraveo.accommodationbundle.calendar.add.more' | translate }}
        </a>
    </div>
    <hr>

    <div class="row legend legend-symbols">
        <div class="col-sm-2">
            <div><h4>{{ 'atraveo.accommodationbundle.calendar.key.title' | translate }}</h4></div>
        </div>
        <div class="col-sm-2">
            <div class="calendar-key free"></div>
            <div>{{ 'atraveo.accommodationbundle.calendar.key.available' | translate }}</div>
        </div>
        <div class="col-sm-2">
            <div class="calendar-key occupied"></div>
            <div> {{ 'atraveo.accommodationbundle.calendar.key.booked' | translate}}</div>
        </div>
        <div class="col-sm-2">
            <div class="calendar-key closed"></div>
            <div>{{ 'atraveo.accommodationbundle.calendar.key.closed' | translate}}</div>
        </div>
        <div class="col-sm-2">
            <div class="calendar-key no-price"></div>
            <div>{{'atraveo.accommodationbundle.calendar.key.noprice' | translate}}</div>
        </div>
        <div class="col-sm-2">
            <div class="calendar-key arrivalDays"></div>
            <div>{{ 'atraveo.accommodationbundle.calendar.key.arrival' | translate}}</div>
        </div>

        <div class="col info-box d-block d-sm-none text-center mt-4">
            <em>
                {{ "atraveo.accommodationbundle.calendar.priceInfoText" | translate: { currency: ownerCurrency } }}
            </em>
        </div>
    </div>

    <hr>

    <ng-container *ngIf="authenticationService.currentUser && accommodation.vacancy?.importUrls?.length">
        <div class="row">
            <div class="col text-md-left text-center">
                <button class="btn btn-success btn-icon btn-icon-done" (click)="this.updateIcalCalendar()">
                    <i class="fas fa-check"></i>
                    {{ 'atraveo.accommodationbundle.calendar.update.interface' | translate }}
                </button>

                <p class="mt-3 mt-sm-2 last-change-info" *ngIf="accommodation.vacancy.lastImport">
                    {{ 'atraveo.accommodationbundle.calendar.last.update.0' | translate }} {{ accommodation.vacancy.lastImport | date : 'short' }}
                </p>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="authenticationService.currentUser && !accommodation.vacancy?.importUrls?.length">
        <div class="row">
            <div class="col text-md-left text-center">
                <button class="btn btn-success btn-icon btn-icon-done" (click)="this.updateCalendarDate()">
                    <i class="fas fa-check"></i>
                    {{ 'atraveo.accommodationbundle.calendar.update.0' | translate }}
                </button>

                <p class="mt-3 mt-sm-2 last-change-info" *ngIf="accommodation.meta?.lastAvailabilityChange">
                    {{ 'atraveo.accommodationbundle.calendar.last.update.0' | translate }} {{ accommodation.meta.lastAvailabilityChange | date : 'short' }}
                </p>
            </div>
        </div>
    </ng-container>

    <hr>

    <span class="price-info" [innerHTML]="priceInfoHtml"></span>

    <hr>

    <div id="#icalBox">
        <h3 class="mt-4 mb-4">{{ 'text.calendar.synchronisation' | translate }}</h3>

        <div *ngIf="authenticationService.currentUser" class="ical-box">
            <p>{{ 'atraveo.accommodationbundle.calendar.interfaceform.ad' | translate }}</p>

            <ng-container *ngIf="!(this.authenticationService.currentUser.interfaces && this.authenticationService.currentUser.interfaces.vacancy) || this.authenticationService.currentUser.isEmployee(); else hasVacancyImport">
                <button class="btn btn-secondary" (click)="openDialog()">
                    <span *ngIf="!accommodation.vacancy?.importUrls?.length">{{ 'atraveo.accommodationbundle.calendar.interfaceform.button-add' | translate }}</span>
                    <span *ngIf="accommodation.vacancy?.importUrls?.length"> {{ 'atraveo.accommodationbundle.calendar.interfaceform.button-edit' | translate }}</span>
                </button>
            </ng-container>
            <ng-template #hasVacancyImport>
                <button class="btn btn-secondary btn-d" data-toggle="modal" data-target="#icalModal" disabled>
                    {{ 'atraveo.accommodationbundle.calendar.interfaceform.button-add' | translate }}
                </button>
                <p class="error">
                    {{ 'atraveo.accommodationbundle.error.alreadyHasVacancyInterface' | translate }}
                </p>
            </ng-template>

            <p class="ical-instructions" [innerHTML]="'urls.ical.instruction' | translate"></p>
            <ng-container *ngIf="accommodation.vacancy?.importUrls?.length">
                <ng-container *ngFor="let ical of accommodation.vacancy.importUrls">
                    <p class="error" *ngIf="ical.lastHttpStatusCode && [401, 403, 500].includes(ical.lastHttpStatusCode)">
                        {{ 'urls.ical.error' | translate }} <br>
                        {{ ical.url }} <br>
                        {{ 'urls.ical.statusCode' | translate}} {{ ical.lastHttpStatusCode }} <br>
                        {{ 'urls.ical.statusMessage' | translate }} {{ ical.lastHttpStatusMessage }}
                    </p>
                </ng-container>
            </ng-container>
        </div>

        <div *ngIf="authenticationService?.currentUser" class="ical-box">
            <p>{{ 'atraveo.accommodationbundle.calendar.interfaceform.export.ad' | translate }}</p>
            <button class="btn btn-secondary" (click)="getNewIcalToken()" *ngIf="authenticationService.currentUser.isEmployee()">{{ 'atraveo.accommodationbundle.calendar.interfaceform.button-generate' | translate }}</button>
            <span [ngClass]="{ 'd-none': !copyClipboard }" class="copy-clipboard">{{ 'atraveo.accommodationbundle.calendar.interfaceform.copiedtoclipboard' | translate }}</span>
            <div class="input-group mt-3 mb-4">
                <input type="text" [value]="icalUrl" class="form-control">
                <div class="input-group-btn border-gray-thin">
                    <button class="btn btn-outline-primary" (click)="copyUrlToClipboard()">
                        <i class="fa fa-clone"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="calendar-footer">
        <select class="select-month"></select>
    </div>
</div>

<div class="modal fade" role="dialog" tabindex="-1" aria-labelledby="advancedSettingsLabelCalendar" aria-hidden="true" id="advancedSettingsCalendar">
    <div class="modal-dialog modal-lg-max" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 id="advancedSettingsLabelCalendar" class="modal-title">
                    {{ 'atraveo.accommodationbundle.price.set-arrival-days.0' | translate }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <app-advanced-settings></app-advanced-settings>
                </div>
            </div>
        </div>
    </div>
</div>
