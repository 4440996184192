import { Component, forwardRef, Input, NgZone, OnChanges, OnInit } from '@angular/core';
import { ConditionsDto } from 'data-structures/lib/es6/dto/common/conditions.dto';
import { GeoDto } from 'data-structures/lib/es6/dto/accommodation/position/geo.dto';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
declare const $: any;

@Component({
    selector: 'app-conditions-select',
    templateUrl: './conditions-select.component.html',
    styleUrls: ['./conditions-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ConditionsSelectComponent),
            multi: true,
        },
    ],
})
export class ConditionsSelectComponent implements OnInit, OnChanges, ControlValueAccessor {
    @Input() condition: ConditionsDto;
    @Input() multipleAccommodationGroups: boolean = true;
    geoDto: GeoDto = new GeoDto();
    selectedType: 'accommodation' | 'accommodation-group' | 'geo' = 'accommodation';

    constructor(readonly ngZone: NgZone) {}

    ngOnInit(): void {
        if (this.condition) {
            this.geoDto.countryId = this.condition.countryId;
            this.geoDto.bestRegionId = this.condition.regionId;
            this.geoDto.cityId = this.condition.cityId;
        }

        // Manchmal ist es [null]
        if (this.condition?.accommodationIds?.length && this.condition.accommodationIds[0]) {
            this.selectedType = 'accommodation';
        } else if (this.condition?.accommodationGroupIds?.length) {
            this.selectedType = 'accommodation-group';
        } else if (this.condition?.countryId) {
            this.selectedType = 'geo';
        }

        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                // @ts-ignore
                const $selectPickers = $('.selectpicker') as any;
                $selectPickers.selectpicker('render');
                $selectPickers.selectpicker('refresh');
            }, 100);
        });
    }

    ngOnChanges() {
        this.ngOnInit();
    }

    setCurrentCondition(condition: ConditionsDto) {
        this.condition = condition;
        this.ngOnInit();
    }

    onChange: any = () => {};

    onTouched: any = () => {};

    writeValue(obj: any): void {
        this.condition = obj;
    }

    get value() {
        return this.condition;
    }

    set value(v) {
        this.condition = v;
        this.onChange(this.condition);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    updateGeoTriplett() {
        // Geo Triplett hat sich geändert
        this.condition.countryId = this.geoDto.countryId;
        this.condition.regionId = Number(this.geoDto.bestRegionId);
        this.condition.cityId = Number(this.geoDto.cityId);
        this.onChange(this.condition);
    }

    updateType($event) {
        const selected = $event.target.value;
        if (selected === 'accommodation') {
            this.geoDto.countryId = null;
            this.geoDto.bestRegionId = null;
            this.geoDto.cityId = null;
            this.updateGeoTriplett();

            this.condition.accommodationGroupIds = [];
        } else if (selected === 'accommodation-group') {
            this.geoDto.countryId = null;
            this.geoDto.bestRegionId = null;
            this.geoDto.cityId = null;
            this.updateGeoTriplett();
            this.condition.accommodationIds = [];
        } else if (selected === 'geo') {
            this.condition.accommodationGroupIds = [];
            this.condition.accommodationIds = [];
        }
        setTimeout(() => {
            const $selectPickers = $('.selectpicker') as any;
            $selectPickers.selectpicker('render');
            $selectPickers.selectpicker('refresh');
        }, 20);
        this.writeValue(this.condition);

        this.selectedType = selected;
    }
}
