import { Component, Inject, OnDestroy } from '@angular/core';
import { DialogService } from '../../../../services/dialog.service';
import { PausedInfoDto, PausedInfoReasonEnum } from 'data-structures/lib/es6/dto/accommodation/state/paused-info.dto';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExtendedAccommodationEntity } from '../../../../entities/extendedAccommodationEntity';
import { ApiConnectorService } from '../../../../services/api-connector/api-connector.service';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-pause-accommodation-confirmation-dialog',
    templateUrl: './pause-accommodation-confirmation-dialog.component.html',
    styleUrl: './pause-accommodation-confirmation-dialog.component.scss',
})
export class PauseAccommodationConfirmationDialogComponent implements OnDestroy {
    pauseForm: FormGroup;
    pausedInfoReasonEnum = PausedInfoReasonEnum;
    valueChangesSubscription: Subscription;
    accommodation: ExtendedAccommodationEntity;
    placeholderKey: string = '';
    constructor(readonly dialogService: DialogService, readonly apiConnectorService: ApiConnectorService, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.accommodation = data.selectedAccommodation;
        this.apiConnectorService.setActiveAccommodation(cloneDeep(this.accommodation));

        this.pauseForm = new FormGroup<any>({
            customText: new FormControl('', [Validators.required, Validators.minLength(5)]),
            pauseReason: new FormControl('', [Validators.required]),
        });

        this.valueChangesSubscription = this.pauseForm.get('pauseReason').valueChanges.subscribe((value) => {
            this.placeholderKey = value;
        });
    }

    ngOnDestroy() {
        this.valueChangesSubscription.unsubscribe();
    }

    closeDialog() {
        this.dialogService.closeDialog();
    }

    submit() {
        if (this.pauseForm.invalid) {
            this.pauseForm.markAllAsTouched();
            return;
        }
        this.accommodation.state.pausedInfo = new PausedInfoDto();
        this.accommodation.state.pausedInfo.reason = this.pauseForm.get('pauseReason')?.value;
        this.accommodation.state.pausedInfo.customReason = this.pauseForm.get('customText').value;
        this.accommodation.state.pausedInfo.timestamp = new Date();
        this.data.pauseAccommodation(this.accommodation);
    }
}
