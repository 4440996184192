import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-hour-select',
    templateUrl: './hour-select.component.html',
    styleUrls: ['./hour-select.component.scss'],
})
export class HourSelectComponent implements OnInit {
    @Input() hour: string;
    @Input() required: boolean = false;
    @Output() hourChange: EventEmitter<string> = new EventEmitter();
    hourArray: string[] = [];

    constructor() {
        for (let i = 0; i < 24; i++) {
            let hour;
            if (i < 10) {
                hour = '0' + i.toString();
            } else {
                hour = i.toString();
            }
            this.hourArray.push(hour + ':00');
        }
    }

    ngOnInit(): void {}

    changeHour(value: string) {
        this.hourChange.emit(value);
    }
}
