import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

declare const Bloodhound;

@Component({
    selector: 'app-accommodation-ajax-search',
    templateUrl: './accommodation-ajax-search.component.html',
    styleUrls: ['./accommodation-ajax-search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AccommodationAjaxSearchComponent),
            multi: true,
        },
    ],
})
export class AccommodationAjaxSearchComponent implements OnInit, ControlValueAccessor, AfterViewInit {
    @Input() hideLabel: boolean = false;
    @Input() classes: string = 'form-control w-auto d-inline typeahead';
    @Input() fieldRequired: boolean = false;
    @Input() hideIcons: boolean = false;
    @Input() htmlId: string = 'search';
    @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
    @Input() selectedAccommodation: number;

    constructor(readonly authenticationService: AuthenticationService) {}

    onChange: any = () => {};
    onTouched: any = () => {};

    writeValue(obj: any): void {
        this.selectedAccommodation = obj;
    }

    get value() {
        return this.selectedAccommodation;
    }

    set value(v) {
        this.selectedAccommodation = v;
        this.onChange(this.selectedAccommodation);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    ngOnInit(): void {}

    ngAfterViewInit() {
        const ownerNumber = this.authenticationService.currentUser.ownerNumber;
        const dataSource = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.whitespace,
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: 'http://bla.xyz', // Wenn man hier nichts angibt, funktioniert es nicht, obwohl unten die Url wieder überschrieben wird
                prepare: (query, settings) => {
                    settings.url = `${environment.apiUrl}/owner-accommodation-autocompletion/${ownerNumber}/${query}`;
                    settings.headers = {
                        Authorization: `Bearer ${this.authenticationService.currentUser.token}`,
                    };

                    return settings;
                },
            },
        });

        // @ts-ignore
        $('#' + this.htmlId).typeahead(
            {
                hint: true,
                highlight: true,
            },
            {
                name: 'search',
                source: dataSource,
                display: 'accommodationId',
                templates: {
                    suggestion: (data) => {
                        let name = '<div>' + data.accommodationId;
                        if (data.eDomizilId) {
                            name += ' / ' + data.eDomizilId;
                        }
                        if (data.accommodationName) {
                            name += ' (' + data.accommodationName + ')';
                        }
                        name += '</div>';
                        return name;
                    },
                },
            },
        );

        if (this.htmlId === 'search') {
            $('#' + this.htmlId).bind('typeahead:select typeahead:change', (ev, selected) => {
                // Wenn man die Suche nutzt, kommt immer ein Objekt zurück, klickt man dann außerhalb der Suchbox, ist es auf einmal eine Zahl
                if (typeof selected === 'object') {
                    this.writeValue(selected.accommodationId);
                    this.onSearch.emit(selected.accommodationId);
                } else if (typeof selected === 'number') {
                    this.writeValue(selected);
                    this.onSearch.emit(selected);
                }
            });
        }
    }

    reset() {
        this.writeValue(null);
        // @ts-ignore
        $('#search').typeahead('val', '');
    }

    manualChange($event: any) {
        if ($event.target.value) {
            this.selectedAccommodation = Number($event.target.value);
            this.writeValue(this.selectedAccommodation);
            this.onSearch.emit(this.selectedAccommodation);
        }
    }
}
