import { ExtendedPriceInformationException } from './extended-price-information-exception';
import { Type } from 'class-transformer';
import { format, startOfDay } from 'date-fns';
import { PriceInformationDto } from 'data-structures/lib/es6/dto/accommodation';
import { TravelPeriodDto } from 'data-structures/lib/es6/dto/accommodation/price-rule/travel-period.dto';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import { DateService } from '../../services/date/date.service';

let dayIsArrivalDayCache = {};

export class ExtendedPriceInformation extends PriceInformationDto {
    @Type(() => ExtendedPriceInformationException)
    exceptions: ExtendedPriceInformationException[];

    createDayIsArrivalDayCache() {
        dayIsArrivalDayCache = [];

        if (this.exceptions && this.exceptions.length) {
            for (const exception of this.exceptions) {
                const dates = exception.getArrivalDates();
                for (const [date, status] of Object.entries(dates)) {
                    dayIsArrivalDayCache[date] = status;
                }
            }
        }
    }

    dayIsArrivalDay(date: Date): boolean {
        if (!Object.keys(dayIsArrivalDayCache).length) {
            this.createDayIsArrivalDayCache();
        }

        const dateString = format(startOfDay(date), 'yyyy-MM-dd');

        if (dayIsArrivalDayCache.hasOwnProperty(dateString)) {
            return dayIsArrivalDayCache[dateString];
        }

        return ExtendedPriceInformationException.getStatusForDay(date.getDay(), this.arrivalDays);
    }

    getInformationForDateRange(travelPeriod: TravelPeriodDto) {
        const exceptions: ExtendedPriceInformationException[] = [];

        // Standardsatz auch als Exception anlegen:
        const standard = new ExtendedPriceInformationException();
        standard.arrivalDays = this.arrivalDays;
        standard.departureDays = this.departureDays;
        standard.minDuration = this.minDuration;
        standard.minOccupancy = this.minOccupancy;

        exceptions.push(standard);

        if (this.exceptions?.length) {
            for (const exception of this.exceptions) {
                try {
                    if (
                        travelPeriod.from &&
                        travelPeriod.to &&
                        exception.travelPeriod?.from &&
                        exception.travelPeriod?.to &&
                        areIntervalsOverlapping(
                            {
                                start: DateService.getDateFromString(travelPeriod.from),
                                end: DateService.getDateFromString(travelPeriod.to),
                            },
                            {
                                start: DateService.getDateFromString(exception.travelPeriod.from),
                                end: DateService.getDateFromString(exception.travelPeriod.to),
                            },
                            { inclusive: true },
                        )
                    ) {
                        exceptions.push(exception);
                    } else if (travelPeriod?.seasonId && exception.travelPeriod?.seasonId && travelPeriod?.seasonId === exception.travelPeriod?.seasonId) {
                        exceptions.push(exception);
                    }
                } catch (e) {}
            }
        }

        return exceptions;
    }
}
