import { Component, OnChanges, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AuthenticationService } from '../../../../../../services/authentication/authentication.service';
import { OwnerService } from '../../../../../../services/owner-service/owner.service';
import { ConfirmationDialogService } from '../../../../../global/confirmation-dialog/confirmation-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { OwnerConnectorService } from '../../../../../../services/api-connector/owner-connector.service';
import { NotificationService } from '../../../../../../services/notification/notification.service';
import { ApiConnectorService } from '../../../../../../services/api-connector/api-connector.service';
import { v4 as uuidv4 } from 'uuid';
import { RegistrationFormComponent } from '../../registration-form/registration-form.component';
import { cloneDeep } from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { DialogService } from '../../../../../../services/dialog.service';
import { ContactDialogComponent } from '../../../../../global/dialogs/contact-dialog/contact-dialog.component';

@Component({
    selector: 'app-contact-person',
    templateUrl: './contact-person.component.html',
    styleUrls: ['./contact-person.component.scss'],
})
export class ContactPersonComponent implements OnInit, OnChanges {
    contactList: any = [];
    filteredContactList: any = [];
    arrivalInfoContactIds: any;
    managerContactIds: any;
    searchForm: FormGroup;
    toDeleteContacts: string[] = [];
    deleteMultipleInProgress: boolean = false;
    @ViewChildren(RegistrationFormComponent) registrationFormComponents: QueryList<RegistrationFormComponent>;

    constructor(
        readonly authService: AuthenticationService,
        readonly ownerService: OwnerService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly translateService: TranslateService,
        readonly ownerConnector: OwnerConnectorService,
        readonly notificationService: NotificationService,
        readonly apiConnector: ApiConnectorService,
        readonly dialogService: DialogService,
    ) {
        this.searchForm = new FormGroup({
            search: new FormControl(''),
        });
        this.searchForm.controls.search.valueChanges.subscribe((value) => {
            this.filterContacts(value);
        });
    }

    async ngOnInit() {
        this.ngOnChanges();
    }

    async ngOnChanges() {
        const response: any = await this.apiConnector.getContactPersonIds(this.authService.currentUser.ownerNumber);
        this.arrivalInfoContactIds = response.contactPersonIds;

        const responseManager: any = await this.apiConnector.getManagerContactIds(this.authService.currentUser.ownerNumber);
        this.managerContactIds = responseManager.managerIds;
        this.buildContactList();
        this.filterContacts('');
    }

    buildContactList() {
        this.contactList = [];
        const contacts = cloneDeep(this.authService.currentUser.addressBook.contacts);
        const primaryId = this.authService.currentUser.addressBook.primaryId;
        for (const contactId of Object.keys(contacts)) {
            if (contactId === primaryId) {
                continue;
            }
            const contact = {
                spokenLanguages: [],
                email: [],
                ...contacts[contactId],
                contactId,
                deletable: true,
                deletableReason: '',
            };

            switch (contact.salutation) {
                case 'Herr':
                    contact.salutation = 'male';
                    break;
                case 'Frau':
                    contact.salutation = 'female';
                    break;
                case 'Familie':
                    contact.salutation = 'family';
                    break;
                case 'Firma':
                    contact.salutation = 'company';
                    break;
                default: {
                    contact.salutation = 'other';
                    break;
                }
            }

            if (contact.phoneNumbers) {
                contact.phoneNumbers = contact.phoneNumbers.map((phoneNumber) => {
                    return phoneNumber.prefix + phoneNumber.number;
                });
            }
            if (this.arrivalInfoContactIds.includes(contactId)) {
                contact.deletable = false;
                contact.deletableReason = 'text.contact.person.not.deletable';
            } else if (this.managerContactIds.includes(contactId)) {
                contact.deletable = false;
                contact.deletableReason = 'text.contact.manager.not.deletable';
            }

            contact.address = ((contact?.street ? contact.street + ', ' : '') + (contact?.zipCode || '') + ' ' + (contact?.city || '')).trim();
            contact.search =
                (contact?.forename || '') +
                ';' +
                (contact?.surname || '') +
                ';' +
                contact.email.join(';') +
                ';' +
                contact.phoneNumbers.join(';') +
                ';' +
                (contact?.street || '') +
                ';' +
                (contact?.city || '') +
                ';' +
                (contact?.zipCode || '');
            this.contactList.push(contact);
        }
    }

    filterContacts(value: string) {
        this.filteredContactList = this.contactList.filter((contact) => contact.search.toLowerCase().includes(value.toLowerCase()));
    }

    editContact(contactId: string) {
        this.dialogService.openDialog(
            ContactDialogComponent,
            { dialogWidth: this.dialogService.dialogWidth.L },
            {
                contactId,
                deletableReason: this.contactList.find((contact) => contact.contactId === contactId)?.deletableReason,
                reloadOwner: () => this.ngOnInit(),
            },
        );
    }

    async addNewContact() {
        const newContactId = uuidv4();
        this.ownerService.createNewContact(this.authService.currentUser, newContactId);
        this.editContact(newContactId);
    }

    async deleteContact(contactId: string) {
        const clone = cloneDeep(this.authService.currentUser);
        delete clone.addressBook.contacts[contactId];

        // E-mails Korrektur
        for (const contactId of Object.keys(clone.addressBook.contacts)) {
            if (contactId !== clone.addressBook.primaryId && clone.addressBook.contacts[contactId].email) {
                clone.addressBook.contacts[contactId].email = clone.addressBook.contacts[contactId].email.filter((email) => email !== '');

                if (!clone.addressBook.contacts[contactId].email.length) {
                    delete clone.addressBook.contacts[contactId].email;
                }
            }
        }

        await this.authService.saveOwner(clone).then(async () => {
            await this.notificationService.add('text.flashbag.profile.contact.person.deleted', 'success');
            this.ngOnInit();
        });
    }

    async openConfirmationDialog(contactId: string) {
        const deleteIndex = this.contactList.findIndex((contact) => contact.contactId === contactId);
        let customText = (this.contactList[deleteIndex].forename ?? '') + ' ' + (this.contactList[deleteIndex].surname ?? '');

        if (customText === ' ') {
            customText = await this.translateService.get('text.contact.person.0').toPromise();
        }

        this.confirmationDialogService
            .confirm('confirm.title.delete', await this.translateService.get('confirm.content.delete.custom', { custom: customText }).toPromise())
            .then(async (confirmed) => {
                if (confirmed) {
                    await this.deleteContact(contactId);
                }
            })
            .catch(() => undefined);
    }

    addOrRemoveDeleteContact(contactId: string) {
        if (this.deleteMultipleInProgress) {
            if (this.toDeleteContacts.includes(contactId)) {
                this.toDeleteContacts = this.toDeleteContacts.filter((id) => id !== contactId);
            } else {
                if (this.contactList.filter((contact) => contact.contactId === contactId)[0]?.deletable) {
                    this.toDeleteContacts.push(contactId);
                }
            }
        }
    }

    abortDeleteMultiple() {
        this.deleteMultipleInProgress = false;
        this.toDeleteContacts = [];
    }

    deleteMultipleContacts() {
        if (this.toDeleteContacts.length) {
            this.confirmationDialogService.confirm('atraveo.accommodationbundle.delete.confirmDeleteMuliple', 'confirm.content.delete.0').then(async (confirmed) => {
                if (confirmed) {
                    for (const contactId of this.toDeleteContacts) {
                        await this.deleteContact(contactId);
                    }
                    this.deleteMultipleInProgress = false;
                }
            });
        }
    }
}
