<div class="accommodation-change-select">
    <ng-select *ngIf="!control"
                [items]="accommodations"
               bindLabel="accommodationId"
               bindValue="accommodationId"
               [virtualScroll]="true"
               (search)="search($event)"
               (open)="open($event)"
               [loading]="loading"
               (change)="selected($event)"
               (scroll)="onScroll($event)"
               (scrollToEnd)="onScrollToEnd()"
               [searchFn]="customFilter"
               class="accomodation-custom-ng-select"
               placeholder="{{ 'accommodation.selectbox.label' | translate }}"
    >
        <ng-template ng-option-tmp let-accommodation="item" let-index="index" let-search="searchTerm">
            <div class="thumbnail">
                <picture>
                    <ng-container *ngIf="accommodation.media && accommodation.media[0]?.displayUrl">
                        <source srcset="{{ accommodation.media[0]?.displayUrl + '?x=30&y=24&format=webp' }}" type="image/webp" width="30" height="24">
                        <img src="{{ accommodation.media[0]?.displayUrl + '?x=30&y=24&format=webp' }}" width="30" height="24" onerror="this.onerror = null; this.src='assets/img/empty-accommodation.png';" />
                    </ng-container>
                    <img *ngIf="!accommodation.media || !accommodation.media[0]?.displayUrl" src="assets/img/empty-accommodation.png" width="30" height="24" />
                </picture>
<!--               Wenn gelöschte Häuser angezeigt werden sollen, muss das noch gestyled werden -->
<!--                <div class="deleted" *ngIf="accommodation.state?.deleted">-->
<!--                    <i class="fas fa-ban fa-3x"></i>-->
<!--                </div>-->
            </div>
            <span><strong>{{ accommodation.accommodationId }}</strong><span *ngIf="accommodation?.internalId"> / {{ accommodation?.internalId }}</span> {{ accommodation.main?.name ? '(' + accommodation.main?.name + ')': '' }}</span>
        </ng-template>
    </ng-select>
    <ng-select *ngIf="control"
               [items]="accommodations"
               bindLabel="accommodationId"
               bindValue="accommodationId"
               [virtualScroll]="true"
               (search)="search($event)"
               (open)="open($event)"
               [loading]="loading"
               (change)="selected($event)"
               (scroll)="onScroll($event)"
               (scrollToEnd)="onScrollToEnd()"
               [searchFn]="customFilter"
               [formControl]="control"
               class="accomodation-custom-ng-select"
               placeholder="{{ 'accommodation.selectbox.label' | translate }}"
    >
        <ng-template ng-option-tmp let-accommodation="item" let-index="index" let-search="searchTerm">
            <div class="thumbnail">
                <picture>
                    <ng-container *ngIf="accommodation.media && accommodation.media[0]?.displayUrl">
                        <source srcset="{{ accommodation.media[0]?.displayUrl + '?x=30&y=24&format=webp' }}" type="image/webp" width="30" height="24">
                        <img src="{{ accommodation.media[0]?.displayUrl + '?x=30&y=24&format=webp' }}" width="30" height="24" onerror="this.onerror = null; this.src='assets/img/empty-accommodation.png';" />
                    </ng-container>
                    <img *ngIf="!accommodation.media || !accommodation.media[0]?.displayUrl" src="assets/img/empty-accommodation.png" width="30" height="24" />
                </picture>
                <!--               Wenn gelöschte Häuser angezeigt werden sollen, muss das noch gestyled werden -->
                <!--                <div class="deleted" *ngIf="accommodation.state?.deleted">-->
                <!--                    <i class="fas fa-ban fa-3x"></i>-->
                <!--                </div>-->
            </div>
            <span><strong>{{ accommodation.accommodationId }}</strong><span *ngIf="accommodation?.internalId"> / {{ accommodation?.internalId }}</span> {{ accommodation.main?.name ? '(' + accommodation.main?.name + ')': '' }}</span>
        </ng-template>
    </ng-select>
</div>
