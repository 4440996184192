import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@Pipe({
    name: 'getTranslation'
})
export class GetTranslationFromLanguageDtoPipe implements PipeTransform {

    constructor(private readonly translateService: TranslateService) {
    }


    transform(translation: any): string {
        if (this.translateService.currentLang in translation) {
            return translation[this.translateService.currentLang];
        } else if ('en' in translation) {
            return translation.en;
        } else if ('de' in translation) {
            return translation.de;
        } else if (Object.keys(translation).length) {
            return translation[Object.keys(translation)[0]];
        }

        if (environment.stage !== 'prod') {
            return 'Übersetzung für diese Geozurodnung nicht vorhanden.'
        }

        return '';
    }

}
