import { ConditionsDto } from 'data-structures/lib/es6/dto/common/conditions.dto';

export class ExtendedConditions extends ConditionsDto {
    static createEmpty(ownerNumber: number | null): ConditionsDto {
        const newCondition = new ConditionsDto();
        newCondition.ownerNumber = ownerNumber;
        newCondition.accommodationIds = [];
        newCondition.accommodationGroupIds = [];
        return newCondition;
    }
}
