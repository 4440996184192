import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiConnectorService } from '../../../../services/api-connector/api-connector.service';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { HistoryEntity } from 'data-structures/lib/es6/entity/history/history.entity';
import { HistoryFilterDto } from 'data-structures/lib/es6/dto/history/history.filter.dto';
import { format, subDays } from 'date-fns';
import { HistoryComponentEnum } from 'data-structures/lib/es6/enum/history-components.enum';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HistoryComponent implements OnInit {
    history: HistoryEntity[];
    filter: HistoryFilterDto = new HistoryFilterDto();
    components: any[] = []; // Komponenten für die Filter-Selectbox
    endReached: boolean = false;

    constructor(readonly apiConnector: ApiConnectorService, readonly authenticationService: AuthenticationService) {
        this.filter.dateFrom = format(subDays(new Date(), 2), 'yyyy-MM-dd');

        for (const component of Object.values(HistoryComponentEnum)) {
            this.components.push(component);
        }

        this.components.sort();
    }

    async ngOnInit(): Promise<void> {
        if (this.authenticationService.currentUser && this.authenticationService.currentUser.isEmployee()) {
            await this.loadHistory();
        }
    }
    async loadHistory(loadMore: boolean = false) {
        if (!loadMore) {
            delete this.filter.skip;
            delete this.filter.lastDate;
            this.endReached = false;
            this.history = [];
        }

        const result = await this.apiConnector.getHistoryForOwner(this.authenticationService.currentUser.ownerNumber, this.filter);

        this.history.push(...result.results);
        this.filter.skip = result.skip;
        this.filter.lastDate = result.lastDate;
        this.endReached = result.endReached;

        // @ts-ignore
        (<any>$('#filter-layer')).modal('hide');
    }

    formatJson(data: any) {
        // Manchmal sind die Daten kein JSON
        const savedValue = data;
        let decoded;
        try {
            decoded = JSON.parse(data);
        } catch (e) {
            decoded = savedValue;
        }
        return JSON.stringify(decoded, null, 2);
    }

    async search(value: number) {
        this.filter.accommodationId = value;
        await this.loadHistory();
    }

    async resetFilter() {
        this.filter = new HistoryFilterDto();
        await this.loadHistory();
        // @ts-ignore
        (<any>$('#filter-layer')).modal('hide');
    }

    hide(i: number) {
        const parent = document.getElementsByClassName('change-' + i.toString())[0];

        // @ts-ignore
        parent.getElementsByClassName('entry-wrapper')[0].style.display = 'none';
    }

    hideUnchanged(i: number) {
        const parent = document.getElementsByClassName('change-' + i.toString())[0];

        // @ts-ignore
        parent.querySelectorAll('.entry-wrapper').forEach((element) => (element.style.display = 'block'));

        // @ts-ignore
        parent.querySelectorAll('.jsondiffpatch-unchanged').forEach((element) => (element.style.display = 'none'));
    }

    showAll(i: number) {
        const parent = document.getElementsByClassName('change-' + i.toString())[0];

        // @ts-ignore
        parent.querySelectorAll('.entry-wrapper, .jsondiffpatch-unchanged').forEach((element) => (element.style.display = 'block'));
    }

    openFilterModal() {
        // @ts-ignore
        (<any>$('#filter-layer')).modal('show');
    }
}
