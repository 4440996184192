import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiConnectorService } from '../../../../services/api-connector/api-connector.service';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { NotificationService } from '../../../../services/notification/notification.service';
import { ExtendedAdditionalCostTemplateEntity } from '../../../../entities/extended-additional-cost-template-entity';
import { FindAccommodationGroupRequestDto } from 'data-structures/lib/es6/dto/accommodation-group/find-accommodation-group/find-accommodation-group-request.dto';
import { ComponentsEnum, FindAccommodationRequestDto } from 'data-structures/lib/es6/dto/accommodation/find-accommodation/find-accommodation-request.dto';
import { ExtendedConditions } from '../../../../entities/extended-conditions';
import { ConfirmationDialogService } from '../../../global/confirmation-dialog/confirmation-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { ConditionsSelectComponent } from '../../../global/conditions-select/conditions-select.component';
import * as Sentry from '@sentry/angular-ivy';
import { cloneDeep } from 'lodash';
import { DialogService } from '../../../../services/dialog.service';
import { AdditionalCostTemplateDialogComponent } from '../../../global/dialogs/additional-cost-template-dialog/additional-cost-template-dialog.component';

interface IdToName {
    [key: number]: string;
}

class Names {
    accommodation: IdToName[] = [];
    accommodationGroup: IdToName[] = [];
}

// tslint:disable-next-line:max-classes-per-file
@Component({
    selector: 'app-additional-cost-template',
    templateUrl: './additional-cost-template.component.html',
    styleUrls: ['./additional-cost-template.component.scss'],
})
export class AdditionalCostTemplateComponent implements OnInit {
    additionalCostTemplates: ExtendedAdditionalCostTemplateEntity[] = [];
    currentTemplate: ExtendedAdditionalCostTemplateEntity = this.createNewTemplate();
    names: Names = new Names();
    deleteElement: number;
    @ViewChild(ConditionsSelectComponent) conditionsSelectComponent: ConditionsSelectComponent;

    constructor(
        readonly apiConnector: ApiConnectorService,
        readonly authService: AuthenticationService,
        readonly notificationService: NotificationService,
        readonly confirmationDialogService: ConfirmationDialogService,
        readonly translationService: TranslateService,
        readonly dialogService: DialogService,
    ) {}

    ngOnChanges(): void {}

    async ngOnInit(): Promise<void> {
        this.additionalCostTemplates = await this.apiConnector.getAdditionalCostTemplates(this.authService.currentUser.ownerNumber);
        this.names = await this.getNames(this.additionalCostTemplates);
    }

    private createNewTemplate(): ExtendedAdditionalCostTemplateEntity {
        const template = new ExtendedAdditionalCostTemplateEntity();
        template.conditions = ExtendedConditions.createEmpty(this.authService.currentUser.ownerNumber);
        return template;
    }

    async save($event) {
        try {
            await this.apiConnector.putAdditionalCostTemplate($event, this.authService.currentUser.ownerNumber);
            await this.notificationService.add('form.save.success', 'success');
            this.additionalCostTemplates = await this.apiConnector.getAdditionalCostTemplates(this.authService.currentUser.ownerNumber);
            this.currentTemplate = this.createNewTemplate();
            this.dialogService.closeDialog();
        } catch (e) {
            Sentry.captureException(e);
            await this.notificationService.add('form.save.failure', 'danger');
        }
    }

    async getNames(additionalCostTemplates: ExtendedAdditionalCostTemplateEntity[]) {
        const accommodationIds: Set<number> = new Set();
        const accommodationGroupIds: Set<number> = new Set();
        const names = new Names();

        if (additionalCostTemplates.length) {
            for (const additionalCostTemplate of this.additionalCostTemplates) {
                if (additionalCostTemplate.conditions?.accommodationIds?.length) {
                    for (const accommodationId of additionalCostTemplate.conditions?.accommodationIds) {
                        accommodationIds.add(accommodationId);
                    }
                }

                if (additionalCostTemplate.conditions?.accommodationGroupIds?.length) {
                    for (const accommodationGroupId of additionalCostTemplate.conditions?.accommodationGroupIds) {
                        accommodationGroupIds.add(accommodationGroupId);
                    }
                }
            }
        }

        const accommodationDto = new FindAccommodationRequestDto();
        accommodationDto.ownerNumber = this.authService.currentUser.ownerNumber;
        accommodationDto.take = 1000;
        if (accommodationIds?.size) {
            accommodationDto.accommodationId = Array.from(accommodationIds).filter((element) => element !== null);

            if (accommodationDto.accommodationId.length === 0) {
                delete accommodationDto.accommodationId;
            }
        }

        accommodationDto.components = [ComponentsEnum.Main, ComponentsEnum.AccommodationId];
        const accommodationPromise = this.apiConnector.findAccommodations(accommodationDto);

        const accommodationGroupDto = new FindAccommodationGroupRequestDto();
        accommodationGroupDto.ownerNumber = this.authService.currentUser.ownerNumber;
        accommodationGroupDto.take = 1000;
        if (accommodationGroupIds?.size) {
            accommodationGroupDto.accommodationGroupId = Array.from(accommodationGroupIds);
        }
        const groupPromise = this.apiConnector.findAccommodationGroups(accommodationGroupDto);

        const [accommodations, accommodationGroups] = await Promise.all([accommodationPromise, groupPromise]);

        if (accommodations.accommodations.length) {
            for (const accommodation of accommodations.accommodations) {
                names.accommodation[accommodation.accommodationId] = accommodation.main?.name;
            }
        }

        if (accommodationGroups.length) {
            for (const accommodationGroup of accommodationGroups) {
                names.accommodationGroup[accommodationGroup.accommodationGroupId] = accommodationGroup.name;
            }
        }

        return names;
    }

    setCurrentTemplate(additionalCostTemplate: ExtendedAdditionalCostTemplateEntity) {
        this.currentTemplate = cloneDeep(additionalCostTemplate);
        this.openAdditionalCostTemplateDialog();
    }

    setNewTemplate() {
        this.currentTemplate = this.createNewTemplate();
        this.openAdditionalCostTemplateDialog();
    }

    openAdditionalCostTemplateDialog() {
        this.dialogService.openDialog(
            AdditionalCostTemplateDialogComponent,
            { dialogWidth: this.dialogService.dialogWidth.M },
            {
                currentTemplate: this.currentTemplate,
                saveAdditionalCostTemplate: this.save.bind(this),
            },
        );
    }

    openConfirmationDialog() {
        this.confirmationDialogService
            .confirm('atraveo.additionalcosttemplatebundle.overview.delete-template.button', 'confirm.content.delete.0')
            .then(async (confirmed) => {
                if (confirmed) {
                    this.deleteTemplate();
                } else {
                    this.deleteElement = null;
                }
            })
            .catch(() => undefined);
    }

    async deleteTemplate() {
        await this.apiConnector.deleteAdditionalCostTemplate(this.additionalCostTemplates[this.deleteElement], this.authService.currentUser.ownerNumber);
        this.additionalCostTemplates.splice(this.deleteElement, 1);
    }
}
