<hr>
<div class="row">
    <a class="btn" routerLink="/notification"
                   [queryParams]="{ownerid: authenticationService.currentUser.ownerNumber}"
                   routerLinkActive="active"><i class="fas fa-arrow-circle-left"></i>
    </a>
</div>
<hr>
<div class="row message-content-info">
    <div class="col-md-2">
        {{ 'text.notification.field.fromUser' | translate }}:
    </div>
    <div class="col-md-3">
        {{ messageContent?.sender }}
    </div>
</div>
<div class="row message-content-info">
    <div class="col-md-2">
        {{ 'text.notification.field.toUser' | translate }}:
    </div>
    <div class="col-md-3">
        {{ messageContent?.receiver }}
    </div>
</div>
<div class="row message-content-info">
    <div class="col-md-2">
        {{ 'text.notification.field.subject' | translate }}:
    </div>
    <div class="col-md-3">
        {{ messageContent?.subject }}
    </div>
</div>
<div class="row message-content-info">
    <div class="col-md-2">
        {{ 'text.notification.field.sendDate' | translate }}:
    </div>
    <div class="col-md-3">
        {{ messageDate | stringToDate | date: 'short' : undefined: this.translationService.currentLang }}
    </div>
</div>
<hr>
<div class="row message-content-info">
    <div class="col-md-12 message-body">
        {{ messageContent?.body }}
    </div>
</div>
<div *ngIf="messageContent?.attachment?.length" class="row message-content-info">
    <div class="col-md-12 attachment-list ">
        <ul>
            <li *ngFor="let attachment of messageContent?.attachment"><a class="attachment" (click)="openAttachment(attachment)"><i class="fa fa-download"></i> {{ attachment.name }}</a></li>
        </ul>
    </div>
</div>


