import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MessageCountService } from '../../../../../services/messages/message-count.service';
import { AccommodationRatingService } from '../../../../../services/accommodation/accommodation-rating.service';
import { ErrorCountService } from '../../../../../services/accommodation/error-count.service';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { AccommodationCountService } from '../../../../../services/accommodation/accommodation-count.service';
import { DialogService } from '../../../../../services/dialog.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-task-display',
    templateUrl: './task-display.component.html',
    styleUrls: ['./task-display.component.scss', './../dashboard.component.scss'],
})
export class TaskDisplayComponent implements OnInit, OnDestroy {
    @Input() logout: boolean = false;
    messagesCount: number;
    accommodationUnreadRatingCount: number;
    accommodationCount: number;
    accommodationErrorCount: number;
    onboardingProcess: boolean = false;
    ratingSubscription: Subscription;
    errorSubscription: Subscription;
    accommodationCountSubscription: Subscription;
    messageCountSubscription: Subscription;

    constructor(
        readonly messageCountService: MessageCountService,
        readonly accommodationRatingService: AccommodationRatingService,
        readonly errorCountService: ErrorCountService,
        readonly authService: AuthenticationService,
        readonly accommodationCountService: AccommodationCountService,
        readonly dialogService: DialogService,
    ) {
        this.messagesCount = this.messageCountService.messagesCount;
        this.messageCountSubscription = this.messageCountService.messagesCountChange.subscribe((value) => {
            this.messagesCount = value;
        });

        this.accommodationCountService.getAccommodationCount().then((count) => {
            this.accommodationCount = count;
        });
        this.accommodationCountSubscription = this.accommodationCountService.accommodationCountChange.subscribe((value) => {
            this.accommodationCount = value;
        });

        this.accommodationErrorCount = this.errorCountService.accommodationErrorCount;
        this.errorSubscription = this.errorCountService.accommodationErrorCountChange.subscribe((value) => {
            this.accommodationErrorCount = value;
        });

        this.accommodationUnreadRatingCount = this.accommodationRatingService.accommodationUnreadRatingCount;
        this.ratingSubscription = this.accommodationRatingService.accommodationUnreadRatingCountChange.subscribe((value) => {
            this.accommodationUnreadRatingCount = value;
        });
    }
    ngOnInit() {
        this.onboardingProcess =
            this.authService.currentUser?.tracking?.onboarding?.registrationStarted &&
            (!this.authService.currentUser?.registration?.idNowStatusText ||
                ['CREATED', 'CLICKED', 'ABORTED'].includes(this.authService.currentUser?.registration?.idNowStatusText) ||
                this.accommodationCount === 0 ||
                this.authService.currentUser?.vat?.hasVatId === null ||
                this.authService.currentUser?.vat?.hasVatId === undefined) &&
            this.logout;
    }

    hideLogoutDialog() {
        this.dialogService.closeDialog();
    }

    ngOnDestroy() {
        this.ratingSubscription?.unsubscribe();
        this.errorSubscription?.unsubscribe();
        this.accommodationCountSubscription?.unsubscribe();
        this.messageCountSubscription?.unsubscribe();
    }
}
