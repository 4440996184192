import { Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { GeoDto } from 'data-structures/lib/es6/dto/accommodation/position/geo.dto';
import { TranslateService } from '@ngx-translate/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GeoRegionEntity } from 'data-structures/lib/es6/entity/geography/geo-region.entity';
import { ApiConnectorService } from '../../../services/api-connector/api-connector.service';
import { GeoCityEntity } from 'data-structures/lib/es6/entity/geography/geo-city.entity';

export interface LanguageTextAndId {
    'id': string,
    'text': string
}

@Component({
    selector: 'app-geo-triplet',
    templateUrl: './geo-triplet.component.html',
    styleUrls: ['./geo-triplet.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GeoTripletComponent),
            multi: true
        }
    ]
})
export class GeoTripletComponent implements OnInit, OnChanges, ControlValueAccessor {
    @Input() geoDto: GeoDto;
    @Input() idKeyPrefix: string = '';
    @Input() classKey: string = '';
    @Input() noneSelectedDisplayAll = false; // Wenn nichts ausgewählt ist, soll "alle Länder" angezeigt werden
    @Input() isAccommodationOrGroupActive: boolean = false;
    regionsRaw: GeoRegionEntity[] = [];
    regionNames: LanguageTextAndId[] = [];
    citiesRaw: GeoCityEntity[] = [];
    cityNames: LanguageTextAndId[] = [];

    constructor(readonly apiConnector: ApiConnectorService,
                readonly translateService: TranslateService) {
    }

    async ngOnInit(): Promise<void> {
    }

    async ngOnChanges(): Promise<void> {
        const promises = [];
        if (this.geoDto?.countryId) {
            promises.push(this.changeCountry(this.geoDto.countryId));
        }

        if (this.geoDto?.bestRegionId) {
            promises.push(this.changeRegion(this.geoDto.bestRegionId));
        }

        await Promise.all(promises);
    }

    onChange: any = () => {
    };
    onTouched: any = () => {
    };

    writeValue(obj: any): void {
        this.geoDto = obj;
    }

    get value() {
        return this.geoDto;
    }

    set value(v) {
        this.geoDto = v;
        this.onChange(this.geoDto);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    async changeCountry(country: string) {
        if (country && country !== 'null') { // Bei "Alle Länder ist kein Land gesetzt"
            this.regionNames = []; // Löschen um die Selectbox zu sperren, da es manchmal recht lange dauert die Regionen zu laden
            this.cityNames = [];
            this.regionsRaw = await this.apiConnector.getRegions(country);
            this.regionNames = this.getNamesAndSort(this.regionsRaw);
        } else {
            this.geoDto.countryId = null;
            this.geoDto.bestRegionId = null;
            this.geoDto.cityId = null;
            this.geoDto.manual = false;
        }
    }

    async changeRegion(value: number) {
        this.cityNames = [];
        this.citiesRaw = await this.apiConnector.getCities(value);
        this.cityNames = this.getNamesAndSort(this.citiesRaw);
    }

    private getNamesAndSort(citiesOrRegions: GeoRegionEntity[] | GeoCityEntity[]): LanguageTextAndId[] {
        const names = citiesOrRegions.map((cityOrRegion) => {
            const translation = this.getTranslation(cityOrRegion);
            if (translation) {
                return {text: this.getTranslation(cityOrRegion), id: cityOrRegion.id}
            }
        });

        names.sort((a, b) => {
            return a.text.localeCompare(b.text);
        });

        return names;
    }

    private getTranslation(regionOrCity: GeoRegionEntity | GeoCityEntity): string {
        let translation = '';

        if (!regionOrCity.text) {
            return translation;
        }

        if (regionOrCity.text[this.translateService.currentLang]) {
            translation = regionOrCity.text[this.translateService.currentLang];
        } else if (regionOrCity.text['en']) {
            translation = regionOrCity.text['en'];
        } else if (Object.keys(regionOrCity)) {
            translation = regionOrCity.text[Object.keys(regionOrCity.text)[0]];
        }

        return translation;
    }
}
