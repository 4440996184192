import { AfterViewInit, Component, NgZone } from '@angular/core';

@Component({
    selector: 'back-to-top-button',
    templateUrl: './back-to-top-button.component.html',
    styleUrls: ['./back-to-top-button.component.scss'],
})
export class BackToTopButtonComponent implements AfterViewInit {
    constructor(readonly ngZone: NgZone) {}
    ngAfterViewInit() {
        this.scrollToTopButton();
    }
    scrollToTopButton() {
        this.ngZone.runOutsideAngular(() => {
            document.addEventListener('scroll', scroll, { passive: true });
            const scrollTop = document.getElementById('arrow-up');
            function scroll() {
                if (document.documentElement.scrollTop > 300) {
                    scrollTop.style.display = 'block';
                } else {
                    scrollTop.style.display = 'none';
                }
            }
            scrollTop.addEventListener('click', scrollToTop);
            function scrollToTop() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        });
    }
}
