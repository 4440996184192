import { Injectable } from '@angular/core';
import { subDays } from 'date-fns';
import { cloneDeep } from 'lodash';

export enum CacheKeys {
    AdditionalCostDefinition = 'AdditionalCostDefinition',
    AdditionalCostRules = 'AdditionalCostRules',
    FeatureDefinition = 'FeatureDefinition',
    FeatureDefinitionWithoutGroup = 'FeatureDefinitionWithoutGroup',
    Seasons = 'Seasons',
    Countries = 'Countries',
}

@Injectable({
    providedIn: 'root',
})
export class CacheService {
    cache = {};
    keepCacheKeysInLocalStorage = {
        [CacheKeys.AdditionalCostDefinition]: 7,
        [CacheKeys.FeatureDefinition]: 7,
        [CacheKeys.Countries]: 7,
        [CacheKeys.AdditionalCostRules]: 7,
        [CacheKeys.FeatureDefinitionWithoutGroup]: 7,
    };

    constructor() {}

    add(key: string, value: any) {
        if (key in this.keepCacheKeysInLocalStorage) {
            this.setDataInLocalStorage(key, value);
            return;
        }

        this.cache[key] = cloneDeep(value);
        if (!Array.isArray(this.cache[key]) && this.cache[key] !== null) {
            this.cache[key].updated = new Date();
        }
    }

    delete(key: string) {
        delete this.cache[key];
    }

    read(key: string) {
        if (key in this.keepCacheKeysInLocalStorage) {
            return this.getDataFromLocalStorage(key);
        }

        return cloneDeep(this.cache[key]);
    }

    clear() {
        this.cache = {};
    }

    getDataFromLocalStorage(key): any {
        const stringData = localStorage.getItem(key);

        if (!stringData) {
            return;
        }

        const data = JSON.parse(stringData);

        if (new Date(data.date) > subDays(new Date(), this.keepCacheKeysInLocalStorage[key])) {
            return data.data;
        }

        localStorage.removeItem(key);
    }

    setDataInLocalStorage(key, data) {
        const storageData = {
            date: new Date(),
            data,
        };

        localStorage.setItem(key, JSON.stringify(storageData));
    }
}
