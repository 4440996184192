import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { ApiConnectorService } from '../../../../services/api-connector/api-connector.service';
import { Router } from '@angular/router';
import { BackOfficeConnectorService } from '../../../../services/api-connector/back-office-connector-service';
import { Contact } from '../../../../entities/contact';
import { BookingConnectorService } from '../../../../services/api-connector/booking-connector.service';
import { NotificationService } from '../../../../services/notification/notification.service';
import { BookingEntity } from 'data-structures/lib/es6/entity/booking.entity';
import { DialogService } from '../../../../services/dialog.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
    @Input() contactFormular: Contact = new Contact(this.authenticationService);

    bookings: BookingEntity[];
    attachments: File[] = [];

    constructor(
        readonly apiConnector: ApiConnectorService,
        readonly backofficeConnector: BackOfficeConnectorService,
        readonly bookingConnector: BookingConnectorService,
        readonly authenticationService: AuthenticationService,
        readonly router: Router,
        readonly notificationService: NotificationService,
        readonly dialogService: DialogService,
    ) {}

    async ngOnInit() {
        /* Das hat seit Livegang noch nie funktioniert, nach Absprache mit Gordon nehme ich das erst mal raus für bessere Perfomance
        const promises: Promise<any>[] = [this.bookingConnector.getBookingsByOwnerNumber (
            this.authenticationService.currentUser,
            [FindBookingComponents.BookingId]
        )];

        await Promise.all(promises).then((resultValues) => {
            const resultBookings: any = resultValues[0];
            this.bookings = resultBookings.result;
        });*/
    }

    async onSubmit(): Promise<void> {
        // @ts-ignore
        const result = $('#contactForm')[0].checkValidity();

        if (!result) {
            // @ts-ignore
            $('#contactForm')[0].reportValidity();
            this.notificationService.add('validation.form.all.required', 'danger');
            return;
        }

        const selectedReceiver = this.contactFormular.receiver;
        this.contactFormular.receiver = this.contactFormular.getRecieverEmailAddress(this.contactFormular.receiver);
        this.contactFormular.getDateTime(Date.now());

        const formData = new FormData();
        for (const attachment of this.attachments) {
            formData.append('attachment[]', attachment, attachment.name);
        }
        formData.set('contactFormular', JSON.stringify(this.contactFormular));

        if (await this.backofficeConnector.sendMessage(formData, this.authenticationService.currentUser.ownerNumber)) {
            this.dialogService.closeDialog(() => this.contactFormular.resetFormValues());

            await this.notificationService.add('form.save.success', 'success');
            this.attachments = [];
            this.contactFormular.resetFormValues();
        }

        // Auswahl wiederherstellen
        this.contactFormular.receiver = selectedReceiver;
    }

    onFileSelected(event) {
        this.attachments.push(...event.target.files);
    }
    closeDialog() {
        this.dialogService.closeDialog(() => this.contactFormular.resetFormValues());
    }
}
