import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { AccommodationEntity } from 'data-structures/lib/es6/entity/accommodation/accommodation.entity';
import { ApiConnectorService } from '../../../services/api-connector/api-connector.service';
import { ThumbnailComponent } from '../../main/content/accommodation/images/thumbnail/thumbnail.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccommodationCountService } from '../../../services/accommodation/accommodation-count.service';
import { Subscription } from 'rxjs';
import { ChannelTypeEnum } from 'data-structures/lib/es6/enum/channel-type.enum';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-accommodation-change-select',
    templateUrl: './accommodation-change-select.component.html',
    styleUrl: './accommodation-change-select.component.scss',
})
export class AccommodationChangeSelectComponent implements OnDestroy {
    @ViewChild(ThumbnailComponent, { static: true }) thumbnail: ThumbnailComponent;
    @Input() currentAccommodationIdList: number[] = [];
    @Input() currentTab: string = null;
    @Input() control: FormControl = null;
    accommodations: AccommodationEntity[] = [];
    loadedAccommodationCount: number = 0;
    loading: boolean = false;
    loadItems: number = 50;
    endReached: boolean = false;
    accommodationCount: number;
    accommodationCountServiceSubscription: Subscription;

    constructor(
        readonly apiConnectorService: ApiConnectorService,
        readonly router: Router,
        readonly translateService: TranslateService,
        readonly accommodationCountService: AccommodationCountService,
    ) {}

    async ngOnInit() {
        if (this.accommodationCount === undefined) {
            this.accommodationCount = await this.accommodationCountService.getAccommodationCount(false);
        }

        this.accommodationCountServiceSubscription = await this.accommodationCountService.accommodationCountChange.subscribe((value) => {
            this.accommodationCount = value;
        });
    }

    async ngOnChanges() {
        if (this.currentAccommodationIdList) {
            this.accommodations = [];
            this.endReached = false;
        }
    }

    async selected($event: any) {
        if (!this.control) {
            let url = '/accommodation/' + $event.accommodationId;
            if (this.currentTab === 'image') {
                this.currentTab = 'images';
            }
            if (this.currentTab) {
                url += '/' + this.currentTab;
            }

            await this.router.navigateByUrl(url);
        }
    }

    async search($event: { term: string; items: any[] }) {
        if ($event.term.length >= 2) {
            await this.load($event.term);
        }
    }

    customFilter(term: string, item): boolean {
        if (item.main?.name?.toLowerCase().includes(term.toLowerCase())) {
            return true;
        }

        if (item?.channels) {
            for (const channel of item.channels) {
                if (channel.type === ChannelTypeEnum.EDOMIZIL && channel?.internalId) {
                    if (channel.internalId.toLowerCase().includes(term.toLowerCase())) {
                        return true;
                    }
                }
            }
        }

        if (item.accommodationId.toString().includes(term)) {
            return true;
        }

        return false;
    }

    async open($event) {
        if (!this.accommodations.length) {
            await this.load();
        }
    }

    async onScroll({ end }) {
        if (this.loading || this.accommodations.length - end > 20) {
            return;
        }

        this.loadedAccommodationCount += this.loadItems;
        await this.load(null, this.loadedAccommodationCount);
    }

    async onScrollToEnd() {
        this.loadedAccommodationCount += this.loadItems;
        await this.load(null, this.loadedAccommodationCount);
    }

    async load(term: string = '', skip: number = 0): Promise<any> {
        if (this.endReached) {
            return;
        }

        this.loading = true;
        const result = await this.apiConnectorService.accommodationAjaxSearchObservable(term, skip).toPromise();
        this.loading = false;
        const addedAccommodations = [];

        if (!term && result.length === 0) {
            this.endReached = true;
            return;
        }

        // Vergleichen, wenn erst "geblättert" wurde und dann die Suche genutzt wird, kann es sein das die Ergebnisse doppelt sind
        for (const loadedAccommodation of result) {
            if (loadedAccommodation.channels?.length) {
                for (const channel of loadedAccommodation.channels) {
                    if (channel.type === ChannelTypeEnum.EDOMIZIL && channel?.internalId) {
                        loadedAccommodation.internalId = channel.internalId;
                    }
                }
            }
            if (
                !this.accommodations.some((accommodation) => accommodation.accommodationId === loadedAccommodation.accommodationId) &&
                !this.currentAccommodationIdList.includes(loadedAccommodation.accommodationId)
            ) {
                addedAccommodations.push(loadedAccommodation); // Wenn man hier direkt in this.accommodations pushed, dann bekommt die Selectbox das nicht mit
            }
        }

        this.accommodations = this.accommodations.concat(addedAccommodations);
        this.accommodations = this.accommodations.sort((a, b) => a.accommodationId - b.accommodationId);
    }

    ngOnDestroy() {
        this.accommodationCountServiceSubscription.unsubscribe();
    }
}
