// toggle-menu.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ToogleMenuService {
    private isMenuVisibleSubject = new BehaviorSubject<boolean>(false);
    isMenuVisible$ = this.isMenuVisibleSubject.asObservable();
    toggleMenu(closeMenu: boolean = false) {
        const shouldClose = closeMenu || this.isMenuVisibleSubject.value;
        this.isMenuVisibleSubject.next(!shouldClose);
    }
}
