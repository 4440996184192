<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'atraveo.bookingbundle.button.show' | translate }}</h5>
        <div class="pdf-download">
            <button class="btn p-0" (click)="downloadPDF();">
                <i class="fas fa-download fa-lg ml-1"></i>
            </button>
            <app-loading-indicator ></app-loading-indicator>
        </div>

        <button class="close" (click)="closeDialog()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" id="pdf{{id}}">

        <div *ngIf="booking">
            <div class="row">
                <div class="col">

                    <div class="row">
                        <div class="col">
                            <strong>{{ 'atraveo.bookingbundle.accommodation.number' | translate }}</strong>
                        </div>
                        <div class="col">
                            <a data-dismiss="modal" routerLink="/accommodation/{{ booking.accommodationId }}"
                               routerLinkActive="active">{{ booking.accommodationId }}</a>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <strong>{{ 'atraveo.bookingbundle.booking.number' | translate }}</strong>
                        </div>
                        <div class="col">
                    <span *ngIf="this.authService.currentUser.roles.includes('OWNERSERVICE')">
                        <a href="">{{ booking.bookingNumber }}</a>
                    </span>
                            <span *ngIf="!this.authService.currentUser.roles.includes('OWNERSERVICE')">
                        {{ booking.bookingNumber }}
                    </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <strong>{{ 'atraveo.bookingbundle.booking.date' | translate }}</strong>
                        </div>
                        <div class="col">

                            <span *ngIf="booking.bookingDates?.bookingDate">{{ booking.bookingDates.bookingDate | stringToDate | date: format : undefined: this.translationService.currentLang }}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <strong>{{ 'atraveo.bookingbundle.arrival' | translate }}</strong>
                        </div>
                        <div class="col">
                            <span *ngIf="booking.arrivalDate">{{ booking.arrivalDate | stringToDate | date: format : undefined: this.translationService.currentLang }}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <strong>{{ 'atraveo.bookingbundle.departure'  | translate }}</strong>
                        </div>
                        <div class="col">
                            <span *ngIf="booking.departureDate">{{ booking.departureDate | stringToDate | date: format : undefined: this.translationService.currentLang }}</span>
                        </div>
                    </div>

                </div>

                <div class="col">

                    <!-- SOll Laut Gordon raus
                    <div class="row">
                        <div class="col">
                            <strong>{{ 'atraveo.bookingbundle.invoice.number' | translate }}</strong>
                        </div>
                        <div class="col">
                            {{ booking.invoiceNumber }}
                        </div>
                    </div>-->
                    <div class="row">
                        <div class="col">
                            <strong>{{ 'atraveo.bookingbundle.payToUs' | translate }}</strong>
                        </div>
                    </div>
                    <hr>

                    <div class="row-wrapper">
                        <div class="row">
                            <div class="col">
                                <strong>{{ 'atraveo.accommodationbundle.price.rentprice' | translate }}:</strong>
                            </div>
                            <div class="col">
                                {{ booking.priceDetail?.price?.amount | currency: booking.priceDetail?.price?.currency ? booking.priceDetail.price.currency : ownerCurrency }}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <strong>{{ 'atraveo.bookingbundle.additionalPrice' | translate }}</strong>
                        </div>
                        <div class="col">
                            {{ booking.priceDetail?.additionalPrice?.amount | currency: booking.priceDetail?.additionalPrice?.currency ? booking.priceDetail.additionalPrice.currency : ownerCurrency }}<br>
                            <span *ngIf="booking.priceDetail?.locationPrice?.amount > 0">
                                        {{ booking.priceDetail?.locationPrice?.amount | currency : booking.priceDetail?.locationPrice?.currency ? booking.priceDetail.locationPrice.currency : ownerCurrency }} {{ 'atraveo.accommodationbundle.price.create.additionalcost.field.payableAtLocation.short' | translate }} <br>
                                    </span>
                        </div>
                    </div>

                    <div class="row-wrapper">
                        <div class="row">
                            <div class="col">
                                <strong>{{ 'atraveo.bookingbundle.locationPrice' | translate}}</strong>
                            </div>
                            <div class="col">
                                        <span *ngFor="let additionalCost of translationMappingList">
                                            <span >
                                                {{ additionalCost }}<br>
                                            </span>
                                        </span>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <div class="row">
                        <div class="col">
                            <strong>{{ 'atraveo.bookingbundle.complete' | translate }}:</strong>
                        </div>
                        <div class="col">
                            {{ booking.priceDetail?.price?.amount | currency: booking.priceDetail?.price?.currency ? booking.priceDetail.price.currency : ownerCurrency }}
                        </div>
                    </div>

                    <div class="row-wrapper">
                        <div class="row">
                            <div class="col">
                                <strong>{{ 'text.e.domizil.deduction' | translate}}:</strong>
                            </div>
                            <div class="col" *ngIf="booking.priceDetail?.commission?.amount">
                                - {{ booking.priceDetail?.commission?.amount | currency: booking.priceDetail?.commission?.currency ? booking.priceDetail.commission.currency : ownerCurrency }}
                            </div>
                        </div>
                    </div>
                    <hr>

                    <div class="row">
                        <div class="col">
                            <strong>{{ 'atraveo.bookingbundle.payment.0' | translate }}</strong>
                        </div>
                        <div class="col">
                            <ng-container *ngIf="booking.payment?.prePaymentRateToOwner">
                                        <span>
                                            {{ booking.payment?.prePaymentRateToOwner | currency: booking.payment?.currency ? booking.payment.currency : ownerCurrency }}
                                        </span><br>
                            </ng-container>
                            <ng-container *ngIf="booking.payment?.finalPaymentRateToOwner">
                                        <span>
                                            {{ booking.payment?.finalPaymentRateToOwner | currency: booking.payment?.currency ? booking.payment.currency : ownerCurrency }}<br>
                                        </span>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row mt-3 mb-2">
                        <div class="col info-box">
                                    <span>
                                        <i class="fas fa-info-circle"></i>
                                        {{ 'text.your.payment.info' | translate }}
                                    </span>
                        </div>
                    </div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col">

                            <div class="row">
                                <div class="col">
                                    <strong>{{ 'atraveo.bookingbundle.fellowCustomerCount' | translate }}</strong>
                                </div>
                                <div class="col">
                                    {{ booking.fellowCustomerCount }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <strong>{{ 'atraveo.bookingbundle.customer.name' | translate }}</strong>
                                </div>
                                <div class="col">
                                    {{ booking.customer?.forename }} {{ booking.customer?.surname }}
                                </div>
                            </div>

                            <div class="row" *ngIf="booking.customer?.street">
                                <div class="col">
                                    <strong>{{ 'atraveo.bookingbundle.customer.address.0' | translate }}</strong>
                                </div>
                                <div class="col">
                                    {{ booking.customer?.street }} {{ booking.customer.streetNumber }}<br>
                                    {{ booking.customer?.postcode }} {{ booking.customer.city }}<br>
                                    {{ countryName }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <strong>{{ 'atraveo.bookingbundle.customer.phone' | translate }}</strong>
                                </div>
                                <div class="col">
                                    {{ booking.customer?.numberPhone }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <strong>{{ 'atraveo.bookingbundle.customer.mobile' | translate }}</strong>
                                </div>
                                <div class="col">
                                    {{ booking.customer?.numberMobile }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <strong>{{ 'atraveo.bookingbundle.customer.email' | translate }}</strong>
                                </div>
                                <div class="col">
                                    {{ booking.customer?.email }}
                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="row">
                                <div class="col">
                                    <strong>{{ 'atraveo.bookingbundle.customer.birthday' | translate }}</strong>
                                </div>
                                <div class="col">
                                    <span *ngIf="booking.customer?.birthday">{{ booking.customer?.birthday | stringToDate | date: format : undefined: this.translationService.currentLang  }}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <strong>{{ 'atraveo.bookingbundle.customer.language' | translate }}</strong>
                                </div>
                                <div class="col">
                                    {{ booking.customer?.language }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <strong>{{ 'atraveo.bookingbundle.customer.pets' | translate }}</strong>
                                </div>
                                <div class="col">
                                    {{ booking.customer?.pets }}
                                </div>
                            </div>

                            <div class="row" *ngIf="booking.fellowCustomers?.length">
                                <div class="col">
                                    <strong>{{ 'atraveo.bookingbundle.create.fellowCustomer.title' | translate }}</strong>
                                </div>
                                <div class="col">
                                    <p *ngFor="let person of booking.fellowCustomers">
                                        {{ person.forename}} {{ person.surname}}<br><span *ngIf="person.birthday">{{ person.birthday | stringToDate | date: format : undefined: this.translationService.currentLang }}</span>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div>
                <p><strong>{{ 'atraveo.bookingbundle.customer.comment.title' | translate }}</strong></p>
                <p *ngIf="booking.comment">{{ booking.comment }}</p>
                <p *ngIf="!booking.comment">{{ 'atraveo.bookingbundle.not.specified' | translate }}</p>
            </div>
            <div>
                <p><strong>{{ 'atraveo.bookingbundle.customer.answer.title' | translate }}</strong></p>
                <p *ngIf="booking.reply">{{ booking.reply }}</p>
                <p *ngIf="!booking.reply">{{ 'atraveo.bookingbundle.not.specified' | translate }}</p>
            </div>

            <p>{{ 'atraveo.bookingbundle.customer.note.title.0' | translate }}</p>
            <!-- nach Absprache mit Gordon wird der interne Vermerk des Vermieters nicht mehr benötigt -->

        </div>

        <div class="free-booking" *ngIf="booking?.ownerStatus === 'declined' && this.bookingIsInFuture">
            <button class="btn btn-primary" (click)="freeBooking()">{{ 'atraveo.bookingbundle.free.calendar' | translate }}</button>
        </div>
    </div>
</div>